<template>
	<card>
		<div class="card-body">
			<div :class="isExpired ? 'bg-danger bg-expired' : ''">
				<h2 class="card-title">
					{{ $t('eshop.reservationDetail') }} {{ reservation.reservationId }}
				</h2>
				<p v-if="isExpired">
					{{ $t('eshop.reservationWillBeTakenToWarehouse') }}
				</p>
			</div>

			<form-group-input :label="$t('eshop.occupiedFrom')" disabled>
				<date-picker
					:value="reservation.from | datetime"
					type="datetime"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					prefix-icon="el-icon-date"
					readonly />
			</form-group-input>
			<form-group-input :label="$t('eshop.subscriptionEnd')" disabled>
				<date-picker
					:value="reservation.to | datetime"
					type="datetime"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					prefix-icon="el-icon-date"
					readonly />
			</form-group-input>
			<form-group-input :label="$t('eshop.pickedUp')" disabled>
				<date-picker
					:value="reservation.realEnd | datetime"
					type="datetime"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					prefix-icon="el-icon-date"
					readonly />
			</form-group-input>
			<div class="d-flex">
				<div class="col-sm-6 pl-0">
					<form-group-input :label="$t('eshop.boxNumber')" :value="reservation.serialNumber" readonly />
				</div>
				<div class="col-sm-6 pr-0">
					<form-group-input :label="$t('eshop.boxType')" :value="BoxType.czech(reservation.type)" readonly />
				</div>
			</div>
			<form-group-input :label="$t('eshop.orderNumber')" :value="reservation.reservationNumber" readonly />
			<form-group-input :label="$t('eshop.cashOnDelivery')" :value="reservation.price" readonly />
			<form-group-input :label="$t('eshop.state')" :value="reservationStatusCzech" readonly />
			<form-group-input :label="$t('eshop.email')" :value="reservation.email" readonly />
			<form-group-input :label="$t('eshop.phone')" :value="reservation.phone | phone" readonly />
			<form-group-input :label="$t('eshop.customerAddress')" :value="reservation.customerAddress" readonly />
			<fieldset class="form-group border p-3">
				<legend class="w-auto px-2 mb-n2 control-label">
					{{ $t('eshop.customerAddress') }}
				</legend>
				<div class="row">
					<div class="col-sm-6">
						<form-group-input :label="$t('eshop.country')" :value="reservation.customer && reservation.customer.state" readonly />
						<form-group-input :label="$t('eshop.county')" :value="reservation.customer && reservation.customer.county" readonly />
						<form-group-input :label="$t('eshop.zip')" :value="reservation.customer && reservation.customer.zip" readonly />
					</div>
					<div class="col-sm-6">
						<form-group-input :label="$t('eshop.region')" :value="reservation.customer && reservation.customer.region" readonly />
						<form-group-input :label="$t('eshop.city')" :value="reservation.customer && reservation.customer.city" readonly />
						<form-group-input :label="$t('eshop.street')" :value="reservation.customer && reservation.customer.street" readonly />
					</div>
				</div>
			</fieldset>
			<form-group-input :label="$t('eshop.customerName')" :value="reservation.customerName" readonly />
			<div class="d-flex justify-content-between">
				<div v-if="canDiscard">
					<confirm-button
						:confirm-message="$t('eshop.discardReservation')"
						:message-success="$t('eshop.discardReservationSuccess')"
						:message-error="$t('eshop.discardReservationError')"
						:callback="discardReservation">
						{{ $t('eshop.discardReservation') }}
					</confirm-button>
				</div>
			</div>
		</div>
	</card>
</template>

<script>
import { assign } from 'lodash';
import { mapState } from 'vuex';
import { DatePicker } from 'element-ui';

import { ReservationStatus, BoxType } from '@/modules/eshop/store/enums';
import { ConfirmButton } from '@/components/UIComponents';
import { appName } from '@/modules/eshop/config';

export default {
	name: 'ReservationDetail',
	components: {
		DatePicker,
		ConfirmButton,
	},
	props: {
		reservation: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			BoxType,
		};
	},
	computed: {
		...mapState(appName, ['currentUser']),
		isExpired() {
			return ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.PREPARED && new Date(this.reservation.prepaidTo) < new Date();
		},
		canDiscard() {
			const {
				WAIT_FOR_COLLECT,
				WAIT_FOR_PAY,
				WAIT_FOR_DELIVERY,
				WAIT_FOR_RETURN,
				WAIT_FOR_PACKAGE,
				WAIT_FOR_EMPTY_DESTINATION,
				DISCARD,
				PICK_UP,
				COLLECTED,
				PRESELECT,
			} = ReservationStatus;

			return (
				[WAIT_FOR_DELIVERY, WAIT_FOR_RETURN, WAIT_FOR_PACKAGE].includes(ReservationStatus.enumValueOf(this.reservation.status))
				|| (this.reservation.stockItem
					&& ![WAIT_FOR_COLLECT, WAIT_FOR_PAY, WAIT_FOR_EMPTY_DESTINATION, DISCARD, PICK_UP, COLLECTED, PRESELECT].includes(
						ReservationStatus.enumValueOf(this.reservation.status),
					))
			);
		},
		reservationStatusCzech() {
			return ReservationStatus.czech(this.reservation.status);
		},
	},
	methods: {
		async discardReservation() {
			const data = await this.$service.reservation.discardReservation(this.reservation.reservationId);
			assign(this.reservation, data);
		},

	},
};
</script>

<style lang="scss" scoped>
.bg-expired {
	border-radius: 10px;
	padding: 5px;
	margin-bottom: 5px;
}
.form-group legend {
	font-size: 0.8rem;
}
</style>
