<template>
	<div v-loading="loading">
		<div v-if="editing" class="row">
			<div class="col-sm-12">
				<delivery-point-values-card
					v-if="!emptyDeliveryPoint"
					data-cy="editPageContent"
					:delivery-point="deliveryPoint"
					:editing="editing"
					:empty-delivery-point="emptyDeliveryPoint"
					:edit-form-visible="editFormVisible"
					@change-to-smartbox="onChangeToSmartbox"
					@show-hide-edit-dialog="showHideEditDialog" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<card v-if="!editing">
					<delivery-point-description-card :delivery-point="deliveryPoint" :editing="editing" />
					<hr>
					<p class="note text-muted">
						{{ $t('admin.required') }}
					</p>
				</card>
			</div>
		</div>
		<div class="row">
			<div
				v-if="editing"
				class="col-sm-12">
				<delivery-point-boxes-card
					v-if="!emptyDeliveryPoint"
					:delivery-point="deliveryPoint"
					:smart-box-infos="smartBoxInfos"
					@go-to-reservation-detail="goToReservationDetail"
					@get-delivery-point="init"
					@update-pma-keys="updatePmaKeys" />
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<delivery-point-pick-up-attempts-card
					v-if="editing && deliveryPoint.type !== DeliveryPointType.SmartKeyBox.name"
					:delivery-point-id="deliveryPointId" />
				<pma-info
					v-if="editing && deliveryPoint.type === DeliveryPointType.SmartKeyBox.name && getSmartBoxes.length"
					ref="refPmaInfo"
					:smart-boxes="getSmartBoxes"
					:smart-box-infos="smartBoxInfos"
					:container-disabled="deliveryPoint.isDisabled || deliveryPoint.isLocked"
					@go-to-reservation-detail="goToReservationDetail" />
				<delivery-point-photos v-if="deliveryPoint && editing" :container-id="deliveryPointId" :primary-photo-file-name="deliveryPoint.primaryPhotoFileName" />
			</div>
			<div
				v-if="editing"
				class="col-md-6">
				<delivery-point-opening-hours-card :delivery-point-id="deliveryPointId" />
			</div>
			<div v-if="deliveryPointType === DeliveryPointType.PenguinBox.name" class="col-sm-6">
				<delivery-point-gallery-card v-if="editing" />
			</div>
		</div>
		<el-dialog
			v-if="!emptyDeliveryPoint && editing"
			:close-on-click-modal="false"
			:visible.sync="editFormVisible"
			:before-close="handleEditDialogClose"
			width="98%"
			top="15px">
			<delivery-point-description-card :delivery-point="deliveryPoint" :editing="editing" @show-hide-edit-dialog="showHideEditDialog" />
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { Dialog as ElDialog } from 'element-ui';

import FormMixin from '@/util/form-mixin';
import { DeliveryPointType } from '@/modules/admin/store/enums';
import DeliveryPointOpeningHoursCard from '@/modules/admin/views/DeliveryPoint/DeliveryPointDetails/DeliveryPointOpeningHoursCard.vue';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
import { formChanged } from '@/store/mutation-types';
import { containerTypes } from '@/globalConstants';
import notifyService from '@/service/notify-service';

import DeliveryPointGalleryCard from './DeliveryPointDetails/DeliveryPointGalleryCard.vue';
import DeliveryPointPickUpAttemptsCard from './DeliveryPointDetails/DeliveryPointPickUpAttemptsCard.vue';
import DeliveryPointValuesCard from './DeliveryPointDetails/DeliveryPointValuesCard.vue';
import DeliveryPointBoxesCard from './DeliveryPointDetails/DeliveryPointBoxesCard.vue';
import DeliveryPointDescriptionCard from './DeliveryPointDetails/DeliveryPointDescriptionCard.vue';
import DeliveryPointPhotos from './DeliveryPointDetails/DeliveryPointPhotos.vue';
import PmaInfo from './DeliveryPointDetails/PmaInfo.vue';

export default {
	name: 'DeliveryPointForm',
	components: {
		DeliveryPointOpeningHoursCard,
		DeliveryPointGalleryCard,
		DeliveryPointPickUpAttemptsCard,
		DeliveryPointDescriptionCard,
		DeliveryPointBoxesCard,
		DeliveryPointValuesCard,
		DeliveryPointPhotos,
		ElDialog,
		PmaInfo,
	},
	mixins: [FormMixin],
	props: {
		deliveryPointType: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			DeliveryPointType,
			loading: false,
			smartBoxInfos: [],
			deliveryPoint: {
				latitude: 49.4952258,
				longitude: 16.6492353,
				state: 'Česká republika',
				region: 'Jihomoravský',
				county: 'Blansko',
				city: 'Boskovice',
				street: 'Dřevařská',
				location: 'Dílna',
				postCode: '68001',
				statusPeriod: 1800,
				periodsUntilOffline: 3,
				priceMap: 1,
				public: false,
				type: this.deliveryPointType,
			},
			editing: false,
			editFormVisible: false,
		};
	},
	computed: {
		...mapState(appName, ['currentUser']),
		deliveryPointId() {
			return Number(this.$route.params.id);
		},
		emptyDeliveryPoint() {
			return Object.keys(this.deliveryPoint).length === 0;
		},
		getDeliveryPointValuesCardClass() {
			return this.deliveryPointType === DeliveryPointType.Store.name ? 'col-sm-12' : 'col-sm-7';
		},
		getDeliveryPointBoxesCardClass() {
			return this.deliveryPointType !== DeliveryPointType.PenguinBox.name ? 'col-sm-12' : 'col-sm-7';
		},
		getSmartBoxes() {
			if (this.deliveryPoint.boxes) {
				return this.deliveryPoint.boxes; // .filter((b) => !b.isLocked && !b.isDisabled).map((x) => x.serialNumber);
			}
			return [];
		},
	},
	watch: {
		deliveryPointId: {
			handler() {
				this.init();
			},
			immediate: true,
		},
	},
	methods: {
		alphanumCompareBox(a, b) {
			return String(a.serialNumber).localeCompare(String(b.serialNumber), undefined, {
				numeric: true,
				sensitivity: 'base',
			});
		},
		showHideEditDialog() {
			this.editFormVisible = !this.editFormVisible;
		},
		async onChangeToSmartbox() {
			try {
				await this.$service.deliveryPoint.changeContainerType(this.deliveryPointId, containerTypes.smartBox);
				notifyService.notifySuccess(this.$t('admin.containerTypeChanged'));
				this.init();
				this.$router.push({ name: 'smart-box-edit', params: { id: this.deliveryPointId } });
			} catch {
				notifyService.notifyError(this.$t('admin.containerTypeUnchanged'));
			}
		},
		async handleEditDialogClose(done) {
			if (this.formChanged) {
				const answer = await this.unsavedChangesMethod();
				if (answer) {
					done();
					this.$store.commit(`${appName}/${formChanged}`, false);
				}
				return {};
			}
			return done();
		},
		goToReservationDetail(reservationId) {
			reservationId && this.$router.push({ name: 'reservation-detail', params: { id: reservationId } });
		},
		async updatePmaKeys() {
			await this.$refs.refPmaInfo.updateKeys();
		},
		async init() {
			this.loading = true;
			if (this.deliveryPointId) {
				this.editing = true;
				try {
					this.deliveryPoint = await this.$service.deliveryPoint.getOne(this.deliveryPointId, this.deliveryPointType === DeliveryPointType.Store.name);
				} catch (e) {
					console.error(e);
					notifyService.notifyError(this.$t('admin.deliveryPointNotFound'));
					this.$router.push({ name: 'container-list' });
					return;
				}

				this.deliveryPoint.boxes.sort(this.alphanumCompareBox);

				this.checkDeliveryPointForm();

				if (this.deliveryPointType === DeliveryPointType.SmartKeyBox.name) {
					const result = await Promise.all(this.deliveryPoint.boxes
						.filter((x) => x.boxType.indexOf('Clothes') < 0)
						.map(async (b) => this.$service.smartBox.getDetail(b.serialNumber)));
					this.smartBoxInfos = result.filter((x) => x);
				} else {
					this.smartBoxInfos = [];
				}
			}
			this.loading = false;
		},
		checkDeliveryPointForm() {
			if (this.deliveryPointType !== this.deliveryPoint.type) {
				let nameToPush;
				switch (this.deliveryPoint.type) {
					case DeliveryPointType.PenguinBox.name:
						nameToPush = 'container-edit';
						break;
					case DeliveryPointType.Store.name:
						nameToPush = 'pickup-place-edit';
						break;
					case DeliveryPointType.SmartKeyBox.name:
						nameToPush = 'smart-box-edit';
						break;
					default:
						nameToPush = 'container-list';
				}
				this.$router.push({ name: nameToPush, params: { id: this.deliveryPointId } });
			}
		},
	},
};
</script>

<style>
.note {
	font-size: 80%;
}
</style>
