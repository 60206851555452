<template>
	<div class="user">
		<div class="info">
			<a
				data-toggle="collapse"
				:aria-expanded="!isClosed"
				href="#"
				@click.prevent="toggleMenu">
				<div style="display: flex; flex-wrap: nowrap; align-items: center; justify-content: space-between; width: 230px;">
					<div class="photo">
						<img src="/static/img/faces/user.svg" alt="user avatar">
					</div>
					<div class="user-name">
						{{ currentUserFullName }}
					</div>
					<b class="caret" />
				</div>
			</a>
		</div>
		<div class="clearfix" />
		<div>
			<collapse-transition>
				<ul v-show="!isClosed" class="nav nav-menu">
					<li v-for="link in links" :key="link.name" :class="{ active: isActive({ name: link.name }) }">
						<a href="#" @click.prevent="(link.action && link.action({ name: link.name })) || redirect({ name: link.name })">
							<span class="sidebar-mini-icon">{{ link.display[0].toUpperCase() }}</span>
							<span class="sidebar-normal">{{ link.display }}</span>
						</a>
					</li>
				</ul>
			</collapse-transition>
		</div>
	</div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';
import VueRouter from 'vue-router';
import { isNavigationFailure } from 'vue-router/src/util/errors';

export default {
	name: 'TheUserMenu',
	components: {
		CollapseTransition,
	},
	data() {
		return {
			isClosed: true,
			links: [...this.$getConst('sidebarUserLinks'), { name: 'login', display: 'Odhlásit', action: this.logoutAndRedirect }],
		};
	},
	computed: {
		currentAppName() {
			return this.$getConst('appName');
		},
		currentUserFullName() {
			return this.$store.getters[`${this.currentAppName}/currentUserFullName`];
		},
	},
	mounted() {
		this.isClosed = this.links.every((link) => !this.isActive(link));
	},
	methods: {
		toggleMenu() {
			this.isClosed = !this.isClosed;
		},

		isActive(link) {
			if (link.name !== 'login' && this.$route) {
				const props = this.$router.resolve(link);

				if (props.route.name === 'eshop-user-reservations'
					|| this.$route.name === 'eshop-user-reservations') {
					return this.$route.name === props.route.name;
				}
				return props.route.name.split('-')[0] === this.$route.name.split('-')[0];
			}
			return false;
		},

		redirect(location) {
			this.$router.push(location).catch((error) => {
				if (!isNavigationFailure(error, VueRouter.NavigationFailureType.duplicated)) {
					throw error;
				}
			});
		},

		async logoutAndRedirect() {
			await this.$store.dispatch(`${this.currentAppName}/logout`);

			this.$router.replace('login').catch((error) => {
				if (!isNavigationFailure(error, VueRouter.NavigationFailureType.redirected)) {
					throw error;
				}
			});
		},
	},
};
</script>

<style scoped>
.nav.nav-menu {
	margin-top: 0;
}
.info a div.user-name {
	font-size: 12px;
	max-width: 160px;
	word-wrap: break-word;
	white-space: pre-wrap;
	flex-grow: 1;
}

.sidebar-mini-icon {
	margin-right: 10px;
}
</style>
