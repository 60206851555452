<template>
	<table-card
		:pages-count="deliveryPointsPage.pagesCount"
		:current-page="deliveryPointsPage.currentPage"
		:page-size="pageSize"
		@go-to-page="goToPage"
		@load-data-page-size="loadDataPageSize">
		<template #filter>
			<div class="d-flex align-items-center">
				<div class="col">
					<div class="row">
						<div class="col-md-6">
							<form-group-input
								v-model="filter.serialNumber.from"
								:label="$t('admin.serialNumberFrom')"
								type="number" />
						</div>
						<div class="col-md-6">
							<form-group-input
								v-model="filter.serialNumber.to"
								:label="$t('admin.serialNumberTo')"
								type="number" />
						</div>
					</div>
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.state"
						:label="$t('admin.country')"
						trigger-on-focus
						search-field-name="States"
						@fetch-suggestions="querySearchAsync"
						@select="fetchFirstPage" />
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.region"
						:label="$t('admin.region')"
						trigger-on-focus
						search-field-name="Regions"
						@fetch-suggestions="querySearchAsync"
						@select="fetchFirstPage" />
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.county"
						:label="$t('admin.county')"
						trigger-on-focus
						search-field-name="Counties"
						@fetch-suggestions="querySearchAsync"
						@select="fetchFirstPage" />
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.city"
						:label="$t('admin.city')"
						trigger-on-focus
						search-field-name="Cities"
						@fetch-suggestions="querySearchAsync"
						@select="fetchFirstPage" />
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.street"
						:label="$t('admin.street')"
						trigger-on-focus
						search-field-name="Streets"
						@fetch-suggestions="querySearchAsync"
						@select="fetchFirstPage" />
				</div>
				<div class="col">
					<form-group-input
						v-model="filter.location"
						:label="$t('admin.location')"
						type="text" />
				</div>
				<div class="col">
					<form-group-input
						v-model="filter.czechPostZip"
						:label="$t('admin.czPostIdentifier')"
						type="text" />
				</div>
			</div>
			<div class="d-flex justify-content-start">
				<div class="col-2">
					<label>
						{{ $t('admin.containerType') }}
					</label>
					<el-select
						v-model="filter.type"
						:label="$t('admin.containerType')"
						size="large"
						class="w-100"
						:placeholder="$t('admin.containerType')"
						value-key="ordinal"
						clearable
						multiple>
						<el-option
							v-for="item in containerTypes"
							:key="item.value"
							:value="item.value"
							:label="item.label" />
					</el-select>
				</div>
				<div class="col-6 flex-filter">
					<form-group-input
						:label="$t('admin.state')"
						type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="filter.isOk"
								trigger-on-change-method
								:yes-text="$t('admin.ok')"
								:no-text="$t('admin.fault')"
								@on-change-method="fetchFirstPage" />
						</div>
					</form-group-input>
					<form-group-input
						:label="$t('admin.offline')"
						type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="filter.isOffline"
								trigger-on-change-method
								@on-change-method="fetchFirstPage" />
						</div>
					</form-group-input>
					<form-group-input
						:label="$t('admin.locked')"
						type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="filter.isLocked"
								no-icon="unlock"
								trigger-on-change-method
								yes-icon="lock"
								@on-change-method="fetchFirstPage" />
						</div>
					</form-group-input>
					<form-group-input
						:label="$t('admin.disabled')"
						type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="filter.isDisabled"
								trigger-on-change-method
								yes-icon="power-off"
								@on-change-method="fetchFirstPage" />
						</div>
					</form-group-input>
					<form-group-input
						:label="$t('admin.terminal')"
						type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="filter.hasPaymentTerminal"
								trigger-on-change-method
								yes-icon="cash-register"
								@on-change-method="fetchFirstPage" />
						</div>
					</form-group-input>
				</div>
				<div class="col-2">
					<label>
						{{ $t('admin.eshop') }}
					</label>
					<el-select
						v-model="filter.eshopId"
						:label="$t('admin.eshop')"
						size="large"
						class="w-100"
						:placeholder="$t('admin.eshop')"
						value-key="ordinal"
						clearable>
						<el-option
							v-for="item in eshops"
							:key="item.id"
							:value="item.id"
							:label="item.name" />
					</el-select>
				</div>
				<div class="col-2">
					<label>
						{{ $t('admin.eshopReservation') }}
					</label>
					<el-select
						v-model="filter.HasReservationForEshopId"
						:label="$t('admin.eshopReservation')"
						size="large"
						class="w-100"
						:placeholder="$t('admin.eshopReservation')"
						value-key="ordinal"
						clearable>
						<el-option
							v-for="item in eshops"
							:key="item.id"
							:value="item.id"
							:label="item.name" />
					</el-select>
				</div>
			</div>
			<div class="col-2 d-flex mb-1 justify-content-start">
				<p-button
					type="success"
					size="md"
					@click="fetchFirstPage">
					{{ $t('admin.search') }}
				</p-button>
				<el-tooltip
					:content="$t('admin.tooltips.clearFilter')"
					placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</template>
		<template #actionButtons>
			<p-button
				type="success"
				@click="newDeliveryPoint('container-new')">
				<span class="fa fa-plus" /> {{ $t('admin.penguinBox') }}
			</p-button>
			<p-button
				type="success"
				@click="newDeliveryPoint('smart-box-new')">
				<span class="fa fa-plus" /> {{ $t('admin.smartBox') }}
			</p-button>
			<p-button
				type="success"
				@click="newDeliveryPoint('pickup-place-new')">
				<span class="fa fa-plus" /> {{ $t('admin.pickupPlace') }}
			</p-button>
		</template>
		<delivery-point-table-definition
			:table-data="tableData"
			:filter="filter"
			@sort-change="sortChange"
			@go-to-edit="goToEdit" />
	</table-card>
</template>

<script>
import { mapState } from 'vuex';
import {
	Select as ElSelect, Option as ElOption, Tooltip as ElTooltip,
} from 'element-ui';

// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
import { resetFilter, setFilter } from '@/store/mutation-types';
import { containerTypes as cType } from '@/globalConstants';
import TableCard from '@/components/UIComponents/TableCard/TableCard.vue';

import DeliveryPointTableDefinition from './ListParts/DeliveryPointTableDefinition.vue';

export default {
	name: 'DeliveryPointList',
	components: {
		ElTooltip,
		ElSelect,
		ElOption,
		TableCard,
		DeliveryPointTableDefinition,
	},
	data() {
		return {
			deliveryPointsPage: Object,
			loading: false,
			eshops: [],
			filter: {
				serialNumber: {},
				eshopId: null,
			},
			containerTypes: [
				{
					label: 'Penguin Box',
					value: 0,
				},
				{
					label: 'Výdejna',
					value: 1,
				}, {
					label: 'Smart Box',
					value: 2,
				},
			],
			abortController: null,
			pageSize: 50,
		};
	},
	computed: {
		...mapState(appName, ['deliveryPointFilter']),
		tableData() {
			return this.deliveryPointsPage.entities;
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'deliveryPointFilter', data: val });
			},
		},
	},
	async created() {
		this.filter = this.deliveryPointFilter;
		if ((this.$route.params.filterContainerType) || (this.$route.params.filterContainerType === 0)) {
			this.filter.type = [this.$route.params.filterContainerType];
		}
		this.getDeliveryPointList();
		this.eshops = (await this.$service.eshop.getList({ hasAssignedBox: true })).entities;
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.getDeliveryPointList();
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderby = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderby = null;
				this.filter.direction = null;
			}
			this.getDeliveryPointList();
		},
		newDeliveryPoint(routerName) {
			this.$router.push({ name: routerName });
		},
		goToEdit(row) {
			let nameToPush;

			if (row.type === cType.penguinBox) {
				nameToPush = 'container-edit';
			} else if (row.type === cType.store) {
				nameToPush = 'pickup-place-edit';
			} else {
				nameToPush = 'smart-box-edit';
			}
			this.$router.push({ name: nameToPush, params: { id: row.containerId } });
		},
		fetchFirstPage() {
			this.goToPage(1);
		},
		goToPage(page) {
			this.page = page;
			this.getDeliveryPointList();
		},
		loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			this.goToPage(1);
		},
		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'deliveryPointFilter');
			this.filter = this.deliveryPointFilter;
			this.filter.type = [];
			this.getDeliveryPointList();
		},
		async getDeliveryPointList() {
			this.loading = true;
			this.deliveryPointsPage = await this.$service.deliveryPoint.getList(this.filter, this.page, this.pageSize);
			this.loading = false;
		},
		querySearchAsync(input, cb, searchFieldName) {
			this.abortController?.abort();
			this.abortController = new AbortController();
			this.$service.deliveryPoint.searchByLocationInput(input, searchFieldName, this.filter, this.abortController)
				.then((results) => {
					cb(results);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}

.el-table {
	::v-deep .tableRowOffline {
		cursor: pointer;
		background-color: #ffb3b3 !important;
	}

	::v-deep .el-table__row:nth-child(odd) {
		background-color: #f0f0f0;
	}
}

.flex-filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
</style>
