import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import { Loading } from 'element-ui';
import { Validator } from 'vee-validate';
import lang from 'element-ui/lib/locale/lang/cs-CZ';
import locale from 'element-ui/lib/locale';
import veeLang from 'vee-validate/dist/locale/cs';

import validationConfigure from '@/validationConfigure';
import store from '@/store';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import BaseMixins from '@/util/base-mixins';

import { i18n } from './i18n';
// Plugins
import Filters from './filters';
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import initProgress from './progressbar';
// library imports
import './assets/sass/paper-dashboard.scss';
import './registerServiceWorker';
import App from './App.vue';

Vue.use(Vuex);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(Filters);
Vue.use(Loading.directive);
Vue.use(VueRouter);
Vue.mixin(BaseMixins);
veeLang.messages.email = (inputName) => `Pole "${inputName}" musí být validní e-mailová adresa.`;

Validator.localize('cs', veeLang);
lang.el.pagination.pagesize = '';
locale.use(lang);

validationConfigure();

initProgress(router);

const vue = new Vue({
	el: '#app',
	name: 'Penguin',
	render: (h) => h(App),
	i18n,
	router,
	store,
});

export default vue;
