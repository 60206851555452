<template>
	<div v-loading="loading">
		<div v-if="eshopOrder.eshopOrderId" class="row">
			<div class="col-md-7">
				<div class="row">
					<div class="col-12">
						<card>
							<div class="card-body">
								<h2 class="card-title">
									{{ $t('eshop.orderDetail') }} {{ eshopOrder.code }}
								</h2>
								<form-group-input :label="$t('eshop.firstName')" :value="eshopOrder.name" readonly />
								<form-group-input :label="$t('eshop.phone')" :value="eshopOrder.phone | phone" readonly />
								<form-group-input :label="$t('eshop.email')" :value="eshopOrder.email" readonly />
								<form-group-input
									v-if="eshopOrder.requiredPayment"
									:label="$t('eshop.cashOnDelivery')"
									:value="eshopOrder.requiredPayment"
									readonly />
								<form-group-input :label="$t('eshop.country')" :value="eshopOrder.country" readonly />
								<form-group-input :label="$t('eshop.city')" :value="eshopOrder.city" readonly />
								<form-group-input :label="$t('eshop.street')" :value="eshopOrder.street" readonly />
								<form-group-input :label="$t('eshop.zip')" :value="eshopOrder.zip" readonly />
								<form-group-input :label="$t('eshop.customerReturnsGoods')" :value="eshopOrder.reqReturn ? $t('eshop.yes') : $t('eshop.no')" readonly />
								<form-group-input
									v-if="eshopOrder.amount"
									:label="$t('eshop.amount')"
									:value="eshopOrder.amount"
									readonly />
								<form-group-input
									v-if="eshopOrder.reqReturn"
									:label="$t('eshop.lengthOfReturnReservation')"
									:value="reqReturnHours"
									readonly />
								<form-group-input :label="$t('eshop.shoptetContainerIdentifier')" :value="eshopOrder.shoptetContainerIdentifier" readonly />
								<form-group-input :label="$t('eshop.created')" readonly>
									<date-picker
										v-model="eshopOrder.createdDate"
										format="dd.MM.yyyy HH:mm"
										prefix-icon="el-icon-date"
										readonly
										type="datetime" />
								</form-group-input>
								<form-group-input :label="$t('eshop.orderDate')" readonly>
									<date-picker
										v-model="eshopOrder.orderDate"
										format="dd.MM.yyyy HH:mm"
										prefix-icon="el-icon-date"
										readonly
										type="datetime" />
								</form-group-input>
							</div>
						</card>
					</div>
					<div class="col-12">
						<card>
							<h2 class="card-title">
								{{ `${$t('eshop.autoReservation')} - ${activeAutoCreateReservation}` }}
							</h2>
							<div class="row">
								<div class="col-6">
									<label class="d-block">{{ $t('eshop.minimalBoxSize') }}</label>
									<el-select v-model="selectedBoxSize" class="select-default w-100" :disabled="eshopOrder.autoCreateReservation">
										<el-option
											v-for="box in boxSizes"
											:key="box"
											:value="box"
											:label="translateBox(box)" />
									</el-select>
								</div>
								<div class="col-3">
									<p-button
										:type="eshopOrder.autoCreateReservation ? 'danger' : 'success'"
										:disabled="!selectedBoxSize && !eshopOrder.autoCreateReservation"
										class="mt-4"
										@click="SetAutoReservation">
										{{ eshopOrder.autoCreateReservation ? $t('eshop.remove') : $t('eshop.add') }}
									</p-button>
								</div>
								<div class="col-3">
									<p-button :type="eshopOrder.sendByAnotherCourier ? 'danger' : 'success'" class="mt-4" @click="SetAnotherCourier">
										{{ eshopOrder.sendByAnotherCourier ? $t('eshop.setMyCourier') : $t('eshop.setAsAnotherCourier') }}
									</p-button>
								</div>
							</div>
						</card>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="row">
					<div class="col-12">
						<container-info v-if="eshopOrder.container" :reservation="reservation" @eshop-order-changed="eshopOrderChanged" />
					</div>
					<div class="col-12">
						<reservation-sticker v-if="eshopOrder.container" :reservation="printData" show-sticker />
					</div>
				</div>
			</div>
		</div>
		<div v-if="eshopOrder.eshopOrderId" class="row">
			<div class="col-md-12">
				<card>
					<div class="card-body">
						<h2 class="card-title">
							{{ $t('eshop.products') }}
						</h2>
						<el-table class="table-striped mt-2" :data="eshopOrder.items">
							<el-table-column property="amount" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.amount')" placement="top-start">
										<span>{{ $t('eshop.amount') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column :min-width="200" property="eshopName" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.product')" placement="top-start">
										<span>{{ $t('eshop.product') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="eshopCode" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.code')" placement="top-start">
										<span>{{ $t('eshop.code') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</card>
			</div>
		</div>
		<div v-if="eshopOrder.eshopOrderId" class="row">
			<div class="col-md-12">
				<card>
					<div class="card-body">
						<h2 class="card-title">
							{{ $t('eshop.reservation') }}
						</h2>
						<p-button type="success" @click.stop="addReservation()">
							<i slot="labelRight" class="nc-icon nc-simple-add" />{{ $t('eshop.add') }}
						</p-button>
						<el-table class="table-striped" :data="eshopOrder.reservations">
							<el-table-column property="reservationNumber" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.reservationNumber')" placement="top-start">
										<span>{{ $t('eshop.reservationNumber') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="status" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.state')" placement="top-start">
										<span>{{ $t('eshop.state') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.orderDateTo')" placement="top-start">
										<span>{{ $t('eshop.orderDateTo') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.prepaidTo | datetime }}
								</template>
							</el-table-column>
							<el-table-column property="status" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.phone')" placement="top-start">
										<span>{{ $t('eshop.phone') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.phone | phone }}
								</template>
							</el-table-column>
							<el-table-column property="price" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.price')" placement="top-start">
										<span>{{ $t('eshop.price') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="unlockCode" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.unlockCode')" placement="top-start">
										<span>{{ $t('eshop.unlockCode') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column align="center">
								<template #default="scope">
									<router-link :to="{ name: 'reservation-detail', params: { id: scope.row.reservationId } }">
										<p-button type="success">
											{{ $t('eshop.detail') }}
										</p-button>
									</router-link>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</card>
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip, DatePicker, Select as ElSelect, Option as ElOption } from 'element-ui';
import _ from 'lodash';
import moment from 'moment';

import ReservationSticker from '../Reservation/ReservationDetails/ReservationSticker.vue';
import ContainerInfo from '../Reservation/ReservationDetails/ContainerInfo.vue';
import { BoxType } from '../../store/enums';
import FormGroupInput from '../../../../components/UIComponents/Inputs/FormGroupInput.vue';

export default {
	name: 'EshopOrderDetail',
	components: {
		ElTable,
		ElTableColumn,
		ContainerInfo,
		ElTooltip,
		DatePicker,
		ElSelect,
		ElOption,
		ReservationSticker,
		FormGroupInput,
	},
	data() {
		return {
			loading: true,
			eshopOrder: {},
			selectedBoxSize: null,
			boxSizes: null,
			reqReturnHours: null,
			defaultHours: 24,
			eshop: {},
		};
	},
	computed: {
		reservation() {
			return {
				eshopOrderId: this.eshopOrder.eshopOrderId,
				containerSerialNumber: this.eshopOrder.container.serialNumber,
				state: this.eshopOrder.container.state,
				city: this.eshopOrder.container.city,
				street: this.eshopOrder.container.street,
				location: this.eshopOrder.container.location,
			};
		},
		printData() {
			const order = this.eshopOrder;
			if (!_.isEmpty(order)) {
				return {
					phone: order.phone,
					email: order.email,
					latitude: order.container.latitude,
					longitude: order.container.longitude,
					street: order.container.street,
					city: order.container.city,
					state: order.container.state,
					location: order.container.location,
					region: order.container.region,
					county: order.container.county,
					customer: {
						fullName: order.name,
						phone: order.phone,
						email: order.email,
						street: order.street,
						city: order.city,
						zip: order.zip,
						state: order.container.state,
					},
					reservationNumber: order.code,
					reservationId: null,
					eshopOrderId: order.eshopOrderId,
					deliveryCode: order.pregeneratedPin,
					containerSerialNumber: order.container.serialNumber,
					type: order.minimalBoxSize,
					packagesCount: 1,
					containerType: order.container.type,
					containerId: order.container.containerId,
					eshop: this.eshop,
				};
			}
			return null;
		},
		eshopOrderId() {
			return this.eshopOrder.eshopOrderId;
		},
		activeAutoCreateReservation() {
			return this.eshopOrder.autoCreateReservation ? this.$t('eshop.active') : this.$t('eshop.inactive');
		},
	},

	async created() {
		this.boxSizes = await this.$service.box.getTypes();
		this.eshop = await this.$service.eshop.currentEshop();
		this.getOne();
	},
	methods: {
		translateBox(box) {
			const boxObj = BoxType.enumValues.find((v) => v.name === box);
			if (boxObj) {
				return boxObj.czech();
			}
			return box;
		},
		async getOne() {
			this.loading = true;
			this.eshopOrder = await this.$service.eshopOrder.getOne(this.$route.params.id);
			if (this.eshopOrder && this.eshopOrder.reqReturn) {
				this.reqReturnHours = this.eshopOrder.reqReturnMinutes > 0 ? +parseFloat(this.eshopOrder.reqReturnMinutes / 60).toFixed(2) : this.defaultHours;
			}
			this.eshopOrder.createdDate = moment.utc(String(this.eshopOrder.createdDate)).local();
			this.eshopOrder.orderDate = moment.utc(String(this.eshopOrder.orderDate)).local();
			this.selectedBoxSize = this.eshopOrder.minimalBoxSize;
			this.loading = false;
		},

		addReservation() {
			this.$router.push({ name: 'box-order-shoptet', params: { shoptet: this.eshopOrder } });
		},

		async SetAutoReservation() {
			this.loading = true;
			await this.$service.eshopOrder.SetAutoReservation(this.eshopOrderId, !this.eshopOrder.autoCreateReservation, this.selectedBoxSize);
			await this.getOne();
		},
		async SetAnotherCourier() {
			this.loading = true;
			await this.$service.eshopOrder.SetAnotherCourier(this.eshopOrderId, !this.eshopOrder.sendByAnotherCourier, this.selectedBoxSize);
			await this.getOne();
		},
		eshopOrderChanged(changedEshopOrder) {
			this.eshopOrder = changedEshopOrder;
		},
	},
};
</script>
