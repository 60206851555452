<template>
	<card>
		<div v-if="containersToShow" class="px-0 py-2 overflow-auto">
			<base-report-print-sheet-table>
				<tr>
					<td colspan="11" class="font-weight-bold">
						{{ $t('logistics.postsCount') }}
					</td>
				</tr>
				<tr>
					<td>{{ $t('logistics.routeDate') }}</td>
					<td>{{ $t('logistics.citiesInTheReport') }}</td>
					<td>{{ $t('logistics.eshopToPBSB') }}</td>
					<td>{{ $t('logistics.eshopToPickUp') }}</td>
					<td>{{ $t('logistics.eshopToAddress') }}</td>
					<td>{{ $t('logistics.packagesReturn') }}</td>
					<td>{{ $t('logistics.packagesExpired') }}</td>
					<td>{{ $t('logistics.possibilitiesPost') }}</td>
					<td>{{ $t('logistics.packagesDirectDelivery') }}</td>
					<td>{{ $t('logistics.bateryReplace') }}</td>
				</tr>
				<tr class="align-top">
					<td>{{ summary.date }}</td>
					<td>{{ summary.cities }}</td>
					<td class="text-center">
						{{ summary.containers }}
					</td>
					<td class="text-center">
						{{ summary.pickUp }}
					</td>
					<td class="text-center">
						{{ summary.privateAddress }}
					</td>
					<td class="text-center">
						{{ summary.returnOrder }}
					</td>
					<td class="text-center">
						{{ summary.expired }}
					</td>
					<td class="text-center">
						{{ summary.post }}
					</td>
					<td class="text-center">
						{{ summary.directDelivery }}
					</td>
					<td class="text-center" />
				</tr>
			</base-report-print-sheet-table>
		</div>
	</card>
</template>

<script>
import moment from 'moment';

import { BaseReportPrintSheetTable } from '@/components/Base';
import { containerTypes } from '@/globalConstants';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import { ReservationStatus } from '@/modules/driver/store/enums';

export default {
	name: 'LogisticSummary',
	components: {
		BaseReportPrintSheetTable,
	},
	mixins: [ReservationNameMixin],
	props: {
		containersToShow: {
			type: Array,
			default: () => [],
		},
		driver: {
			type: Object,
			default: () => {},
		},
		tripDate: {
			type: String,
			default: () => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			summary: {
				driver: null,
				date: null,
				cities: [],
				containers: 0,
				pickUp: 0,
				privateAddress: 0,
				returnOrder: 0,
				expired: 0,
				post: 0,
				directDelivery: 0,
				batteryDead: 0,
			},
		};
	},
	created() {
		this.createSummary();
	},
	methods: {
		createSummary() {
			this.$emit('set-loading', true);

			const { store, smartBox, penguinBox } = containerTypes;

			this.containersToShow.forEach((routeStop) => {
				if (routeStop.city && !this.summary.cities.includes(routeStop.city)) this.summary.cities.push(routeStop.city);
				if (routeStop.stockItemsCounts) this.summary.directDelivery += routeStop.stockItemsCounts.length;

				routeStop.reservations
					.filter(((r) => !r.unlockCode
					|| (r.calcToReward && r.status !== r.currentStatus && ReservationStatus.enumValueOf(r.currentStatus) === ReservationStatus.PREPARED)))
					.forEach((reservation) => {
						if (this.isExpired(reservation)) this.summary.expired += 1;
						else if (reservation.isPostPackage) this.summary.post += 1;
						else if (this.isEshopReturn(reservation)) this.summary.returnOrder += 1;
						else if (reservation.isPrivateAddress) this.summary.privateAddress += 1;
						else if (routeStop.containerType === store) this.summary.pickUp += 1;
						else if ([smartBox, penguinBox].includes(routeStop.containerType)) this.summary.containers += 1;
					});
			});

			this.summary.cities = this.summary.cities.join(', ');
			if (!this.summary.driver && this.driver) {
				this.summary.driver = `${this.driver.name} ${this.driver.surname}`;
			}
			this.summary.date = moment(this.tripDate).format('D.M.YYYY');

			this.$emit('set-loading', false);
		},
	},
};
</script>
