<template>
	<div class="card">
		<div class="card-body">
			<h2 class="card-title">
				Pokusy o vyzvednutí
			</h2>
			<div class="block">
				<p-pagination
					v-model="pickupAttempts.currentPage"
					:page-count="pickupAttempts.pagesCount"
					layout="prev,pager,next"
					@input="reloadPickUpAttempts" />
			</div>
			<el-table
				:data="pickupAttempts.entities"
				:row-class-name="tableRowClassName"
				class="table-striped"
				@row-click="goToReservation">
				<el-table-column property="status">
					<template #header>
						<el-tooltip :content="$t('admin.state')" placement="top-start">
							<span>{{ $t('admin.state') }}</span>
						</el-tooltip>
					</template>
				</el-table-column>

				<el-table-column property="boxSN">
					<template #header>
						<el-tooltip :content="$t('admin.boxSN')" placement="top-start">
							<span>{{ $t('admin.boxSN') }}</span>
						</el-tooltip>
					</template>
				</el-table-column>

				<el-table-column>
					<template #header>
						<el-tooltip :content="$t('admin.date')" placement="top-start">
							<span>{{ $t('admin.date') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ scope.row.date | datetime }}
					</template>
				</el-table-column>
				<el-table-column>
					<template #header>
						<el-tooltip :content="$t('admin.reservationState')" placement="top-start">
							<span>{{ $t('admin.reservationState') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ getStatusTooltip(scope.row.reservationStatusInTime) }}
					</template>
				</el-table-column>
				<el-table-column property="unlockCode">
					<template #header>
						<el-tooltip :content="$t('admin.PIN')" placement="top-start">
							<span>{{ $t('admin.PIN') }}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column property="done">
					<template #header>
						<el-tooltip :content="$t('admin.ack')" placement="top-start">
							<span>{{ $t('admin.ackConfirmed') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<i v-if="scope.row.done" class="fas fa-check" />
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip } from 'element-ui';

import PPagination from '@/components/UIComponents/PPagination.vue';
import { ReservationStatus } from '@/modules/admin/store/enums';

export default {
	name: 'DeliveryPointPickUpAttemptsCard',
	components: {
		PPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	props: {
		deliveryPointId: { type: Number, default: null },
	},
	data() {
		return {
			pickupAttempts: {},
		};
	},

	async created() {
		await this.reloadPickUpAttempts(1);
	},
	methods: {
		async reloadPickUpAttempts(pageNumber) {
			this.pickupAttempts = await this.$service.log.getDeliveryPointPickupAttempts(pageNumber, this.deliveryPointId);
		},
		goToReservation(row) {
			if (row.reservationId) {
				this.$router.push({ name: 'reservation-detail', params: { id: row.reservationId } });
			}
		},
		getStatusTooltip(status) {
			return ReservationStatus.czech(status);
		},
		tableRowClassName(row) {
			return row.row.reservationId ? 'tableRowWithReservation' : 'tableRowWithoutReservation';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/paper/_variables.scss';
.el-table {
	::v-deep .tableRowWithoutReservation {
		background: $bg-warning;
	}
	::v-deep .tableRowWithReservation {
		cursor: pointer;
	}
}
</style>
