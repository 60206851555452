<template>
	<div class="row">
		<div v-loading="loading" class="col-sm-12">
			<card>
				<div class="row">
					<div class="col-12">
						<form-group-input
							v-model="name"
							:label="$t('logistics.name')"
							required
							type="text" />
					</div>
					<div class="col-12 mt-1">
						<label>{{ $t('logistics.note') }}</label>
						<el-input v-model="note" type="textarea" autosize />
					</div>
					<div class="col-12 mt-3">
						<label>{{ $t('logistics.containers') }}</label>
						<span class="text-danger"> *</span>
						<containers-select-area
							v-if="!loading"
							:key="routeId"
							v-model="selectedContainers"
							:allow-service="allowService"
							@set-allow-service="setAllowService"
							@tree-loading="setTreeLoading" />
					</div>
					<div class="col-12 mt-3">
						<label>{{ $t('logistics.driver') }}</label>
						<div class="d-flex">
							<el-select v-model="selectedDriver" filterable @change="updateRouteName">
								<el-option
									v-for="driver in driversList"
									:key="driver.id"
									:label="`${driver.surname} ${driver.name}`"
									:value="driver.id" />
							</el-select>
						</div>
						<form-group-input :label="$t('logistics.tripDate')" type="text">
							<div class="d-flex">
								<date-picker
									v-if="!tripToday"
									v-model="tripDate"
									class="datePicker mr-3"
									type="date"
									:picker-options="datePickerOptions"
									format="dd.MM.yyyy"
									@change="updateRouteName" />
								<p-checkbox v-model="tripToday" class="mt-2">
									{{ $t('logistics.sendTheDay') }}
								</p-checkbox>
							</div>
						</form-group-input>
					</div>
					<div class="col-12 mt-4 d-flex align-items-center">
						<p-button v-if="!editing" :disabled="addBtnDisabled" @click="addNewRoute">
							{{ $t('logistics.addRoute') }}
						</p-button>
						<confirm-button
							v-else
							:disabled="addBtnDisabled"
							:confirm-message="$t('logistics.confirmChanges')"
							:show-success-message="false"
							:callback="editRoute">
							{{ $t('logistics.editRoute') }}
						</confirm-button>
					</div>
				</div>
			</card>
		</div>
	</div>
</template>

<script>
import { DatePicker, Select as ElSelect, Option as ElOption, Input as ElInput } from 'element-ui';
import moment from 'moment';

// eslint-disable-next-line import/no-cycle
import logisticsRouteSharedService from '@/service/logistics-route-shared-service';
import notifyService from '@/service/notify-service';
import ContainersSelectArea from '@/modules/logistics/views/NewRoute/ContainersSelectArea.vue';
import FormMixin from '@/util/form-mixin';
import { appName } from '@/modules/logistics/config';
import { formChanged } from '@/store/mutation-types';

export default {
	name: 'NewRoute',
	components: {
		DatePicker,
		ElSelect,
		ElOption,
		ElInput,
		ContainersSelectArea,
	},
	mixins: [FormMixin],
	data() {
		return {
			datePickerOptions: {
				disabledDate(date) {
					return date < new Date();
				},
			},
			tripDate: new Date(),
			tripToday: false,
			loading: false,
			treeLoading: false,
			editing: false,
			name: null,
			allowService: true,
			note: null,
			isActive: null,
			selectedContainers: [],
			selectedDriver: null,
			driversList: [],
			route: {},
		};
	},
	computed: {
		addBtnDisabled() {
			return !this.name || !this.selectedContainers.length > 0 || this.loading || this.treeLoading || !this.tripDate;
		},
		routeId() {
			const id = Number.parseInt(this.$route.params.id, 10);
			if (Number.isNaN(id)) {
				return 0;
			}
			return id;
		},
	},
	watch: {
		routeId: {
			handler() {
				this.name = null;
				this.note = null;
				this.selectedContainers.splice(0, this.selectedContainers.length);
				this.selectedDriver = null;
				this.$store.commit(`${appName}/${formChanged}`, true);
			},
		},
		tripToday: {
			handler() {
				this.tripDate = this.todayTrip();
				this.updateRouteName();
			},
		},
	},
	async created() {
		this.loading = true;
		this.editing = false;

		this.tripDate.setUTCHours(0);
		this.tripDate.setUTCMinutes(0);
		this.tripDate.setUTCSeconds(0);
		this.tripDate.setUTCDate(this.tripDate.getDate() + 1);

		const [employees, logistics] = await Promise.allSettled([
			this.$service.list.getDriversList({ locked: false }, 1, 1000),
			this.$service.list.getLogisticsList({ locked: false }, 1, 1000),
		]);

		this.driversList = employees.value.entities.sort(this.sortDrivers);

		logistics.value.entities.sort(this.sortDrivers).forEach((logistic) => {
			if (!this.driversList.find((driver) => driver.id === logistic.id)) {
				this.driversList.push(logistic);
			}
		});

		if (this.routeId) {
			this.editing = true;
			this.route = await logisticsRouteSharedService.getReport(this.routeId);
			this.setRoute();
		}
		this.loading = false;
	},
	methods: {
		sortDrivers(driverA, driverB) {
			let cmpResult = driverA.surname?.localeCompare(driverB.surname);
			if (!cmpResult) {
				cmpResult = driverA.name?.localeCompare(driverB.name);
			}
			return cmpResult;
		},
		setAllowService(allowService) {
			this.allowService = allowService;
		},
		setTreeLoading(loading) {
			this.treeLoading = loading;
		},
		async editRoute() {
			this.loading = true;
			try {
				const routeParams = {
					name: this.name,
					note: this.note,
					tripDate: this.tripDate,
					routeStops: this.selectedContainers.map((cont) => ({
						containerId: cont.containerId,
						reservationId: Number(cont.reservationId),
					})),
					driverId: this.selectedDriver,
					allowService: this.allowService,
					isActive: this.isActive,
					logisticRouteId: this.routeId,
				};
				this.route = await this.$service.logisticsRoute.update(routeParams);
				this.setRoute();
				notifyService.notifySuccess(this.$t('admin.routeChanged'));
				this.$store.commit(`${appName}/${formChanged}`, false);
				this.$router.push({
					name: 'report-detail',
					params: { id: this.routeId },
				});
			} catch (e) {
				notifyService.notifyError(this.$t('admin.updateError'));
			} finally {
				this.loading = false;
			}
		},
		todayTrip() {
			const todayTrip = new Date();
			todayTrip.setUTCHours(0);
			todayTrip.setUTCMinutes(0);
			todayTrip.setUTCSeconds(0);

			return todayTrip;
		},
		async addNewRoute() {
			this.loading = true;
			try {
				if (this.tripToday) {
					this.tripDate = this.todayTrip();
				}
				const routeParams = {
					name: this.name,
					note: this.note,
					tripDate: this.tripDate,
					routeStops: this.selectedContainers.map((cont) => ({
						containerId: cont.containerId,
						reservationId: Number(cont.reservationId),
					})),
					driverId: this.selectedDriver,
					allowService: this.allowService,
				};
				const reportData = await this.$service.logisticsRoute.add(routeParams);
				notifyService.notifySuccess(this.$t('admin.routeCreated'));
				this.$router.push({ name: 'report-detail', params: { id: reportData.logisticRouteId } });
			} catch (error) {
				notifyService.notifyError(this.$t('admin.routeCreatedError'));
			} finally {
				this.loading = false;
			}
		},
		setRoute() {
			const { name, note, allowService, isActive, driver, routeStops } = this.route;

			this.name = name;
			this.note = note;
			this.allowService = allowService;
			this.isActive = isActive;
			this.selectedDriver = driver ? driver.id : null;
			this.selectedContainers = routeStops.flatMap((container, index) => {
				const { containerId, reservations, region, county, city, street, location } = container;
				if (containerId) {
					return {
						routeStopId: containerId,
						containerId,
						region,
						county,
						city,
						street,
						location,
						order: index + 1,
					};
				}
				const privateAddressReservations = [];
				reservations.forEach((reservation) => {
					if (reservation.isPrivateAddress) {
						privateAddressReservations.push({
							routeStopId: `r${reservation.reservationId}`,
							reservationId: reservation.reservationId,
							region,
							county,
							city,
							street,
							location,
							order: index + 1,
						});
					}
				});
				return privateAddressReservations;
			});
		},
		updateRouteName() {
			let driverName = this.selectedDriver;
			const driver = this.driversList.find((d) => d.id === this.selectedDriver);
			if (driver) {
				driverName = `${driver.surname} ${driver.name}`;
				const dateString = moment(this.tripDate).format('D.M.YYYY');
				const week = moment(this.tripDate).format('W');
				this.name = `${driverName} ${dateString} (${week}.t)`;
			}
		},
	},
};
</script>

<style scoped>
.datePicker {
	max-width: 220px;
}
</style>
