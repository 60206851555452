<template>
	<div class="card">
		<div class="card-body">
			<h2 class="card-title">
				{{ $t('admin.openingHours') }}
			</h2>
			<el-table :data="openingHours" class="table-striped">
				<el-table-column property="day" width="100">
					<template #default="scope">
						<span v-if="!scope.row.date">{{ Weekday.czech(scope.row.day) }}</span>
						<span v-else>{{ scope.row.date | date }}</span>
					</template>
				</el-table-column>
				<el-table-column property="openTime" width="100">
					<template #default="scope">
						<span v-if="OpeningHoursStatus.enumValueOf(scope.row.status) === OpeningHoursStatus.Open">
							<span v-if="scope.row.openTime">{{ scope.row.openTime }} - {{ scope.row.closeTime }}</span>
						</span>
						<span v-else>
							{{ OpeningHoursStatus.czech(scope.row.status) }}
						</span>
					</template>
				</el-table-column>
				<el-table-column property="openTimeNext" width="100">
					<template #default="scope">
						<span v-if="scope.row.openTimeNext">{{ scope.row.openTimeNext }} - {{ scope.row.closeTimeNext }}</span>
					</template>
				</el-table-column>
				<el-table-column property="description" />
			</el-table>
		</div>
		<div class="card-footer">
			<div class="mt-2">
				<router-link :to="{ name: 'delivery-point-opening-hours', id: deliveryPointId }">
					<p-button type="success" size="md" class="align-self-center">
						{{ $t('admin.editOpeningHours') }}
					</p-button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn } from 'element-ui';
import moment from 'moment';

import { Weekday, OpeningHoursStatus } from '@/modules/admin/store/enums';

export default {
	name: 'DeliveryPointOpeningHoursCard',
	components: {
		ElTable,
		ElTableColumn,
	},
	props: {
		deliveryPointId: { type: Number, default: null },
	},
	data() {
		return {
			openingHours: [],
			Weekday,
			OpeningHoursStatus,
		};
	},

	async created() {
		const openingHoursStart = moment().startOf('day').utc(true).toDate();
		const openingHoursEnd = moment().endOf('day').add(1, 'y').utc(true)
			.toDate();
		this.openingHours = await this.$service.deliveryPoint.getOpeningHours(this.deliveryPointId, openingHoursStart, openingHoursEnd);
	},
};
</script>
