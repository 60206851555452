<template>
	<account-form-wrap
		v-loading="loading"
		:action-title="$t('global.logon')"
		:title="$t('global.login')"
		@form-submit="login">
		<form-group-input
			v-model="form.username"
			:placeholder="$t('global.username')"
			addon-left-icon="nc-icon nc-single-02"
			name="loginName" />

		<form-group-input
			v-model="form.password"
			addon-left-icon="nc-icon nc-key-25"
			type="password"
			:placeholder="$t('global.password')"
			:error="loginError ? loginError.message : null"
			name="password" />

		<router-link :to="{ name: 'forgot-password' }">
			{{ $t('global.passwordForgotten') }}
			<span class="fa fa-angle-double-right" />
		</router-link>
	</account-form-wrap>
</template>

<script>
import notifyService from '@/service/notify-service';
import AccountFormWrap from '@/components/UIComponents/NotLoggedIn/AccountFormWrap.vue';

export default {
	name: 'BaseLogin',
	components: {
		AccountFormWrap,
	},
	props: {
		authenticate: {
			type: Function,
			required: true,
		},
		logout: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			form: {
				username: '',
				password: '',
			},
		};
	},
	computed: {
		loginError() {
			return this.$store.state[this.currentAppName]?.loginError;
		},
		homePage() {
			return this.$store.state[this.currentAppName]?.homePage;
		},
	},
	methods: {
		async login() {
			this.loading = true;

			this.logout();

			await this.authenticate(this.form);

			if (!this.loginError) {
				const { from } = this.$route.params;

				if (from && !/login$/.test(from)) {
					this.$router.push({ path: from }).catch((err) => {
						console.error(err);
						this.$router.push({ name: this.homePage });
					});
				} else {
					this.$router.push({ name: this.homePage });
				}
			} else {
				notifyService.notifyError(this.loginError.message);
			}

			this.loading = false;
		},
	},
};
</script>
