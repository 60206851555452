<template>
	<div class="login-page">
		<div class="wrapper wrapper-full-page">
			<div class="full-page login-page section-image">
				<div class="content">
					<div class="container">
						<div class="col-lg-4 col-md-6 ml-auto mr-auto">
							<info-section
								class="mt-5"
								type="danger"
								title="Marketing"
								description="We've created the marketing campaign of the website. It was a very interesting collaboration."
								icon="nc-icon nc-alert-circle-i">
								<h2 class="text-white mt-3 mb-1 heading-style">
									Stránka nenalezena
								</h2>
								<small class="text-white">Jejda! Vypadá to, že požadovaná stránka neexistuje.
									<br>
									Kliknutím na <router-link :to="homepageUrl">odkaz</router-link> se můžete vrátit na domovskou stránku.
								</small>
							</info-section>
						</div>
					</div>
				</div>
				<div class="full-page-background" style="background-image: url(/static/img/background/gradient.svg)" />
			</div>
		</div>
	</div>
</template>

<script>
import { InfoSection } from '@/components/UIComponents';
import { appBaseUrlList } from '@/globalConstants';

export default {
	name: 'TheNotFoundPage',
	components: {
		InfoSection,
	},
	computed: {
		homepageUrl() {
			return this.currentAppName && this.currentAppName !== 'eshop' ? appBaseUrlList[this.currentAppName] : '/';
		},
	},
	beforeDestroy() {
		this.closeMenu();
	},
	methods: {
		toggleNavbar() {
			document.body.classList.toggle('nav-open');
		},
		closeMenu() {
			document.body.classList.remove('nav-open');
			document.body.classList.remove('off-canvas-sidebar');
		},
	},
};
</script>

<style scoped>
.heading-style{
	font-size: 200%;
    line-height: 45px;
}
</style>
