<template>
	<card>
		<div class="d-flex justify-content-between">
			<h2 class="mb-2">
				{{ valuesCardTitle }}
			</h2>
			<div class="d-flex">
				<confirm-button
					v-if="!emptyDeliveryPoint && editing && deliveryPoint.type === DeliveryPointType.PenguinBox.name"
					:show-success-message="false"
					:confirm-message="$t('admin.changeToSmartBoxQuestion')"
					:callback="() => $emit('change-to-smartbox')">
					{{ $t('admin.changeToSmartBox') }}
				</confirm-button>
				<p-button
					v-if="!emptyDeliveryPoint && editing"
					size="md"
					type="success"
					@click="$emit('show-hide-edit-dialog')">
					{{ editButton }}
				</p-button>
			</div>
		</div>
		<div class="d-flex align-items-center">
			<div class="border p-1 flex-fill">
				{{ $t('admin.country') }}: {{ deliveryPoint.state }}
			</div>
			<div class="border p-1 flex-fill">
				{{ $t('admin.city') }}: {{ deliveryPoint.city }}
			</div>
			<div class="border p-1 flex-fill">
				{{ $t('admin.street') }}: {{ deliveryPoint.street }}
			</div>
			<div class="border p-1 flex-fill">
				{{ $t('admin.location') }}: {{ deliveryPoint.location }}
			</div>
		</div>
		<div class="d-flex align-items-center">
			<div class="border p-1 flex-fill">
				{{ $t('admin.physicalKey') }}: {{ deliveryPoint.physicalKey }}
			</div>
			<div class="border p-1 flex-fill">
				GPS: <span v-if="deliveryPoint.latitude && deliveryPoint.longitude"> {{ latitude | gps }}N, {{ longitude | gps }}E </span>
			</div>
			<div v-if="deliveryPoint.drivers" class="border p-1 flex-fill">
				{{ $t('admin.driversOfDay') + ': ' + dailyDrivers }}
			</div>
		</div>
		<div class="d-flex align-items-center mt-1">
			<div class="p-1 mr-3">
				<p-checkbox v-model="deliveryPoint.public" disabled>
					{{ $t('admin.isPublic') }}
				</p-checkbox>
			</div>
			<div v-if="!(deliveryPoint.type === DeliveryPointType.Store.name)" class="p-1 mr-3">
				<p-checkbox v-model="deliveryPoint.isOK" disabled>
					{{ $t('admin.state') }}
				</p-checkbox>
			</div>
			<div class="p-1 mr-3">
				<p-checkbox v-model="deliveryPoint.isDisabled" disabled>
					{{ $t('admin.disabled') }}
				</p-checkbox>
			</div>
			<div class="p-1 mr-3">
				<p-checkbox v-model="deliveryPoint.isLocked" disabled>
					{{ $t('admin.locked') }}
				</p-checkbox>
			</div>
			<div v-if="deliveryPoint.type !== DeliveryPointType.SmartKeyBox.name" class="p-1 mr-3">
				<p-checkbox v-model="deliveryPoint.hasPaymentTerminal" disabled>
					{{ $t('admin.terminal') }}
				</p-checkbox>
			</div>
		</div>
		<div
			v-if="![DeliveryPointType.Store.name, DeliveryPointType.SmartKeyBox.name].includes(deliveryPoint.type)"
			class="d-flex justify-content-between border-top mt-2">
			<div class="d-flex mt-2">
				<span slot="header" class="card-title mr-4 h4 mt-3"> {{ $t('admin.lastValues') }}</span>
				<span class="text-success h4 mt-3">{{ lastDataReceived | datetime }}</span>
				<confirm-button
					:callback="() => reqStatus()"
					:message-success="$t('admin.reqStatusSuccess')"
					:message-error="$t('admin.reqStatusError')"
					:confirm-message="$t('admin.reqStatusQuestion')">
					{{ $t('admin.reqStatus') }}
				</confirm-button>
				<p-button
					@click="restartDialogVisible = true">
					{{ $t('admin.restart') }}
				</p-button>
				<el-dialog
					:visible.sync="restartDialogVisible"
					width="60%">
					<h2>{{ $t('admin.restart') }}</h2>
					{{ $t('admin.restartContainerConfirm') }}
					{{ deliveryPoint.serialNumber }}
					<br>
					{{ $t('admin.restartNote') }}
					<el-input
						v-model="restartNote"
						placeholder="Note"
						class="mt-2" />
					<span slot="footer" class="dialog-footer">
						<el-button type="primary" @click="restartContainer(false)">
							{{ $t('admin.softRestart') }}
						</el-button>
						<el-button type="danger" @click="restartContainer(true)">
							{{ $t('admin.fullRestart') }}
						</el-button>
						<el-button @click="restartDialogVisible = false">{{ $t('admin.cancel') }}</el-button>
					</span>
				</el-dialog>
			</div>
			<div class="mt-2">
				<p-button type="info" class="align-self-center" @click="goToHistory">
					<i slot="labelRight" class="nc-icon nc-chart-bar-32" />
					{{ $t('admin.history') }}
				</p-button>
			</div>
		</div>
		<ul class="list-group list-group-horizontal flex-row">
			<li v-for="value in deliveryPointParameters" :key="value.id" class="list-group-item flex-fill m-0 p-1">
				{{ value.name }}: {{ value.value }} {{ value.unit }}
			</li>
		</ul>
		<div
			v-if="![DeliveryPointType.Store.name, DeliveryPointType.SmartKeyBox.name].includes(deliveryPoint.type)"
			class="d-flex justify-content-between border-top mt-2">
			<el-tooltip :content="$t('admin.tooltips.periodsToOffline')" placement="top-start">
				<div class="d-flex mt-2">
					<span slot="header" class="card-title mr-4 h4 mt-3">{{ $t('admin.offlinePeriods') }}</span>
					<span class="text-success h4 mt-3">{{ deliveryPoint.periodsUntilOffline }}</span>
				</div>
			</el-tooltip>
		</div>
		<div
			class="d-flex justify-content-between border-top mt-2">
			<el-tooltip :content="$t('admin.tooltips.note')" placement="top-start">
				<div class="d-flex note">
					<span slot="header" class="card-title mr-4 h4 mt-3">{{ $t('admin.note') }}</span>
					<el-input
						v-model="deliveryPoint.note"
						class="note-text"
						type="textarea"
						autosize
						:readonly="!noteEditing" />
					<p-button
						size="md"
						:type="noteEditing ? 'danger' : 'success'"
						class="align-self-center"
						@click="saveNote">
						{{ noteEditing ? $t('admin.saveNote') : $t('admin.editNote') }}
					</p-button>
				</div>
			</el-tooltip>
		</div>
	</card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Tooltip as ElTooltip, Dialog as ElDialog } from 'element-ui';

import { loadContainerParameters } from '@/modules/admin/store/action-types';
import { convertCoordinatesToRad } from '@/util/container-mixin';
import { DeliveryPointType } from '@/modules/admin/store/enums';
import { appName } from '@/modules/admin/config';
import notifyService from '@/service/notify-service';
import { formChanged } from '@/store/mutation-types';

export default {
	name: 'DeliveryPointValuesCard',
	components: {
		ElTooltip,
		ElDialog,
	},
	props: {
		deliveryPoint: { type: Object, default: () => {} },
		editing: { type: Boolean, default: false },
		emptyDeliveryPoint: { type: Boolean, default: false },
		editFormVisible: { type: Boolean, default: false },
	},
	data() {
		return {
			DeliveryPointType,
			loading: false,
			noteEditing: false,
			restartDialogVisible: false,
			restartNote: '',
		};
	},
	computed: {
		...mapState(appName, ['containerValues']),
		valuesCardTitle() {
			if (this.deliveryPoint.type === DeliveryPointType.PenguinBox.name) {
				return `${this.$t('admin.penguinBox')} ${this.deliveryPoint.serialNumber}`;
			}
			if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
				return `${this.$t('admin.pickupPlace')} ${this.deliveryPoint.serialNumber}`;
			}
			return `${this.$t('admin.smartBox')} ${this.deliveryPoint.serialNumber}`;
		},
		editButton() {
			if (this.deliveryPoint.type === DeliveryPointType.PenguinBox.name) {
				return this.$t('admin.editPenguinBox');
			}
			if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
				return this.$t('admin.editPickupPlace');
			}
			return this.$t('admin.editSmartBox');
		},
		lastDataReceived() {
			if (this.deliveryPoint && this.deliveryPoint.lastParameters && this.deliveryPoint.lastParameters.date) {
				return this.deliveryPoint.lastParameters.date;
			}
			return undefined;
		},
		deliveryPointParameters() {
			const result = [];
			if (this.deliveryPoint && this.deliveryPoint.lastParameters && this.deliveryPoint.lastParameters.date) {
				Object.keys(this.deliveryPoint.lastParameters.values).forEach((property) => {
					if (
						Object.prototype.hasOwnProperty.call(this.containerValues, property)
						&& Object.prototype.hasOwnProperty.call(this.deliveryPoint.lastParameters.values, property)
					) {
						const info = this.containerValues[property];
						result.push({
							value: this.deliveryPoint.lastParameters.values[property],
							...info,
						});
					}
				});
			}
			return result;
		},
		longitude() {
			return convertCoordinatesToRad(this.deliveryPoint.longitude);
		},
		latitude() {
			return convertCoordinatesToRad(this.deliveryPoint.latitude);
		},
		dailyDrivers() {
			return this.deliveryPoint.drivers
				.filter((f) => f)
				.map((d) => `${d.name} ${d.surname}`)
				.join();
		},
	},
	watch: {
		deliveryPoint: {
			handler() {
				if (this.editFormVisible) {
					this.$store.commit(`${appName}/${formChanged}`, true);
				}
			},
			deep: true,
		},
	},
	async created() {
		await this.loadContainerParameters();
	},
	methods: {
		...mapActions(appName, [loadContainerParameters]),
		saveNote() {
			if (!this.noteEditing) {
				this.noteEditing = true;
				return;
			}
			this.loading = true;
			try {
				this.$service.deliveryPoint.updateNote(
					this.deliveryPoint.containerId,
					this.deliveryPoint.note,

				);
				notifyService.notifySuccess(this.$t('admin.noteSaved'));
			} catch {
				notifyService.notifyError(this.$t('admin.noteDoesNotSaved'));
			} finally {
				this.loading = false;
				this.noteEditing = false;
			}
		},
		async reqStatus() {
			await this.$service.deliveryPoint.requireStatus(this.deliveryPoint.containerId);
		},
		async restartContainer(full) {
			if (!this.restartNote) {
				notifyService.notifyError(this.$t('admin.restartNoteRequired'));
				return;
			}
			try {
				await this.$service.deliveryPoint.restartContainer(this.deliveryPoint.containerId, full, this.restartNote);
				notifyService.notifySuccess(this.$t('admin.restartSuccess'));
			} catch {
				notifyService.notifyError(this.$t('admin.restartError'));
			} finally {
				this.restartNote = '';
				this.restartDialogVisible = false;
			}
		},
		goToHistory() {
			this.$router.push({
				name: this.deliveryPoint.type === DeliveryPointType.PenguinBox.name ? 'container-history' : 'smart-box-history',
				params: { id: this.deliveryPoint.containerId },
			});
		},
	},
};
</script>

<style scoped>
.note {
	width: 100%;
}

.note-text{
	margin: 5px;
}
</style>
