<template>
	<div v-if="canDirectSale && box" class="row">
		<div class="col-md-12 card">
			<h2 class="card-header mb-2">
				{{ `${$t('eshop.box')} ${box.boxId} - ${$t('eshop.container')} ${box.container.serialNumber}` }}
			</h2>
			<div class="col">
				<div class="d-flex align-items-center mt-2">
					<div class="border p-1 flex-fill">
						{{ $t('eshop.state') }}: {{ box.container.state }}
					</div>
					<div class="border p-1 flex-fill">
						{{ $t('eshop.city') }}: {{ box.container.city }}
					</div>
					<div class="border p-1 flex-fill">
						{{ $t('eshop.street') }}: {{ box.container.street }}
					</div>
					<div class="border p-1 flex-fill">
						{{ $t('eshop.location') }}: {{ box.container.location }}
					</div>
					<div class="border p-1 flex-fill">
						{{ $t('eshop.privilegedStockItemCategory') }}: {{ box.privilegedStockItemCategory }}
					</div>
				</div>
				<div class="d-flex align-items-center mt-2">
					<div class="p-1 mr-3">
						<p-checkbox v-model="box.container.isOK" disabled>
							{{ $t('eshop.state') }}
						</p-checkbox>
					</div>
					<div class="p-1 mr-3">
						<p-checkbox v-model="box.container.isDiabled" disabled>
							{{ $t('eshop.disabled') }}
						</p-checkbox>
					</div>
					<div class="p-1 mr-3">
						<p-checkbox v-model="box.container.isLocked" disabled>
							{{ $t('eshop.locked') }}
						</p-checkbox>
					</div>
				</div>
				<div v-if="stockItem && stockItem.name" class="border-top mb-3 pt-2">
					<div class="d-flex mt-2 justify-content-between align-items-center">
						<span slot="header" class="card-title mr-4 h4 mt-3">Přiřazený produkt pro přímý prodej</span>
						<div class="col-2 d-flex mb-1 justify-content-end">
							<el-tooltip :content="$t('eshop.tooltips.showProduct')">
								<p-button size="md" type="primary" @click.stop="goToProductDetail(stockItem)">
									{{ $t('eshop.showProduct') }}
								</p-button>
							</el-tooltip>
							<el-tooltip :content="$t('eshop.tooltips.unassignStockItem')">
								<confirm-button
									class="ml-3"
									type="danger"
									size="md"
									:message-success="$t('eshop.unassignStockItemSuccess')"
									:message-error="$t('eshop.unassignStockItemError')"
									:confirm-message="$t('eshop.unassignStockItem')"
									:callback="unassign">
									{{ $t('eshop.remove') }}
								</confirm-button>
							</el-tooltip>
						</div>
					</div>
					<div class="d-flex align-items-center">
						<div class="border p-1 flex-fill">
							{{ $t('eshop.name') }}: {{ stockItem.name }}
						</div>
						<div class="border p-1 flex-fill">
							{{ $t('eshop.brand') }}: {{ stockItem.brand }}
						</div>
						<div class="border p-1 flex-fill">
							{{ $t('eshop.category') }}: {{ stockItem.category }}
						</div>
					</div>
					<div class="d-flex align-items-center">
						<div class="border p-1 flex-fill">
							{{ $t('eshop.description') }}: {{ stockItem.description }}
						</div>
						<div class="border p-1 flex-fill">
							{{ $t('eshop.size') }}: {{ stockItem.size }}
						</div>
						<div class="border p-1 flex-fill">
							{{ $t('eshop.price') }}: {{ stockItem.price }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="!(stockItem && stockItem.name)" class="col-md-12 card">
			<div class="col">
				<div class="d-flex mt-2 justify-content-between align-items-center">
					<span slot="header" class="card-title mr-4 h4 mt-3">{{ $t('eshop.singleDirectSell') }}</span>
				</div>
				<el-select
					v-model="boxCategory"
					size="large"
					class="w-50 mb-2 text-center"
					filterable
					:placeholder="$t('eshop.chooseCategory')"
					value-key="ordinal"
					clearable
					@change="loadData()">
					<el-option
						v-for="category in boxesCategories"
						:key="category"
						:label="category"
						:value="category" />
				</el-select>
				<el-tooltip :content="$t('eshop.tooltips.clearFilter')" placement="top-start">
					<p-button type="danger" class="mx-2" @click="boxCategory = ''">
						{{ $t('eshop.cancelChoose') }}
					</p-button>
				</el-tooltip>
				<el-tooltip :content="$t('eshop.tooltips.assignStockItemSingleSale')">
					<p-button type="success" @click="switchDialogVisible">
						{{ $t('eshop.assign') }}
					</p-button>
				</el-tooltip>
			</div>
		</div>
		<div v-if="!(stockItem && stockItem.name)" class="col-md-12 card">
			<div class="d-flex mt-2">
				<div class="col">
					<form-group-input v-model="filter.stockId" :label="$t('eshop.stockId')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.name" :label="$t('eshop.name')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.description" :label="$t('eshop.description')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.category" :label="$t('eshop.category')" type="text" />
				</div>
			</div>
			<div class="d-flex">
				<div class="col">
					<form-group-input v-model="filter.brand" :label="$t('eshop.brand')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.price" :label="$t('eshop.price')" type="number" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.size" :label="$t('eshop.size')" type="text" />
				</div>
			</div>
			<div class="d-flex mt-2">
				<p-checkbox v-model="filter.allowDirectSale" class="ml-4 align-self-center" disabled>
					{{ $t('eshop.directSale') }}
				</p-checkbox>
			</div>
			<div class="col-2 d-flex mb-1 justify-content-start">
				<p-button type="success" size="md" @click="loadData()">
					{{ $t('eshop.search') }}
				</p-button>
				<el-tooltip :content="$t('eshop.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</div>
		<div v-if="!(stockItem && stockItem.name)" class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col">
						<el-pagination
							:current-page="page.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.totalCount"
							:page-count="page.pagesCount"
							:page-size="pageSize"
							:page-sizes="pageSizes"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<el-table
							:data="products"
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							class="table-striped"
							@sort-change="sortChange">
							<el-table-column property="stockId" align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.stockId')" placement="top-start">
										<span>{{ $t('eshop.stockId') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="name" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.name')" placement="top-start">
										<span>{{ $t('eshop.name') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :max-length="16" :text="scope.row.name" />
								</template>
							</el-table-column>
							<el-table-column property="description" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.description')" placement="top-start">
										<span>{{ $t('eshop.description') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :max-length="16" :text="scope.row.description" />
								</template>
							</el-table-column>
							<el-table-column property="category" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.category')" placement="top-start">
										<span>{{ $t('eshop.category') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="brand" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.brand')" placement="top-start">
										<span>{{ $t('eshop.brand') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :max-length="16" :text="scope.row.brand" />
								</template>
							</el-table-column>
							<el-table-column property="price" align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.price')" placement="top-start">
										<span>{{ $t('eshop.price') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="size" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.size')" placement="top-start">
										<span>{{ $t('eshop.size') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="imported" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.imported')" placement="top-start">
										<span>{{ $t('eshop.imported') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.imported | datetime }}
								</template>
							</el-table-column>
							<el-table-column class-name="td-actions" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.assignStockItem.name')" placement="top-start">
										<span>{{ $t('eshop.assignStockItem.name') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip v-if="scope.row.id != selectedStockItemId" :content="$t('eshop.tooltips.assignStockItem')">
										<confirm-button
											type="success"
											size="sm"
											icon
											round
											:message-success="$t('eshop.assignStockItem.success')"
											:message-error="$t('eshop.assignStockItem.error')"
											:confirm-message="$t('eshop.assignStockItem.message')"
											:callback="() => assignStockItem(scope.row)">
											<i class="fa fa-plus" />
										</confirm-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col mt-2">
						<el-pagination
							:current-page="page.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.totalCount"
							:page-count="page.pagesCount"
							:page-size="pageSize"
							:page-sizes="pageSizes"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
				</div>
			</div>
		</div>
		<el-dialog
			:close-on-click-modal="false"
			:visible.sync="dialogVisible"
			round
			width="50%"
			@close="setAssignSingeSaleToAllContainer(false)">
			<h3 class="text-center">
				{{ $t('eshop.assignStockItem.messageFromTop') }}
			</h3>
			<div class="d-flex justify-content-center">
				<div class="text-left w-75">
					<p-checkbox v-model="singleSaleToAllContainer" class="mt-2">
						{{ $t('eshop.assignStockItem.singleSaleToAllContainer') }}
					</p-checkbox>
				</div>
			</div>
			<span slot="footer" class="d-flex justify-content-center">
				<p-button
					type="success"
					@click="
						switchDialogVisible();
						assignStockItemSingleSale();
					">{{ $t('eshop.confirm') }}</p-button>
				<p-button
					type="danger"
					@click="
						switchDialogVisible();
						setAssignSingeSaleToAllContainer(false);
					">{{ $t('eshop.cancel') }}</p-button>
			</span>
		</el-dialog>
	</div>
	<div v-else class="row">
		<h2>{{ $t('eshop.userDoesntAllowDirectSale') }}</h2>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import {
	Select as ElSelect,
	Option as ElOption,
	Table as ElTable,
	TableColumn as ElTableColumn,
	Pagination as ElPagination,
	Tooltip as ElTooltip,
	Dialog as ElDialog,
} from 'element-ui';

import { TrimText } from '@/components/UIComponents';
import ValidationMixin from '@/util/validated-form-mixin';
import { isInteger } from '@/util/helpers';
import { appName } from '@/modules/eshop/config';
import { resetFilter, setFilter } from '@/store/mutation-types';

export default {
	name: 'AssignStockItemList',
	components: {
		TrimText,
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElSelect,
		ElOption,
		ElDialog,
	},
	mixins: [ValidationMixin],
	props: {
		boxId: {
			String,
			default: null,
			validator: isInteger,
		},
	},
	data() {
		return {
			box: null,
			singleSaleToAllContainer: false,
			dialogVisible: false,
			boxCategory: '',
			boxesCategories: [],
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			stockItem: {
				name: null,
				brand: null,
				category: null,
				description: null,
				size: null,
				price: null,
			},
			filter: {},
		};
	},
	computed: {
		...mapState(appName, ['directSaleProductFilter', 'currentUser']),
		products() {
			return this.page.entities;
		},
		canDirectSale() {
			return this.currentUser.DirectSale === 'True';
		},
		selectedStockItemId() {
			return this.stockItem && this.stockItem.id;
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'directSaleProductFilter', data: val });
			},
		},
	},
	async created() {
		this.filter = this.directSaleProductFilter;
		this.box = await this.$service.box.getBox(this.$route.params.boxId);
		this.boxesCategories = await this.$service.stockItem.getCategories();

		if (this.box.stockItem) {
			_.assign(this.stockItem, this.box.stockItem);
		}

		this.loadData();
	},

	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		switchDialogVisible() {
			this.dialogVisible = !this.dialogVisible;
		},
		setAssignSingeSaleToAllContainer(newValue) {
			this.singleSaleToAllContainer = newValue;
		},
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},

		async loadData(page = 1) {
			this.filter.allowDirectSale = true;
			this.page = await this.$service.stockItem.getList(page, this.filter, this.pageSize);
		},

		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'directSaleProductFilter');
			this.filter = this.directSaleProductFilter;
			this.loadData();
		},

		async assignStockItem(row) {
			const data = await this.$service.box.directSale(row.id, this.box.boxId, false, null);
			this.stockItem = data.stockItem;
		},

		async assignStockItemSingleSale() {
			if (this.singleSaleToAllContainer) {
				await this.handleBackEndErrors(true, this.$service.box.directSaleToAllBoxesOfContainer, this.box.container.containerId, this.boxCategory);
				const result = await this.handleBackEndErrors(false, this.$service.box.getStockItem, this.box.boxId);
				this.stockItem = result.stockItem;
			} else {
				const result = await this.handleBackEndErrors(true, this.$service.box.directSale, null, this.box.boxId, true, this.boxCategory);
				this.stockItem = result.stockItem;
			}
			this.setAssignSingeSaleToAllContainer(false);
		},

		async unassign() {
			await this.$service.box.directSale(null, this.box.boxId, false, null);
			this.stockItem = null;
		},

		goToProductDetail(stockItem) {
			this.$router.push({ name: 'direct-sale-product-edit', params: { id: stockItem.id } });
		},
	},
};
</script>
