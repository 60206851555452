<template>
	<div v-loading="loading" class="row">
		<div class="col-lg-6 p-0">
			<base-report-print-sheet
				:report-data="normalizedReportData"
				:remaining-reservations-to-be-loaded="remainingReservationsToBeLoaded"
				:total-reservations-to-be-loaded="totalReservationsToBeLoaded"
				@load="loadData">
				<template #containersInfo>
					<containers-info
						:containers="containersToShow"
						:loading="loading"
						:logistic-route-id="normalizedReportData.logisticRouteId"
						:report-is-active="normalizedReportData.isActive"
						@set-loading="setLoading"
						@set-editing-reservation="setEditingReservation"
						@set-report-data="setReportData"
						@get-report="loadData" />
				</template>
				<template #actions>
					<div v-if="normalizedReportData" id="qrCode" class="row mt-3">
						<div class="col-9">
							<div>
								<div>
									<script
										defer
										onload="loadMap()"
										src="https://api.mapy.cz/loader.js"
										type="application/javascript" />
									<script type="application/javascript">
										function loadMap() {
										Loader.async = true;
										Loader.load(null, null, createUrl);
										};
										function createUrl () {
										var smapUrl = new SMap.URL.Route().addStart({{ startCoords }}){{ wayPointCoords }}.addDestination({{ destinationCoords }});
										document.getElementById("smapUrlInput").value = smapUrl.toString();
										document.getElementById("smapUrlInput").dispatchEvent(new Event('input'));
										};
									</script>
									<input id="smapUrlInput" v-model="smapUrl" type="hidden">
									<form-group-input v-model="insertedMapUrl" :label="$t('logistics.mapUrl')" type="text" />
									<div>
										<p-button @click="saveMapUrl">
											{{ $t('logistics.save') }}
										</p-button>
									</div>
								</div>
							</div>
						</div>
						<div v-if="qrCode" class="col-3">
							<qr-code :size="100" :text="qrCode" />
						</div>
					</div>
					<div v-if="normalizedReportData" class="mt-3 d-flex">
						<div v-if="normalizedReportData.isActive" class="mr-3">
							<p-button @click="optimizeRoute">
								<i slot="labelRight" class="fa fa-map" />
								{{ $t('logistics.optimizeRoute') }}
							</p-button>
						</div>
						<div v-if="normalizedReportData.isActive" class="mr-3">
							<p-button @click="flipRoute">
								<i slot="labelRight" class="fa fa-sync" />
								{{ $t('logistics.flipRoute') }}
							</p-button>
						</div>
						<div>
							<a :href="smapUrl" class="btn" target="_blank"> {{ $t('logistics.showOn') }} Mapy.cz </a>
						</div>
					</div>
					<div class="mt-3 d-flex justify-content-between">
						<div>
							<p-button @click="print">
								<i slot="labelRight" class="fa fa-print" />
								{{ $t('logistics.print') }}
							</p-button>
						</div>
						<div>
							<div v-if="normalizedReportData && normalizedReportData.isActive">
								<span>
									<router-link :to="{ name: 'edit-route', params: { id: normalizedReportData.logisticRouteId } }">
										<p-button>{{ $t('logistics.edit') }}</p-button>
									</router-link>
								</span>
								<span v-if="normalizedReportData.isActive" class="pl-2">
									<el-tooltip :content="$t('logistics.tooltips.finishRoute')" placement="top-start">
										<confirm-button
											:confirm-message="$t('logistics.confirmFinishRoute')"
											:message-error="$t('logistics.confirmFinishRouteError')"
											:message-success="$t('logistics.routeFinished')"
											type="success"
											:callback="handleFinishRoute">
											{{ $t('logistics.finish') }}
										</confirm-button>
									</el-tooltip>
								</span>
							</div>
						</div>
					</div>
				</template>
			</base-report-print-sheet>
		</div>

		<div class="col-lg-6">
			<available-reservations
				v-if="normalizedReportData && normalizedReportData.isActive"
				:route-id="normalizedReportData.logisticRouteId"
				class="availableReservations pl-xl-2" />

			<collected-reservations
				v-if="normalizedReportData && !normalizedReportData.isActive && collectedReservations.length > 0"
				:collected-reservations="reservationsSorted(collectedReservations)"
				:loading="loading"
				@load-data="loadData"
				@set-loading="setLoading" />

			<collected-packages
				v-if="normalizedReportData && !normalizedReportData.isActive && collectedPackages.length > 0"
				:collected-packages="reservationsSorted(collectedPackages)"
				:loading="loading"
				:report-name="normalizedReportData.name" />

			<logistic-summary
				v-if="containersToShow && normalizedReportData && !normalizedReportData.isActive"
				:containers-to-show="containersToShow"
				:driver="normalizedReportData.driver"
				:trip-date="normalizedReportData.tripDate"
				:loading="loading"
				@set-loading="setLoading" />
		</div>

		<el-dialog v-if="optimizeRouteData" :visible.sync="routeVisible" close-on-click-modal>
			<optimize-route
				:loading="loading"
				:optimize-route-data="optimizeRouteData"
				:private-reservations-mapping="optimizeRoutePrivateAddressesMapping"
				:report-data="normalizedReportData"
				:update-route-callback="updateRoute"
				@set-route-visible="setRouteVisible" />
		</el-dialog>

		<el-dialog
			:close-on-click-modal="false"
			:visible.sync="editingReservation"
			round
			width="75%">
			<edit-reservation :reservation-to-edit="reservationToEdit" @page-reload="loadData" />
		</el-dialog>
	</div>
</template>

<script>
import { Tooltip as ElTooltip, Dialog as ElDialog } from 'element-ui';
import VueQRCodeComponent from 'vue-qr-generator';
import { cloneDeep, mapValues } from 'lodash';

import { ReservationStatus } from '@/modules/logistics/store/enums';
// eslint-disable-next-line import/no-cycle
import logisticsRouteSharedService from '@/service/logistics-route-shared-service';
import notifyService from '@/service/notify-service';
import ValidationMixin from '@/util/validated-form-mixin';
import EditReservation from '@/modules/logistics/views/Report/EditReservation.vue';
import PrintArea from '@/util/print-area';
import { BaseReportPrintSheet } from '@/components/Base';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import FormMixin from '@/util/form-mixin';
import { ReservationRouteStatus } from '@/modules/driver/store/enums';

import AvailableReservations from './ReportDetails/AvailableReservations.vue';
import CollectedReservations from './ReportDetails/CollectedReservations.vue';
import CollectedPackages from './ReportDetails/CollectedPackages.vue';
import OptimizeRoute from './ReportDetails/OptimizeRoute.vue';
import ContainersInfo from './ReportDetails/ContainersInfo.vue';
import LogisticSummary from './ReportDetails/LogisticSummary.vue';

export default {
	name: 'ReportDetails',
	components: {
		ContainersInfo,
		OptimizeRoute,
		CollectedReservations,
		CollectedPackages,
		EditReservation,
		AvailableReservations,
		ElTooltip,
		ElDialog,
		BaseReportPrintSheet,
		LogisticSummary,
		'qr-code': VueQRCodeComponent,
	},
	mixins: [ValidationMixin, ReservationNameMixin, FormMixin],
	data() {
		return {
			insertedMapUrl: null,
			reportData: null,
			loading: false,
			collectedReservations: {},
			collectedPackages: [],
			optimizeRouteData: null,
			optimizeRoutePrivateAddressesMapping: {},
			routeVisible: false,
			loadRoute: true,
			smapUrl: 'https://mapy.cz',
			startCoords: '',
			destinationCoords: '',
			wayPointCoords: '',
			startLat: '49.495239618061674',
			startLng: '16.65029620932673',
			editingReservation: false,
			reservationToEdit: {},
		};
	},
	computed: {
		containersToShow() {
			const containersWithReservations = (this.normalizedReportData?.containers || []).filter((container) => !!container.reservations);

			return containersWithReservations.map((container) => {
				const clonedContainer = cloneDeep(container);
				clonedContainer.reservations = this.reservationsSorted(container.reservations);
				clonedContainer.stockItemsCounts = this.setGroupClass(container.stockItemsCounts);

				return clonedContainer;
			});
		},
		getDriverId() {
			return this.normalizedReportData.driver ? this.normalizedReportData.driver.id : null;
		},
		qrCode() {
			return this.normalizedReportData.mapUrl || null;
		},
		remainingReservationsToBeLoaded() {
			if (this.normalizedReportData?.containers) {
				return this.normalizedReportData.containers.reduce(
					(total, container) => total
						+ (container.waitForDeliveryReservations || []).filter(
							({ reservationRouteStatus }) => reservationRouteStatus
								&& ReservationRouteStatus.enumValueOf(reservationRouteStatus) === ReservationRouteStatus.CONFIRMED_BY_DRIVER,
						).length,
					0,
				);
			}
			return 0;
		},
		totalReservationsToBeLoaded() {
			if (this.normalizedReportData?.containers) {
				return this.normalizedReportData.containers.reduce(
					(total, container) => total
						+ (container.waitForDeliveryReservations || []).filter(
							({ reservationRouteStatus }) => reservationRouteStatus
								&& [ReservationRouteStatus.CONFIRMED_BY_DRIVER, ReservationRouteStatus.ASSIGNED].includes(
									ReservationRouteStatus.enumValueOf(reservationRouteStatus),
								),
						).length,
					0,
				);
			}
			return 0;
		},
		normalizedReportData: {
			cache: false,
			get() {
				const reportData = cloneDeep(this.reportData);
				if (reportData?.containers) {
					reportData.mapUrl = this.insertedMapUrl;
					reportData.containers.forEach((container) => {
						container.reservations?.forEach((reservation) => {
							// Tohle je horzný peklo. Kopírovat currentStatus do status je chyba návrhu dat na FE.
							// Current status má jiný význam, než status.
							// status        => s jakým stavem byla zásilka přidána do reportu
							// currentStatus => jaky status má v tuhle chvíli
							if (reservation.isPrivateAddress && ReservationStatus.PICK_UP === ReservationStatus.enumValueOf(reservation.status)) {
								reservation.status = reservation.currentStatus;
							}
						});
					});
					reportData.containers = reportData.containers.map((container) => {
						if (container.reservations && container.reservations.length) {
							container.waitForDeliveryReservations = [];
							container.reservations.forEach((reservation) => {
								if (ReservationStatus.WAIT_FOR_DELIVERY === ReservationStatus.enumValueOf(reservation.status)) {
									container.waitForDeliveryReservations.push(reservation);
								}
							});
						}
						return container;
					});
				}
				return reportData;
			},
		},
	},

	watch: {
		normalizedReportData: {
			deep: true,
			handler() {
				this.refreshMapData();
			},
		},
	},

	created() {
		this.loadData();
	},

	methods: {
		setReportData(reportData) {
			this.reportData = reportData;
		},
		setEditingReservation(reservationToEdit) {
			this.reservationToEdit = reservationToEdit;
			this.editingReservation = true;
		},
		refreshMapData() {
			if (this.normalizedReportData?.containers) {
				this.collectedReservations = this.getCollectedPostReservations();
				this.collectedPackages = this.getCollectedPackages();

				this.startCoords = `SMap.Coords.fromWGS84(${this.startLng},${this.startLat})`;
				this.destinationCoords = this.startCoords;
				let lastCoords = '';
				for (let i = 0; i < this.normalizedReportData.containers.length; i++) {
					const { longitude, latitude } = this.normalizedReportData.containers[i];
					const coords = `SMap.Coords.fromWGS84(${longitude},${latitude})`;
					if (coords !== lastCoords) {
						this.wayPointCoords += `.addWaypoint(${coords})`;
					}
					lastCoords = coords;
				}
			}
		},
		getCollectedPostReservations() {
			if (this.normalizedReportData) {
				return this.normalizedReportData.containers
					.map((c) => c.reservations)
					.reduce((resp, r) => resp.concat(r), [])
					.filter(
						(container) => container && ReservationStatus.enumValueOf(container.currentStatus) === ReservationStatus.COLLECTED && container.isPostPackage,
					);
			}
			return 0;
		},
		getCollectedPackages() {
			if (this.normalizedReportData) {
				const { COLLECTED, WAIT_FOR_DELIVERY } = ReservationStatus;

				return this.normalizedReportData.containers
					.map((c) => c.reservations)
					.reduce((resp, r) => resp.concat(r), [])
					.filter(
						(container) => container
							&& [COLLECTED, WAIT_FOR_DELIVERY].includes(ReservationStatus.enumValueOf(container.currentStatus)) && !container.isPostPackage,
					);
			}
			return 0;
		},
		async loadData() {
			this.loading = true;
			this.editingReservation = false;
			const reportData = await logisticsRouteSharedService.getReport(this.$route.params.id);
			reportData.containers = reportData.routeStops;
			this.reportData = reportData;
			this.insertedMapUrl = reportData.mapUrl;
			this.loading = false;
		},
		setLoading(loading) {
			this.loading = loading;
		},
		setRouteVisible(routeVisible) {
			this.routeVisible = routeVisible;
		},

		print() {
			const printArea = new PrintArea('printRoute');

			if (this.qrCode) {
				const qrCodeSrc = window.document.querySelector('#qrCode img').src;
				printArea.addHtml(`
					<div class="hrefToMaps">
             <div class="labelMapUrl">${this.$t('logistics.mapUrl')}:</div>
             <div>${this.qrCode}</div>
             <img class="qrCode" src="${qrCodeSrc}" alt="" />
          </div>
				`);
			}

			printArea.print();
		},

		async flipRoute() {
			const containers = this.getSimplifiedContainers().reverse();
			await this.updateRoute(containers, this.normalizedReportData.isActive);
			this.$router.go(0);
		},
		async updateRoute(containers, isActive) {
			try {
				this.loading = true;

				const { name, note, logisticRouteId, mapUrl } = this.normalizedReportData;

				const reportData = await this.$service.logisticsRoute.update({
					name,
					note,
					isActive,
					routeStops: containers,
					driverId: this.getDriverId,
					logisticRouteId,
					mapUrl,
				});
				reportData.containers = reportData.routeStops;
				this.reportData = reportData;

				notifyService.notifySuccess(this.$t('admin.updateSuccess'));
			} catch {
				notifyService.notifyError(this.$t('admin.updateError'));
			} finally {
				this.loading = false;
			}
		},
		async saveMapUrl() {
			const simplifiedContainers = this.getSimplifiedContainers();
			await this.updateRoute(simplifiedContainers, this.normalizedReportData.isActive);
		},
		reservationsSorted(reservations) {
			const waitForDelivery = [];
			const expired = [];
			const waitForCollect = [];
			const picked = [];
			const discarded = [];
			const { WAIT_FOR_COLLECT, WAIT_FOR_DELIVERY, PICK_UP, DISCARD } = ReservationStatus;

			reservations.forEach((reservation) => {
				const clonedReservation = cloneDeep(reservation);
				if (ReservationStatus.enumValueOf(reservation.status) === WAIT_FOR_DELIVERY) {
					clonedReservation.group = 'delivery';
					waitForDelivery.push(clonedReservation);
				} else if (ReservationStatus.enumValueOf(reservation.status) === WAIT_FOR_COLLECT) {
					clonedReservation.group = 'collect';
					waitForCollect.push(clonedReservation);
				} else if (this.isExpired(reservation)) {
					clonedReservation.group = 'collect';
					expired.push(clonedReservation);
				} else if (ReservationStatus.enumValueOf(reservation.status) === PICK_UP) {
					clonedReservation.group = 'collect';
					picked.push(clonedReservation);
				} else if (ReservationStatus.enumValueOf(reservation.status) === DISCARD) {
					clonedReservation.group = 'collect';
					discarded.push(clonedReservation);
				}
			});

			return this.setGroupClass(waitForCollect.concat(expired, waitForDelivery, picked, discarded));
		},
		async handleFinishRoute() {
			const simplifiedContainers = this.getSimplifiedContainers();
			await this.updateRoute(simplifiedContainers, false);
		},
		async optimizeRoute() {
			this.routeVisible = !this.routeVisible;
			if (this.loadRoute) {
				const start = `0:0:Boskovice - Dřevařská 17 - odjezd;${this.startLat},${this.startLng}`;
				const end = `0:0:Boskovice - Dřevařská 17 - příjezd;${this.startLat},${this.startLng}`;
				const destinations = this.getDestinations(this.normalizedReportData.containers);
				try {
					this.loading = true;
					const result = await this.$service.logisticsRoute.optimizeRoute(start, end, destinations);
					this.loading = false;
					const [optimizeRouteData] = result.results;
					if (optimizeRouteData) {
						const { waypoints } = optimizeRouteData;

						for (let i = 0; i < waypoints.length; i++) {
							const { lng, lat } = waypoints[i];
							const coords = `SMap.Coords.fromWGS84(${lng},${lat})`;
							if (i === 0) {
								this.startCoords = coords;
							} else if (i === waypoints.length - 1) {
								this.destinationCoords = coords;
							} else {
								this.wayPointCoords += `.addWaypoint(${coords})`;
							}
						}

						this.optimizeRouteData = optimizeRouteData;
					} else {
						this.optimizeRouteData = null;
						this.optimizeRoutePrivateAddressesMapping = {};
					}
				} catch (error) {
					let wasDisplayedError = false;

					if (error?.response?.data?.errors?.length) {
						error.response.data.errors.forEach((err) => {
							err.split(':').forEach((part) => {
								if (/^-?\d+$/.test(part)) {
									mapValues(this.$refs, (ref, refName) => {
										if (refName.indexOf(`reservation-${part}`) === 0) {
											ref[0].classList.add('selected-box');
											ref[0].scrollIntoView({ behavior: 'smooth' });
											setTimeout(() => ref[0].classList.remove('selected-box'), 10000);
											notifyService.notifyError(`${this.$t('logistics.wrongAddress')}: ${this.$t('reservationId')}: ${part}`);
											wasDisplayedError = true;
										}
									});
								}
							});
						});

						if (!wasDisplayedError) {
							notifyService.notifyError(this.$t('admin.generalError'));
						}
					} else {
						notifyService.notifyError(this.$t('admin.generalError'));
					}
				}
				this.loadRoute = false;
			}
		},
		getDestinations(containers) {
			this.optimizeRoutePrivateAddressesMapping = {};
			const destinations = [];
			let destinationIndex = 1;
			containers.forEach((container) => {
				const waypoint = this.getWaypoint(container, destinationIndex);
				destinations[`destination${destinationIndex}`] = waypoint.definition;
				if (waypoint.waypointIds?.length) {
					this.optimizeRoutePrivateAddressesMapping[destinationIndex] = waypoint.waypointIds;
				}
				destinationIndex += 1;
			});
			return destinations;
		},
		getWaypoint(container, destinationIndex) {
			const result = {};
			const { containerId, reservations, city, street, location, latitude, longitude } = container;
			if (containerId) {
				const prefix = `${containerId}:${undefined}`;
				const address = `${city} - ${street} - ${location}`;
				result.definition = this.buildWaypointDefinition(prefix, address, latitude, longitude);
			} else {
				const prefix = `${undefined}:${destinationIndex}`;
				const address = `${city} - ${street}`;
				result.definition = this.buildWaypointDefinition(prefix, address, latitude, longitude);
				result.waypointIds = reservations.map((reservation) => reservation.reservationId);
			}
			return result;
		},
		buildWaypointDefinition(prefix, address, latitude, longitude) {
			return `${prefix}:${address.replace(',', ' ')};${latitude},${longitude}`;
		},
		getSimplifiedContainers() {
			return this.normalizedReportData.containers.flatMap((container) => {
				if (container.containerId) {
					return this.getSimplifiedContainer(container.containerId, null);
				}
				const simplifiedContainers = [];
				container.reservations.forEach((reservation) => {
					if (reservation.isPrivateAddress) {
						simplifiedContainers.push(this.getSimplifiedContainer(null, reservation.reservationId));
					}
				});
				return simplifiedContainers;
			});
		},
		getSimplifiedContainer(containerId, privateAddressReservationId) {
			return {
				containerId,
				reservationId: privateAddressReservationId,
			};
		},
		setGroupClass(reservations) {
			return reservations.map((reservation) => {
				if (this.lastReservationGroup !== reservation.group) {
					this.lastReservationGroup = reservation.group;
					reservation.groupClass = 'group-border';
				} else {
					reservation.groupClass = '';
				}
				return reservation;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
td .action {
	display: none;
}
tr:hover .action {
	display: inline;
}
.noBorder {
	td {
		font-size: 9px;
		padding: 1px;
	}
	* {
		border: none;
	}
}
.availableReservations {
	min-width: 579px;
}
.group-border td {
	border-top-width: 2px;
}
.highlightBlank {
	background-color: #f5f588;
}
.text-pin {
	font-size: 1.2rem;
}

.selected-box {
	animation: selected-box-animation 1s linear 2;
	background: lavenderblush;
}

@keyframes selected-box-animation {
	from {
		background: red;
		color: white;
	}
	to {
		background: lavenderblush;
		color: initial;
	}
}
</style>
