<template>
	<div class="col-md-12">
		<div class="d-flex justify-content-between">
			<div class="align-self-center">
				<el-pagination
					class="flex-fill"
					layout="total, sizes, prev, pager, next, jumper"
					:current-page="currentPage"
					:page-count="pagesCount"
					:page-size="pageSize"
					:page-sizes="pageSizes"
					@current-change="$emit('go-to-page', $event)"
					@size-change="$emit('load-data-page-size', $event)" />
			</div>
			<div class="align-self-center">
				<slot name="actionButtons" />
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Paginator',
	props: {
		currentPage: {
			type: Number,
			default: 1,
		},
		pagesCount: {
			type: Number,
			default: 1,
		},
		pageSize: {
			type: Number,
			default: 10,
		},
		pageSizes: {
			type: Array,
			default: () => [10, 20, 50, 100],
		},
	},

};
</script>
