<template>
	<div class="row">
		<div class="col-md-12 card">
			<div class="d-flex align-items-center">
				<div class="col-2">
					<form-group-input :label="$t('admin.locked')" type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="filter.isLocked"
								:label="$t('admin.locked')"
								yes-icon="lock"
								no-icon="unlock"
								trigger-on-change-method
								@on-change-method="getEshopsList" />
						</div>
					</form-group-input>
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.name"
						:label="$t('admin.name')"
						trigger-on-focus
						search-field-name="name"
						@fetch-suggestions="querySearchAsync"
						@select="getEshopsList" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.mail" :label="$t('admin.email')" type="text" />
				</div>
			</div>
			<div class="col-2 d-flex mb-1 justify-content-start">
				<p-button type="success" size="md" @click="getEshopsList">
					{{ $t('admin.search') }}
				</p-button>
				<el-tooltip :content="$t('admin.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</div>

		<div v-loading="loading" class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="eshopsPage.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="eshopsPage.totalCount"
									:page-count="eshopsPage.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="goToPage"
									@size-change="loadDataPageSize" />
							</div>
							<router-link :to="{ name: 'eshop-new' }">
								<p-button type="success">
									<span class="fa fa-plus" /> {{ $t('admin.eshop') }}
								</p-button>
							</router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 mt-2">
						<el-table
							class="table-striped"
							:data="tableData"
							data-cy="ListMainTable"
							row-class-name="tableRow"
							:default-sort="{ prop: filter.orderby, order: filter.direction }"
							@sort-change="sortChange"
							@row-click="goToEdit">
							<el-table-column property="name" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.name')" placement="top-start">
										<span>{{ $t('admin.name') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>

							<el-table-column property="mail" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.email')" placement="top-start">
										<span>{{ $t('admin.email') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>

							<el-table-column property="isLocked" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.locked')" placement="top-start">
										<span>{{ $t('admin.locked') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div :key="scope.row.isLocked">
										<i v-if="scope.row.isLocked" class="fa fa-lock fa-lg text-danger" />
									</div>
								</template>
							</el-table-column>
							<el-table-column property="selfDelivery" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.SelfDeliveryHeader')" placement="top-start">
										<span>{{ $t('admin.SelfDeliveryHeader') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div :key="scope.row.selfDelivery">
										<i v-if="scope.row.selfDelivery" class="fa fa-shipping-fast text-danger" />
									</div>
								</template>
							</el-table-column>
							<el-table-column property="allowBillingReport" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.tooltips.allowBillingReportHeader')" placement="top-start">
										<span>{{ $t('admin.tooltips.allowBillingReportHeader') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div :key="scope.row.allowBillingReport">
										<i v-if="scope.row.allowBillingReport" class="fa fa-file-invoice-dollar fa-lg text-danger" />
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col mt-2">
						<el-pagination
							:current-page="eshopsPage.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:total="eshopsPage.totalCount"
							:page-count="eshopsPage.pagesCount"
							:page-size="pageSize"
							:page-sizes="pageSizes"
							@size-change="loadDataPageSize"
							@current-change="goToPage" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination, Tooltip as ElTooltip } from 'element-ui';

// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
import { resetFilter, setFilter } from '@/store/mutation-types';

export default {
	name: 'EshopList',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	data() {
		return {
			eshopsPage: Object,
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			loading: false,
			filter: {},
		};
	},
	computed: {
		...mapState(appName, ['eshopFilter']),
		tableData() {
			return this.eshopsPage.entities;
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'eshopFilter', data: val });
			},
		},
	},
	created() {
		this.filter = this.eshopFilter;
		this.getEshopsList();
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.getEshopsList();
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderby = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderby = null;
				this.filter.direction = null;
			}
			this.getEshopsList();
		},
		goToEdit(row) {
			this.$router.push({ name: 'eshop-edit', params: { id: row.id } });
		},
		goToPage(page) {
			this.page = page;
			this.getEshopsList();
		},
		loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			this.goToPage(1);
		},
		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'eshopFilter');
			this.filter = this.eshopFilter;
			this.getEshopsList();
		},
		async getEshopsList() {
			this.loading = true;
			this.eshopsPage = await this.$service.eshop.getList(this.filter, this.page, this.pageSize);
			this.loading = false;
		},
		async querySearchAsync(input, cb) {
			const results = await this.$service.deliveryPoint.searchByEshopInput(input);
			cb(results);
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
</style>
