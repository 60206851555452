import { stringify } from 'qs';
import { isNil, omitBy } from 'lodash';

export const ReservationService = (http) => ({
	async newReservationReturn(reservation) {
		const response = await http.post('/api/EshopReservation/NewReservationReturn', reservation);
		const responseDelivery = await this.delivery(response.data.unlockCode);

		await this.ackDelivery(responseDelivery.reservationId);

		return response.data;
	},
	async delivery(deliveryCode) {
		const response = await http.post('/api/Reservation/Delivery', {
			deliveryCode,
		});
		return response.data;
	},
	async ackDelivery(reservationId) {
		const response = await http.post('/api/Reservation/DeliveryAck', {
			reservationId,
		});
		return response.data;
	},
	async pickup(unlockCode) {
		const response = await http.post('/api/Reservation/Pickup', {
			unlockCode,
		});
		return response.data;
	},
	async ackPickup(payload) {
		const response = await http.post('/api/Reservation/PickupAck', payload);
		return response.data;
	},
	async ackCollected(reservationId) {
		const response = await http.post('/api/Reservation/CollectedAck', {
			reservationId,
		});
		return response.data;
	},
	async writeOffReservation(reservationId) {
		const response = await http.post('/api/Reservation/Lost', {
			reservationId,
		});
		return response.data;
	},
	async getOne(id) {
		const response = await http.get('/api/Reservation/Detail', { params: { ReservationId: id } });
		return response.data;
	},
	async printSticker(id) {
		const response = await http.post('/api/Sticker/a7', { reservationIds: [id] }, { responseType: 'blob' });
		const file = new Blob(
			[response.data],
			{ type: 'application/pdf' },
		);
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	},
	async getList(page, filter, pageSize, abortController) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			signal: abortController?.signal,
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};

		const response = await http.get('/api/Reservation/List', config);

		return response.data;
	},
	async pickupExpired(reservationId) {
		const response = await http.post('/api/Reservation/PickUpExpired', {
			reservationId,
		});
		return response.data;
	},
});
