<template>
	<div v-loading="loading" class="col-md-12 card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-12">
					<div class="d-flex justify-content-between">
						<div class="align-self-center">
							<el-pagination
								:current-page="page.currentPage"
								:page-count="page.pagesCount"
								:page-size="pageSize"
								:page-sizes="pageSizes"
								:total="page.totalCount"
								layout="total, sizes, prev, pager, next, jumper"
								@size-change="setPageSize"
								@current-change="loadData" />
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 mt-2">
					<el-table
						:data="reservations"
						:default-sort="{ prop: filter.orderBy, order: filter.direction }"
						data-cy="ListMainTable"
						row-class-name="tableRow"
						show-overflow-tooltip
						@row-click="goToEdit"
						@sort-change="sortChange">
						<el-table-column align="center" width="60">
							<template #header>
								<el-tooltip :content="$t('pickup.state')" placement="top-start">
									<span>{{ $t('pickup.state') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<el-tooltip :key="scope.row.status" :content="ReservationStatus.czech(scope.row.status)" placement="top-start">
									<div :key="scope.row.status">
										<i :class="ReservationStatus.icon(scope.row.status)" />
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column align="center" property="eshop.name" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('pickup.eshop')" placement="top-start">
									<span>{{ $t('pickup.eshop') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column align="center" property="reservationNumber" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('pickup.orderNumber')" placement="top-start">
									<span>{{ $t('pickup.orderNumber') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column align="center" class-name="td-actions" width="75">
							<template #header>
								<el-tooltip :content="$t('pickup.actions')" placement="top-start">
									<span>{{ $t('pickup.actions') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<p-button v-if="isPreparedOrWaitForCollect(scope.row.status)" type="danger" @click.prevent.stop="writeOffClicked(scope.row)">
									{{ $t('pickup.writeOff') }}
								</p-button>
							</template>
						</el-table-column>
						<el-table-column align="center" property="email" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('pickup.email')" placement="top-start">
									<span>{{ $t('pickup.email') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<trim-text :text="scope.row.email" />
							</template>
						</el-table-column>
						<el-table-column align="center" property="phone" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('pickup.phone')" placement="top-start">
									<span>{{ $t('pickup.phone') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ scope.row.phone | phone }}
							</template>
						</el-table-column>
						<el-table-column align="center" property="reservedFrom" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('pickup.occupiedFrom')" placement="top-start">
									<span>{{ $t('pickup.occupiedFrom') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ scope.row.reservedFrom | datetime }}
							</template>
						</el-table-column>
						<el-table-column align="center" property="expiresOn" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('pickup.subscriptionEnd')" placement="top-start">
									<span>{{ $t('pickup.subscriptionEnd') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ scope.row.expiresOn | datetime }}
							</template>
						</el-table-column>
						<el-table-column align="center" property="expiresOn" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('pickup.printSticker')" placement="top-start">
									<span>{{ $t('pickup.printSticker') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<p-button @click.stop="() => printSticker(scope.row.reservationId)">
									<i class="fa fa-print" aria-hidden />
								</p-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="row">
				<div class="col mt-2">
					<el-pagination
						:current-page="page.currentPage"
						:page-count="page.pagesCount"
						:page-size="pageSize"
						:page-sizes="pageSizes"
						:total="page.totalCount"
						layout="total, sizes, prev, pager, next, jumper"
						@size-change="setPageSize"
						@current-change="loadData" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip, Pagination as ElPagination } from 'element-ui';
import { isNumber } from 'lodash';

import { ReservationStatus } from '@/modules/pickup/store/enums';
import TrimText from '@/components/UIComponents/TrimText.vue';
import ValidationMixin from '@/util/validated-form-mixin';
import PButton from '@/components/UIComponents/PButton.vue';

export default {
	name: 'ReservationListTable',
	components: {
		TrimText,
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		PButton,
	},
	mixins: [ValidationMixin],
	props: {
		filter: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			loading: false,
			ReservationStatus,
			abortController: null,
		};
	},
	computed: {
		reservations() {
			return this.page.entities;
		},
	},
	watch: {
		filter: {
			handler() {
				this.loadData(this.page.currentPage);
			},
			immediate: true,
			deep: true,
		},
		pageSize: {
			async handler() {
				await this.loadData();
			},
		},
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		async printSticker(id) {
			await this.$service.reservation.printSticker(id);
		},
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		isPreparedOrWaitForCollect(status) {
			const { PREPARED, WAIT_FOR_COLLECT } = ReservationStatus;
			return ReservationStatus.enumValueOf(status) === PREPARED || ReservationStatus.enumValueOf(status) === WAIT_FOR_COLLECT;
		},
		writeOffClicked(reservation) {
			this.$emit('write-off-clicked', reservation);
		},
		goToEdit(row) {
			this.$router.push({ name: 'reservation-detail', params: { id: row.reservationId } });
		},
		sortChange(column) {
			const sortingData = {
				orderBy: null,
				direction: null,
			};

			if (column.order) {
				sortingData.orderBy = column.prop;
				sortingData.direction = column.order;
			}

			this.$emit('sorting-changed', sortingData);
		},

		async loadData(page = 1) {
			this.loading = true;
			this.abortController?.abort();
			this.abortController = new AbortController();
			this.page = await this.$service.reservation.getList(isNumber(page) ? page : 1, this.filter, this.pageSize, this.abortController);
			this.loading = false;
		},

		async setPageSize(pageSize) {
			this.pageSize = pageSize;
		},
	},
};
</script>
