<template>
	<div class="form-check-radio" :class="[inlineClass, { disabled: disabled }]">
		<label :for="cbId" class="form-check-label">
			<input
				:id="cbId"
				v-model="model"
				class="form-check-input"
				type="radio"
				:disabled="disabled"
				:value="label">
			<span class="form-check-sign" />
			<slot />
		</label>
	</div>
</template>

<script>
export default {
	name: 'PRadio',
	props: {
		label: {
			default: null,
			type: [String, Number],
			description: 'Radio label',
		},
		disabled: {
			type: Boolean,
			description: 'Whether radio is disabled',
		},
		value: {
			default: null,
			type: [String, Boolean],
			description: 'Radio value',
		},
		inline: {
			type: Boolean,
			description: 'Whether radio is inline',
		},
	},
	data() {
		return {
			cbId: '',
		};
	},
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		inlineClass() {
			if (this.inline) {
				return 'form-check-inline';
			}
			return '';
		},
	},
	created() {
		this.cbId = Math.random().toString(16).slice(2);
	},
};
</script>
