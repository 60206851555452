<template>
	<div class="row">
		<div class="col-12">
			<base-stepper v-if="!emptyReservation" :trace="reservation.trace" />
		</div>
		<div class="col-md-8">
			<reservation-detail :reservation="reservation" @get-one="getOne" @show-hide-update-reservation="showHideUpdateReservation" />
			<direct-sale-product-form v-if="reservation.stockItem" :stock-item="reservation.stockItem" readonly />
		</div>
		<div class="col-md-4">
			<el-tabs v-model="activeTab">
				<el-tab-pane :label="$t('eshop.container')" name="container-info">
					<container-info v-if="reservation.containerId" :reservation="reservation" />
				</el-tab-pane>
				<el-tab-pane :label="$t('eshop.note')" name="note">
					<reservation-note :reservation="reservation" />
				</el-tab-pane>
			</el-tabs>
			<reservation-sticker :reservation="reservationForSticker" :lock="lockAliasOrSerialNumber ? lockAliasOrSerialNumber.toString() : null" />
		</div>
		<el-dialog v-if="!emptyReservation" :visible.sync="updateReservationVisible">
			<base-update-reservation
				:reservation-id="reservation.reservationId"
				:reserved-from="reservation.from"
				:reserved-to="reservation.to"
				:customer="reservationCustomerData"
				:customer-old-address="reservation.customerAddress"
				@refresh-reservation="refreshReservation" />
		</el-dialog>
	</div>
</template>

<script>
import { TabPane as ElTabPane, Tabs as ElTabs, Dialog as ElDialog } from 'element-ui';

import DirectSaleProductForm from '@/modules/eshop/views/DirectSale/DirectSaleProductForm.vue';
import ReservationNote from '@/components/Base/Shared/ReservationNote.vue';
import { BaseStepper, BaseUpdateReservation } from '@/components/Base';
import FormMixin from '@/util/form-mixin';
import LockAliasMixin from '@/util/lock-alias-mixin';
import ReservationFormMixin from '@/util/reservation-form-mixin';

import ReservationDetail from './ReservationDetails/ReservationDetail.vue';
import ReservationSticker from './ReservationDetails/ReservationSticker.vue';
import ContainerInfo from './ReservationDetails/ContainerInfo.vue';

export default {
	name: 'ReservationForm',
	components: {
		ReservationDetail,
		ReservationSticker,
		ContainerInfo,
		BaseUpdateReservation,
		ElDialog,
		DirectSaleProductForm,
		BaseStepper,
		ElTabs,
		ReservationNote,
		ElTabPane,
	},
	mixins: [FormMixin, LockAliasMixin, ReservationFormMixin],

	data() {
		return {
			activeTab: 'note',
			reservationForSticker: {},
			updateReservationVisible: false,
			lockAliasOrSerialNumber: '',
		};
	},

	computed: {
		emptyReservation() {
			return Object.keys(this.reservation).length === 0;
		},
	},

	created() {
		this.getOne();
	},

	methods: {
		async getOne() {
			this.reservation = await this.$service.eshop.getOne(this.$route.params.id);
			if (!this.reservation.customer) {
				this.reservation.customer = {};
			}
			if (!this.reservation.customer.email) {
				this.reservation.customer.email = this.reservation.email;
			}
			if (!this.reservation.customer.phone) {
				this.reservation.customer.phone = this.reservation.phone;
			}
			if (this.reservation.isPostReceive) {
				this.reservationForSticker = this.reservation.postDestination;
				this.reservationForSticker.sender = this.reservation.customer;
				this.reservationForSticker.isPostReceive = this.reservation.isPostReceive;
				this.reservationForSticker.isPostSend = this.reservation.isPostSend;
			} else { this.reservationForSticker = this.reservation; }
			this.reservationForSticker.deliveryCode = this.reservation?.deliveryCode;

			this.loadLockAliasOrSerialNumberForBox(this.$service.smartBox, this.reservation.containerType, this.reservation.serialNumber);
		},

		refreshReservation() {
			this.getOne();
			this.showHideUpdateReservation();
		},

		showHideUpdateReservation() {
			this.updateReservationVisible = !this.updateReservationVisible;
		},
	},
};
</script>

<style lang="scss">
.text-monospace textarea {
	font-family: monospace;
	font-size: 0.75rem;
}
</style>
