import { QrCodeService } from '@/service/qr-code-service';

import { AdminService } from './admin-service';
import { ReservationService } from './reservation-service';
import { LogisticsRouteService } from './logistics-route-service';

export default (http) => ({
	admin: AdminService(http),
	logisticsRoute: LogisticsRouteService(http),
	qrCode: QrCodeService(http),
	reservation: ReservationService(http),
});
