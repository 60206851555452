<template>
	<base-user-form
		add-user-function="addEmployee"
		link-back="employee-list"
		new-title="Nový zaměstnanec"
		show-pickup-place
		title="Zaměstanec" />
</template>

<script>
import FormMixin from '@/util/form-mixin';
import { BaseUserForm } from '@/components/Base';

export default {
	name: 'EmployeeForm',
	components: { BaseUserForm },
	mixins: [FormMixin],
};
</script>
