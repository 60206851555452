export const EshopService = (http) => ({
	async getList(filter, page = 1, pageSize = 10) {
		const response = await http.get('/api/Eshop/List', {
			params: {
				Page: page,
				PageSize: pageSize,
				...filter,
			},
		});

		return response.data;
	},

	async getOne(id) {
		const response = await http.get('/api/Eshop/Detail', { params: { Id: id } });
		return response.data;
	},

	async add(eshop) {
		const response = await http.post('/api/Eshop/Add', eshop);

		return response.data;
	},

	async update(eshop) {
		const response = await http.post('/api/Eshop/Update', eshop);

		return response.data;
	},
});
