import { mapActions } from 'vuex';

import LoggedOutLayout from '@/components/Layouts/LoggedOutLayout.vue';
import Child from '@/components/Layouts/LoggedOut/Child.vue';
import {
	BaseLogin,
	BaseForgotPassword,
	BaseResetPassword,
	BaseConfirmation,
	BaseChangePasswordForm,
	BasePickupDeliveryForm,
} from '@/components/Base';
import ReservationList from '@/modules/pickup/views/Reservation/ReservationList.vue';
import ReservationDetail from '@/modules/pickup/views/Reservation/ReservationDetail.vue';
import NewReturnOrder from '@/modules/pickup/views/Returns/NewReturnOrder.vue';
import { authenticate, logout } from '@/store/action-types';
import { appName } from '@/modules/pickup/config';
import { i18n } from '@/i18n';

export const routes = [
	{
		component: LoggedOutLayout,
		path: '',
		meta: {
			title: i18n.t('admin.pickupPlace'),
		},
		children: [
			{
				path: '/vydejna',
				children: [
					{ name: 'login', path: '', component: BaseLogin, props: { ...mapActions(appName, [authenticate, logout]) } },
					{ name: 'forgot-password', path: 'zapomenute-heslo', component: BaseForgotPassword },
					{ name: 'pass-reset', path: 'ResetPass/:user', component: BaseResetPassword },
					{ name: 'mail-confirm', path: 'Confirmation/:user', component: BaseConfirmation },
				],
				component: Child,
			},
		],
	},
	{
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "pickup" */ '../../components/Layouts/DashboardLayout.vue'),
		path: '/vydejna/',
		redirect: '/vydejna/prijem-vydej',
		meta: {
			title: i18n.t('admin.pickupPlace'),
		},
		children: [
			{
				name: 'password-change',
				path: 'zmena-hesla',
				component: BaseChangePasswordForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.passwordChange') }],
				},
			},
			{
				name: 'pickup-delivery',
				path: 'prijem-vydej',
				component: BasePickupDeliveryForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.receivingAndIssuing') }],
				},
				props: { confirmButtonDescription: i18n.t('admin.receiveOrIssue') },
			},
			{
				name: 'reservation-list',
				path: 'rezervace',
				component: ReservationList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservation') }],
				},
			},
			{
				name: 'reservation-detail',
				path: 'rezervace/detail/:id',
				component: ReservationDetail,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservation'), link: 'reservation-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'return-order',
				path: 'vratka',
				component: NewReturnOrder,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.returnOrder') }],
				},
			},
		],
	},
];
