<template>
	<card>
		<div class="row mx-1 mb-3">
			<p-button @click="cleanSheetForPrint">
				<i slot="labelRight" class="fa fa-times" />
				{{ $t('eshop.delete') }}
			</p-button>
			<p-button @click="print">
				<i slot="labelRight" class="fa fa-print" />
				{{ $t('eshop.print') }}
			</p-button>
		</div>
		<div id="printArea" class="printable a6">
			<div class="page">
				<div v-for="(reservation, key) in sheetForPrint" :key="`pageid-${key}`" class="shipping-label m-2">
					<div class="shipping-label-2">
						<div class="shipping-label-section-1">
							<div>
								<span class="shipping-label-serial-number">{{ reservation.containerSerialNumber }}</span>
								<span
									v-if="reservation.boxSerialNumber && reservation.containerType !== 'Store'"
									class="shipping-label-box">
									<span class="shipping-label-separator">/</span>
									<span v-if="!reservation.isSingleBoxInContainer">{{ (reservation.lockName || "").split('-')[1] || reservation.boxSerialNumber }}</span>
									<i
										v-if="reservation.isSingleBoxInContainer"
										:class="reservation.isBoxPublic ? 'fa fa-city' : 'fa fa-user-secret'" />
								</span>
								<br>
								<span v-if="reservation.boxType && reservation.containerType !== 'Store'" class="shipping-label-box-size">{{ boxType(reservation) }}</span>
							</div>
							<div>
								<span v-if="reservation.containerType === 'SmartKeyBox'" class="shipping-label-right">SMARTBOX</span>
								<span v-if="!reservation.containerId" class="shipping-label-right">ADRESA</span>
								<div
									v-if="reservation.containerId
										&& reservation.containerType !== 'SmartKeyBox'
										&& reservation.containerType !== 'PrivateAddress'
										&& reservation.deliveryCode"
									class="shipping-label-right">
									<div v-if="reservation.packageNumber === 1">
										<span class="shipping-label-pin">PIN</span>
										<strong>{{ reservation.deliveryCode }}</strong>
									</div>
									<span v-if="reservation.packageNumber > 1" class="shipping-label-pin">PIN na 1/{{ reservation.packagesCount }}</span>
								</div>
							</div>
						</div>
						<div class="shipping-label-section-2">
							<span v-if="getStateAbbreviation(reservation)" class="shipping-label-region-state-abbr">
								<strong>
									{{ getStateAbbreviation(reservation) }}
								</strong>
							</span>
							<span class="shipping-label-region"><strong>{{ reservation.region }}</strong></span>
							<span class="shipping-label-county">{{ reservation.county }}</span>
						</div>
						<div class="shipping-label-section-3">
							<div class="shipping-label-delivery-point">
								<strong>Výdejní místo</strong>
							</div>
							<div class="shipping-label-delivery-point-address">
								<div v-if="!reservation.containerId">
									Doručení na adresu
								</div>
								<div v-else>
									<span v-if="reservation.street"> {{ reservation.street }}</span><br>
									<span v-if="reservation.city">{{ reservation.city }}</span><br>
									<span v-if="reservation.location">{{ reservation.location }}</span>
								</div>
							</div>
						</div>
						<div class="shipping-label-section-4">
							<div class="shipping-label-reservation-number">
								<strong>Číslo obj.</strong>
							</div>
							<div v-if="reservation.packagesCount > 1" class="shipping-label-reservation-number-value">
								<span class="reservation-number-value">{{ reservation.reservationNumber }}</span>
								<span class="shipping-label-package-count">{{ reservation.packageNumber }} / {{ reservation.packagesCount }}</span>
							</div>
							<div v-else class="shipping-label-reservation-number-value">
								<span>{{ reservation.reservationNumber }}</span>
							</div>
							<div
								class="shipping-label-right">
								<span class="shipping-label-pin">ID</span>
								<strong>{{ reservation.reservationId }}</strong>
							</div>
						</div>
						<div class="shipping-label-section-5">
							<div class="shipping-label-recipient">
								<strong>Příjemce</strong>
							</div>
							<div class="shipping-label-recipient-details">
								<div v-if="displayCustomerName(reservation)">
									{{ getFirstName(reservation) }}<strong>{{ getLastName(reservation) }}</strong>
								</div>
								<div v-if="displayCustomerAddress(reservation)">
									{{ getCustomerAddress(reservation) }}
								</div>
								<div v-if="reservation.phone" class="shipping-label-recipient-details-phone">
									<i class="fa fa-phone" aria-hidden="true" />
									{{ reservation.phone }}
								</div>
							</div>
						</div>
						<div class="shipping-label-section-6">
							<div class="shipping-label-sender">
								<strong>Odesílatel</strong>
								<div v-if="reservation.isPostReceive || reservation.isPostSend && reservation.sender">
									<div>{{ reservation.sender.fullName }}</div>
									<div>{{ reservation.sender.street }}</div>
									<div>{{ reservation.sender.city }}</div>
									<div>{{ reservation.sender.zip }}</div>
								</div>
								<div v-else-if="reservation.eshop">
									<div>{{ reservation.eshop.name }}</div>
									<div>{{ reservation.eshop.addressLine1 }}</div>
									<div>{{ reservation.eshop.addressLine2 }}</div>
									<div>{{ reservation.eshop.link }}</div>
								</div>
							</div>
							<div class="shipping-label-penguinbox">
								<img :alt="title" src="/static/img/StickerLogo.svg">
							</div>
							<div v-if="reservation.qrCode" class="shipping-label-qr">
								<qr-code
									:text="reservation.qrCode"
									:size="128"
									error-level="L"
									class="qr-code-el" />
							</div>
						</div>
					</div>
					<div class="d-print-none">
						<el-tooltip :content="$t('eshop.tooltips.deleteFromCollectionList')" placement="top-start">
							<p-button
								type="danger"
								size="sm"
								class="my-0 button-close"
								icon
								round
								@click="removeSheetForPrint(key)">
								<i class="fa fa-times" />
							</p-button>
						</el-tooltip>
					</div>
				</div>
			</div>
		</div>
	</card>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Tooltip as ElTooltip } from 'element-ui';
import VueQRCodeComponent from 'vue-qr-generator';

import { cleanSheetForPrint, removeSheetForPrint } from '@/modules/eshop/store/mutation-types';
import { convertCoordinatesToRad } from '@/util/container-mixin';
import notifyService from '@/service/notify-service';
import { BoxType } from '@/modules/eshop/store/enums';
import { appName } from '@/modules/eshop/config';
import { getAbbrState } from '@/util/helpers';

export default {
	name: 'ReservationStickerPrint',
	components: {
		ElTooltip,
		'qr-code': VueQRCodeComponent,
	},
	data() {
		return {
		};
	},
	computed: {
		...mapState(appName, ['sheetForPrint']),
	},
	created() {
		if (!this.sheetForPrint.length) {
			notifyService.notifyError(this.$t('eshop.printPageError'));
			this.$router.push({ name: 'reservation-list' });
		}
	},
	mounted() {
		this.$nextTick(() => {
			setTimeout(this.print, 500);
		});
	},
	methods: {
		...mapMutations(appName, [cleanSheetForPrint, removeSheetForPrint]),
		getLatitude(lat) {
			return convertCoordinatesToRad(lat);
		},
		getLongitude(lon) {
			return convertCoordinatesToRad(lon);
		},
		print() {
			const win = window.open('', '', 'fullscreen=yes;titlebar=yes');
			const element = document.getElementById('printArea');

			this.writeHtml(win, element.outerHTML);
			this.addStyles(win, '/static/css/printing-sticker.css');
			this.addStyles(win, 'https://use.fontawesome.com/releases/v5.15.0/css/all.css');
			this.printDocument(win);
		},
		printDocument(win) {
			win.document.close();
			setTimeout(() => {
				win.print();
			}, 500);

			setTimeout(() => {
				win.close();
			}, 3000);
		},
		addStyles(win, url) {
			const link = win.document.createElement('link');
			link.setAttribute('rel', 'stylesheet');
			link.setAttribute('type', 'text/css');
			link.setAttribute('href', url);
			win.document.getElementsByTagName('head')[0].appendChild(link);
		},
		writeHtml(win, bodyContent) {
			win.document.write(`
        <html lang="cs">
          <body>
            ${bodyContent}
          </body>
        </html>
      `);
		},
		typeCzech(type) {
			return BoxType.czech(type);
		},
		displayCustomerName(reservation) {
			if (reservation.customer) {
				return reservation.customer?.fullName;
			}
			return false;
		},
		displayCustomerAddress(reservation) {
			if (reservation.customer) {
				const { street, county, zip, state } = reservation.customer;
				return !!(street || county || zip || state);
			}
			return '';
		},
		getCustomerAddress(reservation) {
			if (reservation.customer) {
				const { street, city, zip } = reservation.customer;
				let resultAddress = '';
				if (street) {
					resultAddress += street.trim();
				}
				if (city) {
					resultAddress += `, ${city.trim()}`;
				}
				if (zip) {
					resultAddress += `, ${zip.trim()}`;
				}

				return resultAddress;
			}
			return '';
		},
		getStateAbbreviation(reservation) {
			const { state } = reservation.customer;
			const abbrState = state ? getAbbrState(state) : null;
			if (abbrState) {
				return abbrState;
			}
			return '';
		},
		getFirstName(reservation) {
			const splitted = reservation.customer.fullName.split(' ');
			if (splitted.length > 1) {
				splitted.pop();
				return splitted.join(' ');
			}
			return reservation.customer.fullName;
		},
		getLastName(reservation) {
			const splitted = reservation.customer.fullName.split(' ');
			if (splitted.length > 1) {
				return ` ${splitted.pop()}`;
			}
			return '';
		},
		boxType(reservation) {
			return BoxType.czech(reservation.boxType);
		},
	},
};
</script>

<style lang="scss" scoped>
.d-print-none {
	.button-close {
		position: absolute;
		left: -15px;
        top: -20px;
	}
}

.a4,
.a6 {
	.page {
		.shipping-label {
			font-size: 0.6rem;
			max-height: 15cm;

			table {
				height: 100%;

				tr {
					&:last-of-type {
						height: 100%;
					}

					th:first-of-type {
						width: 40%;
					}
				}
			}
		}
	}
}

@media not print {
	.a4,
	.a6 {
		width: auto;
		.page {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: baseline;

			.shipping-label {
				width: 9.5cm;
			}
		}
	}
}

@media print {
	.a4,
	.a6 {
		.page {
			page-break-after: always;

			.shipping-label {
				width: 10cm;
				page-break-inside: avoid;
			}
		}
	}

	.a4 {
		width: 21cm;
		.shipping-label {
			display: inline-block;
			vertical-align: top;
			&:nth-of-type(1 + 4n) {
				page-break-after: always;
			}
		}
	}

	.a6 {
		.page {
			display: block;

			.shipping-label {
				margin: 0 !important;
				page-break-after: always;
			}
		}
	}
}

.text-pin {
	font-size: 1.2rem;
}

.shipping-label-2 {
	margin-top: 12px;
	border: 3px solid black;
	width: 360px;

	& .shipping-label-section-1 {
		display: flex;
		justify-content: space-between;
	}

	& .shipping-label-section-1,
	& .shipping-label-section-2 {
		border-bottom: 1px solid black;
	}

	& .shipping-label-section-1,
	& .shipping-label-section-2,
	& .shipping-label-section-6 {
		padding: 5px;
	}

	& .shipping-label-section-3,
	& .shipping-label-section-5 {
		display: flex;
		border-bottom: 3px solid black;
	}
	& .shipping-label-section-4{
		display: flex;
		border-bottom: 1px solid black;
		padding-right: 5px;
	}

	& .shipping-label-section-6 {
		display: flex;
	}

	& .shipping-label-serial-number {
		font-size: 22px;
	}

	& .shipping-label-right {
		font-size: 22px;
	}
	& .shipping-label-region,
	& .shipping-label-region-state-abbr {
		font-size: 16px;
	}
	& .shipping-label-county {
		font-size: 15px;
		float: right;
	}
	& .shipping-label-delivery-point,
	& .shipping-label-recipient{
		font-size: 16px;
		max-width: 87px;
        min-width: 87px;
		border-right: 1px solid black;
		padding: 5px;
	}

	& .shipping-label-delivery-point-address,
	& .shipping-label-recipient-details {
		padding: 5px;
		flex-grow: 1;
		font-size: 16px;
		min-width: 0;
	}

	& .shipping-label-reservation-number{
		font-size: 16px;
		max-width: 87px;
        min-width: 87px;
		border-right: 1px solid black;
		padding: 5px;
		line-height: 25px;
	}

	& .shipping-label-reservation-number-value{
		font-size: 19px;
		padding: 5px;
		font-weight: bold;
		line-height: 25px;
		justify-content: space-between;
		display: flex;
		flex-grow: 1;
		min-width: 0;

		& .reservation-number-value{
			min-width: 0;
		}
	}

	& .shipping-label-recipient-details-phone {
		text-align: right;
		margin-top: 10px;
	}

	& .shipping-label-sender {
		flex-grow: 1;
		font-size: 16px;
	}
	& .shipping-label-qr {
		width: 128px;
	}

	& .shipping-label-pin {
		margin-right: 5px;
		font-size: 75%;
	}

	& .shipping-label-id {
		margin-right: 5px;
		font-size: 75%;
	}

	& .shipping-label-box {
		font-size: 22px;
	}

	& .shipping-label-separator {
		margin-left: 5px;
		margin-right: 5px;
	}

	& .shipping-label-box-size {
		font-size: 16px;
		text-transform: uppercase;
	}
	& .shipping-label-qr-empty {
		width: 128px;
		height: 128px;
		text-align: center;
		line-height: 128px;
		border: 2px dashed;
		border-radius: 5px;
		font-size: 16px;
	}

	& .shipping-label-penguinbox {
		writing-mode: vertical-rl;
		width: 25px;
		background-color: black;
		color: white;
		margin-top: -5px;
		margin-bottom: -5px;
		margin-right: 5px;
		padding: 2px;
	}
}
</style>
