<template>
	<div class="groupHeadline">
		<div class="font-weight-bold">
			{{ headline }}
		</div>
		<div v-if="textDescription" class="font-italic">
			{{ textDescription }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'NewOrderGroupHeadline',
	props: {
		headline: {
			type: String,
			required: true,
		},
		textDescription: {
			type: String,
			default: null,
		},
	},
};
</script>

<style scoped>
.groupHeadline {
  margin-top: 25px;
  margin-bottom: 9px;
}
</style>
