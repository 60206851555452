<template>
	<el-tooltip
		:content="content"
		placement="top-start">
		<span>{{ content }}</span>
	</el-tooltip>
</template>

<script>

export default {
	name: 'EasyTooltip',
	props: { content: { type: String, default: '' } },
};

</script>
