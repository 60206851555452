import { QrCodeService } from '@/service/qr-code-service';

import { AdminService } from './admin-service';
import { ContainerTreeViewService } from './container-tree-view-service';
import { ListService } from './list-service';
import { ReservationService } from './reservation-service';

export default (http) => ({
	admin: AdminService(http),
	containerTreeView: ContainerTreeViewService(http),
	list: ListService(http),
	qrCode: QrCodeService(http),
	reservation: ReservationService(http),
});
