<template>
	<div class="row">
		<div class="col-md-12 card">
			<div class="col d-flex mt-2">
				<form-group-input :label="$t('logistics.active')" type="text">
					<div class="d-flex mt-1">
						<yes-no-all
							v-model="reportFilter.isActive"
							:no-text="$t('logistics.inactive')"
							:yes-text="$t('logistics.active')"
							trigger-on-change-method
							@on-change-method="loadData" />
					</div>
				</form-group-input>
				<div class="col">
					<form-group-input v-model="reportFilter.name" :label="$t('logistics.name')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="reportFilter.note" :label="$t('logistics.note')" type="text" />
				</div>
				<div v-if="withContainers" class="col-4">
					<label class="d-block">{{ $t('logistics.containers') }}</label>
					<el-select
						ref="containerSelect"
						v-model="reportFilter.containers"
						class="w-100 container-select"
						collapse-tags
						filterable
						multiple
						clearable
						remote
						:remote-method="filterFreeContainers"
						:placeholder="$t('eshop.containerSearchHint')"
						@change="containerSelectOnChange"
						@visible-change="onVisibleChange">
						<el-option
							v-for="opt in freeContainersFiltered"
							:key="opt.containerId"
							:label="formatContainerName(opt)"
							:value="opt.containerId"
							class="select-primary">
							<img v-if="opt.type === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
							<img v-if="opt.type === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
							<i v-if="opt.type === 'PrivateAddress'" class="fa fa-home text-dark" />
							<i v-if="opt.type === 'SmartKeyBox'" class="nc-icon nc-mobile text-dark" />
							<span class="pl-2">{{ formatContainerName(opt) }}</span>
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="d-flex justify-content-between">
				<div class="col-2 d-flex mb-1 justify-content-start">
					<p-button type="success" size="md" @click="loadData()">
						{{ $t('logistics.search') }}
					</p-button>
					<el-tooltip :content="$t('logistics.tooltips.clearFilter')" placement="top-start">
						<p-button
							class="ml-2"
							type="danger"
							icon
							size="md"
							@click="clearFilter">
							<i class="fa fa-times" />
						</p-button>
					</el-tooltip>
				</div>
			</div>
		</div>

		<div v-loading="loading" class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col">
						<el-pagination
							:current-page="page.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.totalCount"
							:page-count="page.pagesCount"
							:page-size="pageSize"
							:page-sizes="pageSizes"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
				</div>
				<div class="col-sm-12">
					<el-table
						data-cy="ListMainTable"
						class="table-striped"
						row-class-name="tableRow"
						:data="reportList"
						:default-sort="{ prop: reportFilter.orderBy, order: reportFilter.direction }"
						@row-click="goToEdit"
						@sort-change="sortChange">
						<el-table-column property="createdBy.name" header-align="left" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('logistics.createdBy')" placement="top-start">
									<span>{{ $t('logistics.createdBy') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ getCreatedByName(scope.row.createdBy) }}
							</template>
						</el-table-column>
						<el-table-column property="createdDate" header-align="left" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('logistics.createdDate')" placement="top-start">
									<span>{{ $t('logistics.createdDate') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ scope.row.createdDate | datetime }}
							</template>
						</el-table-column>
						<el-table-column property="driver" header-align="left" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('logistics.driver')" placement="top-start">
									<span>{{ $t('logistics.driver') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ getDriverName(scope.row.driver) }}
							</template>
						</el-table-column>
						<el-table-column property="isActive" align="center" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('logistics.active')" placement="top-start">
									<span>{{ $t('logistics.active') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<div :key="scope.row.isActive">
									<i v-if="scope.row.isActive" class="fa fa-check fa-lg text-success" />
									<i v-else type="danger" class="fa fa-times fa-lg text-danger" />
								</div>
							</template>
						</el-table-column>
						<el-table-column property="name" header-align="left" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('logistics.name')" placement="top-start">
									<span>{{ $t('logistics.name') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column property="note" header-align="left" sortable="custom">
							<template #header>
								<el-tooltip :content="$t('logistics.note')" placement="top-start">
									<span>{{ $t('logistics.note') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="row">
					<div class="col mt-2">
						<el-pagination
							:current-page="page.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.totalCount"
							:page-count="page.pagesCount"
							:page-size="pageSize"
							:page-sizes="pageSizes"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {
	Table as ElTable,
	TableColumn as ElTableColumn,
	Pagination as ElPagination,
	Tooltip as ElTooltip,
	Option as ElOption,
	Select as ElSelect,
} from 'element-ui';

import ContainerSelectMixin from '@/util/container-select-mixin';

export default {
	name: 'BaseReportList',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElSelect,
		ElOption,
	},
	mixins: [ContainerSelectMixin],
	props: {
		withContainers: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			containersList: {},
			loading: false,
		};
	},
	computed: {
		reportFilter: {
			get() {
				return this.$store.state[this.currentAppName].reportFilter;
			},
			set(val) {
				this.$store.state.reportFilter = val;
			},
		},
		freeContainers() {
			this.enrichFreeContainers(this.containersList.entities);
			return this.containersList.entities;
		},
		reportList() {
			return this.page.entities;
		},
	},
	async created() {
		this.loadData();

		if (this.withContainers) {
			this.containersList = await this.$service.list.getContainerList();
		}
	},

	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
		this.setContainerSelectEventListener();
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},

		goToEdit(row) {
			this.$router.push({ name: 'report-detail', params: { id: row.logisticRouteId } });
		},
		sortChange(column) {
			if (column.order) {
				this.reportFilter.orderBy = column.prop;
				this.reportFilter.direction = column.order;
			} else {
				this.reportFilter.orderBy = null;
				this.reportFilter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},

		async loadData(page = 1) {
			this.loading = true;
			this.page = await this.$service.logisticsRoute.getList(this.reportFilter, page, this.pageSize);
			this.loading = false;
		},

		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		getCreatedByName(createdBy) {
			return createdBy ? `${createdBy.name} ${createdBy.surname}` : null;
		},

		getDriverName(driver) {
			return driver ? `${driver.name} ${driver.surname}` : null;
		},

		formatContainerName(container) {
			return this.$options.filters.location(container);
		},

		clearFilter() {
			this.$store.commit(`${this.currentAppName}/resetFilter`, 'reportFilter');
			this.loadData();
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
</style>
