<template>
	<div class="card">
		<div class="card-body">
			<h2 class="card-title">
				{{ $t('eshop.productGallery') }}
			</h2>
			<div v-if="imagesData.length > 0" class="d-flex flex-wrap my-2">
				<div v-for="image in images" :key="image.imageId" class="block image-container">
					<el-image :src="`data:image/png;base64, ${image.imageData}`" :preview-src-list="previewSrcList" fit="cover" />
					<el-tooltip :content="$t('eshop.tooltips.deleteImage')" placement="top-start">
						<confirm-button
							:key="image.imageId"
							class="delete-button"
							:confirm-message="$t('eshop.deleteStockItemImage')"
							:message-success="$t('eshop.deleteStockItemImageSuccess')"
							:message-error="$t('eshop.deleteStockItemImageError')"
							type="danger"
							icon
							:callback="() => handleDelete(image)">
							<i class="fa fa-times" />
						</confirm-button>
					</el-tooltip>
				</div>
			</div>
			<div v-else-if="loading" class="my-3 text-secondary">
				{{ $t('eshop.loadingData') }}
			</div>
			<div v-else class="my-3 text-secondary">
				{{ $t('eshop.noData') }}
			</div>
			<div v-if="imagesData.length > 0" class="d-flex">
				<el-select v-model="pageSize" class="w-25" @change="pageSizeChange">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value" />
				</el-select>
				<p-pagination
					v-model="currentPage"
					:page-count="pageCount"
					class="mt-1"
					layout="prev,pager,next"
					@input="calcPageCount" />
			</div>
			<div v-if="!readonly" class="card-body row">
				<el-upload
					ref="uploadImages"
					:action="addImageLink"
					accept="image/jpeg"
					name="image"
					multiple
					:disabled="disableImgBtns"
					:on-success="reloadImages"
					auto-upload
					:headers="headers"
					:before-upload="allowImageUpload">
					<p-button slot="trigger" :disabled="disableImgBtns" size="small">
						{{ $t('eshop.insertImages') }}
					</p-button>
					<p-button
						v-if="canUploadImages"
						:disabled="disableImgBtns"
						class="ml-3"
						type="success"
						@click="uploadImages">
						{{ $t('eshop.save') }}
					</p-button>
				</el-upload>
			</div>
		</div>
	</div>
</template>

<script>
import { Upload as ElUpload, Image as ElImage, Select as ElSelect, Option as ElOption, Tooltip as ElTooltip } from 'element-ui';

import notifyService from '@/service/notify-service';
import http from '@/service/http';
import { PPagination } from '@/components/UIComponents';

export default {
	name: 'DirectSaleGalleryCard',
	components: {
		ElImage,
		ElSelect,
		ElOption,
		ElUpload,
		ElTooltip,
		PPagination,
	},
	props: {
		readonly: {
			type: Boolean,
			default: false,
		},
		stockItemId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			imagesData: [],
			previewSrcList: [],
			disableImgBtns: false,
			currentPage: 1,
			pageCount: null,
			pageSize: 4,
			imagesBase64: [],
			loading: false,
			options: [
				{
					value: 4,
					label: 4,
				},
				{
					value: 8,
					label: 8,
				},
				{
					value: 12,
					label: 12,
				},
				{
					value: 16,
					label: 16,
				},
			],
		};
	},
	computed: {
		containerId() {
			return this.$route.params.id;
		},
		images() {
			return this.imagesData.slice(this.currentPage * this.pageSize - this.pageSize, this.currentPage * this.pageSize);
		},
		addImageLink() {
			return `/api/StockItem/${this.$route.params.id}/Images/Add`;
		},
		canUploadImages() {
			return !!this.imagesBase64.length;
		},
		headers() {
			return http.defaults.headers.common;
		},
	},
	async created() {
		await this.reloadImages();
	},
	methods: {
		async reloadImages() {
			this.loading = true;
			this.imagesData = [];
			this.previewSrcList = [];
			this.imagesBase64 = [];
			if (this.$refs.uploadImages) {
				this.$refs.uploadImages.clearFiles();
			}
			const imagesList = await this.$service.stockItem.getImagesList(this.stockItemId);
			imagesList.imageIds.forEach(async (image) => {
				const imageData = await this.$service.stockItem.getImage(this.stockItemId, image);
				this.imagesData.push({ imageData: imageData.imageData, imageId: image });
				this.previewSrcList.push(`data:image/png;base64, ${imageData.imageData}`);
			});
			this.calcPageCount();
			this.loading = false;
		},
		calcPageCount() {
			this.pageCount = Math.ceil(this.imagesData.length / this.pageSize);
		},
		pageSizeChange() {
			this.calcPageCount();
			this.currentPage = 1;
		},

		allowImageUpload(file) {
			const isJPG = file.type === 'image/jpeg';
			const isLt1M = file.size / 1024 / 1024 < 1;

			if (!isJPG) {
				notifyService.notifyError(this.$t('eshop.mustBeJPGImage'));
			}
			if (!isLt1M) {
				notifyService.notifyError(`${this.$t('eshop.maxSizeIs')} 1MB!`);
			}
			return isJPG && isLt1M;
		},

		async handleDelete(image) {
			await this.$service.stockItem.deleteImage(this.$route.params.id, image.imageId);
			await this.reloadImages();
		},
	},
};
</script>

<style scoped>
.image-container {
	position: relative;
	margin: 5px;
}

.el-image {
	width: 100px;
	height: 100px;
}

.btn.btn-icon {
	min-width: 0;
}

.image-container .delete-button {
	position: absolute;
	top: -20%;
	left: 85%;
	height: 20%;
	width: 20%;
	border-radius: 50%;
}
</style>
