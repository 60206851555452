<template>
	<div class="card">
		<div class="card-body">
			<div class="clearfix">
				<h3 class="pull-left">
					PMA info
				</h3>
				<p-button
					v-if="!containerDisabled && locksForUserAddedKeys.length"
					class="pull-right"
					size="md"
					type="success"
					@click="(showAddAccessModal = true)">
					{{ $t('admin.addAccess') }}
				</p-button>
			</div>
			<el-tabs>
				<el-tab-pane :label="$t('admin.activeKeys')">
					<el-table
						:data="activeKeys"
						class="table-striped"
						:row-class-name="getRowClass"
						@row-click="goToReservationDetail">
						<el-table-column property="Email">
							<template #header>
								<el-tooltip :content="$t('admin.email')" placement="top-start">
									<span>{{ $t('admin.email') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column label="Alias / Mac">
							<template #default="scope">
								{{ getAlias(scope.row) }}
							</template>
							<template #header>
								<el-tooltip :content="$t('admin.alias')" placement="top-start">
									<span>{{ $t('admin.alias') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column property="ReservationId">
							<template #header>
								<el-tooltip :content="$t('admin.reservation')" placement="top-start">
									<span>{{ $t('admin.reservation') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column property="Type">
							<template #header>
								<el-tooltip :content="$t('admin.type')" placement="top-start">
									<span>{{ $t('admin.type') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ getTypeDesc(scope.row.Type) }}
							</template>
						</el-table-column>
						<el-table-column property="WasSyncedToMobile">
							<template #header>
								<el-tooltip :content="$t('admin.mobile')" placement="top-start">
									<span>{{ $t('admin.mobile') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<i v-if="scope.row.WasSyncedToMobile" class="fa fa-check" />
							</template>
						</el-table-column>
						<el-table-column property="ValidFrom">
							<template #header>
								<el-tooltip :content="$t('admin.validFrom')" placement="top-start">
									<span>{{ $t('admin.validFrom') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ scope.row.ValidFrom | datetime }}
							</template>
						</el-table-column>
						<el-table-column property="Expire">
							<template #header>
								<el-tooltip :content="$t('admin.expire')" placement="top-start">
									<span>{{ $t('admin.expire') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ scope.row.Expire | datetime }}
							</template>
						</el-table-column>
						<el-table-column>
							<template #header />
							<template #default="scope">
								<confirm-button
									v-if="scope.row.Type === 'UserAdded'"
									:callback="() => removeAccess(scope.row)"
									:confirm-message="$t('admin.userAddedKeyRemovePrompt')"
									:show-success-message="false"
									type="danger">
									{{ $t('admin.removeAccess') }}
								</confirm-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane :label="$t('admin.log')">
					<el-table :data="openLog">
						<el-table-column property="CustomerEmail">
							<template #header>
								<el-tooltip :content="$t('admin.email')" placement="top-start">
									<span>{{ $t('admin.email') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>

						<el-table-column>
							<template #default="scope">
								{{ getAlias(scope.row) }}
							</template>
							<template #header>
								<el-tooltip :content="$t('admin.alias')" placement="top-start">
									<span>{{ $t('admin.alias') }}</span>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column>
							<template #header>
								<el-tooltip :content="$t('admin.syncDate')" placement="top-start">
									<span>{{ $t('admin.syncDate') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ getDate(scope.row) | datetime }}
							</template>
						</el-table-column>
						<el-table-column>
							<template #header>
								<el-tooltip :content="$t('admin.lockHwTime')" placement="top-start">
									<span>{{ $t('admin.lockHwTime') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ getLockTime(scope.row) | datetime }}
							</template>
						</el-table-column>
						<el-table-column>
							<template #header>
								<el-tooltip :content="$t('admin.phoneTime')" placement="top-start">
									<span>{{ $t('admin.phoneTime') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								{{ getPhoneTime(scope.row) | datetime }}
							</template>
						</el-table-column>
						<el-table-column property="IsAdmin">
							<template #header>
								<el-tooltip :content="$t('admin.isAdmin')" placement="top-start">
									<span>{{ $t('admin.isAdmin') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<i v-if="isAdmin(scope.row)" class="fa fa-check" />
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</div>
		<el-dialog
			:visible="showAddAccessModal"
			:model="addAccessModel"
			width="25%"
			:before-close="addAccessDialogClose"
			class="pma-info-access-dialog">
			<h3>{{ $t('admin.addSingleAccess') }}</h3>
			<el-form label-position="top">
				<el-form-item :label="$t('admin.lock')">
					<el-select v-model="addAccessModel.lockMac" :placeholder="$t('admin.chooseLock')">
						<el-option
							v-for="lock in locksForUserAddedKeys"
							:key="lock.lockMac"
							:label="lock.lockName"
							:value="lock.lockMac" />
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('admin.email')" prop="email">
					<el-input v-model="addAccessModel.email" />
				</el-form-item>
				<el-form-item>
					<p-button
						class="pull-right"
						size="md"
						type="success"
						:disabled="(addAccessDisabled || addAccessLoading)"
						@click="addAccess()">
						{{ $t('admin.add') }}
					</p-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import {
	Table as ElTable,
	TableColumn as ElTableColumn,
	TabPane as ElTabPane,
	Tabs as ElTabs,
	Tooltip as ElTooltip,
	Dialog as ElDialog,
	Form as ElForm,
	FormItem as ElFormItem,
	Input as ElInput,
	Option as ElOption,
	Select as ElSelect,
} from 'element-ui';
import { mapState } from 'vuex';
import moment from 'moment';

import { appName } from '@/modules/admin/config';
import notifyService from '@/service/notify-service';
import { ConfirmButton } from '@/components/UIComponents';

export default {
	name: 'PmaInfo',
	components: {
		ElTable,
		ElTableColumn,
		ElTabPane,
		ElTabs,
		ElTooltip,
		ElDialog,
		ElForm,
		ElFormItem,
		ElInput,
		ElOption,
		ElSelect,
		ConfirmButton,
	},
	props: {
		smartBoxes: { type: Array, default: () => [] },
		smartBoxInfos: { type: Array, default: () => [] },
		containerDisabled: { type: Boolean, default: () => false },
	},
	data() {
		return {
			activeKeys: [],
			openLog: [],
			addAccessModel: {
				email: '',
				lockMac: '',
			},
			showAddAccessModal: false,
			addAccessLoading: false,
		};
	},
	computed: {
		addAccessDisabled() {
			return !this.addAccessModel.email || !this.addAccessModel.lockMac;
		},
		locksForUserAddedKeys() {
			const locksByKey = new Map(this.smartBoxInfos.map((x) => [x.lockId, x]));

			return (this.smartBoxes ?? [])
				.map((b) => !b.isDisabled && locksByKey.get(b.serialNumber))
				.filter((x) => x);
		},
		...mapState(appName, ['currentUser']),
	},
	async created() {
		const promises = await Promise.all(this.smartBoxes.map((x) => x.serialNumber).map((x) => this.$service.smartBox.getOpenLog(x)));
		this.openLog = promises.map((x) => x.Data).flat().sort((x, y) => (x.Date < y.Date ? 1 : -1));
		await this.updateKeys();
	},
	methods: {
		async updateKeys() {
			const promisesActiveKeys = await Promise.all(this.smartBoxes.map((x) => x.serialNumber).map((x) => this.$service.smartBox.getActiveKeys(x)));
			this.activeKeys = promisesActiveKeys.flat();
		},
		isAdmin(row) {
			if (row?.IsAdmin === true || row?.IsAdmin === false) {
				return row.IsAdmin;
			}
			return false;
		},
		getDate(row) {
			if (row?.Date) {
				return row.Date;
			}
			return null;
		},
		getLockTime(row) {
			if (row?.LockHwTime) {
				return row.LockHwTime;
			}
			return null;
		},
		getPhoneTime(row) {
			if (row?.PhoneTime) {
				return row.PhoneTime;
			}
			return null;
		},
		getRowClass(row) {
			if (row.row.ReservationId) {
				return 'hover-cursor';
			}
			return '';
		},
		getAlias(row) {
			if (row && row.LockId) {
				const mac = row.LockId;
				const found = this.smartBoxInfos.filter((x) => x.lockMac === mac);
				if (found) {
					return found[0]?.lockAlias;
				}
				return mac;
			}
			return '';
		},
		goToReservationDetail(row) {
			if (row.ReservationId) {
				this.$emit('go-to-reservation-detail', row.ReservationId);
			}
		},
		getTypeDesc(type) {
			if (type?.toUpperCase() === 'DELIVERY') {
				return 'Objednávka';
			}
			if (type?.toUpperCase() === 'PERMANENT') {
				return 'Permanentní';
			}
			if (type?.toUpperCase() === 'USERADDED') {
				return 'Uživatelský';
			}
			return type;
		},
		addAccessDialogClose() {
			this.showAddAccessModal = false;
			this.addAccessModel = {
				email: '',
				lockMac: '',
			};
		},
		async removeAccess(row) {
			try {
				const request = {
					customerEmail: row.Email,
					lockMac: row.LockId,
				};
				await this.$service.smartBox.deleteUserEKey(request);
				this.activeKeys = this.activeKeys.filter((key) => !(key.Email === row.Email && key.Type === 'UserAdded'));
				notifyService.notifySuccess(this.$t('admin.userAddedKeyRemoved'));
			} catch (exc) {
				notifyService.notifyError(exc.data);
			}
		},
		async addAccess() {
			if (!this.validateEmail(this.addAccessModel.email)) {
				notifyService.notifyError(this.$t('admin.emailWrongFormat'));
				return;
			}
			this.addAccessLoading = true;
			try {
				const request = {
					customerEmail: this.addAccessModel.email,
					lockMac: this.addAccessModel.lockMac,
					ownerEmail: this.currentUser.email,
				};
				await this.$service.smartBox.createUserEKey(request);
				notifyService.notifySuccess(this.$t('admin.addAccessSuccesful'));
				const now = moment(moment.utc()).local().toISOString();
				const keyExpires = moment(moment.utc()).local().add(6, 'hours').toISOString();
				this.activeKeys.push({
					LockId: this.addAccessModel.lockMac,
					Email: this.addAccessModel.email,
					Type: 'UserAdded',
					WasUsed: false,
					UsedAt: null,
					ReservationId: 0,
					Owner: this.currentUser.email,
					WasSyncedToMobile: false,
					ValidFrom: now,
					Expire: keyExpires,
				});
				this.addAccessDialogClose();
			} catch (exc) {
				if (exc.data.indexOf('Key already exists') !== -1) {
					notifyService.notifyError(this.$t('admin.userAddedKeyAlreadyExists'));
				}
				if (exc.data.indexOf('Unknown lock') !== -1) {
					notifyService.notifyError(this.$t('admin.unkonwnPmaLock'));
				} else {
					notifyService.notifyError(exc.data);
				}
			} finally {
				this.addAccessLoading = false;
			}
		},
		validateEmail(email) {
			const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return re.test(email);
		},
	},
};
</script>

<style scoped lang="scss">
.el-date-editor.el-input {
	width: 100% !important;
}
</style>
