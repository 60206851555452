// TODO: https://jira.artin.cz/browse/PENG-1549
/* eslint-disable import/no-cycle */
import { routes } from './router';
import store from './store';
import config from './config';
import service from './service';

export const AdminModule = {
	name: 'admin',
	routes,
	store,
	config,
	service,
};
