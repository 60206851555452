export const QrCodeService = (http) => ({
	async getReservation({ reservationId: ReservationId, eshopOrderId: EshopOrderId }) {
		const response = await http.get('/api/Qr', { params: { ReservationId, EshopOrderId } });
		return response.data;
	},
	async getData(reservationId, eshopOrderId) {
		const response = await http.post('/api/Qr', { ReservationId: reservationId, EshopOrderId: eshopOrderId });
		return response.data;
	},
});
