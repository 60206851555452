export default class PrintArea {
	area = null;
	win = null;
	addHtmlCode = '';

	constructor(areaId) {
		this.area = document.getElementById(areaId);

		this.printDocument = () => {
			this.win.document.close();

			setTimeout(() => {
				this.win.print();
			}, 500);

			setTimeout(() => {
				this.win.close();
			}, 3000);
		};

		this.addStyles = (url) => {
			const link = this.win.document.createElement('link');
			link.setAttribute('rel', 'stylesheet');
			link.setAttribute('type', 'text/css');
			link.setAttribute('href', url);
			this.win.document.getElementsByTagName('head')[0].appendChild(link);
		};

		this.writeHtml = (bodyContent) => {
			this.win.document.write(`
        <html lang="cs">
          <head>
          	<title>Report</title>
          	<link
          	 	crossorigin="anonymous"
          	  href="https://use.fontawesome.com/releases/v5.15.0/css/all.css"
          	  integrity="sha384-OLYO0LymqQ+uHXELyx93kblK5YIS3B2ZfLGBmsJaUyor7CpMTBsahDHByqSuWW+q"
          	  rel="stylesheet" />
          </head>
          <body>
            ${bodyContent}
          </body>
        </html>
      `);
		};
	}

	addHtml(code) {
		this.addHtmlCode = code;
	}

	print() {
		this.win = window.open('', '', 'fullscreen=yes;titlebar=yes');

		this.writeHtml(this.area.innerHTML + this.addHtmlCode);
		this.addStyles('/static/css/printing.css');
		this.printDocument();
	}
}
