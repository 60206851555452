<template>
	<table :class="{ 'mt-4': !nomargin }">
		<slot />
	</table>
</template>

<script>
export default {
	name: 'BaseReportPrintSheetTable',
	props: {
		nomargin: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
table {
	width: 100%;
}

table,
th,
td {
	border: 1px solid black;
	border-collapse: collapse;
	padding: 0 0 0 2px;
	font-size: 12px;
	min-width: 75px;
	.userField {
		min-width: 150px;
	}
}
</style>
