export const PriceMapService = (http) => ({
	async getList(filter, page = 1, pageSize = 10) {
		const response = await http.get('/api/PriceMap/List', {
			params: {
				Page: page,
				PageSize: pageSize,
				...filter,
			},
		});

		return response.data;
	},

	async getOne(id) {
		const response = await http.get('/api/PriceMap/Detail', { params: { PriceMapId: id } });
		return response.data;
	},

	async add(priceMap) {
		const response = await http.post('/api/PriceMap/Add', priceMap);
		return response.data;
	},

	async update(priceMap) {
		const response = await http.post('/api/PriceMap/Update', priceMap);
		return response.data;
	},
});
