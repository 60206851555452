import axios from 'axios';

import notifyService from '@/service/notify-service';
import store from '@/store';
import { is400ErrorOnLoginPage } from '@/util/helpers';
import { constants, appBaseUrlList, appNameList } from '@/globalConstants';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import { getAuthDataStorageName } from '@/store/auth-actions';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import vue from '@/main';

import { version } from '../../package.json';

const http = axios.create({});
http.defaults.headers.common['Accept-Language'] = 'cs-CZ';
if (process.env.NODE_ENV !== 'development') {
	http.defaults.headers.common[constants.appVersionHeader] = version;
}

const isFailRefreshTokenInCurrentApp = (error) => {
	const { url, headers } = error.config;
	const { pathname } = window.location;

	if (url === '/connect/token' && error.status === 400) {
		const currentAppName = Object.keys(appNameList).find((appName) => {
			const isAppNameInUrl = pathname.startsWith(appBaseUrlList[appName]);

			if (appName === appNameList.eshop) {
				return isAppNameInUrl || pathname === '/';
			}
			return isAppNameInUrl;
		});

		if (!currentAppName) {
			return false;
		}

		const tokenDataRaw = localStorage.getItem(getAuthDataStorageName(currentAppName));

		if (!tokenDataRaw) {
			return true;
		}

		const tokenData = JSON.parse(tokenDataRaw);

		return headers?.Authorization?.indexOf(tokenData?.accessToken) !== -1;
	}

	return false;
};

http.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 403 && error.response.data === constants.versionMismatch) {
			notifyService.notifyError(vue.$t('global.versionMismatch'));
			setTimeout(() => vue.$router.go(0), 2500);
		}
		return Promise.reject(error);
	},
);
http.interceptors.response.use(
	(response) => response,
	(error) => {
		if (isFailRefreshTokenInCurrentApp(error) || error.status === 401) {
			const moduleName = store.state.currentAppName;

			localStorage.removeItem(getAuthDataStorageName(moduleName));

			store.state[moduleName].refreshTokenLoading = true;

			return vue.$router.push({ name: 'login', params: { from: vue.$route.path } });
		}

		return Promise.reject(error);
	},
);

http.interceptors.response.use(
	(response) => {
		const moduleName = store.state.currentAppName;

		if (store.state[moduleName].versionInfo !== response.headers.version) {
			store.commit(`${moduleName}/setVersionInfo`, { versionInfo: response.headers.version });
		}

		return response;
	},
	async (error) => {
		const {
			data: { reason },
			status,
		} = error.response;
		const moduleName = store.state.currentAppName;

		if (status === 422) {
			console.error('validation error', reason);
		} else if ([400, 404, 500].includes(status)) {
			if (
				moduleName === appNameList.driver
				|| is400ErrorOnLoginPage(error.response.status, appBaseUrlList[moduleName], moduleName === appNameList.eshop ? '/' : null)
			) {
				notifyService.notifyError(vue.$t('global.invalidLogin'));
			}
		}

		return Promise.reject(error.response);
	},
);

export function setJwtTokenHttp(token) {
	http.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default http;
