import { appNameList } from '@/globalConstants';

const sidebarLinks = [
	{
		name: 'Příjem / výdej',
		icon: 'nc-icon nc-box',
		path: '/vydejna/prijem-vydej',
	},
	{
		name: 'Rezervace',
		icon: 'nc-icon nc-calendar-60',
		path: '/vydejna/rezervace',
	},
	{
		name: 'Vratka',
		icon: 'nc-icon nc-delivery-fast',
		path: '/vydejna/vratka',
	},
];

const sidebarUserLinks = [{ name: 'password-change', display: 'Změna hesla' }];

const config = {
	appName: appNameList.pickup,
	sidebarLinks,
	sidebarUserLinks,
};

config.install = function (Vue) {
	Vue.prototype.$getConst = (key) => config[key];
};

export const appName = appNameList.pickup;

export default config;
