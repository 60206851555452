import notifyService from '@/service/notify-service';
import { translate } from '@/globalConstants';

export default {
	methods: {
		async validate() {
			return this.$validator.validateAll();
		},

		getError(fieldName) {
			return this.errors.first(fieldName);
		},

		hasError(fieldName) {
			return this.errors.has(fieldName);
		},

		async validateAndSave() {
			if (!(await this.validate())) {
				return;
			}
			// If validaiton passed call save() from component
			this.handleBackEndErrors(true, this.save);
		},

		async handleBackEndErrors(notifySuccess, func, ...args) {
			let result = {};
			const { successSaveMessage, failSaveMessage } = translate.czech;
			try {
				result = await func(...args);
				if (notifySuccess) {
					notifyService.notifySuccess(successSaveMessage);
				}
			} catch (e) {
				console.warn(e);
				if (e.data && e.data.errors) {
					Object.keys(e.data.errors).forEach((errorObject) => {
						if (typeof e.data.errors[errorObject] === 'string' || e.data.errors[errorObject] instanceof String) {
							notifyService.notifyError(e.data.errors[errorObject]);
						} else {
							notifyService.notifyError(failSaveMessage);
						}
					});
				} else {
					notifyService.notifyError(failSaveMessage);
				}
			}
			return result;
		},
	},
	computed: {
		isValid() {
			return !Object.keys(this.fieldsbag).some((key) => this.fieldsbag[key].invalid);
		},
	},
};
