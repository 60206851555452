<template>
	<div>
		<base-user-form
			add-user-function="addEshopUser"
			link-back="eshop-user-list"
			new-title="Nový uživatel"
			title="Uživatel" />
		<employee-reservations-overview v-if="$route.params.id" />
	</div>
</template>

<script>
import FormMixin from '@/util/form-mixin';
import { BaseUserForm } from '@/components/Base';

import EmployeeReservationsOverview from './EmployeeReservationsOverview.vue';

export default {
	name: 'EshopUserForm',
	components: { BaseUserForm, EmployeeReservationsOverview },
	mixins: [FormMixin],
};
</script>
