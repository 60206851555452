<template>
	<div :class="vertical ? 'row' : ''">
		<div :class="vertical ? verticalNavClasses : ''">
			<div class="nav-tabs-navigation" :class="{ 'verical-navs': vertical }">
				<div class="nav-tabs-wrapper">
					<slot name="nav" />
				</div>
			</div>
		</div>
		<div :class="vertical ? verticalTabContentClasses : ''">
			<slot name="content" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'TabsLayout',
	props: {
		vertical: Boolean,
		verticalNavClasses: { type: [String, Object], default: null },
		verticalTabContentClasses: { type: [String, Object], default: null },
	},
};
</script>
