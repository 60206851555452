<template>
	<div v-if="canDirectSale" class="row">
		<div class="col-md-12 card">
			<div class="d-flex align-items-center">
				<div class="col">
					<autocomplete
						v-model="filter.city"
						:label="$t('eshop.city')"
						search-field-name="Cities"
						trigger-on-focus
						@fetch-suggestions="querySearchAsync" />
				</div>
				<div class="col">
					<label class="d-block">{{ $t('eshop.stockItemsAssigned') }}</label>
					<el-select
						v-model="filter.stockItems"
						size="large"
						class="w-100"
						filterable
						:placeholder="$t('eshop.stockItemsAssigned')"
						value-key="ordinal"
						clearable
						multiple
						@change="loadData()">
						<el-option
							v-for="stockItem in stockItemsList.entities"
							:key="stockItem.id"
							:label="stockItem.name"
							:value="stockItem.id" />
					</el-select>
				</div>
			</div>
			<div v-show="advancedSearch">
				<div class="d-flex align-items-center">
					<div class="col">
						<autocomplete
							v-model="filter.state"
							:label="$t('eshop.country')"
							search-field-name="States"
							trigger-on-focus
							@fetch-suggestions="querySearchAsync" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.region"
							:label="$t('eshop.region')"
							search-field-name="Regions"
							trigger-on-focus
							@fetch-suggestions="querySearchAsync" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.county"
							:label="$t('eshop.county')"
							search-field-name="Counties"
							trigger-on-focus
							@fetch-suggestions="querySearchAsync" />
					</div>
				</div>
				<div class="d-flex justify-content-start">
					<div class="col">
						<autocomplete
							v-model="filter.street"
							:label="$t('eshop.street')"
							search-field-name="Streets"
							trigger-on-focus
							@fetch-suggestions="querySearchAsync" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.location" :label="$t('eshop.location')" type="text" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.itemName" :label="$t('eshop.itemName')" type="text" />
					</div>
				</div>
				<div class="d-flex align-items-center">
					<div class="col">
						<form-group-input :label="$t('eshop.state')" type="text">
							<div class="d-flex mt-1">
								<yes-no-all v-model="filter.isOk" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>
					<div class="col">
						<form-group-input :label="$t('eshop.locked')" type="text">
							<div class="d-flex mt-1">
								<yes-no-all
									v-model="filter.isLocked"
									:label="$t('eshop.locked')"
									trigger-on-change-method
									@on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>

					<div class="col">
						<form-group-input :label="$t('eshop.disabled')" type="text">
							<div class="d-flex mt-1">
								<yes-no-all v-model="filter.isDisabled" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>

					<div class="col">
						<form-group-input :label="$t('eshop.offline')" type="text">
							<div class="d-flex mt-1">
								<yes-no-all v-model="filter.isOffline" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>

					<div class="col">
						<form-group-input :label="$t('eshop.stockItemAssigned')" type="text">
							<div class="d-flex mt-1">
								<yes-no-all v-model="filter.stockItemAssigned" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>
				</div>
			</div>
			<div class="d-flex my-1 justify-content-between">
				<div class="col-2 d-flex mb-1 justify-content-start">
					<p-button type="success" size="md" @click="loadData()">
						{{ $t('eshop.search') }}
					</p-button>
					<el-tooltip :content="$t('eshop.tooltips.clearFilter')" placement="top-start">
						<p-button
							class="ml-2"
							type="danger"
							icon
							size="md"
							@click="clearFilter">
							<i class="fa fa-times" />
						</p-button>
					</el-tooltip>
				</div>
				<div class="d-flex mb-1 mr-3">
					<p-button @click="toggleAdvancedSearch">
						{{ searchButtonLabel }}
					</p-button>
				</div>
			</div>
		</div>

		<div v-loading="loading" class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-sm-12">
						<el-table
							class="table-striped"
							data-cy="ListMainTable"
							:data="tableData"
							:row-class-name="tableRowClassName"
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							@sort-change="sortChange"
							@row-click="assignStockItem">
							<el-table-column property="container.serialNumber" sortable="custom" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.containerSerialNumber')" placement="top-start">
										<span>{{ $t('eshop.containerSerialNumber') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="boxSn" sortable="custom" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.boxNumber')" placement="top-start">
										<span>{{ $t('eshop.boxNumber') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="container.city" sortable="custom" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.city')" placement="top-start">
										<span>{{ $t('eshop.city') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="container.street" sortable="custom" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.street')" placement="top-start">
										<span>{{ $t('eshop.street') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="container.location" sortable="custom" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.location')" placement="top-start">
										<span>{{ $t('eshop.location') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="container.isOK" align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.state')" placement="top-start">
										<span>{{ $t('eshop.state') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div :key="scope.row.container.isOK">
										<i v-if="scope.row.container.isOK" class="fa fa-check fa-lg text-success" />
										<i v-else type="danger" class="fa fa-times fa-lg text-danger" />
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" property="container.battery" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.tooltips.containerBattery')" placement="top-start">
										<span>
											<i class="fa fa-battery-half fa-lg text-danger" type="danger" />
										</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div :key="scope.row.container.battery">
										<span v-if="scope.row.container.type === 'SmartKeyBox'">
											<i v-if="scope.row.container.battery >= 50" class="fa fa-battery-full fa-lg text-success" type="success" />
											<i v-else-if="scope.row.container.battery >= 25" class="fa fa-battery-three-quarters fa-lg battery-three-quarters-color" />
											<i v-else-if="scope.row.container.battery >= 10" class="fa fa-battery-half fa-lg text-warning" type="warning" />
											<i v-else class="fa fa-battery-empty fa-lg text-danger" type="danger" />
											{{ scope.row.container.battery ? scope.row.container.battery : '-' }}%
										</span>
										<span v-else>
											<i v-if="scope.row.container.battery >= 12" class="fa fa-battery-full fa-lg text-success" type="success" />
											<i v-else-if="scope.row.container.battery >= 11" class="fa fa-battery-three-quarters fa-lg battery-three-quarters-color" />
											<i v-else-if="scope.row.container.battery >= 10" class="fa fa-battery-half fa-lg text-warning" type="warning" />
											<i v-else class="fa fa-battery-empty fa-lg text-danger" type="danger" />
											{{ scope.row.container.battery }}V
										</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column property="stockItem.name" sortable="custom" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.stockItemAssigned')" placement="top-start">
										<span>{{ $t('eshop.stockItemAssigned') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.directSale')" placement="top-start">
										<span>{{ $t('eshop.directSale') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :content="$t('eshop.tooltips.assignStockItem')">
										<p-button
											icon
											round
											size="sm"
											type="success"
											@click.stop="assignStockItem(scope.row)">
											<i class="fa fa-plus" />
										</p-button>
									</el-tooltip>
									<el-tooltip v-if="!scope.row.privilegedStockItemCategory" :content="$t('eshop.tooltips.assignStockItemSingleSale')">
										<p-button
											icon
											round
											size="sm"
											type="success"
											@click="
												switchDialogVisible();
												setModalSelectedRow(scope.row);
											"
											@click.stop="">
											+1
										</p-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col mt-2">
						<el-pagination
							:current-page="page.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.totalCount"
							:page-count="page.pagesCount"
							:page-size="pageSize"
							:page-sizes="pageSizes"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
				</div>
			</div>
		</div>

		<el-dialog
			:close-on-click-modal="false"
			:visible.sync="dialogVisible"
			round
			width="50%"
			@close="setAssignStockItemSingeSaleToAllContainer(false)">
			<h3 class="text-center">
				{{ $t('eshop.assignStockItem.messageFromTop') }}
			</h3>
			<div class="d-flex justify-content-center">
				<div class="text-left w-75">
					<el-select
						v-model="boxCategory"
						:placeholder="$t('eshop.chooseCategory')"
						class="w-100"
						clearable
						filterable
						size="large"
						value-key="ordinal">
						<el-option
							v-for="stockItem in boxesCategories"
							:key="stockItem"
							:label="stockItem"
							:value="stockItem" />
					</el-select>
					<p-checkbox v-model="singleSaleToAllContainer" class="mt-2">
						{{ $t('eshop.assignStockItem.singleSaleToAllContainer') }}
					</p-checkbox>
				</div>
			</div>
			<span slot="footer" class="d-flex justify-content-center">
				<p-button
					type="success"
					@click="
						switchDialogVisible();
						assignStockItemSingleSale();
					">{{ $t('eshop.confirm') }}</p-button>
				<p-button
					type="danger"
					@click="
						switchDialogVisible();
						setAssignStockItemSingeSaleToAllContainer(false);
					">{{ $t('eshop.cancel') }}</p-button>
			</span>
		</el-dialog>
	</div>
	<div v-else class="row">
		<h2>{{ $t('eshop.userDoesntAllowDirectSale') }}</h2>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {
	Select as ElSelect,
	Option as ElOption,
	Table as ElTable,
	TableColumn as ElTableColumn,
	Pagination as ElPagination,
	Tooltip as ElTooltip,
	Dialog as ElDialog,
} from 'element-ui';

import ValidationMixin from '@/util/validated-form-mixin';
import { appName } from '@/modules/eshop/config';
import { setFilter } from '@/store/mutation-types';

export default {
	name: 'BoxList',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElSelect,
		ElOption,
		ElDialog,
	},
	mixins: [ValidationMixin],
	data() {
		return {
			singleSaleToAllContainer: false,
			boxCategory: '',
			modalSelectedRow: null,
			dialogVisible: false,
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			loading: false,
			stockItemsList: [],
			advancedSearch: false,
			boxesCategories: [],
			filter: {},
			abortController: null,
		};
	},
	computed: {
		...mapState(appName, ['boxFilter', 'currentUser']),
		tableData() {
			return this.page.entities;
		},
		canDirectSale() {
			return this.currentUser.DirectSale === 'True';
		},
		searchButtonLabel() {
			return this.advancedSearch ? this.$t('eshop.simpleSearch') : this.$t('eshop.advancedSearch');
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'boxFilter', data: val });
			},
		},
	},
	async created() {
		this.filter = this.boxFilter;
		this.loadData();
		const stockItemFilter = { allowDirectSale: true };
		this.boxesCategories = await this.$service.stockItem.getCategories();
		this.stockItemsList = await this.$service.stockItem.getList(1, stockItemFilter, 1000);
	},

	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		setAssignStockItemSingeSaleToAllContainer(newValue) {
			this.singleSaleToAllContainer = newValue;
		},
		setModalSelectedRow(row) {
			this.modalSelectedRow = row;
		},
		switchDialogVisible() {
			this.dialogVisible = !this.dialogVisible;
		},
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},
		async loadData(page = 1) {
			this.loading = true;
			this.page = await this.$service.box.getList(page, this.filter, this.pageSize);
			this.loading = false;
		},
		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		clearFilter() {
			this.$store.commit(`${appName}/resetFilter`, 'boxFilter');
			this.filter = this.boxFilter;
			this.loadData();
		},

		assignStockItem(row) {
			this.$router.push({ name: 'box-list-assign-item', params: { boxId: row.boxId } });
		},

		async querySearchAsync(input, cb, searchFieldName) {
			this.abortController?.abort();
			this.abortController = new AbortController();
			const results = await this.$service.box.searchByLocationInput(input, searchFieldName, this.filter, this.abortController);
			cb(results);
		},

		tableRowClassName(row) {
			return row.row.container.isOffline ? 'tableRowOffline' : 'tableRow';
		},
		toggleAdvancedSearch() {
			this.advancedSearch = !this.advancedSearch;
		},
		async assignStockItemSingleSale() {
			if (this.singleSaleToAllContainer) {
				await this.handleBackEndErrors(true, this.$service.box.directSaleToAllBoxesOfContainer, this.modalSelectedRow.container.containerId, this.boxCategory);
			} else {
				await this.handleBackEndErrors(true, this.$service.box.directSale, null, this.modalSelectedRow.boxId, true, this.boxCategory);
			}
			await this.setAssignStockItemSingeSaleToAllContainer(false);
			this.loadData(this.page.currentPage);
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}

.el-table {
	::v-deep .el-table__body .tableRowOffline {
		cursor: pointer;
		background-color: #ffb3b3 !important;
	}
	.btn {
		margin: 3px;
	}
}
</style>
