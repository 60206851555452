import { ReservationStatus } from '@/modules/logistics/store/enums';

export default {
	methods: {
		isExpired(reservation) {
			const expired = new Date();
			const { ACTIVE, PREPARED, WAIT_FOR_PAY } = ReservationStatus;

			return [ACTIVE, PREPARED, WAIT_FOR_PAY].includes(ReservationStatus.enumValueOf(reservation.status)) && new Date(reservation.expired) < expired;
		},
		isBleskovkaDoruceni(reservation) {
			const { status, isPostPackage, stockItemId } = reservation;

			return ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_DELIVERY && isPostPackage && stockItemId;
		},

		isBleskovkaPrevoz(reservation) {
			const { status, isPostPackage, stockItemId, reservationNumber } = reservation;

			return (
				ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_COLLECT
				&& isPostPackage
				&& stockItemId
				&& reservationNumber
				&& !this.isExpired(reservation)
			);
		},
		isOkamzitaVratka(reservation) {
			const { status, stockItemId, reservationNumber } = reservation;
			return ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_COLLECT
				&& stockItemId
				&& !reservationNumber
				&& !this.isExpired(reservation)
				&& reservation.statusesHistory?.indexOf('SelectForReturnDirectSale') !== -1;
		},

		isBleskovkaVratkaSvoz(reservation) {
			const { status, stockItemId, reservationNumber } = reservation;

			return ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_COLLECT && stockItemId && !reservationNumber && !this.isExpired(reservation);
		},

		isBleskovkaExpiraceNeprodana(reservation) {
			const { status, stockItemId, reservationNumber } = reservation;

			return ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_PAY && stockItemId && !reservationNumber && this.isExpired(reservation);
		},

		isBleskovkaExpiraceNeprodanaSvoz(reservation) {
			const { status, stockItemId, reservationNumber } = reservation;

			return ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_COLLECT && stockItemId && !reservationNumber && this.isExpired(reservation);
		},

		isBleskovkaExpiraceProdana(reservation) {
			const { status, stockItemId, reservationNumber } = reservation;

			return ReservationStatus.enumValueOf(status) === ReservationStatus.PREPARED && stockItemId && !reservationNumber && this.isExpired(reservation);
		},

		isPostDelivery(reservation) {
			const { status, isPostPackage, stockItemId } = reservation;

			return isPostPackage && ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_DELIVERY && !stockItemId;
		},

		isPostDelivered(reservation) {
			const { currentStatus, isPostPackage, stockItemId } = reservation;

			return isPostPackage && ReservationStatus.enumValueOf(currentStatus) === ReservationStatus.PICK_UP && !stockItemId;
		},

		isEshopReturn(reservation) {
			const { status, isPostPackage, stockItemId } = reservation;

			return !isPostPackage && ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_COLLECT && !stockItemId;
		},

		isEshopPackage(reservation) {
			const { status, isPostPackage, stockItemId } = reservation;

			return !isPostPackage && ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_DELIVERY && !stockItemId;
		},

		isPrivateAddressPickedPackage(reservation) {
			const { status, isPrivateAddress, isPostPackage, stockItemId } = reservation;
			return isPrivateAddress && !isPostPackage && ReservationStatus.enumValueOf(status) === ReservationStatus.PICK_UP && !stockItemId;
		},

		isPostCollect(reservation) {
			const { status, isPostPackage, stockItemId } = reservation;

			return isPostPackage && !this.isExpired(reservation) && ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_COLLECT && !stockItemId;
		},

		isPostExpiration(reservation) {
			const { status, isPostPackage, stockItemId } = reservation;

			return isPostPackage && this.isExpired(reservation) && ReservationStatus.enumValueOf(status) === ReservationStatus.PREPARED && !stockItemId;
		},

		isEshopExpiration(reservation) {
			const { status, isPostPackage, stockItemId } = reservation;

			return !isPostPackage && this.isExpired(reservation) && ReservationStatus.enumValueOf(status) === ReservationStatus.PREPARED && !stockItemId;
		},

		isDeliveryExpiration(reservation) {
			const { status, isPostPackage, reservationType } = reservation;

			return !isPostPackage && reservationType
			&& reservationType.includes('Delivery') && ReservationStatus.enumValueOf(status) === ReservationStatus.WAIT_FOR_COLLECT;
		},

		reservationName(reservation) {
			const { customerName } = reservation;
			let { customerAddress } = reservation;
			if (this.isDeliveryExpiration(reservation)) {
				return this.$t('logistics.expiredCollection');
			}

			if (this.isOkamzitaVratka(reservation)) {
				return this.$t('logistics.immediateReturnTransport');
			}

			if (this.isBleskovkaExpiraceProdana(reservation)) {
				return this.$t('logistics.expiredPaidStockItemReservation');
			}

			if (this.isBleskovkaPrevoz(reservation)) {
				return `${this.$t('logistics.stockItemReservation')} ${customerName ? `${customerName}, ` : ''}${customerAddress || ''}`;
			}

			if (this.isBleskovkaDoruceni(reservation)) {
				return `${this.$t('logistics.stockItemPostReservation')} ${customerName ? `${customerName}, ` : ''}${customerAddress || ''}`;
			}

			if (this.isBleskovkaExpiraceNeprodana(reservation)) {
				return this.$t('logistics.expiredUnpaidStockItemReservation');
			}

			if (this.isBleskovkaExpiraceNeprodanaSvoz(reservation)) {
				return this.$t('logistics.expiredUnpaidStockItemReservationTransport');
			}

			if (this.isBleskovkaVratkaSvoz(reservation)) {
				return this.$t('logistics.returnStockItem');
			}

			if (this.isEshopExpiration(reservation)) {
				return this.$t('logistics.expiredEshopPackage');
			}

			if (this.isEshopPackage(reservation)) {
				if (customerName && customerAddress) {
					customerAddress = customerAddress.replace(`${customerName}, `, '');
				}
				return customerName || customerAddress ? `${customerName ? `${customerName}, ` : ''}${customerAddress || ''}` : this.$t('logistics.eshopPackage');
			}

			if (this.isPrivateAddressPickedPackage(reservation)) {
				return `${customerName ? `${customerName}, ` : ''}${customerAddress || ''}`;
			}

			if (this.isEshopReturn(reservation)) {
				return this.$t('logistics.eshopReturned');
			}

			if (this.isPostDelivery(reservation) || this.isPostDelivered(reservation)) {
				return `${this.$t('logistics.postPackageForDelivery')} ${customerName ? `${customerName}, ` : ''}${customerAddress || ''}`;
			}

			if (this.isPostCollect(reservation)) {
				return this.$t('logistics.postPackageForCollect');
			}

			if (this.isPostExpiration(reservation)) {
				return this.$t('logistics.expiredReservation');
			}

			return null;
		},
	},
};
