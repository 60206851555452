export const SmartBoxService = (http) => ({
	async getList({ date, clientId, accessToken }, page = 1, pageSize = 10) {
		const response = await http.get('/api/SmartBox', {
			params: {
				PageNo: page,
				PageSize: pageSize,
				Date: date,
				ClientId: clientId,
				AccessToken: accessToken,
			},
		});

		return response.data;
	},
	async getDetail(boxSn) {
		try {
			const response = await http.get('/api/SmartBox/detail', {
				params: { boxSn },
				headers: { 'api-version': '0.2' },
			});

			return response.data;
		} catch (e) {
			return null;
		}
	},
	async getPersistentAccessUsers(boxId) {
		const response = await http.get('/api/Box/PersistentAccess', { params: { boxId } });
		return response.data;
	},
	async grantPersistentAccess(mail, boxId) {
		const response = await http.post('/api/Box/PersistentAccess/Grant', { boxId, mail });
		return response.data;
	},
	async removePersistentAccess(grantId) {
		const response = await http.post('/api/Box/PersistentAccess/Remove', { grantId });
		return response.data;
	},

	async getOpenLog(boxSn) {
		const response = await http.get('/api/smartbox/openlog', { params: { 'filter.lockid': boxSn }, headers: { 'api-version': '0.2' } });
		return response.data;
	},

	async getActiveKeys(boxSn) {
		const response = await http.get('/api/smartbox/activeKeys', { params: { lockId: boxSn }, headers: { 'api-version': '0.2' } });
		return response.data;
	},

	async createUserEKey(request) {
		const response = await http.post('/api/SmartBox/CreateUserEKey', request, { headers: { 'api-version': '0.2' } });
		return response.data;
	},
	async deleteUserEKey(request) {
		const response = await http.post('/api/SmartBox/DeleteUserEKey', request, { headers: { 'api-version': '0.2' } });
		return response.data;
	},
});
