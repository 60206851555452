export const AdminService = (http) => ({
	async getList(params) {
		const response = await http.get('/api/Account/ListAdmins', { params });

		return response.data;
	},

	async getLogisticsList(params) {
		const response = await http.get('/api/Account/ListLogistics', { params });

		return response.data;
	},

	async getEshopUsersList(params) {
		const response = await http.get('/api/Account/ListEshopUsers', { params });

		return response.data;
	},

	async getOne(userId) {
		const response = await http.get('/api/Account/Detail', { params: { userId } });
		return response.data;
	},

	async addAdmin(admin) {
		const response = await http.post('/api/Account/AddAdmin', admin);
		return response.data;
	},

	async addLogistic(logistic) {
		const response = await http.post('/api/Account/AddLogistic', logistic);
		return response.data;
	},

	async update(admin) {
		const response = await http.post('/api/Account/Update', admin);
		return response.data;
	},

	async changePassword(userPwd) {
		const response = await http.post('/api/Account/ChangePassword', userPwd);
		return response.data;
	},

	async lock(admin, lock) {
		const response = await http.post('/api/Account/LockUser', { userID: admin.id, lock });
		return response.data;
	},

	async assignToAdminGroup(userId) {
		const response = await http.post('/api/Account/AssignToAdminGroup', { userId });
		return response.data;
	},

	async removeFromAdminGroup(userId) {
		const response = await http.post('/api/Account/RemoveFromAdminGroup', { userId });
		return response.data;
	},
});
