export const lastPingContainer = {
	czech: {
		today: 'Dnes',
		yesterday: 'Včera',
		twoDaysAgo: '2 dny',
		threeDaysAgo: '3 dny',
		fourDaysAgo: '4 dny',
		fiveDaysAgo: '5 dny',
		sixDaysAgo: '6 dny',
		sevenDaysAgo: '7 dny',
		moreThanWeekAgo: 'Více než týden',
	},
};

export const publicHolidays = {
	czech: [
		// permanent public holidays
		{ date: '0000-01-01', name: 'Nový rok' },
		// moving public holidays
		{ date: '2021-04-02', name: 'Velký pátek' },
		{ date: '2021-04-05', name: 'Velikonoční pondělí' },
		{ date: '2022-04-15', name: 'Velký pátek' },
		{ date: '2022-04-18', name: 'Velikonoční pondělí' },
		{ date: '2023-04-07', name: 'Velký pátek' },
		{ date: '2023-04-10', name: 'Velikonoční pondělí' },
		{ date: '2024-03-29', name: 'Velký pátek' },
		{ date: '2024-04-01', name: 'Velikonoční pondělí' },
		{ date: '2025-04-18', name: 'Velký pátek' },
		{ date: '2025-04-21', name: 'Velikonoční pondělí' },
		// permanent public holidays
		{ date: '0000-05-01', name: 'Svátek práce' },
		{ date: '0000-05-08', name: 'Den vítězství' },
		{ date: '0000-07-05', name: 'Den slovanských věrozvěstů Cyrila a Metoděje' },
		{ date: '0000-07-06', name: 'Den upálení mistra Jana Husa' },
		{ date: '0000-09-28', name: 'Den české státnosti' },
		{ date: '0000-10-28.', name: 'Den vzniku samostatného československého státu' },
		{ date: '0000-11-17', name: 'Den boje za svobodu a demokracii' },
		{ date: '0000-12-24', name: 'Štědrý den' },
		{ date: '0000-12-25', name: '1. svátek vánoční' },
		{ date: '0000-12-26', name: '2. svátek vánoční' },
	],
};
