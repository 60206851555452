import { mapActions } from 'vuex';

import {
	BaseChangePasswordForm,
	BaseReportList,
	BaseLogin,
	BaseForgotPassword,
	BaseResetPassword,
	BaseConfirmation,
	BasePickupDeliveryForm,
} from '@/components/Base';
import ReportDetails from '@/modules/driver/views/Report/ReportDetails.vue';
import LoggedOutLayout from '@/components/Layouts/LoggedOutLayout.vue';
import Child from '@/components/Layouts/LoggedOut/Child.vue';
import ExpirationsAndReturnOrders from '@/modules/driver/views/ExpirationsAndReturnOrders.vue';
import { authenticate, logout } from '@/store/action-types';
import { appName } from '@/modules/driver/config';

export const routes = [
	{
		component: LoggedOutLayout,
		path: '',
		meta: {
			title: 'Řidič',
		},
		children: [
			{
				path: '/ridic',
				children: [
					{ name: 'login', path: '', component: BaseLogin, props: { ...mapActions(appName, [authenticate, logout]) } },
					{ name: 'forgot-password', path: 'zapomenute-heslo', component: BaseForgotPassword },
					{ name: 'pass-reset', path: 'ResetPass/:user', component: BaseResetPassword },
					{ name: 'mail-confirm', path: 'Confirmation/:user', component: BaseConfirmation },
				],
				component: Child,
			},
		],
	},
	{
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "driver" */ '../../components/Layouts/DashboardLayout.vue'),
		path: '/ridic/',
		redirect: '/ridic/report',
		meta: {
			title: 'Řidič',
		},
		children: [
			{
				name: 'report-list',
				path: 'report',
				component: BaseReportList,
				meta: {
					breadcrumb: [{ name: 'Report' }],
				},
			},
			{
				name: 'report-detail',
				path: 'report/detail/:id',
				component: ReportDetails,
				meta: {
					breadcrumb: [{ name: 'Report', link: 'report-list' }, { name: 'Detail' }],
				},
			},
			{
				name: 'password-change',
				path: 'zmena-hesla',
				component: BaseChangePasswordForm,
				meta: {
					breadcrumb: [{ name: 'Změna hesla' }],
				},
			},
			{
				name: 'pickup-delivery',
				path: 'prijem-vydej',
				component: BasePickupDeliveryForm,
				props: { confirmButtonDescription: 'Přijmout / vydat' },
				meta: {
					breadcrumb: [{ name: 'Příjem / výdej' }],
				},
			},
			{
				name: 'expirations-and-return-orders',
				path: 'expirace-a-vratky',
				component: ExpirationsAndReturnOrders,
				meta: {
					breadcrumb: [{ name: 'Expirace a vratky' }],
				},
			},
		],
	},
];
