import { Validator } from 'vee-validate';

import { appNameList } from '@/globalConstants';
// eslint-disable-next-line import/no-cycle
import http from '@/service/http';

import { LogService } from './service/log-service';

const sidebarLinks = [
	{
		name: 'Přehled',
		icon: 'nc-icon nc-world-2',
		path: '/admin/prehled',
	},
	{
		name: 'Výdejní místa',
		icon: 'nc-icon nc-layout-11',
		path: '/admin/vydejni-mista',
	},
	{
		name: 'Rezervace',
		icon: 'nc-icon nc-calendar-60',
		path: '/admin/rezervace',
	},
	{
		name: 'Eshopy',
		icon: 'nc-icon nc-shop',
		path: '/admin/eshopy',
	},
	{
		name: 'Export',
		icon: 'nc-icon nc-paper',
		path: '/admin/export-historie',
	},
	{
		name: 'Šablony',
		icon: 'nc-icon nc-email-85',
		path: '/admin/sablony',
		newAppRedirect: true,
	},
	{
		name: 'Výchozí hodnoty',
		icon: 'nc-icon nc-email-85',
		path: '/admin/zakladni-hodnoty',
	},
];

const sidebarUserLinks = [
	{ name: 'administrator-list', display: 'Administrátoři' },
	{ name: 'employee-list', display: 'Zaměstnanci' },
	{ name: 'logistics-list', display: 'Logistici' },
	{ name: 'driver-list', display: 'Řidiči' },
	{ name: 'password-change', display: 'Změna hesla' },
];

const config = {
	appName: appNameList.admin,
	sidebarLinks,
	sidebarUserLinks,
};

config.install = function (Vue) {
	Vue.prototype.$getConst = (key) => config[key];

	Validator.extend('validateAddress', {
		getMessage: () => this.$t('admin.addressNotFound'),
		async validate(value, args) {
			const { state, county, city, street } = args;

			// eslint-disable-next-line no-return-await
			return await LogService(http).validateAddress(state, county, city, street);
		},
	});

	Validator.extend('validateGps', {
		getMessage: () => this.$t('admin.gpsNotFound'),
		async validate(value) {
			if (value) {
				return LogService(http).validateGps(value);
			}

			return true;
		},
	});
};

export const appName = appNameList.admin;

export default config;
