<template>
	<div v-loading="loading" class="cards">
		<card v-for="note in notes" :key="note.noteId">
			<div class="note-content">
				{{ note.note }}
			</div>
			<div class="noteCreatedBy mt-1">
				{{ $t('eshop.created') }} {{ note.createdDate | datetime }} {{ $t('eshop.byUser') }} {{ note.createdBy.username }}
			</div>
		</card>
		<div class="clearfix">
			<p-button
				v-show="!showNewNoteForm"
				class="pull-right mb-4"
				type="info"
				@click="showNoteForm">
				<i class="fa fa-plus" /> {{ $t('eshop.addNote') }}
			</p-button>
		</div>
		<card v-show="showNewNoteForm">
			<div class="card-body">
				<div class="form-group mb-3">
					<label>
						{{ $t('eshop.note') }}
					</label>
					<el-input
						ref="noteInput"
						v-model="noteText"
						:data-vv-as="$t('eshop.note')"
						:label="$t('eshop.note')"
						:placeholder="$t('eshop.notePlaceholder')"
						:rows="5"
						class="text-monospace"
						name="noteText"
						type="textarea" />
				</div>
				<confirm-button
					:confirm-message="$t('eshop.reallyAddNote')"
					:show-success-message="false"
					class="pull-right ml-2 btn-lg"
					type="success"
					:callback="save">
					{{ $t('eshop.save') }}
				</confirm-button>
			</div>
		</card>
	</div>
</template>

<script>
import { Input as ElInput } from 'element-ui';

import notifyService from '@/service/notify-service';

export default {
	name: 'ReservationNote',
	components: {
		ElInput,
	},
	props: {
		reservation: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			noteText: null,
			notes: [],
			loading: false,
			showNewNoteForm: false,
		};
	},

	watch: {
		reservation: {
			async handler() {
				if (this.reservation?.reservationId) {
					this.loading = true;
					this.notes = await this.$service.reservation.getNotes(this.reservation.reservationId);
					this.loading = false;
				}
			},
			deep: true,
		},
	},

	methods: {
		async save() {
			try {
				this.loading = true;
				const note = {
					note: this.noteText,
					reservationId: this.reservation?.reservationId,
				};
				this.notes = await this.$service.reservation.saveNotes(note);

				notifyService.notifySuccess(this.$t('eshop.saveNoteSuccess'));
			} catch {
				notifyService.notifyError(this.$t('eshop.saveNoteError'));
			} finally {
				this.loading = false;
				this.showNewNoteForm = false;
				this.noteText = '';
			}
		},
		showNoteForm() {
			this.showNewNoteForm = true;
			this.$nextTick(() => {
				this.$refs.noteInput.$el.querySelector('textarea').focus();
			});
		},
	},
};
</script>

<style scoped>
.noteCreatedBy {
	font-size: 0.85em;
}
.note-content {
    background-color: rgb(249, 249, 249);
    border-radius: 3px;
    padding: 5px;
    white-space: pre-line;
}
</style>
