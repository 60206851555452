import config from '../config';

export const getDefaultState = () => ({
	homePage: 'pickup-delivery',
	clientId: 'adminApp',
	scopeName: 'StoreScope',
	sidebarUserLinks: config.sidebarUserLinks,
	refreshTokenLoading: false,
	isLoginActive: false,
	currentUser: {
		name: '',
		surname: '',
		email: '',
		roles: [],
	},
	userFilter: {
		locked: null,
	},
	formChanged: false,
	loginError: null,
	versionInfo: null,
	reservationFilter: {
		isExpired: null,
		region: null,
		county: null,
		city: null,
		street: null,
		Status: null,
		isPostPackage: null,
		ReservationNumberLike: null,
		Phone: null,
		From: null,
		To: null,
		Email: null,
		orderBy: null,
		direction: null,
		prepaidTo: null,
		reservationSelects: [],
	},
	reportFilter: {
		name: null,
		note: null,
		isActive: null,
		containers: [],
		orderBy: null,
		direction: null,
	},
});

export const state = getDefaultState();
