import Vuex from 'vuex';
import Vue from 'vue';

import mutations from './mutations';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		currentAppName: '',
		apps: [],
		userFilter: {
			locked: null,
			username: null,
			email: null,
		},
		sidebar: {
			isMinimized: false,
			showSidebar: false,
		},
		theSpecialModal: {
			show: false,
			title: null,
			confirmButtonText: null,
			cancelButtonText: null,
			text: null,
			icon: null,
			actionId: null,
			returnValue: null,
			storeParameters: null,
			showInput: false,
			requireInput: false,
			inputDescription: null,
		},
		theSpecialModalInputText: null,
	},
	mutations: {
		...mutations,
		async addApp(state, appName) {
			if (state.apps.indexOf(appName) === -1) {
				state.apps.push(appName);
				// eslint-disable-next-line global-require,import/no-dynamic-require
				const context = await require(`@/modules/${appName}`);
				const module = context[Object.keys(context)[0]].store;
				store.registerModule(appName, module);
			}
		},
	},
});

export default store;
