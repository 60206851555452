<template>
	<div>
		<div
			v-for="(container, containerKey) in containers"
			:key="`container-${container.containerSn}-${containerKey}`"
			class="mt-3 table-responsive">
			<base-report-print-sheet-table nomargin>
				<thead>
					<tr>
						<th><span v-if="container.containerSn">{{ container.containerType | containerType }}</span> {{ container.containerSn }}</th>
						<th v-if="!container.containerId && !container.reservations.length" class="highlightBlank">
							{{ $t('logistics.addressNotFilled') }}
						</th>
						<th v-else-if="!container.containerId">
							{{ getPrivateCustomerAddress(container) }}
						</th>
						<th v-else>
							{{ container | locationReport }}
						</th>
						<th v-if="container.latitude && container.longitude" :colspan="3">
							{{ container.latitude | coordinatesRad }}N, {{ container.longitude | coordinatesRad }}E
						</th>
						<th v-else :colspan="3" class="highlightBlank">
							{{ $t('logistics.coordinatesNotFilled') }}
						</th>
						<th>
							<div v-if="showBatteryAlert(container) && container.containerType === 'PenguinBox'" class="alert-danger">
								<span v-if="container.isOffline && container.batteryVoltage > batteryThresholds.critical">{{ $t('logistics.containerStuck') }}</span>
								<span v-else-if="container.batteryVoltage < batteryThresholds.critical">{{ $t('logistics.containerOutOfBattery') }}</span>
								<span v-else-if="container.batteryVoltage < batteryThresholds.runningLow">{{ $t('logistics.containerLowBattery') }}</span>
							</div>
							<div v-if="showBatteryAlert(container) && container.containerType === 'SmartKeyBox'" class="alert-danger">
								<span v-if="container.isOffline && container.batteryVoltage > smartBatteryThresholds.critical">{{ $t('logistics.containerStuck') }}</span>
								<span v-else-if="container.batteryVoltage < smartBatteryThresholds.critical">{{ $t('logistics.containerOutOfBattery') }}</span>
								<span v-else-if="container.batteryVoltage < smartBatteryThresholds.warning">{{ $t('logistics.containerLowBattery') }}</span>
							</div>
							<div v-if="container.openingHours">
								<span v-if="container.openingHours.status === 'Closed'">
									{{ $t('logistics.closed') }}
								</span>
								<span v-else-if="container.openingHours.openTime && !container.openingHours.closeTime">
									{{ $t('logistics.open24hours') }}
								</span>
								<span v-else>
									{{ container.openingHours.openTime }}-{{ container.openingHours.closeTime }}
									<span v-if="container.openingHours.openTimeNext"> {{ container.openingHours.openTimeNext }}-{{ container.openingHours.closeTimeNext }} </span>
								</span>
							</div>
						</th>
						<th />
					</tr>
				</thead>

				<tbody v-if="container.reservations">
					<tr
						v-for="(reservation, reservationKey) in container.reservations"
						:key="`reservation-${reservation.reservationId}-${reservationKey}`"
						:ref="`reservation-${reservation.reservationId}-${reservationKey}`"
						:class="reservation.groupClass">
						<td>
							<div>
								<div v-if="reportIsActive && reservation.status === reservation.currentStatus" class="action">
									<confirm-button
										:callback="() => removeReservation(reservation.reservationId)"
										:confirm-message="$t('logistics.removeReservationFromReport')"
										:show-success-message="false"
										type="danger">
										<i class="fa fa-times" />
									</confirm-button>
									<p-button v-if="reservation.isPrivateAddress" type="info" @click="editReservation(container, reservation.reservationId)">
										<span class="fa fa-pen" />
									</p-button>
								</div>
								<span v-if="reservation.reservationNumber">
									{{ reservation.reservationNumber && reservation.reservationNumber.replaceAll(',', ', ') }}
								</span>
								<div v-if="reservation.stockItemSku">
									SKU: {{ reservation.stockItemSku }}
								</div>
							</div>
						</td>
						<td>{{ getReservationName(reservation) }}</td>
						<td>{{ reservation.phone }}</td>
						<td>
							<span v-if="container.containerType !== 'SmartKeyBox'">PIN: <span class="text-pin">{{ reservation.unlockCode }}</span>
							</span>
							<span v-else>{{ reservation.lockName ? "BOX:" : "" }} {{ reservation.lockName }}</span>
						</td>
						<td>{{ reservation.packagesCount }} ks</td>
						<td v-if="ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.WAIT_FOR_DELIVERY" class="noBorder">
							<table>
								<tr>
									<td v-if="reservation.containerId">
										{{ $t('logistics.deliveryState') }}
									</td>
									<td v-else>
										{{ $t('logistics.deliveryStateAddress') }}
									</td>
								</tr>
								<tr>
									<td>{{ $t('logistics.reason') }}:</td>
								</tr>
							</table>
						</td>
						<td v-else-if="ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.WAIT_FOR_COLLECT">
							{{ ' ' }}
							<p-button
								v-if="reservation.isPostPackage && ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.COLLECTED"
								type="success"
								@click="confirmCollected(reservation.reservationId)">
								<i class="fa fa-check" /> {{ $t('logistics.accepted') }}
							</p-button>
						</td>
						<td v-else-if="isExpired(reservation)">
							{{ $t('logistics.expiredRouteInfo') }}
						</td>
						<td v-else />
						<td class="text-center">
							<span v-if="ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.WAIT_FOR_DELIVERY" class="noBorder">
								<i
									v-if="isDeliveredToBox(reservation) || isDeliveredToPrivate(reservation)"
									:title="$t('logistics.prepared')"
									class="fa fa-2x fa-check text-success" />
								<i
									v-else-if="compareRouteStatus(reservation, ReservationRouteStatus.CONFIRMED_BY_DRIVER)"
									:title="$t('logistics.pickedUp')"
									class="fa fa-2x fa-car text-success" />
								<i
									v-else-if="compareRouteStatus(reservation, ReservationRouteStatus.ASSIGNED)"
									:title="$t('logistics.notPickedUp')"
									class="fa fa-2x fa-home text-danger" />
							</span>
							<span v-else-if="isReadyForRouteStatus(reservation)" class="noBorder">
								<i
									:title="$t('logistics.completed')"
									class="fa fa-2x fa-check text-success" />
							</span>
						</td>
					</tr>
					<tr
						v-for="(stockItem, stockItemKey) in container.stockItemsCounts"
						:key="`stock_item-${container.containerId}-${stockItem.item.id}-${stockItemKey}`"
						:class="stockItem.groupClass">
						<td>SKU: {{ stockItem.item.stockId }}</td>
						<td>{{ $t('logistics.stockItemDelivery') }} {{ stockItem.item.name }}</td>
						<td colspan="3">
							min {{ stockItem.count }} {{ $t('logistics.piece') }}
						</td>
						<td>{{ $t('logistics.refill') }} ______ {{ $t('logistics.piece') }}</td>
					</tr>
				</tbody>
			</base-report-print-sheet-table>
		</div>
	</div>
</template>

<script>
import { ReservationStatus, ReservationRouteStatus } from '@/modules/logistics/store/enums';
import notifyService from '@/service/notify-service';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import ReportDetailsMixin from '@/util/report-details-mixin';
import { BaseReportPrintSheetTable } from '@/components/Base';
import { batteryThresholds, smartBatteryThresholds } from '@/globalConstants';

export default {
	name: 'ContainersInfo',
	components: {
		BaseReportPrintSheetTable,
	},
	mixins: [ReservationNameMixin, ReportDetailsMixin],
	props: {
		containers: {
			type: Array,
			default: () => [],
		},
		reportIsActive: {
			type: Boolean,
			required: true,
		},
		logisticRouteId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			ReservationRouteStatus,
			ReservationStatus,
			lastReservationGroup: null,
			batteryThresholds,
			smartBatteryThresholds,
		};
	},
	watch: {
		containers: {
			deep: true,
			handler() {
				this.lastReservationGroup = null;
			},
		},
	},
	methods: {
		compareRouteStatus({ reservationRouteStatus }, routeStatus) {
			return ReservationRouteStatus.enumValueOf(reservationRouteStatus) === routeStatus;
		},
		isReadyForRouteStatus(reservation) {
			if (ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.WAIT_FOR_DELIVERY) {
				return (
					ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.PICK_UP
            || ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.DISCARD
            || ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.PREPARED
            || ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.COLLECTED
            || this.isExpired(reservation)
				);
			}
			if (ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.PREPARED) {
				return (
					ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.COLLECTED
				);
			}
			return false;
		},
		isDeliveredToBox(reservation) {
			return (
				(ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.PREPARED
        || ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.PICK_UP) // When customer pickUp soon, then report is finished.
        && !reservation.isPrivateAddress
			);
		},
		isDeliveredToPrivate(reservation) {
			return (
				ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.PICK_UP && reservation.isPrivateAddress
			);
		},
		removeReservation(reservationId) {
			this.$emit('set-loading', true);

			this.$service.logisticsRoute
				.removeReservationFromRoute(reservationId, this.logisticRouteId)
				.then(() => {
					// TODO: Remove page reload and return correct data from backend to enhance user experience
					// PENG-1559
					this.$router.go(0);
				})
				.catch((err) => {
					notifyService.notifyError(err.data.errors.general[0]);
					this.$emit('set-loading', false);
				});
		},
		editReservation(containerToEdit, reservationId) {
			const reservationToEdit = containerToEdit.reservations.find((r) => r.reservationId === reservationId);
			reservationToEdit.addressDetails = {
				latitude: containerToEdit.latitude,
				longitude: containerToEdit.longitude,
				city: containerToEdit.city,
				street: containerToEdit.street,
				psc: containerToEdit.psc,
			};
			this.$emit('set-editing-reservation', reservationToEdit);
		},
		async confirmCollected(reservationId) {
			try {
				this.$emit('set-loading', true);

				await this.$service.reservation.ackCollected(reservationId);

				notifyService.notifySuccess(this.$t('admin.collectedAckSuccess'));

				this.$emit('get-report');
			} catch {
				notifyService.notifyError(this.$t('admin.reservationChangeStateFailure'));
			} finally {
				this.$emit('set-loading', false);
			}
		},
		getReservationName(reservation) {
			if (reservation && reservation.customerName && reservation.customerAddress) {
				reservation.customerAddress = reservation.customerAddress.replace(`${reservation.customerName}, `, '');
			}
			return this.reservationName(reservation);
		},
	},
};
</script>

<style lang="scss" scoped>
td .action {
  display: none;
}
tr:hover .action {
  display: inline;
}
.headerOfContainerTable {
  border-bottom: 3px solid black;
}

.noBorder {
  td {
    font-size: 9px;
    padding: 1px;
  }
  * {
    border: none;
  }
}
.qr_code-area {
  position: fixed;
  bottom: 0;
  margin-left: -15px;
  margin-bottom: 15px;
  width: 500px;
  max-width: 100%;
}
.text-big {
  font-size: 115%;
}

.selected-box {
  animation: selected-box-animation 1s linear 1 1s;
  background: palegreen;
}

@keyframes selected-box-animation {
  from {
    background: red;
    color: white;
  }
  to {
    color: initial;
    background: palegreen;
  }
}

.mb-max {
  margin-bottom: 350px;
}

.table-responsive {
  overflow: auto;
}

.availableReservations {
  min-width: 579px;
}
.group-border td {
  border-top-width: 2px;
}
.highlightBlank {
  background-color: #f5f588;
}
.text-pin {
  font-size: 1.2rem;
}
</style>
