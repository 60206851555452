<template>
	<div v-loading="loading" class="report-area">
		<base-report-print-sheet :report-data="normalizedReportData" @load="loadData" @set-report-data="setReportData">
			<template #headerActions>
				<div class="mb-3 d-flex justify-content-between">
					<p-button :disabled="!totalReservationsToBeLoaded || showQrCodeArea" type="success" @click="toggleDisplayQrCodeArea">
						<i slot="labelRight" class="fa fa-warehouse" />
						{{ $t('driver.takeOverToTransport') }}
					</p-button>
				</div>
			</template>
			<template #containersInfo>
				<containers-info
					ref="containers-info"
					:containers="containersToShow"
					:loading="loading"
					:logistic-route-id="normalizedReportData.logisticRouteId"
					:report-is-active="normalizedReportData.isActive" />
			</template>
			<template #actions>
				<div v-if="normalizedReportData && normalizedReportData.mapUrl" class="row mt-3">
					<div class="col-9">
						<a :href="normalizedReportData.mapUrl" class="btn" target="_blank">
							{{ $t('driver.showOnMapyCz') }}
						</a>
					</div>
				</div>
				<div :class="['mt-3 mb-5 mb-md-0 d-flex justify-content-between', { 'mb-max': showQrCodeArea }]">
					<div>
						<p-button @click="print">
							<i slot="labelRight" class="fa fa-print" />
							{{ $t('driver.print') }}
						</p-button>
						<p-button
							:disabled="!totalReservationsToBeLoaded || showQrCodeArea"
							class="ml-2"
							type="success"
							@click="toggleDisplayQrCodeArea">
							<i slot="labelRight" class="fa fa-warehouse" />
							{{ $t('driver.takeOverToTransport') }}
						</p-button>
					</div>
				</div>
			</template>
		</base-report-print-sheet>

		<ack-load-to-lorry-reservation
			v-if="reportData"
			:report-data="normalizedReportData"
			:show="showQrCodeArea"
			:load-data="loadData"
			:total-reservations-to-be-loaded="totalReservationsToBeLoaded"
			@change-show="setDisplayQrCodeArea"
			@change-remaining-reservations-to-be-loaded="setRemainingReservationsToBeLoaded"
			@select-reservation="selectReservation"
			@unselect-reservation="unselectedReservation" />
	</div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { ReservationStatus, ReservationRouteStatus } from '@/modules/driver/store/enums';
import ValidationMixin from '@/util/validated-form-mixin';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import PrintArea from '@/util/print-area';
import { BaseReportPrintSheet } from '@/components/Base';
import FormMixin from '@/util/form-mixin';
// eslint-disable-next-line import/no-cycle
import logisticsRouteSharedService from '@/service/logistics-route-shared-service';

import ContainersInfo from './ReportDetail/ContainersInfo.vue';
import AckLoadToLorryReservation from './ReportDetail/AckLoadToLorryReservation.vue';

export default {
	name: 'ReportDetails',
	components: {
		AckLoadToLorryReservation,
		ContainersInfo,
		BaseReportPrintSheet,
	},
	mixins: [ValidationMixin, ReservationNameMixin, FormMixin],
	data() {
		return {
			reportData: null,
			loading: false,
			scanQrCode: false,
			showQrCodeArea: false,
			remainingReservationsToBeLoaded: 0,
		};
	},
	computed: {
		qrCode() {
			return this.normalizedReportData.mapUrl || '';
		},
		containersToShow() {
			return (this.normalizedReportData?.containers || []).filter((container) => !!container.reservations);
		},
		scanQrCodeLabel() {
			return this.scanQrCode ? this.$t('driver.hideQrCode') : this.$t('driver.scanQrCode');
		},
		normalizedReportData: {
			cache: false,
			get() {
				const reportData = cloneDeep(this.reportData);

				if (reportData?.containers) {
					reportData.containers = reportData.containers.map((container) => {
						if (container.reservations && container.reservations.length) {
							container.waitForDeliveryReservations = [];

							container.reservations.forEach((reservation) => {
								if (ReservationStatus.WAIT_FOR_DELIVERY === ReservationStatus.enumValueOf(reservation.status)) {
									container.waitForDeliveryReservations.push(reservation);
								}
							});
						}
						return container;
					});
				}
				return reportData;
			},
		},
		totalReservationsToBeLoaded() {
			if (this.normalizedReportData?.containers) {
				return this.normalizedReportData.containers.reduce(
					(total, container) => total
						+ (container.waitForDeliveryReservations || []).filter(({ reservationRouteStatus }) => reservationRouteStatus
							&& ReservationRouteStatus.enumValueOf(reservationRouteStatus) === ReservationRouteStatus.ASSIGNED).length,
					0,
				);
			}
			return 0;
		},
	},

	created() {
		this.loadData();
	},

	methods: {
		async loadData() {
			this.loading = true;
			this.setReportData(await logisticsRouteSharedService.getReport(this.$route.params.id));
			this.loading = false;
		},
		setReportData(reportData) {
			this.reportData = reportData;
			this.reportData.containers = this.reportData.routeStops;
		},
		print() {
			new PrintArea('printRoute').print();
		},
		toggleDisplayQrCodeArea() {
			this.setDisplayQrCodeArea(!this.showQrCodeArea);
		},
		setDisplayQrCodeArea(show) {
			this.showQrCodeArea = show;
		},
		setRemainingReservationsToBeLoaded(number) {
			this.remainingReservationsToBeLoaded = number;
		},
		selectReservation(reservation) {
			const reservationNode = this.getReservationNode(reservation);

			if (reservationNode) {
				const { classList } = reservationNode;
				classList.add('selected-box');
				reservationNode.scrollIntoView();
			}
		},
		unselectedReservation(reservation) {
			const reservationNode = this.getReservationNode(reservation);

			if (reservationNode) {
				reservationNode.classList.remove('selected-box');
			}
		},
		getReservationNode(reservation) {
			const refNode = this.$refs['containers-info'].$refs[`reservation-${reservation.reservationId}-${reservation.containerSn}`];

			return refNode ? refNode[0] : null;
		},
	},
};
</script>

<style lang="scss" scoped>
td .action {
	display: none;
}
tr:hover .action {
	display: inline;
}
.noBorder {
	td {
		font-size: 9px;
		padding: 1px;
	}
	* {
		border: none;
	}
}
.noBorder {
	td {
		font-size: 9px;
		padding: 1px;
	}
	* {
		border: none;
	}
}

.table-responsive {
	overflow: auto;
}
.report-area {
	max-width: 30cm;
	overflow: hidden;
}
</style>
