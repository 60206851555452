<template>
	<div class="row">
		<div class="col-sm-12 mt-2 card">
			<el-table
				class="table-striped"
				:data="mails"
				show-overflow-tooltip
				row-class-name="tableRow">
				<el-table-column header-align="center" align="center" property="email">
					<template #header>
						<el-tooltip :content="$t('logistics.emailForNotify')" placement="top-start">
							<span>{{ $t('logistics.emailForNotify') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<div :key="scope.row.email">
							{{ scope.row.email }}
						</div>
					</template>
				</el-table-column>
				<el-table-column header-align="center" align="center" property="email">
					<template #header />
					<template #default="scope">
						<p-button
							class="mx-1"
							type="danger"
							size="sm"
							icon
							@click.stop="deleteMail(scope.row)">
							<i class="fa fa-times" />
						</p-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="d-flex align-items-center justify-content-center">
				<div>
					<form-group-input
						v-model="newMail"
						:label="$t('logistics.newEmail')"
						required
						type="text" />
				</div>
				<div class="ml-2 mt-2">
					<p-button
						class="mx-1"
						type="success"
						size="sm"
						icon
						@click.stop="addMail()">
						<i class="fa fa-plus" />
					</p-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip } from 'element-ui';

import notifyService from '@/service/notify-service';

export default {
	name: 'SettingsView',
	components: {
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	data() {
		return {
			mails: [],
			newMail: null,
		};
	},
	async created() {
		this.mails = await this.$service.mailNotifyService.getNotifyMails();
	},
	methods: {
		async deleteMail(mail) {
			try {
				this.mails = await this.$service.mailNotifyService.deleteNotifyMail(mail.email);
			} catch {
				notifyService.notifyError(this.$t('logistics.cantRemoveNotifyMail'));
			}
		},
		async addMail() {
			try {
				this.mails = await this.$service.mailNotifyService.addNotifyMail(this.newMail);
				this.newMail = null;
			} catch {
				notifyService.notifyError(this.$t('logistics.cantAddNotifyMail'));
			}
		},
	},
};
</script>

<style scoped>
.form-group{
	min-width: 400px;
}
</style>
