<template>
	<div class="row">
		<div class="col-7">
			<div>
				<div>
					<div class="d-flex">
						<label :title="$t('logistics.selectContainersUnderneath')">
							<span class="number_cycle">1</span>
							{{ $t('logistics.selectContainers') }}
						</label>
						<p-checkbox v-model="filter.onlyWithReservations" :disabled="loading" class="ml-3">
							{{ $t('logistics.ShowOnlyWithReservations') }}
						</p-checkbox>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3">
						<p-checkbox
							v-model="pbAndSbPossibilitiesAll"
							:disabled="loading"
							class="ml-3"
							@input="setChangeFilterWatching">
							<strong>PB, SB</strong>
						</p-checkbox>
						<div class="ml-4">
							<p-checkbox v-model="filter.pbAndSbPossibilities.post" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesPost') }}
							</p-checkbox>
							<p-checkbox v-model="filter.pbAndSbPossibilities.delivery" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesDelivery') }}
							</p-checkbox>
							<p-checkbox v-model="filter.pbAndSbPossibilities.returns" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesReturns') }}
							</p-checkbox>
							<p-checkbox v-model="filter.pbAndSbPossibilities.directDelivery" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesDirectDelivery') }}
							</p-checkbox>
							<p-checkbox v-model="filter.pbAndSbPossibilities.expirations" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesExpirations') }}
							</p-checkbox>
							<p-checkbox v-model="filter.onlyService" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesService') }}
							</p-checkbox>
						</div>
					</div>
					<div class="col-md-3">
						<p-checkbox
							v-model="storePossibilitiesAll"
							:disabled="loading"
							class="ml-3"
							@input="setChangeFilterWatching">
							<strong>{{ $t('logistics.storePossibilities') }}</strong>
						</p-checkbox>
						<div class="ml-4">
							<p-checkbox v-model="filter.storePossibilities.post" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesPost') }}
							</p-checkbox>
							<p-checkbox v-model="filter.storePossibilities.delivery" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesDelivery') }}
							</p-checkbox>
							<p-checkbox v-model="filter.storePossibilities.returns" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesReturns') }}
							</p-checkbox>
							<p-checkbox v-model="filter.storePossibilities.expirations" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesExpirations') }}
							</p-checkbox>
						</div>
					</div>
					<div class="col-md-3">
						<p-checkbox
							v-model="addressPossibilitiesAll"
							:disabled="loading"
							class="ml-3"
							@input="setChangeFilterWatching">
							<strong>{{ $t('logistics.addressPossibilities') }}</strong>
						</p-checkbox>
						<div class="ml-4">
							<p-checkbox v-model="filter.addressPossibilities.post" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesPost') }}
							</p-checkbox>
							<p-checkbox v-model="filter.addressPossibilities.delivery" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesDelivery') }}
							</p-checkbox>
							<p-checkbox v-model="filter.addressPossibilities.returns" :disabled="loading" class="ml-3">
								{{ $t('logistics.possibilitiesReturns') }}
							</p-checkbox>
						</div>
					</div>
				</div>

				<div class="mt-2">
					<base-containers-tree-view
						ref="tree"
						:filter="filter"
						:value="selectedContainers"
						filter-promptly
						@add="addToSelected"
						@remove="removeFromSelected"
						@tree-loading="setTreeLoading" />
				</div>
			</div>
		</div>

		<div class="col-5">
			<div>
				<label :title="$t('logistics.checkOrderUnderneath')">
					<span class="number_cycle">2</span>
					{{ $t('logistics.checkOrder') }}
				</label>
			</div>

			<div class="col-12 mb-2 d-flex justify-content-center">
				<el-pagination
					layout="total, sizes, prev, pager, next, jumper"
					v-bind="pagination"
					@current-change="loadTableData"
					@size-change="loadDataPageSize" />
			</div>

			<el-table
				:data="pageData"
				:empty-text="$t('logistics.selectContainers')"
				:row-class-name="tableRowClassName"
				class="pl-1 mb-3"
				@row-click="goToContainer">
				<el-table-column width="50">
					<template #default="scope">
						<el-tooltip :content="$t('logistics.tooltips.remove')" placement="left">
							<p-button type="warning" @click.stop="removeFromSelected(scope.row)">
								<i class="fa fa-minus" />
							</p-button>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column :label="$t('logistics.order')" width="100">
					<template #default="scope">
						{{ scope.row.order }}
					</template>
				</el-table-column>
				<el-table-column :label="$t('logistics.container')">
					<template #default="scope">
						<span v-if="scope.row && (scope.row.city || scope.row.street || scope.row.location)">
							{{ scope.row | locationReport }}
							<span v-if="!scope.row.containerId" class="badge badge-warning">private</span>
						</span>
						<strong v-else class="text-danger"> <i class="fa fa-exclamation-triangle" /> {{ $t('logistics.missingContainerInfo') }} </strong>
					</template>
				</el-table-column>
				<el-table-column v-slot="scope" align="right" width="100">
					<div class="row">
						<div class="col d-flex justify-content-end">
							<p-button
								v-if="scope.$index > 0"
								icon
								size="sm"
								squared
								@click.stop="containerSooner(scope.row)">
								<i class="fa fa-chevron-up" />
							</p-button>
						</div>
						<div class="col d-flex justify-content-start">
							<p-button
								v-if="selectedContainers && scope.$index !== selectedContainers.length - 1"
								icon
								size="sm"
								squared
								@click.stop="containerLater(scope.row)">
								<i class="fa fa-chevron-down" />
							</p-button>
						</div>
					</div>
				</el-table-column>
			</el-table>

			<div class="col-12 d-flex justify-content-center">
				<el-pagination
					layout="total, sizes, prev, pager, next, jumper"
					v-bind="pagination"
					@current-change="loadTableData"
					@size-change="loadDataPageSize" />
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip, Pagination as ElPagination } from 'element-ui';
import _ from 'lodash';

import { BaseContainersTreeView } from '@/components/Base';

export default {
	name: 'ContainersSelectArea',
	components: {
		BaseContainersTreeView,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElPagination,
	},
	/* value need to be in the following format:
     [
           {
             containerId: 1,
             region: 'Kraj',
             county: 'Okres',
             city: 'Default city',
             street: 'Default street',
             location: 'Default location',
           },
     ]
  */
	props: {
		value: {
			type: Array,
			default: () => [],
			description: 'Selected containers.',
		},
		allowService: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			pagination: {
				pageSize: 50,
				pageSizes: [10, 20, 50, 100],
				pagesCount: 0,
				currentPage: 1,
				total: 0,
			},
			pageData: [],
			loading: false,
			selectedContainers: [],
			expandedKeys: [],
			checkedKeys: [],
			filter: {
				addressPossibilities: {
					post: true,
					delivery: true,
					returns: true,
				},
				storePossibilities: {
					post: true,
					delivery: true,
					returns: true,
					expirations: true,
				},
				pbAndSbPossibilities: {
					post: true,
					delivery: true,
					returns: true,
					expirations: true,
					directDelivery: true,
				},
				onlyWithReservations: false,
				onlyService: this.allowService,
			},
			addressPossibilitiesAll: true,
			storePossibilitiesAll: true,
			pbAndSbPossibilitiesAll: true,
		};
	},
	watch: {
		filter: {
			async handler(newVal, oldVal) {
				const { pbAndSbPossibilities, storePossibilities, addressPossibilities, onlyService } = oldVal;
				this.changeFilterWatching = true;

				this.pbAndSbPossibilitiesAll =					pbAndSbPossibilities.post
					|| pbAndSbPossibilities.delivery
					|| pbAndSbPossibilities.returns
					|| pbAndSbPossibilities.expirations
					|| pbAndSbPossibilities.directDelivery
					|| onlyService;
				this.storePossibilitiesAll = storePossibilities.post || storePossibilities.delivery || storePossibilities.returns || storePossibilities.expirations;
				this.addressPossibilitiesAll = addressPossibilities.post || addressPossibilities.delivery || addressPossibilities.returns;

				this.filter = newVal;
			},
			deep: true,
		},
		'filter.onlyService': {
			handler(val) {
				this.$emit('set-allow-service', val);
			},
		},
		selectedContainers: {
			async handler(value) {
				this.loadTableData(this.pagination.currentPage);
				this.refreshPageMetaData(value);
			},
			deep: true,
		},
		value: {
			async handler(val) {
				// sortBy creates new arrays, so we don't mutate the original ones
				if (!_.isEqual(_.sortBy(this.selectedContainers), _.sortBy(val))) {
					this.selectedContainers = val;
					this.expandedKeys = [];
					this.checkedKeys = [];
					await this.init();
				}
			},
			deep: true,
		},
		filterWithAction(val) {
			this.$refs.tree.makeFilter(val);
		},
		addressPossibilitiesAll(val) {
			if (!this.changeFilterWatching) {
				this.filter.addressPossibilities = {
					returns: val,
					post: val,
					delivery: val,
				};
			}
		},
		storePossibilitiesAll(val) {
			if (!this.changeFilterWatching) {
				this.filter.storePossibilities = {
					returns: val,
					post: val,
					delivery: val,
					expirations: val,
				};
			}
		},
		pbAndSbPossibilitiesAll(val) {
			if (!this.changeFilterWatching) {
				this.filter.pbAndSbPossibilities = {
					returns: val,
					post: val,
					delivery: val,
					expirations: val,
					directDelivery: val,
				};
				this.filter.onlyService = val;
			}
		},
	},
	async created() {
		this.selectedContainers = this.value;

		this.loadTableData();
		this.refreshPageMetaData(this.selectedContainers);
	},
	methods: {
		setTreeLoading(treeLoading) {
			this.loading = treeLoading;
			this.$emit('tree-loading', treeLoading);
		},
		setChangeFilterWatching() {
			this.changeFilterWatching = false;
		},
		loadTableData(page = 1) {
			const { pageSize } = this.pagination;
			const start = (page - 1) * pageSize;
			const end = start + pageSize;

			this.pageData = this.selectedContainers.slice(start, end);
			this.pagination.currentPage = page;
		},
		refreshPageMetaData(selectedContainers) {
			this.pagination.pagesCount = Math.ceil(selectedContainers.length / this.pagination.pageSize);
			this.pagination.total = selectedContainers.length;
		},
		loadDataPageSize(pageSize) {
			this.pagination.pageSize = pageSize;

			this.loadTableData(this.pagination.currentPage);
		},
		goToContainer(container) {
			this.$refs.tree.goToContainer(container);
		},
		addToSelected(data, nodeId, cb) {
			const { container, routeStopId, reservationId } = data;

			if (!this.selectedContainers.some((selectedContainer) => selectedContainer.routeStopId === routeStopId)) {
				const path = this.parseNodePath(nodeId || data.nodeId);
				this.selectedContainers.push({
					routeStopId,
					containerId: container.containerId,
					reservationId,
					region: path.region,
					county: path.county,
					city: path.city,
					street: container.street,
					location: container.location,
					order: this.selectedContainers.length + 1,
				});
			}

			if (cb) {
				cb(data, nodeId || data.nodeId);
			} else {
				this.$refs.tree.addToSelected(container, nodeId || data.nodeId);
			}

			this.$emit('input', this.selectedContainers);
		},
		removeFromSelected(container, nodeId, cb) {
			const { routeStopId } = container;
			const index = this.selectedContainers.findIndex((selectedContainer) => selectedContainer.routeStopId === routeStopId);

			if (index > -1) {
				this.selectedContainers.splice(index, 1);
				this.recalculateOrder(this.selectedContainers);
			}

			if (cb) {
				cb(container, nodeId);
			} else {
				this.$refs.tree.removeFromSelected(container, nodeId);
			}

			this.$emit('input', this.selectedContainers);
		},
		recalculateOrder(containers) {
			containers.forEach((selectedContainer, i) => {
				selectedContainer.order = i + 1;
			});
		},
		handleDelete(index, row) {
			this.removeFromSelected(row);
		},
		// source: https://github.com/lodash/lodash/issues/1701
		moveElementInArray(array, fromIndex, toIndex) {
			array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
		},
		containerSooner(row) {
			if (this.selectedContainers.length > 0) {
				const index = this.selectedContainers.indexOf(row);

				this.moveElementInArray(this.selectedContainers, index, index - 1);
				this.recalculateOrder(this.selectedContainers);
			}
		},
		containerLater(row) {
			if (this.selectedContainers.length > 0) {
				const index = this.selectedContainers.indexOf(row);

				this.moveElementInArray(this.selectedContainers, index, index + 1);
				this.recalculateOrder(this.selectedContainers);
			}
		},
		parseNodePath(path) {
			const split = path.split('>');

			if (!_.isString(path)) console.error('Provided path isn\'t string.');

			return { region: split[0], county: split[1], city: split[2], routeStopId: split[3] };
		},
		tableRowClassName(q) {
			return q.row.containerId ? 'table-row-box' : '';
		},
	},
};
</script>

<style lang="scss" scoped>
.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}
</style>

<style lang="scss">
.highlight-node {
	font-weight: bold;
	color: #009933;
}

.gray {
	color: #606266;
}

.el-tree-node__content:hover {
	.btn_node {
		display: inline;
		font-size: 10px;
	}
}

.btn_node {
	display: none;
}

.number_cycle {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	padding: 4px 10px;
	display: inline;
	background: #eb6532;
	color: #ffffff;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
}

.table-row-box {
	cursor: pointer;
}

.selected-box {
	animation: selected-box-animation 1s linear 1 1s;
}

@keyframes selected-box-animation {
	from {
		background: red;
		color: white;
	}
	to {
		color: initial;
		background: initial;
	}
}
</style>
