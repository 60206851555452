export default {
	data() {
		return {
			reservation: {},
		};
	},
	computed: {
		reservationCustomerData() {
			return {
				...this.reservation.customer,
				street: this.reservation.customer?.street || this.reservation.street,
				city: this.reservation.customer?.city || this.reservation.city,
				zip: this.reservation.customer?.zip || this.reservation.zip,
				county: this.reservation.customer?.county || this.reservation.county,
				region: this.reservation.customer?.region || this.reservation.region,
				state: this.reservation.customer?.state || this.reservation.state,
			};
		},
	},
};
