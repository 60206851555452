const ONE_MINUTE_IN_MS = 60 * 1000;

export default {
	data() {
		return {
			cameraSettings: 'off',
			cameraResetTimer: null,
		};
	},

	computed: {
		currentAppName() {
			return this.$store.state.currentAppName;
		},
		currentUser() {
			return this.$store.state[this.currentAppName].currentUser;
		},
	},

	methods: {
		cameraReset() {
			clearTimeout(this.cameraResetTimer);

			this.cameraSettings = 'off';

			setTimeout(() => {
				this.cameraSettings = 'auto';

				this.cameraResetTimer = setTimeout(this.cameraReset, ONE_MINUTE_IN_MS);
			}, 300);
		},
		switchCamera(showWQrCodeArea) {
			if (showWQrCodeArea) {
				this.cameraReset();
			} else {
				this.cameraSettings = 'off';
				clearTimeout(this.cameraResetTimer);
			}
		},
	},
};
