// eslint-disable-next-line import/no-cycle
import config from '../config';

export const getDefaultState = () => ({
	homePage: 'dashboard',
	clientId: 'adminApp',
	scopeName: 'AdministratorScope',
	sidebarUserLinks: config.sidebarUserLinks,
	appsToLogin: [],
	refreshTokenLoading: false,
	currentUser: {
		name: '',
		surname: '',
		email: '',
		roles: [],
	},
	formChanged: false,
	priceMaps: [],
	loginError: null,
	containerValues: {},
	reservationsForCollect: [],
	versionInfo: null,
	deliveryPointFilter: {
		serialNumber: { from: null, to: null },
		state: null,
		region: null,
		county: null,
		city: null,
		street: null,
		location: null,
		isOk: null,
		isOffline: null,
		isLocked: null,
		isDisabled: null,
		orderby: null,
		direction: null,
		type: [],
	},
	reservationFilter: {
		isExpired: null,
		state: null,
		containers: null,
		region: null,
		county: null,
		city: null,
		street: null,
		Status: null,
		DestinationType: null,
		Destination: null,
		isPostPackage: null,
		ReservationNumberLike: null,
		Phone: null,
		EshopName: null,
		From: null,
		prepaidTo: null,
		Email: null,
		orderBy: null,
		direction: null,
		SKU: null,
		reservationSelects: [],
	},
	lastFilteredContainers: null,
	eshopFilter: {
		name: null,
		mail: null,
		isLocked: null,
		orderby: null,
		direction: null,
	},
	userFilter: {
		locked: null,
		username: null,
		email: null,
	},
	pickupPlaceSelects: [],
	containersForSelect: [],
	pickupPlacesForSelect: {
		lastPage: 0,
		pagesCount: 1,
		entities: [],
	},
	templateData: {
		fromStatus: null,
		toStatus: null,
		destination: null,
		group: null,
		eshopId: null,
		language: '',
		content: '',
	},
});

export const state = getDefaultState();
