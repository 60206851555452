<template>
	<div class="row" data-cy="homePage">
		<div class="col-md-12 card">
			<div class="d-flex">
				<div class="col-4">
					<div class="flex-column">
						<label>
							{{ $t('logistics.reservationState') }}
						</label>
						<el-select
							v-model="filter.reservationSelects"
							size="large"
							class="w-100"
							placeholder="Status rezervace"
							value-key="ordinal"
							clearable
							multiple
							@change="loadData">
							<el-option
								v-for="status in availableStatuses"
								:key="status.ordinal"
								:label="status.czech()"
								:value="status">
								<i :class="status.icon()" />
								{{ status.czech() }}
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="col-3">
					<div class="d-flex">
						<form-group-input :label="$t('logistics.reservationPost')" class="ml-4">
							<div class="d-flex mt-1">
								<yes-no-all v-model="filter.isPostPackage" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>
				</div>
				<div class="col">
					<form-group-input v-model="filter.ReservationNumber" :label="$t('logistics.orderNumber')" type="text" />
				</div>
			</div>
			<div class="col-2 mb-1 d-flex justify-content-start">
				<p-button type="success" size="md" @click="loadData">
					{{ $t('logistics.search') }}
				</p-button>
				<el-tooltip :content="$t('logistics.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</div>

		<div class="col-md-12 card">
			<div v-loading="loading" class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-sm-12 mt-2">
						<el-table
							data-cy="ListMainTable"
							class="table-striped"
							:data="reservations"
							show-overflow-tooltip
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							@sort-change="sortChange">
							<el-table-column
								width="60"
								header-align="center"
								align="center"
								property="status">
								<template #header>
									<el-tooltip :content="$t('logistics.state')" placement="top-start">
										<span>{{ $t('logistics.state') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.status" :content="ReservationStatus.czech(scope.row.status)" placement="top-start">
										<div :key="scope.row.status">
											<i :class="ReservationStatus.icon(scope.row.status)" />
										</div>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								property="reservationNumber"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.orderNumber')" placement="top-start">
										<span>{{ $t('logistics.orderNumber') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								property="hasAction"
								header-align="center"
								align="center"
								width="60"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.actions')" placement="top-start">
										<span>{{ $t('logistics.actions') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip
										v-if="canConfirmDelivery(scope.row)"
										:key="scope.row.reservationId"
										:content="$t('logistics.tooltips.confirmDelivery')"
										placement="right-end">
										<p-button
											class="mx-1"
											type="success"
											size="sm"
											icon
											@click.stop="confirmDelivery(scope.row)">
											<i class="fa fa-check-circle" />
										</p-button>
									</el-tooltip>
									<el-tooltip
										v-if="canConfirmPickUp(scope.row)"
										:key="scope.row.reservationId"
										:content="$t('logistics.tooltips.confirmPickUp')"
										placement="right-end">
										<p-button
											class="mx-1"
											type="success"
											size="sm"
											icon
											@click.stop="confirmPickUp(scope.row)">
											<i class="fa fa-check-circle" />
										</p-button>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								property="email"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.email')" placement="top-start">
										<span>{{ $t('logistics.email') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.email" :max-length="13" />
								</template>
							</el-table-column>
							<el-table-column
								property="phone"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.phone')" placement="top-start">
										<span>{{ $t('logistics.phone') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.phone | phone }}
								</template>
							</el-table-column>
							<el-table-column
								property="reservedFrom"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.occupiedFrom')" placement="top-start">
										<span>{{ $t('logistics.occupiedFrom') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.reservedFrom | datetime }}
								</template>
							</el-table-column>
							<el-table-column
								align="center"
								header-align="center"
								property="container.serialNumber"
								sortable="custom"
								width="70">
								<template #header>
									<el-tooltip :content="$t('logistics.SN')" placement="top-start">
										<span>{{ $t('logistics.SN') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<span v-if="scope.row.container">
										{{ scope.row.container.serialNumber }}
									</span>
								</template>
							</el-table-column>
							<el-table-column
								header-align="center"
								align="center"
								sortable="custom"
								property="DeliveryPoint.city">
								<template #header>
									<el-tooltip :content="$t('logistics.city')" placement="top-start">
										<span>{{ $t('logistics.city') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<span v-if="scope.row.deliveryPoint">
										{{ scope.row.deliveryPoint.city }}
									</span>
								</template>
							</el-table-column>
							<el-table-column
								header-align="center"
								align="center"
								sortable="custom"
								property="DeliveryPoint.street">
								<template #header>
									<el-tooltip :content="$t('logistics.street')" placement="top-start">
										<span>{{ $t('logistics.street') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<span v-if="scope.row.deliveryPoint">
										{{ scope.row.deliveryPoint.street }}
									</span>
								</template>
							</el-table-column>
							<el-table-column header-align="center" align="center">
								<template #header>
									<el-tooltip :content="$t('logistics.route')" placement="top-start">
										<span>{{ $t('logistics.route') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<router-link
										v-for="route in scope.row.route"
										:key="route.logisticRouteId"
										:to="{ name: 'report-detail', params: { id: route.logisticRouteId } }">
										{{ route.name }}
										<span :class="( route.loaded === 'ConfirmedByDriver' ) ? 'fa fa-clipboard-check' : 'far fa-clipboard' " />
									</router-link>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 mt-2">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {
	Table as ElTable,
	TableColumn as ElTableColumn,
	Pagination as ElPagination,
	Tooltip as ElTooltip,
	Select as ElSelect,
	Option as ElOption,
} from 'element-ui';

import notifyService from '@/service/notify-service';
import { ReservationStatus } from '@/modules/logistics/store/enums';
import TrimText from '@/components/UIComponents/TrimText.vue';
import { appName } from '@/modules/logistics/config';
import { resetFilter, setFilter } from '@/store/mutation-types';

export default {
	name: 'DeliveryList',
	components: {
		TrimText,
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElSelect,
		ElOption,
	},
	data() {
		return {
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			ReservationStatus,
			loading: false,
			filter: {},
		};
	},
	computed: {
		...mapState(appName, ['reservationFilter']),
		reservations() {
			return this.page.entities;
		},
		availableStatuses() {
			return [ReservationStatus.WAIT_FOR_DELIVERY,
				ReservationStatus.WAIT_FOR_COLLECT,
				ReservationStatus.WAIT_FOR_RETURN,
				ReservationStatus.WAIT_FOR_RETURN_WITH_RESERVATION];
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'reservationFilter', data: val });
			},
		},
	},
	created() {
		this.filter = this.reservationFilter;
		this.loadData();
	},

	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},

		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},

		async loadData(page = 1) {
			this.loading = true;
			this.filter.Status = this.filter.reservationSelects.map((status) => status.camelCase());
			this.page = await this.$service.list.getWaitForDeliveryList(page, this.filter, this.pageSize);
			this.loading = false;
		},

		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'reservationFilter');
			this.filter = this.reservationFilter;
			this.loadData();
		},

		canConfirmDelivery(row) {
			return row.canConfirmDelivery;
		},
		async confirmDelivery(row) {
			try {
				this.loading = true;
				await this.$service.reservation.ackDelivery(row.reservationId);
				notifyService.notifySuccess(this.$t('admin.deliverAckSuccess'));
				this.loadData();
			} catch {
				notifyService.notifyError(this.$t('admin.reservationChangeStateFailure'));
			} finally {
				this.loading = false;
			}
		},

		canConfirmPickUp(row) {
			return row.canConfirmPickUp;
		},
		async confirmPickUp(row) {
			try {
				this.loading = true;
				await this.$service.reservation.ackPickUp(row.reservationId);
				notifyService.notifySuccess(this.$t('admin.pickupAckSuccess'));
				this.loadData();
			} catch {
				notifyService.notifyError(this.$t('admin.reservationChangeStateFailure'));
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
