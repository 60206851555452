import { stringify } from 'qs';
import { isNil, omit, omitBy } from 'lodash';

export const ListService = (http) => ({
	async getContainerList(page = 1, pageSize = 1000, filter = { orderBy: 'city', direction: 'Ascending' }) {
		const filterParams = omitBy(filter, isNil);
		const response = await http.get('/api/Container/List', {
			params: { page, pageSize, ...filterParams },
		});

		return response.data;
	},

	async getDriversList(filter, page = 1, pageSize = 1000) {
		const filterParams = omitBy(filter, isNil);
		const response = await http.get('/api/Account/ListDrivers', {
			params: { page, pageSize, ...filterParams },
		});

		return response.data;
	},

	async getLogisticsList(filter, page = 1, pageSize = 1000) {
		const filterParams = omitBy(filter, isNil);
		const response = await http.get('/api/Account/ListLogistics', {
			params: { page, pageSize, ...filterParams },
		});

		return response.data;
	},

	async getWaitForDeliveryList(page, filter, pageSize = 50) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};

		const response = await http.get('/api/Reservation/WaitForDelivery', config);

		return response.data;
	},

	async getPostalReservation(page, filter, pageSize = 50) {
		const newFilter = {
			...filter,
		};
		newFilter['interval.From'] = filter.insertedDateFrom;
		newFilter['interval.To'] = filter.insertedDateTo;

		const filterParams = omit(omitBy(newFilter, isNil), ['createdDateFrom', 'createdDateTo']);

		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};

		const response = await http.get('/api/Reservation/Postal', config);
		return response.data;
	},
});
