<template>
	<div class="table-card-container">
		<div class="card">
			<slot name="filter" />
		</div>

		<div
			v-loading="loading"
			class="card table-card">
			<div class="table-card-table-paginator">
				<paginator
					:current-page="currentPage"
					:pages-count="pagesCount"
					:page-size="pageSize"
					:page-sizes="pageSizes"
					@go-to-page="$emit('go-to-page', $event)"
					@load-data-page-size="$emit('load-data-page-size', $event)">
					<template #actionButtons>
						<slot name="actionButtons" />
					</template>
				</paginator>
			</div>
			<div class="table-card-table-content">
				<slot />
			</div>
			<div class="table-card-table-paginator">
				<paginator
					:current-page="currentPage"
					:pages-count="pagesCount"
					:page-size="pageSize"
					:page-sizes="pageSizes"
					@go-to-page="$emit('go-to-page', $event)"
					@load-data-page-size="$emit('load-data-page-size', $event)" />
			</div>
		</div>
	</div>
</template>

<script>
import Paginator from './Paginator.vue';

export default {
	name: 'TableCard',
	components: {
		Paginator,
	},
	props: {
		currentPage: {
			type: Number,
			default: 1,
		},
		pagesCount: {
			type: Number,
			default: 1,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		pageSize: {
			type: Number,
			default: 10,
		},
		pageSizes: {
			type: Array,
			default: () => [10, 20, 50, 100],
		},
	},
};
</script>

<style scoped lang="scss">
.table-card {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	&-container{
		display: flex;
		flex-direction: column;
		height: calc(100vh - 150px);
	}

	&-table {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex-grow: 1;
	}

	&-table-content {
		flex-grow: 1;
	}
}
</style>
