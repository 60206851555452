import axios from 'axios';
import { stringify } from 'qs';
import { isNil, omitBy } from 'lodash';

export const LogisticsRouteService = (http) => ({
	async add(routeParams) {
		const response = await http.post('/api/LogisticRoute/Add', routeParams, { headers: { 'api-version': '0.2' } });
		return response.data;
	},
	async addReservationToRoute(reservationId, logisticRouteId) {
		const params = { reservationId, logisticRouteId };
		const response = await http.post('/api/LogisticRoute/AddReservation', params);
		return response.data;
	},
	async removeReservationFromRoute(reservationId, logisticRouteId) {
		const params = { reservationId, logisticRouteId };
		const response = await http.delete('/api/LogisticRoute/Reservation', { params });
		return response.data;
	},
	async update(routeParams) {
		const response = await http.post('/api/LogisticRoute/Update', routeParams, { headers: { 'api-version': '0.2' } });
		return response.data;
	},
	async getList(filter, page = 1, pageSize = 50) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};
		const response = await http.get('/api/LogisticRoute/List', config);

		return response.data;
	},
	async calculateRoute(origin, destination, via) {
		const request = {
			transportMode: 'car',
			return: 'polyline,summary',
			origin,
			destination,
			via,
			apiKey: 'bCnhwMWWaBLWpDb3OKKHuJfFepQOlXocwMNO5d_8CU4',
		};
		const response = await axios.get('https://router.hereapi.com/v8/routes', { params: request });
		return response.data;
	},
	async optimizeRoute(start, end, destinations) {
		const request = {
			start,
			end,
			...destinations,
			improveFor: 'time',
			mode: 'fastest;car;',
			apiKey: 'bCnhwMWWaBLWpDb3OKKHuJfFepQOlXocwMNO5d_8CU4',
		};
		const response = await axios.get('https://wse.ls.hereapi.com/2/findsequence.json', { params: request });
		return response.data;
	},
});
