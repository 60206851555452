export const AdminService = (http) => ({
	async getOne(userId) {
		const response = await http.get('/api/Account/Detail', { params: { userId } });
		return response.data;
	},

	async update(admin) {
		const response = await http.post('/api/Account/Update', admin);
		return response.data;
	},

	async changePassword(userPwd) {
		const response = await http.post('/api/Account/ChangePassword', userPwd);
		return response.data;
	},
});
