<template>
	<div class="row">
		<div class="col-sm-4">
			<card>
				<h2 slot="header" class="card-title">
					{{ $t('global.changePassword') }}
				</h2>

				<form>
					<form-group-input
						v-model="userPwd.password"
						v-validate="validations.oldUserPassword.rules"
						required
						:placeholder="$t('global.enterPassword')"
						:label="$t('global.password')"
						type="password"
						:name="validations.oldUserPassword.name"
						:error="getError(validations.oldUserPassword.name)"
						:data-vv-as="$t('global.password')" />
					<form-group-input
						ref="passwordToBeConfirmed"
						v-model="userPwd.newPassword"
						v-validate="validations.userPassword.rules"
						required
						:placeholder="$t('global.enterNewPassword')"
						:label="$t('global.newPassword')"
						type="password"
						:name="validations.userPassword.name"
						:error="getError(validations.userPassword.name)"
						:data-vv-as="$t('global.newPassword')" />
					<form-group-input
						v-model="userPwd.confirmPassword"
						v-validate="validations.userPasswordConfirmation.rules"
						required
						:placeholder="$t('global.confirmTheNewPassword')"
						:label="$t('global.confirmNewPassword')"
						type="password"
						:name="validations.userPasswordConfirmation.name"
						:error="getError(validations.userPasswordConfirmation.name)"
						:data-vv-as="$t('global.confirmNewPassword')" />
					<confirm-button
						class="pull-right"
						:show-success-message="false"
						:confirm-message="$t('global.changePasswordMessage')"
						:disabled="!isValid"
						:callback="() => validateAndSave('global')">
						{{ $t('global.changePassword') }}
					</confirm-button>
					<div class="clearfix" />
				</form>
			</card>
		</div>
	</div>
</template>

<script>
import ValidationMixin from '@/util/validation-mixin';
import Validations from '@/util/validations';
import FormMixin from '@/util/form-mixin';
import { ConfirmButton } from '@/components/UIComponents';

export default {
	name: 'BaseChangePasswordForm',
	components: {
		ConfirmButton,
	},
	mixins: [ValidationMixin, FormMixin],
	data() {
		return {
			userPwd: {},
			validations: {
				oldUserPassword: {
					name: 'oldUserPassword',
					rules: Validations.required,
				},
				userPassword: {
					name: 'userPassword',
					rules: Validations.passwordRules,
				},
				userPasswordConfirmation: {
					name: 'userPasswordConfirmation',
					rules: Validations.passwordConfirmationRules,
				},
			},
		};
	},
	watch: {
		userPwd: {
			handler() {
				this.$store.commit(`${this.currentAppName}/formChanged`, true);
			},
			deep: true,
		},
	},
	methods: {
		async save() {
			const result = await this.$service.admin.changePassword(this.userPwd);

			if (result.succeeded) {
				this.$store.commit(`${this.currentAppName}/formChanged`, false);
			}

			return result;
		},
	},
};
</script>
