<template>
	<div class="row">
		<div class="col-md-12">
			<h4 class="title">
				Detail cenové mapy
			</h4>
		</div>
		<div v-if="showPriceMapCard" class="col-md-6" data-cy="editPageContent">
			<price-map-form-card
				:price-map="priceMap"
				:delete-pme-cb="deletePriceException"
				@price-map-exception-selected="readSelectedException"
				@new-price-map-exception="newPriceMapException" />
		</div>
		<div v-if="priceMapEditingMode" class="col-md-6">
			<price-exception-form-card
				:key="priceException.description"
				:price-exception="priceException"
				:created-pme-cb="addPriceException"
				:modified-pme-cb="changePriceException" />
		</div>
	</div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import _ from 'lodash';

import FormMixin from '@/util/form-mixin';
import PriceMapFormCard from '@/modules/admin/views/PriceMap/PriceMapDetails/PriceMapFormCard.vue';
import PriceExceptionFormCard from '@/modules/admin/views/PriceMap/PriceMapDetails/PriceExceptionFormCard.vue';

export default {
	name: 'PriceMapForm',
	components: {
		PriceExceptionFormCard,
		PriceMapFormCard,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn,
	},
	mixins: [FormMixin],

	data() {
		return {
			priceMap: {},
			priceMapEditingMode: false,
			priceException: {},
		};
	},
	computed: {
		priceMapId() {
			return this.$route.params.id;
		},
		showPriceMapCard() {
			return !_.isEmpty(this.priceMap);
		},
	},

	watch: {
		priceMapId: {
			async handler(val) {
				if (val) {
					this.priceMap = await this.$service.priceMap.getOne(val);
				} else {
					this.priceMap = { priceExceptions: [] };
				}
			},
			immediate: true,
		},
	},

	methods: {
		readSelectedException(selectedException) {
			this.priceException = _.cloneDeep(selectedException);
			this.priceMapEditingMode = true;
		},
		newPriceMapException() {
			this.priceException = {};
			this.priceMapEditingMode = true;
		},
		async addPriceException(newPriceException) {
			this.priceMap.priceExceptions.push(newPriceException);
			this.priceMapEditingMode = false;
			this.priceMap = await this.$service.priceMap.update(this.priceMap);
		},
		async changePriceException(changedPriceException) {
			const idx = this.priceMap.priceExceptions.findIndex((x) => x.priceExceptionId === changedPriceException.priceExceptionId);
			this.priceMap.priceExceptions[idx] = changedPriceException;
			this.priceMapEditingMode = false;
			this.priceMap = await this.$service.priceMap.update(this.priceMap);
		},
		async deletePriceException(deletePriceExceptionId) {
			const idx = this.priceMap.priceExceptions.findIndex((x) => x.priceExceptionId === deletePriceExceptionId);
			this.priceMapEditingMode = false;
			if (idx === -1) return;
			this.priceMap.priceExceptions.splice(idx, 1);
			this.priceMap = await this.$service.priceMap.update(this.priceMap);
		},
	},
};
</script>
