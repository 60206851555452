<template>
	<div class="row">
		<div class="col-md-6">
			<card v-loading="loading">
				<h2>
					{{ $t('eshop.orderTransport') }} <span v-if="shoptet">{{ $t('eshop.fromEshopOrder') }}</span>
				</h2>
				<hr>
				<el-alert v-if="showContainerUnavailable" :title="containerUnavailable" type="warning" />
				<div v-if="showContainerUnavailable && !shoptet.autoCreateReservation" class="my-3">
					<router-link :to="{ name: 'eshop-order-detail', params: { id: shoptet.eshopOrderId } }">
						&gt;&gt;&gt; {{ $t('eshop.backToOrder') }}
					</router-link>
				</div>
				<div v-show="showFirstStep">
					<div>
						<new-order-group-headline :headline="$t('eshop.deliveryToAdress')" />
						<yes-no-all
							v-model="deliveryToAddress"
							:enable-doesnt-matter-button="false" />
					</div>
					<new-order-group-headline :headline="$t('eshop.contactInformation')" :text-description="$t('eshop.contactInformationDescription')" />
					<form-group-input :error="getError(newReservationValidation.email.field)" :label="$t('global.email-req-sb')">
						<el-input
							v-model="newReservation.mail"
							v-validate="newReservationValidation.email.rule"
							:name="newReservationValidation.email.field"
							data-cy="email"
							:data-vv-as="$t('eshop.email')"
							@blur="emailUpdated">
							<span slot="append" class="fa fa-at" />
						</el-input>
					</form-group-input>
					<form-group-input :error="getError(newReservationValidation.phone.field)" :label="$t('eshop.phone')">
						<el-input
							v-model="newReservation.phone"
							v-validate="newReservationValidation.phone.rule"
							:name="newReservationValidation.phone.field"
							data-cy="phone"
							:data-vv-as="$t('eshop.phone')"
							@blur="formatPhone">
							<span slot="append" class="fa fa-phone" />
						</el-input>
					</form-group-input>
					<div v-if="stepOfInsertionOrderData === 1" class="float-right mt-2">
						<p-button data-cy="nextButton" :disabled="disableContinueToSecondStep" @click="continueToNextStep">
							{{ $t('eshop.next') }}
						</p-button>
					</div>
				</div>
				<div v-show="showSecondStep">
					<new-order-group-headline :headline="deliveryToAddress ? $t('eshop.deliveryPointInformation') : $t('eshop.selectingDeliveryPoint')" />
					<span v-if="!deliveryToAddress">
						<form-group-input
							v-loading="loading"
							:disabled="disableContainerSelect"
							:label="$t('eshop.deliveryPoints')"
							:required="!deliveryToAddress">
							<el-select
								ref="containerSelect"
								v-model="selectedContainerId"
								data-cy="container"
								:disabled="disableContainerSelect"
								:placeholder="$t('eshop.containerSearchHint')"
								class="select-default container-select"
								remote
								:remote-method="filterFreeContainers"
								filterable
								@visible-change="onVisibleChange">
								<template slot="prefix">
									<span v-if="container" class="container-icon-in-select-box">
										<img v-if="container.containerType === containerTypes.store" src="/static/img/icons/genesis.svg" alt="Genesis">
										<img v-if="container.containerType === containerTypes.penguinBox" src="/static/img/icons/pb.svg" alt="PB">
										<span v-if="container.containerType === containerTypes.privateAddress" class="text-dark"><i class="fa fa-home" aria-hidden="false" /></span>
										<span v-if="container.containerType === containerTypes.smartBox" class="text-dark">
											<i class="nc-icon nc-mobile" aria-hidden="false" />
										</span>
									</span>
								</template>
								<el-option
									v-for="opt in freeContainersFiltered"
									:key="opt.containerId"
									data-cy="select-container"
									:label="formatContainerName(opt)"
									:value="opt.containerId">
									<img v-if="opt.containerType === containerTypes.store" src="/static/img/icons/genesis.svg" alt="Genesis">
									<img v-if="opt.containerType === containerTypes.penguinBox" src="/static/img/icons/pb.svg" alt="PB">
									<i v-if="opt.containerType === containerTypes.privateAddress" class="fa fa-home text-dark" />
									<i v-if="opt.containerType === containerTypes.smartBox" class="nc-icon nc-mobile text-dark" />
									{{ formatContainerName(opt) }}
								</el-option>
							</el-select>
						</form-group-input>
						<form-group-input
							v-loading="loading"
							:label="$t('eshop.boxType')"
							:required="!deliveryToAddress">
							<div>
								<el-radio-group
									v-model="newReservation.boxType"
									size="medium"
									:disabled="disableAllBoxButtons"
									@change="boxTypeChanged">
									<el-radio-button
										v-for="box in BoxType.enumValues"
										:key="box.ordinal"
										data-cy="select-box"
										:label="box"
										:disabled="disableBoxButton(box)">
										<el-tooltip
											v-if="disableBoxButton(box)"
											class="boxTypeButton"
											:content="disableBoxAlert(box)"
											placement="top-start">
											<span>{{ box.czech() }}</span>
										</el-tooltip>
										<span v-else class="boxTypeButton">
											{{ box.czech() }}
										</span>
									</el-radio-button>
								</el-radio-group>
								<div v-if="newReservation.boxType">
									<i class="fa fa-info-circle" aria-hidden="true" />
									<span class="font-weight-bold pl-1">Max: </span>
									{{ $t('eshop.width') }}&nbsp;{{ selectedBoxSizes.width }}&nbsp;cm
									&#x25cf;&nbsp;{{ $t('eshop.height') }}&nbsp;{{ selectedBoxSizes.height }}&nbsp;cm
									&#x25cf;&nbsp;{{ $t('eshop.depth') }}&nbsp;{{ selectedBoxSizes.depth }}&nbsp;cm
								</div>
							</div>
						</form-group-input>
						<p-button :disabled="!canSave" class="my-2" @click="addressValidate">
							{{ $t('eshop.orderTransport') }}
						</p-button>
					</span>
					<form-group-input :label="$t('eshop.orderNumber')">
						<el-input
							v-model="newReservation.reservationNumber"
							:placeholder="disableReservationNumberPlaceholder"
							:disabled="disableReservationNumberInput"
							name="reservationNumber" />
					</form-group-input>
					<form-group-input :label="$t('eshop.cashOnDelivery')">
						<el-input
							v-model.number="newReservation.requiredPayment"
							:disabled="disableRequiredPayment"
							:placeholder="disableRequiredPaymentPlaceholder"
							name="requiredPayment" />
					</form-group-input>
					<form-group-input
						v-if="!deliveryToAddress"
						:error="getError(newReservationValidation.reservedHours.field)"
						:label="$t('eshop.reserve')"
						required>
						<el-tooltip v-if="!newReservation.containerId" :content="$t('eshop.chooseContainerToEdit')" placement="top-start">
							<span>
								<i class=" pl-2 fa fa-2x fa-question-circle questionIcon" />
							</span>
						</el-tooltip>
						<el-input
							v-model.number="reservedHours"
							v-validate="newReservationValidation.reservedHours.rule"
							:disabled="!newReservation.containerId && !deliveryToAddress"
							:name="newReservationValidation.reservedHours.field"
							type="number"
							:data-vv-as="$t('eshop.reserve')"
							@change="reservedHoursWeekendCheck">
							<span slot="append">
								{{ $t(translateWithNumber('eshop', 'hour', reservedHours)) }}
							</span>
						</el-input>
					</form-group-input>
					<p v-if="expiredOnWeekend" class="expired-on-weekend">
						{{ $t('eshop.expiredOnWeekend') }} <a href="javascript:;" @click="expiredOnWeekendReset">{{ $t('eshop.cancel') }}</a>
					</p>
					<form-group-input v-if="!deliveryToAddress">
						<p-checkbox v-model="newReservation.reqReturn">
							{{ $t('eshop.customerReturnsGoods') }}
						</p-checkbox>
					</form-group-input>
					<form-group-input v-if="!deliveryToAddress && newReservation.reqReturn" :label="$t('eshop.lengthOfReturnReservation')">
						<el-input
							v-model="newReservation.reqReturnHours"
							:data-vv-as="$t('eshop.lengthOfReturnReservation')"
							type="number">
							<span slot="append">
								{{ $t(translateWithNumber('eshop', 'hour', newReservation.reqReturnHours)) }}
							</span>
						</el-input>
					</form-group-input>
					<new-order-group-headline
						:headline="$t('eshop.additionalInformations')"
						:text-description="deliveryToAddress ? $t('eshop.fillAll') : $t('eshop.fillAllFieldsOrNothing')" />
					<form-group-input :label="$t('eshop.fullName')" :required="deliveryToAddress" all-or-nothing-required>
						<el-input v-model="newReservation.customer.fullName" name="fullName" data-cy="fullName">
							<span slot="append" class="fa fa-address-card" />
						</el-input>
					</form-group-input>
					<form-group-input :label="$t('eshop.city')" :required="deliveryToAddress" all-or-nothing-required>
						<el-input v-model="newReservation.customer.city" data-cy="city" name="city">
							<span slot="append" class="fa fa-address-card" />
						</el-input>
					</form-group-input>
					<form-group-input :label="$t('eshop.streetAndNumber')" :required="deliveryToAddress" all-or-nothing-required>
						<el-input
							v-model="newReservation.customer.street"
							data-cy="streetAndNumber"
							name="street"
							:class="{'house-number-input-warning': !addressContainsNumber}">
							<span slot="append" class="fa fa-address-card" />
						</el-input>
					</form-group-input>
					<div v-if="!addressContainsNumber" class="house-number-warning">
						{{ $t('eshop.streetNumberNotFilledOut') }}
					</div>
					<form-group-input
						:error="getError(newReservationValidation.zip.field)"
						:label="$t('eshop.zip')"
						:required="deliveryToAddress"
						all-or-nothing-required>
						<el-input
							v-model="newReservation.customer.zip"
							v-validate="newReservationValidation.zip.rule"
							:data-vv-as="$t('eshop.zip')"
							:name="newReservationValidation.zip.field"
							data-cy="zip">
							<span slot="append" class="fa fa-address-card" />
						</el-input>
					</form-group-input>
					<div class="d-flex justify-content-between">
						<p-button @click="backToFirstStep">
							{{ $t('eshop.back') }}
						</p-button>
						<p-button :disabled="!canSave || sendingNewReservationRequest" data-cy="createButton" @click="addressValidate">
							{{ $t('eshop.orderTransport') }}
						</p-button>
					</div>
				</div>
			</card>
			<same-customer-orders
				v-if="shoptet"
				v-loading="loading"
				:order-container="shoptet.container ? shoptet.container.containerId : null"
				:order-email="shoptet.email"
				:order-id="shoptet.eshopOrderId"
				class="col-md-6"
				@orders="handleOrders" />
		</div>
		<div class="col-md-6">
			<container-boxes-card
				v-if="container && container.boxes"
				v-loading="loading"
				:container="container"
				@go-to-reservation-detail="goToReservationDetail"
				@get-container="getOne" />
		</div>

		<base-delivery-point-confirm-dialog :show="confirmDialogShow" @close="closeConfirmDialog" />
	</div>
</template>

<script>
import moment from 'moment';
import {
	RadioGroup as ElRadioGroup,
	RadioButton as ElRadioButton,
	Input as ElInput,
	Option as ElOption,
	Select as ElSelect,
	Alert as ElAlert,
	Tooltip as ElTooltip,
} from 'element-ui';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/max';
import { cloneDeep } from 'lodash';

import notifyService from '@/service/notify-service';
import { BoxType } from '@/modules/eshop/store/enums';
import { containerTypes } from '@/globalConstants';
import Validation from '@/util/validations';
import ValidationForm from '@/util/validated-form-mixin';
import FormMixin from '@/util/form-mixin';
import ContainerSelectMixin from '@/util/container-select-mixin';
import { parseIncompletePhoneNumberWithNull, translateWithNumber } from '@/util/helpers';
import { appName } from '@/modules/eshop/config';
import { formChanged } from '@/store/mutation-types';
import { BaseDeliveryPointConfirmDialog } from '@/components/Base';

import NewOrderGroupHeadline from './NewOrder/NewOrderGroupHeadline.vue';
import SameCustomerOrders from '../EshopOrder/SameCustomerOrders.vue';
import ContainerBoxesCard from './ContainerBoxesCard.vue';

export default {
	name: 'NewOrder',
	components: {
		NewOrderGroupHeadline,
		SameCustomerOrders,
		ElSelect,
		ElOption,
		ElInput,
		ElAlert,
		ElTooltip,
		ContainerBoxesCard,
		BaseDeliveryPointConfirmDialog,
		ElRadioGroup,
		ElRadioButton,
	},

	mixins: [ValidationForm, FormMixin, ContainerSelectMixin],

	data() {
		return {
			selectedBoxSizes: null,
			stepOfInsertionOrderData: null,
			emailUsedToLoadContainers: null,
			translateWithNumber,
			loading: false,
			containerUnavailable: null,
			deliveryToAddress: false,
			BoxType,
			freeContainers: [],
			availableBoxTypes: null,
			newReservation: {
				requiredPayment: null,
				boxType: null,
				containerId: null,
				eshopOrderIds: null,
				reservationNumber: '',
				mail: null,
				phone: null,
				customerAddress: null,
				customer: {
					email: null,
					phone: null,
					fullName: null,
					city: null,
					street: null,
					zip: null,
				},
				reqReturn: false,
				reqReturnHours: 48,
			},
			reservedHours: 48,
			previousHours: 48,
			previousReqReturn: 48,
			expiredOnWeekend: false,
			selectedContainerId: null,
			newReservationValidation: {
				email: {
					rule: Validation.email,
					field: 'email',
				},
				phone: {
					rule: Validation.phone,
					field: 'phone',
				},
				reservedHours: {
					rule: Validation.reservedHours,
					field: 'reservedHours',
				},
				zip: {
					rule: Validation.zip,
					field: 'zip',
				},
				customerCity: {
					name: 'customerCity',
					rules: {
						...Validation.notRequiredTextNumSpaceHyphenField,
					},
				},
			},
			container: null,
			confirmDialogShow: false,
			defaultHours: 48,
			sendingNewReservationRequest: false,
			containerTypes,
		};
	},

	computed: {
		reservationChecksNonWorkingDays() {
			return this.currentUser.ReservationChecksNonWorkingDays === 'True';
		},
		disableAllBoxButtons() {
			return !this.newReservation.containerId;
		},
		disableContinueToSecondStep() {
			return !(this.newReservation.mail || this.newReservation.phone) || !this.isValid;
		},
		disableRequiredPayment() {
			return ((!this.newReservation.containerId || this.CashOnDeliveryDisabled) && !this.deliveryToAddress)
          || !!this.shoptet
          || (this.container?.containerType === containerTypes.penguinBox);
		},
		disableRequiredPaymentPlaceholder() {
			if (this.container?.containerType === containerTypes.penguinBox) {
				return this.$t('eshop.requiredPaymentDisabledForPenguinBox');
			}
			if (!this.newReservation.containerId && !this.deliveryToAddress) {
				return this.$t('eshop.firstChooseDeliveryPoint');
			}
			if (this.shoptet) {
				return this.$t('eshop.disabledBecauseOfShoptet');
			}
			if (this.CashOnDeliveryDisabled && !this.deliveryToAddress) {
				return this.$t('eshop.noPaymentTerminal');
			}
			return '';
		},
		disableReservedHoursPlaceholder() {
			if (!this.newReservation.containerId && !this.deliveryToAddress) {
				return this.$t('eshop.firstChooseDeliveryPoint');
			}
			return '';
		},
		disableContainerSelect() {
			return this.deliveryToAddress || !(this.newReservation.mail || this.newReservation.phone);
		},
		disableReservationNumberInput() {
			return (!this.newReservation.containerId && !this.deliveryToAddress) || !!this.shoptet;
		},
		disableReservationNumberPlaceholder() {
			if (this.disableReservationNumberInput) {
				return (!this.newReservation.containerId) ? this.$t('eshop.firstChooseDeliveryPoint') : this.$t('eshop.disabledBecauseOfShoptet');
			}
			return '';
		},
		showFirstStep() {
			return !this.stepOfInsertionOrderData || this.stepOfInsertionOrderData === 1;
		},
		showSecondStep() {
			return !this.stepOfInsertionOrderData || this.stepOfInsertionOrderData === 2;
		},
		canSave() {
			const { boxType, containerId, mail, phone } = this.newReservation;

			return (
				(boxType || this.deliveryToAddress)
				&& (containerId || this.deliveryToAddress)
				&& (mail || phone)
				&& this.isValid
				&& (this.isAllCustomerFullFilled || (!this.deliveryToAddress && this.isAllCustomerEmpty))
			);
		},
		isAllCustomerFullFilled() {
			const { fullName, city, street, zip } = this.newReservation.customer;

			return fullName && city && street && zip;
		},
		isAllCustomerEmpty() {
			const { fullName, city, street, zip } = this.newReservation.customer;

			return !fullName && !city && !street && !zip;
		},
		shoptet() {
			return this.$route.params.shoptet;
		},
		showContainerUnavailable() {
			return !!this.containerUnavailable && !this.newReservation.containerId && !this.deliveryToAddress;
		},
		containerUnavailableLocation() {
			return this.shoptet.container ? this.formatContainerName(this.shoptet.container) : this.shoptet.shoptetContainerIdentifier;
		},
		CashOnDeliveryDisabled() {
			const containers = this.freeContainers && this.freeContainers.filter((x) => x.containerId === this.newReservation.containerId);
			if (containers.length) {
				return !containers[0].hasPaymentTerminal;
			}
			return true;
		},
		addressContainsNumber() {
			const address = this.newReservation.customer.street;
			if (!address) {
				return true;
			}
			return /\d+(\/)?(\d+)?[a-zA-Z]?/.test(address);
		},
	},

	watch: {
		deliveryToAddress() {
			this.newReservation.boxType = null;
			this.newReservation.containerId = null;
		},
		newReservation: {
			handler() {
				this.$store.commit(`${appName}/${formChanged}`, true);
				this.selectedBoxSizes = this.newReservation?.boxType?.proportions();
			},
			deep: true,
		},
		selectedContainerId() {
			this.newReservation.containerId = this.selectedContainerId;
			this.$store.commit(`${appName}/${formChanged}`, true);

			const newContainerId = this.newReservation.containerId;
			const container = this.freeContainers.find((x) => x.containerId === newContainerId);
			const storeTime = 168;
			const boxTime = 48;
			this.availableBoxTypes = container && container.availableBoxTypes;
			if (!this.reservedHours || this.reservedHours === boxTime || this.reservedHours === storeTime) {
				this.reservedHours = container && container.containerType === containerTypes.store ? storeTime : boxTime;
			}
			this.cleanBoxType();
		},
	},

	async created() {
		if (this.$router.currentRoute.path.includes('shoptet') && !this.shoptet) {
			notifyService.notifyError(this.$t('eshop.shoptetError'));
			this.$router.push({ name: 'eshop-order-list' });
		}
		if (!this.shoptet) {
			this.stepOfInsertionOrderData = 1;
		} else {
			this.loading = true;

			const { container, email, phone, name, city, street, zip, code, eshopOrderId, requiredPayment, reqReturn, reqReturnMinutes } = this.shoptet;

			await this.getFreeContainers(email);

			if (!container?.containerId) {
				this.deliveryToAddress = true;
			}
			// prestoze se ve watcheru k selectedContainerId upravi i newReservation.containerId, je na nasledujicich radcich spravne
			// ze se upravuje jak selectedContainerId, tak newReservation.containerId. Je to kvuli synchronizaci.
			// Watcher totiz reaguje se spozdenim a hodnota newReservation.containerId se o nekolik radku nize pouziva ve volanych funkcich.
			const foundContainer = this.freeContainers.find((searchingContainer) => searchingContainer.containerId === container?.containerId);
			if (foundContainer) {
				this.lastQuery = `${foundContainer.serialNumber}`;
				this.$nextTick(() => {
					this.containerSelectOnChange();
					setTimeout(() => {
						this.lastQuery = '';
					}, 1000);
				});
				this.selectedContainerId = container?.containerId || null;
				this.newReservation.containerId = container?.containerId || null;
			} else {
				this.selectedContainerId = null;
				this.newReservation.containerId = null;
			}

			this.newReservation.mail = email;
			this.newReservation.phone = phone;
			this.newReservation.customer.fullName = name;
			this.newReservation.customer.city = city;
			this.newReservation.customer.street = street;
			this.newReservation.customer.zip = zip;
			this.newReservation.reservationNumber = code;
			this.newReservation.eshopOrderIds = [eshopOrderId];
			this.newReservation.requiredPayment = requiredPayment;
			this.newReservation.reqReturn = reqReturn;
			this.newReservation.reqReturnHours = reqReturnMinutes > 0 ? +parseFloat(reqReturnMinutes / 60).toFixed(2) : this.defaultHours;
			this.newReservation.customerAddress = this.getCustomerAddressFromShoptet(this.shoptet); // preserved for backward compatibility

			const autoCreateReservation = this.shoptet.autoCreateReservation ? `. ${this.$t('eshop.autoOrderActivated')}` : this.$t('eshop.autoOrderGoBack');
			this.containerUnavailable = `${this.$t('eshop.container')} "${this.containerUnavailableLocation}"
			${this.$t('eshop.containerIsFull')}${autoCreateReservation}`;

			if (!this.deliveryToAddress) this.cleanBoxType();
			this.loading = false;
		}
		this.reservedHoursWeekendCheck();
	},
	methods: {
		reservedHoursWeekendCheck() {
			if (!this.reservationChecksNonWorkingDays) {
				return;
			}
			const date = moment().add(this.reservedHours, 'hours');
			const day = date.day();
			if (day === 6 || day === 7) {
				const nextMonday = moment().startOf('isoWeek').add(1, 'week').set('hour', 12);
				const hoursUntilMonday = nextMonday.diff(moment(), 'hours');
				this.previousHours = this.reservedHours;
				this.previousReqReturn = this.newReservation.reqReturnHours;
				this.reservedHours = hoursUntilMonday;
				this.newReservation.reqReturnHours = hoursUntilMonday;
				this.expiredOnWeekend = true;
			}
		},
		expiredOnWeekendReset() {
			this.reservedHours = this.previousHours;
			this.newReservation.reqReturnHours = this.previousReqReturn;
			this.expiredOnWeekend = false;
		},
		disableBoxAlert(box) {
			if (!this.newReservation.containerId) return this.$t('eshop.firstChooseDeliveryPoint');
			if (this.disableBoxButton(box)) return this.$t('eshop.boxNotAvailable');
			return '';
		},
		boxTypeChanged(box) {
			this.newReservation.boxType = box;
		},
		disableBoxButton(box) {
			return !this.availableBoxTypes?.includes(box.name);
		},
		async continueToNextStep() {
			if ((this.newReservation.mail || this.newReservation.phone) && this.fieldsbag.email.valid && this.fieldsbag.phone.valid) {
				if (!this.deliveryToAddress && (!this.freeContainers.length || this.emailUsedToLoadContainers !== this.newReservation.mail)) {
					await this.reloadContainers();
				}
				this.stepOfInsertionOrderData = 2;
				this.$nextTick(() => {
					this.setContainerSelectEventListener();
				});
			}
		},
		async backToFirstStep() {
			this.stepOfInsertionOrderData = 1;
		},
		cleanBoxType() {
			this.container = this.freeContainers.find((x) => x.containerId === this.newReservation.containerId);
			if (this.availableBoxTypes?.length === 1) {
				this.newReservation.boxType = this.BoxType[this.availableBoxTypes.toString()];
			} else {
				this.newReservation.boxType = null;
			}

			if (this.container?.containerType === containerTypes.penguinBox
      || this.container?.containerType === containerTypes.smartBox) {
				this.getOne();
			}

			if (this.container?.containerType === containerTypes.store) {
				this.reservedHours = 168;
			}
		},
		getCustomerAddressFromShoptet(shoptet) {
			return `${shoptet.name}, ${shoptet.street}, ${shoptet.city} ${shoptet.zip}`;
		},
		async getFreeContainers(email) {
			this.freeContainers = await this.$service.container.getFreeContainersWithExpired(email);
			if (!email) {
				this.freeContainers = this.freeContainers.filter((container) => container.containerType !== containerTypes.smartBox);
			}
			this.enrichFreeContainers(this.freeContainers);
		},

		formatContainerName(container) {
			return this.$options.filters.location(container);
		},

		formatPhone() {
			// If parse is not called, format will return empty string on non-number input
			this.newReservation.phone = formatIncompletePhoneNumber(parseIncompletePhoneNumberWithNull(this.newReservation.phone), 'CZ');
		},
		async emailUpdated() {
			if (this.emailUsedToLoadContainers !== this.newReservation.mail && this.fieldsbag.email.valid && !this.stepOfInsertionOrderData) {
				await this.reloadContainers();
				if (!this.stepOfInsertionOrderData) {
					notifyService.notifySuccess(this.$t('eshop.containersLoadedAgain'));
				}
			}
		},
		async reloadContainers() {
			this.loading = true;
			await this.getFreeContainers(this.newReservation.mail);
			if (this.container) {
				this.cleanBoxType();
			}
			this.newReservation.containerId = null;
			this.newReservation.boxType = null;
			this.emailUsedToLoadContainers = this.newReservation.mail;
			this.loading = false;
		},
		handleOrders(orderIds) {
			this.newReservation.eshopOrderIds = orderIds.map((v) => v.eshopOrderId);
			this.newReservation.reservationNumber = orderIds.map((v) => v.code).join();
		},
		async createNewReservation() {
			if (this.canSave) {
				try {
					this.loading = true;
					const reservationToSend = this.replaceEmptyValuesByNull(cloneDeep(this.newReservation));
					if (!this.isAllCustomerFullFilled) {
						reservationToSend.customer = null;
					} else {
						reservationToSend.customer.phone = reservationToSend.phone;
						reservationToSend.customer.email = reservationToSend.mail;
					}
					if (this.newReservation.boxType) reservationToSend.boxType = this.newReservation.boxType.name;
					const oneHourInMinutes = 60;
					reservationToSend.minutes = this.reservedHours * oneHourInMinutes;
					reservationToSend.reqReturnMinutes = this.newReservation.reqReturnHours * oneHourInMinutes;
					const response = await this.$service.eshop.newReservation(reservationToSend);
					if (response.reservationId) {
						notifyService.notifySuccess(this.$t('eshop.reservationCreateSuccess'));
						this.$store.commit(`${appName}/${formChanged}`, false);
						this.$router.push({ name: 'reservation-detail', params: { id: response.reservationId } });
					} else {
						notifyService.notifyError(this.$t('eshop.reservationCreateError'));
					}
				} catch (e) {
					console.warn(e);
					notifyService.notifyError(this.$t('eshop.reservationCreateError'));
					if (e.data.errors) {
						Object.keys(e.data.errors).forEach((errorObject) => {
							if (e.data.errors[errorObject][0]) {
								notifyService.notifyError(e.data.errors[errorObject][0]);
							}
						});
					}
				} finally {
					this.loading = false;
				}
			} else {
				notifyService.notifyError(this.$t('eshop.missingValues'));
			}
		},
		goToReservationDetail(reservationId) {
			reservationId && this.$router.push({ name: 'reservation-detail', params: { id: reservationId } });
		},
		replaceEmptyValuesByNull(reservationToSend) {
			const fieldsToCheck = ['boxType', 'requiredPayment', 'containerId', 'eshopOrderIds', 'reservationNumber', 'mail', 'phone', 'customerAddress'];
			fieldsToCheck.forEach((fieldName) => {
				if (!reservationToSend[fieldName]) reservationToSend[fieldName] = null;
			});

			if (reservationToSend.customer) {
				const customerFieldsToCheck = ['email', 'phone', 'fullName', 'city', 'street', 'zip'];
				customerFieldsToCheck.forEach((fieldName) => {
					if (!reservationToSend.customer[fieldName]) reservationToSend.customer[fieldName] = null;
				});
			}
			return reservationToSend;
		},
		async getOne() {
			this.loading = true;
			const selectedContainerType = this.container.containerType;
			this.container = await this.$service.container.getOne(this.newReservation.containerId);
			this.container.containerType = selectedContainerType;
			this.loading = false;
		},
		async addressValidate() {
			this.sendingNewReservationRequest = true;
			const { valid } = await this.$validator.verify(this.newReservationValidation.customerCity, { validateAddress: this.newReservation.customer });

			if (!valid) {
				this.confirmDialogShow = true;
			} else {
				await this.createNewReservation();
			}
			this.sendingNewReservationRequest = false;
		},
		async closeConfirmDialog(returnValue) {
			this.confirmDialogShow = false;

			if (returnValue) {
				await this.createNewReservation();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.note {
	font-size: 80%;
}
.container-icon-in-select-box {
	padding-left: 7px;
	padding-top: 10px;
	padding-bottom: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.boxTypeButton {
display: inline-block;
  width: 70px;
}
.questionIcon {
  font-size: 0.7em;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner::placeholder {
	color: #636c72;
}

.house-number-warning {
	color: #ffb100;
	margin-top: -10px;
	font-weight: bold;
}

.house-number-input-warning {
	border: 1px solid #ffb100;
}

.expired-on-weekend {
	color: #e59400;
}
</style>
