export default {
	data() {
		return {
			smartBoxes: [],
		};
	},

	methods: {
		getLockAliasName(serialNumber) {
			return (this.smartBoxes || []).find((smartBox) => smartBox.id === serialNumber)?.value;
		},
		async loadLockAliasOrSerialNumberForBox(smartBoxService, containerType, serialNumber) {
			if (containerType === 'SmartKeyBox') {
				if (!this.smartBoxes.find((x) => x.serialNumber === serialNumber)) {
					try {
						const data = await smartBoxService.getDetail(serialNumber);

						this.smartBoxes.push({
							value: data.lockAlias,
							id: data.lockId,
							serialNumber,
						});
					} catch (e) {
						this.lockAliasOrSerialNumber = '-';
					}
				}
				this.lockAliasOrSerialNumber = this.getLockAliasName(this.reservation.serialNumber) || '-';
			} else if (!containerType) {
				this.lockAliasOrSerialNumber = '';
			} else {
				this.lockAliasOrSerialNumber = this.reservation.serialNumber ? this.reservation.serialNumber.toString() : '-';
			}
		},
		async loadLockAliasOrSerialNumber(smartBoxService, containerType) {
			if (!this.smartBoxes.length) {
				const data = await smartBoxService.getList({}, 1, 1000);

				(data?.list || []).forEach((smartBox) => {
					this.smartBoxes.push({
						value: smartBox.lockAlias,
						id: smartBox.lockId,
					});
				});
			}

			if (containerType === 'SmartKeyBox') {
				this.lockAliasOrSerialNumber = this.getLockAliasName(this.reservation.serialNumber) || '-';
			} else {
				this.lockAliasOrSerialNumber = this.reservation.serialNumber;
			}
		},
	},
};
