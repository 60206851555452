<template>
	<card v-loading="loading">
		<div v-if="reportData" id="printRoute">
			<slot name="headerActions" />
			<h2>{{ $t('driver.routeReportHeading') }} - {{ reportData.name }}</h2>
			<div v-if="totalReservationsToBeLoaded && remainingReservationsToBeLoaded" class="text-center">
				<span class="text-big text-success">{{ $t('driver.loadingPackagesIntoTheCar') }}:
					<strong>{{ remainingReservationsToBeLoaded }}/{{ totalReservationsToBeLoaded }}</strong>
				</span>
			</div>

			<base-protocol-info :report-data="reportData" />

			<base-summary-info
				:stock-items-count="stockItemsCount"
				:total-for-collection="totalForCollection"
				:total-for-delivery="totalForDelivery"
				:total-stock-items="totalStockItems" />

			<slot name="containersInfo" />
		</div>

		<slot name="actions" />

		<div class="text-center mt-n5 pt-3 pb-2">
			<el-tooltip :content="$t('driver.tooltips.succesfullBoxes')">
				<span>{{ $t('driver.succesfullBoxes') }} {{ requestedCount }}/{{ completedCount }}/{{ successRate }}%</span>
			</el-tooltip>
		</div>
	</card>
</template>

<script>
import { Tooltip as ElTooltip } from 'element-ui';

import { ReservationStatus } from '@/modules/driver/store/enums';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import { appNameList } from '@/globalConstants';

import BaseProtocolInfo from './BaseProtocolInfo.vue';
import BaseSummaryInfo from './BaseSummaryInfo.vue';

export default {
	name: 'BaseReportPrintSheet',
	components: {
		BaseProtocolInfo,
		BaseSummaryInfo,
		ElTooltip,
	},
	mixins: [ReservationNameMixin],
	props: {
		reportData: {
			type: Object,
			default: () => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		remainingReservationsToBeLoaded: {
			type: Number,
			default: null,
		},
		totalReservationsToBeLoaded: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			directDeliveryTotalCount: 0,
			totalForDelivery: 0,
			totalForCollection: 0,
			stockItemsCount: {},
		};
	},
	computed: {
		totalStockItems() {
			return Object.values(this.stockItemsCount).reduce((total, c) => total + c.amount, 0);
		},
		completedCount() {
			let completedCount = 0;

			(this.reportData?.containers || [])
				.map((container) => container.reservations)
				.reduce((resp, r) => resp.concat(r), [])
				.forEach((reservation) => {
					if (!reservation) {
						return;
					}

					const { calcToReward, status, currentStatus, packagesCount, stockItemSku, unlockCode } = reservation;

					if (!unlockCode || (calcToReward && status !== currentStatus && ReservationStatus.enumValueOf(currentStatus) === ReservationStatus.PREPARED)) {
						if (packagesCount) {
							completedCount += 1;
						}
						completedCount += (stockItemSku && this.stockItemsCount[stockItemSku] ? this.stockItemsCount[stockItemSku].amount : 0);
					}
				});

			return completedCount;
		},
		requestedCount() {
			return this.totalForDelivery + this.totalForCollection + this.directDeliveryTotalCount;
		},
		successRate() {
			return (this.requestedCount && Math.round((100 * this.completedCount) / this.requestedCount)) || 0;
		},
	},
	watch: {
		reportData: {
			deep: true,
			handler() {
				this.preprocessingReport();
			},
		},
	},
	methods: {
		preprocessingReport() {
			if (this.reportData) {
				this.stockItemsCount = {};
				this.totalForDelivery = 0;
				this.totalForCollection = 0;
				this.directDeliveryTotalCount = 0;
				this.reportData.containers.forEach(({ stockItemsCounts, containerSn, city, location, reservations }) => {
					// Count total amount of stock items
					if (stockItemsCounts && stockItemsCounts.length) {
						stockItemsCounts.forEach((stockItem) => {
							if (!this.stockItemsCount[stockItem.item.stockId]) {
								this.stockItemsCount[stockItem.item.stockId] = {
									amount: stockItem.count,
									name: this.currentAppName === appNameList.driver ? stockItem.item.name : `PB ${containerSn} ${city}`,
									containerSn,
									containerLocation: `${city} (${location})`,
								};
							} else {
								this.stockItemsCount[stockItem.item.stockId].amount += stockItem.count;
							}
							this.directDeliveryTotalCount += stockItem.count;
						});
					}

					// Count total amount of reservations for delivery / collection and divide reservations in category arrays
					if (reservations?.length) {
						reservations.forEach((reservation) => {
							const { WAIT_FOR_COLLECT, WAIT_FOR_DELIVERY, PICK_UP, DISCARD } = ReservationStatus;

							if (ReservationStatus.enumValueOf(reservation.status) === WAIT_FOR_DELIVERY || ReservationStatus.enumValueOf(reservation.status) === PICK_UP) {
								this.totalForDelivery += 1;
							} else if (
								ReservationStatus.enumValueOf(reservation.status) === WAIT_FOR_COLLECT
								|| this.isExpired(reservation)
								|| ReservationStatus.enumValueOf(reservation.status) === DISCARD
							) {
								this.totalForCollection += 1;
							}
						});
					}
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
td .action {
	display: none;
}
tr:hover .action {
	display: inline;
}

tbody {
	border-bottom: 3px solid black;
}

.headerOfContainerTable {
	border-bottom: 3px solid black;
}

.noBorder {
	td {
		font-size: 9px;
		padding: 1px;
	}
	* {
		border: none;
	}
}
.qr_code-area {
	position: fixed;
	bottom: 0;
	margin-left: -15px;
	margin-bottom: 15px;
	width: 500px;
	max-width: 100%;
}
.text-big {
	font-size: 115%;
}

.selected-box {
	animation: selected-box-animation 1s linear 1 1s;
	background: palegreen;
}

@keyframes selected-box-animation {
	from {
		background: red;
		color: white;
	}
	to {
		color: initial;
		background: palegreen;
	}
}

.mb-max {
	margin-bottom: 350px;
}

.table-responsive {
	overflow: auto;
}
</style>
