import Vue from 'vue';
import VueI18n from 'vue-i18n';

import czechGlobalTranslations from './czech-global-translations.json';
import czechAdminTranslations from './modules/admin/store/czech-admin-translations.json';
import czechDriverTranslations from './modules/driver/store/czech-driver-translations.json';
import czechEshopTranslations from './modules/eshop/store/czech-eshop-translations.json';
import czechLogisticsTranslations from './modules/logistics/store/czech-logistics-translations.json';
import czechPickupTranslations from './modules/pickup/store/czech-pickup-translations.json';

Vue.use(VueI18n);

const messages = {
	cz: {
		global: czechGlobalTranslations,
		admin: czechAdminTranslations,
		driver: czechDriverTranslations,
		eshop: czechEshopTranslations,
		logistics: czechLogisticsTranslations,
		pickup: czechPickupTranslations,

	},
};

export const i18n = new VueI18n({
	locale: 'cz',
	messages,
});
