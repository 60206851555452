<template>
	<div
		ref="sidebar"
		class="sidebar"
		data-active-color="success"
		data-color="blue"
		@blur="setHover($event, false)"
		@mouseleave="setHover($event, false)"
		@mouseenter="setHover($event, true)"
		@focus="setHover($event, true)">
		<div class="logo">
			<router-link class="simple-text logo-mini" aria-label="sidebar mini logo" :to="{ name: homePage }">
				<div class="logo-img">
					<img :alt="title" src="/static/img/logoPB.svg">
				</div>
			</router-link>
			<router-link class="simple-text logo-normal" :to="{ name: homePage }">
				{{ title }}
			</router-link>
		</div>

		<the-app-switcher v-show="!isMinimized || isHover" />

		<div ref="sidebarScrollArea" class="sidebar-wrapper">
			<the-user-menu />

			<ul class="nav">
				<slot name="links">
					<sidebar-item
						v-for="(link, index) in sidebarLinks.filter(x=>x.hide !== true)"
						:key="link.name + index"
						:link="link">
						<sidebar-item v-for="(subLink, i) in link.children" :key="subLink.name + i" :link="subLink" />
					</sidebar-item>
				</slot>
			</ul>
			<div v-show="!isMinimized || isHover" class="version-info">
				{{ $t('admin.version') }}: {{ versionInfo }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { displaySidebar } from '@/store/mutation-types';
import SidebarItem from '@/components/UIComponents/Sidebar/SidebarItem.vue';

import TheAppSwitcher from '../TheAppSwitcher/TheAppSwitcher.vue';
import TheUserMenu from './TheUserMenu.vue';

export default {
	name: 'SideBar',
	components: { TheAppSwitcher, TheUserMenu, SidebarItem },
	provide() {
		return {
			autoClose: this.autoClose,
		};
	},
	props: {
		title: {
			type: String,
			default: 'penguin',
			description: 'Sidebar title',
		},
		sidebarLinks: {
			type: Array,
			default: () => [],
			description: 'Sidebar links. Can be also provided as children components (sidebar-item)',
		},
		autoClose: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isHover: false,
		};
	},
	computed: {
		...mapState(['sidebar']),
		isMinimized() {
			return this.sidebar.isMinimized;
		},
		currentAppName() {
			return this.$getConst('appName');
		},
		versionInfo() {
			return this.$store.state[this.currentAppName]?.versionInfo;
		},
		homePage() {
			return this.$store.state[this.currentAppName]?.homePage;
		},
	},
	watch: {
		isMinimized() {
			document.body.classList.toggle('sidebar-mini');
			// we simulate the window Resize so the charts will get updated in realtime.
			const simulateWindowResize = setInterval(() => {
				window.dispatchEvent(new Event('resize'));
			}, 180);

			// we stop the simulation of Window Resize after the animations are completed
			setTimeout(() => {
				clearInterval(simulateWindowResize);
			}, 1000);
		},
	},
	beforeDestroy() {
		if (this.$store.state.sidebar.showSidebar) {
			this.$store.commit(displaySidebar, false);
		}
	},
	methods: {
		setHover(event, isHover) {
			if (event.target === this.$refs.sidebar) {
				this.isHover = isHover;
			}
		},
	},
};
</script>

<style lang="scss">
@media (min-width: 992px) {
	.navbar-search-form-mobile,
	.nav-mobile-menu {
		display: none;
	}
}

.version-info {
	position: relative;
	width: 100%;
	bottom: 5px;
	margin: 10px 15px 0;
	padding: 10px 8px;
	color: white;
	font-size: 10px;
}

@media screen and (max-width: 768px) {
	body:not(.el-popup-parent--hidden):not(.hide-transformations) .main-panel {
		transform: translate3d(0, 0, 0) !important;
	}

	.sidebar {
		right: -1000px !important;
		left: auto !important;
	}

	.nav-open {
		.main-panel {
			transform: translate3d(0, 0, 0) !important;

			.content {
				width: calc(100vw + 260px);
				padding-right: 280px;
			}
		}

		.sidebar {
			right: 0 !important;
			left: auto !important;
		}
	}
}
</style>
