<template>
	<div class="row" data-cy="homePage">
		<div class="col-md-12 card">
			<div class="d-flex">
				<div class="col-4">
					<div class="flex-column">
						<label>
							{{ $t('logistics.reservationState') }}
						</label>
						<el-select
							v-model="filterStatus"
							size="large"
							class="w-100"
							placeholder="Status rezervace"
							value-key="ordinal"
							clearable
							@change="loadData(1)">
							<el-option
								v-for="status in availableStatuses"
								:key="status.ordinal"
								:label="status.czech()"
								:value="status">
								<i :class="status.icon()" />
								{{ status.czech() }}
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="col">
					<form-group-input v-model="filter.ReservationNumberLike" :label="$t('logistics.orderNumber')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.SerialNumber" :label="$t('logistics.SerialNumber')" type="text" />
				</div>
				<div class="col">
					<form-group-input :label="$t('logistics.insertedDateFrom')">
						<date-picker
							v-model="filter.insertedDateFrom"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime"
							@change="loadData(1)" />
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input :label="$t('logistics.insertedDateTo')">
						<date-picker
							v-model="filter.insertedDateTo"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime"
							@change="loadData(1)" />
					</form-group-input>
				</div>
			</div>
			<div class="d-flex">
				<div class="col">
					<el-radio-group v-model="filter.type">
						<el-radio-button label="All">
							{{ $t('logistics.all') }}
						</el-radio-button>
						<el-radio-button label="Post">
							{{ $t('logistics.post') }}
						</el-radio-button>
						<el-radio-button label="DirectSale">
							{{ $t('logistics.directSale') }}
						</el-radio-button>
						<el-radio-button label="ExternalEshop">
							{{ $t('logistics.eshop') }}
						</el-radio-button>
					</el-radio-group>
				</div>
			</div>
			<div class="col-2 mb-1 d-flex justify-content-start">
				<p-button type="success" size="md" @click="loadData">
					{{ $t('logistics.search') }}
				</p-button>
				<el-tooltip :content="$t('logistics.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</div>

		<div class="col-md-12 card">
			<div v-loading="loading" class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-sm-12 mt-2">
						<el-table
							data-cy="ListMainTable"
							class="table-striped"
							:data="reservations"
							show-overflow-tooltip
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							@sort-change="sortChange">
							<el-table-column
								width="60"
								header-align="center"
								align="center"
								property="status">
								<template #header>
									<el-tooltip :content="$t('logistics.state')" placement="top-start">
										<span>{{ $t('logistics.state') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.status" :content="ReservationStatus.czech(scope.row.status)" placement="top-start">
										<div :key="scope.row.status">
											<i :class="ReservationStatus.icon(scope.row.status)" />
										</div>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column width="50">
								<template #header>
									<el-tooltip :content="$t('logistics.actions')" placement="top-start">
										<span>{{ $t('logistics.actions') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip
										v-if="ReservationStatus.enumValueOf(scope.row.status) === ReservationStatus.COLLECTED"
										:key="scope.row.fromReservationId"
										:content="$t('logistics.accepted')"
										placement="right-end">
										<p-button
											type="success"
											@click="confirmCollected(scope.row.fromReservationId)">
											<i class="fa fa-check-circle" />
										</p-button>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								width="78"
								property="postSource"
								header-align="center"
								align="center">
								<template #header>
									<el-tooltip :content="$t('logistics.postSource')" placement="top-start">
										<span> {{ $t('logistics.postSource') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div :key="scope.row.from" class="row">
										<span class="icon-width">
											<router-link
												:key="scope.row.fromReservationId"
												:to="{ name: 'reservation-detail', params: { id: scope.row.fromReservationId } }">
												<img
													v-if="scope.row.from === 'PenguinBox'"
													class="ml-2"
													src="/static/img/icons/pb.svg"
													alt="PB">
												<img v-else-if="scope.row.from === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
												<i v-else-if="scope.row.from === 'SmartKeyBox'" class="nc-icon nc-mobile gray" />
												<i v-else class="fa fa-home gray" />
											</router-link>
										</span>
										<i class="mt-1 fas fa-arrow-right arrow-width" />
										<span class="icon-width">
											<router-link
												v-if="scope.row.toReservationId"
												:key="scope.row.toReservationId"
												:to="{ name: 'reservation-detail', params: { id: scope.row.toReservationId } }">
												<img
													v-if="scope.row.to === 'PenguinBox'"
													class="ml-2"
													src="/static/img/icons/pb.svg"
													alt="PB">
												<img v-else-if="scope.row.to === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
												<i v-else-if="scope.row.to === 'SmartKeyBox'" class="nc-icon nc-mobile gray" />
												<i v-else class="fa fa-home gray" />
											</router-link>
											<span v-else>
												<img
													v-if="scope.row.to === 'PenguinBox'"
													class="ml-2"
													src="/static/img/icons/pb.svg"
													alt="PB">
												<img v-else-if="scope.row.to === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
												<i v-else-if="scope.row.to === 'SmartKeyBox'" class="nc-icon nc-mobile gray" />
												<i v-else class="fa fa-home gray" />
											</span>
										</span>
									</div>
								</template>
							</el-table-column>
							<el-table-column
								property="fromAddress"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.fromAddress')" placement="top-start">
										<span>{{ $t('logistics.fromAddress') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<span class="d-flex justify-content-start align-items-center text-left">
										<span v-if="scope.row.fromSerialNumber" class="badge badge-info p-2 m-1">{{ scope.row.fromSerialNumber }}</span>
										<opening-hours-tooltip
											v-if="scope.row.from === 'Store'"
											:opening-hours="scope.row.sourceOpeningHours"
											:serial-number="scope.row.fromSerialNumber" />
										{{ scope.row.fromAddress }}
									</span>
								</template>
							</el-table-column>
							<el-table-column
								property="toAddress"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.toAddress')" placement="top-start">
										<span>{{ $t('logistics.toAddress') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<span class="d-flex justify-content-start align-items-center text-left">
										<span v-if="scope.row.toSerialNumber" class="badge badge-info p-2 m-1">{{ scope.row.toSerialNumber }}</span>
										<opening-hours-tooltip
											v-if="scope.row.to === 'Store'"
											:opening-hours="scope.row.destinationOpeningHours"
											:serial-number="scope.row.toSerialNumber" />
										{{ scope.row.toAddress }}
									</span>
								</template>
							</el-table-column>
							<el-table-column
								width="150"
								property="reservationNumber"
								header-align="center"
								align="left"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.orderNumber')" placement="top-start">
										<span>{{ $t('logistics.orderNumber') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								width="150"
								property="inserted"
								header-align="center"
								align="center"
								sortable>
								<template #header>
									<el-tooltip :content="$t('logistics.insertedDate')" placement="top-start">
										<span>{{ $t('logistics.insertedDate') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.inserted | datetime }}
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 mt-2">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {
	Table as ElTable,
	TableColumn as ElTableColumn,
	Pagination as ElPagination,
	Tooltip as ElTooltip,
	Select as ElSelect,
	Option as ElOption,
	RadioGroup as ElRadioGroup,
	RadioButton as ElRadioButton,
	DatePicker,
} from 'element-ui';

import { ReservationStatus } from '@/modules/logistics/store/enums';
import { appName } from '@/modules/logistics/config';
import { resetFilter, setFilter } from '@/store/mutation-types';
import notifyService from '@/service/notify-service';

import OpeningHoursTooltip from './OpeningHoursTooltip.vue';

export default {
	name: 'Post',
	components: {
		OpeningHoursTooltip,
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElSelect,
		ElOption,
		ElRadioButton,
		ElRadioGroup,
		DatePicker,
	},
	data() {
		return {
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			ReservationStatus,
			loading: false,
			filter: {},
			filterStatus: null,
		};
	},
	computed: {
		...mapState(appName, ['postFilter']),
		reservations() {
			return this.page.entities;
		},
		availableStatuses() {
			return [ReservationStatus.WAIT_FOR_PACKAGE, ReservationStatus.WAIT_FOR_COLLECT, ReservationStatus.COLLECTED, ReservationStatus.WAIT_FOR_DELIVERY];
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'postFilter', data: val });
			},
		},
	},
	created() {
		this.filter = this.postFilter;
		this.loadData();
	},

	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},
		async loadData(page = 1) {
			this.loading = true;
			this.filter.Status = this.filterStatus ? this.filterStatus.camelCase() : null;
			this.page = await this.$service.list.getPostalReservation(page, this.filter, this.pageSize);
			this.loading = false;
		},
		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},
		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'postFilter');
			this.filter = this.postFilter;
			this.filterStatus = null;
			this.loadData();
		},
		async confirmCollected(reservationId) {
			try {
				await this.$service.reservation.ackCollected(reservationId);

				notifyService.notifySuccess(this.$t('admin.collectedAckSuccess'));
				this.loadData();
			} catch {
				notifyService.notifyError(this.$t('admin.reservationChangeStateFailure'));
			}
		},

	},
};
</script>

<style scoped>
.icon-width {
width: 35px;
}
.arrow-width {
  width: 10px;
}
</style>
