<template>
	<div class="row">
		<div class="col-md-6">
			<card v-if="formStep === formSteps.first">
				<h2>{{ $t('global.requestReturnOrder') }}</h2>
				<hr>
				<new-order-group-headline :headline="$t('eshop.contactInformation')" :text-description="$t('global.fillAtLeastOneField')" />
				<form-group-input
					:error="getError(newReservationValidation.email.field)"
					:label="$t('global.email-req-sb')"
					some-required
					no-asterisk>
					<el-input
						v-model="newReservation.mail"
						v-validate="newReservationValidation.email.rule"
						type="email"
						autocomplete="on"
						:name="newReservationValidation.email.field"
						:data-vv-as="$t('global.email')"
						data-cy="contactEmail">
						<span slot="append" class="fa fa-at" />
					</el-input>
				</form-group-input>

				<form-group-input
					:error="getError(newReservationValidation.phone.field)"
					:label="$t('global.phone')"
					some-required
					no-asterisk>
					<el-input
						v-model="newReservation.phone"
						v-validate="newReservationValidation.phone.rule"
						:name="newReservationValidation.phone.field"
						type="tel"
						autocomplete="on"
						:data-vv-as="$t('global.phone')"
						data-cy="contactPhone"
						@blur="formatPhone">
						<span slot="append" class="fa fa-phone" />
					</el-input>
				</form-group-input>

				<div>
					<p-button
						data-cy="nextButton"
						class="float-right mt-2"
						:disabled="disableContinueToSecondStep"
						@click="continueToNextStep">
						{{ $t('eshop.next') }}
					</p-button>
				</div>
			</card>
			<card v-if="formStep === formSteps.second" v-loading="loading">
				<h2>{{ $t('global.requestReturnOrder') }}</h2>
				<hr>
				<new-order-group-headline :headline="$t('eshop.selectingDeliveryPoint')" />
				<form-group-input
					v-if="boxTypeMode"
					v-loading="loadingContainers"
					:label="$t('eshop.pickupPlaceType')"
					required>
					<el-select
						ref="containerSelect"
						v-model="newReservation.containerId"
						class="select-default container-select"
						data-cy="container"
						filterable
						remote
						:remote-method="filterFreeContainers"
						:placeholder="$t('eshop.containerSearchHint')"
						@change="cleanBoxType"
						@visible-change="onVisibleChange">
						<template slot="prefix">
							<span v-if="container" class="container-icon-in-select-box">
								<img v-if="container.containerType === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
								<img v-if="container.containerType === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
								<i v-if="container.containerType === 'PrivateAddress'" class="fa fa-home text-dark" />
								<i v-if="container.containerType === 'SmartKeyBox'" class="nc-icon nc-mobile text-dark" />
							</span>
						</template>
						<el-option
							v-for="opt in freeContainersFiltered"
							:key="opt.containerId"
							data-cy="selectContainer"
							:label="formatContainerName(opt)"
							:value="opt.containerId">
							<img v-if="opt.containerType === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
							<img v-if="opt.containerType === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
							<i v-if="opt.containerType === 'PrivateAddress'" class="fa fa-home text-dark" />
							<i v-if="opt.containerType === 'SmartKeyBox'" class="nc-icon nc-mobile text-dark" />
							{{ formatContainerName(opt) }}
						</el-option>
					</el-select>
				</form-group-input>
				<form-group-input
					v-if="availableBoxTypes && boxTypeMode"
					:disabled="!newReservation.containerId || newReservation.boxId"
					:label="$t('global.boxType')"
					required>
					<div>
						<el-radio-group
							v-model="newReservation.boxType"
							size="medium"
							:disabled="disableAllBoxButtons"
							@change="boxTypeChanged">
							<el-radio-button
								v-for="box in BoxType.enumValues"
								:key="box.ordinal"
								data-cy="select-box"
								:label="box"
								:disabled="disableBoxButton(box)">
								<el-tooltip
									v-if="disableBoxButton(box)"
									class="boxTypeButton"
									:content="disableBoxAlert(box)"
									placement="top-start">
									<span>{{ box.czech() }}</span>
								</el-tooltip>
								<span v-else class="boxTypeButton">
									{{ box.czech() }}
								</span>
							</el-radio-button>
						</el-radio-group>
					</div>
					<div>
						<div v-if="newReservation.boxType && selectedBoxSizes">
							<i class="fa fa-info-circle" aria-hidden="true" />
							<span class="font-weight-bold pl-1">Max: </span>
							{{ $t('eshop.width') }}&nbsp;{{ selectedBoxSizes.width }}&nbsp;cm
							&#x25cf;&nbsp;{{ $t('eshop.height') }}&nbsp;{{ selectedBoxSizes.height }}&nbsp;cm
							&#x25cf;&nbsp;{{ $t('eshop.depth') }}&nbsp;{{ selectedBoxSizes.depth }}&nbsp;cm
						</div>
					</div>
				</form-group-input>
				<form-group-input :label="$t('eshop.orderNumber')">
					<el-input
						v-model="newReservation.reservationNumber"
						:disabled="!newReservation.containerId"
						name="reservationNumber"
						data-cy="reservationNumber" />
				</form-group-input>
				<p-checkbox v-model="newReservation.ecoChallenge">
					{{ $t('eshop.ecoChallenge') }}
				</p-checkbox>
				<form-group-input
					v-if="boxTypeMode"
					:error="getError(newReservationValidation.reservedHours.field)"
					:label="$t('global.reserve')"
					required>
					<el-input
						v-model="reservedHours"
						v-validate="newReservationValidation.reservedHours.rule"
						:disabled="!newReservation.containerId"
						:name="newReservationValidation.reservedHours.field"
						type="number"
						:data-vv-as="$t('global.reserve')"
						@change="reservedHoursWeekendCheck">
						<span slot="append">
							{{ $t(translateWithNumber('global', 'hour', reservedHours)) }}
						</span>
					</el-input>
				</form-group-input>
				<p v-if="expiredOnWeekend" class="expired-on-weekend">
					{{ $t("eshop.expiredOnWeekendReturn") }} <a href="javascrit:;" @click="presetExpirationToMonday">{{ $t("eshop.expiredOnWeekendReturnLink") }}</a>
				</p>
				<hr>
				<div class="d-flex justify-content-between align-items-center">
					<div>
						<p v-if="boxTypeMode" class="note text-muted">
							{{ $t('global.required') }}
						</p>
					</div>
				</div>
				<div>
					<p-button @click="returnToFirstStep">
						{{ $t('eshop.back') }}
					</p-button>
					<p-button
						:disabled="!canSave"
						class="pull-right"
						data-cy="returnRequest"
						@click.once="createNewReservation">
						{{ $t('global.requestReturnOrder') }}
					</p-button>
				</div>
			</card>
		</div>
		<div class="col-md-6">
			<container-boxes-card
				ref="containerBoxesCard"
				v-loading="loading"
				:container="container"
				@go-to-reservation-detail="goToReservationDetail"
				@box-selection-changed="selectSpecificBox"
				@get-container="getOne" />
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import {
	Input as ElInput, Option as ElOption, Select as ElSelect, Tooltip as ElTooltip, RadioGroup as ElRadioGroup,
	RadioButton as ElRadioButton,
} from 'element-ui';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/max';

import { containerTypes } from '@/globalConstants';
import NewOrderGroupHeadline from '@/modules/eshop/views/Order/NewOrder/NewOrderGroupHeadline.vue';
import notifyService from '@/service/notify-service';
import { BoxType } from '@/modules/eshop/store/enums';
import Validation from '@/util/validations';
import ValidationForm from '@/util/validated-form-mixin';
import ContainerSelectMixin from '@/util/container-select-mixin';
import FormMixin from '@/util/form-mixin';
import { parseIncompletePhoneNumberWithNull, translateWithNumber } from '@/util/helpers';
import { formChanged } from '@/store/mutation-types';
import ContainerBoxesCard from '@/modules/eshop/views/Order/ContainerBoxesCard.vue';

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

export default {
	name: 'BaseNewReturnOrder',
	components: {
		ElSelect,
		ElOption,
		ElInput,
		NewOrderGroupHeadline,
		ElTooltip,
		ElRadioButton,
		ElRadioGroup,
		ContainerBoxesCard,
	},

	mixins: [ValidationForm, FormMixin, ContainerSelectMixin],
	props: {
		boxTypeMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			translateWithNumber,
			BoxType,
			loading: false,
			loadingContainers: false,
			freeContainers: [],
			availableBoxTypes: [],
			expiredOnWeekend: false,
			newReservation: {
				boxType: this.boxTypeMode ? null : 'Large',
				containerId: null,
				mail: null,
				phone: null,

				reservationNumber: null,
				minutes: HOURS_IN_DAY * MINUTES_IN_HOUR,
			},
			reservedHours: HOURS_IN_DAY,
			newReservationValidation: {
				email: {
					rule: Validation.email,
					field: 'email',
				},
				phone: {
					rule: Validation.phone,
					field: 'phone',
				},
				reservedHours: {
					rule: Validation.reservedHours,
					field: 'reservedHours',
				},
			},
			container: null,
			immediateSuceeded: false,
			formSteps: {
				first: 'first',
				second: 'second',
			},
			formStep: 'first',
			selectedBoxSizes: null,
		};
	},

	computed: {
		currentUser() {
			return this.$store.state[this.currentAppName].currentUser;
		},
		canSave() {
			const { boxType, containerId, mail, phone } = this.newReservation;
			return (this.boxTypeMode ? boxType : true) && containerId && (mail || phone) && this.isValid;
		},
		disableContinueToSecondStep() {
			return !(this.newReservation.mail || this.newReservation.phone) || !this.isValid;
		},
		disableAllBoxButtons() {
			return !this.newReservation.containerId;
		},
		reservationChecksNonWorkingDays() {
			return this.currentUser.ReservationChecksNonWorkingDays === 'True';
		},
	},

	watch: {
		newReservation: {
			handler() {
				if (this.immediateSuceeded) {
					this.$store.commit(`${this.currentAppName}/${formChanged}`, true);
				} else {
					this.immediateSuceeded = true;
				}

				if (this.boxTypeMode) {
					const newContainerId = this.newReservation.containerId;
					const container = this.freeContainers.find((x) => x.containerId === newContainerId);
					this.availableBoxTypes = container?.availableBoxTypes || [];
				}
				if (this.newReservation?.boxType instanceof Object) {
					this.selectedBoxSizes = this.newReservation.boxType.proportions();
				}
			},
			deep: true,
		},
		boxTypeMode: {
			immediate: true,
			handler(val) {
				if (!val) {
					this.newReservation.containerId = Number(this.currentUser.client_ContainerId);
				}
			},
		},
	},
	mounted() {
		this.immediateSuceeded = false;
		this.reservedHoursWeekendCheck();
	},
	methods: {
		reservedHoursWeekendCheck() {
			if (this.reservationChecksNonWorkingDays) {
				const date = moment().add(this.reservedHours, 'hours');
				const day = date.day();
				if (day === 6 || day === 7) {
					this.expiredOnWeekend = true;
				}
			}
		},
		presetExpirationToMonday() {
			const nextMonday = moment().startOf('isoWeek').add(1, 'week').set('hour', 12);
			this.reservedHours = nextMonday.diff(moment(), 'hours');
			this.expiredOnWeekend = false;
		},
		async continueToNextStep() {
			if ((this.newReservation.mail || this.newReservation.phone) && this.fieldsbag.email.valid && this.fieldsbag.phone.valid) {
				await this.getFreeContainers();
				this.formStep = this.formSteps.second;
				this.$nextTick(() => {
					this.setContainerSelectEventListener();
				});
			}
		},
		returnToFirstStep() {
			this.formStep = this.formSteps.first;
			this.newReservation.boxType = null;
			this.newReservation.containerId = null;
		},
		cleanBoxType() {
			const newContainerId = this.newReservation.containerId;
			this.container = this.freeContainers.find((x) => x.containerId === newContainerId);
			if (this.container?.availableBoxTypes?.length === 1) {
				this.newReservation.boxType = this.container.availableBoxTypes.toString();
			} else {
				this.newReservation.boxType = null;
			}

			if (this.container?.containerType === containerTypes.penguinBox
      || this.container?.containerType === containerTypes.smartBox) {
				this.getOne();
			}

			if (this.container?.containerType === containerTypes.store) {
				this.reservedHours = 168;
			}
		},
		async getFreeContainers() {
			try {
				this.loadingContainers = true;
				this.freeContainers = await this.$service.container.getFreeContainersWithExpired(this.newReservation.mail, false);
				if (!this.newReservation.mail) {
					this.freeContainers = this.freeContainers.filter((container) => container.containerType !== containerTypes.smartBox);
				}
				this.enrichFreeContainers(this.freeContainers);
			} catch (e) {
				notifyService.notifyError(this.$t('global.generalError'));
			} finally {
				this.loadingContainers = false;
			}
		},

		formatContainerName(container) {
			return this.$options.filters.location(container);
		},

		formatPhone() {
			// If parse is not called, format will return empty string on non-number input
			this.newReservation.phone = formatIncompletePhoneNumber(parseIncompletePhoneNumberWithNull(this.newReservation.phone), 'CZ');
		},

		async createNewReservation() {
			if (this.canSave) {
				try {
					this.loading = true;

					this.newReservation.minutes = this.reservedHours * MINUTES_IN_HOUR;
					const boxType = this.newReservation.boxType.name;
					this.newReservation.boxType = boxType;
					const response = await this.$service.reservation.newReservationReturn(this.newReservation);

					if (response.reservationId) {
						notifyService.notifySuccess(this.$t('global.returnOrderCreated'));

						this.$store.commit(`${this.currentAppName}/${formChanged}`, false);
						this.$router.push({ name: 'reservation-detail', params: { id: response.reservationId } });
					} else {
						notifyService.notifyError(this.$t('global.returnOrderCreationError'));
					}
				} catch (e) {
					console.warn(e);

					notifyService.notifyError(this.$t('global.returnOrderCreationError'));
				} finally {
					this.loading = false;
				}
			} else {
				notifyService.notifyError(this.$t('global.missingValues'));
			}
		},
		disableBoxAlert(box) {
			if (!this.newReservation.containerId) return `${this.$t('eshop.firstChooseDeliveryPoint')}`;
			if (this.disableBoxButton(box)) return `${this.$t('eshop.boxNotAvailable')}`;
			return '';
		},
		boxTypeChanged(box) {
			this.newReservation.boxId = null;
			this.$refs.containerBoxesCard.cleanSelection();
			this.newReservation.boxType = box;
		},
		disableBoxButton(box) {
			return !this.availableBoxTypes?.includes(box.name);
		},
		async getOne() {
			this.loading = true;
			const selectedContainerType = this.container.containerType;
			this.container = await this.$service.container.getOne(this.newReservation.containerId);
			this.container.containerType = selectedContainerType;
			this.loading = false;
		},
		goToReservationDetail(reservationId) {
			reservationId && this.$router.push({ name: 'reservation-detail', params: { id: reservationId } });
		},

		selectSpecificBox(boxId, boxType) {
			this.newReservation.boxId = boxId;
			this.newReservation.boxType = BoxType.enumValues.find((v) => v.name === boxType);
		},
	},
};
</script>

<style>
.note {
	font-size: 80%;
}
.el-select .el-input.is-disabled .el-input__inner::placeholder {
	color: #636c72;
}
.container-icon-in-select-box {
	padding-left: 7px;
	padding-top: 10px;
	padding-bottom: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.expired-on-weekend {
	color: #e59400;
}
</style>
