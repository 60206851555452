import { mapActions } from 'vuex';

import DriverList from '@/modules/logistics/views/LogisticsUsers/DriverList.vue';
import DriverForm from '@/modules/logistics/views/LogisticsUsers/DriverForm.vue';
import DeliveryList from '@/modules/logistics/views/Reservations/DeliveryList.vue';
import SettingsView from '@/modules/logistics/views/Settings/SettingsView.vue';
import NewRoute from '@/modules/logistics/views/NewRoute/NewRoute.vue';
import ReportDetails from '@/modules/logistics/views/Report/ReportDetails.vue';
import LoggedOutLayout from '@/components/Layouts/LoggedOutLayout.vue';
import Child from '@/components/Layouts/LoggedOut/Child.vue';
import { BaseLogin, BaseForgotPassword, BaseResetPassword, BaseConfirmation, BaseChangePasswordForm, BaseReportList } from '@/components/Base';
import { authenticate, logout } from '@/store/action-types';
import { appName } from '@/modules/logistics/config';
import Post from '@/modules/logistics/views/Post/Post.vue';
import ReservationForm from '@/modules/logistics/views/Reservations/ReservationForm.vue';
import { i18n } from '@/i18n';

// TODO - I didnt used i18n for this router, because this will be deleted with old logistics in PENG-2418
export const routes = [
	{
		component: LoggedOutLayout,
		path: '',
		meta: {
			title: i18n.t('admin.logistics'),
		},
		children: [
			{
				path: '/logistika',
				children: [
					{ name: 'login', path: '', component: BaseLogin, props: { ...mapActions(appName, [authenticate, logout]) } },
					{ name: 'forgot-password', path: 'zapomenute-heslo', component: BaseForgotPassword },
					{ name: 'pass-reset', path: 'ResetPass/:user', component: BaseResetPassword },
					{ name: 'mail-confirm', path: 'Confirmation/:user', component: BaseConfirmation },
				],
				component: Child,
			},
		],
	},
	{
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "logistic" */ '../../components/Layouts/DashboardLayout.vue'),
		path: '/logistika/',
		redirect: '/logistika/rezervace',
		meta: {
			title: 'Logistika',
		},
		children: [
			{
				name: 'driver-new',
				path: 'ridici/novy',
				component: DriverForm,
				meta: {
					breadcrumb: [{ name: 'Řidiči', link: 'driver-list' }, { name: 'Nový' }],
				},
			},
			{
				name: 'settings',
				path: 'nastaveni',
				component: SettingsView,
				meta: {
					breadcrumb: [{ name: 'Nastavení', link: 'settings' }, { name: 'Nastavení' }],
				},
			},
			{
				name: 'driver-edit',
				path: 'ridici/:id',
				component: DriverForm,
				meta: {
					breadcrumb: [{ name: 'Řidiči', link: 'driver-list' }, { name: 'Detail' }],
				},
			},
			{
				name: 'driver-list',
				path: 'ridici',
				component: DriverList,
				meta: {
					breadcrumb: [{ name: 'Řidiči' }],
				},
			},
			{
				name: 'delivery-list',
				path: 'rezervace',
				component: DeliveryList,
				meta: {
					breadcrumb: [{ name: 'Rezervace' }],
				},
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2${to.path}`;
				},
			},
			{
				name: 'new-route',
				path: 'nova-trasa',
				component: NewRoute,
				meta: {
					breadcrumb: [{ name: 'Nová trasa' }],
				},
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2${to.path}`;
				},
			},
			{
				name: 'edit-route',
				path: 'uprava-trasy/:id',
				component: NewRoute,
				meta: {
					breadcrumb: [{ name: 'Úprava trasy' }],
				},
			},
			{
				name: 'report-list',
				path: 'report',
				component: BaseReportList,
				meta: {
					breadcrumb: [{ name: 'Report' }],
				},
				props: {
					withContainers: true,
				},
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2${to.path}`;
				},
			},
			{
				name: 'report-detail',
				path: 'report/detail/:id',
				component: ReportDetails,
				meta: {
					breadcrumb: [{ name: 'Report', link: 'report-list' }, { name: 'Detail' }],
				},
			},
			{
				name: 'password-change',
				path: 'zmena-hesla',
				component: BaseChangePasswordForm,
				meta: {
					breadcrumb: [{ name: 'Změna hesla' }],
				},
			},
			{
				name: 'post-orders',
				path: 'postovni-zasilky',
				component: Post,
				meta: {
					breadcrumb: [{ name: 'Pošta' }],
				},
			},
			{
				name: 'reservation-detail',
				path: 'postovni-zasilky/detail/:id',
				component: ReservationForm,
				meta: {
					breadcrumb: [{ name: 'Pošta', link: 'post-orders' }, { name: 'Detail' }],
				},
			},
			{
				name: 'statistiky',
				path: 'statistiky',
				component: Post,
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2${to.path}`;
				},
			},
			{
				name: 'svoz-obleceni',
				path: 'svoz-obleceni',
				component: Post,
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2${to.path}`;
				},
			},
		],
	},
];
