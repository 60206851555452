<template>
	<card>
		<div class="card-body">
			<div :class="isExpired ? 'bg-danger bg-expired' : ''">
				<h2 class="card-title">
					{{ $t('eshop.reservationDetail') }} {{ reservation.reservationId }}
				</h2>
				<p v-if="isExpired">
					{{ $t('eshop.reservationWillBeTakenToWarehouse') }}
				</p>
			</div>

			<form-group-input :label="$t('eshop.occupiedFrom')" disabled>
				<date-picker
					:value="reservation.from | datetime"
					type="datetime"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					prefix-icon="el-icon-date"
					readonly />
			</form-group-input>
			<form-group-input :label="$t('eshop.subscriptionEnd')" disabled>
				<date-picker
					:value="reservation.to | datetime"
					type="datetime"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					prefix-icon="el-icon-date"
					readonly />
			</form-group-input>
			<form-group-input :label="$t('eshop.pickedUp')" disabled>
				<date-picker
					:value="reservation.realEnd | datetime"
					type="datetime"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					prefix-icon="el-icon-date"
					readonly />
			</form-group-input>
			<div class="d-flex">
				<div class="col-sm-6 pl-0">
					<form-group-input :label="$t('eshop.boxNumber')" :value="lockAliasOrSerialNumber" readonly />
				</div>
				<div class="col-sm-6 pr-0">
					<form-group-input :label="$t('eshop.boxType')" :value="BoxType.czech(reservation.type)" readonly />
				</div>
			</div>
			<form-group-input :label="$t('eshop.orderNumber')" :value="reservation.reservationNumber" readonly />
			<form-group-input :label="$t('eshop.cashOnDelivery')" :value="reservation.price" readonly />
			<form-group-input :label="$t('eshop.state')" :value="reservationStatusCzech" readonly />
			<form-group-input :label="$t('eshop.email')" :value="reservation.email" readonly />
			<form-group-input :label="$t('eshop.phone')" :value="reservation.phone | phone" readonly />
			<form-group-input :label="$t('eshop.boxAddress')" :value="getAddressBoxValue(reservation)" readonly />
			<fieldset class="form-group border p-3">
				<legend class="w-auto px-2 mb-n2 control-label">
					{{ $t('eshop.customerAddress') }}
				</legend>
				<div class="row">
					<div class="col-sm-6">
						<form-group-input :label="$t('eshop.country')" :value="reservation.customer && reservation.customer.state" readonly />
						<form-group-input :label="$t('eshop.county')" :value="reservation.customer && reservation.customer.county" readonly />
						<form-group-input :label="$t('eshop.zip')" :value="reservation.customer && reservation.customer.zip" readonly />
					</div>
					<div class="col-sm-6">
						<form-group-input :label="$t('eshop.region')" :value="reservation.customer && reservation.customer.region" readonly />
						<form-group-input :label="$t('eshop.city')" :value="reservation.customer && reservation.customer.city" readonly />
						<form-group-input :label="$t('eshop.street')" :value="reservation.customer && reservation.customer.street" readonly />
					</div>
				</div>
			</fieldset>
			<form-group-input :label="$t('eshop.customerName')" :value="reservation.customerName" readonly />
			<form-group-input
				v-if="allowPinOperations"
				:label="$t('admin.unlockCode')"
				:value="reservation.unlockCode"
				readonly />
			<div class="d-flex justify-content-between">
				<div>
					<div v-if="canEditReservation">
						<el-tooltip :content="$t('eshop.tooltips.updateReservation')" placement="top-start">
							<p-button size="md" type="success" @click="$emit('show-hide-update-reservation')">
								{{ $t('eshop.reservationEdit') }}
							</p-button>
						</el-tooltip>
					</div>
				</div>
				<div v-if="canBeFounded">
					<el-tooltip :content="$t('eshop.tooltips.packageFounded')" placement="top-start">
						<confirm-button
							:confirm-message="$t('eshop.markAsCollectedQuestion')"
							:message-success="$t('eshop.collectSuccess')"
							:message-error="$t('eshop.collectError')"
							:show-success-message="false"
							:callback="lostPackageFounded">
							{{ $t('eshop.packageFounded') }}
						</confirm-button>
					</el-tooltip>
				</div>
				<div v-if="canCollectIt">
					<el-tooltip :content="$t('eshop.tooltips.collectIt')" placement="top-start">
						<confirm-button
							:confirm-message="$t('eshop.collectQuestion')"
							:show-success-message="false"
							:message-error="$t('eshop.collectError')"
							:callback="collectIt">
							{{ $t('eshop.collect') }}
						</confirm-button>
					</el-tooltip>
				</div>
				<div v-if="canDirectSale">
					<el-tooltip :content="$t('eshop.tooltips.directSale')" placement="top-start">
						<p-button @click="showHideDirectSale">
							{{ $t('eshop.sell') }}
						</p-button>
					</el-tooltip>
				</div>
				<div v-if="canDiscard">
					<confirm-button
						:confirm-message="$t('global.confirmDiscardReservation')"
						:message-success="$t('eshop.discardReservationSuccess')"
						:message-error="$t('eshop.discardReservationError')"
						:callback="discardReservation"
						:show-input="true"
						:input-description="$t('global.requiredNoteDialogText')"
						:require-input="true">
						{{ $t('eshop.discardReservation') }}
					</confirm-button>
				</div>
				<div v-if="canEndReservation">
					<el-tooltip :content="$t('eshop.tooltips.finishReservation')" placement="top-start">
						<confirm-button
							:confirm-message="$t('eshop.finishReservation')"
							:message-success="$t('eshop.finishReservationSuccess')"
							:message-error="$t('eshop.finishReservationError')"
							:callback="discardReservation">
							{{ $t('eshop.finishReservation') }}
						</confirm-button>
					</el-tooltip>
				</div>
				<div v-if="canReactivate">
					<el-tooltip :content="$t('eshop.tooltips.returnPickUp')" placement="top-start">
						<p-button type="success" size="md" @click="reactivateReservation">
							{{ $t('eshop.returnForPickup') }}
						</p-button>
					</el-tooltip>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="directSaleVisible">
			<direct-sale
				:reservation-id="reservation.reservationId"
				:reservation-number="reservation.reservationNumber"
				@save-direct-sale="saveDirectSale"
				@cancel-direct-sale="showHideDirectSale" />
		</el-dialog>
	</card>
</template>

<script>
import { assign } from 'lodash';
import { DatePicker, Tooltip as ElTooltip, Dialog as ElDialog } from 'element-ui';
import { mapState } from 'vuex';

import notifyService from '@/service/notify-service';
import { ReservationStatus, BoxType } from '@/modules/eshop/store/enums';
import { setTheSpecialModal } from '@/store/mutation-types';
import ReservationDetailMixin from '@/util/reservation-detail-mixin';
import BaseMixins from '@/util/base-mixins';
import LockAliasMixin from '@/util/lock-alias-mixin';
import { appName } from '@/modules/eshop/config';

import DirectSale from './DirectSale.vue';

export default {
	name: 'ReservationDetail',
	components: {
		DatePicker,
		ElTooltip,
		DirectSale,
		ElDialog,
	},
	mixins: [ReservationDetailMixin, BaseMixins, LockAliasMixin],
	props: {
		reservation: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			BoxType,
			directSaleVisible: false,
			lockAliasOrSerialNumber: this.reservation?.containerId ? this.$t('eshop.loadingData') : '',
		};
	},
	computed: {
		...mapState(appName, ['currentUser']),
		...mapState(['theSpecialModalInputText']),
		canEndReservation() {
			return ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.PREPARED && new Date(this.reservation.prepaidTo) > new Date();
		},
		canBeFounded() {
			return ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.LOST;
		},
		isExpired() {
			return ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.PREPARED && new Date(this.reservation.prepaidTo) < new Date();
		},
		allowPinOperations() {
			return this.currentUser.AllowPinOperations === 'True';
		},
		canDiscard() {
			const {
				WAIT_FOR_COLLECT,
				WAIT_FOR_PAY,
				WAIT_FOR_DELIVERY,
				WAIT_FOR_RETURN,
				WAIT_FOR_RETURN_WITH_RESERVATION,
				WAIT_FOR_PACKAGE,
				WAIT_FOR_EMPTY_DESTINATION,
				DISCARD,
				PICK_UP,
				COLLECTED,
				PRESELECT,
			} = ReservationStatus;

			return (
				[WAIT_FOR_DELIVERY, WAIT_FOR_RETURN, WAIT_FOR_PACKAGE, WAIT_FOR_RETURN_WITH_RESERVATION]
					.includes(ReservationStatus.enumValueOf(this.reservation.status))
				|| (this.reservation.stockItem
					&& ![WAIT_FOR_COLLECT, WAIT_FOR_PAY, WAIT_FOR_EMPTY_DESTINATION, DISCARD, PICK_UP, COLLECTED, PRESELECT].includes(
						ReservationStatus.enumValueOf(this.reservation.status),
					))
					|| (this.reservation.stockItem && this.reservation.stockItem.isRent
					&& (ReservationStatus.enumValueOf(this.reservation.status) === PICK_UP
						|| ReservationStatus.enumValueOf(this.reservation.status) === WAIT_FOR_COLLECT))
			);
		},
		canCollectIt() {
			const { WAIT_FOR_PAY, PREPARED, ACTIVE } = ReservationStatus;

			return [PREPARED, WAIT_FOR_PAY, ACTIVE].includes(ReservationStatus.enumValueOf(this.reservation.status));
		},
		canReactivate() {
			return ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.PICK_UP
				|| (ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.WAIT_FOR_COLLECT
					&& (this.reservation.reservationType).includes('Delivery'));
		},
		canEditReservation() {
			const { isEditable, status } = this.reservation;
			const { PREPARED, ACTIVE, WAIT_FOR_DELIVERY, WAIT_FOR_RETURN, WAIT_FOR_PACKAGE, WAIT_FOR_RETURN_WITH_RESERVATION } = ReservationStatus;

			return isEditable && [PREPARED, ACTIVE, WAIT_FOR_DELIVERY, WAIT_FOR_RETURN, WAIT_FOR_PACKAGE, WAIT_FOR_RETURN_WITH_RESERVATION]
				.includes(ReservationStatus.enumValueOf(status));
		},
		canDirectSale() {
			return this.currentUser.DirectSale === 'True' && ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.WAIT_FOR_PAY;
		},
		reservationStatusCzech() {
			return ReservationStatus.czech(this.reservation.status);
		},
	},
	watch: {
		reservation: {
			deep: true,
			immediate: true,
			async handler() {
				await this.loadLockAliasOrSerialNumberForBox(this.$service.smartBox, this.reservation.containerType, this.reservation.serialNumber);
			},
		},
	},
	methods: {
		async discardReservation() {
			await this.$service.reservation.saveNotes({
				note: this.theSpecialModalInputText,
				reservationId: this.reservation.reservationId,
			});
			const data = await this.$service.eshop.discardReservation(this.reservation.reservationId);
			this.$store.commit('setTheSpecialModalInputText', '');
			assign(this.reservation, data);
		},

		async reactivateReservation() {
			try {
				await this.$service.eshop.reactivateReservation(this.reservation.reservationId);
				notifyService.notifySuccess(this.$t('eshop.newPinGeneratedReservationStatusChanged'));
				this.$emit('get-one');
			} catch {
				notifyService.notifyError(this.$t('eshop.changeStatusErrorNewPinError'));
			}
		},

		showHideDirectSale() {
			this.directSaleVisible = !this.directSaleVisible;
		},

		async collectIt() {
			try {
				const data = await this.$service.eshop.collectReservation(this.reservation.reservationId);
				assign(this.reservation, data);

				notifyService.notifySuccess(this.$t('eshop.collectSuccess'));
			} catch (e) {
				this.$store.commit(setTheSpecialModal, {
					show: true,
					title: this.$t('eshop.error'),
					text: this.$t('collectError'),
					type: 'error',
					confirmButtonText: 'OK',
				});
			}
		},

		async lostPackageFounded() {
			try {
				await this.$service.eshop.markAsCollected(this.reservation.reservationId);
				notifyService.notifySuccess(this.$t('eshop.markAsCollectedSucces'));
				this.$emit('get-one');
			} catch {
				notifyService.notifyError(this.$t('eshop.markAsCollectedError'));
			}
		},

		saveDirectSale() {
			this.$emit('get-one');
			this.showHideDirectSale();
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-expired {
	border-radius: 10px;
	padding: 5px;
	margin-bottom: 5px;
}
.form-group legend {
	font-size: 0.8rem;
}
</style>
