<template>
	<form @submit.prevent="submit">
		<h3 slot="header" class="header text-center">
			{{ title }}
		</h3>
		<slot />
		<p-button
			slot="footer"
			native-type="submit"
			type="primary"
			round
			block
			class="mb-3">
			<i slot="labelRight" class="nc-icon nc-minimal-right" />
			{{ actionTitle }}
		</p-button>
	</form>
</template>

<script>
export default {
	name: 'AccountFormWrap',
	props: {
		title: { type: String, default: () => {} },
		actionTitle: { type: String, default: () => {} },
	},
	methods: {
		submit() {
			this.$emit('form-submit');
		},
	},
};
</script>
