export const authenticate = 'authenticate';
export const loadCurrentUser = 'loadCurrentUser';
export const logout = 'logout';
export const refreshToken = 'refreshToken';
export const authSuccess = 'authSuccess';

export const addForCollection = 'addForCollection';
export const removeFromCollection = 'removeFromCollection';
export const cleanForCollection = 'cleanForCollection';
export const collectSoon = 'collectSoon';
export const collectLater = 'collectLater';
