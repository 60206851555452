import { addReservationForCollection, cleanReservationsForCollection, collectLater, collectSoon, removeReservationForCollection } from '@/store/mutation-types';

export default {
	[cleanReservationsForCollection](state) {
		state.reservationsForCollect = [];
	},

	[addReservationForCollection](state, { reservation }) {
		if (!state.reservationsForCollect.some((e) => e.reservationId === reservation.reservationId)) {
			state.reservationsForCollect.push(reservation);
		}
	},

	[removeReservationForCollection](state, { reservation }) {
		state.reservationsForCollect = state.reservationsForCollect.filter((v) => v.reservationId !== reservation.reservationId);
	},

	[collectSoon](state, reservation) {
		const index = state.reservationsForCollect.findIndex((x) => x.reservationId === reservation.reservationId);
		if (index > 0) {
			state.reservationsForCollect.splice(index - 1, 0, state.reservationsForCollect.splice(index, 1)[0]);
		}
	},

	[collectLater](state, reservation) {
		const index = state.reservationsForCollect.findIndex((x) => x.reservationId === reservation.reservationId);
		if (index < state.reservationsForCollect.length - 1) {
			state.reservationsForCollect.splice(index + 1, 0, state.reservationsForCollect.splice(index, 1)[0]);
		}
	},
};
