<template>
	<div class="row">
		<div class="col-md-12 card">
			<div class="d-flex align-items-center">
				<div class="col-6">
					<form-group-input :label="$t('logistics.reservationState')">
						<el-select
							v-model="filter.reservationSelects"
							size="large"
							class="w-100"
							:placeholder="$t('logistics.reservationStatus')"
							value-key="ordinal"
							clearable
							multiple
							@change="loadData">
							<el-option
								v-for="status in availableStatuses"
								:key="status.ordinal"
								:label="status.czech()"
								:value="status">
								<i :class="status.icon()" />
								{{ status.czech() }}
							</el-option>
						</el-select>
					</form-group-input>
				</div>
				<div class="col-6">
					<form-group-input v-model="filter.ReservationNumber" :label="$t('logistics.orderNumber')" type="text" />
				</div>
			</div>
			<div class="col-2 mb-1 d-flex justify-content-start">
				<p-button type="success" size="md" @click="loadData">
					{{ $t('logistics.search') }}
				</p-button>
				<el-tooltip :content="$t('logistics.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</div>

		<div class="col-md-12 card">
			<div v-loading="loading" class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-sm-12 mt-2">
						<el-table
							data-cy="ListMainTable"
							class="table-striped"
							:data="reservations"
							show-overflow-tooltip
							row-class-name="tableRow"
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							@sort-change="sortChange">
							<el-table-column
								width="60"
								header-align="center"
								align="center"
								property="status">
								<template #header>
									<el-tooltip :content="$t('logistics.state')" placement="top-start">
										<span>{{ $t('logistics.state') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.status" :content="ReservationStatus.czech(scope.row.status)" placement="top-start">
										<div :key="scope.row.status">
											<i :class="ReservationStatus.icon(scope.row.status)" />
										</div>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								property="reservationNumber"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.orderNumber')" placement="top-start">
										<span>{{ $t('logistics.ordNum') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								property="reservedFrom"
								header-align="center"
								align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('logistics.occupiedFrom')" placement="top-start">
										<span>{{ $t('logistics.from') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.reservedFrom | date }}
								</template>
							</el-table-column>
							<el-table-column
								align="center"
								header-align="center"
								property="container.serialNumber"
								sortable="custom"
								width="70">
								<template #header>
									<el-tooltip :content="$t('logistics.SN')" placement="top-start">
										<span>{{ $t('logistics.SN') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ serialNumber(scope.row) }}
								</template>
							</el-table-column>
							<el-table-column
								header-align="center"
								align="center"
								sortable="custom"
								property="deliveryPoint.city">
								<template #header>
									<el-tooltip :content="$t('logistics.city')" placement="top-start">
										<span>{{ $t('logistics.city') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.deliveryPoint ? scope.row.deliveryPoint.city : '' }}
								</template>
							</el-table-column>
							<el-table-column
								header-align="center"
								align="center"
								sortable="custom"
								property="deliveryPoint.street">
								<template #header>
									<el-tooltip :content="$t('logistics.street')" placement="top-start">
										<span>{{ $t('logistics.street') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.deliveryPoint ? scope.row.deliveryPoint.street : '' }}
								</template>
							</el-table-column>
							<el-table-column header-align="center" align="center" width="60">
								<template #header>
									<el-tooltip :content="$t('logistics.actions')" placement="top-start">
										<span>{{ $t('logistics.actions') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.reservationId" :content="$t('logistics.tooltips.addToRoute')" placement="right-end">
										<p-button
											v-loading="loadingRow(scope.row)"
											class="mx-1"
											icon
											size="sm"
											type="success"
											@click.stop="addDelivery(scope.row)">
											<i class="fa fa-plus" />
										</p-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 mt-2">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import {
	Table as ElTable,
	TableColumn as ElTableColumn,
	Pagination as ElPagination,
	Tooltip as ElTooltip,
	Select as ElSelect,
	Option as ElOption,
} from 'element-ui';

import { ReservationStatus } from '@/modules/logistics/store/enums';
import { appName } from '@/modules/logistics/config';
import { resetFilter, setFilter } from '@/store/mutation-types';
import notifyService from '@/service/notify-service';

export default {
	name: 'AvailableReservations',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElSelect,
		ElOption,
	},
	props: {
		routeId: { type: Number, default: null },
	},
	data() {
		return {
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			ReservationStatus,
			loading: false,
			loadingRows: [],
			filter: {},
		};
	},
	computed: {
		...mapState(appName, ['reservationFilter']),
		reservations() {
			return this.page.entities;
		},
		availableStatuses() {
			return [ReservationStatus.WAIT_FOR_DELIVERY,
				ReservationStatus.WAIT_FOR_COLLECT,
				ReservationStatus.WAIT_FOR_RETURN,
				ReservationStatus.WAIT_FOR_RETURN_WITH_RESERVATION];
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'reservationFilter', data: val });
			},
		},
	},
	created() {
		this.filter = this.reservationFilter;
		const availableStatuses = [];
		this.availableStatuses.forEach((status) => availableStatuses.push(status.name));
		this.filter.reservationSelects = this.filter.reservationSelects.filter((selected) => availableStatuses.includes(selected.name));
		this.loadData();
	},

	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		loadingRow({ reservationId }) {
			return this.loadingRows.indexOf(reservationId) !== -1;
		},
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},

		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},

		async loadData(page = 1) {
			this.filter.Status = this.filter.reservationSelects.map((status) => status.camelCase());
			this.page = await this.$service.list.getWaitForDeliveryList(page, this.filter, this.pageSize);
		},

		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'reservationFilter');
			this.filter = this.reservationFilter;
			this.loadData();
		},

		async addDelivery(row) {
			this.loadingRows.push(row.reservationId);

			this.$service.logisticsRoute
				.addReservationToRoute(row.reservationId, this.routeId)
				.then(() => {
					notifyService.notifySuccess(this.$t('admin.reservationAdded'));
					// TODO: Remove page reload and return correct data from backend to enhance user experience
					// PENG-1559
					this.$router.go(0);
				})
				.catch(() => notifyService.notifyError(this.$t('admin.generalError')))
				.finally(() => {
					const loaded = this.loadingRows.indexOf(row.reservationId);

					if (loaded !== -1) {
						this.loadingRows.splice(loaded, 1);
					}
				});
		},

		serialNumber(row) {
			if (row.container) {
				return row.container.serialNumber;
			}
			return '';
		},
	},
};
</script>
