<template>
	<div>
		<el-tooltip placement="top-start">
			<i class="far fa-clock  fa-sm" />
			<template #content>
				<div v-for="openingHour in openingHours" :key="openingHour.id">
					<div :class="isCurrentDay(openingHour.day) ? 'font-weight-bold':''">
						{{ getCzechName(openingHour.day) }}
						<span v-if="openingHour.date">
							{{ openingHour.date | date }}
						</span>
						<span v-if="openingHour.status === 'Open'">
							{{ openingHour.openTime }} {{ openingHour.closeTime }}
							<span v-if="openingHour.openTimeNext">
								| {{ openingHour.openTimeNext }} {{ openingHour.closeTimeNext }}
							</span>
						</span>
						<span v-else>
							{{ $t('logistics.closed') }}
						</span>
					</div>
				</div>
			</template>
		</el-tooltip>
	</div>
</template>

<script>
import {
	Tooltip as ElTooltip,
} from 'element-ui';

import { Weekday } from '@/modules/logistics/store/enums';

export default {
	name: 'OpeningHoursTooltip',
	components: { ElTooltip },
	props: {
		serialNumber: {
			type: String,
			default: '',
		},
		openingHours: {
			type: Array,
			default: () => ([]),
		},
	},
	created() {
		this.sortDays();
	},
	methods: {
		sortDays() {
			this.openingHours.sort((a, b) => Weekday.orderNumber(a.day) - Weekday.orderNumber(b.day));
		},
		isCurrentDay(day) {
			const currentDay = new Date().toLocaleString('en-GB', { weekday: 'long' });
			return currentDay === day;
		},
		getCzechName(dayName) {
			return this.$t(`logistics.${dayName.toLowerCase()}`);
		},
	},
};
</script>

 <style scoped>
 i {
   font-size: 1.2em;
 }
 </style>
