<template>
	<card v-if="false" v-loading="loading">
		<div id="printCollectedPost">
			<h2>{{ $t('logistics.routeReportCollectedPostReservations') }}</h2>
			<base-report-print-sheet-table>
				<tr v-for="reservation in collectedReservations" :key="reservation.reservationId">
					<td>
						{{ reservation.reservationNumber }}
					</td>
					<td>{{ reservationName(reservation) }}</td>
					<td>{{ reservation.phone }}</td>
					<td>
						<p-button type="success" @click="confirmCollected(reservation.reservationId)">
							<i class="fa fa-check" /> {{ $t('logistics.accepted') }}
						</p-button>
					</td>
				</tr>
			</base-report-print-sheet-table>
		</div>
		<div class="mt-3 d-flex justify-content-between">
			<div>
				<p-button @click="printCollectedPost">
					<i slot="labelRight" class="fa fa-print" />
					{{ $t('logistics.print') }}
				</p-button>
			</div>
		</div>
	</card>
</template>

<script>
import notifyService from '@/service/notify-service';
import PrintArea from '@/util/print-area';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import { BaseReportPrintSheetTable } from '@/components/Base';

export default {
	name: 'CollectedReservations',
	components: {
		BaseReportPrintSheetTable,
	},
	mixins: [ReservationNameMixin],
	props: {
		collectedReservations: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		printCollectedPost() {
			new PrintArea('printCollectedPost').print();
		},
		async confirmCollected(reservationId) {
			try {
				this.$emit('set-loading', true);
				await this.$service.reservation.ackCollected(reservationId);
				notifyService.notifySuccess(this.$t('admin.collectedAckSuccess'));

				this.$emit('load-data');
			} catch {
				notifyService.notifyError(this.$t('admin.reservationChangeStateFailure'));
			} finally {
				this.$emit('set-loading', false);
			}
		},
	},
};
</script>
