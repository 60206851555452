<template>
	<div class="row">
		<div v-if="showFilter && !inOneCard" class="col-md-12 card">
			<div class="d-flex justify-content-start">
				<div class="col-2">
					<form-group-input
						v-model="username"
						data-cy="inputUserName"
						:label="$t('admin.username')"
						type="text" />
				</div>
				<div class="col-2">
					<form-group-input
						v-model="email"
						data-cy="inputEmail"
						:label="$t('admin.email')"
						type="email" />
				</div>
				<div class="col-2">
					<form-group-input :label="$t('admin.locked')" type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="locked"
								no-icon="unlock"
								trigger-on-change-method
								yes-icon="lock"
								@on-change-method="loadData()" />
						</div>
					</form-group-input>
				</div>
			</div>
			<div class="col-2 d-flex mb-1 justify-content-start">
				<p-button
					data-cy="findButton"
					type="success"
					size="md"
					@click="loadData()">
					{{ $t('admin.search') }}
				</p-button>
				<el-tooltip :content="$t('admin.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</div>

		<div class="col-md-12 card">
			<div class="card-body">
				<div v-if="!basicList && !hidePaginator" class="row">
					<div class="d-flex col-md-12 justify-content-between">
						<div class="align-self-center">
							<el-pagination
								:current-page="users.currentPage"
								layout="total, sizes, prev, pager, next, jumper"
								:page-count="users.pagesCount"
								:page-size="pageSize"
								@current-change="loadData"
								@size-change="loadDataPageSize" />
						</div>
						<div class="align-self-center">
							<router-link :to="{ name: newLink }">
								<p-button data-cy="addUserButton" type="success">
									<i class="fa fa-plus" /> {{ $t('admin.add') }}
								</p-button>
							</router-link>
						</div>
					</div>
				</div>
				<div class="row">
					<h4 v-if="title" class="ml-3 mt-0">
						{{ title }}
					</h4>
					<div class="col-sm-12 mt-2">
						<div v-if="showFilter && inOneCard" class="col-md-12">
							<div class="d-flex justify-content-start">
								<div class="col-2">
									<form-group-input :label="$t('admin.locked')" type="text">
										<div class="d-flex mt-1">
											<yes-no-all
												v-model="locked"
												no-icon="unlock"
												trigger-on-change-method
												yes-icon="lock"
												@on-change-method="loadData()" />
										</div>
									</form-group-input>
								</div>
							</div>
							<div class="col-2 d-flex mb-1 justify-content-start">
								<p-button type="success" size="md" @click="loadData()">
									{{ $t('admin.search') }}
								</p-button>
								<el-tooltip :content="$t('admin.tooltips.clearFilter')" placement="top-start">
									<p-button
										class="ml-2"
										type="danger"
										icon
										size="md"
										@click="clearFilter">
										<i class="fa fa-times" />
									</p-button>
								</el-tooltip>
							</div>
						</div>
					</div>
					<div data-cy="userList" class="col-sm-12 mt-2">
						<el-table
							class="table-striped"
							:data="data"
							data-cy="ListMainTable"
							:row-class-name="tableRowName"
							@sort-change="sortChange"
							@row-click="goToEdit">
							<el-table-column property="name" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.firstName')" placement="top-start">
										<span>{{ $t('admin.firstName') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="surname" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.surname')" placement="top-start">
										<span>{{ $t('admin.surname') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="username" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.username')" placement="top-start">
										<span>{{ $t('admin.username') }}</span>
									</el-tooltip>
								</template>
								<template slot-scope="scope">
									<span data-cy="userName">{{ scope.row.username }}</span>
								</template>
							</el-table-column>
							<el-table-column property="email" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.email')" placement="top-start">
										<span>{{ $t('admin.email') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="phone" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.phone')" placement="top-start">
										<span>{{ $t('admin.phone') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column :min-width="120" class-name="td-actions">
								<template #header>
									<el-tooltip :content="$t('admin.lock')" placement="top-start">
										<span>{{ $t('admin.lock') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.locked" :content="statusTooltip(scope.row)" placement="top-start">
										<confirm-button
											:key="scope.row.locked"
											:type="statusType(scope.row)"
											size="sm"
											data-cy="lockButton"
											icon
											:show-success-message="false"
											:confirm-message="lockUnlockUserMessage(scope.row.locked)"
											:disabled="withoutLocking"
											:callback="() => handleLock(scope.row)">
											<i :class="statusIcon(scope.row)" />
										</confirm-button>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								v-if="showAssignAdminColumn"
								v-slot="scope"
								:min-width="120"
								class-name="td-actions"
								:label="$t('admin.adminGroupRole')">
								<el-tooltip :key="scope.row.locked" :content="statusTooltip(scope.row, true)" placement="top-start">
									<confirm-button
										:key="scope.row.locked"
										:callback="() => assignAdminRole(scope.row)"
										:type="statusType(scope.row, true)"
										size="sm"
										icon
										:show-success-message="false"
										:confirm-message="assignAdminRoleMessage(scope.row)">
										<i class="fa fa-user-cog" />
									</confirm-button>
								</el-tooltip>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div v-if="!hidePaginator" class="row mt-2">
					<div class="col">
						<el-pagination
							:current-page="users.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:page-count="users.pagesCount"
							:page-size="pageSize"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination, Tooltip as ElTooltip } from 'element-ui';
import { isNumber } from 'lodash';

import notifyService from '@/service/notify-service';

export default {
	name: 'BaseUserList',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	props: {
		title: { type: String, default: null },
		detailLink: { type: String, default: null },
		newLink: { type: String, default: null },
		users: { type: Object, default: () => null },
		showAssignAdminColumn: { type: Boolean, default: false },
		basicList: { type: Boolean, default: false },
		showFilter: Boolean,
		inOneCard: { type: Boolean, default: false },
		userSorting: Boolean,
		withoutLocking: Boolean,
		hidePaginator: Boolean,
	},
	data() {
		return {
			pageSize: 10,
		};
	},
	computed: {
		userFilter: {
			get() {
				return this.$store.state.userFilter;
			},
			set(val) {
				this.$store.state.userFilter = val;
			},
		},
		locked: {
			get() {
				return this.$store.state.userFilter.locked;
			},
			set(val) {
				this.$store.state.userFilter.locked = val;
			},
		},
		email: {
			get() {
				return this.$store.state.userFilter.email;
			},
			set(val) {
				this.$store.state.userFilter.email = val;
			},
		},
		username: {
			get() {
				return this.$store.state.userFilter.username;
			},
			set(val) {
				this.$store.state.userFilter.username = val;
			},
		},
		data() {
			return this.users.entities;
		},
		tableRowName() {
			return !this.basicList ? 'tableRow' : null;
		},
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	async created() {
		this.userFilter.locked = false;
		this.loadData();
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		sortChange(column) {
			if (this.userSorting) {
				this.$emit('sort', column);
			} else {
				if (column.order) {
					this.userFilter.orderby = column.prop;
					this.userFilter.direction = column.order;
				} else {
					this.userFilter.orderby = null;
					this.userFilter.direction = null;
				}
				this.loadData(this.users.currentPage);
			}
		},
		goToEdit(row) {
			if (!this.basicList) {
				this.$router.push({ name: this.detailLink, params: { id: row.id } });
			}
		},
		async handleLock(admin) {
			const lock = !admin.locked;

			const result = await this.$service.admin.lock(admin, lock);
			if (result.succeeded) {
				admin.locked = lock; // Just locally mirror of database
				notifyService.notifySuccess(this.$t('admin.userLockSuccess'));
			} else {
				notifyService.notifyError(this.$t('admin.userLockError'));
			}
		},
		async assignAdminRole(user) {
			try {
				if (user.roles.includes('Admin')) {
					await this.$service.admin.removeFromAdminGroup(user.id);
				} else {
					await this.$service.admin.assignToAdminGroup(user.id);
				}
				notifyService.notifySuccess(this.$t('admin.adminGroupRoleSuccess'));
			} catch (err) {
				notifyService.notifyError(this.$t('admin.adminGroupRoleError'));
			} finally {
				this.loadData();
			}
		},
		async loadData(page = 1) {
			await this.$emit('get-page', { page: isNumber(page) ? page : 1, ...this.userFilter, pageSize: this.pageSize });
		},
		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},
		statusIcon(user) {
			return user.locked ? 'fa fa-lock' : 'fa fa-unlock';
		},
		statusType(user, adminRole = false) {
			if (adminRole) {
				return user.roles.includes('Admin') ? 'success' : 'danger';
			}
			return user.locked ? 'danger' : 'success';
		},
		statusTooltip(user, adminRole = false) {
			if (adminRole) {
				return user.roles.includes('Admin') ? this.$t('admin.tooltips.unassignFromAdminGroup') : this.$t('admin.tooltips.assignToAdminGroup');
			}
			return user.locked ? this.$t('admin.tooltips.unlock') : this.$t('admin.tooltips.lock');
		},
		lockUnlockUserMessage(locked) {
			return locked ? this.$t('admin.unlockUser') : this.$t('admin.lockUser');
		},
		assignAdminRoleMessage(user) {
			return user.roles.includes('Admin') ? this.$t('admin.unassignFromAdminGroup') : this.$t('admin.assignToAdminGroup');
		},
		clearFilter() {
			this.$store.commit('resetFilter', 'userFilter');
			this.loadData();
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
</style>
