export function convertCoordinatesToReal(degree, minutes, seconds) {
	return degree + minutes / 60 + seconds / 3600;
}

export function convertCoordinatesToRad(coordinates) {
	const splitCoordinates = coordinates.toString().split('.');
	const degree = parseInt(splitCoordinates[0], 10);
	const minutes = parseFloat(`0.${splitCoordinates[1]}`) * 60;
	const seconds = parseFloat(`0.${minutes.toString().split('.')[1]}`) * 60;
	return {
		degree,
		minutes: parseInt(minutes.toString().split('.'), 10),
		seconds: parseFloat(seconds.toFixed(3)),
	};
}
