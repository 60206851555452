import { saveAs } from 'file-saver';
import { stringify } from 'qs';
import { isNil, omitBy } from 'lodash';

export const ReservationService = (http) => ({
	async getList(page, filter, pageSize, abortController) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			signal: abortController?.signal,
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};

		const response = await http.get('/api/Reservation/List', config);

		return response.data;
	},

	async getOne(id) {
		const response = await http.get('/api/Reservation/Detail', { params: { ReservationId: id } });

		return response.data;
	},

	async updateReservation(reserveTo, reservationId, customerAddress) {
		const response = await http.post('/api/EshopReservation/Update', {
			to: reserveTo,
			reservationId,
			...customerAddress,
		});
		return response.data;
	},

	async discardReservation(reservationId) {
		const response = await http.post('/api/EShopReservation/Discard', { reservationId });

		return response.data;
	},
	async undeliveredReservation(reservationId) {
		const response = await http.post('/api/Reservation/Undelivered', { reservationId });

		return response.data;
	},

	async reactivateReservation(reservationId) {
		const response = await http.post('/api/EshopReservation/Reactivate', { reservationId });
		return response.data;
	},

	async ackDelivery(reservationId) {
		const response = await http.post('/api/Reservation/DeliveryAck', {
			reservationId,
		});
		return response.data;
	},
	async ackPickup(reservationId) {
		const response = await http.post('/api/Reservation/PickUpAck', {
			reservationId,
		});
		return response.data;
	},

	async fetchHistory(containers, from, to) {
		const body = {
			containers,
			date: {
				from,
				to,
			},
		};
		const { data } = await http.post('/api/Reservation/ReservationHistory/csv', body);
		// Log somewhat to show that the browser actually exposes the custom HTTP header
		const filename = 'export.csv';
		const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
		// Let the user save the file.
		saveAs(blob, filename, { autoBom: true });
	},

	async generateNewCode(reservationId) {
		const response = await http.post('/api/Reservation/GenerateNewUnlockCode', { reservationId });
		return response.data;
	},

	async saveNotes(note) {
		const response = await http.post('/api/ReservationNotes', note);
		return response.data;
	},
	async getNotes(reservationId) {
		const response = await http.get('/api/ReservationNotes', {
			params: {
				reservationId,
			},
		});
		return response.data;
	},
});
