export const ContainerTreeViewService = (http) => ({
	async getRegionList(isPostPackage) {
		const response = await http.get('/api/ContainerTree/Region/Reservations', { params: { isPostPackage } });
		return response.data;
	},
	async getCountyList(region, isPostPackage) {
		const response = await http.get('/api/ContainerTree/County/Reservations', { params: { region, isPostPackage } });
		return response.data;
	},
	async getCityList(region, county, isPostPackage) {
		const response = await http.get('/api/ContainerTree/City/Reservations', { params: { region, county, isPostPackage } });
		return response.data;
	},

	async getContainerTree(region, county, city, isPostPackage) {
		const response = await http.get('/api/ContainerTree/ContainerForDelivery', { params: { region, county, city, isPostPackage } });
		return response.data;
	},

	async getService() {
		const requireService = true;
		const response = await http.get('/api/ContainerTree/ContainerForDelivery', { params: { requireService } });
		return response.data;
	},
});
