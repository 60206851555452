export const SmartLockInfoService = (http) => ({
	async getAllAvailableLocks() {
		try {
			const response = await http.get('/api/SmartLockInfo/AllAvailableLocks');

			return response.data;
		} catch (e) {
			return [];
		}
	},
});
