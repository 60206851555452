export const AdminService = (http) => ({
	async getEshopUsers(params) {
		const response = await http.get('/api/Account/ListEshopUsers', { params });

		return response.data;
	},

	async addEshopUser(eshopUser) {
		const response = await http.post('/api/Account/AddEshopUser', eshopUser);
		return response.data;
	},

	async getOne(userId) {
		const response = await http.get('/api/Account/Detail', { params: { userId } });
		return response.data;
	},

	async update(eshopUser) {
		const response = await http.post('/api/Account/Update', eshopUser);
		return response.data;
	},

	async lock(eshopUser, lock) {
		const response = await http.post('/api/Account/LockUser', { userID: eshopUser.id, lock });
		return response.data;
	},

	async changePassword(userPwd) {
		const response = await http.post('/api/Account/ChangePassword', userPwd);
		return response.data;
	},
});
