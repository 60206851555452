import { stringify } from 'qs';

export const BoxService = (http) => ({
	async setState(id, isDisabled) {
		const response = await http.post('/api/Box/State', { boxId: id, isDisabled });
		return response.data;
	},
	async openRemote(id) {
		const response = await http.post('/api/Box/OpenBox', { boxId: id, note: 'dummy note' });
		return response.data;
	},
	async occupiedBoxes(EshopId) {
		const response = await http.get('/api/Box/Occupied', { params: { EshopId } });
		return response.data;
	},
	async updateBoxCustomer(boxId, mail) {
		return http.post('/api/Box/SetCustomer', { boxId, mail });
	},
	async getBox(boxId) {
		const config = {
			params: { boxId },
			paramsSerializer: stringify,
		};
		const response = await http.get('/api/Box/Detail', config);
		return response.data;
	},
	async SetBoxAsPrivate(boxId, ownerEmail) {
		const response = await http.post('/api/BoxOwner/SetBoxAsPrivate', { boxId, ownerEmail });
		return response.data;
	},
	async SetBoxAsPublic(boxId, ownerEmail) {
		const response = await http.post('/api/BoxOwner/SetBoxAsPublic', { boxId, ownerEmail });
		return response.data;
	},
});
