// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import { getAuthDataStorageName } from '@/store/auth-actions';
import { appNameList } from '@/globalConstants';
import BaseMutations from '@/store/base-mutations';
import { authFailed, loggedOut } from '@/store/mutation-types';

import { getDefaultState } from './state';

export default {
	...BaseMutations,

	[authFailed](state, { error }) {
		BaseMutations[authFailed](state, { error });
		localStorage.removeItem(getAuthDataStorageName(appNameList.driver));
	},

	[loggedOut](state) {
		Object.assign(state, getDefaultState());
		BaseMutations[loggedOut](state);
	},
};
