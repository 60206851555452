<template>
	<div class="card">
		<div class="card-body">
			<h2 class="card-title">
				{{ $t('eshop.boxStateOfSelectedContainer') }}
			</h2>
			<el-table
				ref="containerTable"
				class="table-striped"
				size="mini"
				:data="container.boxes"
				highlight-current-row
				:row-class-name="tableRowClassName"
				@current-change="boxSelectionChanged">
				<el-table-column :width="35">
					<template #default="scope">
						<el-tooltip :content="getStatusTooltip(scope.row.reservation)" placement="top-start">
							<div>
								<i :class="getStatusIcon(scope.row.reservation)" />
							</div>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column property="serialNumber" align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.order')" placement="top-start">
							<span>{{ $t('eshop.order') }}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column property="type" header-align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.boxType')" placement="top-start">
							<span>{{ $t('eshop.boxType') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ BoxType.czech(scope.row.type) }}
					</template>
				</el-table-column>
				<el-table-column align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.availability')" placement="top-start">
							<span>{{ $t('eshop.availability') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<p-button
							:key="scope.row.isDisabled"
							:type="statusType(scope.row)"
							icon
							size="sm">
							<i :class="statusIcon(scope.row)" />
						</p-button>
					</template>
				</el-table-column>
				<el-table-column align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.usedBy')" placement="top-start">
							<span>{{ $t('eshop.usedBy') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ UsedBy.czech(scope.row.usedBy) }}
					</template>
				</el-table-column>
				<el-table-column header-align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.occupiedFrom')" placement="top-start">
							<span>{{ $t('eshop.occupiedFrom') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<span v-if="scope.row.reservation">
							{{ scope.row.reservation.from | datetime }}
						</span>
					</template>
				</el-table-column>
				<el-table-column header-align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.subscriptionEnd')" placement="top-start">
							<span>{{ $t('eshop.subscriptionEnd') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<div v-if="scope.row.reservation">
							<span v-if="scope.row.reservation.to < lastDataReceived" class="fa fa-exclamation fg-danger" />
							{{ scope.row.reservation.to | datetime }}
							<span v-if="scope.row.reservation.to < lastDataReceived" class="fa fa-exclamation fg-danger" />
						</div>
					</template>
				</el-table-column>
				<el-table-column header-align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.reservation')" placement="top-start">
							<span>{{ $t('eshop.reservation') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<p-button v-if="scope.row.reservation && scope.row.reservation.reservationId" @click="goToReservationDetail(scope.row)">
							{{ $t('eshop.detail') }}
						</p-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip } from 'element-ui';

import { ReservationStatus, BoxType, UsedBy } from '@/modules/eshop/store/enums';

export default {
	name: 'ContainerBoxesCard',
	components: {
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	props: {
		container: { type: Object, default: () => {} },
	},
	data() {
		return {
			BoxType,
			UsedBy,
		};
	},
	computed: {
		lastDataReceived() {
			if (this.container && this.container.lastParameters && this.container.lastParameters.date) {
				return this.container.lastParameters.date;
			}
			return undefined;
		},
	},
	methods: {
		cleanSelection() {
			this.$refs.containerTable.setCurrentRow();
		},
		statusIcon(box) {
			return box.isDisabled || box.usedBy === 'Other' || box.reservation ? 'fa fa-times btn-danger' : 'fa fa-check btn-success';
		},
		statusType(box) {
			return box.isDisabled || box.usedBy === 'Other' || box.reservation ? 'danger' : 'success';
		},
		statusTooltip(box) {
			return box.isDisabled ? this.$t('eshop.tooltips.enable') : this.$t('eshop.tooltips.ban');
		},
		tableRowClassName(row) {
			if (row.row.reservation && row.row.reservation.status) {
				return ReservationStatus.enumValueOf(row.row.reservation.status) !== ReservationStatus.DISCARD && 'hover-cursor';
			}
			return null;
		},
		goToReservationDetail(row) {
			if (row.reservation) {
				this.$emit('go-to-reservation-detail', row.reservation.reservationId);
			}
		},
		boxSelectionChanged(row) {
			if (row) {
				this.$emit('box-selection-changed', row.id, row.type);
			}
		},
		getStatusTooltip(reservation) {
			const status = reservation ? reservation.status : null;
			return status ? ReservationStatus.czech(status) : this.$t('eshop.tooltips.emptyBox');
		},
		getStatusIcon(reservation) {
			const status = reservation ? reservation.status : null;
			return status ? ReservationStatus.icon(status) : 'fas fa-box-open';
		},
	},
};
</script>

<style>
.hover-cursor:hover {
	cursor: pointer;
}

</style>
