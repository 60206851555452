import notifyService from '@/service/notify-service';

export default {
	methods: {
		async validate() {
			return this.$validator.validateAll();
		},

		getError(fieldName) {
			return this.errors.first(fieldName);
		},

		async validateAndSave(moduleName) {
			if (!(await this.validate())) {
				return;
			}
			// If validation passed call save() from component
			this.handleBackEndErrors(moduleName, this.save);
		},

		async handleBackEndErrors(moduleName, func, ...args) {
			const successMessage = 'Změny byly uloženy';
			const failMessage = 'Uložení se nezdařilo';
			try {
				const result = await func(...args);

				if (result?.succeeded === false) {
					result.errors.forEach((error) => notifyService.notifyError(error.description));
				} else {
					notifyService.notifySuccess(successMessage);
				}
			} catch (e) {
				console.warn(e);
				if (e.data && e.data.errors) {
					Object.keys(e.data.errors).forEach((errorObject) => {
						const err = errorObject.charAt(0).toLowerCase() + errorObject.slice(1);
						if (this?.$t(`${moduleName}.${err}`) !== `${moduleName}.${err}`) {
							notifyService.notifyError(`${e.data.errors[errorObject]}`);
						} else if (typeof e.data.errors[errorObject] === 'string' || e.data.errors[errorObject] instanceof String) {
							notifyService.notifyError(e.data.errors[errorObject]);
						} else if (e.data.errors[errorObject].description) {
							notifyService.notifyError(e.data.errors[errorObject].description);
						} else {
							notifyService.notifyError(failMessage);
						}
					});
				} else {
					notifyService.notifyError(failMessage);
				}
			}
		},
	},
	computed: {
		isValid() {
			return !Object.keys(this.fieldsbag).some((key) => this.fieldsbag[key].invalid);
		},
	},
};
