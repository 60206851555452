<template>
	<div :id="wizardId" class="wizard-container">
		<div class="card card-wizard active" :class="[wizardClasses, { 'card-transparent': plain }]">
			<form @submit.prevent>
				<!--        You can switch " data-color="primary" "  with one of the next bright colors: "green", "orange", "red", "blue"       -->
				<div class="card-header text-center">
					<slot v-if="showHeader && !$slots.header" name="header">
						<h3 class="card-title">
							{{ title }}
						</h3>
						<h3 class="description">
							{{ subTitle }}
						</h3>
					</slot>

					<div class="wizard-navigation">
						<ul class="nav nav-pills" role="tablist">
							<li
								v-for="(tab, index) in tabs"
								:id="`step-${tab.tabId}`"
								:key="tab.title"
								:ref="`tab-${index}`"
								role="tab"
								:tabindex="tab.checked ? 0 : ''"
								:aria-controls="tab.tabId"
								:aria-disabled="tab.active"
								:aria-selected="tab.active"
								class="nav-item wizard-tab-link"
								:style="linkWidth">
								<a
									class="nav-link"
									:class="[{ 'disabled-wizard-link': !tab.checked }, { active: tab.active }, { checked: tab.checked }]"
									data-toggle="tab"
									@click="navigateToTab(index)"
									@keydown="navigateToTab(index)">
									<tab-item-content :tab="tab" />
								</a>
							</li>
						</ul>
						<div
							v-if="activeTab"
							class="moving-tab"
							:class="{ 'error-link': activeTab.hasError }"
							style="transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1); width: 100%"
							:style="movingTabStyles">
							<tab-item-content :tab="activeTab" moving-tab />
						</div>
					</div>
				</div>

				<div class="card-body">
					<div class="tab-content">
						<slot :active-index="activeTabIndex" :active-tab="activeTab" />
					</div>
				</div>

				<div class="card-footer">
					<slot name="footer" :next-tab="nextTab" :prev-tab="prevTab">
						<div class="pull-right">
							<p-button
								v-if="activeTabIndex < tabCount - 1"
								wide
								class="btn-next"
								@click.native="nextTab">
								{{ nextButtonText }}
							</p-button>
							<p-button v-else wide @click.native="nextTab">
								{{ finishButtonText }}
							</p-button>
						</div>

						<div class="pull-left">
							<p-button
								v-if="activeTabIndex > 0"
								wide
								class="btn-previous"
								@click.native="prevTab">
								{{ prevButtonText }}
							</p-button>
						</div>
						<div class="clearfix" />
					</slot>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { throttle } from 'src/util/throttle';

function randomString(maxChars = 7) {
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
	let text = '';

	for (let i = 0; i < maxChars; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

	return text;
}
export default {
	name: 'Wizard',
	components: {
		TabItemContent: {
			props: ['tab', 'movingTab'],
			render(h) {
				return h('span', [this.tab.$slots.label || this.tab.label]);
			},
		},
	},
	provide() {
		return {
			addTab: this.addTab,
			removeTab: this.removeTab,
		};
	},
	props: {
		wizardClasses: {
			type: [String, Object, Array],
			default: null,
			description: 'Wizard card classes',
		},
		plain: {
			type: Boolean,
			default: false,
			description: 'Whether wizard should be on plain background',
		},
		showHeader: {
			type: Boolean,
			default: true,
			description: 'Whether Wizard header should be displayed',
		},
		startIndex: {
			type: Number,
			default: 0,
			description: 'Wizard start index (activated tab to start with)',
		},
		title: {
			type: String,
			default: 'Title',
			description: 'Wizard title',
		},
		subTitle: {
			type: String,
			default: 'Subtitle',
			description: 'Wizard sub title',
		},
		prevButtonText: {
			type: String,
			default: 'Previous',
			description: 'Previous button text',
		},
		nextButtonText: {
			type: String,
			default: 'Next',
			description: 'Next button text',
		},
		finishButtonText: {
			type: String,
			default: 'Finish',
			description: 'Finish button text',
		},
		vertical: {
			type: Boolean,
			description: 'Whether wizard tabs should be vertical',
		},
	},
	data() {
		return {
			tabs: [],
			activeTabIndex: 0,
			tabLinkWidth: 0,
			tabLinkHeight: 50,
			wizardId: randomString(),
		};
	},
	computed: {
		tabCount() {
			return this.tabs.length;
		},
		linkWidth() {
			let width = 100;
			if (this.tabCount > 0) {
				width = 100 / this.tabCount;
			}
			if (this.vertical) {
				width = 100;
			}
			return { width: `${width}%` };
		},
		activeTab() {
			return this.tabs[this.activeTabIndex];
		},
		movingTabStyles() {
			let translateXValue = this.tabLinkWidth * this.activeTabIndex;
			let translateYValue = 0;
			if (this.vertical) {
				translateYValue = this.tabLinkHeight * this.activeTabIndex;
				translateXValue = 0;
			}
			const styles = {
				transform: `translate3d(${translateXValue}px, ${translateYValue}px, 0px)`,
			};
			if (this.tabLinkWidth !== 0) {
				styles.width = `${this.tabLinkWidth}px`;
			}
			return styles;
		},
	},
	watch: {
		activeTabIndex(newValue, oldValue) {
			if (newValue !== oldValue) {
				const oldTab = this.tabs[oldValue];
				const newTab = this.tabs[newValue];
				oldTab.active = false;
				newTab.active = true;

				if (!newTab.checked) {
					newTab.checked = true;
				}
				this.$emit('tab-change', oldTab, newTab);
				this.$emit('update:startIndex', newValue);
			}
		},
	},
	mounted() {
		this.activeTabIndex = this.startIndex;
		this.$nextTick(() => {
			this.tabs[this.activeTabIndex].active = true;
			this.tabs[this.activeTabIndex].checked = true;
			this.onResize();
		});
		window.addEventListener(
			'resize',
			() => {
				throttle(this.onResize, 40);
			},
			false,
		);
	},
	methods: {
		addTab(tab) {
			const index = this.$slots.default.indexOf(tab.$vnode);
			const tabTitle = tab.title || '';
			tab.tabId = `${tabTitle.replace(/ /g, '')}${index}`;
			if (!this.activeTab && index === 0) {
				tab.active = true;
				tab.checked = true;
			}
			if (this.activeTab === tab.name) {
				tab.active = true;
				tab.checked = true;
			}
			this.onResize();
			this.tabs.splice(index, 0, tab);
		},
		removeTab(tab) {
			const { tabs } = this;
			const index = tabs.indexOf(tab);
			if (index > -1) {
				tabs.splice(index, 1);
			}
		},
		validate(tab) {
			const tabToValidate = tab || this.activeTab;
			const { beforeChange } = tabToValidate;
			if (beforeChange) {
				return Promise.resolve(beforeChange())
					.then((res) => {
						this.activeTab.hasError = !res;
						return res;
					})
					.catch(() => {
						this.activeTab.hasError = true;
					});
			}
			return Promise.resolve(true);
		},
		async nextTab() {
			const isValid = await this.validate();
			if (isValid && this.activeTabIndex === this.tabCount - 1) {
				this.$emit('complete');
			}
			if (isValid && this.activeTabIndex < this.tabCount - 1) {
				this.activeTabIndex += 1;
			}
			return isValid;
		},
		prevTab() {
			this.activeTabIndex -= 1;
		},
		async navigateToTab(index) {
			if (this.tabs[index].checked) {
				// recursively validate each tab
				if (index > this.activeTabIndex) {
					const valid = await this.nextTab();
					if (valid) {
						this.navigateToTab(index);
					}
				} else {
					this.activeTabIndex = index;
				}
			}
		},
		onResize() {
			const tabLinks = document.querySelectorAll(`#${this.wizardId} .wizard-tab-link`);
			if (tabLinks.length > 0 && this.tabCount > 0) {
				const { clientWidth, clientHeight } = tabLinks[0];
				this.tabLinkWidth = clientWidth;
				this.tabLinkHeight = clientHeight;
			}
		},
	},
};
</script>

<style lang="scss">
/* Tab content animation */
.tab-content {
	display: flex; // to avoid horizontal scroll when animating
	.tab-pane {
		display: block;
		animation: fadeIn 0.5s;
		width: 100%;
	}
}

/**
    Extra niceties. Display error tabs and disable navigation unvisited tabs
   */
.wizard-navigation .nav-link {
	&.active,
	&.checked {
		cursor: pointer;
	}
}

.disabled-wizard-link {
	cursor: not-allowed;
}
</style>
