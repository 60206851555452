<template>
	<div class="home" data-cy="homePage">
		<div class="row">
			<div v-for="stats in statsCards" :key="stats.title" class="col-lg-3 col-md-6 col-sm-6">
				<stats-card
					:type="stats.type"
					:icon="stats.icon"
					:small-title="stats.title"
					:title="stats.value">
					<div slot="footer" class="stats">
						<router-link v-if="stats.filterContainerType !== null" :to="{ name: stats.link.name, params: { filterContainerType: stats.filterContainerType} }">
							<i class="nc-icon nc-bullet-list-67" />
							{{ stats.footerText }}
						</router-link>
						<router-link v-else :to="stats.link">
							<i class="nc-icon nc-bullet-list-67" />
							{{ stats.footerText }}
						</router-link>
					</div>
				</stats-card>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import StatsCard from '@/components/UIComponents/Cards/StatsCard.vue';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';

export default {
	name: 'Dashboard',
	components: {
		StatsCard,
	},

	data() {
		return {
			containers: [],
			pickupPlaces: [],
			smartBoxes: [],
			reservations: [],
			priceMaps: [],

			statsCards: {
				containers: {
					type: 'warning',
					icon: 'nc-icon nc-layout-11',
					title: 'Penguin Boxy',
					value: null,
					link: { name: 'container-list' },
					footerText: 'Přehled Penguin Boxů',
					filterContainerType: 0,
				},
				pickupPlaces: {
					type: 'danger',
					icon: 'nc-icon nc-pin-3',
					title: 'Výdejny',
					value: null,
					link: { name: 'container-list' },
					footerText: 'Přehled výdejen',
					filterContainerType: 1,
				},
				smartBoxes: {
					type: 'secondary',
					icon: 'nc-icon  nc-mobile',
					title: 'Smart Boxy',
					value: null,
					link: { name: 'container-list' },
					footerText: 'Přehled Smart Boxů',
					filterContainerType: 2,
				},
				reservations: {
					type: 'success',
					icon: 'nc-icon nc-calendar-60',
					title: 'Rezervace',
					value: null,
					link: { name: 'reservation-list' },
					footerText: 'Přehled rezervací',
				},
				priceMaps: {
					type: 'info',
					icon: 'nc-icon nc-vector',
					title: 'Cenové Mapy',
					value: null,
					link: { name: 'price-map-list' },
					footerText: 'Přehled cenových map',
				},
			},
		};
	},

	computed: {
		...mapState(appName, ['currentUser']),
	},

	async created() {
		[this.containers, this.pickupPlaces, this.smartBoxes, this.reservations, this.priceMaps] = (
			await Promise.allSettled([
				this.$service.deliveryPoint.getList({ type: 0 }, 1, 1),
				this.$service.deliveryPoint.getList({ type: 1 }, 1, 1),
				this.$service.deliveryPoint.getList({ type: 2 }, 1, 1),
				this.$service.reservation.getList(1, {}, 1),
				this.$service.priceMap.getList(undefined, 1, 1),
			])
		).map((result) => result.value);

		const statCards = ['containers', 'pickupPlaces', 'smartBoxes', 'reservations', 'priceMaps'];

		statCards.forEach((card) => {
			this.statsCards[card].value = `${this[card]?.totalCount || '-'}`;
		});
	},
};
</script>
