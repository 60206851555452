import _ from 'lodash';

import { appNameList } from '@/globalConstants';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import { getAuthDataStorageName } from '@/store/auth-actions';
import { authFailed, loggedOut } from '@/store/mutation-types';
import BaseMutations from '@/store/base-mutations';
import ReservationCollectionMutations from '@/store/reservation-collection-mutations';

import {
	setContainerParameters,
	setContainerPriceMaps,
	addPickupPlaces,
	setTemplateData,
	addContainersForSelect,
	setLastFilteredContainers,
} from './mutation-types';
// eslint-disable-next-line import/no-cycle
import { getDefaultState } from './state';

export default {
	...BaseMutations,
	...ReservationCollectionMutations,

	[authFailed](state, { error }) {
		BaseMutations[authFailed](state, { error });
		localStorage.removeItem(getAuthDataStorageName(appNameList.admin));
	},

	[loggedOut](state) {
		Object.assign(state, getDefaultState());
		BaseMutations[loggedOut](state);
	},

	[setContainerParameters](state, { knownParameters }) {
		state.containerValues = knownParameters.reduce((resultObject, parameter) => {
			resultObject[parameter.id] = parameter;
			return resultObject;
		}, {});
	},

	[setContainerPriceMaps](state, { priceMaps }) {
		state.priceMaps = priceMaps;
	},

	[addPickupPlaces](state, { pickupPlaces }) {
		state.pickupPlacesForSelect.lastPage = pickupPlaces.currentPage;
		state.pickupPlacesForSelect.pagesCount = pickupPlaces.pagesCount;
		state.pickupPlacesForSelect.entities = _.concat(state.pickupPlacesForSelect.entities, pickupPlaces.entities);
	},

	[setTemplateData](state, { templateData }) {
		state.templateData = templateData;
	},
	[addContainersForSelect](state, containers) {
		state.containersForSelect = containers;
	},
	[setLastFilteredContainers](state, containers) {
		state.lastFilteredContainers = containers;
	},
};
