<template>
	<div v-if="canDirectSale" v-loading="loading" class="row">
		<div class="col-md-6 p-0">
			<div class="card">
				<div class="d-flex mt-2">
					<div class="col">
						<form-group-input v-model.number="filter.stockId" :label="$t('eshop.stockId')" type="number" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.name" :label="$t('eshop.name')" type="text" />
					</div>
				</div>
				<div class="d-flex">
					<div class="col">
						<form-group-input v-model="filter.description" :label="$t('eshop.description')" type="text" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.category" :label="$t('eshop.category')" type="text" />
					</div>
				</div>
				<div class="d-flex">
					<div class="col">
						<form-group-input v-model="filter.brand" :label="$t('eshop.brand')" type="text" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.size" :label="$t('eshop.size')" type="text" />
					</div>
				</div>
				<div class="d-flex">
					<div class="col">
						<form-group-input :label="$t('eshop.directSale')" type="text">
							<div class="d-flex mt-1 justify-content-start">
								<yes-no-all v-model="filter.allowDirectSale" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>
					<div class="col">
						<form-group-input :label="$t('eshop.inStock')" type="text">
							<div class="d-flex mt-1 justify-content-start">
								<yes-no-all v-model="filter.inStock" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>
				</div>
				<div class="d-flex">
					<div class="col">
						<form-group-input :label="$t('eshop.inContainer')" type="text">
							<div class="d-flex mt-1 justify-content-start">
								<yes-no-all v-model="filter.inContainer" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>
					<div class="col d-flex justify-content-end input-group align-item-end">
						<p-button size="md" type="success" @click="loadData()">
							{{ $t('eshop.search') }}
						</p-button>
						<el-tooltip :content="$t('eshop.tooltips.clearFilter')" placement="top-start">
							<p-button
								class="ml-2"
								icon
								size="md"
								type="danger"
								@click="clearFilter">
								<i class="fa fa-times" />
							</p-button>
						</el-tooltip>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-6 p-0">
			<direct-sale-categories :data="queuesSize" />
		</div>
		<div class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
							<div class="col-sm-2 text-right pr-0">
								<router-link :to="{ name: 'direct-sale-product-new' }">
									<p-button type="success" data-cy="addProductBtn">
										<span class="fa fa-plus" />
										{{ $t('eshop.product') }}
									</p-button>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<el-table
							data-cy="ListMainTable"
							class="table-striped"
							:data="products"
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							row-class-name="tableRow"
							@row-click="goToEdit"
							@sort-change="sortChange">
							<el-table-column property="stockId" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.stockId')" placement="top-start">
										<span>{{ $t('eshop.stockId') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="name" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.name')" placement="top-start">
										<span>{{ $t('eshop.name') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.name" :max-length="16" />
								</template>
							</el-table-column>
							<el-table-column property="description" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.description')" placement="top-start">
										<span>{{ $t('eshop.description') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.description" :max-length="16" />
								</template>
							</el-table-column>
							<el-table-column property="category" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.category')" placement="top-start">
										<span>{{ $t('eshop.category') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.category" :max-length="16" />
								</template>
							</el-table-column>
							<el-table-column property="brand" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.brand')" placement="top-start">
										<span>{{ $t('eshop.brand') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.brand" :max-length="16" />
								</template>
							</el-table-column>
							<el-table-column property="price" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.price')" placement="top-start">
										<span>{{ $t('eshop.price') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="size" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.size')" placement="top-start">
										<span>{{ $t('eshop.size') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="imported" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.imported')" placement="top-start">
										<span>{{ $t('eshop.imported') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.imported | datetime }}
								</template>
							</el-table-column>
							<el-table-column property="allowDirectSale" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.directSale')" placement="top-start">
										<span>{{ $t('eshop.directSale') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.allowDirectSale ? $t('eshop.tooltips.yes') : $t('eshop.tooltips.no') }}
								</template>
							</el-table-column>
							<el-table-column property="directSaleQueueCount" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.directSaleQueueCount')" placement="top-start">
										<span>{{ $t('eshop.directSaleQueueCount') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column width="120" wiclass-name="td-actions">
								<template #header>
									<el-tooltip :content="$t('eshop.actions')" placement="top-start">
										<span>{{ $t('eshop.actions') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.id" :content="$t('eshop.tooltips.deleteStockItem')" placement="top-start">
										<confirm-button
											:key="scope.row.id"
											type="danger"
											size="sm"
											icon
											:confirm-message="$t('eshop.deleteStockItemMessage')"
											:message-success="$t('eshop.deleteStockItemSuccess')"
											:message-error="$t('eshop.deleteStockItemError')"
											:callback="() => handleDelete(scope.row)">
											<i class="fa fa-times" />
										</confirm-button>
									</el-tooltip>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 mt-2">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="row">
		<h2>{{ $t('eshop.userDoesntAllowDirectSale') }}</h2>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination, Tooltip as ElTooltip } from 'element-ui';

import { TrimText } from '@/components/UIComponents';
import { appName } from '@/modules/eshop/config';
import { setFilter } from '@/store/mutation-types';

import DirectSaleCategories from './DirectSaleCategories.vue';

export default {
	name: 'DirectSaleProductList',
	components: {
		DirectSaleCategories,
		TrimText,
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	data() {
		return {
			loading: false,
			page: {},
			queuesSize: [],
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			filter: {},
		};
	},
	computed: {
		...mapState(appName, ['directSaleProductFilter', 'currentUser']),
		products() {
			return this.page.entities;
		},
		canDirectSale() {
			return this.currentUser.DirectSale === 'True';
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'directSaleProductFilter', data: val });
			},
		},
	},
	async created() {
		this.filter = this.directSaleProductFilter;
		this.loadData();
	},

	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},

		async loadData(page = 1) {
			this.loading = true;
			this.queuesSize = await this.$service.stockItem.getQueuesSize();
			this.page = await this.$service.stockItem.getList(page, this.filter, this.pageSize);
			this.loading = false;
		},

		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		async handleDelete(row) {
			await this.$service.stockItem.deleteStockItem(row.id);
			this.loadData(this.page.currentPage);
		},

		clearFilter() {
			this.$store.commit(`${appName}/resetFilter`, 'directSaleProductFilter');
			this.filter = this.directSaleProductFilter;
			this.loadData();
		},

		goToEdit(row) {
			this.$router.push({ name: 'direct-sale-product-edit', params: { id: row.id } });
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
</style>
