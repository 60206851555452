<template>
	<div v-loading="loading" class="card">
		<div class="card-body">
			<h2 class="card-title">
				{{ $t('admin.photoActions') }}
			</h2>
			<el-row :gutter="20">
				<el-col :span="6">
					<label class="mr-2">{{ $t('admin.chooseDay') }}</label>
					<date-picker
						v-model="date"
						type="date"
						format="dd.MM.yyyy"
						@change="clearImages" />
				</el-col>
				<el-col :span="8">
					<label class="mr-2">{{ $t('admin.chooseTime') }}</label>
					<el-select v-model="time" @change="clearImages">
						<el-option
							v-for="hour in Array.from(Array(24).keys())"
							:key="hour"
							:value="hour"
							:label="`${hour < 10 ? '0' + hour : hour}:00-${hour < 10 ? '0' + hour : hour}:59`" />
					</el-select>
				</el-col>
				<el-col :span="8">
					<el-button
						type="primary"
						:loading="loading"
						@click="reloadImages">
						{{ $t('admin.showPhotos') }}
					</el-button>
				</el-col>
			</el-row>
			<div v-if="imagesData.length > 0" class="d-flex flex-wrap my-2">
				<div
					v-for="image in images"
					:key="image.dateTime"
					class="block"
					style="margin: 5px">
					<span class="d-block text-center">{{ image.dateTime | time }}</span>
					<el-image
						style="width: 100px; height: 100px"
						:src="`data:image/png;base64, ${image.image}`"
						:preview-src-list="pivotImagesBy(`data:image/png;base64, ${image.image}`)" />
				</div>
			</div>
			<div v-else class="my-3 text-secondary">
				{{ $t('admin.noData') }}
			</div>
			<div v-if="imagesData.length > 0" class="d-flex">
				<el-select v-model="pageSize" class="w-25" @change="pageSizeChange">
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value" />
				</el-select>
				<p-pagination
					v-model="currentPage"
					class="mt-1"
					layout="prev,pager,next"
					:page-count="pageCount"
					@input="imagesPerPage" />
			</div>
		</div>
	</div>
</template>

<script>
import { Image as ElImage, DatePicker, Select as ElSelect, Option as ElOption } from 'element-ui';
import { concat } from 'lodash';

import { PPagination } from '@/components/UIComponents';

export default {
	name: 'DeliveryPointGalleryCard',
	components: {
		ElImage,
		DatePicker,
		ElSelect,
		ElOption,
		PPagination,
	},
	data() {
		return {
			images: [],
			noImages: false,
			imagesData: [],
			currentPage: 1,
			loading: false,
			pageCount: null,
			previewSrcList: [],
			date: new Date(),
			time: 12,
			pageSize: 4,
			options: [
				{
					value: 4,
					label: 4,
				},
				{
					value: 8,
					label: 8,
				},
				{
					value: 12,
					label: 12,
				},
				{
					value: 16,
					label: 16,
				},
			],
		};
	},
	computed: {
		deliveryPointId() {
			return this.$route.params.id;
		},
	},
	methods: {
		async reloadImages() {
			this.loading = true;
			try {
				this.imagesData = await this.$service.deliveryPoint.getGallery(this.deliveryPointId, this.date, this.time);
				this.previewSrcList = [];
				if (this.imagesData) {
					this.imagesData.forEach((image) => {
						this.previewSrcList.push(`data:image/png;base64, ${image.image}`);
					});
				}
				await this.imagesPerPage();
			} finally {
				this.noImages = !this.images.length;
				this.loading = false;
			}
		},
		imagesPerPage() {
			this.pageCount = Math.ceil(this.previewSrcList.length / this.pageSize);
			this.images = this.imagesData.slice(this.currentPage * this.pageSize - this.pageSize, this.currentPage * this.pageSize);
		},
		pageSizeChange() {
			this.imagesPerPage();
			this.currentPage = 1;
		},
		pivotImagesBy(image) {
			const indexToSplit = this.previewSrcList.indexOf(image);
			const first = this.previewSrcList.slice(0, indexToSplit);
			const second = this.previewSrcList.slice(indexToSplit);
			return concat(second, first);
		},
		clearImages() {
			this.images = [];
		},
	},
};
</script>

<style scoped lang="scss">
el-row > el-col {
	margin-right: 10px;

	.el-input, .el-select {
		width: 150px;
	}
}
</style>
