<template>
	<div class="row">
		<div class="col-12">
			<div v-if="!boxes" class="d-flex">
				<h6 class="mt-1">
					{{ $t('logistics.selectContainers') }}
				</h6>
			</div>

			<div class="mt-2">
				<el-tree
					ref="tree"
					v-loading="loading"
					:data="states"
					:default-checked-keys="checkedKeys"
					:default-expanded-keys="expandedKeys"
					:filter-node-method="filterNode"
					:load="loadNode"
					:props="treeProps"
					:check-strictly="!onlyCheck"
					expand-on-click-node
					lazy
					node-key="nodeId"
					show-checkbox
					@check-change="handleCheckChange"
					@node-expand="nodeExpanded"
					@node-collapse="nodeCollapsed">
					<template #default="{ node }">
						<div v-if="onlyCheck" :id="`node_${node.data.nodeId}`" :ref="node.data.nodeId">
							<span>
								{{ node.data.label }}
							</span>
							<span v-if="!node.expanded && !node.isLeaf" class="openAll ml-4">
								<span @click.prevent="openAllChildren(node)" @keydown.prevent="openAllChildren(node)"> {{ $t('logistics.expandAll') }} </span>
							</span>
						</div>
						<span v-else :ref="node.data.nodeId">
							<span>
								<span v-if="node.data.type === 'PenguinBox'">
									<img alt="PB" src="/static/img/icons/pb.svg" class="penguin-box-icon">
								</span>
								<span v-else-if="node.data.type === 'Store'">
									<img alt="Genesis" src="/static/img/icons/genesis.svg" class="genesis-icon">
								</span>
								<span v-else-if="node.data.type === 'PrivateAddress'">
									<i class="fa fa-home gray" />
								</span>
								<span v-else-if="node.data.type === 'SmartKeyBox'">
									<i class="nc-icon nc-mobile gray" />
								</span>
								<span
									class="node-name"
									:class="{ 'highlight-node': hasAnyReservation(node.data), 'highlight-node-repair': highlightRepair(node.data) }">{{ node.data.label }}</span>
								<span v-if="node.data.isLeaf">
									<span :class="{ gray: !node.data.postReservationsCount }">
										<i class="fa fa-envelope" />
										{{ node.data.postReservationsCount }}
									</span>
									<span v-if="node.data.type !== 'PrivateAddress'" :class="{ gray: !node.data.expiredReservationsCount }">
										<i class="fa fa-hourglass-end" />
										{{ node.data.expiredReservationsCount }}
									</span>
									<span :class="{ gray: !node.data.reservationCount && !node.data.stockItemCount }">
										<i class="fa fa-shopping-cart" />
										{{ node.data.reservationCount + node.data.stockItemCount }}
									</span>
									<span :class="{ gray: !node.data.returnsReservationsCount }">
										<i class="fa fa-undo" />
										{{ node.data.returnsReservationsCount }}
									</span>
									<span v-if="node.data.openingHours" :key="node.data.openingHours.status">
										<span v-if="node.data.openingHours.status === 'Closed'">
											{{ $t('logistics.closed') }}
										</span>
										<span v-else-if="node.data.openingHours.openTime && !node.data.openingHours.closeTime" class="margin-left-10">
											{{ $t('logistics.open24hours') }}
										</span>
										<span v-else>
											{{ node.data.openingHours.openTime }}-{{ node.data.openingHours.closeTime }}
											<span v-if="node.data.openingHours.openTimeNext">
												{{ node.data.openingHours.openTimeNext }}-{{ node.data.openingHours.closeTimeNext }}
											</span>
										</span>
									</span>
									<span v-else-if="node.data.type !== 'Store' && node.data.batteryVoltage !== null" :key="node.data.batteryVoltage">
										<el-tooltip v-if="isBatteryDead(node.data)" :content="$t('logistics.containerOutOfBattery')" placement="top">
											<i :class="`fa fa-lg ${getBatteryHealthStatus(node.data)}`" />
										</el-tooltip>
										<i v-else :class="`fa fa-lg ${getBatteryHealthStatus(node.data)}`" />
									</span>
									<span v-if="node.data.type !== 'Store' && node.data.batteryVoltage > 0" class="ml-1">{{ node.data.batteryVoltage }}
										<span v-if="node.data.type === 'SmartKeyBox'">%</span>
										<span v-else>V</span>
									</span>
									<el-tooltip v-if="node.data.maxTimeDelta > 0" :content="$t('logistics.timeDelay')" placement="top">
										<span class="ml-1">
											<i class="fa fa-lg fa-clock" />
											<span :class="timeDelayClass(node.data.maxTimeDelta)">
												{{ node.data.maxTimeDelta | deltamin }}
											</span></span>
									</el-tooltip>
									<el-tooltip v-if="node.data.isOffline" :content="$t('logistics.containerStuck')" placement="top">
										<i class="fa fa-exclamation fa-lg text-red ml-1" type="danger" />
									</el-tooltip>
								</span>
								<span v-else>
									<span v-if="pbAndSbPossibilitiesAll">
										<span><i class="nc-icon nc-mobile ml-1 mr-1 gray smartBoxIcon" /></span>
										<img alt="PB" src="/static/img/icons/pb.svg">
										<el-tooltip :content="nodeCountToolTip(node.data.penguins.selectedCount, node.data.penguins.totalCount)" placement="top">
											<span>{{ node.data.penguins.selectedCount }}</span>
										</el-tooltip>
									</span>
									<span v-if="storePossibilitiesAll">
										<img alt="Genesis" class="ml-1 mr-1" src="/static/img/icons/genesis.svg">
										<el-tooltip :content="nodeCountToolTip(node.data.stores.selectedCount, node.data.stores.totalCount)" placement="top">
											<span>{{ node.data.stores.selectedCount }}</span>
										</el-tooltip>
									</span>
									<span v-if="addressPossibilitiesAll">
										<i class="fa fa-home ml-1 mr-1 gray" />
										<el-tooltip :content="nodeCountToolTip(node.data.addresses.selectedCount, node.data.addresses.totalCount)" placement="top">
											<span>{{ node.data.addresses.selectedCount }}</span>
										</el-tooltip>
									</span>
								</span>
								<span v-if="!node.expanded" class="btn_node ml-4">
									<span :ref="`${node.data.nodeId}-expand`" @click.prevent="openAllChildren(node.data)" @keydown.prevent="openAllChildren(node.data)">
										{{ $t('logistics.expandAll') }}
									</span>
								</span>
								<span v-if="node.expanded && !node.data.isLeaf && !treeLoading" class="btn_node ml-4">
									<span
										:ref="`${node.data.nodeId}-check_all`"
										@click.prevent.stop="openAndCheckAllChildren(node.data)"
										@keydown.prevent.stop="openAndCheckAllChildren(node.data)">
										{{ filter.onlyService ? $t('logistics.checkAllWithReservationsAndService') : $t('logistics.checkAllWithReservations') }}
									</span>
								</span>
								<span v-if="node.expanded && !node.data.isLeaf && !treeLoading" class="btn_node ml-4">
									<span
										:ref="`${node.data.nodeId}-uncheck_all`"
										@click.prevent.stop="openAndUncheckAllChildren(node.data)"
										@keydown.prevent.stop="openAndUncheckAllChildren(node.data)">
										{{ $t('logistics.uncheckAll') }}
									</span>
								</span>
							</span>
						</span>
					</template>
				</el-tree>
			</div>
		</div>
	</div>
</template>

<script>
import { Tree as ElTree, Tooltip as ElTooltip } from 'element-ui';
import { concat, differenceWith, isEmpty, pullAllWith, pullAt, startsWith } from 'lodash';

import { BATTERY_LEVELS_ICONS } from '@/util/enums';

export default {
	name: 'BaseContainersTreeView',
	components: {
		ElTree,
		ElTooltip,
	},
	props: {
		onlyCheck: {
			type: Boolean,
			default: false,
		},
		boxes: {
			type: Boolean,
			default: false,
		},
		value: {
			type: Array,
			default: () => [],
		},
		filter: {
			type: Object,
			default: () => {},
		},
		filterPromptly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			autoOpenTree: [],
			treeLoading: false,
			loading: false,
			states: [],
			regions: [],
			treeProps: {
				label: 'label',
				children: 'children',
				isLeaf: 'isLeaf',
			},
			expandedKeys: [],
			checkedKeys: [],

			addressPossibilitiesAll: true,
			storePossibilitiesAll: true,
			pbAndSbPossibilitiesAll: true,

			afterLoadingSelectAll: null,
			afterLoadingUnselectAll: null,
		};
	},
	watch: {
		// In the Vue 2 watch is the old and new value ALWAYS wrongly the same,
		// even if a some property in the value has changed.
		'filter.pbAndSbPossibilities': {
			deep: true,
			handler: 'filterHandler',
		},
		'filter.storePossibilities': {
			deep: true,
			handler: 'filterHandler',
		},
		'filter.addressPossibilities': {
			deep: true,
			handler: 'filterHandler',
		},
		'filter.onlyWithReservations': {
			deep: true,
			handler: 'filterHandler',
		},
		// For filter changes without the tree completely reloading.
		'filter.onlyService': {
			handler(val) {
				this.states.forEach((state) => {
					const allNodes = this.getAllNodes(this.$refs.tree.getNode(state.nodeId), val, true);

					allNodes.forEach((nodeForSelecting) => {
						if (!this.hasAnyReservation(nodeForSelecting) && (this.isBatteryDead(nodeForSelecting) || nodeForSelecting.isOffline)) {
							if (val) {
								this.addToSelected(nodeForSelecting, nodeForSelecting.nodeId);
							} else {
								this.removeFromSelected(nodeForSelecting, nodeForSelecting.nodeId);
							}
						}
					});
				});
			},
		},
		treeLoading(value) {
			if (!value) {
				if (this.afterLoadingSelectAll) {
					setTimeout(() => {
						if (!this.treeLoading && this.afterLoadingSelectAll) {
							this.setCheckingAllChildren(this.afterLoadingSelectAll, true);
							this.afterLoadingSelectAll = null;
						}
					}, 500);
				}

				if (this.afterLoadingUnselectAll) {
					setTimeout(() => {
						if (!this.treeLoading && this.afterLoadingUnselectAll) {
							this.setCheckingAllChildren(this.afterLoadingUnselectAll, false);
							this.afterLoadingUnselectAll = null;
						}
					}, 500);
				}

				const containers = this.$refs.tree.getCheckedNodes(true);
				this.$emit(
					'containers',
					containers.map((c) => c.containerId),
				);
			}

			this.$emit('tree-loading', !!value);
		},
	},
	async created() {
		await this.init();
	},
	methods: {
		getBatteryHealthStatus(nodeData) {
			const batteryStatus = Object.values(BATTERY_LEVELS_ICONS).find((batteryLevelData) => {
				if (nodeData.type === 'SmartKeyBox') {
					return nodeData.batteryVoltage >= batteryLevelData.levelPercentage;
				}

				return nodeData.batteryVoltage >= batteryLevelData.levelVoltage;
			});

			return batteryStatus?.icon || null;
		},
		isBatteryDead(nodeData) {
			if (nodeData.type === 'SmartKeyBox') {
				return nodeData.batteryVoltage < 25;
			}
			return nodeData.batteryVoltage < 12;
		},
		async filterHandler() {
			if (!this.loading && this.filterPromptly) {
				this.$emit('watching', true);

				await this.init();

				this.$emit('watching', false);
			}
		},
		timeDelayClass(value) {
			if (value) {
				if (value > 15) { return 'ml-1 text-danger'; }
				if (value > 1) { return 'ml-1 text-warning'; }
			}
			return 'ml-1 success';
		},
		setFilter(value) {
			if (value) {
				const { pbAndSbPossibilities, addressPossibilities, storePossibilities, onlyService } = value;

				if (pbAndSbPossibilities) {
					const { post, delivery, returns, expirations, directDelivery } = pbAndSbPossibilities;

					this.pbAndSbPossibilitiesAll = post || delivery || returns || expirations || directDelivery || onlyService;
				} else {
					this.pbAndSbPossibilitiesAll = false;
				}

				if (storePossibilities) {
					const { post, delivery, returns, expirations } = storePossibilities;

					this.storePossibilitiesAll = post || delivery || returns || expirations;
				} else {
					this.storePossibilitiesAll = false;
				}

				if (addressPossibilities) {
					const { post, delivery, returns } = addressPossibilities;

					this.addressPossibilitiesAll = post || delivery || returns;
				} else {
					this.addressPossibilitiesAll = false;
				}
			}
		},
		filterNode(value, data, node) {
			if (!isEmpty(this.filter)) {
				if (this.boxes) {
					let filter = true;

					if (this.filter.onlyEmpty && data.isLeaf) {
						filter = !data.occupiedBy;
					}

					if (this.filter.deliveryPoint.serialNumber.from && data.isLeaf && data.container?.serialNumber) {
						filter = filter && data.container.serialNumber.toString().toLowerCase() === this.filter.deliveryPoint.serialNumber.from.toLowerCase();
					}

					if ((this.filter.deliveryPoint.city || this.filter.deliveryPoint.street) && data.container && data.isLeaf) {
						const { city, street, location, containerId, region, postCode, state } = data.container;
						const containerName = `${city}_${street}_${location}_${containerId}_${region}_${postCode}_${street}_${state}`.toLowerCase();

						if (this.filter.deliveryPoint.city) {
							filter = filter && containerName.indexOf(this.filter.deliveryPoint.city?.toLowerCase()) !== -1;
						}

						if (this.filter.deliveryPoint.street) {
							filter = filter && containerName.indexOf(this.filter.deliveryPoint.street?.toLowerCase()) !== -1;
						}
					}

					if (
						!data.isLeaf
						&& (this.filter.onlyEmpty || this.filter.deliveryPoint.serialNumber.from || this.filter.deliveryPoint.city || this.filter.deliveryPoint.street)
						&& (this.filter.onlyEmpty || this.filter.deliveryPoint.serialNumber.from || this.filter.deliveryPoint.city || this.filter.deliveryPoint.street)
					) {
						filter = filter && !node.loaded && !!node.childNodes.find((x) => this.filterNode(value, x.data, x));
					}

					return filter;
				}

				return !value || !data.isLeaf || data.reservationCount + data.stockItemCount > 0;
			}

			return true;
		},
		goToContainer(data) {
			const { boxId, container } = data;
			const { region, county, city, containerId } = this.boxes ? container : data;

			if (!containerId) {
				return;
			}

			this.expandContainerNode(this.boxes ? container : data, boxId);

			// the next code is because the tree loading data and animation are async outside the expandContainerNode
			const timeInterval = 100;
			const defaultStopTime = 10000;
			let stopTime = defaultStopTime;
			let currentTime = 0;

			const tickerHandleScrollTo = setInterval(() => {
				let refNode;
				if (this.boxes) {
					refNode = this.$refs[`${region}>${county}>${city}>${containerId}>${boxId}`];
				} else {
					const { routeStopId } = data || {};

					refNode = this.$refs[`${region}>${county}>${city}>r${routeStopId}`];
					refNode = refNode ?? this.$refs[`${region}>${county}>${city}>${routeStopId}`];
				}

				if (refNode) {
					if (stopTime === defaultStopTime) {
						const { classList } = refNode.parentNode;

						stopTime = currentTime + timeInterval * 10; // because of animation of the last branch
						classList.add('selected-box');
						setTimeout(() => classList.remove('selected-box'), 2000);
					}
					refNode.scrollIntoView();
				}

				currentTime += timeInterval;

				if (currentTime === stopTime) {
					clearInterval(tickerHandleScrollTo);
				}
			}, timeInterval);
		},
		async loadNode(node, resolve) {
			this.treeLoading += 1;

			if (node.data.loadChildren) {
				const children = await node.data.loadChildren();
				node.data.children = children.map((child) => child.nodeId);

				const result = resolve(children);
				this.nodeExpanded(node.data);
				this.treeLoading -= 1;
				return result;
			}

			this.treeLoading -= 1;
			return resolve([]);
		},
		sortItems(items) {
			return items.sort((itemA, itemB) => itemA.label.localeCompare(itemB.label));
		},
		async expandRegion(state) {
			this.treeLoading += 1;
			const regions = await this.$service.containerTreeView.getRegionList(state);
			const result = regions.map((value) => {
				if (this.onlyCheck) {
					return {
						disabled: this.boxes,
						label: value || this.$t('logistics.notAssigned'),
						nodeId: `${state}>${value}`,
						loadChildren: () => this.expandCounty(state, value),
					};
				}

				return {
					label: value.location || this.$t('logistics.notAssigned'),
					reservationCount: value.reservationCount,
					nodeId: `${state}>${value.location}`,
					addresses: value.addresses,
					penguins: value.penguins,
					disabled: true,
					stores: value.stores,
					loadChildren: () => this.expandCounty(state, value.location),
				};
			});

			if (!this.onlyCheck) {
				this.count(result);
			}

			this.treeLoading -= 1;
			this.regions = this.regions.concat(
				...regions.map((region) => ({
					nodeId: `${state}>${this.onlyCheck ? region : region.location}`,
					region: this.onlyCheck ? region : region.location,
					state,
				})),
			);
			return this.sortItems(result);
		},
		async expandCounty(state, region) {
			this.treeLoading += 1;
			const counties = await this.$service.containerTreeView.getCountyList(state, region);
			const result = counties.map((value) => {
				if (this.onlyCheck) {
					return {
						label: value,
						nodeId: `${region}>${value}`,
						disabled: this.boxes,
						loadChildren: () => this.expandCity(state, region, value),
					};
				}

				return {
					label: value.location || this.$t('logistics.notAssigned'),
					reservationCount: value.reservationCount,
					nodeId: `${region}>${value.location}`,
					addresses: value.addresses,
					penguins: value.penguins,
					disabled: true,
					stores: value.stores,
					loadChildren: () => this.expandCity(state, region, value.location),
				};
			});

			if (!this.onlyCheck) {
				this.count(result);
			}

			this.treeLoading -= 1;
			return this.sortItems(result);
		},
		async expandCity(state, region, county) {
			this.treeLoading += 1;
			const cities = await this.$service.containerTreeView.getCityList(state, region, county);
			const result = cities.map((value) => {
				if (this.onlyCheck) {
					return {
						label: value,
						nodeId: `${region}>${county}>${value}`,
						disabled: this.boxes,
						loadChildren: () => this.expandContainer(region, county, value),
					};
				}

				return {
					label: value.location || this.$t('logistics.notAssigned'),
					reservationCount: value.reservationCount,
					nodeId: `${region}>${county}>${value.location}`,
					addresses: value.addresses,
					penguins: value.penguins,
					stores: value.stores,
					disabled: true,
					loadChildren: () => this.expandContainer(region, county, value.location),
				};
			});

			if (!this.onlyCheck) {
				this.count(result);
			}

			this.treeLoading -= 1;
			return this.sortItems(result);
		},
		createContainerData(filterPossibilities, data, type, region, county, city) {
			if (!data) {
				return [];
			}

			const { post, delivery, returns, expirations, directDelivery } = filterPossibilities;

			return data.map((value) => {
				const {
					street,
					address,
					containerId,
					reservationIds,
					reservationsCount,
					returnsReservationsCount,
					stockItemsCount,
					postReservationsCount,
					serialNumber,
					expiredReservationsCount,
					batteryVoltage,
					maxTimeDelta,
					isOffline,
					openingHours,
				} = value;

				let containerData = {
					postReservationsCount: post ? postReservationsCount : 0,
					reservationCount: delivery ? reservationsCount : 0,
					returnsReservationsCount: returns ? returnsReservationsCount : 0,
					isLeaf: true,
					disabled: this.boxes,
				};

				if (type === 'PenguinBox' || type === 'Store' || type === 'SmartKeyBox') {
					containerData = {
						...containerData,
						label: `${street}, ${this.$t('logistics.serialNumberPrefix')}${serialNumber}`,
						container: value,
						expiredReservationsCount: expirations ? expiredReservationsCount : 0,
						batteryVoltage,
						maxTimeDelta,
						isOffline,
						openingHours,
						routeStopId: containerId,
						containerId,
						nodeId: `${region}>${county}>${city}>${containerId}`,
						type: value.type,
						stockItemCount: (directDelivery && (type === 'PenguinBox' || type === 'SmartKeyBox')) || type === 'Store' ? stockItemsCount : 0,
					};
				} else {
					containerData = {
						...containerData,
						label: address,
						container: { ...value, street: address, location: '' },
						reservationId: reservationIds.join(','),
						routeStopId: `r${reservationIds}`,
						containerId: null,
						nodeId: `${region}>${county}>${city}>r${reservationIds}`,
						type,
						stockItemCount: 0,
					};
				}

				return containerData;
			});
		},
		expandContainerNode(container, boxId) {
			const { region, county, city, routeStopId, containerId } = container;

			this.regions.forEach((regionItem) => {
				if (regionItem.region === region) {
					this.pushIfDoesntExist(this.expandedKeys, regionItem.nodeId);
				}
			});
			this.pushIfDoesntExist(this.expandedKeys, `${region}>${county}`);
			this.pushIfDoesntExist(this.expandedKeys, `${region}>${county}>${city}`);

			if (this.boxes) {
				this.pushIfDoesntExist(this.expandedKeys, `${region}>${county}>${city}>${containerId}`);

				if (boxId) {
					this.pushIfDoesntExist(this.checkedKeys, `${region}>${county}>${city}>${containerId}>${boxId}`);
				}
			} else {
				this.pushIfDoesntExist(this.checkedKeys, `${region}>${county}>${city}>${routeStopId}`);
			}
		},
		async expandContainer(region, county, city) {
			this.treeLoading += 1;
			let containers = [];

			if (this.boxes) {
				containers = await this.$service.containerTreeView.getContainerTree(region, county, city);
				containers = containers.map((value) => ({
					label: `${value.street}, ${this.$t('logistics.serialNumberPrefix')}${value.serialNumber}`,
					containerId: value.containerId,
					nodeId: `${region}>${county}>${city}>${value.containerId}`,
					disabled: this.onlyCheck,
					loadChildren: () => this.expandBoxes(region, county, city, value),
				}));
			} else if (this.onlyCheck) {
				containers = await this.$service.containerTreeView.getContainerTree(region, county, city);
				containers = containers.map((value) => ({
					label: `${value.street}, ${this.$t('logistics.serialNumberPrefix')}${value.serialNumber}`,
					containerId: value.containerId,
					nodeId: `${region}>${county}>${city}>${value.containerId}`,
					isLeaf: true,
				}));
			} else {
				if (this.pbAndSbPossibilitiesAll || this.storePossibilitiesAll) {
					const response = await this.$service.containerTreeView.getContainerTree(region, county, city);

					if (this.pbAndSbPossibilitiesAll) {
						containers = containers.concat(
							this.createContainerData(
								{ ...this.filter.pbAndSbPossibilities, service: this.filter?.onlyService },
								response.filter((container) => container.type === 'PenguinBox'),
								'PenguinBox',
								region,
								county,
								city,
							),
						);

						containers = containers.concat(
							this.createContainerData(
								{ ...this.filter.pbAndSbPossibilities, service: this.filter?.onlyService },
								response.filter((container) => container.type === 'SmartKeyBox'),
								'SmartKeyBox',
								region,
								county,
								city,
							),
						);
					}

					if (this.storePossibilitiesAll) {
						containers = containers.concat(
							this.createContainerData(
								this.filter.storePossibilities,
								response.filter((container) => container.type === 'Store'),
								'Store',
								region,
								county,
								city,
							),
						);
					}
				}

				if (this.addressPossibilitiesAll) {
					const response = await this.$service.containerTreeView.getPrivateAddressTree(region, county, city);

					containers = containers.concat(this.createContainerData(this.filter.addressPossibilities, response, 'PrivateAddress', region, county, city));
				}
			}

			this.treeLoading -= 1;

			return this.sortItems(containers);
		},
		expandBoxes(region, county, city, container) {
			return container.boxes.map((box) => ({
				label: `${this.$t('logistics.serialNumberPrefix')} ${box.boxSN} ${box.references ? box.references.name : ''}`,
				boxId: box.boxId,
				boxSn: box.boxSN,
				container,
				occupiedBy: box.references || '',
				stockItem: box.references || '',
				nodeId: `${region}>${county}>${city}>${container.containerId}>${box.boxId}`,
				isLeaf: true,
			}));
		},
		handleCheckChange(data, checked) {
			if (this.onlyCheck && !this.boxes) {
				const containers = this.$refs.tree.getCheckedNodes(true);
				this.$emit(
					'containers',
					containers.map((c) => c.containerId),
				);
				this.autoOpenTree.push(data.nodeId);

				if (checked) {
					this.pushIfDoesntExist(this.expandedKeys, data.nodeId);
				}
			} else if (data.isLeaf) {
				if (checked) {
					this.addToSelected(data);
				} else {
					this.removeFromSelected(data);
				}
			} else if (!this.boxes && !checked) {
				this.setCheckedLeavesToSelected(false);
			}
		},
		openAllChildren(node) {
			this.autoOpenTree = [];
			this.autoOpenTree.push(this.onlyCheck ? node.data.nodeId : node.nodeId);
		},
		openAndUncheckAllChildren(node) {
			this.openAllChildren(node);
			this.afterLoadingUnselectAll = node;
			this.nodeExpanded(node);

			if (!this.treeLoading) {
				this.setCheckingAllChildren(node, false);
			}
		},
		openAndCheckAllChildren(node) {
			this.openAllChildren(node);
			this.afterLoadingSelectAll = node;
			this.nodeExpanded(node);

			if (!this.treeLoading) {
				this.setCheckingAllChildren(node, true);
			}
		},
		setCheckingAllChildren(node, checked) {
			const nodesWithReservations = this.getAllNodes(this.$refs.tree.getNode(node.nodeId), checked);
			let serviceNodes = [];
			if (this.filter?.onlyService) {
				serviceNodes = this.getAllNodes(this.$refs.tree.getNode(node.nodeId), checked, true);
			}
			const allNodes = nodesWithReservations.concat(serviceNodes);
			allNodes.forEach((nodeForSelecting) => {
				this.$refs.tree.setChecked(nodeForSelecting.nodeId, checked, false);
			});
		},
		getAllNodes(node, checkKindData = true, checkOnlyWithDeadBattery = false) {
			let nodes = [];

			node.childNodes.forEach((kind) => {
				const { postReservationsCount, reservationCount, returnsReservationsCount, stockItemCount, expiredReservationsCount } = kind.data;

				if (
					kind.isLeaf
					&& ((checkOnlyWithDeadBattery && (this.isBatteryDead(kind.data) || kind.data.isOffline))
						|| (!checkOnlyWithDeadBattery
							&& (!checkKindData
								|| (checkKindData && (postReservationsCount || reservationCount || returnsReservationsCount || stockItemCount || expiredReservationsCount)))))
				) {
					nodes.push(kind.data);
				} else {
					nodes = nodes.concat(this.getAllNodes(kind, checkKindData, checkOnlyWithDeadBattery));
				}
			});

			return nodes;
		},
		nodeExpanded(node) {
			const index = this.autoOpenTree.indexOf(node.nodeId);
			if (index >= 0 && !!node.children) {
				pullAt(this.autoOpenTree, index);
				this.autoOpenTree = concat(this.autoOpenTree, node.children);
				node.children.forEach((child) => this.pushIfDoesntExist(this.expandedKeys, child));
			}

			if (!this.onlyCheck || (this.onlyCheck && this.boxes)) {
				if (!this.$refs.tree) {
					return;
				}

				this.$refs.tree.filter(this.boxes ? this.filter?.onlyEmpty : this.filter?.onlyWithReservations);

				this.pushIfDoesntExist(this.expandedKeys, node.nodeId);

				if (this.value.length) {
					this.value.forEach((selectedItem) => {
						const { container, boxId } = selectedItem;
						const { region, county, city, containerId } = this.boxes ? container : selectedItem;
						const selectedItemNodeId = this.boxes ? `${region}>${county}>${city}>${containerId}` : `${region}>${county}>${city}`;

						if (this.expandedKeys.indexOf(selectedItemNodeId) !== -1) {
							if (!this.boxes && this.onlyCheck) {
								this.expandContainerNode(selectedItem);
							}

							this.pushIfDoesntExist(
								this.checkedKeys,
								this.boxes ? `${region}>${county}>${city}>${containerId}>${boxId}` : `${region}>${county}>${city}>${containerId}`,
							);
						}
					});
				}
			}
		},
		nodeCollapsed(node) {
			pullAllWith(this.expandedKeys, node.nodeId, startsWith);
		},
		setCheckedLeavesToSelected(addToSelected) {
			const checkedLeaves = this.$refs.tree.getCheckedNodes().filter((node) => node.isLeaf);
			const selectionDifference = differenceWith(checkedLeaves, this.value, (arrVal, othVal) => arrVal.routeStopId === othVal.routeStopId);
			selectionDifference.forEach((container) => (addToSelected ? this.addToSelected(container) : this.removeFromSelected(container)));
		},
		makeAddToSelected(data, nodeId) {
			if (this.boxes) {
				const box = data;
				this.$emit('add', data, () => {
					this.$refs.tree.setChecked(
						`${box.container.region}>${box.container.county}>${box.container.city}>${box.container.containerId}>${box.boxId}`,
						true,
						false,
					);
				});
			} else {
				const { routeStopId, region, city, county } = data;
				this.$refs.tree.setChecked(nodeId || `${region}>${county}>${city}>${routeStopId}`, true, false);
			}
		},
		addToSelected(data, nodeId) {
			if (this.boxes) {
				this.$emit('add', data, this.makeAddToSelected);
			} else {
				this.$emit('add', data, nodeId, this.makeAddToSelected);
			}
		},
		makeRemoveFromSelected(data, nodeId) {
			if (this.boxes) {
				const box = data;
				this.$refs.tree.setChecked(
					`${box.container.region}>${box.container.county}>${box.container.city}>${box.container.containerId}>${box.boxId}`,
					false,
					false,
				);
			} else {
				const { routeStopId, region, city, county } = data;
				this.$refs.tree.setChecked(nodeId || `${region}>${county}>${city}>${routeStopId}`, false, false);
			}
		},
		removeFromSelected(data, nodeId) {
			if (this.boxes) {
				this.$emit('remove', data, this.makeRemoveFromSelected);
			} else {
				this.$emit('remove', data, nodeId, this.makeRemoveFromSelected);
			}
		},
		expandFirstContainer() {
			if (this.value.length) {
				const box = this.value[0];
				const { container, boxId } = box;

				this.expandContainerNode(container, boxId);
				this.goToContainer(box);
			}
			this.loading = false;
		},
		pushIfDoesntExist(array, value) {
			if (array.indexOf(value) === -1) {
				array.push(value || 'null');
			}
		},

		async init() {
			this.loading = true;
			this.treeLoading += 1;
			this.setFilter(this.filter);
			const states = await this.$service.containerTreeView.getStateList();

			const unsortedStates = states.map((value) => {
				if (this.onlyCheck) {
					return {
						label: value || this.$t('logistics.notAssigned'),
						nodeId: value || 'null',
						disabled: this.boxes,
						loadChildren: () => this.expandRegion(value),
					};
				}

				const { location, reservationCount, addresses, penguins, stores } = value;

				return {
					label: location || this.$t('logistics.notAssigned'),
					nodeId: location || 'null',
					reservationCount,
					addresses,
					penguins,
					stores,
					disabled: true,
					loadChildren: () => this.expandRegion(location),
				};
			});

			this.states = this.sortItems(unsortedStates);

			if (!this.onlyCheck) {
				this.count(this.states);
			}

			this.states.forEach((state) => this.pushIfDoesntExist(this.expandedKeys, state.nodeId));

			this.treeLoading -= 1;

			if (this.boxes && this.value && this.value.length > 0) {
				this.expandFirstContainer();
			} else {
				this.loading = false;
			}
		},

		count(data) {
			const { pbAndSbPossibilities, storePossibilities, addressPossibilities, onlyService } = this.filter;

			data.forEach((item) => {
				const { penguins, stores, addresses } = item;

				penguins.selectedCount = this.selectedCount(penguins, { ...pbAndSbPossibilities, service: onlyService });
				penguins.totalCount = this.totalCount(penguins);

				stores.selectedCount = this.selectedCount(stores, storePossibilities);
				stores.totalCount = this.totalCount(stores);

				addresses.selectedCount = this.selectedCount(addresses, addressPossibilities);
				addresses.totalCount = this.totalCount(addresses);
			});
		},
		totalCount({ deliveryCount, returnsCount, deliveryDirectSaleCount, receiveDirectSaleCount, deliveryPostCount, receivePostCount }) {
			return deliveryCount
				+ returnsCount
				+ deliveryDirectSaleCount
				+ receiveDirectSaleCount
				+ deliveryPostCount
				+ receivePostCount;
		},
		selectedCount(data, possibilities) {
			const { deliveryCount, returnsCount, deliveryDirectSaleCount, receiveDirectSaleCount, deliveryPostCount, receivePostCount } = data;
			let selectedCount = 0;

			if (possibilities.post) {
				selectedCount += deliveryPostCount + receivePostCount;
			}
			if (possibilities.delivery) {
				selectedCount += deliveryCount;
			}
			if (possibilities.returns) {
				selectedCount += returnsCount;
			}
			if (possibilities.directDelivery) {
				selectedCount += deliveryDirectSaleCount + receiveDirectSaleCount;
			}

			return selectedCount;
		},
		hasAnyReservation(data) {
			return (
				data.penguins?.selectedCount
				|| data.stores?.selectedCount
				|| data.addresses?.selectedCount
				|| data.postReservationsCount
				|| data.reservationCount
				|| data.returnsReservationsCount
				|| data.stockItemCount
				|| data.expiredReservationsCount
			);
		},
		highlightRepair(node) {
			return (this.isBatteryDead(node) || node.isOffline);
		},
		nodeCountToolTip(count, total) {
			return `${count}/${total}`;
		},
		makeFilter(val) {
			this.$refs.tree.filter(val);
		},
	},
};
</script>

<style lang="scss" scoped>
.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.column {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.el-tree-node__content:hover .openAll {
	display: inline;
	font-size: 10px;
}

.openAll {
	display: none;
}

.highlight-node {
	font-weight: bold;
	color: #009933;
}
.highlight-node-repair:not(.highlight-node) {
	font-weight: bold;
	color: #ffb100;
}

.tree-container {
	height: 35rem;
	overflow: auto;
	border-top: 1px solid silver;
}
::v-deep {
	.table-row-box {
		cursor: pointer;
	}

	.selected-box {
		animation: selected-box-animation 1s linear 1 1s;
	}

	@keyframes selected-box-animation {
		from {
			background: red;
			color: white;
		}
		to {
			color: initial;
			background: initial;
		}
	}
}

.gray {
	color: #606266;
}

.el-tree-node__content:hover {
	.btn_node {
		display: inline;
		font-size: 10px;
	}
}

.btn_node {
	display: none;
}

.number_cycle {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	padding: 4px 10px;
	display: inline;
	background: #eb6532;
	color: #ffffff;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
}
.btn-sm-filter {
	padding: 5px 15px;
}
.smartBoxIcon {
	font-size: 0.93em;
}
.text-red {
	color: red;
}
.node-name {
	margin-right: 7px;
	margin-left: 5px;
}
.penguin-box-icon {
	transform: translateX(1px);
}
.genesis-icon {
	transform: translateX(-2.5px);
}
.warning{
	color: #E6A23C;
}
.error{
	color: #F56C6C;
}
.margin-left-10{
	margin-left: 10px;
}
</style>
