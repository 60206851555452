export const upperToCamel = (value) => {
	if (value) {
		return value.toLowerCase().replace(/(?:(^[a-z])|_([a-zA-Z]))/g, (k) => k[k.length - 1].toUpperCase());
	}
	return value;
};

export const camelToUpper = (value) => {
	if (value) {
		return value.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();
	}
	return value;
};

export const BATTERY_LEVELS_ICONS = {
	full: {
		icon: 'fa-battery-full text-success',
		levelVoltage: 12,
		levelPercentage: 50,
	},
	attention: {
		icon: 'fa-battery-three-quarters battery-three-quarters-color',
		levelVoltage: 11,
		levelPercentage: 25,
	},
	half: {
		icon: 'fa-battery-half text-warning',
		levelVoltage: 10,
		levelPercentage: 10,
	},
	empty: {
		icon: 'fa-battery-empty text-danger',
		levelVoltage: 0,
		levelPercentage: 0,
	},
};
