import { stringify } from 'qs';
import { saveAs } from 'file-saver';
import { isNil, omitBy } from 'lodash';

export const StockItemService = (http) => ({
	async getList(page, filter, pageSize = 50) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};

		const response = await http.get('/api/StockItem/List', config);
		return response.data;
	},

	async getCategories() {
		const response = await http.get('/api/stockItem/categories');
		return response.data;
	},

	async getQueuesSize() {
		const response = await http.get('/api/stockItem/QueuesSize');
		return response.data;
	},

	async getOne(Id) {
		const response = await http.get('/api/StockItem/Detail', { params: { Id } });
		return response.data;
	},

	async addStockItem(stockItem) {
		const response = await http.post('/api/StockItem/Add', stockItem);

		return response.data;
	},

	async deleteStockItem(Id) {
		const response = await http.delete(`/api/StockItem/${Id}`);

		return response.data;
	},

	async updateStockItem(stockItem) {
		const response = await http.post('/api/StockItem/Update', stockItem);

		return response.data;
	},

	async getImagesList(stockItemId) {
		const response = await http.get(`/api/StockItem/${stockItemId}/Images/List`);

		return response.data;
	},

	async getImage(stockItemId, imageId) {
		const response = await http.get(`/api/StockItem/${stockItemId}/Images`, { params: { imageId } });

		return response.data;
	},

	async deleteImage(stockItemId, imageId) {
		const response = await http.delete(`/api/StockItem/${stockItemId}/Images`, { params: { imageId } });

		return response.data;
	},

	async exportData(from, to, containerIds) {
		const response = await http.get('/api/StockItem/Sold/csv', { params: { from, to, containerIds }, paramsSerializer: stringify });
		// Log somewhat to show that the browser actually exposes the custom HTTP header
		const filename = 'export.csv';
		const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
		// Let the user save the file.
		saveAs(blob, filename, { autoBom: true });
	},
});
