import {
	Select as ElSelect, Option as ElOption, Table as ElTable,
	TableColumn as ElTableColumn, Tooltip as ElTooltip,
	Pagination as ElPagination, Button as ElButton, ButtonGroup as ElButtonGroup, RadioButton as ElRadioButton,
	RadioGroup as ElRadioGroup, FormItem as ElFormItem, Form as ElForm, Input as ElInput,
} from 'element-ui';

import { PRadio, PCheckbox, FormGroupInput, PButton, Dropdown, Card, YesNoAll, Autocomplete, ConfirmButton, EasyTooltip } from 'src/components/UIComponents';

const GlobalComponents = {
	install(Vue) {
		Vue.component(FormGroupInput.name, FormGroupInput);
		Vue.component(Dropdown.name, Dropdown);
		Vue.component(PCheckbox.name, PCheckbox);
		Vue.component(PRadio.name, PRadio);
		Vue.component(PButton.name, PButton);
		Vue.component(Card.name, Card);
		Vue.component(YesNoAll.name, YesNoAll);
		Vue.component(Autocomplete.name, Autocomplete);
		Vue.component(ConfirmButton.name, ConfirmButton);
		Vue.component(ElSelect.name, ElSelect);
		Vue.component(ElOption.name, ElOption);
		Vue.component(ElTable.name, ElTable);
		Vue.component(ElTableColumn.name, ElTableColumn);
		Vue.component(ElTooltip.name, ElTooltip);
		Vue.component(ElPagination.name, ElPagination);
		Vue.component(EasyTooltip.name, EasyTooltip);
		Vue.component(ElButton.name, ElButton);
		Vue.component(ElButtonGroup.name, ElButtonGroup);
		Vue.component(ElRadioButton.name, ElRadioButton);
		Vue.component(ElRadioGroup.name, ElRadioGroup);
		Vue.component(ElForm.name, ElForm);
		Vue.component(ElInput.name, ElInput);
		Vue.component(ElFormItem.name, ElFormItem);
	},
};

export default GlobalComponents;
