<template>
	<div class="login-page">
		<div class="wrapper wrapper-full-page">
			<div class="full-page login-page section-image">
				<div class="content">
					<div class="container">
						<div class="col-lg-4 col-md-6 ml-auto mr-auto">
							<card type="login">
								<router-view />
							</card>
						</div>
					</div>
				</div>
				<div class="full-page-background" style="background-image: url(/static/img/background/gradient.svg)" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoggedOutLayout',
};
</script>
