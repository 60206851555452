import { batteryThresholds, smartBatteryThresholds, containerTypes } from '@/globalConstants';

export default {
	methods: {
		getPrivateCustomerAddress(container) {
			return `${container.street}, ${container.city} ${container.psc}`;
		},
		hasPrivateAddressReservation(container) {
			return container.reservations?.some((reservation) => reservation.isPrivateAddress);
		},
		showBatteryAlert(container) {
			if (container.containerType === containerTypes.store) {
				return false;
			}
			if (container.containerType === containerTypes.penguinBox) {
				return !this.hasPrivateAddressReservation(container) && (container.isOffline || container.batteryVoltage < batteryThresholds.runningLow);
			}
			if (container.containerType === containerTypes.smartBox) {
				return container.isOffline || container.batteryVoltage < smartBatteryThresholds.warning;
			}

			return false;
		},
	},
};
