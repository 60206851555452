<template>
	<base-report-print-sheet-table class="mb-4">
		<tr>
			<th>SKU</th>
			<th>{{ $t('global.storageDispensation') }}:</th>
			<th>{{ $t('global.totalCount') }}:</th>
		</tr>
		<tr v-for="(stockItem, key) in stockItemsCount" :key="key">
			<td>{{ key }}</td>
			<td v-if="currentAppName === appNameList.driver">
				PB {{ stockItem.containerSn }} - {{ stockItem.containerLocation }}
			</td>
			<td v-else>
				{{ stockItem.name }}
			</td>
			<td class="text-right">
				{{ stockItem.amount }} {{ $t('global.piece') }}
			</td>
		</tr>
		<tr>
			<td />
			<td>{{ $t('global.stockItemsTotal') }}</td>
			<td class="text-right">
				{{ totalStockItems }} {{ $t('global.piece') }}
			</td>
		</tr>
		<tr>
			<td />
			<td>{{ $t('global.packagesForDelivery') }}</td>
			<td class="text-right">
				{{ totalForDelivery }} {{ $t('global.piece') }}
			</td>
		</tr>
		<tr>
			<td />
			<td>{{ $t('global.packagesForCollection') }}</td>
			<td class="text-right">
				{{ totalForCollection }} {{ $t('global.piece') }}
			</td>
		</tr>
	</base-report-print-sheet-table>
</template>

<script>
import { appNameList } from '@/globalConstants';
import BaseReportPrintSheetTable from '@/components/Base/Report/BaseReportPrintSheetTable.vue';

export default {
	name: 'BaseSummaryInfo',
	components: {
		BaseReportPrintSheetTable,
	},
	props: {
		stockItemsCount: {
			type: Object,
			default: () => {},
		},
		totalForDelivery: {
			type: Number,
			default: 0,
		},
		totalForCollection: {
			type: Number,
			default: 0,
		},
		totalStockItems: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			appNameList,
		};
	},
};
</script>

<style lang="scss" scoped>
tbody {
	border-bottom: 3px solid black;
}
</style>
