import { QrCodeService } from '@/service/qr-code-service';

import { AdminService } from './admin-service';
import { BoxService } from './box-service';
import { ContainerService } from './container-service';
import { ContainerTreeViewService } from './container-tree-view-service';
import { DeliveryPointService } from './delivery-point-service';
import { EshopOrderService } from './eshop-order-service';
import { EshopService } from './eshop-service';
import { ListService } from './list-service';
import { ReservationService } from './reservation-service';
import { SmartBoxService } from './smart_box-service';
import { StockItemService } from './stock-item-service';

export default (http) => ({
	admin: AdminService(http),
	box: BoxService(http),
	container: ContainerService(http),
	containerTreeView: ContainerTreeViewService(http),
	deliveryPoint: DeliveryPointService(http),
	eshop: EshopService(http),
	eshopOrder: EshopOrderService(http),
	list: ListService(http),
	qrCode: QrCodeService(http),
	reservation: ReservationService(http),
	smartBox: SmartBoxService(http),
	stockItem: StockItemService(http),
});
