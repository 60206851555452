<template>
	<card v-loading="loading" class="col">
		<div v-if="reservation" class="card-body">
			<div class="d-flex justify-content-between">
				<h2 class="card-title">
					{{ $t('pickup.reservationDetail') }} {{ reservation.reservationId }}
				</h2>
				<p-button @click.stop="() => printSticker(reservation.reservationId)">
					{{ $t('pickup.printSticker') }}
				</p-button>
			</div>
			<div>
				<div class="row">
					<div class="col-sm-4">
						<form-group-input :label="$t('pickup.occupiedFrom')" disabled>
							<date-picker
								:value="reservation.from | datetime"
								format="dd.MM.yyyy HH:mm"
								prefix-icon="el-icon-date"
								readonly
								type="datetime"
								value-format="dd.MM.yyyy HH:mm" />
						</form-group-input>
					</div>
					<div class="col-sm-4">
						<form-group-input :label="$t('pickup.subscriptionEnd')" disabled>
							<date-picker
								:value="reservation.to | datetime"
								format="dd.MM.yyyy HH:mm"
								prefix-icon="el-icon-date"
								readonly
								type="datetime"
								value-format="dd.MM.yyyy HH:mm" />
						</form-group-input>
					</div>
					<div class="col-sm-4">
						<form-group-input :label="$t('pickup.pickedUp')" disabled>
							<date-picker
								:value="reservation.realEnd | datetime"
								format="dd.MM.yyyy HH:mm"
								prefix-icon="el-icon-date"
								readonly
								type="datetime"
								value-format="dd.MM.yyyy HH:mm" />
						</form-group-input>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-4">
						<form-group-input :label="$t('pickup.boxNumber')" :value="reservation.serialNumber" readonly />
					</div>
					<div class="col-sm-4">
						<form-group-input :label="$t('pickup.boxType')" :value="boxCzech(reservation.type)" readonly />
					</div>
					<div class="col-sm-4">
						<form-group-input :label="$t('pickup.packagesCount')" :value="reservation.packagesCount" readonly />
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<form-group-input :label="$t('pickup.orderNumber')" :value="reservation.reservationNumber" readonly />
					</div>
					<div class="col-sm-6">
						<form-group-input :label="$t('pickup.cashOnDelivery')" :value="reservation.price" readonly />
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<form-group-input :label="$t('pickup.state')" :value="statusCzech(reservation.status)" readonly />
					</div>
					<div class="col-sm-6">
						<form-group-input :label="$t('pickup.eshop')" :value="eshopName" readonly />
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6">
						<form-group-input :label="$t('pickup.email')" :value="reservation.email" readonly />
					</div>
					<div class="col-sm-6">
						<form-group-input :label="$t('pickup.phone')" :value="reservation.phone | phone" readonly />
					</div>
				</div>
				<form-group-input :label="$t('pickup.customerAddress')" :value="reservation.customerAddress" readonly />
				<fieldset class="form-group border p-3">
					<legend class="w-auto px-2 mb-n2 control-label">
						{{ $t('pickup.customerAddress') }}
					</legend>
					<div class="row">
						<div class="col-sm-6">
							<form-group-input :label="$t('pickup.country')" :value="reservation.customer && reservation.customer.state" readonly />
							<form-group-input :label="$t('pickup.county')" :value="reservation.customer && reservation.customer.county" readonly />
							<form-group-input :label="$t('pickup.zip')" :value="reservation.customer && reservation.customer.zip" readonly />
						</div>
						<div class="col-sm-6">
							<form-group-input :label="$t('pickup.region')" :value="reservation.customer && reservation.customer.region" readonly />
							<form-group-input :label="$t('pickup.city')" :value="reservation.customer && reservation.customer.city" readonly />
							<form-group-input :label="$t('pickup.street')" :value="reservation.customer && reservation.customer.street" readonly />
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	</card>
</template>

<script>
import { DatePicker } from 'element-ui';

import { ReservationStatus } from '@/modules/admin/store/enums';
import { BoxType } from '@/modules/eshop/store/enums';

export default {
	name: 'ReservationDetail',
	components: {
		DatePicker,
	},
	data() {
		return {
			reservation: null,
			loading: false,
		};
	},
	computed: {
		eshopName() {
			return this.reservation.eshop?.name || '';
		},
	},
	created() {
		this.getOne();
	},
	methods: {
		async getOne() {
			this.loading = true;
			this.reservation = await this.$service.reservation.getOne(this.$route.params.id);
			this.loading = false;
		},
		statusCzech(status) {
			return ReservationStatus.czech(status);
		},
		boxCzech(boxType) {
			return BoxType.czech(boxType);
		},
		async printSticker(id) {
			this.loading = true;
			try {
				await this.$service.reservation.printSticker(id);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.form-group legend {
	font-size: 0.9rem;
}
</style>
