import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

import { containerTypes, shortContainerTypes } from '@/globalConstants';

import { lastPingContainer } from './modules/admin/store/constants';

export const LOCAL_DATE_FORMAT = 'DD.MM.YYYY HH:mm';

const Filters = {
	install(Vue) {
		Vue.filter('datetime', (value) => {
			if (value) {
				return moment.utc(String(value)).local().format(LOCAL_DATE_FORMAT);
			}
			return undefined;
		});

		Vue.filter('date', (value) => {
			if (value) {
				return moment.utc(String(value)).local().format('DD.MM.YYYY');
			}
			return undefined;
		});

		Vue.filter('ping', (value) => {
			if (value) {
				const daysDiff = moment.utc(String(value)).diff(moment().endOf('day'), 'days');
				switch (daysDiff) {
					case 0:
						return lastPingContainer.czech.today;
					case -1:
						return lastPingContainer.czech.yesterday;
					case -2:
						return lastPingContainer.czech.twoDaysAgo;
					case -3:
						return lastPingContainer.czech.threeDaysAgo;
					case -4:
						return lastPingContainer.czech.fourDaysAgo;
					case -5:
						return lastPingContainer.czech.fiveDaysAgo;
					case -6:
						return lastPingContainer.czech.sixDaysAgo;
					case -7:
						return lastPingContainer.czech.sevenDaysAgo;
					default:
						return lastPingContainer.czech.moreThanWeekAgo;
				}
			}
			return undefined;
		});

		Vue.filter('deltamin', (value) => {
			if (value > 1) {
				if (value < 60) { return `${value} min`; }
				return '60+ min';
			}
			return undefined;
		});

		Vue.filter('phone', (value) => {
			if (value) {
				const number = parsePhoneNumberFromString(value, 'CZ');

				if (number && number.isValid()) {
					// Show international prefix
					return number.formatInternational();
				}
				// Return original if number is invalid
				return value;
			}
			return undefined;
		});

		Vue.filter('time', (value) => {
			if (value) {
				return moment.utc(String(value)).local().format('HH:mm');
			}
			return undefined;
		});
		Vue.filter('location', (container) => {
			if (container.city || container.serialNumber) {
				return `${container.city}, ${container.street}, ${container.location} - ${container.serialNumber}`;
			}
			return '---';
		});
		Vue.filter('locationReport', (container) => {
			if (container) {
				return [container.city, container.street, container.location].filter((x) => x).join(', ');
			}
			return null;
		});
		Vue.filter('gps', (rad) => {
			if (rad) {
				return `${rad.degree}° ${rad.minutes}' ${rad.seconds}"`;
			}
			return null;
		});
		Vue.filter('coordinatesRad', (coordinates) => {
			if (coordinates) {
				const splitCoordinates = coordinates.toString().split('.');
				const degree = parseInt(splitCoordinates[0], 10);
				const minutes = parseFloat(`0.${splitCoordinates[1]}`) * 60;
				const seconds = parseFloat(`0.${minutes.toString().split('.')[1]}`) * 60;
				return `${degree}°${parseInt(minutes.toString().split('.'), 10)}'${parseFloat(seconds.toFixed(3))}"`;
			}
			return null;
		});
		Vue.filter('containerType', (containerType) => {
			if (containerType === containerTypes.store) {
				return shortContainerTypes.store;
			}
			if (containerType === containerTypes.penguinBox) {
				return shortContainerTypes.penguinBox;
			}
			if (containerType === containerTypes.smartBox) {
				return shortContainerTypes.smartBox;
			}
			return '';
		});
	},
};

export default Filters;
