<template>
	<div class="row">
		<div class="col-md-12">
			<h4 class="title">
				Seznam cenových map
			</h4>
		</div>
		<div class="block mb-3">
			<el-pagination
				:current-page="priceMapPage.currentPage"
				:total="priceMapPage.totalCount"
				layout="total, sizes, prev, pager, next, jumper"
				type="success"
				:page-count="priceMapPage.pagesCount"
				@input="goToPage"
				@size-change="loadDataPageSize" />
		</div>
		<div v-loading="loading" class="col-md-12 card">
			<div class="card-body row">
				<div class="col-sm-12 mt-2">
					<router-link :to="{ name: 'price-map-new' }">
						<p-button type="success">
							Přidat cenovou mapu
						</p-button>
					</router-link>
					<el-table
						class="table-striped"
						:data="data"
						data-cy="ListMainTable"
						row-class-name="tableRow"
						@sort-change="sortChange"
						@row-click="goToEdit">
						<el-table-column property="priceMapId" sortable="custom">
							<template #header>
								<el-tooltip content="Id" placement="top-start">
									<span>Id</span>
								</el-tooltip>
							</template>
						</el-table-column>

						<el-table-column property="description" sortable="custom">
							<template #header>
								<el-tooltip content="Popis #" placement="top-start">
									<span>Popis #</span>
								</el-tooltip>
							</template>
						</el-table-column>

						<el-table-column property="basePrice" sortable="custom">
							<template #header>
								<el-tooltip content="Základní cena" placement="top-start">
									<span>Základní cena</span>
								</el-tooltip>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="block">
			<el-pagination
				:current-page="priceMapPage.currentPage"
				:total="priceMapPage.totalCount"
				layout="total, sizes, prev, pager, next, jumper"
				type="success"
				:page-count="priceMapPage.pagesCount"
				@input="goToPage"
				@size-change="loadDataPageSize" />
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination, Tooltip as ElTooltip } from 'element-ui';

export default {
	name: 'PriceMapList',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
	},

	data() {
		return {
			priceMapPage: Object,
			filter: {},
			pageSize: 10,
			loading: false,
		};
	},

	computed: {
		data() {
			return this.priceMapPage.entities;
		},
	},

	async created() {
		this.priceMapPage = await this.getPriceMapList();
	},

	methods: {
		async sortChange(column) {
			if (column.order) {
				this.filter.orderby = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter = {};
			}
			await this.goToPage(this.priceMapPage.currentPage);
		},
		async goToPage(page) {
			this.priceMapPage = await this.getPriceMapList(page, this.pageSize, this.filter);
		},

		loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			this.goToPage(1);
		},
		goToEdit(row) {
			this.$router.push({ name: 'price-map-edit', params: { id: row.priceMapId } });
		},
		async getPriceMapList(page, pageSize, filter) {
			this.loading = true;
			const list = await this.$service.priceMap.getList(filter, page, pageSize);
			this.loading = false;
			return list;
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
</style>
