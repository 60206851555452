<template>
	<div id="app" :class="{ 'nav-open': $store.state.sidebar.showSidebar }">
		<router-view />
		<the-special-modal />
	</div>
</template>

<script>
import { TheSpecialModal } from '@/components/UIComponents';

export default {
	name: 'App',
	components: { TheSpecialModal },
	async created() {
		const currentAppName = this.$getConst('appName');

		if (!currentAppName) {
			return;
		}

		window.addEventListener('beforeunload', (event) => {
			if (this.$store.state[currentAppName]?.refreshTokenLoading) {
				event.preventDefault();
				event.returnValue = this.$t('global.unloadPageProblem');

				return event.returnValue;
			}

			return false;
		});
	},
};
</script>

<style>
.battery-three-quarters-color {
	color: #9bae00;
}
.pma-info-access-dialog .el-dialog {
	min-width: 450px !important;
}
</style>
