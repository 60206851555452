import store from '@/store';

import { i18n } from '../i18n';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import http from './http';

export default {
	async login(username, password) {
		try {
			const params = this.prepareUrlParams('openid profile offline_access');
			params.append('grant_type', 'password');
			params.append('UserName', username);
			params.append('Password', password);

			const response = await http.post('/connect/token', params);
			return this.prepareTokenData(response.data);
		} catch (e) {
			throw new Error(i18n.t('global.invalidLogin'));
		}
	},

	prepareUrlParams(scope) {
		const moduleName = store.state.currentAppName;
		const { clientId, scopeName } = store.state[moduleName];

		const params = new URLSearchParams();
		params.append('client_id', clientId);
		params.append('client_secret', 'MegaTajnyHeslo');
		params.append('Scope', `${scopeName} ${scope}`);

		return params;
	},

	prepareTokenData(responseData) {
		return {
			refreshToken: responseData.refresh_token,
			accessToken: responseData.access_token,
			expiresIn: responseData.expires_in,
		};
	},

	async refreshToken(refreshToken) {
		try {
			const params = this.prepareUrlParams('offline_access');
			params.append('grant_type', 'refresh_token');
			params.append('refresh_token', refreshToken);

			const response = await http.post('/connect/token', params);

			return this.prepareTokenData(response.data);
		} catch (e) {
			throw new Error(i18n.t('global.invalidLogin'));
		}
	},

	async loadCurrentUser() {
		try {
			const response = await http.get('/connect/userinfo');

			return response.data;
		} catch (e) {
			throw new Error(i18n.t('global.invalidLogin'));
		}
	},

	async changePassword(userPwd) {
		const response = await http.post('/api/Account/ChangePassword', userPwd);
		return response.data;
	},

	async requestResetPassword(data) {
		try {
			const response = await http.post('/api/Account/GenerateResetPassword', data);
			if (!response.data.succeeded) {
				console.warn('Fail request new password', response.data.errors);
			}
			return response.data;
		} catch (e) {
			console.error('Error when request new password');
		}
		return false;
	},

	async resetPassword(data) {
		try {
			const response = await http.post('/api/Account/ResetPassword', data);
			if (!response.data.succeeded) {
				console.error('Failed resetting password', response.data.errors);
			}
			return response.data;
		} catch (e) {
			console.error('Error when resetting password');
		}
		return false;
	},

	async confirmEmail(user, code) {
		try {
			const response = await http.get('/api/Account/ConfirmEmail', {
				params: { user, code },
				transformRequest: (data, headers) => {
					delete headers.common.Authorization;
					return data;
				},
			});
			if (!response.data.succeeded) {
				console.warn('Fail confirmation', response.data.errors);
			}
			return response.data;
		} catch (e) {
			console.error('Error when change password');
		}
		return false;
	},
};
