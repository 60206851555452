<template>
	<card>
		<h2 v-if="editing" slot="header" class="card-title">
			Editace cenové výjimky
		</h2>
		<h2 v-else slot="header" class="card-title">
			Nová výjimka cenové mapy
		</h2>
		<form>
			<form-group-input
				v-model="priceExceptionModified.description"
				v-validate="validations.priceExceptionModifiedDescription.rules"
				placeholder="Zadejte název"
				label="Název"
				:name="validations.priceExceptionModifiedDescription.name"
				:error="getError(validations.priceExceptionModifiedDescription.name)"
				data-vv-as="Název" />
			<label class="mb-3">Dny platnosti</label>
			<div v-for="(value, day) in applicableDays" :key="day">
				<p-checkbox v-model="applicableDays[day].value">
					{{ value.name }}
				</p-checkbox>
			</div>
			<form-group-input :error="getError(validations.priceExceptionModifiedFromHour.name)" label="Od">
				<el-input
					v-model.number="priceExceptionModified.fromHour"
					v-validate="validations.priceExceptionModifiedFromHour.rules"
					class="styled-input"
					placeholder="Zadejte dobu od"
					type="number"
					:name="validations.priceExceptionModifiedFromHour.name"
					data-vv-as="Od">
					<template slot="append">
						hod.
					</template>
				</el-input>
			</form-group-input>
			<form-group-input :error="getError(validations.priceExceptionModifiedToHour.name)" label="Do">
				<el-input
					v-model.number="priceExceptionModified.toHour"
					v-validate="validations.priceExceptionModifiedToHour.rules"
					class="styled-input"
					placeholder="Zadejte dobu do"
					type="number"
					:name="validations.priceExceptionModifiedToHour.name"
					data-vv-as="Do">
					<template slot="append">
						hod.
					</template>
				</el-input>
			</form-group-input>
			<form-group-input :error="getError(validations.priceExceptionModifiedPrice.name)" label="Cena">
				<el-input
					v-model.number="priceExceptionModified.price"
					v-validate="validations.priceExceptionModifiedPrice.rules"
					class="styled-input"
					placeholder="Zadejte cenu"
					type="number"
					step="0.01"
					:name="validations.priceExceptionModifiedPrice.name"
					data-vv-as="Cena">
					<template slot="append">
						Kč/hod.
					</template>
				</el-input>
			</form-group-input>
			<confirm-button
				:callback="validateAndSave"
				:disabled="!isValid"
				class="pull-right">
				{{ submitButtonText }}
			</confirm-button>
		</form>
	</card>
</template>

<script>
import _ from 'lodash';
import { Input as ElInput } from 'element-ui';

import ValidationMixin from '@/util/validation-mixin';
import Validations from '@/util/validations';
import { appName } from '@/modules/admin/config';
import { formChanged } from '@/store/mutation-types';
import { Weekday, WeekdayNumber } from '@/modules/admin/store/enums';

export default {
	name: 'PriceExceptionFormCard',
	components: {
		ElInput,
	},
	mixins: [ValidationMixin],
	props: {
		priceException: { type: Object, default: () => {} },
		createdPmeCb: { type: Function, required: true },
		modifiedPmeCb: { type: Function, required: true },
	},
	data() {
		return {
			priceExceptionModified: {
				description: '',
			},
			applicableDays: {
				Monday: { value: false, name: 'Pondělí' },
				Tuesday: { value: false, name: 'Úterý' },
				Wednesday: { value: false, name: 'Středa' },
				Thursday: { value: false, name: 'Čtvrtek' },
				Friday: { value: false, name: 'Pátek' },
				Saturday: { value: false, name: 'Sobota' },
				Sunday: { value: false, name: 'Neděle' },
			},
			validations: {
				priceExceptionModifiedDescription: {
					name: 'priceExceptionModifiedDescription',
					rules: Validations.requiredField,
				},
				priceExceptionModifiedFromHour: {
					name: 'priceExceptionModifiedFromHour',
					rules: Validations.requiredHours,
				},
				priceExceptionModifiedToHour: {
					name: 'priceExceptionModifiedToHour',
					rules: Validations.requiredHours,
				},
				priceExceptionModifiedPrice: {
					name: 'priceExceptionModifiedPrice',
					rules: Validations.requiredField,
				},
			},
			editing: false,
		};
	},
	computed: {
		submitButtonText() {
			return this.editing ? 'Změnit' : 'Vytvořit';
		},
	},
	watch: {
		priceException: {
			immediate: true,
			handler(val) {
				this.priceExceptionModified = _.cloneDeep(val);
				this.editing = Object.keys(this.priceException).length !== 0;
				this.applicableDays = _.mapValues(this.applicableDays, (x) => ({ value: false, name: x.name }));
				if (this.editing) {
					const days = this.priceExceptionModified.daysOfWeek.split(',');
					for (let i = 0; i < days.length; i++) {
						const dayCategory = days[i].trim();

						switch (dayCategory) {
							case 'Weekend': {
								this.applicableDays[Weekday.Saturday.name].value = true;
								this.applicableDays[Weekday.Sunday.name].value = true;
								break;
							}
							case 'WorkingDays': {
								this.applicableDays[Weekday.Monday.name].value = true;
								this.applicableDays[Weekday.Tuesday.name].value = true;
								this.applicableDays[Weekday.Wednesday.name].value = true;
								this.applicableDays[Weekday.Thursday.name].value = true;
								this.applicableDays[Weekday.Friday.name].value = true;
								break;
							}
							default: {
								this.applicableDays[dayCategory].value = true;
							}
						}
					}
				}
			},
		},
		priceExceptionModified: {
			handler() {
				if (this.priceExceptionLoaded) {
					this.$store.commit(`${appName}/${formChanged}`, true);
				}
			},
			deep: true,
		},
	},
	updated() {
		this.priceExceptionLoaded = true;
	},
	methods: {
		async validateAndSave() {
			if (!await this.validate()) {
				return;
			}

			let daysOfWeek = 0;
			Object.keys(this.applicableDays).forEach((key) => {
				if (this.applicableDays[key].value) {
					// eslint-disable-next-line no-bitwise
					daysOfWeek |= WeekdayNumber[key];
				}
			});
			this.priceExceptionModified.daysOfWeek = daysOfWeek;
			if (this.editing) {
				await this.modifiedPmeCb(this.priceExceptionModified);
			} else {
				this.priceExceptionModified.priceExceptionId = null;
				await this.createdPmeCb(this.priceExceptionModified);
			}
			this.$store.commit(`${appName}/${formChanged}`, false);
		},
	},
};
</script>
