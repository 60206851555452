<template>
	<dropdown
		v-if="availableApps && availableApps.length > 1"
		class="applications"
		hide-arrow
		icon="fa fa-random"
		position="right"
		tag="div">
		<the-app-switcher-item
			v-if="availableApps.includes(appNameList.admin)"
			:app-name="appNameList.admin"
			icon="nc-laptop"
			title="Admin"
			url="/admin/prehled" />
		<the-app-switcher-item
			v-if="availableApps.includes(appNameList.eshop)"
			:app-name="appNameList.eshop"
			icon="nc-shop"
			title="E-shop"
			url="/eshop/rezervace" />
		<the-app-switcher-item
			v-if="availableApps.includes(appNameList.logistics)"
			:app-name="appNameList.logistics"
			:title="$t('admin.logistics')"
			icon="nc-send"
			url="/logistika/rezervace" />
	</dropdown>
</template>

<script>
import { appNameList } from '@/globalConstants';
import { getAuthDataStorageName } from '@/store/auth-actions';

import TheAppSwitcherItem from './TheAppSwitcherItem.vue';

export default {
	name: 'TheAppSwitcher',
	components: { TheAppSwitcherItem },
	data() {
		return {
			appNameList,
			availableApps: [],
		};
	},
	computed: {
		currentAppName() {
			return this.$getConst('appName');
		},
	},
	watch: {
		currentAppName: {
			handler(val) {
				if (val) {
					const rawTokenData = localStorage.getItem(getAuthDataStorageName(val));

					if (rawTokenData) {
						const tokenData = JSON.parse(rawTokenData);

						this.availableApps = tokenData.availableApps;
						return;
					}
				}

				this.availableApps = [];
			},
			immediate: true,
		},
	},
};
</script>

<style>
.applications {
	color: white;
	float: right;
	position: relative;
	z-index: 10;
	margin-top: -50px;
}

.dropdown-menu {
	min-width: 11em;
}
</style>
