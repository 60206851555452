export const translate = {
	czech: {
		successSaveMessage: 'Změny byly uloženy',
		failSaveMessage: 'Uložení se nezdařilo',
	},
};

export const appNameList = {
	admin: 'admin',
	logistics: 'logistics',
	eshop: 'eshop',
	driver: 'driver',
	pickup: 'pickup',
};

export const appBaseUrlList = {
	admin: '/admin',
	logistics: '/logistika',
	eshop: '/eshop',
	driver: '/ridic',
	pickup: '/vydejna',
};

export const batteryThresholds = {
	healthy: 12,
	runningLow: 11,
	critical: 10,
};

export const smartBatteryThresholds = {
	attention: 50,
	warning: 25,
	critical: 10,
};

export const pickupAppVariants = {
	pickup: 'pickup',
	delivery: 'delivery',
};

export const constants = {
	versionMismatch: 'Version mismatch.',
	appVersionHeader: 'app-version',
};

export const containerTypes = {
	store: 'Store',
	penguinBox: 'PenguinBox',
	smartBox: 'SmartKeyBox',
	private: 'PrivateAddress',
};

export const shortContainerTypes = {
	store: 'Výdejna',
	penguinBox: 'PB',
	smartBox: 'SB',
};

// add pages which should be prevented from navigating to
export const newAppPaths = [
	'/admin/sablony',
];

export const stateNames = {
	czechRepublic: 'česká republika',
	czechia: 'česko',
	slovakRepublic: 'slovenská republika',
	slovakia: 'slovensko',
};
