export const ListService = (http) => ({
	async getParameterDefinitions() {
		const response = await http.get('/api/Lists/ContainerParameters');
		return response.data;
	},

	async getContainerPriceMaps() {
		const response = await http.get('/api/Lists/PriceMaps');
		return response.data;
	},
});
