<template>
	<el-table
		class="table-striped"
		height="100%"
		:data="tableData"
		:row-class-name="tableRowClassName"
		show-overflow-tooltip
		:default-sort="{ prop: filter.orderby, order: filter.direction }"
		data-cy="ListMainTable"
		@sort-change="$emit('sort-change', $event)"
		@row-click="$emit('go-to-edit', $event)">
		<el-table-column
			key="type"
			property="type"
			header-align="center"
			sortable="custom"
			width="60">
			<template #header>
				<easy-tooltip
					:content="$t('admin.containerType')" />
			</template>
			<template #default="scope">
				<div>
					<span v-if="scope.row.type === cType.penguinBox">
						<img
							alt="PB"
							src="/static/img/icons/pb.svg"
							class="penguin-box-icon">
					</span>
					<span v-else-if="scope.row.type === cType.store">
						<img
							alt="Genesis"
							src="/static/img/icons/genesis.svg"
							class="genesis-icon">
					</span>
					<span v-else-if="scope.row.type === cType.private">
						<i class="fa fa-home gray" />
					</span>
					<span v-else-if="scope.row.type === cType.smartBox">
						<i class="nc-icon nc-mobile gray" />
					</span>
				</div>
			</template>
		</el-table-column>
		<el-table-column
			key="serialNumber"
			property="serialNumber"
			header-align="center"
			sortable="custom"
			align="center"
			width="70">
			<template #header>
				<easy-tooltip
					:content="$t('admin.manufacturerNumberShort')" />
			</template>
		</el-table-column>
		<el-table-column
			key="physicalKey"
			property="physicalKey"
			header-align="center"
			sortable="custom"
			align="center"
			width="130">
			<template #header>
				<easy-tooltip
					:content="$t('admin.physicalKey')" />
			</template>
		</el-table-column>
		<el-table-column
			key="region"
			property="region"
			sortable="custom"
			header-align="center"
			align="center"
			width="130">
			<template #header>
				<easy-tooltip
					:content="$t('admin.region')" />
			</template>
		</el-table-column>
		<el-table-column
			key="county"
			property="county"
			sortable="custom"
			header-align="center"
			align="center"
			width="110">
			<template #header>
				<easy-tooltip
					:content="$t('admin.county')" />
			</template>
		</el-table-column>
		<el-table-column
			key="city"
			property="city"
			sortable="custom"
			header-align="center"
			align="center">
			<template #header>
				<easy-tooltip
					:content="$t('admin.city')" />
			</template>
		</el-table-column>
		<el-table-column
			key="street"
			property="street"
			sortable="custom"
			header-align="center"
			align="center">
			<template #header>
				<easy-tooltip
					:content="$t('admin.street')" />
			</template>
			<template #default="scope">
				<trim-text
					:text="scope.row.street"
					:max-length="40" />
			</template>
		</el-table-column>
		<el-table-column
			key="location"
			property="location"
			sortable="custom"
			header-align="center">
			<template #header>
				<easy-tooltip
					:content="$t('admin.location')" />
			</template>
			<template #default="scope">
				<trim-text
					:text="scope.row.location"
					:max-length="40" />
			</template>
		</el-table-column>
		<el-table-column
			key="postCode"
			property="postCode"
			sortable="custom"
			header-align="center"
			align="center">
			<template #header>
				<easy-tooltip
					:content="$t('admin.postCode')" />
			</template>
			<template #default="scope">
				<trim-text
					:text="scope.row.postCode"
					:max-length="40" />
			</template>
		</el-table-column>
		<el-table-column
			key="isOK"
			property="isOK"
			align="center"
			sortable="custom"
			width="100">
			<template #header>
				<easy-tooltip
					:content="$t('admin.state')" />
			</template>
			<template #default="scope">
				<div :key="scope.row.isOK">
					<i
						v-if="scope.row.isOK"
						class="fa fa-check fa-lg text-success" />
					<i
						v-else
						type="danger"
						class="fa fa-times fa-lg text-danger" />
				</div>
			</template>
		</el-table-column>
		<el-table-column
			v-if="tableData && tableData.filter(x => x.type !== 'Store').length"
			key="batteryVoltage"
			property="batteryVoltage"
			align="center"
			sortable="custom"
			width="80">
			<template #header>
				<el-tooltip
					:content="$t('admin.battery')"
					placement="top-start">
					<span>
						<i
							type="danger"
							class="fa fa-battery-half fa-lg text-danger" />
					</span>
				</el-tooltip>
			</template>
			<template #default="scope">
				<div :key="scope.row.batteryVoltage">
					<span v-if="scope.row.type === 'SmartKeyBox'">
						<i
							v-if="scope.row.batteryVoltage >= 50"
							class="fa fa-battery-full fa-lg text-success"
							type="success" />
						<i
							v-else-if="scope.row.batteryVoltage >= 25"
							class="fa fa-battery-three-quarters fa-lg battery-three-quarters-color" />
						<i
							v-else-if="scope.row.batteryVoltage >= 10"
							class="fa fa-battery-half fa-lg text-warning"
							type="warning" />
						<i
							v-else
							class="fa fa-battery-empty fa-lg text-danger"
							type="danger" />
						{{ scope.row.batteryVoltage ? scope.row.batteryVoltage : '-' }}%
					</span>
					<span v-else-if="scope.row.type === 'PenguinBox'">
						<i
							v-if="scope.row.batteryVoltage >= 12"
							class="fa fa-battery-full fa-lg text-success"
							type="success" />
						<i
							v-else-if="scope.row.batteryVoltage >= 11"
							class="fa fa-battery-three-quarters fa-lg battery-three-quarters-color" />
						<i
							v-else-if="scope.row.batteryVoltage >= 10"
							class="fa fa-battery-half fa-lg text-warning"
							type="warning" />
						<i
							v-else
							class="fa fa-battery-empty fa-lg text-danger"
							type="danger" />
						{{ scope.row.batteryVoltage }}V
					</span>
				</div>
			</template>
		</el-table-column>
		<el-table-column
			key="isLocked"
			property="isLocked"
			align="center"
			sortable="custom"
			width="60">
			<template #header>
				<el-tooltip
					:content="$t('admin.locked')"
					placement="top-start">
					<span>
						<i
							type="danger"
							class="fa fa-lock fa-lg text-danger" />
					</span>
				</el-tooltip>
			</template>
			<template #default="scope">
				<div :key="scope.row.isLocked">
					<i
						v-if="scope.row.isLocked"
						type="danger"
						class="fa fa-lock fa-lg text-danger" />
				</div>
			</template>
		</el-table-column>
		<el-table-column
			key="isDisabled"
			property="isDisabled"
			align="center"
			sortable="custom"
			width="60">
			<template #header>
				<el-tooltip
					:content="$t('admin.disabled')"
					placement="top-start">
					<span>
						<i
							type="danger"
							class="fa fa-power-off fa-lg text-danger" />
					</span>
				</el-tooltip>
			</template>
			<template #default="scope">
				<div :key="scope.row.isDisabled">
					<i
						v-if="scope.row.isDisabled"
						type="danger"
						class="fa fa-power-off fa-lg text-danger" />
				</div>
			</template>
		</el-table-column>
		<el-table-column
			key="hasPaymentTerminal"
			property="hasPaymentTerminal"
			align="center"
			sortable="custom"
			width="60">
			<template #header>
				<el-tooltip
					:content="$t('admin.terminal')"
					placement="top-start">
					<span>
						<i
							type="danger"
							class="fa fa-cash-register fa-lg text-danger" />
					</span>
				</el-tooltip>
			</template>
			<template #default="scope">
				<div :key="scope.row.hasPaymentTerminal">
					<i
						v-if="scope.row.hasPaymentTerminal"
						type="danger"
						class="fa fa-cash-register fa-lg text-danger" />
				</div>
			</template>
		</el-table-column>
		<el-table-column
			key="isPublic"
			align="center"
			property="isPublic"
			sortable="custom"
			width="60">
			<template #header>
				<el-tooltip
					:content="$t('admin.isPublic')"
					placement="top-start">
					<span>
						<i
							type="danger"
							class="fa fa-city fa-lg text-danger" />
					</span>
				</el-tooltip>
			</template>
			<template #default="scope">
				<div :key="scope.row.isPublic">
					<i
						v-if="scope.row.isPublic"
						type="danger"
						class="fa fa-city fa-lg text-success" />
					<i
						v-else
						type="danger"
						class="fab fa-dev fa-lg text-danger" />
				</div>
			</template>
		</el-table-column>
		<el-table-column
			key="hasDisabledOrFailedBox"
			property="hasDisabledOrFailedBox"
			align="center"
			width="80"
			sortable="custom">
			<template #header>
				<easy-tooltip
					:content="$t('admin.boxes')" />
			</template>
			<template #default="scope">
				<div :key="scope.row.hasDisabledOrFailedBox">
					<i
						v-if="scope.row.hasDisabledOrFailedBox"
						type="danger"
						class="fa fa-times fa-lg text-danger" />
					<i
						v-else
						class="fa fa-check fa-lg text-success" />
				</div>
			</template>
		</el-table-column>
		<el-table-column
			v-if="tableData && tableData.filter(x => x.lastPing).length"
			key="lastPing"
			property="lastPing"
			header-align="center"
			align="center"
			sortable="custom"
			width="120">
			<template #header>
				<easy-tooltip
					:content="$t('admin.lastStatusTime')" />
			</template>
			<template #default="scope">
				{{ scope.row.lastPing | ping }}
			</template>
		</el-table-column>
		<el-table-column
			v-if="tableData && tableData.filter(x => x.maxTimeDelta).length"
			key="maxTimeDelta"
			property="maxTimeDelta"
			header-align="center"
			sortable="custom"
			align="center"
			width="120">
			<template #header>
				<easy-tooltip :content="$t('admin.maxTimeDelta')" />
			</template>
			<template #default="scope">
				<span :class="timeDelayClass(scope.row.maxTimeDelta)">
					{{ scope.row.maxTimeDelta | deltamin }}
				</span>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>

import TrimText from '@/components/UIComponents/TrimText.vue';
import { containerTypes as cType } from '@/globalConstants';

export default ({
	name: 'DeliveryPointTableDefinition',
	components: {
		TrimText,
	},
	props: {
		tableData: {
			type: Array,
			default: () => [],
		},
		filter: {
			type: Object,
			default: () => {
			},
		},
	},
	data() {
		return {
			cType,
		};
	},
	methods: {
		timeDelayClass(value) {
			if (value) {
				if (value > 15) { return 'text-danger'; }
				if (value > 1) { return 'text-warning'; }
			}
			return 'success';
		},
		tableRowClassName(row) {
			return row.row.isOffline ? 'tableRowOffline' : 'tableRow';
		},
	},
});
</script>
