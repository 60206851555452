import { appNameList } from '@/globalConstants';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import AuthActions from '@/store/auth-actions';

const authActions = new AuthActions([appNameList.logistics, appNameList.admin, appNameList.eshop]);

export default {
	...authActions.actions,
};
