<template>
	<div v-loading="loading">
		<div class="row">
			<div class="col-sm-6">
				<card>
					<form @submit.prevent="searchReservation">
						<form-group-input
							ref="pinOrScan"
							v-model="pinOrScan"
							:data-vv-as="$t('pickup.PIN')"
							:error="getError(validations.PIN.name)"
							:label="$t('pickup.PIN')"
							:name="validations.PIN.name"
							:placeholder="`${$t('pickup.insertPin')} / ${$t('pickup.scanQrCode')}`" />
						<p-button
							class="mr-3"
							native-type="submit"
							size="md"
							type="success">
							{{ $t('pickup.search') }}
						</p-button>
						<p-button
							class="mr-3"
							size="md"
							type="primary"
							@click="confirmAckAction">
							{{ confirmButtonDescription }}
						</p-button>
						<p-button size="md" type="primary" @click="toggleQrCode">
							{{ scanQrCodeLabel }}
						</p-button>
						<qrcode-stream
							v-if="scanQrCode"
							:camera="cameraSettings"
							class="mt-3"
							@decode="onDecode" />
					</form>
				</card>
				<div v-loading="loading" />
			</div>
		</div>
		<div v-if="reservation" class="row">
			<div class="col-sm-9">
				<card class="col">
					<div class="card-body">
						<div class="d-flex justify-content-between">
							<h2 class="card-title">
								{{ $t('pickup.reservationDetail') }} {{ reservation.reservationId }}
							</h2>
							<p-button @click.stop="() => printSticker(reservation.reservationId)">
								{{ $t('pickup.printSticker') }}
							</p-button>
						</div>
						<div>
							<div class="row">
								<div class="col-sm-4">
									<form-group-input :label="$t('pickup.occupiedFrom')" disabled>
										<date-picker
											:value="reservation.from | datetime"
											format="dd.MM.yyyy HH:mm"
											prefix-icon="el-icon-date"
											readonly
											type="datetime"
											value-format="dd.MM.yyyy HH:mm" />
									</form-group-input>
								</div>
								<div class="col-sm-4">
									<form-group-input :label="$t('pickup.subscriptionEnd')" disabled>
										<date-picker
											:value="reservation.to | datetime"
											format="dd.MM.yyyy HH:mm"
											prefix-icon="el-icon-date"
											readonly
											type="datetime"
											value-format="dd.MM.yyyy HH:mm" />
									</form-group-input>
								</div>
								<div class="col-sm-4">
									<form-group-input :label="$t('pickup.pickedUp')" disabled>
										<date-picker
											:value="reservation.realEnd | datetime"
											format="dd.MM.yyyy HH:mm"
											prefix-icon="el-icon-date"
											readonly
											type="datetime"
											value-format="dd.MM.yyyy HH:mm" />
									</form-group-input>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4">
									<form-group-input :label="$t('pickup.boxNumber')" :value="reservation.serialNumber" readonly />
								</div>
								<div class="col-sm-4">
									<form-group-input :label="$t('pickup.boxType')" :value="reservation.type" readonly />
								</div>
								<div class="col-sm-4">
									<form-group-input :label="$t('pickup.packagesCount')" :value="reservation.packagesCount" readonly />
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<form-group-input :label="$t('pickup.orderNumber')" :value="reservation.reservationNumber" readonly />
								</div>
								<div class="col-sm-6">
									<form-group-input :label="$t('pickup.cashOnDelivery')" :value="reservation.price" readonly />
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<form-group-input :label="$t('pickup.state')" :value="statusCzech(reservation.status)" readonly />
								</div>
								<div class="col-sm-6">
									<form-group-input :label="$t('pickup.eshop')" :value="eshopName" readonly />
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<form-group-input :label="$t('pickup.email')" :value="reservation.email" readonly />
								</div>
								<div class="col-sm-6">
									<form-group-input :label="$t('pickup.phone')" :value="reservation.phone | phone" readonly />
								</div>
							</div>
							<form-group-input :label="$t('pickup.customerAddress')" :value="reservation.customerAddress" readonly />
							<fieldset v-if="variant === pickupAppVariants.delivery" class="form-group border p-3">
								<legend class="w-auto px-2 mb-n2 control-label">
									{{ $t('pickup.customerAddress') }}
								</legend>
								<div class="row">
									<div class="col-sm-6">
										<form-group-input :label="$t('pickup.country')" :value="reservation.customer && reservation.customer.state" readonly />
										<form-group-input :label="$t('pickup.county')" :value="reservation.customer && reservation.customer.county" readonly />
										<form-group-input :label="$t('pickup.zip')" :value="reservation.customer && reservation.customer.zip" readonly />
									</div>
									<div class="col-sm-6">
										<form-group-input :label="$t('pickup.region')" :value="reservation.customer && reservation.customer.region" readonly />
										<form-group-input :label="$t('pickup.city')" :value="reservation.customer && reservation.customer.city" readonly />
										<form-group-input :label="$t('pickup.street')" :value="reservation.customer && reservation.customer.street" readonly />
									</div>
								</div>
							</fieldset>
						</div>
					</div>
					<p-button
						v-if="canConfirmAction"
						size="md"
						type="primary"
						@click="confirmAction">
						{{ confirmButtonDescription }}
					</p-button>
				</card>
			</div>
		</div>
		<el-dialog
			v-if="variant === pickupAppVariants.pickup"
			:close-on-click-modal="false"
			:visible.sync="showReqPaymentWarning"
			round
			width="50%">
			<h3 v-if="reservation" class="text-center">
				{{ $t('pickup.requirePaymentWarning') }}<strong>{{ $t('pickup.cashOnDelivery2') }}.</strong> <br><br>
			</h3>
			<span slot="footer" class="d-flex justify-content-center">
				<p-button
					class="w-25"
					size="lg"
					type="success"
					@click="confirmPaymentWarning"> <i class="fa fa-check-circle" /> </p-button>
			</span>
		</el-dialog>
		<el-dialog
			:visible.sync="showPostPackageInfo"
			close-on-click-modal
			round
			width="50%">
			<h3 class="text-center">
				{{ $t('pickup.scannedPackageIsType') }} <strong>{{ $t('pickup.reservationPost') }}</strong>.
			</h3>
			<span slot="footer" class="d-flex justify-content-center">
				<p-button
					class="w-25"
					size="lg"
					type="success"
					@click="hidePostPackageInfo"> <i class="fa fa-check-circle" /> </p-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import _ from 'lodash';
import { DatePicker, Dialog as ElDialog } from 'element-ui';
import { QrcodeStream } from 'vue-qrcode-reader';

import ValidationMixin from '@/util/validation-mixin';
import Validations from '@/util/validations';
import FormMixin from '@/util/form-mixin';
import notifyService from '@/service/notify-service';
import { ReservationStatus } from '@/modules/admin/store/enums';
import { formChanged } from '@/store/mutation-types';
import BaseMixins from '@/util/base-mixins';
import { pickupAppVariants } from '@/globalConstants';

export default {
	name: 'BasePickupDeliveryForm',
	components: {
		DatePicker,
		ElDialog,
		QrcodeStream,
	},
	mixins: [ValidationMixin, FormMixin, BaseMixins],
	props: {
		confirmButtonDescription: { type: String, default: '' },
	},
	data() {
		return {
			pinOrScan: null,
			pin: null,
			reservationQrData: null,
			reservation: null,
			showReqPaymentWarning: false,
			showPostPackageInfo: false,
			loading: false,
			validations: {
				PIN: {
					name: 'PIN',
					rules: Validations.PIN,
				},
			},
			scanQrCode: false,
			variant: pickupAppVariants.delivery,
			pickupAppVariants,
		};
	},
	computed: {
		eshopName() {
			return this.reservation.eshop?.name || null;
		},
		canConfirmAction() {
			const status = this.reservation ? ReservationStatus.enumValueOf(this.reservation.status) : null;
			const refStatus = this.variant === pickupAppVariants.pickup ? ReservationStatus.PREPARED : ReservationStatus.WAIT_FOR_DELIVERY;

			return status === refStatus;
		},
		scanQrCodeLabel() {
			return this.scanQrCode ? this.$t('pickup.hideQrCode') : this.$t('pickup.scanQrCode');
		},
		genesisOrderDetailLink() {
			if (this.reservation?.reservationNumber) {
				return `https://prodejny.genesis-eshop.cz/objednavky/detail/${this.reservation.reservationNumber}`;
			}
			return '';
		},
	},
	watch: {
		async pin(val) {
			if (!Number.isNaN(val)) {
				this.onPinUpdate(val);
			}
		},
		async pinOrScan(val) {
			const pin = Number(val);

			if (Number.isNaN(pin)) {
				this.onDecode(val);
			} else {
				this.pin = pin;
			}
		},
	},
	methods: {
		hidePostPackageInfo() {
			this.showPostPackageInfo = false;
		},
		async searchReservation() {
			try {
				this.loading = true;

				if (this.pin) {
					this.reservation = null;

					let result = await this.$service.reservation[pickupAppVariants.delivery](Number(this.pin));
					if (!result.reservationId) { result = await this.$service.reservation[pickupAppVariants.pickup](Number(this.pin)); }

					if (result.reservationId) {
						const reservation = await this.$service.reservation.getOne(result.reservationId);
						const variant = reservation.canConfirmPickUp ? pickupAppVariants.pickup : pickupAppVariants.delivery;

						if (result.status === 'ExpiredReservation' && variant === pickupAppVariants.delivery) {
							notifyService.notifyError(this.$t('pickup.reservationExpired') + reservation.reservationNumber);
						} else {
							this.reservation = reservation;
							this.variant = variant;

							if (this.reservation?.isPostSend || this.reservation?.isPostReceive) {
								this.showPostPackageInfo = true;
							}
						}

						if (ReservationStatus.enumValueOf(reservation.status).name === ReservationStatus.WAIT_FOR_RETURN.name) {
							await this.printSticker(result.reservationId);
						}
					} else {
						notifyService.notifyError(this.$t('pickup.reservationNotFound'));
					}
				}
			} catch {
				notifyService.notifyError(this.$t('pickup.reservationChangeStateFailure'));
			} finally {
				this.loading = false;
			}
		},
		async confirmAction() {
			try {
				this.loading = true;
				const payload = {
					reservationId: this.reservation.reservationId,
				};
				if (this.variant === pickupAppVariants.pickup) {
					if (this.tryGetPinFromInput() !== this.reservation.unlockCode) {
						notifyService.notifyError(this.$t('pickup.haveToUsePin'));
						return;
					}
					if (this.reservation.price > 0) {
						payload.debtPaymentIdentifier = this.genesisOrderDetailLink;
					}
					await this.$service.reservation.ackPickup(payload);
				} else {
					await this.$service.reservation.ackDelivery(this.reservation.reservationId);
				}

				if (this.variant === pickupAppVariants.pickup) {
					notifyService.notifySuccess(this.$t('global.pickupAckSuccess'));
				} else {
					notifyService.notifySuccess(this.$t('global.deliverAckSuccess'));
				}

				this.pin = null;
				this.$refs.pinOrScan.$el.querySelector('input').focus();

				this.reservation = await this.$service.reservation.getOne(this.reservation.reservationId);

				if (this.variant === pickupAppVariants.pickup && this.reservation.price > 0) {
					this.showReqPaymentWarning = true;
				}

				this.$store.commit(`${this.currentAppName}/${formChanged}`, false);
			} catch {
				notifyService.notifyError(this.$t('pickup.reservationChangeStateFailure'));
			} finally {
				this.loading = false;
			}
		},
		async confirmAckAction() {
			if (this.pin) {
				await this.searchReservation();
				if (this.reservation) {
					await this.confirmAction();
				}
			} else {
				notifyService.notifyError(this.$t('pickup.reservationNotFound'));
			}
		},
		statusCzech(status) {
			return ReservationStatus.czech(status);
		},
		async onDecode(decodedString) {
			let qrData = null;
			try {
				qrData = JSON.parse(decodedString);
			} catch (e) {
				qrData = decodedString;
			}

			this.cameraReset();

			if (_.isObject(qrData)) {
				this.reservationQrData = qrData;

				let qrResult = null;

				try {
					qrResult = await this.$service.qrCode.getReservation(this.reservationQrData);
				} catch (err) {
					if (err.status !== 404) {
						throw err;
					}
				}

				if (qrResult?.[0]?.unlockCode) {
					const oldPin = this.pin;
					this.pin = qrResult[0].unlockCode;

					if (oldPin === this.pin) {
						// force pin update logic even if the same pin was decoded a second time
						this.onPinUpdate(this.pin);
					}
				} else {
					notifyService.notifyError(this.$t('pickup.reservationNotFound'));
				}
			} else {
				this.pin = Number(qrData);
			}
		},
		toggleQrCode() {
			this.scanQrCode = !this.scanQrCode;

			this.switchCamera(this.scanQrCode);
		},
		confirmPaymentWarning() {
			this.showReqPaymentWarning = false;
			window.open(this.genesisOrderDetailLink, '_blank');
		},
		async onPinUpdate(pin) {
			if (pin?.toString().length === 5) {
				this.$store.commit(`${this.currentAppName}/${formChanged}`, true);
				const scan = this.scanQrCode;
				this.scanQrCode = false;
				await this.confirmAckAction();
				this.scanQrCode = scan;
			}
		},
		tryGetPinFromInput() {
			return Number(this.pinOrScan);
		},
		async printSticker(id) {
			this.loading = true;
			try {
				await this.$service.reservation.printSticker(id);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.form-group legend {
	font-size: 0.9rem;
}
</style>
