export const MailNotifyService = (http) => ({
	async addNotifyMail(email) {
		const response = await http.post('/api/NotifyMail', {
			email,
		});
		return response.data;
	},
	async deleteNotifyMail(email) {
		const response = await http.delete('/api/NotifyMail', {
			params: {
				email,
			},
		});
		return response.data;
	},
	async getNotifyMails() {
		const response = await http.get('/api/NotifyMail');
		return response.data;
	},
});
