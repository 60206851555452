import { ReservationStatusBase } from '@/store/enums';

export class ReservationStatus extends ReservationStatusBase {}

ReservationStatus.initEnum([
	'WAIT_FOR_COLLECT',
	'DISCARD',
	'PAYMENT_WAITING',
	'ACTIVE',
	'PICK_UP',
	'COLLECTED',
	'WAIT_FOR_DELIVERY',
	'PREPARED',
	'WAIT_FOR_PAY',
	'WAIT_FOR_RETURN',
	'WAIT_FOR_PACKAGE',
	'WAIT_FOR_PACKAGE_PAY',
	'WAIT_FOR_EMPTY_DESTINATION',
	'DELIVERY_RESERVATION_CREATED',
	'PRESELECT',
	'LOST',
	'UNDELIVERED',
	'WAIT_FOR_RETURN_PAY',
]);
