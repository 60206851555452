import { appNameList } from '@/globalConstants';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import AuthActions from '@/store/auth-actions';
import ReservationCollectionActions from '@/store/reservation-collection-actions';
// eslint-disable-next-line import/no-cycle
import http from '@/service/http';

import { ListService } from '../service/list-service';
import * as mutationTypes from './mutation-types';
import * as actionTypes from './action-types';

const authActions = new AuthActions([appNameList.admin, appNameList.logistics, appNameList.eshop]);
const reservationCollectionActions = new ReservationCollectionActions();

export default {
	...authActions.actions,
	...reservationCollectionActions.actions,

	async [actionTypes.loadContainerParameters]({ commit }) {
		const knownParameters = await ListService(http).getParameterDefinitions();
		commit(mutationTypes.setContainerParameters, { knownParameters });
	},

	async [actionTypes.loadContainerPriceMaps]({ commit }) {
		const priceMaps = await ListService(http).getContainerPriceMaps();
		commit(mutationTypes.setContainerPriceMaps, { priceMaps });
	},

	async [actionTypes.loadPickupPlaces]({ commit, state }) {
		while (state.pickupPlacesForSelect.lastPage < state.pickupPlacesForSelect.pagesCount) {
			// eslint-disable-next-line no-await-in-loop
			const pickupPlaces = await ListService(http).getPickupPlaces(state.pickupPlacesForSelect.lastPage + 1);
			commit(mutationTypes.addPickupPlaces, { pickupPlaces });
		}
	},
};
