import { mapActions } from 'vuex';

import Dashboard from '@/modules/admin/views/Dashboard.vue';
import ReservationList from '@/modules/admin/views/Reservation/ReservationList.vue';
import ReservationForm from '@/modules/admin/views/Reservation/ReservationForm.vue';
import AdministratorForm from '@/modules/admin/views/AdminUsers/AdministratorForm.vue';
import AdministratorList from '@/modules/admin/views/AdminUsers/AdministratorList.vue';
import EmployeeList from '@/modules/admin/views/AdminUsers/EmployeesList.vue';
import EmployeeForm from '@/modules/admin/views/AdminUsers/EmployeeForm.vue';
import DriverList from '@/modules/admin/views/AdminUsers/DriverList.vue';
import DriverForm from '@/modules/admin/views/AdminUsers/DriverForm.vue';
import LogisticsForm from '@/modules/admin/views/AdminUsers/LogisticsForm.vue';
import LogisticsList from '@/modules/admin/views/AdminUsers/LogisticsList.vue';
import EshopForm from '@/modules/admin/views/Eshop/EshopForm.vue';
import EshopList from '@/modules/admin/views/Eshop/EshopList.vue';
import { BaseChangePasswordForm, BaseCollectionPrintSheet, BaseLogin, BaseForgotPassword, BaseResetPassword, BaseConfirmation } from '@/components/Base';
import PriceMapList from '@/modules/admin/views/PriceMap/PriceMapList.vue';
import PriceMapForm from '@/modules/admin/views/PriceMap/PriceMapForm.vue';
import HistoryExport from '@/modules/admin/views/HistoryExport/HistoryExport.vue';
import LoggedOutLayout from '@/components/Layouts/LoggedOutLayout.vue';
import TemplateEdit from '@/modules/admin/views/MessageTemplate/TemplateEdit.vue';
import Child from '@/components/Layouts/LoggedOut/Child.vue';
import { DeliveryPointType } from '@/modules/admin/store/enums';
import DeliveryPointList from '@/modules/admin/views/DeliveryPoint/DeliveryPointList.vue';
import DeliveryPointForm from '@/modules/admin/views/DeliveryPoint/DeliveryPointForm.vue';
import DeliveryPointHistory from '@/modules/admin/views/DeliveryPoint/DeliveryPointHistory.vue';
import DeliverPointOpeningHoursForm from '@/modules/admin/views/DeliveryPoint/DeliveryPointDetails/DeliveryPointOpeningHoursForm.vue';
import DefaultReplacements from '@/modules/admin/views/Replacements/DefaultReplacements.vue';
import { authenticate, logout } from '@/store/action-types';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
import { i18n } from '@/i18n';

export const routes = [
	{
		component: LoggedOutLayout,
		path: '',
		meta: {
			title: i18n.t('admin.administration'),
		},
		children: [
			{
				path: '/admin',
				children: [
					{ name: 'login', path: '', component: BaseLogin, props: { ...mapActions(appName, [authenticate, logout]) } },
					{ name: 'forgot-password', path: 'zapomenute-heslo', component: BaseForgotPassword },
					{ name: 'pass-reset', path: 'ResetPass/:user', component: BaseResetPassword },
					{ name: 'mail-confirm', path: 'Confirmation/:user', component: BaseConfirmation },
				],
				component: Child,
			},
		],
	},
	{
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "admin" */ '../../components/Layouts/DashboardLayout.vue'),
		path: '/admin/',
		redirect: '/admin/prehled',
		meta: {
			title: i18n.t('admin.isAdmin'),
		},
		children: [
			{
				name: 'dashboard',
				path: 'prehled',
				component: Dashboard,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.dashboard') }],
				},
			},
			{
				name: 'container-list',
				path: 'vydejni-mista',
				component: DeliveryPointList,
				props: { filterContainerType: Number },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.pickupPlaces') }],
				},
			},
			{
				name: 'container-new',
				path: 'vydejni-mista/penguinBoxy/novy',
				component: DeliveryPointForm,
				props: { deliveryPointType: DeliveryPointType.PenguinBox.name },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.penguinBoxes'), link: 'container-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'container-edit',
				path: 'vydejni-mista/penguinBoxy/:id',
				component: DeliveryPointForm,
				props: { deliveryPointType: DeliveryPointType.PenguinBox.name },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.penguinBoxes'), link: 'container-list' }, { name: i18n.t('admin.detail') }],
				},
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2/admin/vydejni-mista/detail/${to.params.id}`;
				},
			},
			{
				name: 'container-history',
				path: 'vydejni-mista/penguinBoxy/historie/:id',
				component: DeliveryPointHistory,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.penguinBoxes'), link: 'container-list' }, { name: i18n.t('admin.history') }],
				},
			},
			{
				name: 'smart-box-new',
				path: 'vydejni-mista/smartBoxy/novy',
				component: DeliveryPointForm,
				props: { deliveryPointType: DeliveryPointType.SmartKeyBox.name },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.smartBoxes'), link: 'container-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'smart-box-edit',
				path: 'vydejni-mista/smartBoxy/:id',
				component: DeliveryPointForm,
				props: { deliveryPointType: DeliveryPointType.SmartKeyBox.name },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.smartBoxes'), link: 'container-list' }, { name: i18n.t('admin.detail') }],
				},
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2/admin/vydejni-mista/detail/${to.params.id}`;
				},
			},
			{
				name: 'smart-box-history',
				path: 'vydejni-mista/smartBoxy/historie/:id',
				component: DeliveryPointHistory,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.smartBoxes'), link: 'container-list' }, { name: i18n.t('admin.history') }],
				},
			},
			{
				name: 'pickup-place-new',
				path: 'vydejni-mista/vydejny/nova',
				component: DeliveryPointForm,
				props: { deliveryPointType: DeliveryPointType.Store.name },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.stores'), link: 'container-list' }, { name: i18n.t('admin.new-she') }],
				},
			},
			{
				name: 'pickup-place-edit',
				path: 'vydejni-mista/vydejny/:id',
				component: DeliveryPointForm,
				props: { deliveryPointType: DeliveryPointType.Store.name },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.stores'), link: 'container-list' }, { name: i18n.t('admin.detail') }],
				},
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2/admin/vydejni-mista/detail/${to.params.id}`;
				},
			},
			{
				name: 'delivery-point-opening-hours',
				path: 'vydejni-mista/vydejny/oteviraci-doba/:id',
				component: DeliverPointOpeningHoursForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.stores'), link: 'container-list' }, { name: i18n.t('admin.openingHours') }],
				},
			},
			{
				name: 'reservation-list',
				path: 'rezervace',
				component: ReservationList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservation') }],
				},
			},
			{
				name: 'reservation-detail',
				path: 'rezervace/detail/:id',
				component: ReservationForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservation'), link: 'reservation-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'administrator-new',
				path: 'administratori/novy',
				component: AdministratorForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.administrators'), link: 'administrator-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'administrator-edit',
				path: 'administratori/:id',
				component: AdministratorForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.administrators'), link: 'administrator-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'administrator-list',
				path: 'administratori',
				component: AdministratorList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.administrators') }],
				},
			},
			{
				name: 'employee-new',
				path: 'zamestnanci/novy',
				component: EmployeeForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.employees'), link: 'employee-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'employee-edit',
				path: 'zamestnanci/:id',
				component: EmployeeForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.employees'), link: 'employee-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'employee-list',
				path: 'zamestnanci',
				component: EmployeeList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.employees') }],
				},
			},
			{
				name: 'driver-new',
				path: 'ridici/novy',
				component: DriverForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.drivers'), link: 'driver-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'driver-edit',
				path: 'ridici/:id',
				component: DriverForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.drivers'), link: 'driver-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'driver-list',
				path: 'ridici',
				component: DriverList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.drivers') }],
				},
			},

			{
				name: 'logistics-new',
				path: 'logistici/novy',
				component: LogisticsForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.logisticsEmployees'), link: 'logistics-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'logistics-edit',
				path: 'logistici/:id',
				component: LogisticsForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.logisticsEmployees'), link: 'logistics-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'logistics-list',
				path: 'logistici',
				component: LogisticsList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.logisticsEmployees') }],
				},
			},

			{
				name: 'eshop-new',
				path: 'eshopy/novy',
				component: EshopForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.eshop'), link: 'eshops-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'eshop-edit',
				path: 'eshopy/:id',
				component: EshopForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.eshop'), link: 'eshops-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'eshops-list',
				path: 'eshopy',
				component: EshopList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.eshop') }],
				},
			},
			{
				name: 'password-change',
				path: 'zmena-hesla',
				component: BaseChangePasswordForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.passwordChange') }],
				},
			},
			{
				name: 'price-map-list',
				path: 'cenove-mapy',
				component: PriceMapList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.priceMaps') }],
				},
			},
			{
				name: 'price-map-edit',
				path: 'cenove-mapy/:id',
				component: PriceMapForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.priceMaps'), link: 'price-map-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'price-map-new',
				path: 'cenove-mapy/nova',
				component: PriceMapForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.priceMaps'), link: 'price-map-list' }, { name: i18n.t('admin.new-she') }],
				},
			},
			{
				name: 'expired-print',
				path: 'sber-rezervaci',
				component: BaseCollectionPrintSheet,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservationsPickup') }],
				},
			},
			{
				name: 'history-export',
				path: 'export-historie',
				component: HistoryExport,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.historyExport') }],
				},
			},
			{
				name: 'templates',
				path: 'sablony',
				component: TemplateEdit,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.templates') }],
				},
				beforeEnter(to) {
					window.location.href = `${window.location.protocol}//${window.location.host}/v2${to.path}`;
				},
			},
			{
				name: 'templates-default-values',
				path: 'zakladni-hodnoty',
				component: DefaultReplacements,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.defaultEmailAndSmsTemplates') }],
				},
			},
		],
	},
];
