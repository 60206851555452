<template>
	<div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-12">
					<div class="d-flex justify-content-between">
						<div class="align-self-center">
							<p-button type="success" @click="newDefaultReplacement">
								<span class="fa fa-plus" /> {{ $t('admin.newValue') }}
							</p-button>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 mt-2 card">
					<el-table
						class="table-striped"
						:data="replacements"
						show-overflow-tooltip
						row-class-name="tableRow">
						<el-table-column header-align="center" align="center" property="email">
							<template #header>
								<el-tooltip :content="$t('admin.country')" placement="top-start">
									<span>{{ $t('admin.country') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<div :key="scope.row.state">
									{{ GetStateName(scope.row.state) }}
								</div>
							</template>
						</el-table-column>
						<el-table-column header-align="center" align="center" property="email">
							<template #header>
								<el-tooltip :content="$t('admin.replacement')" placement="top-start">
									<span>{{ $t('admin.replacement') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<div :key="scope.row.propertyName">
									{{ scope.row.propertyName }}
								</div>
							</template>
						</el-table-column>
						<el-table-column header-align="center" align="center" property="email">
							<template #header>
								<el-tooltip :content="$t('admin.value')" placement="top-start">
									<span>{{ $t('admin.value') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<div :key="scope.row.value">
									{{ scope.row.value }}
								</div>
							</template>
						</el-table-column>
						<el-table-column header-align="center" align="center" property="email">
							<template #header>
								<el-tooltip :content="$t('admin.target')" placement="top-start">
									<span>{{ $t('admin.target') }}</span>
								</el-tooltip>
							</template>
							<template #default="scope">
								<div :key="scope.row.target">
									{{ GetTargetName(scope.row.target) }}
								</div>
							</template>
						</el-table-column>
						<el-table-column header-align="center" align="center" property="email">
							<template #header />
							<template #default="scope">
								<p-button
									class="mx-1"
									type="danger"
									size="sm"
									icon
									@click.stop="deleteReplacement(scope.row)">
									<i class="fa fa-times" />
								</p-button>
								<p-button
									class="mx-1"
									type="info"
									size="sm"
									icon
									@click.stop="editReplacement(scope.row)">
									<i class="fa fa-pen" />
								</p-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<el-dialog v-if="editing" :close-on-click-modal="false" :visible.sync="editing">
			<replacement-edit :replacement="newReplacement" @show-hide-edit-dialog="hideEditing" />
		</el-dialog>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip, Dialog as ElDialog } from 'element-ui';

import ReplacementEdit from './ReplacementEdit.vue';

export default {
	name: 'DefaultReplacements',
	components: {
		ReplacementEdit,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElDialog,
	},
	data() {
		return {
			replacements: [],
			newReplacement: null,
			editing: false,
		};
	},
	async created() {
		this.replacements = await this.$service.replacements.getReplacements();
	},
	methods: {
		GetStateName(state) {
			if (state === 'cs') {
				return 'Česká republika';
			} if (state === 'sk') {
				return 'Slovensko';
			}
			return state;
		},
		GetTargetName(target) {
			if (target === 'EMail') {
				return 'E-mail';
			}
			return target;
		},
		async deleteReplacement(mail) {
			await this.$service.replacements.delete(mail);
			this.replacements = await this.$service.replacements.getReplacements();
		},
		async editReplacement(mail) {
			this.newReplacement = mail;
			this.editing = true;
		},
		newDefaultReplacement() {
			this.newReplacement = {};
			this.editing = true;
		},
		async hideEditing() {
			this.replacements = await this.$service.replacements.getReplacements();
			this.editing = false;
		},
	},
};
</script>
