<template>
	<div v-if="canDirectSale" v-loading="loading" class="row">
		<div class="col-md-5">
			<card>
				<form>
					<form-group-input
						v-model.number="product.stockId"
						v-validate="validations.stockId.rules"
						data-cy="productSKU"
						:placeholder="$t('eshop.fillStockID')"
						:label="$t('eshop.stockId')"
						:name="validations.stockId.name"
						:error="getError(validations.stockId.name)"
						:data-vv-as="$t('eshop.stockId')"
						type="number"
						required
						:readonly="readonly" />
					<form-group-input
						v-model="product.name"
						v-validate="validations.name.rules"
						data-cy="productName"
						:placeholder="$t('eshop.fillName')"
						:label="$t('eshop.name')"
						:name="validations.name.name"
						:error="getError(validations.name.name)"
						:data-vv-as="$t('eshop.name')"
						required
						:readonly="readonly" />
					<div class="form-group">
						<label>
							{{ $t('eshop.description') }}
							<span class="text-danger">*</span>
						</label>
						<el-input
							v-model="product.description"
							v-validate="validations.description.rules"
							:name="validations.description.name"
							data-cy="productDescription"
							:placeholder="$t('eshop.fillDescription')"
							type="textarea"
							autosize
							required
							:readonly="readonly" />
					</div>
					<form-group-input
						v-model="product.category"
						v-validate="validations.category.rules"
						data-cy="productCategory"
						:placeholder="$t('eshop.fillCategory')"
						:label="$t('eshop.category')"
						:name="validations.category.name"
						:error="getError(validations.category.name)"
						:data-vv-as="$t('eshop.category')"
						required
						:readonly="readonly" />
					<form-group-input
						v-model="product.brand"
						v-validate="validations.brand.rules"
						data-cy="productBrand"
						:placeholder="$t('eshop.fillBrand')"
						:label="$t('eshop.brand')"
						:name="validations.brand.name"
						:error="getError(validations.brand.name)"
						:data-vv-as="$t('eshop.brand')"
						required
						:readonly="readonly" />
					<form-group-input :error="getError(validations.price.name)" :label="$t('eshop.price')" required>
						<el-input
							v-model="product.price"
							v-validate="validations.price.rules"
							data-cy="productPrice"
							:placeholder="$t('eshop.fillPrice')"
							:name="validations.price.name"
							:data-vv-as="$t('eshop.price')"
							:readonly="readonly"
							@input="czechDecimalParser">
							<span slot="append"> {{ $t('eshop.currencySymbol') }} </span>
						</el-input>
					</form-group-input>
					<form-group-input
						v-model="product.size"
						data-cy="productSize"
						:placeholder="$t('eshop.fillSize')"
						:label="$t('eshop.size')"
						:name="validations.size.name"
						:error="getError(validations.size.name)"
						:data-vv-as="$t('eshop.size')"
						:readonly="readonly" />
					<p-checkbox
						v-if="!readonly"
						v-model="product.allowDirectSale"
						readonly="readonly"
						class="mt-3">
						{{ $t('eshop.allowDirectSale') }}
					</p-checkbox>
					<form-group-input :error="getError(validations.queueLength.name)" :label="$t('eshop.queueLength')">
						<el-input
							v-model.number="product.directSaleQueueCount"
							data-cy="DirectSaleQueueCount"
							:placeholder="$t('eshop.fillQueueLength')"
							:disabled="!product.allowDirectSale"
							:name="validations.queueLength.name"
							:data-vv-as="$t('eshop.queueLength')"
							:readonly="readonly">
							<span slot="append"> {{ $t('eshop.piece') }} </span>
						</el-input>
					</form-group-input>
					<form-group-input :error="getError(validations.expirationTime.name)" :label="$t('eshop.expirationTime')">
						<el-input
							v-model.number="product.directSaleExpirationDays"
							v-validate="validations.expirationTime.rules"
							data-cy="DirectSaleExpirationDays"
							:placeholder="$t('eshop.fillExpirationTime')"
							:disabled="!product.allowDirectSale"
							:name="validations.expirationTime.name"
							:data-vv-as="$t('eshop.expirationTime')"
							:readonly="readonly">
							<span slot="append">
								{{ $t(translateWithNumber('eshop', 'day', product.directSaleExpirationDays)) }}
							</span>
						</el-input>
					</form-group-input>
					<p-checkbox
						v-if="!readonly"
						v-model="product.allowRent"
						:disabled="!product.allowDirectSale"
						readonly="readonly"
						class="mt-3">
						{{ $t('eshop.allowRent') }}
					</p-checkbox>
					<el-tooltip v-if="editing && allowDelete && !readonly" :content="$t('eshop.tooltips.deleteStockItem')" placement="top-start">
						<confirm-button
							data-cy="deleteProductBtn"
							type="danger"
							:confirm-message="$t('eshop.deleteStockItemMessage')"
							:message-success="$t('eshop.deleteStockItemSuccess')"
							:message-error="$t('eshop.deleteStockItemError')"
							:callback="handleDelete">
							{{ $t('eshop.delete') }}
						</confirm-button>
					</el-tooltip>

					<confirm-button
						v-if="!readonly"
						data-cy="saveProductBtn"
						class="pull-right"
						:show-success-message="false"
						:confirm-message="editing ? $t('eshop.productUpdate') : $t('eshop.productCreate')"
						:disabled="!enabledSaveButton"
						:callback="validateAndSave">
						{{ $t('eshop.save') }}
					</confirm-button>
					<div class="clearfix" />
					<hr>
					<p class="note text-muted">
						{{ $t('eshop.required') }}
					</p>
				</form>
			</card>
		</div>
		<div v-if="editing && productExists" class="col-md-5">
			<direct-sale-gallery-card :stock-item-id="stockItemId" :readonly="readonly" />
		</div>
		<div v-if="editing && !readonly && productExists" class="col-12">
			<card>
				<base-container-boxes-tree-view
					v-model="selectedBoxes"
					:current-item="Number(product.id)"
					:result-message="assignResultMessage"
					:question-message="$t('global.assignItem.message')"
					@callback="assignProductToBoxes" />
			</card>
		</div>
	</div>
	<div v-else class="row">
		<h2>{{ $t('eshop.userDoesntAllowDirectSale') }}</h2>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import { Tooltip as ElTooltip, Input as ElInput } from 'element-ui';

import FormMixin from '@/util/form-mixin';
import { translateWithNumber } from '@/util/helpers';
// eslint-disable-next-line import/extensions,import/no-unresolved
import ValidationMixin from '@/util/validated-form-mixin';
import Validations from '@/util/validations';
import { BaseContainerBoxesTreeView } from '@/components/Base';
import { appName } from '@/modules/eshop/config';
import { formChanged } from '@/store/mutation-types';

import DirectSaleGalleryCard from './DirectSaleGalleryCard.vue';

export default {
	name: 'DirectSaleProductForm',
	components: {
		ElTooltip,
		ElInput,
		DirectSaleGalleryCard,
		BaseContainerBoxesTreeView,
	},
	mixins: [ValidationMixin, FormMixin],
	props: {
		stockItem: {
			type: Object,
			default: null,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			translateWithNumber,
			loading: false,
			productLoaded: false,
			allowDelete: true,
			product: {},
			selectedBoxes: [],
			assignResultMessage: {
				show: false,
				successMessage: null,
				errorMessages: null,
			},
			validations: {
				stockId: {
					name: 'stockId',
					rules: { ...Validations.required, ...Validations.integer, ...Validations.sku },
				},
				queueLength: {
					name: 'queueLength',
					rules: { ...Validations.required, ...Validations.integer, ...Validations.inRange },
				},
				expirationTime: {
					name: 'expirationTime',
					rules: { ...Validations.integer, ...Validations.inRange },
				},
				name: {
					name: 'name',
					rules: Validations.required,
				},
				description: {
					name: 'description',
					rules: Validations.required,
				},
				category: {
					name: 'category',
					rules: Validations.required,
				},
				brand: {
					name: 'brand',
					rules: Validations.required,
				},
				price: {
					name: 'price',
					rules: { ...Validations.required, ...Validations.decimal, ...Validations.inRange },
				},
				size: {
					name: 'size',
				},
			},
		};
	},
	computed: {
		...mapState(appName, ['currentUser']),
		canDirectSale() {
			return this.currentUser.DirectSale === 'True';
		},
		productId() {
			return this.$route.params.id;
		},
		productExists() {
			return !_.isEmpty(this.product);
		},
		editing() {
			if (this.stockItem) {
				return true;
			}
			return !!this.productId;
		},
		stockItemId() {
			return (this.stockItem && this.stockItem.id) || Number(this.$route.params.id);
		},
		enabledSaveButton() {
			return !!(this.product.stockId && this.product.name && this.product.description && this.product.category && this.product.brand && this.product.price);
		},
	},
	watch: {
		product: {
			handler() {
				if (this.productLoaded) {
					this.$store.commit(`${appName}/${formChanged}`, true);
				}
			},
			deep: true,
		},
	},
	async created() {
		this.loadData();
		this.prepareCustomErrorMessages();
	},
	updated() {
		this.productLoaded = true;
	},
	methods: {
		async loadData() {
			if (this.stockItem) {
				this.allowDelete = false;
				this.product = this.stockItem;
			} else if (this.$route.params.id) {
				this.product = await this.$service.stockItem.getOne(this.$route.params.id);
				const { entities } = await this.$service.box.getList(1, { StockItems: [this.$route.params.id] }, 1000);
				this.selectedBoxes = entities;
			}
		},
		async save() {
			this.loading = true;
			this.product.stockId = Number(this.product.stockId);
			this.product.price = Number(this.product.price);
			this.product.directSaleQueueCount = this.product.allowDirectSale ? this.product.directSaleQueueCount : 0;
			if (!this.editing) {
				this.product.allowRent = this.product.allowRent && this.product.allowDirectSale;
				this.product = await this.$service.stockItem.addStockItem(this.product);
				this.loading = false;
				this.$store.commit(`${appName}/${formChanged}`, false);
				this.$router.push({ name: 'direct-sale-product-edit', params: { id: this.product.id } });
			} else {
				this.$store.commit(`${appName}/${formChanged}`, false);
				const stockItem = {
					stockId: this.product.stockId,
					name: this.product.name,
					description: this.product.description,
					category: this.product.category,
					brand: this.product.brand,
					price: this.product.price,
					size: this.product.size,
					allowDirectSale: this.product.allowDirectSale,
					directSaleQueueCount: this.product.directSaleQueueCount,
					directSaleExpirationDays: this.product.directSaleExpirationDays,
					id: this.product.id,
					allowRent: this.product.allowRent && this.product.allowDirectSale,
				};
				await this.$service.stockItem.updateStockItem(stockItem);
				this.$store.commit(`${appName}/${formChanged}`, false);
				this.loading = false;
				this.$router.push({ name: 'direct-sale-list' });
			}
		},

		async handleDelete() {
			await this.$service.stockItem.deleteStockItem(this.product.id);
			this.$store.commit(`${appName}/${formChanged}`, false);
			this.$router.push({ name: 'direct-sale-list' });
		},
		prepareCustomErrorMessages() {
			const dict = {
				custom: {
					price: {
						regex: this.$t('eshop.validationDecimal2digits'),
					},
				},
			};

			this.$validator.localize('cs', dict);
		},
		czechDecimalParser(value) {
			if (value.includes(',')) {
				this.product.price = value.replace(',', '.');
			}
		},
		setResultMessage(show = false, successMessage = null, errorMessages = null) {
			this.assignResultMessage.show = show;
			this.assignResultMessage.successMessage = successMessage;
			this.assignResultMessage.errorMessages = errorMessages;
		},
		async assignProductToBoxes() {
			this.loading = true;

			const promises = this.selectedBoxes.map((box) => this.$service.box.directSale(box.removed ? null : this.product.id, box.boxId, false));
			const results = await Promise.all(promises.map((p) => p.catch((e) => e)));
			// Filter failed requests results
			const failedResults = results.filter((result) => typeof result.status !== 'string');
			// Set success message
			if (!failedResults.length) {
				const successMessage = this.$t('eshop.assignStockItem.toBoxesSuccess');
				this.setResultMessage(true, successMessage, null);
				// timeout to close success message
				setTimeout(() => {
					this.setResultMessage();
				}, 4000);
				// Set error message
			} else {
				const failedBoxesMessage = failedResults.map((result) => {
					const failedBox = this.selectedBoxes.filter((selectedBox) => selectedBox.boxId === JSON.parse(result.config.data).boxId)[0];
					const { boxSn } = failedBox;
					const container = `${failedBox.container.city}, ${failedBox.container.street}, ${failedBox.container.location} - ${failedBox.container.serialNumber}`;
					const responseMessage = result.data.errors.general.join();
					const message = `${boxSn}. ${this.$t('eshop.container')}: ${container}. ${responseMessage}`;
					return `${this.$t('eshop.assignStockItem.toBoxesError')} ${message}`;
				});
				this.setResultMessage(true, null, failedBoxesMessage);
				// timeout to close error message
				setTimeout(() => {
					this.setResultMessage();
				}, 20000);
			}
			this.loadData();
			this.loading = false;
		},
	},
};
</script>

<style scoped>
.note {
	font-size: 80%;
}
.el-textarea >>> .el-textarea__inner {
	padding: 10px;
}
</style>
