<template>
	<div>
		<div class="row">
			<div class="col-12">
				<h3>{{ $t('global.assignItem.toBoxes') }}</h3>
			</div>
			<div v-if="resultMessage.show" class="col-12 mb-1">
				<el-alert
					v-if="resultMessage.successMessage"
					:title="resultMessage.successMessage"
					center
					show-icon
					type="success" />
				<div v-else>
					<div v-for="message in resultMessage.errorMessages" :key="message">
						<el-alert
							:title="message"
							center
							class="mb-1"
							show-icon
							type="error" />
					</div>
				</div>
			</div>
		</div>
		<div class="row m-0">
			<div class="col-4">
				<h6 class="mt-1">
					{{ $t('global.selectBoxes') }}
				</h6>

				<div class="row">
					<div class="col-6">
						<autocomplete
							v-model="deliveryPointFilter.city"
							:label="$t('global.city')"
							search-field-name="Cities"
							trigger-on-focus
							@fetch-suggestions="querySearchAsync" />

						<form-group-input
							v-model="serialNumberFrom"
							:label="$t('global.serialNumber')"
							:placeholder="$t('global.exactMatch')"
							type="text" />
					</div>
					<div class="col-6">
						<autocomplete
							v-model="deliveryPointFilter.street"
							:label="$t('global.street')"
							search-field-name="Streets"
							trigger-on-focus
							@fetch-suggestions="querySearchAsync" />

						<div class="justify-content-center d-flex">
							<p-checkbox v-model="filter.onlyEmpty">
								{{ $t('global.ShowOnlyWithoutEshop') }}
							</p-checkbox>
						</div>
						<div v-loading="loading" class="justify-content-end d-flex">
							<p-button
								class="btn-sm-filter"
								size="sm"
								type="success"
								@click="findContainer">
								{{ $t('global.search') }}
							</p-button>
							<el-tooltip :content="$t('global.tooltips.clearFilter')" placement="top-start">
								<p-button
									class="ml-2"
									icon
									size="sm"
									type="danger"
									@click="clearFilter">
									<i class="fa fa-times" />
								</p-button>
							</el-tooltip>
						</div>
					</div>
				</div>

				<div class="mt-2 tree-container">
					<base-containers-tree-view
						ref="tree"
						:filter="filterToTree"
						:value="selectedBoxes"
						boxes
						only-check
						@add="addToSelected"
						@loading="setLoading"
						@remove="removeFromSelected"
						@tree-loading="setTreeLoading" />
				</div>
			</div>

			<div class="col-8">
				<div>
					<h6>
						{{ $t('global.selectedBoxes') }}
					</h6>
					<div class="pull-right mb-2 mt-n5 pt-2">
						<confirm-button
							:callback="() => $emit('callback')"
							:confirm-message="questionMessage"
							:show-success-message="false"
							type="success">
							{{ $t('global.assignItem.name') }}
						</confirm-button>
					</div>
					<div class="col-12 mb-2 d-flex justify-content-center">
						<el-pagination
							layout="total, sizes, prev, pager, next, jumper"
							v-bind="pagination"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
					<el-table
						:data="pageData"
						:empty-text="$t('global.selectContainers')"
						:highlight-current-row="highlightCurrentRow"
						class="pl-1 mb-3"
						row-class-name="table-row-box"
						row-key="boxId"
						@row-click="goToContainer">
						<el-table-column v-if="eshopMode" width="50">
							<template #default="scope">
								<el-tooltip
									v-if="!scope.row.occupiedBy || scope.row.occupiedBy.id != currentEshop"
									:content="$t('global.tooltips.discardAssignEshop')"
									placement="left">
									<p-button type="warning" @click.stop="removeFromSelected(scope.row)">
										<i class="fa fa-plus" />
									</p-button>
								</el-tooltip>
								<el-tooltip v-else-if="scope.row.removed" :content="$t('global.tooltips.reverseAssignEshop')" placement="left">
									<p-button type="danger" @click.stop="addToSelected(scope.row)">
										<i class="fa fa-minus" />
									</p-button>
								</el-tooltip>
								<el-tooltip v-else :content="$t('global.tooltips.removeEshop')" placement="left">
									<p-button type="success" @click.stop="removeFromSelected(scope.row)">
										<i class="fa fa-check" />
									</p-button>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column v-else width="50">
							<template #default="scope">
								<el-tooltip
									v-if="!scope.row.stockItem || scope.row.stockItem.id != currentItem"
									:content="$t('global.tooltips.discardAssignEshop')"
									placement="left">
									<p-button type="warning" @click.stop="removeFromSelected(scope.row)">
										<i class="fa fa-plus" />
									</p-button>
								</el-tooltip>
								<el-tooltip v-else-if="scope.row.removed" :content="$t('global.tooltips.reverseAssignDirectSale')" placement="left">
									<p-button type="danger" @click.stop="addToSelected(scope.row)">
										<i class="fa fa-minus" />
									</p-button>
								</el-tooltip>
								<el-tooltip v-else :content="$t('global.tooltips.removeDirectSale')" placement="left">
									<p-button type="success" @click.stop="removeFromSelected(scope.row)">
										<i class="fa fa-check" />
									</p-button>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column :label="$t('global.container')">
							<template #default="scope">
								{{ scope.row.container | location }}
							</template>
						</el-table-column>
						<el-table-column :label="$t('global.boxNumber')">
							<template #default="scope">
								{{ scope.row.boxSn }}
							</template>
						</el-table-column>
						<el-table-column v-if="eshopMode" :label="$t('global.currentEshopAssigned')">
							<template #default="scope">
								<span :class="highlightItem(scope.row.eshopId)">
									{{ scope.row.occupiedBy ? scope.row.occupiedBy.name : ' ' }}
								</span>
							</template>
						</el-table-column>
						<el-table-column v-else :label="$t('global.currentStockItemAssigned')">
							<template #default="scope">
								<span :class="highlightItem(scope.row.stockItem)">
									{{ scope.row.stockItem.name }}
								</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="col-12 d-flex justify-content-center">
						<el-pagination
							layout="total, sizes, prev, pager, next, jumper"
							v-bind="pagination"
							@current-change="loadData"
							@size-change="loadDataPageSize" />
					</div>
					<div class="pull-right mt-2">
						<confirm-button
							:callback="() => $emit('callback')"
							:confirm-message="questionMessage"
							:show-success-message="false"
							type="success">
							{{ $t('global.assignItem.name') }}
						</confirm-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Alert as ElAlert, Tooltip as ElTooltip, Pagination as ElPagination } from 'element-ui';
import _ from 'lodash';

import BaseContainersTreeView from './BaseContainersTreeView.vue';

export default {
	name: 'BaseContainerBoxesTreeView',
	components: {
		ElTable,
		ElTableColumn,
		ElAlert,
		ElTooltip,
		ElPagination,
		BaseContainersTreeView,
	},
	props: {
		value: { type: Array, default: () => [] },
		eshopMode: {
			type: Boolean,
			default: false,
		},
		currentItem: {
			type: Number,
			default: null,
		},
		currentEshop: {
			type: Number,
			default: null,
		},
		resultMessage: {
			type: Object,
			default: () => ({
				show: false,
				successMessage: null,
				errorMessages: null,
			}),
		},
		questionMessage: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			pagination: {
				pageSize: 10,
				pageSizes: [10, 20, 50, 100],
				pagesCount: 0,
				currentPage: 1,
				total: 0,
			},
			pageData: [],
			filter: {
				container: '',
				serialNumber: '',
				onlyEmpty: false,
			},
			loading: false,
			selectedBoxes: [],
			currentRow: null,
			highlightCurrentRow: false,
			deliveryPointsPage: Object,
			filterToTree: {},
			treeLoading: false,
			abortController: null,
		};
	},
	computed: {
		deliveryPointFilter: {
			get() {
				return this.$store.state[this.currentAppName].deliveryPointFilter;
			},
			set(val) {
				this.$store.state[this.currentAppName].deliveryPointFilter = val;
			},
		},
		serialNumberFrom: {
			get() {
				return this.deliveryPointFilter.serialNumber.from;
			},
			set(val) {
				this.deliveryPointFilter.serialNumber.from = val;
			},
		},
		serialNumberTo: {
			get() {
				return this.deliveryPointFilter.serialNumber.to;
			},
			set(val) {
				this.deliveryPointFilter.serialNumber.to = val;
			},
		},
	},
	watch: {
		value: {
			async handler(val) {
				// sortBy creates new arrays, so we don't mutate the original ones
				if (!_.isEqual(_.sortBy(this.selectedBoxes), _.sortBy(val))) {
					this.selectedBoxes = val;
					await this.$refs.tree.init();
				}
			},
			deep: true,
		},
		selectedBoxes: {
			async handler(value) {
				this.loadData(this.pagination.currentPage);
				this.refreshPageMetaData(value);
			},
			deep: true,
		},
		serialNumberFrom: {
			async handler(val) {
				this.deliveryPointFilter.serialNumber.to = val;
			},
		},
	},
	async created() {
		this.selectedBoxes = this.value;
		this.loadData();
		this.refreshPageMetaData(this.selectedBoxes);
	},
	methods: {
		goToContainer(container) {
			this.$refs.tree.goToContainer(container);
		},
		setTreeLoading(treeLoading) {
			this.treeLoading = treeLoading;
		},
		setLoading(loading) {
			this.setLoading = loading;
		},
		loadData(page = 1) {
			this.loading = true;
			const { pageSize } = this.pagination;
			const start = (page - 1) * pageSize;
			const end = start + pageSize;
			this.pageData = this.selectedBoxes.slice(start, end);
			this.pagination.currentPage = page;

			this.loading = false;
		},
		refreshPageMetaData(selectedBoxes) {
			this.pagination.pagesCount = Math.ceil(selectedBoxes.length / this.pagination.pageSize);
			this.pagination.total = selectedBoxes.length;
		},
		loadDataPageSize(pageSize) {
			this.pagination.pageSize = pageSize;
			this.loadData(this.pagination.currentPage);
		},
		highlightItem(itemId) {
			return Number(this.$route.params.id) === itemId ? 'font-weight-bold' : null;
		},
		handleCurrentRowChange(val) {
			this.currentRow = val.boxId;
			this.highlightCurrentRow = true;
			setTimeout(() => {
				this.currentRow = null;
				this.highlightCurrentRow = false;
			}, 1500);
		},
		addToSelected(box, cb) {
			const selectedBox = this.selectedBoxes.find((x) => x.boxId === box.boxId);
			if (!selectedBox) {
				this.selectedBoxes.push({
					boxId: box.boxId,
					boxSn: box.boxSn,
					container: box.container,
					stockItem: box.stockItem,
					occupiedBy: box.occupiedBy,
					removed: false,
					added: true,
				});
			} else {
				selectedBox.removed = false;
				this.selectedBoxes.splice(this.selectedBoxes.indexOf(selectedBox), 1);
				this.selectedBoxes.unshift(selectedBox);
			}

			if (cb) {
				cb(box);
			} else {
				this.$refs.tree.addToSelected(box);
			}

			this.value = this.selectedBoxes;
			this.$emit('input', this.value);
			this.handleCurrentRowChange(box);
		},
		removeFromSelected(box, cb) {
			const index = this.selectedBoxes.findIndex((selectedBox) => selectedBox.boxId === box.boxId);
			if (index > -1) {
				const spliced = this.selectedBoxes.splice(index, 1);
				if (this.eshopMode && spliced[0].occupiedBy.id === this.currentEshop) {
					spliced[0].removed = true;
					this.selectedBoxes.push(spliced[0]);
				} else if (!this.eshopMode && spliced[0].stockItem.id === this.currentItem) {
					spliced[0].removed = true;
					this.selectedBoxes.push(spliced[0]);
				}
			}

			if (cb) {
				cb(box);
			} else {
				this.$refs.tree.removeFromSelected(box);
			}

			this.$emit('input', this.selectedBoxes);
			this.handleCurrentRowChange(box);
		},
		handleDelete(index, row) {
			this.removeFromSelected(row);
		},
		querySearchAsync(input, cb, searchFieldName) {
			this.abortController?.abort();
			this.abortController = new AbortController();
			this.$service.deliveryPoint.searchByLocationInput(input, searchFieldName, this.deliveryPointFilter, this.abortController)
				.then((results) => {
					cb(results);
				});
		},
		async findContainer() {
			this.loading = true;

			this.filterToTree = { ...this.filter, deliveryPoint: this.deliveryPointFilter };

			if (this.deliveryPointFilter.serialNumber.from || this.deliveryPointFilter.city || this.deliveryPointFilter.street) {
				this.deliveryPointsPage = await this.$service.deliveryPoint.getList(this.deliveryPointFilter, 1, 10000);
				this.deliveryPointsPage.entities.forEach((entity) => this.$refs.tree.expandContainerNode(entity));
			}

			this.$refs.tree.makeFilter();

			this.loading = false;
		},
		clearFilter() {
			this.filter = {
				onlyEmpty: false,
			};
			this.deliveryPointFilter.serialNumber.from = null;
			this.deliveryPointFilter.city = null;
			this.deliveryPointFilter.street = null;

			this.findContainer();
		},
	},
};
</script>

<style lang="scss" scoped>
.el-tree-node__content:hover .openAll {
	display: inline;
	font-size: 10px;
}

.openAll {
	display: none;
}

.highlight-node {
	font-weight: bold;
}

.tree-container {
	height: 35rem;
	overflow: auto;
	border-top: 1px solid silver;
}
::v-deep {
	.table-row-box {
		cursor: pointer;
	}
}
.btn-sm-filter {
	padding: 5px 15px;
}
</style>
