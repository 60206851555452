<template>
	<div v-loading="loading" class="row">
		<div class="col-12">
			<base-stepper v-if="!emptyReservation && eshopName" :trace="reservation.trace" />
		</div>
		<div class="col-sm-8">
			<card class="col">
				<div class="card-body">
					<h2 class="card-title">
						{{ $t('admin.reservationDetail') }} {{ reservation.reservationId }}
					</h2>
					<div v-if="reservation.isUnreliable" class="unreliable-hint">
						{{ $t('admin.unreliableHint') }}
					</div>
					<div v-if="showReservationCard" data-cy="editPageContent">
						<form-group-input :label="$t('admin.occupiedFrom')" disabled>
							<date-picker
								:value="reservation.from | datetime"
								type="datetime"
								prefix-icon="el-icon-date"
								value-format="dd.MM.yyyy HH:mm"
								format="dd.MM.yyyy HH:mm"
								readonly />
						</form-group-input>
						<form-group-input :label="$t('admin.subscriptionEnd')" disabled>
							<date-picker
								:value="reservation.to | datetime"
								type="datetime"
								prefix-icon="el-icon-date"
								value-format="dd.MM.yyyy HH:mm"
								format="dd.MM.yyyy HH:mm"
								readonly />
						</form-group-input>
						<form-group-input :label="$t('admin.pickedUp')" disabled>
							<date-picker
								:value="reservation.realEnd | datetime"
								type="datetime"
								prefix-icon="el-icon-date"
								value-format="dd.MM.yyyy HH:mm"
								format="dd.MM.yyyy HH:mm"
								readonly />
						</form-group-input>
						<div class="d-flex">
							<div class="col-sm-6 pl-0">
								<form-group-input :label="$t('admin.boxNumber')" :value="lockAliasOrSerialNumber" readonly />
							</div>
							<div class="col-sm-6 pr-0">
								<form-group-input :label="$t('admin.boxType')" :value="BoxType.czech(reservation.type)" readonly />
							</div>
						</div>
						<form-group-input :label="$t('admin.orderNumber')" :value="reservation.reservationNumber" readonly />
						<form-group-input :label="$t('admin.state')" :value="statusCzech(reservation.status)" readonly />
						<form-group-input :label="$t('admin.eshop')" :value="eshopName" readonly />
						<form-group-input :label="$t('admin.email')" :value="reservation.email" readonly />
						<form-group-input :label="$t('admin.phone')" :value="reservation.phone | phone" readonly />
						<form-group-input :label="$t('admin.boxAddress')" :value="getAddressBoxValue(reservation)" readonly />
						<fieldset class="form-group border p-3">
							<legend class="w-auto px-2 mb-n2 control-label">
								{{ $t('admin.customerAddress') }}
							</legend>
							<div class="row">
								<div class="col-sm-6">
									<form-group-input :label="$t('admin.country')" :value="reservation.customer && reservation.customer.state" readonly />
									<form-group-input :label="$t('admin.county')" :value="reservation.customer && reservation.customer.county" readonly />
									<form-group-input :label="$t('admin.zip')" :value="reservation.customer && reservation.customer.zip" readonly />
								</div>
								<div class="col-sm-6">
									<form-group-input :label="$t('admin.region')" :value="reservation.customer && reservation.customer.region" readonly />
									<form-group-input :label="$t('admin.city')" :value="reservation.customer && reservation.customer.city" readonly />
									<form-group-input :label="$t('admin.street')" :value="reservation.customer && reservation.customer.street" readonly />
								</div>
							</div>
						</fieldset>
						<form-group-input
							v-if="reservation.createdBy"
							:label="$t('admin.createdByUser')"
							:value="nameAndUserName"
							readonly />
						<div class="d-flex justify-content-between">
							<div v-if="canEditReservation">
								<el-tooltip :content="$t('admin.tooltips.updateReservation')" placement="top-start">
									<p-button type="success" size="md" @click="showHideUpdateReservation">
										{{ $t('admin.updateReservation') }}
									</p-button>
								</el-tooltip>
							</div>
							<div v-if="canGenerateNewCode">
								<el-tooltip :content="$t('admin.generateNewCode')" placement="top-start">
									<p-button type="primary" size="md" @click="showGenerateNewCode">
										{{ $t('admin.generateNewCode') }}
									</p-button>
								</el-tooltip>
							</div>
							<div v-if="canDiscard">
								<confirm-button
									:message-success="$t('admin.reservationDiscarded')"
									:message-error="$t('admin.reservationDiscardError')"
									:confirm-message="$t('global.confirmDiscardReservation')"
									:callback="discardReservation"
									:show-input="true"
									:input-description="$t('global.requiredNoteDialogText')"
									:require-input="true">
									{{ $t('admin.discardReservation') }}
								</confirm-button>
							</div>
							<div v-if="canMarkUndelivered">
								<confirm-button
									:message-success="$t('admin.reservationUndelivered')"
									:message-error="$t('admin.reservationUndeliveredError')"
									:confirm-message="$t('admin.confirmUndeliveredReservation')"
									:callback="undeliveredReservation"
									:show-input="true"
									:input-description="$t('global.requiredNoteDialogText')"
									:require-input="true">
									{{ $t('admin.undeliveredReservation') }}
								</confirm-button>
							</div>
							<div v-if="canReactivate">
								<el-tooltip :content="$t('admin.tooltips.returnPickUp')" placement="top-start">
									<p-button type="success" size="md" @click="reactivateReservation">
										{{ $t('admin.returnToPickUp') }}
									</p-button>
								</el-tooltip>
							</div>
							<div v-if="canDeliver">
								<el-tooltip :content="$t('admin.tooltips.deliver')" placement="top-start">
									<p-button type="success" size="md" @click="deliverReservation">
										{{ $t('admin.deliver') }}
									</p-button>
								</el-tooltip>
							</div>
							<div v-if="canPickup">
								<el-tooltip :content="$t('admin.pickup')" placement="top-start">
									<p-button type="success" size="md" @click="tryPickupReservation">
										{{ $t('admin.pickup') }}
									</p-button>
								</el-tooltip>
							</div>
						</div>
					</div>
				</div>
			</card>
		</div>
		<div class="col-sm-4">
			<div class="row">
				<card v-if="reservation.containerId" class="col">
					<div class="card-body">
						<el-tooltip :content="getContainerTooltip(container.type)" placement="top-start">
							<router-link :to="{ name: getRouteName(container.type), params: { id: container.containerId } }">
								<h2 class="card-title">
									{{ getContainerLabel(container.type) }} {{ container.serialNumber }}
								</h2>
							</router-link>
						</el-tooltip>
						<form-group-input :label="$t('admin.country')" :value="container.state" readonly />
						<form-group-input :label="$t('admin.city')" :value="container.city" readonly />
						<form-group-input :label="$t('admin.street')" :value="container.street" readonly />
						<form-group-input :label="$t('admin.location')" :value="container.location" readonly />
					</div>
				</card>
			</div>
			<div v-if="showGallery" class="row">
				<card class="col">
					<el-table :data="gallery">
						<el-table-column v-slot="scope">
							{{ statusCzech(scope.row.status) }}
						</el-table-column>
						<el-table-column v-slot="scope">
							{{ scope.row.dateOfAction | datetime }}
						</el-table-column>
						<el-table-column v-if="!imagesLoaded" v-slot="scope">
							<el-button
								type="primary"
								:loading="loading"
								@click="() => loadImages(scope.row)">
								{{ $t('admin.showPhotos') }}
							</el-button>
						</el-table-column>
						<el-table-column v-if="imagesLoaded" v-slot="scope">
							<el-image
								v-if="scope.row.before"
								:src="`data:image/png;base64, ${scope.row.before}`"
								:preview-src-list="[`data:image/png;base64, ${scope.row.before}`]" />
							<span v-else>
								{{ $t('global.noData') }}
							</span>
						</el-table-column>
						<el-table-column v-if="imagesLoaded" v-slot="scope">
							<el-image
								v-if="scope.row.after"
								:src="`data:image/png;base64, ${scope.row.after}`"
								:preview-src-list="[`data:image/png;base64, ${scope.row.after}`]" />
							<span v-else>
								{{ $t('global.noData') }}
							</span>
						</el-table-column>
					</el-table>
				</card>
			</div>
			<reservation-note :reservation="reservation" />
		</div>
		<el-dialog v-if="!emptyReservation" :visible.sync="updateReservationVisible">
			<base-update-reservation
				:allow-edit-address="!reservation.containerId"
				:reservation-id="reservation.reservationId"
				:reserved-from="reservation.from"
				:reserved-to="reservation.to"
				:customer="reservationCustomerData"
				:customer-old-address="reservation.customerAddress"
				@refresh-reservation="refreshReservation" />
		</el-dialog>
		<el-dialog v-if="!emptyReservation" :visible.sync="generateNewCodeVisible">
			<generate-new-code
				:reservation-id="reservation.reservationId"
				:email="reservation.email"
				:phone="reservation.phone"
				@hide-generate-new-code="hideGenerateNewCode" />
		</el-dialog>
		<el-dialog :visible.sync="addNoteForPickupVisible">
			<div class="form-group mb-3">
				<label>
					{{ $t('admin.noteForPickup') }}
				</label>
				<el-input
					v-model="noteForPickup"
					:label="$t('admin.noteForPickup')"
					:placeholder="$t('eshop.notePlaceholder')"
					:rows="3"
					class="text-monospace"
					name="noteText"
					type="textarea"
					:disabled="loading" />
			</div>
			<p-button
				:disabled="loading || noteIsEmpty"
				type="success"
				size="md"
				@click="addPickUpNote">
				{{ $t('admin.pickup') }}
			</p-button>
		</el-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { DatePicker, Image as ElImage, Table as ElTable, TableColumn as ElTableColumn, Dialog as ElDialog, Tooltip as ElTooltip } from 'element-ui';
import { assign, isEmpty } from 'lodash';

import ReservationNote from '@/components/Base/Shared/ReservationNote.vue';
import notifyService from '@/service/notify-service';
import { ReservationStatus } from '@/modules/admin/store/enums';
import { BoxType } from '@/modules/eshop/store/enums';
import { BaseStepper, BaseUpdateReservation } from '@/components/Base';
import FormMixin from '@/util/form-mixin';
import { containerTypes } from '@/globalConstants';
import ReservationDetailMixin from '@/util/reservation-detail-mixin';
import ReservationFormMixin from '@/util/reservation-form-mixin';
import LockAliasMixin from '@/util/lock-alias-mixin';

import GenerateNewCode from './ReservationForm/GenerateNewCode.vue';

export default {
	name: 'ReservationForm',
	components: {
		DatePicker,
		ElImage,
		ElTable,
		ElTableColumn,
		ElDialog,
		BaseUpdateReservation,
		ElTooltip,
		BaseStepper,
		GenerateNewCode,
		ReservationNote,
	},
	mixins: [FormMixin, ReservationDetailMixin, ReservationFormMixin, LockAliasMixin],
	data() {
		return {
			loading: false,
			container: {},
			gallery: [],
			updateReservationVisible: false,
			generateNewCodeVisible: false,
			BoxType,
			lockAliasOrSerialNumber: this.$t('admin.loading'),
			imagesLoaded: false,
			addNoteForPickupVisible: false,
			noteForPickup: null,
		};
	},
	computed: {
		...mapState(['theSpecialModalInputText']),
		canEditReservation() {
			const { isEditable, status } = this.reservation;
			const { PREPARED, ACTIVE, WAIT_FOR_DELIVERY, WAIT_FOR_RETURN, WAIT_FOR_PACKAGE, WAIT_FOR_RETURN_WITH_RESERVATION } = ReservationStatus;

			return isEditable && [PREPARED, ACTIVE, WAIT_FOR_DELIVERY, WAIT_FOR_RETURN, WAIT_FOR_PACKAGE, WAIT_FOR_RETURN_WITH_RESERVATION]
				.includes(ReservationStatus.enumValueOf(status));
		},
		canGenerateNewCode() {
			return [ReservationStatus.PREPARED,
				ReservationStatus.WAIT_FOR_RETURN,
				ReservationStatus.WAIT_FOR_PACKAGE,
				ReservationStatus.WAIT_FOR_RETURN_WITH_RESERVATION]
				.includes(
					ReservationStatus.enumValueOf(this.reservation.status),
				) && (this.container.type !== containerTypes.smartBox);
		},
		showReservationCard() {
			return !isEmpty(this.reservation);
		},
		emptyReservation() {
			return Object.keys(this.reservation).length === 0;
		},
		nameAndUserName() {
			const { name, surname } = this.reservation.createdBy;

			return `${name} ${surname}`;
		},
		canReactivate() {
			return ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.PICK_UP
				|| (ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.WAIT_FOR_COLLECT
					&& (this.reservation.reservationType).includes('Delivery'));
		},
		canDiscard() {
			return [ReservationStatus.WAIT_FOR_DELIVERY,
				ReservationStatus.WAIT_FOR_RETURN,
				ReservationStatus.WAIT_FOR_RETURN_WITH_RESERVATION,
				ReservationStatus.WAIT_FOR_PACKAGE,
				ReservationStatus.WAIT_FOR_PACKAGE_PAY,
				ReservationStatus.WAIT_FOR_RETURN_PAY]
				.includes(
					ReservationStatus.enumValueOf(this.reservation.status),
				);
		},
		showGallery() {
			return this.reservation.actionImages?.length > 0;
		},
		eshopName() {
			return this.reservation.eshop?.name || null;
		},
		canDeliver() {
			return this.reservation.canConfirmDelivery;
		},
		canPickup() {
			return this.reservation.canConfirmPickUp;
		},
		requiresNoteForPickup() {
			return this.canPickup && (ReservationStatus.enumValueOf(this.reservation.status) === ReservationStatus.LOST);
		},
		canMarkUndelivered() {
			return [ReservationStatus.WAIT_FOR_COLLECT]
				.includes(
					ReservationStatus.enumValueOf(this.reservation.status),
				);
		},
		noteIsEmpty() {
			return this.noteForPickup === null || this.noteForPickup === '';
		},
	},
	async created() {
		await this.getOne();
		await this.loadLockAliasOrSerialNumberForBox(this.$service.smartBox, this.container?.type, this.reservation.serialNumber);
	},
	methods: {
		getRouteName(containerType) {
			switch (containerType) {
				case containerTypes.store:
					return 'pickup-place-edit';
				case containerTypes.smartBox:
					return 'smart-box-edit';
				case containerTypes.penguinBox:
				default: return 'container-edit';
			}
		},
		getContainerLabel(containerType) {
			switch (containerType) {
				case containerTypes.store:
					return this.$t('admin.numberOfStore');
				case containerTypes.smartBox:
					return this.$t('admin.numberOfSmartKeyBox');
				case containerTypes.penguinBox:
				default: return this.$t('admin.numberOfPenguinBox');
			}
		},
		getContainerTooltip(containerType) {
			switch (containerType) {
				case containerTypes.store:
					return this.$t('admin.tooltips.goToStoreDetail');
				case containerTypes.smartBox:
					return this.$t('admin.tooltips.goToSmartKeyBoxDetail');
				case containerTypes.penguinBox:
				default: return this.$t('admin.tooltips.goToContainerDetail');
			}
		},
		statusCzech(status) {
			return ReservationStatus.czech(status);
		},
		async getOne() {
			this.loading = true;
			this.reservation = await this.$service.reservation.getOne(this.$route.params.id);
			if (!this.reservation.customer) {
				this.reservation.customer = {};
			}
			if (!this.reservation.customer.phone) {
				this.reservation.customer.phone = this.reservation.phone;
			}
			if (!this.reservation.customer.email) {
				this.reservation.customer.email = this.reservation.email;
			}
			this.loading = false;
			if (this.reservation.containerId) {
				this.container = await this.$service.deliveryPoint.getOne(this.reservation.containerId);
			}
			this.getNearestImages();
		},
		refreshReservation() {
			this.getOne();
			this.showHideUpdateReservation();
		},
		showHideUpdateReservation() {
			this.updateReservationVisible = !this.updateReservationVisible;
		},
		showGenerateNewCode() {
			this.generateNewCodeVisible = true;
		},
		hideGenerateNewCode() {
			this.generateNewCodeVisible = false;
		},
		async discardReservation() {
			await this.$service.reservation.saveNotes({
				note: this.theSpecialModalInputText,
				reservationId: this.reservation.reservationId,
			});
			const data = await this.$service.reservation.discardReservation(this.reservation.reservationId);
			this.$store.commit('setTheSpecialModalInputText', '');
			assign(this.reservation, data);
		},
		async undeliveredReservation() {
			await this.$service.reservation.saveNotes({
				note: this.theSpecialModalInputText,
				reservationId: this.reservation.reservationId,
			});
			const data = await this.$service.reservation.undeliveredReservation(this.reservation.reservationId);
			this.$store.commit('setTheSpecialModalInputText', '');
			assign(this.reservation, data);
		},
		async reactivateReservation() {
			try {
				await this.$service.reservation.reactivateReservation(this.reservation.reservationId);
				notifyService.notifySuccess(this.$t('admin.newPinGeneratedReservationStatusChanged'));
				this.getOne();
			} catch {
				notifyService.notifyError(this.$t('admin.changeStatusErrorNewPinError'));
			}
		},
		async deliverReservation() {
			try {
				this.loading = true;
				const activeReservation = await this.$service.reservation.ackDelivery(this.reservation.reservationId);
				if (activeReservation.reservationId === this.reservation.reservationId) {
					notifyService.notifySuccess(this.$t('admin.deliverAckSuccess'));
				} else {
					notifyService.notifyError(this.$t('admin.cannotDeliverReservationAnotherActive'));
					this.$router.push({ name: 'reservation-detail', params: { id: activeReservation.reservationId } });
				}
				await this.getOne();
			} catch {
				notifyService.notifyError(this.$t('admin.reservationChangeStateFailure'));
			} finally {
				this.loading = false;
			}
		},
		async pickupReservation() {
			try {
				this.loading = true;
				await this.$service.reservation.ackPickup(this.reservation.reservationId);
				notifyService.notifySuccess(this.$t('admin.pickupAckSuccess'));
				await this.getOne();
			} catch {
				notifyService.notifyError(this.$t('admin.reservationChangeStateFailure'));
			} finally {
				this.loading = false;
			}
		},
		async tryPickupReservation() {
			if (this.requiresNoteForPickup) {
				this.addNoteForPickupVisible = true;
			} else {
				await this.pickupReservation();
			}
		},
		async addPickUpNote() {
			try {
				this.loading = true;
				const note = {
					note: this.noteForPickup,
					reservationId: this.reservation?.reservationId,
				};
				this.addNoteForPickupVisible = false;
				await this.$service.reservation.saveNotes(note);
				await this.pickupReservation();

				notifyService.notifySuccess(this.$t('eshop.saveNoteSuccess'));
			} catch {
				notifyService.notifyError(this.$t('eshop.saveNoteError'));
			} finally {
				this.loading = false;
				this.noteForPickup = '';
			}
		},
		getNearestImages() {
			this.gallery = [];
			(this.reservation.actionImages || []).forEach(async (action) => {
				this.gallery.push(action);
			});
		},
		async loadImages(row) {
			const images = await this.$service.deliveryPoint.getNearestImages(this.container.containerId, row.dateOfAction);
			this.imagesLoaded = true;
			this.row = { ...row, ...images };
		},
	},
};
</script>

<style lang="scss" scoped>
.form-group legend {
	font-size: 0.8rem;
}
.unreliable-hint {
	background-color: rgb(255, 200, 105);
	border-radius: 5px;
	padding: 8px 12px;
}
</style>
