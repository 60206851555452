<template>
	<div v-loading="loading" class="row">
		<div class="col-md-6">
			<card>
				<div v-if="showEshopCard || !eshopId" data-cy="editPageContent">
					<form>
						<form-group-input
							v-model="eshop.name"
							v-validate="validations.eshopName.rules"
							placeholder="Zadejte název"
							:label="$t('admin.name')"
							:name="validations.eshopName.name"
							:error="getError(validations.eshopName.name)"
							:data-vv-as="$t('admin.name')" />
						<form-group-input
							v-model="eshop.mail"
							v-validate="validations.eshopMail.rules"
							placeholder="Zadejte email"
							:label="$t('admin.email')"
							:name="validations.eshopMail.name"
							:error="getError(validations.eshopMail.name)"
							:data-vv-as="$t('admin.email')" />
						<form-group-input
							v-model="eshop.addressLine1"
							v-validate="validations.addressLine1.rules"
							placeholder="Zadejte adresní řádek 1"
							:label="$t('admin.addressLine1')"
							:name="validations.addressLine1.name"
							:error="getError(validations.addressLine1.name)"
							:data-vv-as="$t('admin.addressLine1')" />
						<form-group-input
							v-model="eshop.addressLine2"
							v-validate="validations.addressLine2.rules"
							placeholder="Zadejte adresní řádek 2"
							:label="$t('admin.addressLine2')"
							:name="validations.addressLine2.name"
							:error="getError(validations.addressLine2.name)"
							:data-vv-as="$t('admin.addressLine2')" />
						<form-group-input
							v-model="eshop.link"
							v-validate="validations.link.rules"
							placeholder="Zadejte link"
							:label="$t('admin.link')"
							:name="validations.link.name"
							:error="getError(validations.link.name)"
							:data-vv-as="$t('admin.link')" />
						<div v-if="!eshopId">
							<form-group-input
								v-model="eshop.userName"
								v-validate="validations.userName.rules"
								placeholder="Zadejte jméno uživatele"
								:label="$t('admin.username')"
								:name="validations.userName.name"
								:error="getError(validations.userName.name)"
								:data-vv-as="$t('admin.username')" />
							<form-group-input
								ref="passwordToBeConfirmed"
								v-model="eshop.password"
								v-validate="validations.userPassword.rules"
								placeholder="Zadejte heslo"
								type="password"
								:label="$t('admin.password')"
								:name="validations.userPassword.name"
								:error="getError(validations.userPassword.name)"
								:data-vv-as="$t('admin.password')" />
							<form-group-input
								v-model="eshop.passwordConfirmation"
								v-validate="validations.userPasswordConfirmation.rules"
								placeholder="Potvrďte heslo"
								type="password"
								:label="$t('admin.confirmPassword')"
								:name="validations.userPasswordConfirmation.name"
								:error="getError(validations.userPasswordConfirmation.name)"
								:data-vv-as="$t('admin.confirmPassword')" />
						</div>
						<div v-if="editing">
							<form-group-input
								v-loading="loading"
								:label="$t('admin.defaultStore')">
								<el-select
									v-model="eshop.mainStockId"
									class="select-default"
									filterable>
									<template slot="prefix">
										<span v-if="container" class="container-icon-in-select-box">
											<img v-if="container.type === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
											<img v-if="container.type === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
											<span v-if="container.type === 'PrivateAddress'" class="text-dark"><i class="fa fa-home" aria-hidden="false" /></span>
											<span v-if="container.type === 'SmartKeyBox'" class="text-dark"><i class="nc-icon nc-mobile" aria-hidden="false" /></span>
										</span>
									</template>
									<el-option
										v-for="opt in freeContainers"
										:key="opt.containerId"
										:label="formatContainerName(opt)"
										:value="opt.containerId">
										<img v-if="opt.type === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
										<img v-if="opt.type === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
										<i v-if="opt.type === 'PrivateAddress'" class="fa fa-home text-dark" />
										<i v-if="opt.type === 'SmartKeyBox'" class="nc-icon nc-mobile text-dark" />
										{{ formatContainerName(opt) }}
									</el-option>
								</el-select>
							</form-group-input>
						</div>
						<p-checkbox v-if="editing" v-model="eshop.isLocked" class="mt-4">
							{{ $t('admin.locked') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.allowDirectSale" class="mt-4">
							{{ $t('admin.allowDirectSale') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.selfDelivery" class="mt-4">
							{{ $t('admin.selfDelivery') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.disableNotifications" class="mt-4">
							{{ $t('admin.disableNotifications') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.isInternal" class="mt-4">
							{{ $t('admin.eshopIsInternal') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.allowBillingReport" class="mt-4">
							{{ $t('admin.tooltips.allowBillingReport') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.allowPinOperations" class="mt-4">
							{{ $t('admin.allowPinOperations') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.reservationChecksNonWorkingDays" class="mt-4">
							{{ $t('admin.reservationChecksNonWorkingDays') }}
						</p-checkbox>
						<p-checkbox v-model="eshop.requireEkoReturn" class="mt-4">
							{{ $t('admin.requireEkoReturn') }}
						</p-checkbox>
						<confirm-button
							class="pull-right"
							:show-success-message="false"
							:confirm-message="editing ? $t('admin.eshopUpdate') : $t('admin.eshopCreate')"
							:disabled="!isValid"
							:callback="() => validateAndSave('admin')">
							{{ $t('admin.save') }}
						</confirm-button>
					</form>
				</div>
			</card>
		</div>
		<div v-if="eshopId && showEshopCard" class="col-md-6">
			<base-user-list
				v-loading="loading"
				:title="$t('admin.eshopUsersList')"
				:users="users"
				basic-list
				in-one-card
				show-assign-admin-column
				show-filter
				@get-page="loadUsers" />
		</div>
		<div v-if="editing && showEshopCard" class="col-12">
			<card>
				<base-container-boxes-tree-view
					v-model="selectedBoxes"
					:current-eshop="Number(eshop.id)"
					:result-message="assignResultMessage"
					:question-message="$t('global.assignEshopMessage')"
					eshop-mode
					@callback="assignProductToBoxes" />
			</card>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { Option as ElOption, Select as ElSelect } from 'element-ui';

import FormMixin from '@/util/form-mixin';
import ValidationMixin from '@/util/validation-mixin';
import Validations from '@/util/validations';
import { BaseContainerBoxesTreeView, BaseUserList } from '@/components/Base';
import { formChanged } from '@/store/mutation-types';

export default {
	name: 'EshopForm',
	components: {
		BaseUserList,
		BaseContainerBoxesTreeView,
		ElSelect,
		ElOption,
	},
	mixins: [FormMixin, ValidationMixin],

	data() {
		return {
			eshop: {},
			users: {},
			loading: false,
			eshopLoaded: false,
			freeContainers: [],
			container: null,
			selectedBoxes: [],
			editing: false,
			assignResultMessage: {
				show: false,
				successMessage: null,
				errorMessages: null,
			},
			validations: {
				eshopName: {
					name: 'eshopName',
					rules: Validations.requiredField,
				},
				addressLine1: {
					name: 'addressLine1',
					rules: Validations.requiredField,
				},
				addressLine2: {
					name: 'addressLine2',
					rules: Validations.requiredField,
				},
				link: {
					name: 'link',
					rules: Validations.requiredField,
				},
				eshopMail: {
					name: 'eshopMail',
					rules: Validations.emailRules,
				},
				userName: {
					name: 'userName',
					rules: Validations.requiredField,
				},
				userPassword: {
					name: 'userPassword',
					rules: Validations.passwordRules,
				},
				userPasswordConfirmation: {
					name: 'userPasswordConfirmation',
					rules: Validations.passwordConfirmationRules,
				},
			},
		};
	},
	computed: {
		eshopId() {
			return Number(this.$route.params.id);
		},
		showEshopCard() {
			return !_.isEmpty(this.eshop);
		},
	},
	watch: {
		eshop: {
			handler() {
				if (this.eshopLoaded) {
					this.$store.commit(`${this.currentAppName}/${formChanged}`, true);
				}
			},
			deep: true,
		},
		eshopId: {
			handler() {
				this.loadData();
			},
			immediate: true,
		},
	},
	updated() {
		this.eshopLoaded = true;
	},
	async created() {
		this.$store.commit(`${this.currentAppName}/${formChanged}`, false);
	},
	methods: {
		async loadUsers(params) {
			this.loading = true;
			this.users = await this.$service.admin.getEshopUsersList({ EshopId: this.eshopId, ...params });
			this.loading = false;
		},
		async loadData() {
			if (this.eshopId) {
				this.freeContainers = await this.$service.deliveryPoint.GetAllowedStores(this.eshopId);
				this.freeContainers.splice(0, 0, { });
				if (this.eshopId) {
					this.loading = true;
					this.editing = true;
					this.eshop = await this.$service.eshop.getOne(this.eshopId);
					const data = await this.$service.box.occupiedBoxes(this.eshopId);
					data.forEach((box) => {
						delete Object.assign(box, { boxSn: box.serialNo }).serialNo;
					});
					this.selectedBoxes = data;
					this.loading = false;
				}
				this.$store.commit(`${this.currentAppName}/${formChanged}`, false);
			}
		},
		formatContainerName(container) {
			return this.$options.filters.location(container);
		},
		async save() {
			if (this.eshopId) {
				await this.$service.eshop.update(this.eshop);
				this.$store.commit(`${this.currentAppName}/${formChanged}`, false);
			} else {
				await this.$service.eshop.add(this.eshop);
				this.$store.commit(`${this.currentAppName}/${formChanged}`, false);
				this.$router.push({ name: 'eshops-list' });
			}
		},
		setResultMessage(show = false, successMessage = null, errorMessages = null) {
			this.assignResultMessage.show = show;
			this.assignResultMessage.successMessage = successMessage;
			this.assignResultMessage.errorMessages = errorMessages;
		},
		async assignProductToBoxes() {
			this.loading = true;
			// Assign product into each box
			const boxes = this.selectedBoxes.filter((selectedBox) => selectedBox.added || selectedBox.removed);
			const promises = boxes.map((box) => this.$service.deliveryPoint.occupyBox(box.boxId, box.removed ? null : this.eshopId));
			const results = await Promise.all(promises.map((p) => p.catch((e) => e)));
			// Filter failed requests results
			const failedResults = results.filter((result) => result.data.status !== 'OK');
			// Set success message
			if (!failedResults.length) {
				const successMessage = this.$t('admin.assignEshopToBoxesSuccess');
				this.loading = false;
				this.setResultMessage(true, successMessage, null);
				// timeout to close success message
				setTimeout(() => {
					this.setResultMessage();
				}, 4000);
				// Set error message
			} else {
				const failedBoxesMessage = failedResults.map((result) => {
					const failedBox = this.selectedBoxes.filter((selectedBox) => selectedBox.boxId === JSON.parse(result.config.data).boxId)[0];
					const { boxSn } = failedBox;
					const container = `${failedBox.container.city}, ${failedBox.container.street}, ${failedBox.container.location} - ${failedBox.container.serialNumber}`;
					const responseMessage = result.data.errors[Object.keys(result.data.errors)[0]].join();
					const message = `${boxSn}. ${this.$t('admin.container')}: ${container}. ${responseMessage}`;
					return `${this.$t('admin.assignEshopToBoxesError')} ${message}`;
				});
				this.loading = false;
				this.setResultMessage(true, null, failedBoxesMessage);
				// timeout to close error message
				setTimeout(() => {
					this.setResultMessage();
				}, 20000);
			}
			this.loadData();
			this.loading = false;
		},
	},
};
</script>
