<template>
	<div class="col-md-12 card">
		<div class="d-flex widthReorganize">
			<div class="col-2 yesNoAll">
				<form-group-input :label="$t('pickup.expired')" type="text">
					<div class="d-flex mt-1">
						<yes-no-all
							v-model="filter.isExpired"
							:label="$t('pickup.expired')"
							trigger-on-change-method
							@on-change-method="search" />
					</div>
				</form-group-input>
			</div>
			<div class="col">
				<div class="flex-column">
					<label>
						{{ $t('pickup.reservationState') }}
					</label>
					<div class="d-flex">
						<el-select
							v-model="filter.reservationSelects"
							class="w-100"
							clearable
							multiple
							placeholder="Status rezervace"
							size="large"
							value-key="ordinal">
							<el-option
								v-for="status in ReservationStatus.enumValues"
								:key="status.ordinal"
								:label="status.czech()"
								:value="status">
								<i :class="status.icon()" />
								{{ status.czech() }}
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="col">
				<form-group-input v-model="filter.ReservationNumberLike" :label="$t('pickup.orderNumber')" type="text" />
			</div>
		</div>
		<div v-show="advancedSearch">
			<div class="d-flex widthReorganize">
				<div class="col-2 yesNoAll">
					<div class="d-flex">
						<form-group-input :label="$t('pickup.reservationPost')">
							<div class="d-flex mt-1">
								<yes-no-all v-model="filter.isPostPackage" trigger-on-change-method @on-change-method="search" />
							</div>
						</form-group-input>
					</div>
				</div>
				<div class="col">
					<form-group-input
						v-model="filter.Phone"
						:label="$t('pickup.phone')"
						type="text"
						@keypress="isTelNumber($event)" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.Email" :label="$t('pickup.email')" type="email" />
				</div>
			</div>
			<div class="d-flex mt-2 widthReorganize">
				<div class="col">
					<form-group-input :label="$t('pickup.occupiedFromStart')">
						<date-picker
							v-model="filter.From"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime" />
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input :label="$t('pickup.subscriptionEndBefore')">
						<date-picker
							v-model="filter.prepaidTo"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime" />
					</form-group-input>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-between">
			<div class="col-2 d-flex mb-1 justify-content-start">
				<p-button size="md" type="success" @click="search">
					{{ $t('pickup.search') }}
				</p-button>
				<p-button
					class="ml-2"
					size="md"
					type="primary"
					@click="toggleQrCode">
					{{ scanQrCodeLabel }}
				</p-button>
				<el-tooltip :content="$t('pickup.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						icon
						size="md"
						type="danger"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
			<div class="d-flex mb-1 mr-3">
				<p-button @click="toggleAdvancedSearch">
					{{ searchButtonLabel }}
				</p-button>
			</div>
		</div>
		<qrcode-stream
			v-if="scanQrCode"
			:camera="cameraSettings"
			class="col-3 mb-3"
			@decode="onDecode" />
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { Tooltip as ElTooltip, DatePicker, Select as ElSelect, Option as ElOption } from 'element-ui';
import { QrcodeStream } from 'vue-qrcode-reader';
import { cloneDeep } from 'lodash';

import { ReservationStatus } from '@/modules/pickup/store/enums';
import notifyService from '@/service/notify-service';
import { setFilterReservationNumberLike } from '@/modules/pickup/store/mutation-types';
import ValidationMixin from '@/util/validated-form-mixin';
import { appName } from '@/modules/pickup/config';
import { resetFilter, setFilter } from '@/store/mutation-types';

export default {
	name: 'ReservationListFilter',
	components: {
		ElTooltip,
		DatePicker,
		ElSelect,
		ElOption,
		QrcodeStream,
	},
	mixins: [ValidationMixin],
	data() {
		return {
			ReservationStatus,
			advancedSearch: false,
			scanQrCode: false,
			filter: {},
		};
	},
	computed: {
		...mapState(appName, ['reservationFilter']),
		searchButtonLabel() {
			return this.advancedSearch ? this.$t('pickup.simpleSearch') : this.$t('pickup.advancedSearch');
		},
		scanQrCodeLabel() {
			return this.scanQrCode ? this.$t('pickup.hideQrCode') : this.$t('pickup.scanQrCode');
		},
	},
	watch: {
		reservationFilter: {
			handler(val) {
				this.filter = cloneDeep(val);
			},
			immediate: true,
			deep: true,
		},
		'filter.reservationSelects': {
			handler(val) {
				this.filter.Status = val.map((status) => status.camelCase());
			},
			deep: true,
		},
	},
	methods: {
		isTelNumber(event) {
			const evt = event || window.event;
			const charCode = evt.which ? evt.which : evt.keyCode;
			return (charCode >= 48 && charCode <= 57) || charCode === 32 || charCode === 43 ? true : evt.preventDefault();
		},

		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'reservationFilter');
			this.filter.reservationSelects = [];
		},

		toggleAdvancedSearch() {
			this.advancedSearch = !this.advancedSearch;

			if (!this.advancedSearch) {
				this.filter.isPostPackage = null;
				this.filter.Phone = null;
				this.filter.Email = null;
				this.filter.From = null;
				this.filter.prepaidTo = null;
			}
		},
		onDecode(decodedString) {
			try {
				this.cameraReset();

				const data = JSON.parse(decodedString);
				const qrData = this.$service.qrCode.getData(data.reservationId, data.eshopOrderId);
				const reservation = this.$service.qrCode.getReservation(qrData);

				if (reservation.reservationNumber) {
					this.$store.commit(`${appName}/${setFilterReservationNumberLike}`, reservation.reservationNumber);
				} else {
					notifyService.notifyError(this.$t('pickup.reservationNotFound'));
				}
			} catch {
				notifyService.notifyError(this.$t('pickup.reservationNotFound'));
			}

			this.scanQrCode = false;
		},
		toggleQrCode() {
			this.scanQrCode = !this.scanQrCode;

			this.switchCamera(this.scanQrCode);
		},
		search() {
			this.$store.commit(`${appName}/${setFilter}`, { filterName: 'reservationFilter', data: this.filter });
		},
	},
};
</script>

<style lang="scss" scoped>
.yesNoAll {
	min-width: 210px;
}
@media (max-width: 1200px) {
	.widthReorganize {
		display: flex;
		flex-direction: column;
	}
}
</style>
