<template>
	<account-form-wrap :action-title="$t('global.send')" :title="$t('global.passwordRecovery')" @form-submit="resetPassword">
		<ul v-if="!changeResult.succeeded">
			<li v-for="err in changeResult.errors" :key="err.code">
				{{ err.description }}
			</li>
		</ul>
		<form-group-input
			v-model="form.newPassword"
			:placeholder="$t('global.newPassword')"
			addon-left-icon="nc-icon nc-key-25"
			type="password" />

		<form-group-input
			v-model="form.confirmPassword"
			:placeholder="$t('global.confirmNewPassword')"
			addon-left-icon="nc-icon nc-key-25"
			type="password" />
	</account-form-wrap>
</template>

<script>
import notifyService from '@/service/notify-service';
import AccountFormWrap from '@/components/UIComponents/NotLoggedIn/AccountFormWrap.vue';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import authService from '@/service/auth-service';

export default {
	name: 'BaseResetPassword',
	components: {
		AccountFormWrap,
	},
	data() {
		return {
			form: {
				newPassword: '',
				confirmPassword: '',
			},
			changeResult: {},
		};
	},
	methods: {
		async resetPassword() {
			const resetParams = {
				...this.form,
				userId: this.$route.params.user,
				token: decodeURIComponent(this.$route.query.token || null),
			};
			this.changeResult = await authService.resetPassword(resetParams);

			if (this.changeResult.succeeded) {
				notifyService.notifySuccess(this.$t('global.passwordChanged'));

				this.$router.push({ name: 'login' });
			} else {
				notifyService.notifyError(this.$t('global.passwordCannotChange'));
			}
		},
	},
};
</script>
