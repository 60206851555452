import { stringify } from 'qs';
import { isNil, omit, omitBy } from 'lodash';

export const EshopOrderService = (http) => ({
	async getList(page, filter, pageSize = 50) {
		const newFilter = {
			...filter,
		};

		newFilter['createdDate.From'] = filter.createdDateFrom;
		newFilter['createdDate.To'] = filter.createdDateTo;

		const filterParams = omit(omitBy(newFilter, isNil), ['createdDateFrom', 'createdDateTo']);
		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};
		const response = await http.get('/api/EshopOrder/List', config);

		return response.data;
	},

	async getOne(orderId) {
		const response = await http.get('/api/EshopOrder/Detail', { params: { orderId } });
		return response.data;
	},

	async getSimilar(orderId) {
		const response = await http.get(`/api/EshopOrder/${orderId}/SimilarReservations`, { params: { orderId } });
		return response.data;
	},

	async stickToReservation(eshopOrderId, reservationId) {
		const response = await http.post('/api/EshopOrder/PairWithReservation', { eshopOrderId, reservationId });
		return response.data;
	},

	async SetAutoReservation(EshopOrderId, AutoCreateReservation, MinimalBoxSize) {
		const response = await http.post('/api/EshopOrder/SetAutoReservation', { EshopOrderId, AutoCreateReservation, MinimalBoxSize });
		return response.data;
	},

	async SetAnotherCourier(EshopOrderId, SendByAnotherCourier) {
		const response = await http.post('/api/EshopOrder/SetAnotherCourier', { EshopOrderId, SendByAnotherCourier });
		return response.data;
	},
});
