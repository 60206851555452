import { getAbbrState } from '@/util/helpers';

export default {
	data() {
		return {
			boxDetails: {},
		};
	},
	methods: {
		getAddressBoxValue(reservation) {
			if (!reservation.containerId) {
				return reservation.city;
			}
			const {
				city,
				street,
				zip,
				state,
			} = reservation;
			return `${this.getEmptyString(street)}, ${this.getEmptyString(city)} ${this.getEmptyString(zip)}, ${this.getEmptyString(getAbbrState(state))}`;
		},
		getEmptyString(value) {
			if (value === null) {
				return '';
			}
			return value;
		},
	},
};
