<template>
	<div class="row">
		<reservation-list-filter />

		<reservation-list-table
			:filter="reservationFilter"
			@sorting-changed="sortChange"
			@write-off-clicked="writeOffClicked" />

		<reservation-list-write-off-dialog :reservation="reservationToWriteOff" @close="closeDialog" />
	</div>
</template>

<script>
import { mapState } from 'vuex';

import { appName } from '@/modules/pickup/config';
import { setFilter } from '@/store/mutation-types';

import ReservationListFilter from './ReservationList/ReservationListFilter.vue';
import ReservationListTable from './ReservationList/ReservationListTable.vue';
import ReservationListWriteOffDialog from './ReservationList/ReservationListWriteOffDialog.vue';

export default {
	name: 'ReservationList',
	components: {
		ReservationListWriteOffDialog,
		ReservationListTable,
		ReservationListFilter,
	},
	data() {
		return {
			reservationToWriteOff: null,
		};
	},
	computed: {
		...mapState(appName, ['reservationFilter']),
	},
	methods: {
		writeOffClicked(reservation) {
			this.reservationToWriteOff = reservation;
		},
		sortChange(sortData) {
			this.$store.commit(`${appName}/${setFilter}`, {
				filterName: 'reservationFilter',
				data: { ...this.reservationFilter, ...sortData },
			});
		},
		closeDialog(returnValue) {
			if (returnValue) {
				this.reservationToWriteOff = null;
			}
		},
	},
};
</script>
