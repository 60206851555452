<template>
	<div>
		<h1>
			{{ $t('eshop.orderTransport') }}
			<span v-if="shoptet">{{ $t('eshop.fromEshopOrder') }}</span>
			{{ shoptet.code }}
		</h1>
		<div class="row">
			<div v-loading="loading" class="col-6">
				<card v-if="!deliveryToAddress" v-loading="loading">
					<el-alert v-if="showContainerUnavailable" :title="containerUnavailable" type="warning" />
					<div>
						<h2> {{ $t('eshop.selectingDeliveryPoint') }} </h2>
						<form-group-input
							v-loading="loading"
							:disabled="disableContainerSelect"
							:label="$t('eshop.pickupPlaceType')"
							:required="!deliveryToAddress">
							<el-select
								ref="containerSelect"
								v-model="selectedContainerId"
								:disabled="disableContainerSelect"
								:placeholder="$t('eshop.containerSearchHint')"
								class="select-default container-select"
								remote
								:remote-method="filterFreeContainers"
								filterable
								@visible-change="onVisibleChange">
								<template slot="prefix">
									<span v-if="container" class="container-icon-in-select-box">
										<img v-if="container.containerType === containerTypes.store" src="/static/img/icons/genesis.svg" alt="Genesis">
										<img v-if="container.containerType === containerTypes.penguinBox" src="/static/img/icons/pb.svg" alt="PB">
										<span v-if="container.containerType === containerTypes.privateAddress" class="text-dark"><i class="fa fa-home" aria-hidden="false" /></span>
										<span v-if="container.containerType === containerTypes.smartBox" class="text-dark">
											<i class="nc-icon nc-mobile" aria-hidden="false" />
										</span>
									</span>
								</template>
								<el-option
									v-for="opt in freeContainersFiltered"
									:key="opt.containerId"
									:label="formatContainerName(opt)"
									:value="opt.containerId">
									<img v-if="opt.containerType === containerTypes.store" src="/static/img/icons/genesis.svg" alt="Genesis">
									<img v-if="opt.containerType === containerTypes.penguinBox" src="/static/img/icons/pb.svg" alt="PB">
									<i v-if="opt.containerType === containerTypes.privateAddress" class="fa fa-home text-dark" />
									<i v-if="opt.containerType === containerTypes.smartBox" class="nc-icon nc-mobile text-dark" />
									{{ formatContainerName(opt) }}
								</el-option>
							</el-select>
						</form-group-input>
						<form-group-input
							v-loading="loading"
							:label="$t('eshop.boxType')"
							:required="!deliveryToAddress">
							<div>
								<el-radio-group
									v-model="newReservation.boxType"
									size="medium"
									:disabled="disableAllBoxButtons"
									@change="boxTypeChanged">
									<el-radio-button
										v-for="box in BoxType.enumValues"
										:key="box.ordinal"
										:label="box"
										:disabled="disableBoxButton(box)">
										<el-tooltip
											v-if="disableBoxButton(box)"
											class="boxTypeButton"
											:content="disableBoxAlert(box)"
											placement="top-start">
											<span>{{ box.czech() }}</span>
										</el-tooltip>
										<span v-else class="boxTypeButton">
											{{ box.czech() }}
										</span>
									</el-radio-button>
								</el-radio-group>
								<div v-if="newReservation.boxType">
									<i class="fa fa-info-circle" aria-hidden="true" />
									<span class="font-weight-bold pl-1">Max: </span>
									{{ $t('eshop.width') }}&nbsp;{{ selectedBoxSizes.width }}&nbsp;cm
									&#x25cf;&nbsp;{{ $t('eshop.height') }}&nbsp;{{ selectedBoxSizes.height }}&nbsp;cm
									&#x25cf;&nbsp;{{ $t('eshop.depth') }}&nbsp;{{ selectedBoxSizes.depth }}&nbsp;cm
								</div>
							</div>
						</form-group-input>
						<p-button class="mb-2 mt-2" @click="showAlternativeDeliveryPointDialog">
							{{ $t('eshop.findAlternativeContainer') }}
						</p-button>
						<span class="d-flex justify-content-between align-items-center">
							<p-button :disabled="!canSave" class="mb-2" @click="addressValidate">
								{{ $t('eshop.orderTransport') }}
							</p-button>
							<router-link :to="{ name: 'box-order-shoptet', params: { shoptet: shoptet} }">
								{{ $t('eshop.editData') }}
							</router-link>
						</span>
					</div>
				</card>
				<card v-loading="loading">
					<h2> {{ shoptet.container ? $t('eshop.orderInformation') : $t('eshop.privateAddressOrderInformation') }} </h2>
					<div><span class="font-weight-bold"> {{ $t('eshop.reservationOrderNumber') }}: </span>{{ shoptet.code }}</div>
					<div v-if="shoptet.amount">
						<span class="font-weight-bold"> {{ $t('eshop.amount') }}: </span>{{ shoptet.amount }}
					</div>
					<div><span class="font-weight-bold"> {{ $t('eshop.address') }}: </span>{{ shoptet.street }}, {{ shoptet.zip }}, {{ newReservation.abbrState }}</div>
					<div><span class="font-weight-bold"> {{ $t('eshop.fullName') }}: </span>{{ shoptet.name }}</div>
					<div><span class="font-weight-bold"> {{ $t('eshop.email') }}: </span>{{ shoptet.email }}</div>
					<div><span class="font-weight-bold"> {{ $t('eshop.phone') }}: </span>{{ shoptet.phone }}</div>
					<div><span class="font-weight-bold"> {{ $t('eshop.created') }}: </span>{{ shoptet.createdDate | datetime }}</div>
					<span v-if="deliveryToAddress" class="d-flex justify-content-between align-items-center">
						<p-button :disabled="!canSave" class="mb-2 mt-2" @click="addressValidate">
							{{ $t('eshop.orderTransport') }}
						</p-button>
						<router-link :to="{ name: 'box-order-shoptet', params: { shoptet: shoptet} }">
							{{ $t('eshop.editData') }}
						</router-link>
					</span>
				</card>
			</div>
			<div class="col-6">
				<container-boxes-card
					v-if="container && container.boxes"
					v-loading="loading"
					:container="container"
					@go-to-reservation-detail="goToReservationDetail"
					@get-container="getOne" />
			</div>
			<base-delivery-point-confirm-dialog :show="confirmDialogShow" @close="closeConfirmDialog" />
		</div>
		<el-dialog
			:visible.sync="alternativeContainerModalVisible"
			:modal-append-to-body="false"
			:modal="false"
			width="70%">
			<eshop-order-list-alternative-container-modal
				:original-container-id="newReservation.containerId || newReservation.originalContainerId"
				:free-containers="freeContainers"
				@found-new-container="onAlternativeContainerFound" />
		</el-dialog>
	</div>
</template>

<script>
import {
	RadioGroup as ElRadioGroup,
	RadioButton as ElRadioButton,
	Option as ElOption,
	Select as ElSelect,
	Alert as ElAlert,
	Tooltip as ElTooltip,
	Dialog as ElDialog,
} from 'element-ui';
import { cloneDeep } from 'lodash';

import { containerTypes } from '@/globalConstants';
import notifyService from '@/service/notify-service';
import { BoxType } from '@/modules/eshop/store/enums';
import Validation from '@/util/validations';
import ValidationForm from '@/util/validated-form-mixin';
import ContainerSelectMixin from '@/util/container-select-mixin';
import FormMixin from '@/util/form-mixin';
import { appName } from '@/modules/eshop/config';
import { formChanged } from '@/store/mutation-types';
import { BaseDeliveryPointConfirmDialog } from '@/components/Base';
import { getAbbrState } from '@/util/helpers';
import EshopOrderListAlternativeContainerModal from '@/modules/eshop/views/EshopOrder/EshopOrderList/EshopOrderListAlternativeContainerModal.vue';

import ContainerBoxesCard from '../../Order/ContainerBoxesCard.vue';

export default {
	name: 'EshopOrderListOrderTransportModal',
	components: {
		ElSelect,
		ElOption,
		ElAlert,
		ElTooltip,
		ElRadioGroup,
		ElRadioButton,
		ElDialog,
		ContainerBoxesCard,
		BaseDeliveryPointConfirmDialog,
		EshopOrderListAlternativeContainerModal,
	},
	mixins: [ValidationForm, FormMixin, ContainerSelectMixin],
	props: {
		shoptet: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			selectedBoxSizes: null,
			loading: false,
			containerUnavailable: null,
			deliveryToAddress: false,
			BoxType,
			freeContainers: [],
			availableBoxTypes: [],
			newReservation: {
				requiredPayment: null,
				boxType: null,
				containerId: null,
				eshopOrderIds: null,
				reservationNumber: '',
				mail: null,
				phone: null,
				customerAddress: null,
				customer: {
					email: null,
					phone: null,
					fullName: null,
					city: null,
					street: null,
					zip: null,
				},
				reqReturn: false,
				originalContainerId: null,
			},
			reservedHours: 48,
			selectedContainerId: null,
			newReservationValidation: {
				customerCity: {
					name: 'customerCity',
					rules: {
						...Validation.notRequiredTextNumSpaceHyphenField,
					},
				},
			},
			container: null,
			confirmDialogShow: false,
			containerTypes,
			alternativeContainerModalVisible: false,
		};
	},

	computed: {
		disableAllBoxButtons() {
			return !this.newReservation.containerId;
		},
		disableContainerSelect() {
			return this.deliveryToAddress;
		},
		canSave() {
			const { boxType, containerId } = this.newReservation;
			return ((boxType || this.deliveryToAddress) && (containerId || this.deliveryToAddress));
		},
		isAllCustomerFullFilled() {
			const { fullName, city, street, zip } = this.newReservation.customer;

			return fullName && city && street && zip;
		},
		isAllCustomerEmpty() {
			const { fullName, city, street, zip } = this.newReservation.customer;

			return !fullName && !city && !street && !zip;
		},
		showContainerUnavailable() {
			return !!this.containerUnavailable && !this.newReservation.containerId && !this.deliveryToAddress;
		},
		containerUnavailableLocation() {
			return this.shoptet.container ? this.formatContainerName(this.shoptet.container) : this.shoptet.shoptetContainerIdentifier;
		},
		disableBoxButton() {
			return (box) => !this.availableBoxTypes.includes(box.name);
		},
	},

	watch: {
		shoptet() {
			this.shoptetChanged();
		},
		deliveryToAddress() {
			this.newReservation.boxType = null;
			this.newReservation.containerId = null;
		},
		newReservation: {
			handler() {
				this.$store.commit(`${appName}/${formChanged}`, true);
				this.selectedBoxSizes = this.newReservation?.boxType?.proportions();
			},
			deep: true,
		},
		selectedContainerId() {
			this.newReservation.containerId = this.selectedContainerId;
			this.$store.commit(`${appName}/${formChanged}`, true);

			const newContainerId = this.newReservation.containerId;
			const container = this.freeContainers.find((x) => x.containerId === newContainerId);
			const storeTime = 168;
			const boxTime = 48;
			this.availableBoxTypes = container && container.availableBoxTypes;
			if (!this.reservedHours || this.reservedHours === boxTime || this.reservedHours === storeTime) {
				this.reservedHours = container && container.containerType === containerTypes.store ? storeTime : boxTime;
			}
			this.cleanBoxType();
		},
	},

	async created() {
		this.shoptetChanged();
	},
	mounted() {
		this.setContainerSelectEventListener();
	},
	methods: {
		async shoptetChanged() {
			if (this.$router.currentRoute.path.includes('shoptet') && !this.shoptet) {
				notifyService.notifyError(this.$t('eshop.shoptetError'));
				this.$router.push({ name: 'eshop-order-list' });
			}
			this.loading = true;

			const { container, email, phone, name, city, street, zip, code, eshopOrderId, requiredPayment, reqReturn, country } = this.shoptet;

			await this.getFreeContainers(email);

			if (!container?.containerId) {
				this.deliveryToAddress = true;
			}
			// prestoze se ve watcheru k selectedContainerId upravi i newReservation.containerId, je na nasledujicich radcich spravne
			// ze se upravuje jak selectedContainerId, tak newReservation.containerId. Je to kvuli synchronizaci.
			// Watcher totiz reaguje se spozdenim a hodnota newReservation.containerId se o nekolik radku nize pouziva ve volanych funkcich.
			const foundContainer = this.freeContainers.find((searchingContainer) => searchingContainer.containerId === container?.containerId);
			if (foundContainer) {
				this.lastQuery = `${foundContainer.serialNumber}`;
				this.$nextTick(() => {
					this.containerSelectOnChange();
					setTimeout(() => {
						this.lastQuery = '';
					}, 1000);
				});
				this.selectedContainerId = container?.containerId || null;
				this.newReservation.containerId = container?.containerId || null;
			} else {
				this.selectedContainerId = null;
				this.newReservation.containerId = null;
			}
			this.newReservation.mail = email;
			this.newReservation.phone = phone;
			this.newReservation.customer.fullName = name;
			this.newReservation.customer.city = city;
			this.newReservation.customer.street = street;
			this.newReservation.customer.zip = zip;
			this.newReservation.reservationNumber = code;
			this.newReservation.eshopOrderIds = [eshopOrderId];
			this.newReservation.requiredPayment = requiredPayment;
			this.newReservation.reqReturn = reqReturn;
			this.newReservation.abbrState = country ? getAbbrState(country) : null;
			this.newReservation.customerAddress = this.getCustomerAddressFromShoptet(this.shoptet); // preserved for backward compatibility

			this.newReservation.originalContainerId ??= container?.containerId || null;

			const autoCreateReservation = this.shoptet.autoCreateReservation ? `. ${this.$t('eshop.autoOrderActivated')}` : this.$t('eshop.autoOrderGoBack');
			this.containerUnavailable = `${this.$t('eshop.container')} "${this.containerUnavailableLocation}"
			${this.$t('eshop.containerIsFull')}${autoCreateReservation}`;

			if (!this.deliveryToAddress) await this.cleanBoxType();
			this.loading = false;
		},
		disableBoxAlert(box) {
			if (!this.newReservation.containerId) return `${this.$t('eshop.firstChooseDeliveryPoint')}`;
			if (this.disableBoxButton(box)) return `${this.$t('eshop.boxNotAvailable')}`;
			return '';
		},
		boxTypeChanged(box) {
			this.newReservation.boxType = box;
		},
		cleanBoxType() {
			this.container = this.freeContainers.find((x) => x.containerId === this.newReservation.containerId);
			if (this.availableBoxTypes.length === 1) {
				this.newReservation.boxType = this.BoxType[this.availableBoxTypes.toString()];
			} else {
				this.newReservation.boxType = null;
			}

			if (this.container?.containerType === containerTypes.penguinBox
          || this.container?.containerType === containerTypes.smartBox) {
				this.getOne();
			}

			if (this.container?.containerType === containerTypes.store) {
				this.reservedHours = 168;
			}
		},
		getCustomerAddressFromShoptet(shoptet) {
			return `${shoptet.name}, ${shoptet.street}, ${shoptet.city} ${shoptet.zip}`;
		},
		async getFreeContainers(email) {
			this.freeContainers = await this.$service.container.getFreeContainersWithExpired(email);
			this.enrichFreeContainers(this.freeContainers);
		},

		formatContainerName(container) {
			return this.$options.filters.location(container);
		},
		async createNewReservation() {
			if (this.canSave) {
				try {
					this.loading = true;
					const reservationToSend = this.replaceEmptyValuesByNull(cloneDeep(this.newReservation));
					if (!this.isAllCustomerFullFilled) {
						reservationToSend.customer = null;
					} else {
						reservationToSend.customer.phone = reservationToSend.phone;
						reservationToSend.customer.email = reservationToSend.mail;
					}
					if (this.newReservation.boxType) reservationToSend.boxType = this.newReservation.boxType.name;
					const oneHourInMinutes = 60;
					reservationToSend.minutes = this.reservedHours * oneHourInMinutes;
					const response = await this.$service.eshop.newReservation(reservationToSend);
					if (response.reservationId) {
						notifyService.notifySuccess(this.$t('eshop.reservationCreateSuccess'));
						this.$store.commit(`${appName}/${formChanged}`, false);
						this.$router.push({ name: 'reservation-detail', params: { id: response.reservationId } });
					} else {
						notifyService.notifyError(this.$t('eshop.reservationCreateError'));
					}
				} catch (e) {
					console.warn(e);
					notifyService.notifyError(this.$t('eshop.reservationCreateErrorDataNotValid'));
				} finally {
					this.loading = false;
				}
			} else {
				notifyService.notifyError(this.$t('eshop.missingValues'));
			}
		},
		goToReservationDetail(reservationId) {
			reservationId && this.$router.push({ name: 'reservation-detail', params: { id: reservationId } });
		},
		replaceEmptyValuesByNull(reservationToSend) {
			const fieldsToCheck = ['boxType', 'requiredPayment', 'containerId', 'eshopOrderIds', 'reservationNumber', 'mail', 'phone', 'customerAddress'];
			fieldsToCheck.forEach((fieldName) => {
				if (!reservationToSend[fieldName]) reservationToSend[fieldName] = null;
			});

			if (reservationToSend.customer) {
				const customerFieldsToCheck = ['email', 'phone', 'fullName', 'city', 'street', 'zip'];
				customerFieldsToCheck.forEach((fieldName) => {
					if (!reservationToSend.customer[fieldName]) reservationToSend.customer[fieldName] = null;
				});
			}
			return reservationToSend;
		},
		async getOne() {
			this.loading = true;
			const selectedContainerType = this.container.containerType;
			this.container = await this.$service.container.getOne(this.newReservation.containerId);
			this.container.containerType = selectedContainerType;
			this.loading = false;
		},
		async addressValidate() {
			const { valid } = await this.$validator.verify(this.newReservationValidation.customerCity, { validateAddress: this.newReservation.customer });

			if (!valid) {
				this.confirmDialogShow = true;
			} else {
				await this.createNewReservation();
			}
		},
		async closeConfirmDialog(returnValue) {
			this.confirmDialogShow = false;

			if (returnValue) {
				await this.createNewReservation();
			}
		},
		showAlternativeDeliveryPointDialog() {
			this.alternativeContainerModalVisible = true;
		},
		onAlternativeContainerFound(container) {
			this.selectedContainerId = container.containerId;
			this.alternativeContainerModalVisible = false;
			this.containerSelectSetText(this.formatContainerName(container));
		},
	},
};
</script>

<style lang="scss" scoped>

.container-icon-in-select-box {
	padding-left: 7px;
	padding-top: 10px;
	padding-bottom: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.boxTypeButton {
	display: inline-block;
	width: 70px;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner::placeholder {
	color: #636c72;
}

</style>
