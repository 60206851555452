<template>
	<account-form-wrap :title="$t('global.confirmEmailFailed')" :action-title="$t('global.logon')" @form-submit="login">
		<p v-if="!succeeded">
			{{ $t('global.confirmContactAdmin') }}
		</p>
	</account-form-wrap>
</template>

<script>
import notifyService from '@/service/notify-service';
import AccountFormWrap from '@/components/UIComponents/NotLoggedIn/AccountFormWrap.vue';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import authService from '@/service/auth-service';

export default {
	name: 'BaseConfirmation',
	components: { AccountFormWrap },
	data() {
		return {
			succeeded: false,
		};
	},
	async created() {
		const {
			params: { user },
			query: { token },
		} = this.$route;

		if (user && token) {
			const { succeeded } = await authService.confirmEmail(user, token);

			this.succeeded = !!succeeded;

			if (succeeded) {
				notifyService.notifySuccess(this.$t('global.emailConfirmed'));
				this.login();
			}
		}
	},
	methods: {
		login() {
			this.$router.push({ name: 'login' });
		},
	},
};
</script>
