// eslint-disable-next-line max-classes-per-file
import { Enum } from 'enumify';

import { camelToUpper, upperToCamel } from '@/util/enums';
import { ReservationStatusBase } from '@/store/enums';
import { ClothesAndAccesories } from '@/modules/eshop/store/constants';

export class ReservationStatus extends ReservationStatusBase {}

ReservationStatus.initEnum([
	'DISCARD',
	'PAYMENT_WAITING',
	'ACTIVE',
	'PICK_UP',
	'COLLECTED',
	'WAIT_FOR_DELIVERY',
	'PREPARED',
	'WAIT_FOR_PAY',
	'WAIT_FOR_RETURN',
	'WAIT_FOR_COLLECT',
	'WAIT_FOR_PACKAGE',
	'WAIT_FOR_PACKAGE_PAY',
	'WAIT_FOR_EMPTY_DESTINATION',
	'DELIVERY_RESERVATION_CREATED',
	'PRESELECT',
	'LOST',
	'UNDELIVERED',
	'WAIT_FOR_RETURN_PAY',
	'WAIT_FOR_RETURN_WITH_RESERVATION',
	'SELECT_FOR_RETURN_DIRECT_SALE',
]);

export class PickupPlace extends Enum {
	static #CZECH_DICT = {
		STORE: 'Výdejny',
		PENGUIN_BOX: 'Penguin Boxy',
		PRIVATE_ADDRESS: 'Adresy',
		SMART_KEY_BOX: 'Smart Boxy',
	};

	static #ICON_DICT = {
		STORE: '/static/img/icons/genesis.svg',
		PENGUIN_BOX: '/static/img/icons/pb.svg',
		PRIVATE_ADDRESS: 'fa fa-home',
		SMART_KEY_BOX: 'nc-icon nc-mobile',
	};

	static czech(str) {
		return PickupPlace.#CZECH_DICT[camelToUpper(str)];
	}

	static icon(str) {
		return PickupPlace.#ICON_DICT[camelToUpper(str)];
	}

	static enumValueOf(str) {
		return super.enumValueOf(camelToUpper(str));
	}

	camelCase() {
		return upperToCamel(this.name);
	}

	czech() {
		return PickupPlace.#CZECH_DICT[this.name];
	}

	icon() {
		return PickupPlace.#ICON_DICT[this.name];
	}

	valueOf() {
		return this.camelCase();
	}
}
PickupPlace.initEnum(['STORE', 'PENGUIN_BOX', 'PRIVATE_ADDRESS', 'SMART_KEY_BOX']);

export class OrderStatus extends Enum {
	static #CZECH_DICT = {
		COMPLETED: 'Zpracováno',
		WAITING: 'Čeká na přiřazení schránky',
	};

	static #ICON_DICT = {
		COMPLETED: 'fas fa-check-circle',
		WAITING: 'fas fa-shopping-cart',
	};

	static czech(str) {
		return OrderStatus.#CZECH_DICT[camelToUpper(str)];
	}

	static icon(str) {
		return OrderStatus.#ICON_DICT[camelToUpper(str)];
	}

	static enumValueOf(str) {
		return super.enumValueOf(camelToUpper(str));
	}

	camelCase() {
		return upperToCamel(this.name);
	}

	czech() {
		return OrderStatus.#CZECH_DICT[this.name];
	}

	icon() {
		return OrderStatus.#ICON_DICT[this.name];
	}

	valueOf() {
		return this.camelCase();
	}
}

OrderStatus.initEnum(['COMPLETED', 'WAITING']);

export class BoxType extends Enum {
	static #CZECH_DICT = {
		Mini: 'XS',
		Small: 'S',
		SmallWithUSB: 'S (+USB)',
		Medium: 'M',
		Large: 'L',
		XL: 'XL',
		[ClothesAndAccesories]: 'Oblečení',
	};

	static #PROPORTIONS = {
		Small: {
			width: 39,
			height: 67,
			depth: 15,
		},
		SmallWithUSB: {
			width: 39,
			height: 67,
			depth: 15,
		},
		Large: {
			width: 39,
			height: 67,
			depth: 33,
		},
		XL: {
			width: 39,
			height: 67,
			depth: 67,
		},
		Medium: {
			width: 47,
			height: 63,
			depth: 23,
		},
		ClothesAndAccesories: {
			width: 400,
			height: 600,
			depth: 500,
		},
	};

	czech() {
		return BoxType.#CZECH_DICT[this.name];
	}

	proportions() {
		return BoxType.#PROPORTIONS[this.name];
	}

	static czech(str) {
		return BoxType.#CZECH_DICT[str];
	}
}

BoxType.initEnum(['Small', 'SmallWithUSB', 'Medium', 'Large', 'XL', 'ClothesAndAccesories']);

export class UsedBy extends Enum {
	static #CZECH_DICT = {
		Me: 'Moje',
		Other: 'Ostatní',
		GeneralUse: 'Obecné',
	};

	czech() {
		return UsedBy.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return UsedBy.#CZECH_DICT[str];
	}
}

UsedBy.initEnum(['Me', 'Other', 'GeneralUse']);
