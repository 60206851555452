<template>
	<base-user-form
		add-user-function="addDriver"
		link-back="driver-list"
		new-title="Nový řidič"
		title="Řidič" />
</template>

<script>
import FormMixin from '@/util/form-mixin';
import { BaseUserForm } from '@/components/Base';

export default {
	name: 'DriverForm',
	components: { BaseUserForm },
	mixins: [FormMixin],
};
</script>
