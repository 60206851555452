<template>
	<el-dialog
		:append-to-body="true"
		:close-on-click-modal="false"
		:show-close="false"
		:visible.sync="show"
		custom-class="fit-to-content">
		<div slot="title" class="text-center pt-3">
			<div class="swal2-icon swal2-warning swal2-icon-show">
				!
			</div>
		</div>

		<p class="m-0 text-center">
			{{ $t('admin.addressNotFound') }} {{ $t('admin.continue') }}?
		</p>

		<div slot="footer" class="d-flex justify-content-center py-2">
			<p-button size="sm" type="success" @click.stop="confirmDialogYes">
				{{ $t('admin.yes') }}
			</p-button>
			<p-button size="sm" type="danger" @click.stop="confirmDialogCancel">
				{{ $t('admin.cancel') }}
			</p-button>
		</div>
	</el-dialog>
</template>

<script>
import { Dialog as ElDialog } from 'element-ui';

import ValidationMixin from '@/util/validation-mixin';

export default {
	name: 'BaseDeliveryPointConfirmDialog',

	components: {
		ElDialog,
	},
	mixins: [ValidationMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
		};
	},
	methods: {
		confirmDialogCancel() {
			this.$emit('close', false);
		},
		async confirmDialogYes() {
			this.$emit('close', true);
		},
	},
};
</script>
