<template>
	<el-dialog
		v-if="reservation"
		v-loading="loading"
		:close-on-click-modal="false"
		:visible.sync="show"
		class="writeOffDialog"
		round
		width="60%"
		@closed="closeConfirmDialog">
		<h3 class="text-center mt-2">
			{{ writeOffMessage }}
		</h3>
		<div class="swal2-icon swal2-warning" style="display: block;">
			!
		</div>
		<div v-if="reservation.isPostPackage" class="postPackageWarning">
			{{ $t('pickup.postPackageWarning') }}
		</div>
		<div slot="footer" class="text-center">
			<p-button type="success" @click="confirmWriteOff">
				{{ $t('pickup.confirm') }}
			</p-button>
			<p-button type="danger" @click="cancelWriteOff">
				{{ $t('pickup.cancel') }}
			</p-button>
		</div>
	</el-dialog>
</template>

<script>
import { Dialog as ElDialog } from 'element-ui';

import ValidationMixin from '@/util/validated-form-mixin';

export default {
	name: 'ReservationListWriteOffDialog',
	components: {
		ElDialog,
	},
	mixins: [ValidationMixin],
	props: {
		reservation: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			show: false,
			loading: false,
		};
	},
	computed: {
		writeOffMessage() {
			return `${
				this.reservation.reservationNumber
					? `${this.$t('pickup.writeOffNumberConfirmation')} ${this.reservation.reservationNumber}?`
					: `${this.$t('pickup.writeOffConfirmation')}?`
			}`;
		},
	},
	watch: {
		reservation: {
			handler(val) {
				this.show = Boolean(val);
			},
		},
	},
	methods: {
		async confirmWriteOff() {
			this.loading = true;
			await this.handleBackEndErrors(true, this.$service.reservation.writeOffReservation, this.reservation.reservationId);
			this.closeConfirmDialog(true);
			this.loading = false;
		},
		cancelWriteOff() {
			this.closeConfirmDialog(false);
		},
		closeConfirmDialog(returnValue) {
			this.show = false;

			this.$emit('close', returnValue);
		},
	},
};
</script>

<style lang="scss" scoped>
.postPackageWarning {
	display: block;
	color: red;
	font-size: large;
	text-align: center;
}
</style>
