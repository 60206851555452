<template>
	<base-report-print-sheet-table nomargin>
		<tr>
			<th colspan="2">
				{{ $t('global.protocolNumber') }} {{ reportData.logisticRouteId }}
			</th>
			<th colspan="2">
				{{ $t('global.vehicle') }}:
			</th>
		</tr>
		<tr>
			<td>{{ $t('global.createdBy') }}:</td>
			<td>{{ getFullName(reportData.createdBy) }}</td>
			<td>RZ:</td>
			<td class="userField" />
		</tr>
		<tr>
			<td>{{ $t('global.driver') }}:</td>
			<td>{{ getFullName(reportData.driver) }}</td>
			<td>{{ $t('global.kmBefore') }}:</td>
			<td />
		</tr>
		<tr>
			<td>{{ $t('global.date') }}:</td>
			<td>{{ reportData.createdDate | datetime }}</td>
			<td>{{ $t('global.kmAfter') }}:</td>
			<td />
		</tr>
		<tr>
			<td>{{ $t('global.dateOfRoute') }}:</td>
			<td />
			<td>{{ $t('global.averageFuelConsumption') }}:</td>
			<td />
		</tr>
		<tr>
			<td>{{ $t('global.driverSign') }}:</td>
			<td />
			<td>{{ $t('global.averageSpeed') }}:</td>
			<td />
		</tr>
	</base-report-print-sheet-table>
</template>

<script>
import BaseReportPrintSheetTable from '@/components/Base/Report/BaseReportPrintSheetTable.vue';

export default {
	name: 'BaseProtocolInfo',
	components: { BaseReportPrintSheetTable },
	props: {
		reportData: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
		};
	},
	methods: {
		getFullName(person) {
			return person ? `${person.name} ${person.surname}` : null;
		},
	},
};
</script>

<style lang="scss" scoped>
tbody {
	border-bottom: 3px solid black;
}
</style>
