<template>
	<card>
		<h2 v-if="editing" slot="header" class="card-title">
			Editace cenové mapy {{ priceMap.priceMapId }}
		</h2>
		<h2 v-else slot="header" class="card-title">
			Nová cenová mapa
		</h2>

		<form>
			<form-group-input
				v-model="priceMap.description"
				v-validate="validations.priceMapDescription.rules"
				placeholder="Zadejte popis"
				label="Název"
				:name="validations.priceMapDescription.name"
				:error="getError(validations.priceMapDescription.name)"
				data-vv-as="Název" />
			<form-group-input :error="getError(validations.priceMapBasePrice.name)" label="Cena">
				<el-input
					v-model.number="priceMap.basePrice"
					v-validate="validations.priceMapBasePrice.rules"
					class="styled-input"
					type="number"
					placeholder="Zadejte cenu"
					:name="validations.priceMapBasePrice.name"
					:error="getError(validations.priceMapBasePrice.name)"
					data-vv-as="Cena">
					<template slot="append">
						Kč/hod.
					</template>
				</el-input>
			</form-group-input>
			<form-group-input label="Cenové výjimky">
				<el-table :data="priceMap.priceExceptions">
					<el-table-column property="description">
						<template #header>
							<el-tooltip :content="$t('admin.name')" placement="top-start">
								<span>{{ $t('admin.name') }}</span>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column>
						<template #header>
							<el-tooltip :content="$t('admin.actions')" placement="top-start">
								<span>{{ $t('admin.actions') }}</span>
							</el-tooltip>
						</template>
						<template #default="scope">
							<el-tooltip :content="$t('admin.tooltips.edit')" placement="top-start">
								<p-button
									type="success"
									class="mx-1"
									size="sm"
									icon
									@click="sendSelectedException(scope.row)">
									<i class="fa fa-edit" />
								</p-button>
							</el-tooltip>
							<el-tooltip :content="$t('admin.tooltips.delete')" placement="top-start">
								<p-button
									type="danger"
									class="mx-1"
									size="sm"
									icon
									@click="deletePme(scope.row.priceExceptionId)">
									<i class="fa fa-times" />
								</p-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</form-group-input>
			<price-timeline :key="rerenderTimeLine" :data="priceMap" />
			<div class="pull-left">
				<p-button
					type="default"
					size="sm"
					outline
					round
					@click="sendNewPriceMapException()">
					{{ $t('admin.submitPriceException') }}
				</p-button>
			</div>
			<confirm-button
				:callback="() => validateAndSave('admin')"
				:disabled="!isValid"
				:show-success-message="false"
				class="pull-right">
				{{ $t('admin.save') }}
			</confirm-button>
		</form>
	</card>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Input as ElInput, Tooltip as ElTooltip } from 'element-ui';

import ValidationMixin from '@/util/validation-mixin';
import Validations from '@/util/validations';
import PriceTimeline from '@/modules/admin/views/PriceMap/PriceMapDetails/PriceTimeline.vue';
import { appName } from '@/modules/admin/config';
import { formChanged } from '@/store/mutation-types';

export default {
	name: 'PriceMapFormCard',
	components: {
		PriceTimeline,
		ElInput,
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	mixins: [ValidationMixin],
	props: {
		priceMap: { type: Object, default: () => {} },
		deletePmeCb: { type: Function, required: true },

	},
	data() {
		return {
			validations: {
				priceMapDescription: {
					name: 'priceMapDescription',
					rules: Validations.requiredField,
				},
				priceMapBasePrice: {
					name: 'priceMapBasePrice',
					rules: Validations.requiredField,
				},
			},
			rerenderTimeLine: true,
			priceMapLoaded: false,
		};
	},
	computed: {
		editing() {
			return this.$route.params.id;
		},
	},
	watch: {
		priceMap: {
			handler() {
				this.rerenderTimeLine = !this.rerenderTimeLine;
				if (this.priceMapLoaded) {
					this.$store.commit(`${appName}/${formChanged}`, true);
				}
			},
			deep: true,
		},
	},
	updated() {
		this.priceMapLoaded = true;
	},
	methods: {
		async save() {
			if (this.editing) {
				await this.$service.priceMap.update(this.priceMap);
			} else {
				await this.$service.priceMap.add(this.priceMap);
			}
			this.$store.commit(`${appName}/${formChanged}`, false);
			this.$router.push({ name: 'price-map-list' });
		},
		sendSelectedException(sel) {
			this.$emit('price-map-exception-selected', sel);
		},
		sendNewPriceMapException() {
			this.$emit('new-price-map-exception');
		},
		async deletePme(priceExceptionId) {
			await this.deletePmeCb(priceExceptionId);
		},
	},
};
</script>
