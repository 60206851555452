<template>
	<div v-loading="loading">
		<card>
			<template slot="header">
				<h4 v-if="clickedParamName" class="card-title">
					{{ clickedParamName }}
				</h4>
				<h5 v-else class="card-title">
					{{ $t('admin.clickParamDescription') }}
				</h5>
			</template>
			<line-chart
				:labels="chartLabels"
				:height="250"
				:color="stockChart.color"
				:extra-options="stockChart.options"
				:datasets="datasets" />
		</card>
	</div>
</template>

<script>
import moment from 'moment';

import LineChart from '@/components/UIComponents/Charts/LineChart';

const tooltipOptions = {
	tooltipFillColor: 'rgba(0,0,0,0.5)',
	tooltipFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
	tooltipFontSize: 14,
	tooltipFontStyle: 'normal',
	tooltipFontColor: '#fff',
	tooltipTitleFontFamily: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
	tooltipTitleFontSize: 14,
	tooltipTitleFontStyle: 'bold',
	tooltipTitleFontColor: '#fff',
	tooltipYPadding: 6,
	tooltipXPadding: 6,
	tooltipCaretSize: 8,
	tooltipCornerRadius: 6,
	tooltipXOffset: 10,
};
export default {
	name: 'DeliveryPointStatusChart',
	components: {
		LineChart,
	},
	props: {
		params: { type: Array, default: null },
		clickedParamId: { type: Number, default: null },
		clickedParamName: { type: String, default: null },
		loading: { type: Boolean, default: false },
	},
	data() {
		return {
			stockChart: {
				datasets: [
					{
						label: 'Active Users',
						borderColor: '#f17e5d',
						pointBackgroundColor: '#f17e5d',
						pointRadius: 3,
						pointHoverRadius: 3,
						lineTension: 0,
						fill: false,
						borderWidth: 3,
						data: [200, 250, 300, 350, 280, 330, 400],
					},
				],
				color: '#f17e5d',
				options: {
					tooltips: tooltipOptions,
					scales: {
						yAxes: [
							{
								ticks: {
									fontColor: '#9f9f9f',
									beginAtZero: false,
									maxTicksLimit: 5,
								},
								gridLines: {
									drawBorder: false,
									borderDash: [8, 5],
									zeroLineColor: 'transparent',
									color: '#9f9f9f',
								},
							},
						],
						xAxes: [
							{
								barPercentage: 1.6,
								gridLines: {
									drawBorder: false,
									borderDash: [8, 5],
									color: '#9f9f9f',
									zeroLineColor: 'transparent',
								},
								ticks: {
									padding: 20,
									fontColor: '#9f9f9f',
								},
							},
						],
					},
				},
			},
		};
	},
	computed: {
		chartLabels() {
			if (this.params && this.params.length) {
				const dates = [];
				this.params.forEach((el) => {
					dates.push(moment.utc(String(el.date)).local().format('DD.MM - HH:mm'));
				});
				return dates.reverse();
			}
			return [''];
		},
		datasets() {
			const dataset = [
				{
					label: this.clickedParamName,
					borderColor: '#f17e5d',
					pointBackgroundColor: '#f17e5d',
					pointRadius: 3,
					pointHoverRadius: 3,
					lineTension: 0,
					fill: false,
					borderWidth: 3,
					data: [1, 2, 3],
				},
			];
			if (this.params && this.params.length) {
				const data = [];
				this.params.forEach((el) => {
					data.push(el.values[this.clickedParamId]);
				});
				dataset[0].data = data.reverse();
				return dataset;
			}
			return dataset;
		},
	},
};
</script>
