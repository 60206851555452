export const DefaultReplacementService = (http) => ({
	async getReplacements() {
		const response = await http.get('/api/TemplateDefaultValues');
		return response.data;
	},
	async addOrUpdate(replacement) {
		const response = await http.post('/api/TemplateDefaultValues/Update', replacement);
		return response.data;
	},
	async delete(replacement) {
		const response = await http.delete('/api/TemplateDefaultValues', { data: replacement });
		return response.data;
	},
	async getPlaceholders(target) {
		const response = await http.get('/api/TemplateDefaultValues/placeholders', { params: { target } });
		return response.data;
	},
});
