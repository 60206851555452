<template>
	<card v-loading="loading">
		<div id="printReturned">
			<h2>{{ $t('logistics.routeReportCollectedReservations') }} - {{ reportName }}</h2>
			<base-report-print-sheet-table>
				<tr v-for="reservation in collectedPackages" :key="reservation.reservationId">
					<td>
						<i :class="ReservationStatus.icon(reservation.currentStatus)" />
						{{ ReservationStatus.czech(reservation.currentStatus) }}
					</td>
					<td>
						{{ reservation.reservationNumber }}
					</td>
					<td>{{ reservationName(reservation) }}</td>
					<td>{{ reservation.phone }}</td>
				</tr>
			</base-report-print-sheet-table>
		</div>
		<div class="mt-3 d-flex justify-content-between">
			<div>
				<p-button @click="printReturned">
					<i slot="labelRight" class="fa fa-print" />
					{{ $t('logistics.print') }}
				</p-button>
			</div>
		</div>
	</card>
</template>

<script>
import { ReservationStatus } from '@/modules/logistics/store/enums';
import PrintArea from '@/util/print-area';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import { BaseReportPrintSheetTable } from '@/components/Base';

export default {
	name: 'CollectedPackages',
	components: {
		BaseReportPrintSheetTable,
	},
	mixins: [ReservationNameMixin],
	props: {
		reportName: {
			type: String,
			default: '',
		},
		collectedPackages: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			ReservationStatus,
		};
	},
	methods: {
		printReturned() {
			new PrintArea('printReturned').print();
		},
	},
};
</script>
