import _ from 'lodash';

import { appNameList } from '@/globalConstants';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import { getAuthDataStorageName } from '@/store/auth-actions';
import ReservationCollectionMutations from '@/store/reservation-collection-mutations';
import { authFailed, loggedOut } from '@/store/mutation-types';
import BaseMutations from '@/store/base-mutations';

import {
	setContainerParameters,
	cleanSheetForPrint,
	addSheetForPrint,
	removeSheetForPrint,
	setContainerPriceMaps,
	addContainersForSelect,
} from './mutation-types';
import { getDefaultState } from './state';

export default {
	...BaseMutations,
	...ReservationCollectionMutations,

	[authFailed](state, { error }) {
		BaseMutations[authFailed](state, { error });
		localStorage.removeItem(getAuthDataStorageName(appNameList.eshop));
	},

	[loggedOut](state) {
		Object.assign(state, getDefaultState());
		BaseMutations[loggedOut](state);
	},

	[setContainerParameters](state, { knownParameters }) {
		state.containerValues = knownParameters.reduce((resultObject, parameter) => {
			resultObject[parameter.id] = parameter;
			return resultObject;
		}, {});
	},

	[cleanSheetForPrint](state) {
		state.sheetForPrint = [];
	},

	[addSheetForPrint](state, reservation) {
		if (!state.sheetForPrint.some((e) => e.reservationId === reservation.reservationId)) {
			for (let i = 1; i <= reservation.packagesCount; i++) {
				const res = _.clone(reservation);
				res.packageNumber = i;
				state.sheetForPrint.push(res);
			}
		}
	},

	[removeSheetForPrint](state, index) {
		state.sheetForPrint.splice(index, 1);
	},

	[setContainerPriceMaps](state, { priceMaps }) {
		state.priceMaps = priceMaps;
	},
	[addContainersForSelect](state, containers) {
		state.containersForSelect = containers;
	},
};
