<template>
	<component
		:is="tag"
		v-click-outside="closeDropDown"
		class="dropdown"
		:class="[{ show: isOpen }]"
		aria-haspopup="true"
		:aria-expanded="isOpen"
		@click="toggleDropDown">
		<slot name="title" :is-open="isOpen">
			<a class="dropdown-toggle nav-link" :class="{ 'no-caret': hideArrow }" data-toggle="dropdown">
				<i :class="icon" />
				<span class="no-icon">{{ title }}</span>
			</a>
		</slot>
		<ul :class="[{ 'dropdown-menu-right': position === 'right' }, { show: isOpen }]" :x-placement="directionAttribute" class="dropdown-menu">
			<slot />
		</ul>
	</component>
</template>

<script>
export default {
	name: 'Dropdown',
	props: {
		direction: {
			type: String,
			default: 'down',
			description: 'Drop down menu direction (up|down)',
		},
		title: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default: null,
		},
		position: {
			default: null,
			type: String,
			description: 'Drop down menu arrow position (left|right)',
		},
		hideArrow: {
			type: Boolean,
			description: 'Whether to hide drop down arrow',
		},
		tag: {
			type: String,
			default: 'li',
			description: 'Html tag of the dropdown',
		},
	},
	data() {
		return {
			isOpen: false,
		};
	},
	computed: {
		directionAttribute() {
			let baseDirection = '';
			if (this.direction === 'up') {
				baseDirection = this.position === 'right' ? 'top-end' : 'top-start';
			} else if (this.direction === 'down') {
				baseDirection = this.position === 'right' ? 'bottom-end' : 'bottom-start';
			}
			return baseDirection;
		},
	},
	methods: {
		toggleDropDown() {
			this.isOpen = !this.isOpen;
			this.$emit('change', this.isOpen);
		},
		closeDropDown() {
			this.isOpen = false;
			this.$emit('change', this.isOpen);
		},
	},
};
</script>

<style>
.dropdown {
	list-style-type: none;
}

.dropdown .dropdown-toggle {
	cursor: pointer;
}
</style>
