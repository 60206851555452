<template>
	<div class="row">
		<div
			v-loading="loading || loadingContainerForSelect"
			class="col-md-12 card">
			<h5 class="card-header mb-2 font-weight-bold">
				{{ $t('admin.exportChoice') }}
			</h5>
			<div class="col">
				<div class="d-flex justify-content-between">
					<div class="col">
						<form-group-input
							:label="$t('admin.deliveryPointType')"
							required>
							<div>
								<el-radio-group v-model="containerType" @change="select">
									<el-radio-button type="primary" :label="containerTypes.store">
										{{ $t("admin.stores") }}
									</el-radio-button>
									<el-radio-button type="primary" :label="containerTypes.penguinBox">
										{{ $t("admin.penguinBoxes") }}
									</el-radio-button>
									<el-radio-button type="primary" :label="containerTypes.smartBox">
										{{ $t("admin.smartBoxes") }}
									</el-radio-button>
								</el-radio-group>
							</div>
						</form-group-input>
					</div>
				</div>
				<div class="d-flex justify-content-between">
					<div class="col">
						<form-group-input :error="getError(exportValidation.fromDate.field)" :label="$t('admin.dateFrom')" required>
							<date-picker
								v-model="fromDate"
								v-validate="exportValidation.fromDate.rule"
								:name="exportValidation.fromDate.field"
								:data-vv-as="$t('admin.dateFrom')"
								type="datetime"
								prefix-icon="el-icon-date"
								format="dd.MM.yyyy HH:mm"
								required />
						</form-group-input>
					</div>
					<div class="col">
						<form-group-input :label="$t('admin.dateTo')">
							<date-picker
								v-model="toDate"
								type="datetime"
								prefix-icon="el-icon-date"
								format="dd.MM.yyyy HH:mm" />
						</form-group-input>
					</div>
				</div>
				<div class="d-flex justify-content-between">
					<div class="col">
						<p class="note text-muted">
							{{ $t('admin.required') }}
						</p>
					</div>
				</div>
				<span class="d-flex justify-content-between">
					<div class="col-2 d-flex mb-1 justify-content-start">
						<p-button
							type="success"
							size="md"
							:disabled="!canSave"
							@click="loadData()">
							{{ $t('admin.exportHistory') }}
						</p-button>
						<el-tooltip :content="$t('admin.clearFilter')" placement="top-start">
							<p-button
								class="ml-2"
								type="danger"
								icon
								size="md"
								@click="clearFilter">
								<i class="fa fa-times" />
							</p-button>
						</el-tooltip>
					</div>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { DatePicker, Tooltip as ElTooltip } from 'element-ui';

import Validation from '@/util/validations';
import ValidationForm from '@/util/validation-mixin';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
// eslint-disable-next-line import/no-cycle
import ContainersForSelectMixin from '@/util/containers-for-select-mixin';
import ContainerSelectMixin from '@/util/container-select-mixin';
import { containerTypes } from '@/globalConstants';

export default {
	name: 'HistoryExport',
	components: {
		ElTooltip,
		DatePicker,
	},
	mixins: [ValidationForm, ContainersForSelectMixin, ContainerSelectMixin],
	data() {
		return {
			loading: false,
			loadingContainerForSelect: false,
			containers: null,
			fromDate: null,
			toDate: null,
			exportValidation: {
				fromDate: {
					rule: Validation.requiredField,
					field: 'fromDate',
				},
				containers: {
					rule: Validation.requiredField,
					field: 'containers',
				},
			},
			containerTypes,
			containerType: null,
		};
	},
	computed: {
		...mapState(appName, ['containersForSelect']),
		canSave() {
			return this.containers && this.fromDate
			&& (!this.toDate || this.fromDate < this.toDate);
		},
		freeContainers() {
			this.enrichFreeContainers(this.containersForSelect);
			return this.containersForSelect;
		},
	},
	mounted() {
		this.setContainerSelectEventListener();
	},
	methods: {
		formatContainerName(container) {
			return this.$options.filters.location(container);
		},
		clearFilter() {
			this.containers = [];
			this.fromDate = null;
			this.toDate = null;
			this.clearLastQuery();
		},
		async loadData() {
			this.loading = true;
			try {
				await this.validate();
				if (this.canSave) {
					await this.$service.reservation.fetchHistory(this.containers.map((c) => c.containerId), this.fromDate, this.toDate);
				}
			} finally {
				this.loading = false;
			}
		},
		select() {
			this.containers = this.freeContainers.filter((c) => c.type === this.containerType);
		},
	},
};
</script>
