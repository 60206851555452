<template>
	<div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
		<label :for="cbId" class="form-check-label" data-cy="checkbox">
			<input
				:id="cbId"
				v-model="model"
				class="form-check-input"
				type="checkbox"
				:disabled="disabled"
				:value="value">
			<span class="form-check-sign" />
			<slot>
				<span v-if="inline">&nbsp;</span>
			</slot>
		</label>
	</div>
</template>

<script>
export default {
	name: 'PCheckbox',
	model: {
		prop: 'checked',
	},
	props: {
		checked: {
			default: null,
			type: [Array, Boolean],
			description: 'Whether checkbox is checked',
		},
		disabled: {
			type: Boolean,
			description: 'Whether checkbox is disabled',
		},
		inline: {
			type: Boolean,
			description: 'Whether checkbox is inline',
		},
		value: {
			type: String,
			default: null,
			nullable: true,
		},
	},
	data() {
		return {
			cbId: '',
			touched: false,
		};
	},
	computed: {
		model: {
			get() {
				return this.checked;
			},
			set(check) {
				if (!this.touched) {
					this.touched = true;
				}
				this.$emit('input', check);
			},
		},
		inlineClass() {
			if (this.inline) {
				return 'form-check-inline';
			}
			return '';
		},
	},
	created() {
		this.cbId = Math.random().toString(16).slice(2);
	},
};
</script>
