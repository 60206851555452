<template>
	<div class="row">
		<div class="col-md-12 card">
			<div class="d-flex mt-2">
				<div class="col-2">
					<form-group-input :label="$t('eshop.completed')" type="radio">
						<div>
							<el-tooltip :content="OrderStatus.czech(completedStatus)" placement="top-start">
								<p-radio v-model="filter.completed" inline label="true">
									<i class="fa fa-2x fa-check-circle" />
								</p-radio>
							</el-tooltip>
							<el-tooltip :content="OrderStatus.czech(waitingStatus)" placement="top-start">
								<p-radio v-model="filter.completed" inline label="false">
									<i class="fa fa-2x fa-shopping-cart" />
								</p-radio>
							</el-tooltip>
							<el-tooltip :content="$t('eshop.all')" placement="top-start">
								<p-radio v-model="filter.completed" inline label="">
									<i class="fa fa-2x fa-question-circle" />
								</p-radio>
							</el-tooltip>
						</div>
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input :label="$t('eshop.importDateFrom')">
						<date-picker
							v-model="filter.createdDateFrom"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime"
							@change="loadData(1)" />
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input :label="$t('eshop.importDateTo')">
						<date-picker
							v-model="filter.createdDateTo"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime"
							@change="loadData(1)" />
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input
						ref="resNumberInput"
						v-model="filter.code"
						:label="$t('eshop.code')"
						type="text" />
					<p-button type="primary" size="md" @click="toggleQrCode">
						{{ scanQrCodeLabel }}
					</p-button>
					<qrcode-stream v-if="scanQrCode" :camera="cameraSettings" @decode="onDecode" />
				</div>
			</div>
			<div v-show="advancedSearch">
				<div class="d-flex">
					<div class="col">
						<autocomplete
							v-model="filter.containerState"
							:label="$t('eshop.containerCountry')"
							trigger-on-focus
							search-field-name="States"
							@fetch-suggestions="querySearchAsyncContainer"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.containerRegion"
							:label="$t('eshop.containerRegion')"
							trigger-on-focus
							search-field-name="Regions"
							@fetch-suggestions="querySearchAsyncContainer"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.containerCounty"
							:label="$t('eshop.containerCounty')"
							trigger-on-focus
							search-field-name="Counties"
							@fetch-suggestions="querySearchAsyncContainer"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.containerCity"
							:label="$t('eshop.containerCity')"
							trigger-on-focus
							search-field-name="Cities"
							@fetch-suggestions="querySearchAsyncContainer"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.containerStreet"
							:label="$t('eshop.containerStreet')"
							trigger-on-focus
							search-field-name="Streets"
							@fetch-suggestions="querySearchAsyncContainer"
							@select="loadData" />
					</div>
				</div>
				<div class="d-flex mt-2">
					<div class="col">
						<autocomplete
							v-model="filter.country"
							:label="$t('eshop.country')"
							trigger-on-focus
							search-field-name="States"
							@fetch-suggestions="querySearchAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.city"
							:label="$t('eshop.city')"
							trigger-on-focus
							search-field-name="Cities"
							@fetch-suggestions="querySearchAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.street"
							:label="$t('eshop.street')"
							trigger-on-focus
							search-field-name="Streets"
							@fetch-suggestions="querySearchAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.zip" :label="$t('eshop.zip')" type="text" />
					</div>
				</div>
				<div class="d-flex mt-2">
					<div class="col">
						<form-group-input v-model="filter.email" :label="$t('eshop.email')" type="email" />
					</div>
					<div class="col">
						<form-group-input
							v-model="filter.phone"
							:label="$t('eshop.phone')"
							type="text"
							@keypress="isTelNumber($event)" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.shoptetContainerIdentifier" :label="$t('eshop.shoptetContainerIdentifier')" type="text" />
					</div>
					<div class="col">
						<form-group-input v-model="filter.name" :label="$t('eshop.firstName')" type="text" />
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-between">
				<div class="col-2 d-flex mb-1 justify-content-start">
					<p-button type="success" size="md" @click="loadData">
						{{ $t('eshop.search') }}
					</p-button>
					<el-tooltip :content="$t('eshop.tooltips.clearFilter')" placement="top-start">
						<p-button
							class="ml-2"
							type="danger"
							icon
							size="md"
							@click="clearFilter">
							<i class="fa fa-times" />
						</p-button>
					</el-tooltip>
				</div>
				<div class="d-flex mb-1 mr-3">
					<p-button @click="toggleAdvancedSearch">
						{{ searchButtonLabel }}
					</p-button>
				</div>
			</div>
		</div>

		<div class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
				<div v-loading="loading" class="row">
					<div class="col-sm-12 mt-2">
						<el-table
							data-cy="ListMainTable"
							class="table-striped"
							:data="eshopOrders"
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							row-class-name="tableRow"
							@sort-change="sortChange"
							@row-click="goToEdit">
							<el-table-column header-align="center" sortable="custom" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.completed')" placement="top-start">
										<span>{{ $t('eshop.completed') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip
										:key="scope.row.completed"
										:content="OrderStatus.czech(scope.row.completed ? completedStatus : waitingStatus)"
										placement="top-start">
										<div :key="scope.row.completed">
											<i :class="OrderStatus.icon(scope.row.completed ? completedStatus : waitingStatus)" />
										</div>
									</el-tooltip>
								</template>
							</el-table-column>

							<el-table-column property="code" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.code')" placement="top-start">
										<span>{{ $t('eshop.code') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="amount" header-align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.amount')" placement="top-start">
										<span>{{ $t('eshop.amount') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								:label="$t('eshop.deliveryPoint')"
								header-align="center">
								<el-table-column property="shoptetContainerIdentifier" header-align="center" sortable="custom">
									<template #header>
										<el-tooltip :content="$t('eshop.shoptetContainerIdentifier')" placement="top-start">
											<span>{{ $t('eshop.shoptetContainerIdentifier') }}</span>
										</el-tooltip>
									</template>
								</el-table-column>
								<el-table-column property="container.city" header-align="center" sortable="custom">
									<template #header>
										<el-tooltip :content="$t('eshop.containerity')" placement="top-start">
											<span>{{ $t('eshop.city') }}</span>
										</el-tooltip>
									</template>
								</el-table-column>
								<el-table-column property="container.street" header-align="center" sortable="custom">
									<template #header>
										<el-tooltip :content="$t('eshop.containerStreet')" placement="top-start">
											<span>{{ $t('eshop.street') }}</span>
										</el-tooltip>
									</template>
								</el-table-column>
							</el-table-column>
							<el-table-column property="createdDate" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.created')" placement="top-start">
										<span>{{ $t('eshop.created') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.createdDate | datetime }}
								</template>
							</el-table-column>
							<el-table-column property="name" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.firstName')" placement="top-start">
										<span>{{ $t('eshop.firstName') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="email" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.email')" placement="top-start">
										<span>{{ $t('eshop.email') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="phone" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.phone')" placement="top-start">
										<span>{{ $t('eshop.phone') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.phone | phone }}
								</template>
							</el-table-column>
							<el-table-column property="city" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.city')" placement="top-start">
										<span>{{ $t('eshop.city') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="street" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.street')" placement="top-start">
										<span>{{ $t('eshop.street') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column min-width="160%">
								<template #default="scope">
									<span v-if="!scope.row.completed" class="col px-1">
										<el-tooltip :content="$t('eshop.tooltips.orderFromShoptet')" placement="top-start">
											<p-button @click.stop="openTransportOrderModal(scope.row)">
												{{ $t('eshop.placeOrder') }}
											</p-button>
										</el-tooltip>
									</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 mt-2">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="orderTransportModalVisible" width="70%" close-on-click-modal>
			<eshop-order-list-order-transport-modal :shoptet="selectedRow" />
		</el-dialog>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Dialog as ElDialog, Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination, DatePicker, Tooltip as ElTooltip } from 'element-ui';
import { QrcodeStream } from 'vue-qrcode-reader';

import { OrderStatus } from '@/modules/eshop/store/enums';
import EshopOrderListOrderTransportModal from '@/modules/eshop/views/EshopOrder/EshopOrderList/EshopOrderListOrderTransportModal.vue';
import { isTelNumber } from '@/util/helpers';
import { appName } from '@/modules/eshop/config';
import { resetFilter, addReservationForCollection, setFilter } from '@/store/mutation-types';

export default {
	name: 'EshopOrderList',
	components: {
		EshopOrderListOrderTransportModal,
		ElPagination,
		ElTable,
		ElDialog,
		ElTableColumn,
		DatePicker,
		ElTooltip,
		QrcodeStream,
	},
	data() {
		return {
			orderTransportModalVisible: false,
			selectedRow: null,
			loading: false,
			page: {},
			advancedSearch: false,
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			OrderStatus,
			scanQrCode: false,
			filter: {},
			abortController: null,
		};
	},

	computed: {
		...mapState(appName, ['containerValues', 'reservationsForCollect', 'reservationFilter', 'shoptetFilter', 'currentUser']),
		eshopOrders() {
			return this.page.entities;
		},
		searchButtonLabel() {
			return this.advancedSearch ? this.$t('eshop.simpleSearch') : this.$t('eshop.advancedSearch');
		},
		completedStatus() {
			return OrderStatus.COMPLETED.name;
		},
		waitingStatus() {
			return OrderStatus.WAITING.name;
		},
		scanQrCodeLabel() {
			return this.scanQrCode ? this.$t('eshop.hideQrCode') : this.$t('eshop.scanQrCode');
		},
	},
	watch: {
		filter: {
			deep: true,
			async handler(val) {
				this.filter.code = val.code;

				if (val.code?.length >= 0) {
					this.scanQrCode = false;
					await this.loadData();
					if (this.page.entities.length === 1 && !this.page.entities[0].completed) {
						val.code = null;
						this.openTransportOrderModal(this.page.entities[0]);
					}
				}
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'shoptetFilter', data: val });
			},
		},
	},
	created() {
		this.filter = this.shoptetFilter;
		this.loadData();
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
		this.$refs.resNumberInput.$el.querySelector('input').focus();
	},
	methods: {
		isTelNumber,
		...mapMutations(appName, [addReservationForCollection]),
		goToEdit(row) {
			this.$router.push({ name: 'eshop-order-detail', params: { id: row.eshopOrderId } });
		},
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}
			this.loadData(this.page.currentPage);
		},

		async loadData(page = 1) {
			this.loading = true;
			this.page = await this.$service.eshopOrder.getList(page, this.filter, this.pageSize);
			this.loading = false;
		},

		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		querySearchAsyncContainer(input, cb, searchFieldName) {
			this.abortController?.abort();
			this.abortController = new AbortController();
			const filter = {
				state: this.filter.containerState,
				city: this.filter.containerCity,
				region: this.filter.containerRegion,
				county: this.filter.containerCounty,
			};
			this.$service.container.searchByInput(input, searchFieldName, filter, this.abortController)
				.then((results) => {
					cb(results);
				});
		},

		querySearchAsync(input, cb, searchFieldName) {
			const { state, city, region, county } = this.filter;

			const filter = {
				state,
				city,
				region,
				county,
			};
			this.abortController?.abort();
			this.abortController = new AbortController();
			this.$service.container.searchByInput(input, searchFieldName, filter)
				.then((results) => {
					cb(results);
				});
		},

		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'shoptetFilter');
			this.filter = this.shoptetFilter;
			this.loadData();
		},

		openTransportOrderModal(row) {
			this.selectedRow = row;
			this.orderTransportModalVisible = true;
		},

		goToTransportOrderDetailPage(row) {
			this.$router.push({ name: 'box-order-shoptet', params: { shoptet: row } });
		},

		toggleAdvancedSearch() {
			this.advancedSearch = !this.advancedSearch;
		},

		onDecode(decodedString) {
			this.filter.code = decodedString;

			this.cameraReset();
		},

		toggleQrCode() {
			this.scanQrCode = !this.scanQrCode;
			if (!this.scanQrCode) {
				this.$refs.resNumberInput.$el.querySelector('input').focus();
			}

			this.switchCamera(this.scanQrCode);
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
.qrcode-stream-wrapper {
	height: auto !important;
	margin-bottom: 0.8em;
}
</style>
