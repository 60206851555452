import { isObject } from 'lodash';

import {
	addAppToLogin,
	authFailed,
	authSuccess,
	formChanged,
	loggedOut,
	removeAppToLogin,
	resetFilter,
	setCurrentUser,
	setFilter,
	setLoginActive,
	setRefreshTokenLoading,
	setVersionInfo,
} from './mutation-types';

export default {
	[authSuccess](state) {
		state.loginError = null;
	},

	[authFailed](state, { error }) {
		state.currentUser = {};
		state.loginError = error;
	},

	[loggedOut](state) {
		state.currentUser = {};
		state.loginError = null;
	},

	[setCurrentUser](state, { currentUser, roles }) {
		state.currentUser = currentUser;
		state.currentUser.roles = roles;
	},

	[setVersionInfo](state, { versionInfo }) {
		state.versionInfo = versionInfo;
	},

	[setRefreshTokenLoading](state, loading) {
		state.refreshTokenLoading = loading;
	},

	[setLoginActive](state, isLoginActive) {
		state.isLoginActive = isLoginActive;
	},

	[formChanged](state, changed) {
		state.formChanged = changed;
	},

	[resetFilter](state, filterName) {
		Object.keys(state[filterName]).forEach((keyName) => {
			const val = state[filterName][keyName];

			if (isObject(val) && !Array.isArray(val)) {
				const keys = Object.keys(val);

				if (keys.length) {
					keys.forEach((i) => {
						state[filterName][keyName][i] = null;
					});

					return;
				}
			}

			state[filterName][keyName] = Array.isArray(val) ? [] : null;
		});
	},

	[setFilter](state, { filterName, data }) {
		state[filterName] = data;
	},

	[setFilter](state, { filterName, data }) {
		state[filterName] = data;
	},

	[addAppToLogin](state, appName) {
		if (!state.appsToLogin) {
			state.appsToLogin = [];
		}

		if (!state.appsToLogin.includes(appName)) {
			state.appsToLogin.push(appName);
		}
	},

	[removeAppToLogin](state, appName) {
		state.appsToLogin = (state.appsToLogin || []).filter((appToLoginName) => appToLoginName !== appName);
	},
};
