<template>
	<div v-if="box" v-loading="loading">
		<h3>{{ $t('admin.persistentAccessToBox') }} {{ box.boxId }}</h3>
		<div class="mt-4">
			<span	v-show="!ownerIsSet">
				<form-group-input
					v-model="emailOfBoxOwner"
					v-validate="validations.emailOfBoxOwner.rules"
					:placeholder="$t('admin.enterEmailOfOwner')"
					:label="$t('admin.emailOfBoxOwner')"
					:error="getError(validations.emailOfBoxOwner.name)"
					:name="validations.emailOfBoxOwner.name"
					:data-vv-as="$t('admin.emailOfBoxOwner')" />
				<div class="d-flex justify-content-around">
					<p-button type="success" @click="setOwnerToBox()">
						{{ $t('admin.setOwner') }}
					</p-button>
				</div>
			</span>
			<span v-show="ownerIsSet">
				<form-group-input
					:placeholder="emailOfBoxOwner"
					disabled="true"
					:label="$t('admin.emailOfBoxOwner')" />
				<div class="d-flex justify-content-around">
					<confirm-button
						type="danger"
						:message-success="$t('admin.removeOwnerOfBoxSuccess')"
						:message-error="$t('admin.removeOwnerOfBoxError')"
						:confirm-message="$t('admin.removeOwnerOfBox')"
						:callback="() => removeOwnerOfBox()">
						{{ $t('admin.removeOwner') }}
					</confirm-button>
					<confirm-button
						v-if="isPublic"
						:message-success="$t('admin.removePublicAccessOfBoxSuccess')"
						:message-error="$t('admin.removePublicAccessOfBoxError')"
						:confirm-message="$t('admin.removePublicAccessOfBox')"
						:callback="() => removePublicAccess()">
						{{ $t('admin.removePublicAccess') }}
					</confirm-button>
					<confirm-button
						v-else
						:message-success="$t('admin.allowPublicAccessOfBoxSuccess')"
						:message-error="$t('admin.allowPublicAccessOfBoxError')"
						:confirm-message="$t('admin.allowPublicAccessOfBox')"
						:callback="() => allowPublicAccess()">
						{{ $t('admin.allowPublicAccess') }}
					</confirm-button>
				</div>
			</span>
		</div>
		<el-table class="table-striped m-2" size="mini" :data="persistentAccessUsers">
			<el-table-column key="155">
				<template #header>
					<el-tooltip :content="$t('admin.usersWithPermanentAccess')" placement="top-start">
						<span>{{ $t('admin.usersWithPermanentAccess') }}</span>
					</el-tooltip>
				</template>
				<template #default="scope">
					{{ scope.row.mail }}
				</template>
			</el-table-column>
			<el-table-column key="255" width="170px">
				<template #header>
					<el-tooltip :content="$t('admin.removePersistentAccess')" placement="top-start">
						<span>{{ $t('admin.removePersistentAccess') }}</span>
					</el-tooltip>
				</template>
				<template #default="scope">
					<div class="d-flex justify-content-center m-1">
						<confirm-button
							type="danger"
							icon
							round
							size="sm"
							:confirm-message="$t('admin.removeUserWithPersistentAccess')"
							:message-success="$t('admin.removeUserWithPersistentAccessSuccess')"
							:message-error="$t('admin.removeUserWithPersistentAccessError')"
							:callback="() => removeUserWithPersistentAccess(scope.row.grantId)">
							<i class="fa fa-minus" />
						</confirm-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="mt-4">
			<form-group-input
				v-model="insertedEmailOfNewUserWithPersistentAccess"
				v-validate="validations.emailOfUserWithPersistentAccess.rules"
				:placeholder="getEmailOfUserWithPersistentAccessPlaceholder()"
				:disabled="!ownerIsSet"
				:label="$t('admin.enterEmailOfUserToAddPersistentAccess')"
				:error="getError(validations.emailOfUserWithPersistentAccess.name)"
				:name="validations.emailOfUserWithPersistentAccess.name"
				:data-vv-as="$t('admin.userEmailWithPersistentAccess')" />
			<div class="d-flex justify-content-around">
				<p-button :disabled="!insertedEmailOfNewUserWithPersistentAccess || !ownerIsSet" type="success" @click="addNewUserWithPersistentAccess">
					{{ $t('admin.addUserWithPersistentAccess') }}
				</p-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip } from 'element-ui';

import ValidationMixin from '@/util/validated-form-mixin';
import Validations from '@/util/validations';
import { ConfirmButton } from '@/components/UIComponents';
import notifyService from '@/service/notify-service';

import PButton from '../../../../../../components/UIComponents/PButton.vue';

export default {
	name: 'DeliveryPointKeyOwnersDialog',
	components: {
		ElTable,
		ElTableColumn,
		ElTooltip,
		ConfirmButton,
		PButton,
	},
	mixins: [ValidationMixin],
	props: {
		showKeyOwnersDialog: { type: Boolean, default: false },
		box: { type: Object, default: null },
	},
	data() {
		return {
			loading: false,
			insertedEmailOfNewUserWithPersistentAccess: null,
			emailOfBoxOwner: null,
			ownerIsSet: false,
			isPublic: false,
			persistentAccessUsers: null,
			validations: {
				emailOfUserWithPersistentAccess: {
					name: 'emailOfUserWithPersistentAccess',
					rules: Validations.email,
				},
				emailOfBoxOwner: {
					name: 'emailOfBoxOwner',
					rules: Validations.email,
				},
			},
		};
	},
	computed: {
		tableData() {
			return this.persistentAccessUsers;
		},
	},
	watch: {
		box: {
			immediate: true,
			async handler() {
				this.ownerIsSet = !!this.box.allowedCustomerMail;
				this.emailOfBoxOwner = this.box.allowedCustomerMail;
				this.isPublic = this.box.publicByOwner;
				await this.loadPersistentAccessUsers();
			},
		},
		showKeyOwnersDialog(value) {
			if (value) {
				this.loadPersistentAccessUsers();
			} else {
				this.persistentAccessUsers = null;
			}
		},
	},
	methods: {
		async loadPersistentAccessUsers() {
			this.loading = true;
			this.persistentAccessUsers = await this.handleBackEndErrors(false, this.$service.smartBox.getPersistentAccessUsers, this.box.boxId);
			this.loading = false;
		},
		async addNewUserWithPersistentAccess() {
			this.loading = true;
			const result = await this.handleBackEndErrors(
				true,
				this.$service.smartBox.grantPersistentAccess,
				this.insertedEmailOfNewUserWithPersistentAccess,
				this.box.boxId,
			);
			this.insertedEmailOfNewUserWithPersistentAccess = null;
			this.persistentAccessUsers = result;
			this.loading = false;

			this.$emit('update-pma-keys');
		},
		async removeUserWithPersistentAccess(grantId) {
			this.loading = true;
			const result = await this.handleBackEndErrors(
				false,
				this.$service.smartBox.removePersistentAccess,
				grantId,
			);
			this.insertedEmailOfNewUserWithPersistentAccess = null;
			this.persistentAccessUsers = result;
			this.loading = false;
		},
		async setOwnerToBox() {
			try {
				this.loading = true;
				await this.$service.box.updateBoxCustomer(this.box.boxId, this.emailOfBoxOwner);
				this.addCustomerMailDialog = !this.addCustomerMailDialog;
				await this.loadPersistentAccessUsers();
				notifyService.notifySuccess(this.$t('admin.assignCustomerEmailAddressToBoxSuccess'));
			} catch (err) {
				notifyService.notifyError(this.$t('admin.assignCustomerEmailAddressToBoxError'));
			} finally {
				this.ownerIsSet = true;
				this.loading = false;
			}
		},
		async removeOwnerOfBox() {
			this.loading = true;
			await this.$service.box.updateBoxCustomer(this.box.boxId, null);
			this.ownerIsSet = false;
			this.persistentAccessUsers = null;
			this.emailOfBoxOwner = null;
			this.loading = false;
		},
		async allowPublicAccess() {
			this.loading = true;
			await this.$service.box.SetBoxAsPublic(this.box.boxId, this.box.allowedCustomerMail);
			this.isPublic = true;
			this.loading = false;
		},
		async removePublicAccess() {
			this.loading = true;
			await this.$service.box.SetBoxAsPrivate(this.box.boxId, this.box.allowedCustomerMail);
			this.isPublic = false;
			this.loading = false;
		},
		getEmailOfUserWithPersistentAccessPlaceholder() {
			if (this.ownerIsSet) {
				return this.$t('admin.enterEmail');
			}
			return this.$t('admin.firstSetOwner');
		},
	},
};
</script>
