<template>
	<div v-if="[appNameList.admin, appNameList.eshop].includes(currentAppName)">
		<card id="printArea">
			<h2>{{ $t('global.reservationToCollect') }}</h2>
			<table class="reservations table-bordered table">
				<thead>
					<tr>
						<th width="60">
							<el-tooltip :content="$t('global.key')" placement="top-start">
								<span>{{ $t('global.key') }}</span>
							</el-tooltip>
						</th>

						<th v-if="currentAppName === appNameList.admin">
							<el-tooltip :content="$t('global.reservation')" placement="top-start">
								<span>{{ $t('global.reservation') }}</span>
							</el-tooltip>
						</th>
						<th v-if="currentAppName === appNameList.admin">
							<el-tooltip :content="$t('global.contact')" placement="top-start">
								<span>{{ $t('global.contact') }}</span>
							</el-tooltip>
						</th>
						<th v-if="currentAppName === appNameList.admin">
							<el-tooltip :content="$t('global.reservedExpired')" placement="top-start">
								<span>{{ $t('global.reservedExpired') }}</span>
							</el-tooltip>
						</th>

						<th v-if="currentAppName === appNameList.eshop">
							<el-tooltip :content="$t('global.contact')" placement="top-start">
								<span>{{ $t('global.contact') }}</span>
							</el-tooltip>
						</th>
						<th v-if="currentAppName === appNameList.eshop">
							<el-tooltip :content="$t('global.occupied')" placement="top-start">
								<span>{{ $t('global.occupied') }}</span>
							</el-tooltip>
						</th>
						<th v-if="currentAppName === appNameList.eshop">
							<el-tooltip :content="$t('global.expired2')" placement="top-start">
								<span>{{ $t('global.expired2') }}</span>
							</el-tooltip>
						</th>

						<th>
							<el-tooltip :content="$t('global.city')" placement="top-start">
								<span>{{ $t('global.city') }}</span>
							</el-tooltip>
						</th>
						<th>
							<el-tooltip :content="$t('global.street')" placement="top-start">
								<span>{{ $t('global.street') }}</span>
							</el-tooltip>
						</th>
						<th>
							<el-tooltip :content="$t('global.description')" placement="top-start">
								<span>{{ $t('global.description') }}</span>
							</el-tooltip>
						</th>
						<th>
							<el-tooltip :content="$t('global.contentDescription')" placement="top-start">
								<span>{{ $t('global.contentDescription') }}</span>
							</el-tooltip>
						</th>
					</tr>
				</thead>
				<tbody v-if="reservationsForCollect.length">
					<tr v-for="reservation in reservationsForCollect" :key="`r-${reservation.reservationId}`">
						<td>
							<span v-if="currentAppName === appNameList.admin">
								{{ reservation.reservationId }}
							</span>
						</td>

						<td v-if="currentAppName === appNameList.admin">
							{{ reservation.reservationNumber }}
						</td>
						<td v-if="currentAppName === appNameList.admin">
							<span v-if="!!reservation.phone">{{ reservation.phone | phone }}</span> <span v-else>{{ reservation.email }}</span>
						</td>

						<td v-if="currentAppName === appNameList.eshop">
							{{ reservation.email }}
						</td>
						<td v-if="currentAppName === appNameList.eshop">
							{{ reservation.reservedFrom | date }}
						</td>

						<td>
							<span v-if="currentAppName === appNameList.admin">{{ reservation.reservedFrom | date }}</span> {{ reservation.expiresOn | date }}
						</td>
						<td>
							{{ reservation.container.city }}
						</td>
						<td>
							{{ reservation.container.city }}
						</td>
						<td>
							{{ reservation.container.location }}
						</td>
						<td />
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td colspan="8">
							<div class="alert alert-dark text-black-50 m-0 text-center">
								{{ $t('global.noData') }}
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</card>
		<p-button @click="print">
			{{ $t('global.print') }} <i class="fa fa-print" />
		</p-button>
	</div>
</template>

<script>
import { Tooltip as ElTooltip } from 'element-ui';

import { appNameList } from '@/globalConstants';

export default {
	name: 'BaseCollectionPrintSheet',
	components: {
		ElTooltip,
	},
	data() {
		return {
			appNameList,
		};
	},
	computed: {
		reservationsForCollect() {
			return this.$store.state[this.currentAppName].reservationsForCollect;
		},
	},
	methods: {
		print() {
			const win = window.open('', '', 'fullscreen=yes;titlebar=yes');
			const element = window.document.getElementById('printArea');

			this.writeHtml(win, element.innerHTML);
			this.addStyles(win, '/static/css/printing.css');
			this.printDocument(win);
		},
		writeHtml(win, bodyContent) {
			win.document.write(`
        <html lang="cs">
          <head>
          <title></title>
          </head>
          <body>
            ${bodyContent}
          </body>
        </html>
      `);
		},
		addStyles(win, url) {
			const link = win.document.createElement('link');
			link.setAttribute('rel', 'stylesheet');
			link.setAttribute('type', 'text/css');
			link.setAttribute('href', url);
			win.document.getElementsByTagName('head')[0].appendChild(link);
		},
		printDocument(win) {
			win.document.close();
			setTimeout(() => {
				win.print();
			}, 500);

			setTimeout(() => {
				win.close();
			}, 3000);
		},
	},
};
</script>
