<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="d-flex">
						<div class="col-6">
							<form-group-input :label="$t('admin.dateFrom')">
								<date-picker
									v-model="filter.from"
									type="datetime"
									prefix-icon="el-icon-date"
									format="dd.MM.yyyy HH:mm"
									@change="reloadValuesHistory(1)" />
							</form-group-input>
						</div>
						<div class="col-6">
							<form-group-input :label="$t('admin.dateTo')">
								<date-picker
									v-model="filter.to"
									type="datetime"
									prefix-icon="el-icon-date"
									format="dd.MM.yyyy HH:mm"
									@change="reloadValuesHistory(1)" />
							</form-group-input>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6">
				<div v-loading="loading" class="col-md-12 card">
					<div class="card-body">
						<div class="row">
							<div class="col">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="reloadValuesHistory"
									@size-change="loadDataPageSize" />
							</div>
						</div>
						<div class="row">
							<div class="col-sm-12 mt-2">
								<el-table
									class="table-striped"
									:data="parametersHistory"
									header-cell-class-name="headerCell"
									@header-click="headerClicked">
									<el-table-column>
										<template #header>
											<el-tooltip :content="$t('admin.date')" placement="top-start">
												<span>{{ $t('admin.date') }}</span>
											</el-tooltip>
										</template>
										<template #default="scope">
											{{ scope.row.date | datetime }}
										</template>
									</el-table-column>
									<el-table-column
										v-for="(item, index) in parameterTypes"
										:key="index"
										:index="item.id"
										:label="item.name"
										:property="'values[' + index + ']'">
										<template #header>
											<el-tooltip :content="item.name + ' [' + item.unit + ']'" placement="top-start">
												<span>{{ item.name + ' [' + item.unit + ']' }}</span>
											</el-tooltip>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="reloadValuesHistory"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<delivery-point-status-chart
					:clicked-param-id="clickedParamId"
					:clicked-param-name="clickedParamName"
					:loading="loading"
					:params="parametersHistory" />
			</div>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination, DatePicker, Tooltip as ElTooltip } from 'element-ui';
import { mapActions, mapState } from 'vuex';

import { loadContainerParameters } from '@/modules/admin/store/action-types';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';

import DeliveryPointStatusChart from './DeliveryPointDetails/DeliveryPointStatusChart.vue';

export default {
	name: 'DeliveryPointHistory',
	components: {
		DeliveryPointStatusChart,
		ElPagination,
		ElTable,
		ElTableColumn,
		DatePicker,
		ElTooltip,
	},
	data() {
		return {
			loading: false,
			page: {
				currentPage: null,
			},
			clickedParamId: null,
			clickedParamName: null,
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			filter: {
				from: null,
				to: null,
			},
		};
	},

	computed: {
		...mapState(appName, ['containerValues']),
		parametersHistory() {
			return this.page.entities;
		},
		parameterTypes() {
			let types = [];
			if (this.page.entities) {
				types = this.page.entities.reduce((resultObject, item) => {
					Object.keys(item.values).forEach((key) => {
						resultObject[key] = this.containerValues[key];
					});
					return resultObject;
				}, {});
			}
			return types;
		},
	},

	async created() {
		if (this.$route.params.id) {
			await this[loadContainerParameters]();
			await this.reloadValuesHistory(1);
		}
	},

	methods: {
		...mapActions(appName, [loadContainerParameters]),
		async reloadValuesHistory(pageNumber) {
			this.loading = true;
			this.page = await this.$service.deliveryPoint.getValuesHistory(this.$route.params.id, this.filter, pageNumber, this.pageSize);
			this.loading = false;
		},
		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			this.reloadValuesHistory(1);
		},
		headerClicked(col) {
			this.clickedParamName = col.label !== 'Datum' ? col.label : null;
			this.clickedParamId = col.index;
		},
	},
};
</script>

<style>
.headerCell {
	cursor: pointer;
}
</style>
