import axios from 'axios';

export default {
	async validateAddress(city, zip, street) {
		const params = {
			q: `${city} ${zip}, ${street}`,
			format: 'jsonv2',
		};

		try {
			const response = await axios.get('https://nominatim.openstreetmap.org/search', { params });

			return Array.isArray(response?.data) && response?.data?.length;
		} catch {
			return false;
		}
	},
};
