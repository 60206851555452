<template>
	<div>
		<h2 slot="header" class="card-title">
			<div class="row mt-3">
				<div class="col-6">
					{{ editing ? editingLabel : newLabel }}
				</div>
				<div class="col-6">
					{{ $t('admin.gpsPreview') }}
				</div>
			</div>
		</h2>

		<div class="row">
			<div class="col-6">
				<form>
					<div class="textbox-with-button">
						<form-group-input
							v-model.number="deliveryPoint.serialNumber"
							v-validate="serialNumberValidation"
							type="number"
							:disabled="editing"
							:placeholder="$t('admin.insertSerialNumber')"
							:label="$t('admin.serialNumber')"
							:name="validations.deliveryPointSerialNumber.name"
							:error="getError(validations.deliveryPointSerialNumber.name)"
							:data-vv-as="$t('admin.serialNumber')"
							required />
						<el-button
							v-if="deliveryPoint.type === DeliveryPointType.SmartKeyBox.name && !editing"
							:class="embeddedButtonClass"
							type="primary"
							@click="preselectBoxes(deliveryPoint.serialNumber)">
							{{ $t('admin.preselectBoxes') }}
						</el-button>
					</div>
					<form-group-input
						v-model="deliveryPoint.physicalKey"
						:placeholder="$t('admin.physicalKeyPlaceholder')"
						:label="$t('admin.physicalKey')"
						:data-vv-as="serialNumber" />
					<p-checkbox v-model="deliveryPoint.isDisabled">
						{{ $t('admin.disabled') }}
					</p-checkbox>
					<div class="textbox-with-button">
						<form-group-input
							v-model="coordinates"
							v-validate="validations.deliveryPointCoordinates.rules"
							:error="getError(validations.deliveryPointCoordinates.name)"
							:name="validations.deliveryPointCoordinates.name"
							:data-vv-as="$t('admin.copyCoords')"
							:placeholder="$t('admin.separateCoordsWithComma')"
							:label="$t('admin.copyCoords')" />
						<el-button
							class="pull-right"
							:class="embeddedButtonClass"
							type="primary"
							@click="loadAddressFromGps">
							{{ $t('admin.loadAddress') }}
						</el-button>
					</div>
					<div class="row">
						<div class="col-6">
							<label>{{ $t('admin.latitude') }}</label>
							<span class="text-danger"> *</span>
							<div class="d-flex">
								<form-group-input
									v-model.number="latitude.degree"
									v-validate="validations.deliveryPointLatitude.degree.rules"
									type="number"
									:error="getError(validations.deliveryPointLatitude.degree.name)"
									:name="validations.deliveryPointLatitude.degree.name"
									:data-vv-as="$t('admin.degrees')"
									class="w-25"
									:disabled="!!coordinates"
									required />
								<span class="d-block mx-2">°</span>
								<form-group-input
									v-model.number="latitude.minutes"
									v-validate="validations.deliveryPointLatitude.minutes.rules"
									type="number"
									:error="getError(validations.deliveryPointLatitude.minutes.name)"
									:name="validations.deliveryPointLatitude.minutes.name"
									:data-vv-as="$t('admin.minutes')"
									class="w-25"
									:disabled="!!coordinates"
									required />
								<span class="d-block mx-2">'</span>
								<form-group-input
									v-model="latitude.seconds"
									v-validate="validations.deliveryPointLatitude.seconds.rules"
									:error="getError(validations.deliveryPointLatitude.seconds.name)"
									:name="validations.deliveryPointLatitude.seconds.name"
									:data-vv-as="$t('admin.seconds')"
									class="w-25"
									:disabled="!!coordinates"
									required />
								<span class="d-block mx-2">"</span>
							</div>
						</div>
						<div class="col-6">
							<label>{{ $t('admin.longitude') }}</label>
							<span class="text-danger"> *</span>
							<div class="d-flex">
								<form-group-input
									v-model.number="longitude.degree"
									v-validate="validations.deliveryPointLongitude.degree.rules"
									type="number"
									:error="getError(validations.deliveryPointLongitude.degree.name)"
									:name="validations.deliveryPointLongitude.degree.name"
									:data-vv-as="$t('admin.degrees')"
									class="w-25"
									:disabled="!!coordinates"
									required />
								<span class="d-block mx-2">°</span>
								<form-group-input
									v-model.number="longitude.minutes"
									v-validate="validations.deliveryPointLongitude.minutes.rules"
									type="number"
									:error="getError(validations.deliveryPointLongitude.minutes.name)"
									:name="validations.deliveryPointLongitude.minutes.name"
									:data-vv-as="$t('admin.minutes')"
									class="w-25"
									:disabled="!!coordinates"
									required />
								<span class="d-block mx-2">'</span>
								<form-group-input
									v-model="longitude.seconds"
									v-validate="validations.deliveryPointLongitude.seconds.rules"
									:error="getError(validations.deliveryPointLongitude.seconds.name)"
									:name="validations.deliveryPointLongitude.seconds.name"
									:data-vv-as="$t('admin.seconds')"
									class="w-25"
									:disabled="!!coordinates"
									required />
								<span class="d-block mx-2">"</span>
							</div>
						</div>
					</div>
					<autocomplete
						v-model="deliveryPoint.state"
						v-validate="validations.deliveryPointState.rules"
						:placeholder="$t('admin.insertState')"
						:name="validations.deliveryPointState.name"
						:error="getError(validations.deliveryPointState.name)"
						:data-vv-as="$t('admin.country')"
						:label="$t('admin.country')"
						trigger-on-focus
						search-field-name="States"
						required
						@fetch-suggestions="querySearchAsync" />
					<autocomplete
						v-model="deliveryPoint.region"
						v-validate="validations.deliveryPointRegion.rules"
						:name="validations.deliveryPointRegion.name"
						:error="getError(validations.deliveryPointRegion.name)"
						:data-vv-as="$t('admin.region')"
						:label="$t('admin.region')"
						:placeholder="$t('admin.insertRegion')"
						trigger-on-focus
						search-field-name="Regions"
						required
						@fetch-suggestions="querySearchAsync" />
					<autocomplete
						v-model="deliveryPoint.county"
						v-validate="validations.deliveryPointCounty.rules"
						:name="validations.deliveryPointCounty.name"
						:error="getError(validations.deliveryPointCounty.name)"
						:data-vv-as="$t('admin.county')"
						:label="$t('admin.county')"
						:placeholder="$t('admin.insertCounty')"
						trigger-on-focus
						search-field-name="Counties"
						required
						@fetch-suggestions="querySearchAsync" />
					<autocomplete
						v-model="deliveryPoint.city"
						v-validate="validations.deliveryPointCity.rules"
						:placeholder="$t('admin.insertCity')"
						:label="$t('admin.city')"
						:name="validations.deliveryPointCity.name"
						:error="getError(validations.deliveryPointCity.name)"
						:data-vv-as="$t('admin.city')"
						trigger-on-focus
						search-field-name="Cities"
						required
						@fetch-suggestions="querySearchAsync" />
					<autocomplete
						v-model="deliveryPoint.street"
						v-validate="validations.deliveryPointStreet.rules"
						:placeholder="$t('admin.insertStreet')"
						:name="validations.deliveryPointStreet.name"
						:error="getError(validations.deliveryPointStreet.name)"
						:data-vv-as="$t('admin.street')"
						:label="$t('admin.street')"
						trigger-on-focus
						search-field-name="Streets"
						required
						@fetch-suggestions="querySearchAsync" />
					<div class="row">
						<div class="col-5">
							<form-group-input
								v-model="deliveryPoint.postCode"
								v-validate="validations.deliveryPointPostCode.rules"
								:placeholder="$t('admin.insertPostCode')"
								type="number"
								:label="$t('admin.postCode')"
								:name="validations.deliveryPointPostCode.name"
								:error="getError(validations.deliveryPointPostCode.name)"
								:data-vv-as="$t('admin.postCode')"
								required />
						</div>
						<div class="col-7">
							<el-button type="primary pull-right in-form-button-margin" @click="loadGpsAndPostCode">
								{{ $t('admin.loadZipAndGPS') }}
							</el-button>
						</div>
					</div>
					<form-group-input
						v-model="deliveryPoint.location"
						v-validate="validations.deliveryPointLocation.rules"
						:placeholder="$t('admin.insertLocation')"
						:label="$t('admin.location')"
						:name="validations.deliveryPointLocation.name"
						:error="getError(validations.deliveryPointLocation.name)"
						:data-vv-as="$t('admin.location')"
						required />
					<form-group-input
						v-if="!isPickupPlaceOrSmartBox(deliveryPoint.type)"
						v-model.number="deliveryPoint.statusPeriod"
						v-validate="validations.statusPeriod.rules"
						type="number"
						:placeholder="$t('admin.insertStatusPeriod')"
						:label="$t('admin.statusPeriod')"
						:name="validations.statusPeriod.name"
						:error="getError(validations.statusPeriod.name)"
						:data-vv-as="$t('admin.statusPeriod')"
						:required="!(deliveryPoint.type === DeliveryPointType.Store.name)" />
					<form-group-input
						v-if="!isPickupPlaceOrSmartBox(deliveryPoint.type)"
						v-model.number="deliveryPoint.periodsUntilOffline"
						v-validate="validations.periodsUntilOffline.rules"
						type="number"
						:placeholder="$t('admin.insertPeriodsUntilOffline')"
						:label="$t('admin.periodsUntilOffline')"
						:name="validations.periodsUntilOffline.name"
						:error="getError(validations.periodsUntilOffline.name)"
						:data-vv-as="$t('admin.periodsUntilOffline')"
						:required="!(deliveryPoint.type === DeliveryPointType.Store.name)" />
					<form-group-input
						v-if="deliveryPoint.type === DeliveryPointType.Store.name"
						v-model="stockCapacity"
						v-validate="validations.deliveryPointStockCapacity.rules"
						:placeholder="$t('admin.insertStockCapacity')"
						type="number"
						:label="$t('admin.stockCapacity')"
						:name="validations.deliveryPointStockCapacity.name"
						:error="getError(validations.deliveryPointStockCapacity.name)"
						:data-vv-as="$t('admin.stockCapacity')"
						:required="deliveryPoint.type === DeliveryPointType.Store.name" />
					<form-group-input
						v-if="deliveryPoint.type !== DeliveryPointType.Store.name && editing"
						:label="$t('admin.placementDate')"
						required>
						<date-picker
							v-model="deliveryPoint.placementDate"
							v-validate="validations.deliveryPointPlacementDate.rules"
							:placeholder="$t('admin.insertPlacementDate')"
							type="datetime"
							:name="validations.deliveryPointPlacementDate.name"
							:error="getError(validations.deliveryPointPlacementDate.name)"
							:data-vv-as="$t('admin.placementDate')"
							prefix-icon="el-icon-date"
							format="dd.MM.yyyy HH:mm" />
					</form-group-input>

					<div class="d-flex justify-content-center">
						<p-checkbox v-model="deliveryPoint.isLocked">
							{{ $t('admin.locked') }}
						</p-checkbox>
						<p-checkbox v-model="deliveryPoint.public" class="ml-3">
							{{ $t('admin.isPublic') }}
						</p-checkbox>
						<p-checkbox v-if="deliveryPoint.type !== DeliveryPointType.SmartKeyBox.name" v-model="deliveryPoint.hasPaymentTerminal" class="ml-3">
							{{ $t('admin.terminal') }}
						</p-checkbox>
					</div>
					<div v-if="deliveryPoint.type === DeliveryPointType.SmartKeyBox.name && !editing">
						<el-row>
							<h2>{{ $t('admin.addBoxes') }}</h2>
						</el-row>
						<el-row>
							<el-col :span="6">
								<label>
									{{ $t('admin.boxType') }}
								</label>
							</el-col>
							<el-col :span="6">
								<label>
									{{ $t('admin.lock') }}
								</label>
							</el-col>
						</el-row>
						<el-row
							v-for="(b, index) in boxesToAdd"
							:key="b.serialNumber"
							class="box-row"
							:gutter="12">
							<el-col :span="6">
								<el-select
									v-model="boxesToAdd[index].boxType"
									class="w-100"
									:data-vv-as="$t('admin.boxType')"
									:name="validations.boxType.name"
									clearable>
									<el-option
										v-for="box in BoxType.enumValues"
										:key="box.ordinal"
										:value="box.name"
										:label="box.czech()">
										{{ box.czech() }}
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="6" class="lock-list-label">
								<span v-if="boxesToAdd[index].boxType !== ClothesAndAccesories">
									{{ getLockLabel(b.serialNumber) }}
								</span>
							</el-col>
							<el-col :span="6">
								<el-button
									class="remove-lock-icon"
									type="danger"
									size="small"
									circle
									@click="removeBox(b.serialNumber)">
									<i class="fa fa-minus" />
								</el-button>
							</el-col>
						</el-row>
						<el-row :gutter="12">
							<el-col :span="6">
								<el-select
									v-model="boxToAdd.boxType"
									class="w-100"
									:data-vv-as="$t('admin.boxType')"
									:name="validations.boxType.name"
									clearable>
									<el-option
										v-for="box in BoxType.enumValues"
										:key="box.ordinal"
										:value="box.name"
										:disabled="isClothesBoxSelectionDisabled(box)"
										:label="box.czech()">
										{{ box.czech() }}
									</el-option>
								</el-select>
							</el-col>
							<el-col :span="6">
								<el-select
									v-model.number="boxToAdd.serialNumber"
									:disabled="boxToAdd.boxType === ClothesAndAccesories"
									:data-vv-as="$t('admin.lock')"
									:placeholder="$t('admin.insertLock')"
									:name="validations.serialNumber.name"
									class="w-100"
									:error="getError(validations.serialNumber.name)"
									clearable
									filterable
									value-key="ordinal">
									<el-option
										v-for="lock in availableSmartLocks"
										:key="lock.lockId"
										:label="lock.lockName + ' / ' + lock.lockAlias"
										:value="lock.lockId" />
								</el-select>
							</el-col>
							<el-col :span="6">
								<el-button @click="addBox">
									{{ $t('admin.add') }}
								</el-button>
							</el-col>
						</el-row>
					</div>
					<confirm-button
						:callback="addressValidate"
						:confirm-message="confirmMessage"
						:show-success-message="false"
						:disabled="editing && (!deliveryPoint.placementDate && deliveryPoint.type !== DeliveryPointType.Store.name)"
						class="pull-right">
						{{ $t('admin.save') }}
					</confirm-button>
					<div class="clearfix" />
				</form>
			</div>
			<div class="col-6 map-column">
				<div class="map-wrapper">
					<mapy-c-z-static-map :center="mapCenter" />
				</div>
			</div>
		</div>

		<base-delivery-point-confirm-dialog :show="confirmDialogShow" @close="closeConfirmDialog" />
	</div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import { mapActions, mapState } from 'vuex';
import { Select as ElSelect, Option as ElOption, Row as ElRow, Col as ElCol, Button as ElButton, DatePicker } from 'element-ui';

import { loadContainerPriceMaps } from '@/modules/admin/store/action-types';
import ValidationMixin from '@/util/validation-mixin';
import { convertCoordinatesToReal, convertCoordinatesToRad } from '@/util/container-mixin';
import Validations from '@/util/validations';
import { czechDecimalParser } from '@/util/helpers';
import { DeliveryPointType } from '@/modules/admin/store/enums';
import { appName } from '@/modules/admin/config';
import { formChanged } from '@/store/mutation-types';
import { BaseDeliveryPointConfirmDialog } from '@/components/Base';
import { BoxType } from '@/modules/eshop/store/enums';
import notifyService from '@/service/notify-service';
import { ClothesAndAccesories } from '@/modules/eshop/store/constants';

import MapyCZStaticMap from './MapyCZStaticMap/MapyCZStaticMap.vue';

export default {
	name: 'DeliveryPointDescriptionCard',

	components: {
		MapyCZStaticMap,
		BaseDeliveryPointConfirmDialog,
		DatePicker,
		ElSelect,
		ElOption,
		ElRow,
		ElCol,
		ElButton,
	},
	mixins: [ValidationMixin],
	props: {
		deliveryPoint: {
			type: Object,
			default: () => ({}),
		},
		editing: { type: Boolean, default: false },
	},
	data() {
		return {
			DeliveryPointType,
			BoxType,
			smartLocks: [],
			boxesToAdd: [],
			boxToAdd: { boxType: null, serialNumber: null },
			ClothesAndAccesories,
			coordinates: null,
			latitude: {
				degree: null,
				minutes: null,
				seconds: null,
			},
			longitude: {
				degree: null,
				minutes: null,
				seconds: null,
			},
			stockCapacity: null,
			validations: {
				boxType: {
					name: 'boxType',
					rules: Validations.required,
				},
				serialNumber: {
					name: 'serialNumber',
					rules: Validations.requiredSerialNumber,
				},
				deliveryPointSerialNumber: {
					name: 'deliveryPointSerialNumber',
				},
				deliveryPointState: {
					name: 'deliveryPointState',
					rules: Validations.requiredTextOnlyField,
				},
				deliveryPointRegion: {
					name: 'deliveryPointRegion',
					rules: Validations.requiredTextOnlyField,
				},
				deliveryPointCounty: {
					name: 'deliveryPointCounty',
					rules: Validations.requiredTextSpaceHyphenField,
				},
				deliveryPointCity: {
					name: 'deliveryPointCity',
					rules: {
						...Validations.requiredTextNumSpaceHyphenField,
					},
				},
				deliveryPointStreet: {
					name: 'deliveryPointStreet',
					rules: {
						...Validations.requiredField,
					},
				},
				statusPeriod: {
					name: 'statusPeriod',
					rules: { ...Validations.requiredField, ...Validations.integer },
				},
				periodsUntilOffline: {
					name: 'periodsUntilOffline',
					rules: { ...Validations.requiredField, ...Validations.integer },
				},
				deliveryPointLocation: {
					name: 'deliveryPointLocation',
					rules: Validations.requiredField,
				},
				deliveryPointPostCode: {
					name: 'deliveryPointPostCode',
					rules: {
						...Validations.requiredField,
					},
				},
				deliveryPointStockCapacity: {
					name: 'deliveryPointStockCapacity',
					rules: { ...Validations.requiredField, ...Validations.integer, between: { min: 1, max: 1000 } },
				},
				deliveryPointPlacementDate: {
					name: 'deliveryPointPlacementDate',
					rules: Validations.requiredField,
				},
				deliveryPointPriceMap: {
					name: 'deliveryPointPriceMap',
					rules: Validations.requiredField,
				},
				deliveryPointLatitude: {
					degree: {
						name: 'latitudeDegree',
						rules: Validations.requiredLatitude,
					},
					minutes: {
						name: 'latitudeMinutes',
						rules: Validations.requiredGpsMinutes,
					},
					seconds: {
						name: 'latitudeSeconds',
						rules: Validations.requiredGpsSeconds,
					},
				},
				deliveryPointLongitude: {
					degree: {
						name: 'longitudeDegree',
						rules: Validations.requiredLongitude,
					},
					minutes: {
						name: 'longitudeMinutes',
						rules: Validations.requiredGpsMinutes,
					},
					seconds: {
						name: 'longitudeSeconds',
						rules: Validations.requiredGpsSeconds,
					},
				},
				deliveryPointCoordinates: {
					name: 'deliveryPointCoordinates',
					rules: { ...Validations.coordinates, validateGps: null },
				},
			},
			confirmDialogShow: false,
			abortController: null,
		};
	},
	computed: {
		mapCenter() {
			const latitude = convertCoordinatesToReal(this.latitude.degree, this.latitude.minutes, this.latitude.seconds);
			const longitude = convertCoordinatesToReal(this.longitude.degree, this.longitude.minutes, this.longitude.seconds);
			// eslint-disable-next-line max-len
			return {
				latitude,
				longitude,
			};
		},
		...mapState(appName, ['priceMaps']),
		availableSmartLocks() {
			const boxesToAddIds = this.boxesToAdd.map((b) => b.serialNumber);
			return this.smartLocks.filter((smartLock) => boxesToAddIds.indexOf(smartLock.lockId) === -1);
		},
		confirmMessage() {
			if (this.editing) {
				if (this.deliveryPoint.type === DeliveryPointType.PenguinBox.name) {
					return this.$t('admin.penguinBoxUpdate');
				}
				if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
					return this.$t('admin.pickupPlaceUpdate');
				}
				return this.$t('admin.smartBoxUpdate');
			}
			if (this.deliveryPoint.type === DeliveryPointType.PenguinBox.name) {
				return this.$t('admin.containerCreate');
			}
			if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
				return this.$t('admin.pickupPlaceCreate');
			}
			return this.$t('admin.smartBoxCreate');
		},
		editingLabel() {
			if (this.deliveryPoint.type === DeliveryPointType.PenguinBox.name) {
				return this.$t('admin.editingPenguinBox');
			}
			if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
				return this.$t('admin.editingPickupPlace');
			}
			return this.$t('admin.editingSmartBox');
		},
		newLabel() {
			if (this.deliveryPoint.type === DeliveryPointType.PenguinBox.name) {
				return this.$t('admin.newContainer');
			}
			if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
				return this.$t('admin.newPickupPlace');
			}
			return this.$t('admin.newSmartBox');
		},
		serialNumberValidation() {
			if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
				return { ...Validations.requiredNumber, between: { min: 1000000, max: 9999999 } };
			}
			return { ...Validations.requiredNumber, between: { min: 1, max: 9999999 } };
		},
		embeddedButtonClass() {
			// ensure correct alignment with textbox in both new and edit dialogs
			return this.editing ? 'embedded-button-edit' : 'embedded-button-new';
		},
	},
	watch: {
		deliveryPoint: {
			handler() {
				this.$store.commit(`${appName}/${formChanged}`, true);
			},
			deep: true,
		},
	},
	async mounted() {
		this.confirmDialogShow = false;
		await this.getAllSmartLocks();
	},
	async created() {
		const { type, latitude, longitude, capacity } = this.deliveryPoint;

		if (!this.editing) {
			await this.initiateSerialNumber();
		}
		if (latitude) {
			this.latitude = convertCoordinatesToRad(latitude);
		}
		if (longitude) {
			this.longitude = convertCoordinatesToRad(longitude);
		}
		if (type === DeliveryPointType.Store.name) {
			this.stockCapacity = this.editing ? capacity : 100;
		}

		await this.loadContainerPriceMaps();

		this.ClothesAndAccesories = ClothesAndAccesories;
	},
	methods: {
		addBox() {
			if (this.boxToAdd) {
				if (this.boxToAdd.boxType === ClothesAndAccesories) {
					this.boxToAdd.serialNumber = -1;
				}
				if (this.boxToAdd.boxType && (this.boxToAdd.serialNumber || this.boxToAdd.boxType === ClothesAndAccesories)) {
					this.boxesToAdd.push({
						boxType: this.boxToAdd.boxType,
						serialNumber: this.boxToAdd.serialNumber,
					});
					this.boxToAdd = { boxType: null, serialNumber: null };
				}
			}
		},
		removeBox(serialNumber) {
			this.boxesToAdd = this.boxesToAdd.filter((b) => b.serialNumber !== serialNumber);
		},
		preselectBoxes(serialNumber) {
			if (serialNumber) {
				const locksToAdd = this.smartLocks.filter((lock) => lock.lockName.indexOf(serialNumber) !== -1 || lock.lockAlias.indexOf(serialNumber) !== -1);
				if (locksToAdd && locksToAdd.length > 0) {
					locksToAdd.forEach((lock) => {
						if (!this.boxesToAdd.some((b) => b.serialNumber === lock.lockId)) {
							this.boxesToAdd.push({
								boxType: 'Large',
								serialNumber: lock.lockId,
							});
						}
					});
					notifyService.notifySuccess(this.$t('admin.locksPreselected'));
				} else {
					notifyService.notifyError(this.$t('admin.noLocksToPreselectFound'));
				}
			} else {
				notifyService.notifyError(this.$t('admin.noLocksToPreselectFound'));
			}
		},
		getLockLabel(serialNumber) {
			const lock = this.smartLocks.find((l) => l.lockId === serialNumber);
			if (lock) {
				return `${lock.lockName} / ${lock.lockAlias}`;
			}
			return '';
		},
		async getAllSmartLocks() {
			this.smartLocks = await this.$service.smartLockInfo.getAllAvailableLocks() || [];
		},
		...mapActions(appName, [loadContainerPriceMaps]),
		isPickupPlaceOrSmartBox(deliveryPointType) {
			return [DeliveryPointType.Store.name, DeliveryPointType.SmartKeyBox.name].includes(deliveryPointType);
		},
		async initiateSerialNumber() {
			this.deliveryPoint.serialNumber = await this.$service.deliveryPoint.getFreeSerialNumber(this.deliveryPoint.type);
		},
		parseCoordinates(coordinates, type) {
			const splitCoords = coordinates.split(/[°'"]/).map((el) => parseFloat(el.trim()));
			this[type].degree = splitCoords[0];
			this[type].minutes = splitCoords[1];
			this[type].seconds = splitCoords[2];
		},
		async closeConfirmDialog(returnValue) {
			this.confirmDialogShow = false;

			if (returnValue) {
				await this.validateAndSave('admin');
			}
		},
		async addressValidate() {
			let subUrbError = false;
			if (this.deliveryPoint?.county?.toLowerCase().indexOf('praha') !== -1) {
				const { state, county, street, city } = this.deliveryPoint;
				const result = await this.$service.log.validateAddressData(state, county, city, street);
				const data = result?.data.filter((node) => node.address.state === 'Praha');
				if (data?.length === 1) {
					this.deliveryPoint.city = `Praha - ${data[0].address.suburb}`;
				} else if (data?.length > 1) {
					const subUrbs = data.map((res) => res.address.suburb);
					const subUrbsSet = [...new Set(subUrbs)];
					if (subUrbsSet.length > 1) {
						notifyService
							.notifyError(`${this.$t('admin.multiplePragueParts')}(${subUrbsSet.join(', ')})`);
						subUrbError = true;
					} else if (subUrbsSet.length === 1) {
						this.deliveryPoint.city = `Praha - ${subUrbsSet[0]}`;
					} else {
						notifyService.notifyError(this.$t('admin.praguePartsCollision'));
					}
				}
			}
			const { valid } = await this.$validator.verify(this.validations.deliveryPointCity.name, { validateAddress: this.deliveryPoint });
			if (!valid || subUrbError) {
				if (subUrbError) {
					setTimeout(() => {
						this.confirmDialogShow = true;
					}, 2000);
				} else {
					this.confirmDialogShow = true;
				}
			} else {
				await this.validateAndSave('admin');
			}
		},
		async save() {
			if (this.latitude.degree >= 0 && this.latitude.minutes >= 0 && this.latitude.seconds >= 0) {
				this.latitude.seconds = czechDecimalParser(this.latitude.seconds);
				this.deliveryPoint.latitude = convertCoordinatesToReal(this.latitude.degree, this.latitude.minutes, this.latitude.seconds);
			}
			if (this.longitude.degree >= 0 && this.longitude.minutes >= 0 && this.longitude.seconds >= 0) {
				this.longitude.seconds = czechDecimalParser(this.longitude.seconds);
				this.deliveryPoint.longitude = convertCoordinatesToReal(this.longitude.degree, this.longitude.minutes, this.longitude.seconds);
			}
			if (this.editing) {
				const deliveryPointToUpdate = { ...this.deliveryPoint };
				delete deliveryPointToUpdate.boxes;
				delete deliveryPointToUpdate.drivers;
				delete deliveryPointToUpdate.lastParameters;
				await this.$service.deliveryPoint.update(deliveryPointToUpdate);
				if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
					await this.$service.deliveryPoint.setStockCapacity(this.deliveryPoint.containerId, this.stockCapacity);
				}
				this.$store.commit(`${appName}/${formChanged}`, false);
				this.$emit('show-hide-edit-dialog');
			} else {
				this.deliveryPoint.boxes = this.boxesToAdd;
				this.deliveryPoint.containerId = (await this.$service.deliveryPoint.add(this.deliveryPoint)).containerId;
				if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
					await this.$service.deliveryPoint.setStockCapacity(this.deliveryPoint.containerId, this.stockCapacity);
				}
				this.$store.commit(`${appName}/${formChanged}`, false);
				this.$router.push({ name: 'container-list' });
			}
		},
		querySearchAsync(input, cb, searchFieldName) {
			this.abortController?.abort();
			this.abortController = new AbortController();
			this.$service.deliveryPoint.searchByLocationInput(input, searchFieldName, this.deliveryPoint, this.abortController)
				.then((results) => {
					cb(results);
				});
		},
		async loadGpsAndPostCode() {
			const location = await this.$service.deliveryPoint.validateAddress(`${this.deliveryPoint.street}, ${this.deliveryPoint.city}`);

			this.mapNewAddress(location.address);

			this.latitude = convertCoordinatesToRad(location.position.lat);
			this.longitude = convertCoordinatesToRad(location.position.lng);
		},
		async loadAddressFromGps() {
			if (!this.coordinates) {
				notifyService.notifyError(this.$t('admin.noCoordinatesInserted'));
				return;
			}
			if (this.coordinates.length > 21) {
				const coordsArray = this.coordinates.split(',');
				coordsArray.forEach((coordinates) => {
					if (coordinates.includes('N')) {
						this.parseCoordinates(coordinates, 'latitude');
					} else if (coordinates.includes('E')) {
						this.parseCoordinates(coordinates, 'longitude');
					}
				});
			}

			const latitude = convertCoordinatesToReal(this.latitude.degree, this.latitude.minutes, this.latitude.seconds);
			const longitude = convertCoordinatesToReal(this.longitude.degree, this.longitude.minutes, this.longitude.seconds);
			const location = await this.$service.deliveryPoint.checkAddress(latitude, longitude);

			this.mapNewAddress(location.address);
			this.coordinates = null;
		},
		mapNewAddress(address) {
			this.deliveryPoint.city = address.city;
			this.deliveryPoint.street = address.houseNumber ? `${address.street} ${address.houseNumber}` : address.street;
			this.deliveryPoint.county = address.county;
			this.deliveryPoint.region = address.state;
			this.deliveryPoint.state = address.countryName;
			this.deliveryPoint.postCode = address.postalCode;
		},
		isClothesBoxSelectionDisabled(boxType) {
			const hasClothesAndAccessories = this.boxesToAdd.some((b) => b.boxType === ClothesAndAccesories);
			return boxType.name === ClothesAndAccesories && hasClothesAndAccessories;
		},
	},
};
</script>

<style lang="scss" scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.card-title {
	color: #2c2c2c;
}

.box-row {
	margin-top: 6px;
	margin-bottom: 12px;
}

.textbox-with-button {
	position: relative;
	& .embedded-button-new {
		position: absolute;
		top: 25.25px;
		right: 0;
		height: 37.75px;
		line-height: 13px;
	}
	& .embedded-button-edit {
		position: absolute;
		top: 29px;
		right: 0;
		height: 38px;
		line-height: 13px;
	}
}

.lock-list-label {
	margin-top: 9px;
}

.remove-lock-icon {
    background-color: #ef8157;
    color: #ffffff;
}

.map-wrapper{
	display: block;
	height: 600px;
	border-radius: 4px;
	margin-top: 25px;
	margin-right: 13px;
	background-color: rgb(235, 235, 235);
}

iframe {
	border: none;
    border-radius: 2px;
	box-shadow: 0px 0px 15px -4px darkgrey;
}

.in-form-button-margin {
	margin-top: 25px;
	height: 38px;
}

</style>
