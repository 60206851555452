<template>
	<el-dialog
		:append-to-body="true"
		:close-on-click-modal="false"
		:show-close="false"
		:visible.sync="show"
		custom-class="fit-to-content">
		<div slot="title" class="text-center pt-3">
			<div class="swal2-icon swal2-warning swal2-icon-show">
				!
			</div>

			<h2 class="mb-2 modal-text">
				{{ theSpecialModal.title }}
			</h2>
		</div>

		<p class="m-0 text-center">
			{{ theSpecialModal.text }}
		</p>

		<div v-if="theSpecialModal.showInput" class="m-0 text-center">
			<p class="input-description">
				{{ theSpecialModal.inputDescription }}
			</p>
			<el-input v-model="inputText" :required="theSpecialModal.requireInput" :placeholder="theSpecialModal.inputDescription" />
		</div>

		<div slot="footer" class="d-flex justify-content-center py-2">
			<p-button
				data-cy="yesButton"
				size="sm"
				type="success"
				:disabled="theSpecialModal.requireInput && !inputText"
				@click.stop="confirmRedirect">
				{{ theSpecialModal.confirmButtonText }}
			</p-button>
			<p-button
				v-if="theSpecialModal.cancelButtonText"
				size="sm"
				type="danger"
				@click.stop="cancelRedirect">
				{{ theSpecialModal.cancelButtonText }}
			</p-button>
		</div>
	</el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { Dialog as ElDialog, Input as ElInput } from 'element-ui';

export default {
	name: 'TheSpecialModal',
	components: {
		ElDialog,
		ElInput,
	},
	data() {
		return {
			inputText: null,
		};
	},
	computed: {
		...mapState(['theSpecialModal']),
		show: {
			get() { return this.$store.state.theSpecialModal.show; },
			set(value) { this.$store.state.theSpecialModal.show = value; },
		},
	},
	watch: {
		show(val) {
			const documentClasses = document.body.classList;
			if (val) {
				documentClasses.add('modal-open');
			} else {
				documentClasses.remove('modal-open');
			}
		},
	},
	methods: {
		closeModal() {
			this.$store.state.theSpecialModal.show = false;
			this.inputText = null;
		},
		confirmRedirect() {
			this.$root.$emit('the-special-modal-close', true, this.theSpecialModal.actionId);
			this.$store.state.theSpecialModal.returnValue = true;
			if (this.theSpecialModal.showInput) {
				this.$store.commit('setTheSpecialModalInputText', this.inputText);
			}
			this.closeModal();
		},
		cancelRedirect() {
			this.$root.$emit('the-special-modal-close', false, this.theSpecialModal.actionId);
			this.$store.state.theSpecialModal.returnValue = false;
			this.closeModal();
		},
	},
};
</script>

<style lang="scss" scoped>
.input-description {
	font-size: 125%;
}
.modal-text {
	font-size: 180%;
	padding: 0 45px;
}
</style>
