export const SmartBoxService = (http) => ({
	async getList({ date, clientId, accessToken }, page = 1, pageSize = 10) {
		const response = await http.get('/api/SmartBox', {
			params: {
				PageNo: page,
				PageSize: pageSize,
				Date: date,
				ClientId: clientId,
				AccessToken: accessToken,
			},
		});

		return response.data;
	},
	async getDetail(boxSn) {
		try {
			const response = await http.get('/api/SmartBox/detail', {
				params: { boxSn },
				headers: { 'api-version': '0.2' },
			});
			return response.data;
		} catch (e) {
			return null;
		}
	},
});
