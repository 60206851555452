import { omit, pick } from 'lodash';

import { convertKeysFirstLetterToUppercase } from '@/util/helpers';

export const DeliveryPointService = (http) => ({
	async GetAllowedStores(eshopId) {
		const response = await http.get('/api/Container/AllowedToBeMainStore', { params: { eshopId } });
		return response.data;
	},
	async requireStatus(id) {
		await http.post('/api/Container/RequireStatus', { containerId: id, note: 'dummy note' });
	},
	async restartContainer(id, full, note) {
		await http.post('/api/Container/Restart', {
			containerId: id,
			fullRestart: full,
			note: note || 'No note given',
		});
	},
	async getList(filter, page = 1, pageSize = 10) {
		const params = {
			Page: page,
			PageSize: pageSize,
			...filter,
		};

		delete params.serialNumber;
		delete params.type;
		const { serialNumber, type } = filter;
		let types = [];
		if (type || type === 0) {
			types = Array.isArray(filter.type) ? filter.type : [filter.type];
		}

		if (serialNumber?.from) {
			params['SerialNumber.From'] = serialNumber.from;
		}

		if (serialNumber?.to) {
			params['SerialNumber.To'] = serialNumber.to;
		}

		let url = '/api/Container/List';
		types?.forEach((t, index) => {
			const letter = index === 0 ? '?' : '&';
			url += `${letter}type[]=${t}`;
		});

		const response = await http.get(url, {
			params: convertKeysFirstLetterToUppercase(params),
		});

		return response.data;
	},

	async searchByLocationInput(input, searchFieldName, filter, abortController) {
		const locations = {
			State: filter.state,
			City: filter.city,
			Region: filter.region,
			County: filter.county,
		};
		let locationParams = null;
		switch (searchFieldName) {
			case 'Regions':
				locationParams = pick(locations, ['State']);
				break;
			case 'Counties':
				locationParams = pick(locations, ['State', 'Region']);
				break;
			case 'Cities':
				locationParams = omit(locations, ['City']);
				break;
			case 'Streets':
				locationParams = locations;
				break;
			default:
				locationParams = null;
		}
		const results = [];
		const response = await http.get(`/api/Lists/Container${searchFieldName}`, {
			signal: abortController?.signal,
			params: {
				Contains: input,
				...locationParams,
			},
		});
		response.data.forEach((el) => {
			results.push({ value: el });
		});
		return results;
	},

	async searchByEshopInput(input) {
		const results = [];
		const response = await http.get('/api/Lists/EshopNames', {
			params: {
				Contains: input,
			},
		});
		response.data.forEach((el) => {
			results.push({ value: el });
		});
		return results;
	},

	async getOne(id, fetchOnlyFullBoxes = false) {
		const response = await http.get('/api/Container/Detail', { params: { ContainerID: id, fetchOnlyFullBoxes } });
		return response.data;
	},

	async getValuesHistory(id, filter, page, pageSize = 50) {
		const response = await http.get('/api/Container/ParametersHistory', {
			params: {
				ContainerID: id,
				Page: page,
				PageSize: pageSize,
				From: filter.from,
				To: filter.to,
			},
		});
		return response.data;
	},

	async add(container) {
		const response = await http.post('/api/Container/Add', container);

		return response.data;
	},

	async update(container) {
		const response = await http.post('/api/Container/Update', container);

		return response.data;
	},

	async updateNote(containerId, note) {
		const response = await http.post('/api/Container/UpdateNote', { containerId, note });
		return response.data;
	},

	async setStockCapacity(containerId, stockCapacity) {
		const response = await http.post('/api/Container/SetStockCapacity', { containerId, requiredCapacity: Number(stockCapacity) });

		return response.data;
	},

	async getGallery(containerId, date, time) {
		const response = await http.get('/api/Container/Images', {
			params: {
				Day: date,
				containerId,
				Hour: time,
			},
		});

		return response.data;
	},

	async getNearestImages(containerId, actionDate) {
		const response = await http.get('/api/Container/NearestImages', {
			params: {
				containerId,
				actionDate,
			},
		});

		return response.data;
	},

	async occupyBox(boxId, eshopId) {
		return http.post('/api/Box/OccupyBox', { boxId, eshopId });
	},

	async setBoxes(boxes, containerId) {
		const response = await http.post('/api/Container/SetBoxes', { boxes, containerId });
		return response.data;
	},

	async  createPickupReservation(boxId) {
		const response = await http.post('/api/Clothes/ReqExplicitPickup', { boxId });
		return response.data;
	},

	async getOpeningHours(containerId, dateFrom, dateTo) {
		const response = await http.get('/api/Container/OpeningHours', {
			params: {
				containerId,
				dateFrom,
				dateTo,
			},
		});
		return response.data;
	},

	async getFreeSerialNumber(containerType) {
		try {
			const response = await http.get('/api/Container/FreeSn', {
				params: { containerType },
			});
			return response.data?.serialNumber ?? 0;
		} catch {
			return 0;
		}
	},

	async updateOpeningHours(containerId, openingHours) {
		const response = await http.post('/api/Container/UpdateOpeningHours', { containerId, openingHours });
		return response.data;
	},
	async getContainerPhotos(containerId) {
		const response = await http.get('/api/Container/GetContainerPhotos', {
			params: {
				containerId,
			},
		});
		return response.data;
	},
	async deleteContainerPhoto(containerId, fileName) {
		const response = await http.delete('/api/Container/DeleteContainerPhoto', {
			params: {
				containerId,
				fileName,
			},
		});
		return response.data;
	},
	async setContainerPhotoAsPrimary(containerId, fileName) {
		const response = await http.post('/api/Container/SetPhotoAsPrimary', {
			containerId,
			fileName,
		});
		return response.data;
	},

	async uploadContainerPhoto(containerId, image) {
		const data = new FormData();
		data.append('image', image, 'image.jpg');
		data.append('containerId', containerId);

		const response = await http.post('/api/Container/SaveContainerPhoto', data);
		return response.data;
	},
	async checkAddress(lat, lng) {
		const response = await http.get('/api/Container/CheckAddress', {
			params: { lat, lng },
		});

		return response.data;
	},
	async validateAddress(address) {
		const response = await http.get('/api/Container/ValidateAddress', {
			params: { address },
		});

		return response.data;
	},
	async changeContainerType(containerId, containerType) {
		const response = await http.post('/api/Container/ChangeContainerType', { containerId, containerType });
		return response.data;
	},
});
