import { appNameList } from '@/globalConstants';

const sidebarLinks = [
	{
		name: 'Report',
		icon: 'nc-icon nc-paper',
		path: '/ridic/report',
	},
	{
		name: 'Balíky',
		icon: 'nc-icon nc-box-2',
		path: '/ridic/vydej',
	},
	{
		name: 'Expirace a vratky',
		icon: 'nc-icon nc-delivery-fast',
		path: '/ridic/expirace-a-vratky',
	},
];

const sidebarUserLinks = [{ name: 'password-change', display: 'Změna hesla' }];

const config = {
	appName: appNameList.driver,
	sidebarLinks,
	sidebarUserLinks,
};

config.install = function (Vue) {
	Vue.prototype.$getConst = (key) => config[key];
};

export const appName = appNameList.driver;

export default config;
