// TODO: https://jira.artin.cz/browse/PENG-1549
/* eslint-disable import/no-cycle */
import VueRouter from 'vue-router';
import Vue from 'vue';

import store from '@/store';
import { getAuthDataStorageName } from '@/store/auth-actions';
import { appBaseUrlList, appNameList, newAppPaths } from '@/globalConstants';
import { refreshToken } from '@/store/action-types';
import { AdminModule } from '@/modules/admin';
import { LogisticsModule } from '@/modules/logistics';
import { PickupModule } from '@/modules/pickup';
import { DriverModule } from '@/modules/driver';
import { EshopModule } from '@/modules/eshop';
import TheNotFoundPage from '@/components/Layouts/TheNotFoundPage.vue';
import ModuleService from '@/service/moduleService';
import http from '@/service/http';

let routes = [];

const RequiredAuthorization = (route) => {
	const anonymousRoutes = ['forgot-password', 'pass-reset', 'mail-confirm', 'login'];
	return !anonymousRoutes.includes(route);
};

const registerModule = (module) => {
	// TODO remove for production
	routes = module.routes;
	routes.push({ path: '*', component: TheNotFoundPage });

	Vue.use(module.config);
	Vue.use(ModuleService, { moduleService: module.service(http) });

	store.commit('addApp', module.config.appName);
	store.state.currentAppName = module.config.appName;
};

const app = async () => {
	const { pathname } = window.location;

	if (pathname.startsWith(appBaseUrlList.admin)) {
		registerModule(AdminModule);
		await store.commit('addApp', 'admin');
		await store.commit('addApp', 'eshop');
		await store.commit('addApp', 'logistics');
	} else if (pathname.startsWith(appBaseUrlList.logistics)) {
		registerModule(LogisticsModule);
		await store.commit('addApp', 'admin');
		await store.commit('addApp', 'eshop');
		await store.commit('addApp', 'logistics');
	} else if (pathname.startsWith(appBaseUrlList.pickup)) {
		registerModule(PickupModule);
	} else if (pathname.startsWith(appBaseUrlList.driver)) {
		registerModule(DriverModule);
	} else {
		registerModule(EshopModule);
		await store.commit('addApp', 'admin');
		await store.commit('addApp', 'eshop');
		await store.commit('addApp', 'logistics');
	}
};

app();

const router = new VueRouter({
	mode: 'history',
	routes,
	linkActiveClass: 'active',
	scrollBehavior: (to) => {
		if (to.hash) {
			return { selector: to.hash };
		}
		return { x: 0, y: 0 };
	},
});

const readyForRefreshToken = async (url, appName) => {
	await store.commit('addApp', appName);

	const { pathname } = window.location;
	const { isLoginActive } = store.state[appName];

	return pathname.startsWith(url) && !isLoginActive && localStorage.getItem(getAuthDataStorageName(appName));
};

const makeRefreshToken = async (appName, eventNameForDispatch) => {
	const { refreshToken: jwtRefreshToken, dispatchTimeInSeconds } = JSON.parse(localStorage.getItem(getAuthDataStorageName(appName)));

	await store.dispatch(`${appName}/${eventNameForDispatch}`, { token: jwtRefreshToken, dispatchTimeInSeconds });
};

router.beforeEach(async (to, from, next) => {
	if (await readyForRefreshToken(appBaseUrlList.admin, appNameList.admin)) {
		await makeRefreshToken(appNameList.admin, refreshToken);
	} else if (await readyForRefreshToken(appBaseUrlList.logistics, appNameList.logistics)) {
		await makeRefreshToken(appNameList.logistics, refreshToken);
	} else if (await readyForRefreshToken(appBaseUrlList.driver, appNameList.driver)) {
		await makeRefreshToken(appNameList.driver, refreshToken);
	} else if (await readyForRefreshToken(appBaseUrlList.pickup, appNameList.pickup)) {
		await makeRefreshToken(appNameList.pickup, refreshToken);
	} else {
		await store.commit('addApp', appNameList.eshop);

		const { isLoginActive } = store.state[appNameList.eshop];
		const { pathname } = window.location;

		if ((pathname.startsWith(appBaseUrlList.eshop) || pathname === '/')
			&& !isLoginActive && localStorage.getItem(getAuthDataStorageName(appNameList.eshop))) {
			await makeRefreshToken(appNameList.eshop, refreshToken);
		}
	}

	Vue.nextTick(() => {
		const metaTitle = to.matched[0]?.meta?.title;

		document.title = `PENGUIN${(metaTitle ? ` - ${metaTitle}` : '')}`;
	});

	if (RequiredAuthorization(to.name) && !localStorage.getItem(getAuthDataStorageName(store.state.currentAppName))) {
		return next({ name: 'login', params: { from: to.name === 'login' ? to.name : to.path } });
	}

	if (to.name === 'login' && localStorage.getItem(getAuthDataStorageName(store.state.currentAppName))) {
		return next({ name: store.state[store.state.currentAppName].homePage });
	}

	if (newAppPaths.indexOf(to.path) >= 0) {
		next(false);
	}

	return next();
});

export default router;
