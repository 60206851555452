export const ContainerTreeViewService = (http) => ({
	async getStateList() {
		const response = await http.get('/api/ContainerTree/State');
		return response.data;
	},
	async getRegionList(state) {
		const response = await http.get('/api/ContainerTree/Region', { params: { state } });
		return response.data;
	},
	async getCountyList(state, Region) {
		const response = await http.get('/api/ContainerTree/County', { params: { Region } });
		return response.data;
	},
	async getCityList(state, Region, County) {
		const response = await http.get('/api/ContainerTree/City', { params: { Region, County } });
		return response.data;
	},

	async getContainerTree(Region, County, City) {
		const response = await http.get('/api/ContainerTree/ContainerWithEshops', { params: { Region, County, City } });
		return response.data;
	},
});
