import jwtDecode from 'jwt-decode';

import store from '@/store';
import {
	addAppToLogin,
	authFailed,
	loggedOut,
	removeAppToLogin,
	setCurrentApp,
	setCurrentUser,
	setLoginActive,
	setRefreshTokenLoading,
} from '@/store/mutation-types';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import { setJwtTokenHttp } from '@/service/http';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import authService from '@/service/auth-service';
import { loadCurrentUser, logout, refreshToken, authSuccess, authenticate } from '@/store/action-types';

const ESHOP_GENESIS_ID = '1';
const ONE_MINUTE_IN_SECONDS = 60;
const DEV_TESTING_MODE = false;
const DEV_TESTING_REFRESH_TOKEN_TIMEOUT_IN_SEC = 10;

export function getAuthDataStorageName(appName) {
	return `jwt-${appName}-auth_data`;
}

function getLoginTokens(appName, loginCb) {
	const jwtRefreshTokenRaw = localStorage.getItem(getAuthDataStorageName(appName));

	if (jwtRefreshTokenRaw) {
		const jwtRefreshToken = JSON.parse(jwtRefreshTokenRaw);

		return {
			refreshToken: jwtRefreshToken.refreshToken,
			accessToken: jwtRefreshToken.accessToken,
			expiresIn: jwtRefreshToken.dispatchTimeInSeconds * 2,
		};
	}

	return loginCb();
}

function addAvailableAppToAuthData(appName, addedAppName) {
	const authDataKeyName = getAuthDataStorageName(appName);
	const rawAuthData = localStorage.getItem(authDataKeyName);

	if (!rawAuthData) {
		return;
	}

	const authData = JSON.parse(rawAuthData);
	const { availableApps } = authData;

	if (availableApps && !availableApps.includes(addedAppName)) {
		availableApps.push(addedAppName);

		localStorage.setItem(
			authDataKeyName,
			JSON.stringify({
				...authData,
				availableApps,
			}),
		);
	}
}

async function authenticateAccount(
	availableApps,
	dispatchTimeInSeconds,
	primaryAppName,
	appName,
	loginCb,
	{ commit, state, rootState },
	loadUserData = null,
	secondaryAppName = null,
	primaryAccessToken = null,
) {
	try {
		const currentUser = state?.currentUser || {};

		setActiveApp(appName);
		const authData = await loginCb();
		const userData = loadUserData ? await loadUserData(primaryAccessToken, authData.accessToken) : null;

		if (loadUserData && primaryAccessToken && userData?.Eshop !== ESHOP_GENESIS_ID) {
			return;
		}

		availableApps.push(appName);

		localStorage.setItem(
			getAuthDataStorageName(appName),
			JSON.stringify({
				accessToken: authData.accessToken,
				refreshToken: authData.refreshToken,
				dispatchTimeInSeconds: DEV_TESTING_MODE ? DEV_TESTING_REFRESH_TOKEN_TIMEOUT_IN_SEC : authData.expiresIn / 2,
				availableApps,
			}),
		);

		commit(setLoginActive, true);
		commit(addAppToLogin, appName);
		addAvailableAppToAuthData(primaryAppName, appName);

		if (secondaryAppName && localStorage.getItem(getAuthDataStorageName(secondaryAppName))) {
			addAvailableAppToAuthData(secondaryAppName, appName);
		}

		if (currentUser?.roles && rootState[appName]) {
			rootState[appName].currentUser = { ...currentUser, roles: [] };
		}
	} catch (error) {
		console.error(error);

		commit(removeAppToLogin, appName);
	} finally {
		setActiveApp(primaryAppName);
	}
}

async function refreshTokenForAccount({ commit, state }, appName, finallyCb) {
	try {
		const rawTokenData = localStorage.getItem(getAuthDataStorageName(appName));
		let jwtRefreshToken = null;
		let refreshTokenData = {};

		if (rawTokenData) {
			refreshTokenData = JSON.parse(rawTokenData);
			jwtRefreshToken = refreshTokenData.refreshToken;
		}

		if (refreshTokenData?.accessToken) {
			const currentUser = state?.currentUser || {};

			setActiveApp(appName);

			if (currentUser?.roles) {
				state.currentUser = { ...currentUser, roles: [] };
			}

			setJwtTokenHttp(refreshTokenData?.accessToken);

			const authData = await authService.refreshToken(jwtRefreshToken);

			const rawTokenDataAfterRefresh = localStorage.getItem(getAuthDataStorageName(appName));

			if (authData.accessToken || rawTokenDataAfterRefresh.refreshToken === jwtRefreshToken) {
				localStorage.setItem(
					getAuthDataStorageName(appName),
					JSON.stringify({
						availableApps: refreshTokenData.availableApps,
						accessToken: authData.accessToken,
						refreshToken: authData.refreshToken,
						dispatchTimeInSeconds: DEV_TESTING_MODE ? DEV_TESTING_REFRESH_TOKEN_TIMEOUT_IN_SEC : (authData?.expiresIn || 0) / 2,
					}),
				);
			}

			commit(addAppToLogin, appName);
		}
	} catch (error) {
		commit(removeAppToLogin, appName);
		localStorage.removeItem(getAuthDataStorageName(appName));

		console.error(error);
	} finally {
		await finallyCb();
	}
}

function setActiveApp(appName) {
	store.commit(setCurrentApp, appName);

	let accessToken = null;

	if (appName) {
		const jwtTokenRaw = localStorage.getItem(getAuthDataStorageName(appName));

		if (jwtTokenRaw) {
			try {
				const jwtToken = JSON.parse(jwtTokenRaw);
				accessToken = jwtToken.accessToken;
			} catch (e) {
				console.error(e);
			}
		}
	}

	setJwtTokenHttp(accessToken);
}

export default class AuthActions {
	static handleTimeouts = [];

	availableApps = [];
	primaryAppName = null;
	actions = {};

	constructor(availableApps) {
		this.availableApps = availableApps;
		[this.primaryAppName] = availableApps;

		this.actions = {
			[authSuccess]: (context, tokenData) => {
				this.authSuccess(context, tokenData);
			},

			[logout]: (context) => {
				this.logout(context);
			},

			[loadCurrentUser]: async (context) => {
				await this.loadCurrentUser(context);
			},

			[authenticate]: async (context, authData) => {
				await this.authenticate(context, authData);
			},

			[refreshToken]: async (context, refreshTokenData) => {
				await this.refreshToken(context, refreshTokenData);
			},
		};
	}

	static clearAuthTimeouts() {
		this.handleTimeouts.filter(Boolean).forEach((handleTimeout) => clearTimeout(handleTimeout));
		this.handleTimeouts.splice(0, this.handleTimeouts.length);
	}

	async authenticate(context, { username, password }) {
		const { dispatch, state, commit } = context;
		const [primaryAppName, secondaryAppName, tertiaryAppName] = this.availableApps;

		try {
			AuthActions.clearAuthTimeouts();

			const primaryLoginData = await getLoginTokens(primaryAppName, () => authService.login(username, password));
			const dispatchTimeInSeconds = DEV_TESTING_MODE ? DEV_TESTING_REFRESH_TOKEN_TIMEOUT_IN_SEC : primaryLoginData.expiresIn / 2;
			const availableApps = [primaryAppName];

			await dispatch(authSuccess, {
				accessToken: primaryLoginData.accessToken,
				refreshToken: primaryLoginData.refreshToken,
				dispatchTimeInSeconds,
				availableApps,
			});
			await dispatch(loadCurrentUser);

			commit(addAppToLogin, primaryAppName);

			if (secondaryAppName) {
				const isValidEshop = typeof state.currentUser.Eshop === 'undefined' ? true : state.currentUser.Eshop === ESHOP_GENESIS_ID;

				if (!isValidEshop) {
					return;
				}

				await authenticateAccount(availableApps, dispatchTimeInSeconds, primaryAppName, secondaryAppName, () => authService.login(username, password), context);

				if (tertiaryAppName) {
					await authenticateAccount(
						availableApps,
						dispatchTimeInSeconds,
						primaryAppName,
						tertiaryAppName,
						() => authService.login(username, password),
						context,
						async (primaryAccessToken, tertiaryAccessToken) => {
							setJwtTokenHttp(tertiaryAccessToken);
							const userData = await authService.loadCurrentUser();
							setJwtTokenHttp(primaryAccessToken);

							return userData;
						},
						secondaryAppName,
						primaryLoginData.accessToken,
					);
				}
			}
		} catch (error) {
			commit(removeAppToLogin, primaryAppName);
			commit(authFailed, { error });
			await dispatch(logout);
		} finally {
			commit(setLoginActive, false);
		}
	}

	async refreshToken({ dispatch, state, rootState, commit }, { token, dispatchTimeInSeconds }) {
		const [primaryAppName, secondaryAppName, tertiaryAppName] = this.availableApps;
		const primaryRawTokenData = localStorage.getItem(getAuthDataStorageName(primaryAppName));

		if (!primaryRawTokenData) {
			return;
		}

		const oldPrimaryAuthData = JSON.parse(primaryRawTokenData);

		setActiveApp(primaryAppName);

		commit(setRefreshTokenLoading, true);

		try {
			if (!state.isLoginActive) {
				await dispatch(loadCurrentUser);
				commit(setLoginActive, true);
			}

			const primaryAuthData = await authService.refreshToken(token);
			const refreshTokenDispatchTimeInSeconds = DEV_TESTING_MODE ? DEV_TESTING_REFRESH_TOKEN_TIMEOUT_IN_SEC : primaryAuthData.expiresIn / 2;

			AuthActions.handleTimeouts.push(
				setTimeout(async () => {
					commit(setRefreshTokenLoading, true);

					if (secondaryAppName) {
						setActiveApp(secondaryAppName);

						await refreshTokenForAccount(
							{
								commit,
								state,
								rootState,
							},
							secondaryAppName,
							async () => {
								setActiveApp(tertiaryAppName);
								await refreshTokenForAccount(
									{
										commit,
										state,
										rootState,
									},
									tertiaryAppName,
									async () => {
										commit(setRefreshTokenLoading, false);
										await dispatch(refreshToken, {
											token: primaryAuthData.refreshToken,
											dispatchTimeInSeconds: refreshTokenDispatchTimeInSeconds,
										});
									},
								);
							},
						);
					} else {
						await dispatch(refreshToken, {
							token: primaryAuthData.refreshToken,
							dispatchTimeInSeconds: refreshTokenDispatchTimeInSeconds,
						});
						commit(setRefreshTokenLoading, false);
					}
				}, refreshTokenDispatchTimeInSeconds * 1000),
			);

			commit(addAppToLogin, primaryAppName);

			if (secondaryAppName && localStorage.getItem(getAuthDataStorageName(secondaryAppName))) {
				commit(addAppToLogin, secondaryAppName);
			}

			if (tertiaryAppName && localStorage.getItem(getAuthDataStorageName(tertiaryAppName))) {
				commit(addAppToLogin, tertiaryAppName);
			}

			await dispatch(authSuccess, {
				accessToken: primaryAuthData.accessToken,
				refreshToken: primaryAuthData.refreshToken,
				availableApps: oldPrimaryAuthData.availableApps,
				dispatchTimeInSeconds: refreshTokenDispatchTimeInSeconds,
			});
		} catch (error) {
			console.error(error);

			commit(removeAppToLogin, primaryAppName);
			commit(authFailed, { error });

			if (dispatchTimeInSeconds > ONE_MINUTE_IN_SECONDS) {
				const refreshTokenDispatchTimeInSeconds = DEV_TESTING_MODE ? DEV_TESTING_REFRESH_TOKEN_TIMEOUT_IN_SEC : dispatchTimeInSeconds / 2;

				AuthActions.handleTimeouts.push(
					setTimeout(async () => {
						await dispatch(refreshToken, {
							token,
							dispatchTimeInSeconds: refreshTokenDispatchTimeInSeconds,
						});
					}, refreshTokenDispatchTimeInSeconds * 1000),
				);
			} else {
				AuthActions.handleTimeouts.forEach((handleTimeout) => clearTimeout(handleTimeout));

				commit(setLoginActive, false);

				if (!secondaryAppName) {
					await dispatch(logout);
				}
			}
		} finally {
			commit(setRefreshTokenLoading, false);
		}
	}

	authSuccess({ commit }, tokenData) {
		localStorage.setItem(getAuthDataStorageName(this.primaryAppName), JSON.stringify(tokenData));

		setActiveApp(this.primaryAppName);

		commit(authSuccess);
	}

	async loadCurrentUser({ commit }) {
		const jwtTokenRaw = localStorage.getItem(getAuthDataStorageName(this.primaryAppName));

		if (jwtTokenRaw) {
			store.commit(setCurrentApp, this.primaryAppName);

			const jwtToken = JSON.parse(jwtTokenRaw);
			const decodedJwtToken = jwtDecode(jwtToken.accessToken);

			setJwtTokenHttp(jwtToken.accessToken);

			const currentUser = await authService.loadCurrentUser();

			commit(setCurrentUser, { currentUser, roles: decodedJwtToken.scope });
		}
	}

	logout({ commit }) {
		AuthActions.clearAuthTimeouts();

		localStorage.removeItem(getAuthDataStorageName(this.primaryAppName));
		setActiveApp(this.primaryAppName);

		commit(loggedOut);
	}
}
