<template>
	<form>
		<form-group-input :label="$t('admin.country')" required>
			<el-select v-model="replacement.state" class="select-default">
				<el-option key="cs" value="cs" label="Česká republika" />
				<el-option key="sk" value="sk" label="Slovensko" />
			</el-select>
		</form-group-input>
		<form-group-input :label="$t('admin.target')" required>
			<el-select v-model="replacement.target" class="select-default" @change="changeType">
				<el-option key="SMS" value="SMS" label="SMS" />
				<el-option key="E-mail" value="EMail" label="E-mail" />
			</el-select>
		</form-group-input>
		<form-group-input
			:label="$t('admin.replacement')"
			required>
			<el-select v-model="replacement.propertyName" class="select-default" @change="changeType">
				<el-option v-for="property in propertyNames" :key="property" :value="property" />
			</el-select>
		</form-group-input>
		<form-group-input
			v-model="replacement.value"
			:label="$t('admin.value')"
			required />
		<confirm-button
			:callback="save"
			:confirm-message="confirmMessage"
			:show-success-message="false"
			class="pull-right">
			{{ $t('admin.save') }}
		</confirm-button>
		<div class="clearfix" />
	</form>
</template>

<script>
import { Select as ElSelect, Option as ElOption } from 'element-ui';

export default {
	name: 'ReplacementEdit',
	components: {
		ElSelect,
		ElOption,
	},
	props: {
		replacement: {
			type: Object,
			default: () => ({
				state: 'cs',
				target: 'SMS',
			}),
		},
	},
	data() {
		return {
			propertyNames: [],
		};
	},
	computed: {
		confirmMessage() { return this.$t('admin.replacementSave'); },
	},
	async created() {
		if (this.replacement.target) {
			this.replacememnts = await this.$service.replacements.getPlaceholders(this.replacement.target);
		}
	},
	methods: {
		async save() {
			await this.$service.replacements.addOrUpdate(this.replacement);
			this.$emit('show-hide-edit-dialog');
		},
		async changeType() {
			this.propertyNames = await this.$service.replacements.getPlaceholders(this.replacement.target);
		},
	},
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-title {
  color: #2c2c2c;
}
</style>
