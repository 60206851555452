<template>
	<base-user-form
		add-user-function="addAdmin"
		link-back="administrator-list"
		new-title="Nový administrátor"
		title="Administrátor" />
</template>

<script>
import FormMixin from '@/util/form-mixin';
import { BaseUserForm } from '@/components/Base';

export default {
	name: 'AdministratorForm',
	components: { BaseUserForm },
	mixins: [FormMixin],
};
</script>
