<template>
	<div>
		<div class="card row user-reservation-filter">
			<div class="col-2">
				<form-group-input :label="$t('eshop.monthAndYear')">
					<date-picker
						v-model="reservationUserFilter.monthYear"
						type="month"
						format="MMMM yyyy"
						prefix-icon="el-icon-date"
						:clearable="false"
						@change="loadData" />
				</form-group-input>
			</div>
		</div>
		<base-user-list
			v-loading="loading"
			:show-filter="false"
			user-sorting
			:users="users"
			without-locking
			title="Seznam uživatelů s rezervacemi"
			detail-link="eshop-user-edit"
			@get-page="loadData"
			@sort="sortData" />
	</div>
</template>

<script>
import { DatePicker } from 'element-ui';

import { SORTING_ASC } from '@/modules/eshop/store/constants';
import { BaseUserList } from '@/components/Base';
import notifyService from '@/service/notify-service';

export default {
	name: 'EshopReservationUserList',
	components: {
		BaseUserList,
		DatePicker,
	},
	data() {
		return {
			users: {},
			loading: false,
			reservationUserFilter: {
				monthYear: new Date(),
			},
		};
	},
	methods: {
		async loadData() {
			this.loading = true;

			const { monthYear } = this.reservationUserFilter;

			if (monthYear) {
				const reservations = await this.$service.reservation.createByUserStatic(monthYear.getFullYear(), monthYear.getMonth() + 1);
				const allUsers = reservations.map((item) => item.user);
				const tableUsers = allUsers
					.filter((user, index, users) => index === users.indexOf(user))
					.filter(Boolean)
					.sort((item, item2) => item.name.localeCompare(item2.name));
				this.users = {
					currentPage: 1,
					entities: tableUsers,
					pagesCount: 1,
					totalCount: tableUsers.length,
				};
			} else {
				notifyService.notifyError(this.$t('eshop.czech.dateRequired'));
			}

			this.loading = false;
		},
		sortData(column) {
			if (this.users.entities[0][column.prop]) {
				const sortedUsers = this.users.entities.sort((item, item2) => item[column.prop].localeCompare(item2[column.prop]));

				this.users.entities = column.order === SORTING_ASC ? sortedUsers : sortedUsers.reverse();
			}
		},
	},
};
</script>

<style lang="scss">
.user-reservation-filter {
	flex-direction: row;
}
</style>
