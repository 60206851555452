<template>
	<div class="row" data-cy="homePage">
		<div class="col-md-12 card">
			<div class="d-flex align-items-center">
				<div class="col">
					<autocomplete
						v-model="filter.region"
						:label="$t('eshop.region')"
						trigger-on-focus
						search-field-name="Regions"
						@fetch-suggestions="querySearchAsync"
						@select="loadData" />
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.county"
						:label="$t('eshop.county')"
						trigger-on-focus
						search-field-name="Counties"
						@fetch-suggestions="querySearchAsync"
						@select="loadData" />
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.city"
						:label="$t('eshop.city')"
						trigger-on-focus
						search-field-name="Cities"
						@fetch-suggestions="querySearchAsync"
						@select="loadData" />
				</div>
				<div class="col">
					<autocomplete
						v-model="filter.street"
						:label="$t('eshop.street')"
						trigger-on-focus
						search-field-name="Streets"
						@fetch-suggestions="querySearchAsync"
						@select="loadData" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.ReservationNumberLike" :label="$t('eshop.orderNumber')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.SKU" :label="$t('eshop.sku')" type="text" />
				</div>
			</div>
			<div class="d-flex">
				<div class="col col-2.5">
					<label> {{ $t('eshop.reservationState') }} </label>
					<el-select
						v-model="filter.reservationSelects"
						size="large"
						class="w-100"
						:placeholder="$t('eshop.reservationState')"
						value-key="ordinal"
						clearable
						multiple
						@change="loadData">
						<el-option
							v-for="status in ReservationStatus.enumValues"
							:key="status.ordinal"
							:label="status.czech()"
							:value="status">
							<i :class="status.icon()" /> {{ status.czech() }}
						</el-option>
					</el-select>
				</div>
				<div class="col col-2.5">
					<label>
						{{ $t('eshop.pickupPlaceType') }}
					</label>
					<el-select
						v-model="Destination"
						size="large"
						class="w-100"
						:placeholder="$t('eshop.pickupPlaceType')"
						value-key="ordinal"
						clearable
						@change="loadData">
						<template slot="prefix">
							<span v-if="Destination" class="container-icon-in-select-box">
								<span v-show="Destination.valueOf() === 'PrivateAddress'"><i :class="PickupPlace.PRIVATE_ADDRESS.icon()" /></span>
								<span v-show="Destination.valueOf() === 'SmartKeyBox'"><i :class="PickupPlace.SMART_KEY_BOX.icon()" /></span>
								<span v-show="Destination.valueOf() === 'PenguinBox'"><img :src="Destination.icon()" alt=""></span>
								<span v-show="Destination.valueOf() === 'Store'"><img :src="Destination.icon()" alt=""></span>
							</span>
						</template>
						<el-option
							v-for="place in PickupPlace.enumValues"
							:key="place.ordinal"
							:label="place.czech()"
							:value="place">
							<span v-if="place">
								<span v-if="place.valueOf() === 'PrivateAddress'"><i :class="place.icon()" /></span>
								<span v-else-if="place.valueOf() === 'SmartKeyBox'"><i :class="place.icon()" /></span>
								<img v-else :src="place.icon()" alt="">
							</span>
							{{ place.czech() }}
						</el-option>
					</el-select>
				</div>
				<div class="col-3">
					<form-group-input :label="$t('eshop.expired')" class="ml-4">
						<div class="d-flex mt-1">
							<yes-no-all v-model="filter.isExpired" trigger-on-change-method @on-change-method="loadData" />
						</div>
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input :label="$t('eshop.reservationPost')" class="ml-4">
						<div class="d-flex mt-1">
							<yes-no-all v-model="filter.isPostPackage" trigger-on-change-method @on-change-method="loadData" />
						</div>
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input
						v-model="filter.czechPostZip"
						:label="$t('admin.czPostIdentifier')"
						type="text" />
				</div>
			</div>
			<div class="d-flex">
				<div class="col">
					<label> {{ $t('eshop.container') }} </label>
					<el-select
						ref="containerSelect"
						v-model="filter.containers"
						v-loading="loading"
						:label="$t('eshop.container')"
						class="w-100 container-select"
						:placeholder="$t('eshop.containerSearchHint')"
						value-key="ordinal"
						clearable
						multiple
						remote
						filterable
						:remote-method="filterFreeContainers"
						@change="loadDataContainers"
						@visible-change="onVisibleChange">
						<el-option
							v-for="opt in freeContainersFiltered"
							:key="opt.containerId"
							:label="$options.filters.location(opt)"
							:value="opt.containerId">
							<img v-if="opt.type === containerTypes.store" src="/static/img/icons/genesis.svg" alt="Genesis">
							<img v-if="opt.type === containerTypes.penguinBox" src="/static/img/icons/pb.svg" alt="PB">
							<span v-if="opt.type === containerTypes.smartBox"><i :class="PickupPlace.SMART_KEY_BOX.icon()" /></span>
							<i v-if="opt.type === containerTypes.private" class="fa fa-home" />
							{{ $options.filters.location(opt) }}
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="d-flex">
				<div class="col">
					<form-group-input
						v-model="filter.Phone"
						:label="$t('eshop.phone')"
						type="text"
						@keypress="isTelNumber($event)" />
				</div>
				<div class="col">
					<form-group-input :label="$t('eshop.occupiedFromStart')">
						<date-picker
							v-model="filter.From"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime"
							@change="loadData(1)" />
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input :label="$t('eshop.subscriptionEndBefore')">
						<date-picker
							v-model="filter.To"
							format="dd.MM.yyyy HH:mm"
							prefix-icon="el-icon-date"
							type="datetime"
							@change="loadData(1)" />
					</form-group-input>
				</div>
				<div class="col">
					<form-group-input v-model="filter.Email" :label="$t('eshop.email')" type="email" />
				</div>
			</div>
			<div class="d-flex justify-content-between">
				<div class="col-2 mb-1 d-flex justify-content-start">
					<p-button type="success" size="md" @click="loadData">
						{{ $t('eshop.search') }}
					</p-button>
					<el-tooltip :content="$t('eshop.tooltips.clearFilter')" placement="top-start">
						<p-button
							class="ml-2"
							type="danger"
							icon
							size="md"
							@click="clearFilter">
							<i class="fa fa-times" />
						</p-button>
					</el-tooltip>
				</div>
			</div>
		</div>

		<div class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>

				<div v-loading="loading" class="row">
					<div class="col-sm-12 mt-2">
						<el-table
							data-cy="ListMainTable"
							class="table-striped"
							:data="reservations"
							show-overflow-tooltip
							row-class-name="tableRow"
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							@row-click="goToEdit"
							@sort-change="sortChange">
							<el-table-column width="60" align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.state')" placement="top-start">
										<span>{{ $t('eshop.state') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.status" :content="ReservationStatus.czech(scope.row.status)" placement="top-start">
										<span :key="scope.row.status" class="mr-2">
											<i :class="ReservationStatus.icon(scope.row.status)" />
										</span>
									</el-tooltip>
									<span v-if="scope.row.container">
										<span v-show="scope.row.container.type === 'PenguinBox'">
											<el-tooltip content="Penguin Box" placement="top">
												<img src="/static/img/icons/pb.svg" alt="PB">
											</el-tooltip>
										</span>
										<span v-show="scope.row.container.type === 'Store'">
											<el-tooltip content="Výdejna" placement="top">
												<img src="/static/img/icons/genesis.svg" alt="Genesis">
											</el-tooltip>
										</span>
										<span v-show="scope.row.container.type === 'SmartKeyBox'">
											<el-tooltip content="Smart Box" placement="top">
												<i class="nc-icon nc-mobile" />
											</el-tooltip>
										</span>
									</span>
									<span v-show="!scope.row.container || scope.row.container.type === 'PrivateAddress'">
										<el-tooltip content="Adresa" placement="top">
											<i class="fa fa-home gray" />
										</el-tooltip>
									</span>
								</template>
							</el-table-column>
							<el-table-column
								property="reservationNumber"
								header-align="center"
								align="right"
								width="150"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.orderNumber')" placement="top-start">
										<span>{{ $t('eshop.orderNumber') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								property="stockItem.name"
								header-align="center"
								align="center"
								sortable="custom"
								width="100">
								<template #header>
									<el-tooltip :content="$t('eshop.product')" placement="top-start">
										<span>{{ $t('eshop.product') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								v-if="allowPinOperations"
								property="unlockCode"
								header-align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.unlockCode')" placement="top-start">
										<span>{{ $t('admin.unlockCode') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column align="center" width="60">
								<template #header>
									<el-tooltip :content="$t('eshop.actions')" placement="top-start">
										<span>{{ $t('eshop.actions') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :content="$t('eshop.tooltips.addToCollectionList')" placement="top-start">
										<p-button
											v-if="scope.row.isExpired"
											:key="scope.row.reservationId"
											:disabled="disabledAddForCollect(scope.row)"
											type="danger"
											size="sm"
											icon
											@click.stop="addForCollection(scope.row)">
											<i class="fa fa-print" />
										</p-button>
									</el-tooltip>
									<el-tooltip
										v-if="canDirectSale(scope.row)"
										:key="scope.row.reservationId"
										:content="$t('eshop.tooltips.directSale')"
										placement="top-start">
										<p-button size="sm" icon @click.stop="directSale(scope.row)">
											<i class="fa fa-shopping-cart" />
										</p-button>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column
								property="email"
								header-align="center"
								sortable="custom">
								<template #header>
									<el-tooltip :content="$t('eshop.email')" placement="top-start">
										<span>{{ $t('eshop.email') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.email" :max-length="20" />
								</template>
							</el-table-column>
							<el-table-column
								property="phone"
								header-align="center"
								sortable="custom"
								width="150">
								<template #header>
									<el-tooltip :content="$t('eshop.phone')" placement="top-start">
										<span>{{ $t('eshop.phone') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.phone | phone }}
								</template>
							</el-table-column>
							<el-table-column
								property="reservedFrom"
								header-align="center"
								sortable="custom"
								width="150">
								<template #header>
									<el-tooltip :content="$t('eshop.occupiedFrom')" placement="top-start">
										<span>{{ $t('eshop.occupiedFrom') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.reservedFrom | datetime }}
								</template>
							</el-table-column>
							<el-table-column
								property="expiresOn"
								header-align="center"
								sortable="custom"
								width="150">
								<template #header>
									<el-tooltip :content="$t('eshop.subscriptionEnd')" placement="top-start">
										<span>{{ $t('eshop.subscriptionEnd') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.expiresOn | datetime }}
								</template>
							</el-table-column>
							<el-table-column
								header-align="center"
								sortable="custom"
								property="deliveryPoint.city"
								align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.city')" placement="top-start">
										<span>{{ $t('eshop.city') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.container ? scope.row.container.city : scope.row.deliveryPoint ? scope.row.deliveryPoint.city : '' }}
								</template>
							</el-table-column>
							<el-table-column
								header-align="center"
								sortable="custom"
								property="deliveryPoint.street"
								align="center">
								<template #header>
									<el-tooltip :content="$t('eshop.street')" placement="top-start">
										<span>{{ $t('eshop.street') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.container ? scope.row.container.street : scope.row.deliveryPoint ? scope.row.deliveryPoint.street : '' }}
								</template>
							</el-table-column>
							<el-table-column
								property="container.serialNumber"
								header-align="center"
								sortable="custom"
								align="center"
								width="110">
								<template #header>
									<el-tooltip :content="$t('eshop.container')" placement="top-start">
										<span>{{ $t('eshop.serialNumber') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div class="d-flex justify-content-center">
										{{ scope.row.container ? scope.row.container.serialNumber : "" }}
									</div>
								</template>
							</el-table-column>
							<el-table-column
								property="czechPostZip"
								header-align="center"
								sortable="custom"
								align="center"
								width="100">
								<template #header>
									<el-tooltip :content="$t('admin.czPostIdentifier')" placement="top-start">
										<span>{{ $t('eshop.cpostBalikovnaPsc') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.czechPostZip" :max-length="13" />
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 mt-2">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@current-change="loadData"
									@size-change="loadDataPageSize" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<base-collection-list
			:clean-for-collection="removeFromCollection"
			:collect-later="collectLater"
			:collect-soon="collectSoon"
			:remove-from-collection="removeFromCollection" />

		<el-dialog v-if="directSaleVisible" :visible.sync="directSaleVisible">
			<direct-sale
				:reservation-id="directSaleProps.reservationId"
				:reservation-number="directSaleProps.reservationNumber"
				@cancel-direct-sale="cancelDirectSale"
				@save-direct-sale="saveDirectSale" />
		</el-dialog>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
	Select as ElSelect,
	Option as ElOption,
	Table as ElTable,
	TableColumn as ElTableColumn,
	Pagination as ElPagination,
	DatePicker,
	Tooltip as ElTooltip,
	Dialog as ElDialog,
} from 'element-ui';

import { ReservationStatus, PickupPlace } from '@/modules/eshop/store/enums';
import { isTelNumber } from '@/util/helpers';
import TrimText from '@/components/UIComponents/TrimText.vue';
import { appName } from '@/modules/eshop/config';
import { resetFilter, setFilter } from '@/store/mutation-types';
import { BaseCollectionList } from '@/components/Base';
import { addForCollection, collectLater, collectSoon, removeFromCollection } from '@/store/action-types';
import { containerTypes } from '@/globalConstants';
// eslint-disable-next-line import/no-cycle
import ContainersForSelectMixin from '@/util/containers-for-select-mixin';
import ContainerSelectMixin from '@/util/container-select-mixin';

import DirectSale from './ReservationDetails/DirectSale.vue';

export default {
	name: 'ReservationList',
	components: {
		TrimText,
		ElPagination,
		ElTable,
		ElTableColumn,
		ElSelect,
		ElOption,
		DatePicker,
		ElTooltip,
		BaseCollectionList,
		ElDialog,
		DirectSale,
	},
	mixins: [ContainersForSelectMixin, ContainerSelectMixin],
	data() {
		return {
			loading: false,
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			ReservationStatus,
			PickupPlace,
			directSaleVisible: false,
			directSaleProps: {},
			Destination: null,
			filter: {},
			containerTypes,
			abortController: null,
			abortControllerData: null,
		};
	},
	computed: {
		...mapState(appName, ['containerValues', 'reservationsForCollect', 'reservationFilter', 'currentUser', 'containersForSelect']),
		reservations() {
			return this.page.entities;
		},
		allowPinOperations() {
			return this.currentUser.AllowPinOperations === 'True';
		},
		freeContainers() {
			this.enrichFreeContainers(this.containersForSelect);
			return this.containersForSelect;
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'reservationFilter', data: val });
			},
		},
	},
	created() {
		this.filter = this.reservationFilter;
		this.filter.orderBy = 'ExpiresOn';
		this.filter.direction = 'Descending';
		this.loadData();
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
		this.setContainerSelectEventListener();
	},
	methods: {
		isTelNumber,
		...mapActions(appName, [addForCollection, removeFromCollection, collectSoon, collectLater]),
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		goToEdit(row) {
			this.$router.push({ name: 'reservation-detail', params: { id: row.reservationId } });
		},
		sortChange(column) {
			this.filter.orderBy = column.prop;
			this.filter.direction = column.order;
			this.loadData(this.page.currentPage);
		},
		async loadData(page = 1) {
			this.loading = true;
			this.abortControllerData?.abort();
			this.abortControllerData = new AbortController();
			this.filter.Status = this.filter.reservationSelects.map((status) => status.camelCase());
			this.filter.DestinationType = this.Destination ? this.Destination.valueOf() : null;
			this.page = await this.$service.eshop.getList(page || 1, this.filter, this.pageSize, this.abortControllerData);
			this.loading = false;
		},
		async loadDataContainers() {
			await this.loadData();
			this.containerSelectOnChange();
		},
		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		querySearchAsync(input, cb, searchFieldName) {
			this.abortController?.abort();
			this.abortController = new AbortController();
			this.$service.container.searchByInput(input, searchFieldName, this.filter)
				.then((results) => {
					cb(results);
				});
		},

		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'reservationFilter');
			this.filter = this.reservationFilter;
			this.filter.orderBy = 'ExpiresOn';
			this.filter.direction = 'Descending';
			this.Destination = null;
			this.clearLastQuery();
			this.loadData();
		},

		disabledAddForCollect(row) {
			return !!this.reservationsForCollect?.filter((res) => res.reservationId === row.reservationId).length;
		},

		canDirectSale(row) {
			return this.currentUser.DirectSale === 'True' && ReservationStatus.enumValueOf(row.status) === ReservationStatus.WAIT_FOR_PAY;
		},

		cancelDirectSale() {
			this.directSaleProps = {};
			this.showHideDirectSale();
		},

		saveDirectSale() {
			this.directSaleProps = {};
			this.loadData(this.page.currentPage);
			this.showHideDirectSale();
		},

		showHideDirectSale() {
			this.directSaleVisible = !this.directSaleVisible;
		},

		directSale(row) {
			this.directSaleProps.reservationId = row.reservationId;
			this.directSaleProps.reservationNumber = row.reservationNumber;
			this.showHideDirectSale();
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}

.fa-home {
	color: #606266;
}

.container-icon-in-select-box {
	padding-left: 7px;
	padding-top: 10px;
	padding-bottom: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
</style>
