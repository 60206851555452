<template>
	<card>
		<div class="card-body">
			<h2 class="card-title">
				{{ $t('eshop.container') }} {{ $t('eshop.nr') }} {{ reservation.containerSerialNumber }}
			</h2>
			<form-group-input :label="$t('eshop.country')" :value="reservation.state" readonly />
			<form-group-input :label="$t('eshop.city')" :value="reservation.city" readonly />
			<form-group-input :label="$t('eshop.street')" :value="reservation.street" readonly />
			<form-group-input :label="$t('eshop.location')" :value="reservation.location" readonly />
			<el-table v-if="similarReservations && similarReservations.length" class="table-striped" :data="similarReservations">
				<el-table-column property="customerAddress" header-align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.address')" placement="top-start">
							<span>{{ $t('eshop.address') }}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column header-align="center">
					<template #header>
						<el-tooltip :content="$t('eshop.orderDateFrom')" placement="top-start">
							<span>{{ $t('eshop.orderDateFrom') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ scope.row.from | datetime }}
					</template>
				</el-table-column>
				<el-table-column align="center">
					<template #default="scope">
						<el-tooltip :content="$t('eshop.stickToReservation')" placement="top-start">
							<p-button type="success" @click="stickToReservation(scope.row.reservationId)">
								<span class="fa fa-plus" />
							</p-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</card>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip } from 'element-ui';

import notifyService from '@/service/notify-service';

export default {
	name: 'ContainerInfo',
	components: {
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	props: {
		reservation: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			similarReservations: [],
		};
	},
	async created() {
		if (this.reservation.eshopOrderId) {
			this.similarReservations = await this.$service.eshopOrder.getSimilar(this.reservation.eshopOrderId);
		}
	},

	methods: {
		async stickToReservation(reservationId) {
			try {
				const data = await this.$service.eshopOrder.stickToReservation(this.reservation.eshopOrderId, reservationId);
				notifyService.notifySuccess(this.$t('eshop.stickToReservationSuccess'));
				this.$emit('eshop-order-changed', data);
				this.similarReservations = await this.$service.eshopOrder.getSimilar(this.reservation.eshopOrderId);
			} catch {
				notifyService.notifyError(this.$t('eshop.stickToReservationError'));
			}
		},
	},
};
</script>
