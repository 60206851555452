<template>
	<card v-loading="loading">
		<div class="row">
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-6">
						<form-group-input :label="$t('admin.fromStatus')">
							<span class="text-danger"> *</span>
							<el-select
								v-model="formData.fromStatus"
								:label="$t('admin.fromStatus')"
								size="large"
								class="w-100"
								value-key="ordinal"
								:placeholder="$t('admin.fromStatusPlaceholder')"
								@change="validateFormForLoading">
								<el-option
									v-for="status in MailTemplateFromStatus.enumValues"
									:key="status.ordinal"
									:value="status"
									:label="status.czech()">
									{{ status.czech() }}
								</el-option>
							</el-select>
							<div v-if="getError(validations.fromStatus.name)" class="invalid-feedback d-block">
								{{ getError(validations.fromStatus.name) }}
							</div>
						</form-group-input>
					</div>
					<div class="col-md-6">
						<form-group-input :label="$t('admin.toStatus')">
							<span class="text-danger"> *</span>
							<el-select
								v-model="formData.toStatus"
								:label="$t('admin.toStatus')"
								size="large"
								class="w-100"
								:placeholder="$t('admin.toStatusPlaceholder')"
								value-key="ordinal"
								@change="validateFormForLoading">
								<el-option
									v-for="status in MailTemplateToStatus.enumValues"
									:key="status.ordinal"
									:value="status"
									:label="status.czech()">
									{{ status.czech() }}
								</el-option>
							</el-select>
							<div v-if="getError(validations.toStatus.name)" class="invalid-feedback d-block">
								{{ getError(validations.toStatus.name) }}
							</div>
						</form-group-input>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<form-group-input :label="$t('admin.destination')">
							<span class="text-danger"> *</span>
							<el-select
								v-model="formData.destination"
								:label="$t('admin.destination')"
								size="large"
								class="w-100"
								:placeholder="$t('admin.destinationPlaceholder')"
								value-key="ordinal"
								@change="validateFormForLoading">
								<el-option
									v-for="status in MailTemplateDestination.enumValues"
									:key="status.ordinal"
									:value="status"
									:label="status.czech()">
									{{ status.czech() }}
								</el-option>
							</el-select>
							<div v-if="getError(validations.destination.name)" class="invalid-feedback d-block">
								{{ getError(validations.destination.name) }}
							</div>
						</form-group-input>
					</div>
					<div class="col-md-6">
						<form-group-input :label="$t('admin.group')">
							<span class="text-danger"> *</span>
							<el-select
								v-model="formData.group"
								:label="$t('admin.group')"
								size="large"
								class="w-100"
								:placeholder="$t('admin.groupPlaceholder')"
								value-key="ordinal"
								@change="validateAndLoadReplacements">
								<el-option
									v-for="status in MailTemplateGroup.enumValues"
									:key="status.ordinal"
									:value="status"
									:label="status.czech()">
									{{ status.czech() }}
								</el-option>
							</el-select>
							<div v-if="getError(validations.group.name)" class="invalid-feedback d-block">
								{{ getError(validations.group.name) }}
							</div>
						</form-group-input>
					</div>
				</div>
				<div class="row">
					<div class="col-md-8">
						<form-group-input
							v-model="formData.language"
							type="text"
							:placeholder="$t('admin.languagePlaceholder')"
							:label="$t('admin.language')"
							:data-vv-as="$t('admin.language')"
							@change="validateFormForLoading"
							@keyup="validateFormForLoading" />
					</div>
					<div class="col-md-4">
						<form-group-input :label="$t('admin.smsTemplate')">
							<p-checkbox v-model="isSms" class="mt-1">
								{{ $t('admin.switchToSmsTemplate') }}
							</p-checkbox>
						</form-group-input>
					</div>
				</div>

				<eshop-list
					:default-value="formData.eshopId"
					:loading="loading"
					@set-value="setEshopId"
					@set-loading="eshopLoading" />

				<div class="pull-right button-load_template mr-3">
					<p-button
						type="primary"
						:disabled="!isValidForLoading"
						class="btn-lg"
						@click="loadTemplateData">
						{{ $t('admin.load') }}
					</p-button>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group mb-4">
					<label>
						{{ $t('admin.content') }}
						<span class="text-danger">*</span>
					</label>
					<el-input
						v-model="formData.content"
						v-validate="validations.content.rules"
						type="textarea"
						:rows="15"
						:disabled="!isValidForSaving"
						:placeholder="$t('admin.contentPlaceholder')"
						:label="$t('admin.content')"
						:name="validations.content.name"
						:error="getError(validations.content.name)"
						:data-vv-as="$t('admin.content')"
						class="text-monospace" />
				</div>
				<div v-if="resultMessage.show" class="mb-4">
					<el-alert
						v-if="resultMessage.successMessage"
						:title="resultMessage.successMessage"
						type="success"
						show-icon
						center />
				</div>
				<label>
					{{ $t('admin.templatePreview') }}
				</label>
				<div class="preview mb-3 p-2 form-control">
					<div v-if="resultMessage.errorMessages.length" class="mb-4">
						<div v-for="message in resultMessage.errorMessages" :key="message">
							<el-alert
								:title="message"
								type="error"
								show-icon
								center
								class="mb-1" />
						</div>
					</div>
					<iframe
						v-else
						ref="previewArea"
						class="m-n1"
						title="Preview template">
						{{ preview }}
					</iframe>
				</div>
				<confirm-button
					class="pull-right ml-2 btn-lg"
					type="success"
					:show-success-message="false"
					:confirm-message="$t('admin.saveTemplateConfirm')"
					:disabled="!isValidForSaving"
					:callback="save">
					{{ $t('admin.save') }}
				</confirm-button>
				<p-button
					:disabled="!isValidForValidation"
					class="pull-right btn-lg"
					type="warning"
					@click="validate">
					{{ $t('admin.validate') }}
				</p-button>
			</div>
			<div v-loading="helpLoading" class="col-md-3">
				<label> <i class="fa fa-info-circle" /> {{ $t('admin.helpPlaceholders') }} </label>
				<pre v-if="replacements">{{ replacements }}</pre>
				<div v-else class="alert alert-warning">
					{{ $t('admin.forDisplayHelpSelectGroup') }}
				</div>
			</div>
		</div>
	</card>
</template>

<script>
import { Option as ElOption, Select as ElSelect, Input as ElInput, Alert as ElAlert } from 'element-ui';
import { mapState } from 'vuex';

import FormMixin from '@/util/form-mixin';
import ValidationMixin from '@/util/validation-mixin';
import Validations from '@/util/validations';
import { MailTemplateDestination, MailTemplateFromStatus, MailTemplateGroup, MailTemplateToStatus } from '@/modules/admin/store/enums';
import EshopList from '@/modules/admin/views/MessageTemplate/EshopList.vue';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
import { setTemplateData } from '@/modules/admin/store/mutation-types';
import { formChanged } from '@/store/mutation-types';

export default {
	name: 'TemplateEdit',
	components: {
		ElSelect,
		ElOption,
		ElInput,
		EshopList,
		ElAlert,
	},
	mixins: [FormMixin, ValidationMixin],

	data() {
		return {
			MailTemplateFromStatus,
			MailTemplateToStatus,
			MailTemplateDestination,
			MailTemplateGroup,
			loading: false,
			helpLoading: false,
			isSms: false,
			resultMessage: {
				show: false,
				successMessage: null,
				errorMessages: [],
			},
			isValidForSaving: false,
			isValidForLoading: false,
			preview: null,
			replacements: '',
			validations: {
				fromStatus: {
					name: 'fromStatus',
					rules: Validations.requiredField,
				},
				toStatus: {
					name: 'toStatus',
					rules: Validations.requiredField,
				},
				destination: {
					name: 'destination',
					rules: Validations.requiredField,
				},
				group: {
					name: 'group',
					rules: Validations.requiredField,
				},
				content: {
					name: 'content',
					rules: Validations.requiredField,
				},
			},
			formData: {},
		};
	},
	computed: {
		...mapState(appName, ['templateData']),
		isValidForValidation() {
			return !this.loading && this.isValidForLoading && this.isValidForSaving;
		},
	},
	watch: {
		formData: {
			deep: true,
			handler(templateData) {
				this.$store.commit(`${appName}/${setTemplateData}`, { templateData });
			},
		},
		isSms() {
			this.loadReplacements();
			if (this.isValidForLoading) {
				this.loadTemplateData();
			}
		},
		preview(value) {
			this.$refs.previewArea.contentWindow.document.body.innerHTML = value?.result || '';
		},
		isValidForSaving(value) {
			if (value) {
				this.$store.commit(`${appName}/${formChanged}`, true);
			}
		},
	},
	created() {
		this.formData = this.templateData;
		this.validateAndLoadReplacements();
	},
	methods: {
		validateAndLoadReplacements() {
			this.validateFormForLoading();
			this.loadReplacements();
		},
		async loadReplacements() {
			this.helpLoading = true;

			if (this.formData.group?.name) {
				const replacementsData = await this.$service.template.replacements(this.formData.group.name, this.isSms);

				this.replacements = Object.values(replacementsData).join('\n');
			} else {
				this.replacements = null;
			}

			this.helpLoading = false;
		},
		setEshopId(eshopId) {
			this.formData.eshopId = eshopId;
			this.validateFormForLoading();
		},
		eshopLoading(loading) {
			this.loading = loading;
		},
		validateFormForLoading() {
			this.isValidForSaving = false;
			this.isValidForLoading = Boolean(this.formData.fromStatus && this.formData.toStatus && this.formData.destination && this.formData.group);
			this.clearResultMessages();
		},
		getData() {
			return {
				fromStatus: this.formData.fromStatus.name,
				toStatus: this.formData.toStatus.name,
				destination: this.formData.destination.name,
				group: this.formData.group.name,
				specificEshopId: this.formData.eshopId ? Number(this.formData.eshopId) : null,
				language: this.formData.language || null,
				template: this.formData.content || '',
			};
		},
		async sendRequest(isValid, cb) {
			if (!isValid) {
				return;
			}

			try {
				this.loading = true;

				await cb();
			} catch (e) {
				const failedResults = e.data ? e.data.wrongPlaceholders : [e.toString()];
				this.setResultMessage(true, null, failedResults);
			} finally {
				this.loading = false;
			}
		},
		validate() {
			this.clearResultMessages();

			this.sendRequest(this.isValidForValidation, async () => {
				const data = await this.$service.template.validate(this.getData(), this.isSms);

				this.preview = data.isValid !== false ? data : null;
				this.isValidForSaving = data.isValid !== false;
			});
		},
		loadTemplateData() {
			this.validateFormForLoading();

			this.sendRequest(this.isValidForLoading, async () => {
				this.formData.content = await this.$service.template.getOne(this.getData(), this.isSms);
				this.preview = null;
				this.isValidForSaving = true;
			});
		},
		save() {
			this.clearResultMessages();

			this.sendRequest(this.isValidForSaving, async () => {
				const data = await this.$service.template.update(this.getData(), this.isSms);

				if (data.isValid) {
					this.preview = data;

					this.setResultMessage(true, this.$t('admin.templateSaved'));
					this.$store.commit(`${appName}/${formChanged}`, false);
				}
			});
		},
		setResultMessage(show = false, successMessage = null, errorMessages = []) {
			this.resultMessage.show = show;
			this.resultMessage.successMessage = successMessage;
			this.resultMessage.errorMessages = errorMessages;
		},
		clearResultMessages() {
			this.resultMessage.successMessage = null;
			this.resultMessage.errorMessages = [];
		},
	},
};
</script>

<style lang="scss" scoped>
.button-load_template {
	margin-top: -70px;
	margin-bottom: 25px;
	position: relative;
}
.preview {
	height: 18rem;
	overflow: auto;
	iframe {
		border: none;
		display: block;
		width: 100%;
		height: 100%;
	}
}
::v-deep .text-monospace textarea {
	font-family: monospace;
	font-size: 0.75rem;
}
</style>
