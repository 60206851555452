import { omit, pick } from 'lodash';

import { convertKeysFirstLetterToUppercase } from '@/util/helpers';

export const DeliveryPointService = (http) => ({
	async getList(filter, page = 1, pageSize = 10) {
		const params = {
			Page: page,
			PageSize: pageSize,
			...filter,
		};

		delete params.serialNumber;
		const { serialNumber } = filter;

		if (serialNumber?.from) {
			params['SerialNumber.From'] = serialNumber.from;
		}

		if (serialNumber?.to) {
			params['SerialNumber.To'] = serialNumber.to;
		}

		const response = await http.get('/api/Container/List', {
			params: convertKeysFirstLetterToUppercase(params),
		});

		return response.data;
	},

	async searchByLocationInput(input, searchFieldName, filter, abortController) {
		const locations = {
			State: filter.state,
			City: filter.city,
			Region: filter.region,
			County: filter.county,
		};
		let locationParams = null;
		switch (searchFieldName) {
			case 'Regions':
				locationParams = pick(locations, ['State']);
				break;
			case 'Counties':
				locationParams = pick(locations, ['State', 'Region']);
				break;
			case 'Cities':
				locationParams = omit(locations, ['City']);
				break;
			case 'Streets':
				locationParams = locations;
				break;
			default:
				locationParams = null;
		}
		const results = [];
		const response = await http.get(`/api/Lists/Container${searchFieldName}`, {
			signal: abortController?.signal,
			params: {
				Contains: input,
				...locationParams,
			},
		});
		response.data.forEach((el) => {
			results.push({ value: el });
		});
		return results;
	},
});
