import http from '@/service/http';

export default {
	mounted() {
		this.loadContainersForSelect();
	},
	methods: {
		async getContainers(page) {
			const response = await http.get('/api/Container/List/ForSelect', {
				timeout: 5000,
				params: {
					page,
					pageSize: 400,
				},
			});
			return response.data;
		},
		async getContainersForSelect() {
			let lastPage = 0;
			let pagesCount = 1;
			const containers = [];
			while (lastPage < pagesCount) {
				lastPage += 1;
				// eslint-disable-next-line no-await-in-loop
				const result = await this.getContainers(lastPage);
				pagesCount = result.pagesCount;
				containers.push(...result.entities);
			}
			return containers;
		},
		async loadContainersForSelect() {
			this.loadingContainerForSelect = true;
			const { currentAppName } = this.$store.state;
			if (!this.containersForSelect.length) {
				this.$store.commit(`${currentAppName}/addContainersForSelect`, await this.getContainersForSelect());
			}
			this.loadingContainerForSelect = false;
		},
	},
};
