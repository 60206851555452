<template>
	<div v-if="userId" class="row">
		<div class="col-md-8">
			<card>
				<h3 slot="header" class="card-title mb-0">
					{{ $t('eshop.userReservationList') }}
				</h3>
				<div class="row">
					<div class="col-3">
						<form-group-input :label="$t('eshop.monthAndYear')">
							<date-picker
								v-model="reservationFilter.monthYear"
								type="month"
								format="MMMM yyyy"
								prefix-icon="el-icon-date"
								:clearable="false"
								@change="loadData" />
						</form-group-input>
					</div>
				</div>

				<div v-if="loading || !daysInCurrentMonth" class="alert alert-info">
					{{ $t('eshop.loadingData') }}
				</div>
				<div v-else class="panel table-responsive">
					<table v-if="isDataReady" class="table table-bordered mb-0 mt-2">
						<thead>
							<tr>
								<th />
								<th v-for="day in daysInCurrentMonth" :key="`header_${day}`">
									{{ day }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									{{ $t('eshop.countOfReservations') }}
								</td>
								<td v-for="day in daysInCurrentMonth" :key="`body_reservations_${day}`">
									{{ getCountReservations(day, statisticData[0] ? statisticData[0].dayCounts : []) }}
								</td>
							</tr>
							<tr>
								<td>
									{{ $t('eshop.countOfPiecesOfGoods') }}
								</td>
								<td v-for="day in daysInCurrentMonth" :key="`body_packages_${day}`">
									{{ getCountPackages(day, statisticData[0] ? statisticData[0].dayCounts : []) }}
								</td>
							</tr>
						</tbody>
					</table>
					<div v-else class="alert alert-danger">
						{{ $t('eshop.dataReadError') }}
					</div>
				</div>
			</card>
		</div>
	</div>
</template>

<script>
import { DatePicker } from 'element-ui';
import { getMonthDays } from 'element-ui/src/utils/date-util';

import notifyService from '@/service/notify-service';

export default {
	name: 'EmployeeReservationsOverview',
	components: { DatePicker },
	data() {
		return {
			userId: null,
			statisticData: null,
			loading: false,
			reservationFilter: {
				monthYear: new Date(),
			},
		};
	},
	computed: {
		isDataReady() {
			return Array.isArray(this.statisticData);
		},
		daysInCurrentMonth() {
			const { monthYear } = this.reservationFilter;

			return monthYear ? getMonthDays(monthYear) : null;
		},
	},
	created() {
		this.userId = this.$route.params.id;
		this.loadData();
	},
	methods: {
		async loadData() {
			this.loading = true;

			if (this.userId) {
				const { monthYear } = this.reservationFilter;

				if (monthYear) {
					this.statisticData = await this.$service.reservation.createByUserStatic(monthYear.getFullYear(), monthYear.getMonth() + 1, this.userId);
				} else {
					notifyService.notifyError(this.$t('eshop.dateRequired'));
				}
			} else {
				notifyService.notifyError(this.$t('eshop.userIdRequired'));
			}

			this.loading = false;
		},
		getCountReservations(day, data) {
			return data.find((item) => item.day === day)?.count || 0;
		},
		getCountPackages(day, data) {
			return data.find((item) => item.day === day)?.amount || 0;
		},
	},
};
</script>

<style lang="scss" scoped>
.panel.table-responsive {
	overflow: auto;

	.table {
		border: 1px solid #dee2e6;
	}
}

.el-date-picker__only-month {
	.el-date-picker__header {
	}

	display: none;
}
</style>
