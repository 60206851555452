<template>
	<div v-loading="loading">
		<h2 class="card-title">
			{{ $t('global.updateReservation') }} {{ reservationId }}
		</h2>

		<form>
			<form-group-input :label="$t('global.occupiedFrom')" disabled>
				<date-picker
					:value="reservedFrom | datetime"
					type="datetime"
					prefix-icon="el-icon-date"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					readonly />
			</form-group-input>
			<form-group-input :label="$t('global.subscriptionEnd')" disabled>
				<date-picker
					:value="reservedTo | datetime"
					type="datetime"
					prefix-icon="el-icon-date"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm"
					readonly />
			</form-group-input>
			<form-group-input :label="$t('global.changeDurationOfReservation')" disabled>
				<date-picker
					v-model="updateData.reserveTo"
					v-validate="validations.reserveTo.rules"
					:name="validations.reserveTo.name"
					:error="getError(validations.reserveTo.name)"
					type="datetime"
					prefix-icon="el-icon-date"
					value-format="dd.MM.yyyy HH:mm"
					format="dd.MM.yyyy HH:mm" />
			</form-group-input>
			<form-group-input
				v-if="allowEditAddress"
				:label="$t('global.customerAddress')"
				:value="customerOldAddress"
				readonly />
			<fieldset class="form-group border p-3">
				<legend class="w-auto px-2 mb-n2 control-label">
					{{ $t('global.updateCustomerAddress') }}
				</legend>
				<div class="row">
					<div class="col-sm-6">
						<form-group-input v-if="allowEditAddress" :label="$t('global.country')" disabled>
							<el-input v-model="updateData.customer.state">
								<span slot="append" class="fa fa-address-card" />
							</el-input>
						</form-group-input>
						<form-group-input v-if="allowEditAddress" :label="$t('global.county')" disabled>
							<el-input v-model="updateData.customer.county">
								<span slot="append" class="fa fa-address-card" />
							</el-input>
						</form-group-input>
						<form-group-input v-if="allowEditAddress" :label="$t('global.zip')" disabled>
							<el-input v-model="updateData.customer.zip">
								<span slot="append" class="fa fa-address-card" />
							</el-input>
						</form-group-input>
						<form-group-input :label="$t('global.email')" disabled>
							<el-input v-model="updateData.customer.email">
								<span slot="append" class="fa fa-at" />
							</el-input>
						</form-group-input>
					</div>
					<div class="col-sm-6">
						<form-group-input v-if="allowEditAddress" :label="$t('global.region')" disabled>
							<el-input v-model="updateData.customer.region">
								<span slot="append" class="fa fa-address-card" />
							</el-input>
						</form-group-input>
						<form-group-input v-if="allowEditAddress" :label="$t('global.city')" disabled>
							<el-input v-model="updateData.customer.city">
								<span slot="append" class="fa fa-address-card" />
							</el-input>
						</form-group-input>
						<form-group-input v-if="allowEditAddress" :label="$t('global.street')" disabled>
							<el-input v-model="updateData.customer.street">
								<span slot="append" class="fa fa-address-card" />
							</el-input>
						</form-group-input>
						<form-group-input :label="$t('global.phone')" disabled>
							<el-input v-model="updateData.customer.phone">
								<span slot="append" class="fa fa-phone" />
							</el-input>
						</form-group-input>
					</div>
				</div>
			</fieldset>
			<confirm-button
				class="pull-right"
				:show-success-message="false"
				:confirm-message="$t('global.reservationUpdate')"
				:disabled="!updateData.reserveTo"
				:callback="validateAndSave">
				{{ $t('global.save') }}
			</confirm-button>
			<div class="clearfix" />
		</form>
	</div>
</template>

<script>
import { DatePicker, Input as ElInput } from 'element-ui';
import moment from 'moment';

import ValidationMixin from '@/util/validated-form-mixin';
import Validations from '@/util/validations';
import { LOCAL_DATE_FORMAT } from '@/filters';
import { ConfirmButton } from '@/components/UIComponents';
import FormMixin from '@/util/form-mixin';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
import { formChanged } from '@/store/mutation-types';

export default {
	name: 'BaseUpdateReservation',
	components: {
		DatePicker,
		ElInput,
		ConfirmButton,
	},
	mixins: [ValidationMixin, FormMixin],
	props: {
		reservationId: {
			type: Number,
			default: null,
			description: 'reservation ID',
		},
		reservedFrom: {
			type: String,
			default: null,
			description: 'datetime of reservation',
		},
		reservedTo: {
			type: String,
			default: null,
			description: 'end of reservation datetime',
		},
		customerOldAddress: {
			type: String,
			default: null,
			description: 'customer old address',
		},
		customer: {
			type: Object,
			default: () => {},
			description: 'customer data',
		},
		allowEditAddress: {
			type: Boolean,
			default: true,
			description: 'allow edit address',
		},
	},
	data() {
		return {
			loading: false,
			updateData: {
				reserveTo: this.$options.filters.datetime(this.reservedTo),
				customer: {
					street: null,
					region: null,
					state: null,
					zip: null,
					county: null,
					city: null,
					...this.customer,
				},
			},
			validations: {
				reserveTo: {
					name: 'reserveTo',
					rules: Validations.requiredField,
				},
			},
		};
	},
	mounted() {
		this.$store.commit(`${appName}/${formChanged}`, true);
	},
	methods: {
		async save() {
			this.loading = true;

			const preserveTo = moment(this.updateData.reserveTo, LOCAL_DATE_FORMAT).utc().format();
			await this.$service.reservation.updateReservation(preserveTo, this.reservationId, this.updateData.customer, this.customerOldAddress).finally(() => {
				this.loading = false;
			});
			this.$store.commit(`${appName}/${formChanged}`, false);

			this.$emit('refresh-reservation');
		},
	},
};
</script>

<style lang="scss" scoped>
.card-title {
	color: #2c2c2c;
}
.form-group legend {
	font-size: 0.9rem;
}
</style>
