<script>
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, SEZNAM_API_KEY } from './constants';
import { debounce } from './debounce';

export default {
	name: 'MapyCZStaticMap',
	props: {
		center: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			mapContainer: null,
			mapContainerWidth: null,
			resizeObserver: null,
			defaultHeight: DEFAULT_HEIGHT,
		};
	},
	computed: {
		seznamMap() {
			if (!this.center) {
				return null;
			}

			return {
				// eslint-disable-next-line max-len
				imgUrl: `https://api.mapy.cz/v1/static/map?lon=${this.center.longitude}&lat=${this.center.latitude}&zoom=17&width=${this.mapContainerWidth || DEFAULT_WIDTH}&height=${DEFAULT_HEIGHT}&mapset=outdoor&markers=color:red;size:normal;${this.center.longitude},${this.center.latitude}&apikey=${SEZNAM_API_KEY}`,
				linkUrl: `https://mapy.cz/fnc/v1/showmap?mapset=outdoor&center=${this.center.longitude},${this.center.latitude}&zoom=17&marker=true`,
			};
		},
	},
	mounted() {
		this.mapContainer = this.$refs.mapContainer;
		this.initializeResizeObserver();
	},
	beforeDestroy() {
		if (this.resizeObserver && this.$refs.mapContainer) {
			this.resizeObserver.unobserve(this.$refs.mapContainer);
		}
	},
	methods: {
		getMapContainerWidth() {
			if (this.$refs.mapContainer) {
				this.mapContainerWidth = this.$refs.mapContainer.offsetWidth;
			}
		},
		initializeResizeObserver() {
			this.getMapContainerWidth();
			if (this.$refs.mapContainer) {
				const debouncedCb = debounce(this.getMapContainerWidth, 100);
				this.resizeObserver = new ResizeObserver(debouncedCb);
				this.resizeObserver.observe(this.$refs.mapContainer);
			}
		},
	},
};
</script>

<template>
	<div
		v-if="seznamMap"
		ref="mapContainer"
		class="container">
		<img
			width="100%"
			:height="defaultHeight"
			:src="seznamMap.imgUrl"
			alt="Mapa">

		<div>
			<a :href="seznamMap.linkUrl" target="_blank">
				Otevřít na mapy.cz
			</a>
		</div>
	</div>
	<div v-else>
		Mapu se nepodařilo načíst
	</div>
</template>

<style scoped lang="scss">
.container {
  width: 100%;
}
</style>
