import config from '../config';

export const getDefaultState = () => ({
	homePage: 'delivery-list',
	clientId: 'adminApp',
	scopeName: 'LogisticScope',
	sidebarUserLinks: config.sidebarUserLinks,
	appsToLogin: [],
	refreshTokenLoading: false,
	currentUser: {
		name: '',
		surname: '',
		email: '',
		roles: [],
	},
	userFilter: {
		locked: null,
	},
	formChanged: false,
	loginError: null,
	versionInfo: null,
	reservationFilter: {
		isExpired: null,
		region: null,
		county: null,
		city: null,
		street: null,
		Status: null,
		isPostPackage: null,
		ReservationNumber: null,
		Phone: null,
		From: null,
		To: null,
		Email: null,
		orderBy: null,
		direction: null,
		reservationSelects: [],
	},
	reportFilter: {
		name: null,
		note: null,
		isActive: true,
		containers: [],
		orderBy: 'createdDate',
		direction: 'descending',
	},
	postFilter: {
		Status: null,
		intervalFrom: null,
		intervalTo: null,
		ReservationNumberLike: null,
		orderBy: 'inserted',
		direction: 'descending',
		SerialNumber: null,
		type: 'All',
	},
});

export const state = getDefaultState();
