const oneYearInHours = 8784;

export default {
	required: {
		required: true,
	},
	sku: {
		between: { min: 0, max: 9999999999999 },
	},
	requiredInteger: {
		required: true,
		integer: true,
	},
	requiredEmail: {
		required: true,
		email: true,
	},
	requiredPhone: {
		required: true,
		phone: true,
	},
	phoneRule: {
		phone: true,
	},
	requiredTextOnlyField: {
		required: true,
		alpha_spaces: true,
	},
	requiredField: {
		required: true,
	},
	requiredTextSpaceHyphenField: {
		required: true,
		// eslint-disable-next-line no-useless-escape
		regex: /^[ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮľĽĹĺäÄôÔA-Za-z\s\-]+$/,
	},
	requiredTextNumSpaceHyphenField: {
		required: true,
		// eslint-disable-next-line no-useless-escape
		regex: /^[ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮľĽĹĺäÄôÔ0-9A-Za-z\s\-]+$/,
	},
	notRequiredTextNumSpaceHyphenField: {
		required: false,
		// eslint-disable-next-line no-useless-escape
		regex: /^[ěščřžýáíéóúůďťňĎŇŤŠČŘŽÝÁÍÉÚŮľĽĹĺäÄôÔ0-9A-Za-z\s\-]+$/,
	},
	requiredNumDivider: {
		required: true,
		regex: /^\d+(\.\d{1,2})?$/,
	},
	coordinates: {
		required: false,
		regex: /^([0-9.°'"\s]+[N],[0-9.°'"\s]+[E])$|^([0-9.°'"\s]+[E],[0-9.°'"\s]+[N])$/,
		min: 21,
		// TODO: 28?
		max: 32,
	},
	requiredNumber: {
		required: true,
		integer: true,
	},
	requiredSerialNumber: {
		required: true,
		integer: true,
		between: {
			min: 0,
			max: 10000000000000000,
		},
	},
	requiredLatitude: {
		required: true,
		integer: true,
		between: {
			min: 0,
			max: 89,
		},
	},
	requiredLongitude: {
		required: true,
		integer: true,
		between: {
			min: 0,
			max: 179,
		},
	},
	requiredGpsMinutes: {
		required: true,
		integer: true,
		between: {
			min: 0,
			max: 59,
		},
	},
	requiredGpsSeconds: {
		required: true,
		between: {
			min: 0,
			max: 60,
		},
		excluded: 60,
	},
	emailRules: {
		required: true,
		email: true,
	},
	requiredHours: {
		required: true,
		between: {
			min: 0,
			max: 24,
		},
	},
	passwordRules: {
		required: true,
		min: 5,
	},
	passwordConfirmationRules: {
		required: true,
		confirmed: 'passwordToBeConfirmed',
	},
	// expected usage:
	// A combination of objects
	// eg. { ...Validations.required, ...Validations.decimal, ...Validations.inRange }
	integer: {
		integer: true,
	},
	decimal: {
		decimal: { decimals: 2 },
	},
	inRange: {
		between: { min: 0, max: 999999 },
	},
	email: {
		email: true,
	},
	phone: {
		phone: true,
	},
	borrowLengthHours: {
		integer: true,
		between: { min: 1, max: oneYearInHours },
	},
	zip: {
		regex: /^\d{5}$|^\d{3}\s\d{2}$/,
	},
	reservedDays: {
		required: true,
		integer: true,
		between: { min: 1, max: 10 },
	},
	reservedHours: {
		required: true,
		integer: true,
		between: { min: 1, max: 240 },
	},
	PIN: {
		integer: true,
		between: {
			min: 10000,
			max: 99999,
		},
	},
};
