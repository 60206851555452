<template>
	<account-form-wrap :action-title="$t('global.send')" :title="$t('global.passwordRecovery')" @form-submit="reset">
		<ul v-if="!sendMailResult.succeeded">
			<li v-for="error in sendMailResult.errors" :key="error.code">
				{{ error.description }}
			</li>
		</ul>

		<p v-if="sendMailResult.succeeded">
			{{ $t('global.linkForPasswordRecoverySent') }}
		</p>

		<form-group-input v-model="form.email" :placeholder="$t('global.yourEmail')" addon-left-icon="fa fa-envelope" />

		<router-link :to="{ name: 'login' }">
			<span class="fa fa-angle-double-left" />
			{{ $t('global.login') }}
		</router-link>
	</account-form-wrap>
</template>

<script>
import AccountFormWrap from '@/components/UIComponents/NotLoggedIn/AccountFormWrap.vue';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import authService from '@/service/auth-service';

export default {
	name: 'BaseForgotPassword',
	components: {
		AccountFormWrap,
	},
	data() {
		return {
			form: {
				email: '',
			},
			sendMailResult: {},
		};
	},

	methods: {
		async reset() {
			this.sendMailResult = await authService.requestResetPassword(this.form);
		},
	},
};
</script>
