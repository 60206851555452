<template>
	<div v-loading="loading">
		<h4 class="mt-0">
			{{ $t('logistics.reservationDetails') }}
		</h4>
		<div class="reservationDetails">
			<div v-if="reservationToEdit.customerAddress">
				<b>{{ $t('logistics.addressEnteredByCustomer') }}: </b>{{ reservationToEdit.customerAddress }}
			</div>
			<div v-if="reservationToEdit.customerName">
				<b>{{ $t('logistics.firstName') }}: </b>{{ reservationToEdit.customerName }}
			</div>
			<div v-if="reservationToEdit.email">
				<b>{{ $t('logistics.email') }}: </b>{{ reservationToEdit.email }}
			</div>
			<div v-if="reservationToEdit.phone">
				<b>{{ $t('logistics.phone') }}: </b>{{ reservationToEdit.phone }}
			</div>
			<div v-if="reservation.eshop && reservation.eshop.name">
				<b>{{ $t('logistics.eshop') }}: </b> {{ reservation.eshop.name }}
			</div>
			<div v-if="reservation.trace && reservation.trace.created">
				<b>{{ $t('logistics.reservationCreated') }}: </b>{{ reservation.trace.created | datetime }}
			</div>
		</div>
		<h4>{{ $t('logistics.editDeliveryAddressData') }}</h4>
		<form>
			<form-group-input :error="getError(editedReservationValidation.city.name)" :label="$t('logistics.city')" required>
				<el-input
					v-model="editedReservation.address.city"
					v-validate="editedReservationValidation.city.rules"
					:data-vv-as="$t('logistics.city')"
					:name="editedReservationValidation.city.name">
					<span slot="append" class="fa fa-address-card" />
				</el-input>
			</form-group-input>
			<form-group-input :error="getError(editedReservationValidation.street.name)" :label="$t('logistics.streetAndNumber')" required>
				<el-input
					v-model="editedReservation.address.street"
					v-validate="editedReservationValidation.street.rules"
					:data-vv-as="$t('logistics.street')"
					:name="editedReservationValidation.street.name">
					<span slot="append" class="fa fa-address-card" />
				</el-input>
			</form-group-input>
			<form-group-input :error="getError(editedReservationValidation.zip.name)" :label="$t('logistics.zip')" required>
				<el-input
					v-model="editedReservation.address.zip"
					v-validate="editedReservationValidation.zip.rules"
					:data-vv-as="$t('logistics.zip')"
					:name="editedReservationValidation.zip.name">
					<span slot="append" class="fa fa-address-card" />
				</el-input>
			</form-group-input>
			<form-group-input
				v-model="coordinates"
				v-validate="editedReservationValidation.deliveryCoordinates.rules"
				:error="getError(editedReservationValidation.deliveryCoordinates.name)"
				:name="editedReservationValidation.deliveryCoordinates.name"
				:data-vv-as="$t('logistics.copyCoords')"
				:placeholder="$t('logistics.separateCoordsWithComma')"
				:label="$t('logistics.copyCoords')" />
			<div class="row">
				<div class="col-6">
					<label>{{ $t('logistics.latitude') }}</label>
					<span class="text-danger"> *</span>
					<div class="d-flex">
						<form-group-input
							v-model.number="editedReservation.latitude.degree"
							v-validate="editedReservationValidation.latitude.degree.rules"
							type="number"
							:error="getError(editedReservationValidation.latitude.degree.name)"
							:name="editedReservationValidation.latitude.degree.name"
							:data-vv-as="$t('logistics.degrees')"
							class="w-25"
							:disabled="!!coordinates"
							required />
						<span class="d-block mx-2">°</span>
						<form-group-input
							v-model.number="editedReservation.latitude.minutes"
							v-validate="editedReservationValidation.latitude.minutes.rules"
							type="number"
							:error="getError(editedReservationValidation.latitude.minutes.name)"
							:name="editedReservationValidation.latitude.minutes.name"
							:data-vv-as="$t('logistics.minutes')"
							class="w-25"
							:disabled="!!coordinates"
							required />
						<span class="d-block mx-2">'</span>
						<form-group-input
							v-model.number="editedReservation.latitude.seconds"
							v-validate="editedReservationValidation.latitude.seconds.rules"
							type="number"
							:error="getError(editedReservationValidation.latitude.seconds.name)"
							:name="editedReservationValidation.latitude.seconds.name"
							:data-vv-as="$t('logistics.seconds')"
							class="w-25"
							:disabled="!!coordinates"
							required />
						<span class="d-block mx-2">"</span>
					</div>
				</div>
				<div class="col-6">
					<label>{{ $t('logistics.longitude') }}</label>
					<span class="text-danger"> *</span>
					<div class="d-flex">
						<form-group-input
							v-model.number="editedReservation.longitude.degree"
							v-validate="editedReservationValidation.longitude.degree.rules"
							type="number"
							:error="getError(editedReservationValidation.longitude.degree.name)"
							:name="editedReservationValidation.longitude.degree.name"
							:data-vv-as="$t('logistics.degrees')"
							class="w-25"
							:disabled="!!coordinates"
							required />
						<span class="d-block mx-2">°</span>
						<form-group-input
							v-model.number="editedReservation.longitude.minutes"
							v-validate="editedReservationValidation.longitude.minutes.rules"
							type="number"
							:error="getError(editedReservationValidation.longitude.minutes.name)"
							:name="editedReservationValidation.longitude.minutes.name"
							:data-vv-as="$t('logistics.minutes')"
							class="w-25"
							:disabled="!!coordinates"
							required />
						<span class="d-block mx-2">'</span>
						<form-group-input
							v-model.number="editedReservation.longitude.seconds"
							v-validate="editedReservationValidation.longitude.seconds.rules"
							type="number"
							:error="getError(editedReservationValidation.longitude.seconds.name)"
							:name="editedReservationValidation.longitude.seconds.name"
							:data-vv-as="$t('logistics.seconds')"
							class="w-25"
							:disabled="!!coordinates"
							required />
						<span class="d-block mx-2">"</span>
					</div>
				</div>
			</div>
			<p-button :disabled="!canSave || saving" @click="saveEditedReservation">
				{{ $t('logistics.save') }}
			</p-button>
		</form>
	</div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import { Input as ElInput } from 'element-ui';

import ValidationMixin from '@/util/validated-form-mixin';
import FormMixin from '@/util/form-mixin';
import { convertCoordinatesToRad, convertCoordinatesToReal } from '@/util/container-mixin';
import Validations from '@/util/validations';
import { appName } from '@/modules/logistics/config';
import { formChanged } from '@/store/mutation-types';

export default {
	name: 'EditReservation',
	components: {
		ElInput,
	},
	mixins: [FormMixin, ValidationMixin],
	props: {
		reservationToEdit: { type: Object, required: true },
	},
	data() {
		return {
			saving: false,
			loading: false,
			coordinates: null,
			reservation: {},
			editedReservation: {
				address: {
					city: null,
					street: null,
					zip: null,
				},
				latitude: {
					degree: null,
					minutes: null,
					seconds: null,
				},
				longitude: {
					degree: null,
					minutes: null,
					seconds: null,
				},
			},
			editedReservationValidation: {
				city: {
					name: 'city',
					rules: Validations.requiredField,
				},
				street: {
					name: 'street',
					rules: Validations.requiredField,
				},
				zip: {
					name: 'zip',
					rules: { ...Validations.requiredField, ...Validations.zip },
				},
				latitude: {
					degree: {
						name: 'latitudeDegree',
						rules: Validations.requiredLatitude,
					},
					minutes: {
						name: 'latitudeMinutes',
						rules: Validations.requiredGpsMinutes,
					},
					seconds: {
						name: 'latitudeSeconds',
						rules: Validations.requiredGpsSeconds,
					},
				},
				longitude: {
					degree: {
						name: 'longitudeDegree',
						rules: Validations.requiredLongitude,
					},
					minutes: {
						name: 'longitudeMinutes',
						rules: Validations.requiredGpsMinutes,
					},
					seconds: {
						name: 'longitudeSeconds',
						rules: Validations.requiredGpsSeconds,
					},
				},
				deliveryCoordinates: {
					name: 'deliveryCoordinates',
					rules: Validations.coordinates,
				},
			},
		};
	},
	computed: {
		canSave() {
			return this.isValid;
		},
	},
	watch: {
		reservationToEdit: {
			handler() {
				this.init();
			},
		},
		coordinates(val) {
			if (val?.length > 21) {
				const coordsArray = val.split(',');
				coordsArray.forEach((coordinates) => {
					if (coordinates.includes('N')) {
						this.parseCoordinates(coordinates, 'latitude');
					} else if (coordinates.includes('E')) {
						this.parseCoordinates(coordinates, 'longitude');
					}
				});
			}
		},
	},
	async created() {
		this.init();
	},
	methods: {
		async init() {
			this.loading = true;
			this.coordinates = null;
			const { psc, city } = this.reservationToEdit.addressDetails;
			let { street } = this.reservationToEdit.addressDetails;
			if (!city || !street || !psc) {
				street = this.reservationToEdit.customerAddress;
			}
			this.reservation = await this.$service.reservation.getReservation(this.reservationToEdit.reservationId);
			this.editedReservation.address = { city, street, zip: psc };

			const latitude = this.reservationToEdit.addressDetails.latitude;
			if (latitude) {
				this.editedReservation.latitude = convertCoordinatesToRad(latitude);
			}
			const longitude = this.reservationToEdit.addressDetails.longitude;
			if (longitude) {
				this.editedReservation.longitude = convertCoordinatesToRad(longitude);
			}

			this.$store.commit(`${appName}/${formChanged}`, true);
			this.loading = false;
		},

		async saveEditedReservation() {
			this.saving = true;
			const { city, street, zip: psc } = this.editedReservation.address;
			const { degree: latitudeDegree, minutes: latitudeMinutes, seconds: latitudeSeconds } = this.editedReservation.latitude;
			const { degree: longitudeDegree, minutes: longitudeMinutes, seconds: longitudeSeconds } = this.editedReservation.longitude;
			const { reservationId } = this.reservationToEdit;
			const reservationToUpdate = {
				city,
				street,
				psc,
				reservationId,
				latitude: convertCoordinatesToReal(latitudeDegree, latitudeMinutes, latitudeSeconds),
				longitude: convertCoordinatesToReal(longitudeDegree, longitudeMinutes, longitudeSeconds),
			};
			await this.handleBackEndErrors(true, this.$service.reservation.updateAddress, reservationToUpdate);
			this.saving = false;
			this.coordinates = null;
			this.$store.commit(`${appName}/${formChanged}`, false);
			await this.$emit('page-reload');
		},
		parseCoordinates(coordinates, type) {
			const splitCoords = coordinates.split(/[°'"]/).map((el) => parseFloat(el.trim()));
			this.editedReservation[type].degree = splitCoords[0];
			this.editedReservation[type].minutes = splitCoords[1];
			this.editedReservation[type].seconds = splitCoords[2];
		},
	},
};
</script>

<style scoped>
.reservationDetails {
	font-size: 16px;
}
.reservationDetails div {
	margin-top: 5px;
}
</style>
