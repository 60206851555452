<template>
	<div
		v-show="active"
		:id="tabId"
		class="tab-pane fade"
		role="tabpanel"
		:aria-hidden="!active"
		:aria-labelledby="`step-${tabId}`"
		:class="{ 'active show': active }">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'WizardTab',
	inject: ['addTab', 'removeTab'],
	props: {
		label: {
			type: String,
			default: null,
			description: 'Wizard tab label (title)',
		},
		id: {
			type: String,
			default: null,
			description: 'wizard tab id',
		},
		beforeChange: {
			type: Function,
			default: () => null,
			description: 'Function to execute before switching to another tab. Can be Function<boolean> or Function<Promise<boolean>>',
		},
	},
	data() {
		return {
			active: false,
			tabId: '',
		};
	},
	mounted() {
		this.addTab(this);
	},
	destroyed() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
		this.removeTab(this);
	},
};
</script>
