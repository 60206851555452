<template>
	<div v-loading="loading">
		<h2 class="card-title">
			{{ $t('eshop.directSale') }} {{ reservationId }}
		</h2>

		<form>
			<form-group-input :label="$t('eshop.orderNumber')">
				<el-input v-model="reservationNumber" disabled name="reservationNumber" />
			</form-group-input>
			<form-group-input :error="getError(directSaleValidation.email.field)" :label="$t('eshop.email')" some-required>
				<el-input
					v-model="directSale.email"
					v-validate="directSaleValidation.email.rule"
					:name="directSaleValidation.email.field"
					:data-vv-as="$t('eshop.email')">
					<span slot="append" class="fa fa-at" />
				</el-input>
			</form-group-input>

			<form-group-input :error="getError(directSaleValidation.phone.field)" :label="$t('eshop.phone')" some-required>
				<el-input
					v-model="directSale.phone"
					v-validate="directSaleValidation.phone.rule"
					:name="directSaleValidation.phone.field"
					:data-vv-as="$t('eshop.phone')"
					@blur="formatPhone">
					<span slot="Telefon" class="fa fa-phone" />
				</el-input>
			</form-group-input>
			<form-group-input :error="getError(directSaleValidation.borrowLength.field)" :label="$t('eshop.borrowLength')">
				<el-input
					v-model.number="borrowLengthHours"
					v-validate="directSaleValidation.borrowLength.rule"
					type="number"
					:data-vv-as="$t('eshop.borrowLength')"
					:name="directSaleValidation.borrowLength.field" />
			</form-group-input>
			<div class="d-flex justify-content-between">
				<p-button type="danger" @click="cancel">
					Zrušit
				</p-button>
				<confirm-button
					:callback="validateAndSave"
					:confirm-message="$t('eshop.sellMessage')"
					:disabled="!canSell"
					:show-success-message="false">
					{{ $t('eshop.sell') }}
				</confirm-button>
			</div>
		</form>
		<hr>
		<p class="note text-muted">
			** {{ $t('eshop.fillAtLeastOneField') }}
		</p>
	</div>
</template>

<script>
import { Input as ElInput } from 'element-ui';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/max';

import Validation from '@/util/validations';
import ValidationForm from '@/util/validated-form-mixin';
import FormMixin from '@/util/form-mixin';
import { parseIncompletePhoneNumberWithNull } from '@/util/helpers';

export default {
	name: 'DirectSale',
	components: {
		ElInput,
	},
	mixins: [ValidationForm, FormMixin],
	props: {
		reservationId: {
			type: Number,
			default: null,
			description: 'reservation ID',
		},
		reservationNumber: {
			type: String,
			default: null,
			description: 'reservation number',
		},
	},
	data() {
		return {
			loading: false,
			borrowLengthHours: null,
			directSale: {},
			directSaleValidation: {
				email: {
					rule: Validation.email,
					field: 'email',
				},
				phone: {
					rule: Validation.phone,
					field: 'phone',
				},
				borrowLength: {
					rule: Validation.borrowLengthHours,
					field: 'borrowLength',
				},
			},
		};
	},
	computed: {
		canSell() {
			return (this.directSale.email || this.directSale.phone) && this.isValid;
		},
	},
	methods: {
		formatPhone() {
			// If parse is not called, format will return empty string on non-number input
			this.directSale.phone = formatIncompletePhoneNumber(parseIncompletePhoneNumberWithNull(this.directSale.phone), 'CZ');
		},

		async save() {
			this.loading = true;
			if (!this.directSale.phone) this.directSale.phone = null;
			if (!this.directSale.email) this.directSale.email = null;
			this.directSale.minutes = this.borrowLengthHours * 60;
			this.directSale.reservationNumber = this.reservationNumber;
			this.directSale.reservationId = this.reservationId;
			await this.$service.eshop.sellDirectItem(this.directSale);
			this.directSale = {};
			this.loading = false;
			this.$emit('save-direct-sale');
		},
		cancel() {
			this.directSale = {};
			this.$emit('cancel-direct-sale');
		},
	},
};
</script>

<style scoped>
.note {
	font-size: 80%;
}

.el-select .el-input.is-disabled .el-input__inner::placeholder {
	color: #636c72;
}

.card-title {
	color: #2c2c2c;
}
</style>
