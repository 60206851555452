import { render, staticRenderFns } from "./LogisticsForm.vue?vue&type=template&id=51cbda99"
import script from "./LogisticsForm.vue?vue&type=script&lang=js"
export * from "./LogisticsForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports