import { AdminService } from './admin-service';
import { BoxService } from './box-service';
import { ContainerTreeViewService } from './container-tree-view-service';
import { DeliveryPointService } from './delivery-point-service';
import { EshopService } from './eshop-service';
import { ListService } from './list-service';
import { LogService } from './log-service';
import { PriceMapService } from './price-map-service';
import { ReservationService } from './reservation-service';
import { SmartBoxService } from './smart_box-service';
import { TemplateService } from './template-service';
import { DefaultReplacementService } from './default-replacement-service';
import { SmartLockInfoService } from './smart-lock-info-service';

export default (http) => ({
	admin: AdminService(http),
	box: BoxService(http),
	deliveryPoint: DeliveryPointService(http),
	containerTreeView: ContainerTreeViewService(http),
	eshop: EshopService(http),
	list: ListService(http),
	log: LogService(http),
	priceMap: PriceMapService(http),
	reservation: ReservationService(http),
	smartBox: SmartBoxService(http),
	template: TemplateService(http),
	replacements: DefaultReplacementService(http),
	smartLockInfo: SmartLockInfoService(http),
});
