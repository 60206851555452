<template>
	<div class="card">
		<div class="card-body">
			<div class="d-flex justify-content-between">
				<h2 class="mb-2">
					{{ $t('admin.boxState') }}
				</h2>
				<p-button
					v-if="isSmartKeyBox"
					size="md"
					type="success"
					@click="switchAddBoxDialogVisible()">
					{{ $t('admin.addBox') }}
				</p-button>
			</div>
			<el-table
				class="table-striped"
				size="mini"
				:data="deliveryPoint.boxes"
				:row-class-name="tableRowClassName"
				@row-click="goToReservationDetail">
				<el-table-column key="1" :width="35">
					<template #default="scope">
						<el-tooltip :content="getStatusTooltip(scope.row.reservationStatus)" placement="top-start">
							<div>
								<i :class="getStatusIcon(scope.row.reservationStatus)" />
							</div>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column key="2" width="150">
					<template #header>
						<el-tooltip
							v-if="isSmartKeyBox"
							:content="$t('admin.lockAliasName')"
							placement="top-start">
							<span>{{ $t('admin.lockAliasName') }}</span>
						</el-tooltip>
						<el-tooltip v-else :content="$t('admin.manufacturerNumber')" placement="top-start">
							<span>{{ $t('admin.manufacturerNumber') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<span v-if="isSmartKeyBox">{{ getLockAliasName(scope.row) || "-" }}</span>
						<span v-else>{{ scope.row.serialNumber }}</span>
					</template>
				</el-table-column>
				<el-table-column key="3" property="boxType" header-align="center">
					<template #header>
						<el-tooltip :content="$t('admin.boxType')" placement="top-start">
							<span>{{ $t('admin.boxType') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ BoxType.czech(scope.row.boxType) }}
					</template>
				</el-table-column>
				<el-table-column v-if="(deliveryPoint.type === DeliveryPointType.PenguinBox.name)" key="44" align="center">
					<template #header>
						<el-tooltip :content="$t('admin.remoteOpen')" placement="top-start">
							<span>{{ $t('admin.remoteOpen') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<confirm-button
							size="sm"
							icon
							:callback="() => openRemote(scope.row)"
							:message-success="$t('admin.remoteOpenSuccess')"
							:message-error="$t('admin.remoteOpenError')"
							:confirm-message="$t('admin.remoteOpenQuestion')">
							<i class="fa fa-door-open fa-2x" />
						</confirm-button>
					</template>
				</el-table-column>
				<el-table-column v-if="!(deliveryPoint.type === DeliveryPointType.Store.name)" key="4" align="center">
					<template #header>
						<el-tooltip :content="$t('admin.status')" placement="top-start">
							<span>{{ $t('admin.status') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<i v-if="scope.row.failed" class="fa fa-exclamation-triangle fa-2x text-danger" />
						<i v-else-if="scope.row.opened" class="fa fa-door-open fa-2x text-danger" />
					</template>
				</el-table-column>
				<el-table-column v-if="!(deliveryPoint.type === DeliveryPointType.Store.name)" key="5" align="center">
					<template #header>
						<el-tooltip :content="$t('admin.allowed')" placement="top-start">
							<span>{{ $t('admin.allowed') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<el-tooltip :key="scope.row.isDisabled" :content="statusTooltip(scope.row)" placement="top-start">
							<confirm-button
								:key="scope.row.isDisabled"
								:type="statusType(scope.row)"
								size="sm"
								icon
								:callback="() => setStatus(scope.row)"
								:message-success="$t('admin.changeBoxStatusSuccess')"
								:message-error="$t('admin.changeBoxStatusError')"
								:confirm-message="$t('admin.changeBoxStatus')">
								<i :class="statusIcon(scope.row)" />
							</confirm-button>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column key="6" header-align="center">
					<template #header>
						<el-tooltip :content="$t('admin.occupiedFrom')" placement="top-start">
							<span>{{ $t('admin.occupiedFrom') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ scope.row.occupiedFrom | datetime }}
					</template>
				</el-table-column>
				<el-table-column key="7" header-align="center">
					<template #header>
						<el-tooltip :content="$t('admin.subscriptionEnd')" placement="top-start">
							<span>{{ $t('admin.subscriptionEnd') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<span v-if="scope.row.subscriptionEnd < lastDataReceived" class="fa fa-exclamation fg-danger" />
						{{ scope.row.subscriptionEnd | datetime }}
						<span v-if="scope.row.subscriptionEnd < lastDataReceived" class="fa fa-exclamation fg-danger" />
					</template>
				</el-table-column>
				<el-table-column key="8" property="email" header-align="center">
					<template #header>
						<el-tooltip :content="$t('admin.email')" placement="top-start">
							<span>{{ $t('admin.email') }}</span>
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column key="9" header-align="center">
					<template #header>
						<el-tooltip :content="$t('admin.phone')" placement="top-start">
							<span>{{ $t('admin.phone') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						{{ scope.row.phone | phone }}
					</template>
				</el-table-column>
				<el-table-column v-if="isSmartKeyBox" key="10" header-align="center">
					<template #header>
						<el-tooltip :content="$t('admin.availability')" placement="top-start">
							<span>{{ $t('admin.availability') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<div v-if="scope.row.allowedCustomerMail && !scope.row.publicByOwner" class="d-flex justify-content-center">
							<el-tooltip :content="$t('admin.isPrivate')" placement="top-start">
								<i class="fa fa-user-secret fa-2x" />
							</el-tooltip>
						</div>
						<div v-else class="d-flex justify-content-center">
							<el-tooltip :content="scope.row.publicByOwner ? $t('admin.isPublicByOwner') : $t('admin.isPublic')" placement="top-start">
								<i class="fa fa-city fa-2x" :class="{'public-green-color': scope.row.publicByOwner}" />
							</el-tooltip>
						</div>
					</template>
				</el-table-column>
				<el-table-column key="11" property="eshop" align="center">
					<template #header>
						<el-tooltip :content="$t('admin.eshop')" placement="top-start">
							<span>{{ $t('admin.eshop') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<div v-if="scope.row.boxType !== ClothesAndAccesories">
							<div v-if="scope.row.eshop">
								<div>{{ scope.row.eshop.name }}</div>
								<p-button
									type="danger"
									size="sm"
									icon
									round
									@click.stop="removeEshopClicked(scope.row.boxId, scope.row.eshop.id)">
									<i class="fa fa-minus" />
								</p-button>
							</div>
							<p-button
								v-else
								type="success"
								size="sm"
								icon
								round
								@click.stop="addEshopClicked(scope.row.boxId)">
								<i class="fa fa-plus" />
							</p-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column
					v-if="isSmartKeyBox"
					key="12"
					align="center"
					property="allowedCustomerMail">
					<template #header>
						<el-tooltip :content="$t('admin.authorizedUsers')" placement="top-start">
							<span>{{ $t('admin.authorizedUsers') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<div v-if="scope.row.boxType !== ClothesAndAccesories">
							<p-button @click.stop="showKeyOwnersDIalog(scope.row)">
								{{ $t('admin.edit') }}
							</p-button>
						</div>
					</template>
				</el-table-column>

				<el-table-column v-if="isSmartKeyBox" key="13" align="center">
					<template #header>
						<el-tooltip :content="$t('admin.createPickupReservationTooltip')" placement="top-start">
							<span>{{ $t('admin.createPickupReservation') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<p-button
							v-if="!scope.row.reservationId && scope.row.boxType === BoxType.ClothesAndAccesories.name"
							type="success"
							icon
							round
							size="sm"
							@click="createPickupReservation(scope.row.boxId)">
							<i class="fa fa-tshirt fa-2x" />
						</p-button>
					</template>
				</el-table-column>

				<el-table-column v-if="isSmartKeyBox" key="14" align="center">
					<template #header>
						<el-tooltip :content="$t('admin.removeBox')" placement="top-start">
							<span>{{ $t('admin.removeBox') }}</span>
						</el-tooltip>
					</template>
					<template #default="scope">
						<confirm-button
							type="danger"
							icon
							round
							size="sm"
							:confirm-message="$t('admin.removeBoxFromSmartKeyBox')"
							:message-success="$t('admin.removeBoxFromSmartKeyBoxSuccess')"
							:message-error="$t('admin.removeBoxFromSmartKeyBoxError')"
							:callback="() => removeBoxFromSmartKeyBox(scope.row.boxId)">
							<i class="fa fa-minus" />
						</confirm-button>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog
				:close-on-click-modal="false"
				:close-on-press-escape="false"
				:visible.sync="addEshopDialog"
				width="30%"
				@closed="addEshopDialogClosed">
				<div v-loading="loading">
					<h4 class="text-center">
						{{ $t('admin.selectEshop') }}
					</h4>
					<autocomplete
						v-model="selectedEshopName"
						trigger-on-focus
						class="w-75 mx-auto"
						search-field-name="EshopName"
						@fetch-suggestions="querySearchEshop"
						@select="handleEshopSelect" />
					<div class="d-flex justify-content-center mt-2">
						<p-checkbox v-model="forWholeDeliveryPoint">
							{{ performForWholeDeliveryPoint() }}
						</p-checkbox>
					</div>
					<div class="d-flex justify-content-around mt-5">
						<p-button :disabled="!selectedEshopName" type="success" @click="occupyBoxByEshop">
							{{ $t('admin.eshopAdd') }}
						</p-button>
						<p-button type="danger" @click="addEshopDialog = !addEshopDialog">
							{{ $t('admin.cancel') }}
						</p-button>
					</div>
				</div>
			</el-dialog>

			<el-dialog
				:close-on-click-modal="false"
				:close-on-press-escape="false"
				:visible.sync="removeEshopDialog"
				width="30%"
				@closed="removeEshopDialogClosed">
				<div v-loading="loading">
					<h4 class="text-center">
						{{ $t('admin.removeEshopFromBox') }}
					</h4>

					<div class="d-flex justify-content-center mt-2">
						<p-checkbox v-model="forWholeDeliveryPoint">
							{{ performForWholeDeliveryPoint() }}
						</p-checkbox>
					</div>
					<div class="d-flex justify-content-around mt-5">
						<p-button type="success" @click="occupyBoxByEshop">
							{{ $t('admin.eshopRemove') }}
						</p-button>
						<p-button type="danger" @click="addEshopDialog = !addEshopDialog">
							{{ $t('admin.cancel') }}
						</p-button>
					</div>
				</div>
			</el-dialog>

			<el-dialog
				:close-on-click-modal="false"
				:visible.sync="addBoxDialogVisible"
				width="30%"
				@closed="addBoxDialogClosed">
				<div v-loading="loading">
					<h4 class="text-center">
						{{ $t('admin.newBox') }}
					</h4>
					<form-group-input :label="$t('admin.boxType')" :placeholder="$t('admin.boxType')">
						<el-select
							v-model="boxToAdd.boxType"
							v-validate="validations.boxType.rules"
							class="select-default"
							:data-vv-as="$t('admin.boxType')"
							:name="validations.boxType.name"
							required>
							<el-option
								v-for="box in BoxType.enumValues"
								:key="box.id"
								:value="box.name"
								:label="box.czech()">
								{{ box.czech() }}
							</el-option>
						</el-select>
						<div v-if="getError(validations.boxType.name)" class="invalid-feedback d-block">
							{{ getError(validations.boxType.name) }}
						</div>
					</form-group-input>

					<label v-if="boxToAdd.boxType !== ClothesAndAccesories">
						{{ $t('admin.lock') }}
					</label>
					<el-select
						v-if="boxToAdd.boxType !== ClothesAndAccesories"
						v-model.number="boxToAdd.serialNumber"
						v-validate="validations.serialNumber.rules"
						:data-vv-as="$t('admin.lock')"
						:placeholder="$t('admin.insertLock')"
						:name="validations.serialNumber.name"
						class="w-100"
						:error="getError(validations.serialNumber.name)"
						clearable
						filterable
						value-key="ordinal">
						<el-option
							v-for="opt in smartBoxes"
							:key="opt.id"
							:label="opt.value"
							:value="opt.id" />
					</el-select>

					<div class="d-flex justify-content-around mt-5">
						<p-button
							:disabled="(boxToAdd.boxType !== ClothesAndAccesories && !boxToAdd.serialNumber) || !boxToAdd.boxType"
							type="success"
							@click="addBoxIntoSmartBox">
							{{ $t('admin.addBox') }}
						</p-button>
						<p-button type="danger" @click="switchAddBoxDialogVisible">
							{{ $t('admin.cancel') }}
						</p-button>
					</div>
				</div>
			</el-dialog>

			<el-dialog
				:close-on-click-modal="false"
				:close-on-press-escape="false"
				:visible.sync="showKeyOwnersDialog"
				width="40%"
				@closed="keyOwnerDialogClosed">
				<div v-loading="loading">
					<delivery-point-key-owners-dialog :box="selectedBox" :show-key-owners-dialog="showKeyOwnersDialog" @update-pma-keys="$emit('update-pma-keys')" />
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip, Dialog as ElDialog, Select as ElSelect, Option as ElOption } from 'element-ui';
import _ from 'lodash';
import moment from 'moment';

import { ReservationStatus, DeliveryPointType } from '@/modules/admin/store/enums';
import { BoxType } from '@/modules/eshop/store/enums';
import notifyService from '@/service/notify-service';
import Validations from '@/util/validations';
import DeliveryPointKeyOwnersDialog from '@/modules/admin/views/DeliveryPoint/DeliveryPointDetails/BoxesCard/DeliveryPointKeyOwnersDialog.vue';
import { ClothesAndAccesories } from '@/modules/eshop/store/constants';

export default {
	name: 'DeliveryPointBoxesCard',
	components: {
		DeliveryPointKeyOwnersDialog,
		ElTable,
		ElTableColumn,
		ElTooltip,
		ElDialog,
		ElSelect,
		ElOption,
	},
	props: {
		deliveryPoint: { type: Object, default: () => {} },
		smartBoxInfos: { type: Array, default: () => [] },
	},
	data() {
		return {
			boxToAdd: { boxType: null, serialNumber: null },
			addBoxDialogVisible: false,
			DeliveryPointType,
			BoxType,
			loading: false,
			eshops: [],
			smartBoxes: [],
			addEshopDialog: false,
			removeEshopDialog: false,
			originalEshopId: null,
			selectedBox: null,
			selectedEshopId: null,
			selectedEshopName: null,
			forWholeDeliveryPoint: false,
			showKeyOwnersDialog: false,
			ClothesAndAccesories,
			validations: {
				boxType: {
					name: 'boxType',
					rules: Validations.required,
				},
				serialNumber: {
					name: 'serialNumber',
					rules: Validations.requiredSerialNumber,
				},
			},
		};
	},
	computed: {
		lastDataReceived() {
			return this.deliveryPoint?.lastParameters?.date;
		},
		isSmartKeyBox() {
			return this.deliveryPoint?.type === DeliveryPointType.SmartKeyBox.name;
		},
	},
	async mounted() {
		this.getEshops();
		await this.getAllSmartBoxes();
	},
	created() {
		this.ClothesAndAccesories = ClothesAndAccesories;
	},
	methods: {
		showKeyOwnersDIalog(selectedBox) {
			this.selectedBox = selectedBox;
			this.showKeyOwnersDialog = !this.showKeyOwnersDialog;
		},
		performForWholeDeliveryPoint() {
			if (this.deliveryPoint.type === DeliveryPointType.PenguinBox.name) {
				return this.$t('admin.performForWholeContainer');
			}
			if (this.deliveryPoint.type === DeliveryPointType.Store.name) {
				return this.$t('admin.performForWholePickupPlace');
			}
			return this.$t('admin.performForWholeSmartBox');
		},

		async setStatus(box) {
			const result = await this.$service.box.setState(box.boxId, !box.isDisabled);
			box.isDisabled = result.isDisabled;
		},

		async openRemote(box) {
			await this.$service.box.openRemote(box.boxId);
		},

		statusIcon(box) {
			return box.isDisabled ? 'fa fa-times' : 'fa fa-check-square';
		},
		statusType(box) {
			return box.isDisabled ? 'danger' : 'success';
		},
		statusTooltip(box) {
			return box.isDisabled ? this.$t('admin.tooltips.enable') : this.$t('admin.tooltips.ban');
		},
		tableRowClassName(row) {
			if (row.row.reservationStatus) {
				return ReservationStatus.enumValueOf(row.row.reservationStatus) !== ReservationStatus.DISCARD && 'hover-cursor';
			}
			return null;
		},
		goToReservationDetail(row) {
			this.$emit('go-to-reservation-detail', row.reservationId);
		},
		getStatusTooltip(status) {
			return status ? ReservationStatus.czech(status) : this.$t('admin.tooltips.emptyBox');
		},
		getStatusIcon(status) {
			return status ? ReservationStatus.icon(status) : 'fas fa-box-open';
		},
		async querySearchEshop(input, cb) {
			let results;
			if (input) {
				results = this.eshops.filter((eshop) => eshop.value.toLowerCase().indexOf(input.toLowerCase()) !== -1);
			} else {
				results = this.eshops;
			}
			cb(results);
		},

		handleEshopSelect(eshop) {
			this.selectedEshopId = eshop.id;
		},
		addEshopClicked(boxId) {
			this.addEshopDialog = !this.addEshopDialog;
			this.selectedBoxId = boxId;
			this.originalEshopId = null;
		},
		removeEshopClicked(boxId, eshopId) {
			this.originalEshopId = eshopId;
			this.selectedBoxId = boxId;
			this.removeEshopDialog = true;
		},
		getError(fieldName) {
			return this.errors.first(fieldName);
		},
		removeEshopDialogClosed() {
			this.selectedBoxId = null;
			this.selectedEshopId = null;
			this.selectedEshopName = null;
			this.forWholeDeliveryPoint = false;
		},
		addEshopDialogClosed() {
			this.selectedBoxId = null;
			this.selectedEshopId = null;
			this.selectedEshopName = null;
			this.forWholeDeliveryPoint = false;
		},
		keyOwnerDialogClosed() {
			this.selectedBoxId = null;
			this.$emit('get-delivery-point');
		},
		addBoxDialogClosed() {
			this.boxToAdd.boxType = null;
			this.boxToAdd.serialNumber = null;
			this.$validator.reset();
		},
		async removeBoxFromEshop(selectedBoxId) {
			const result = await this.$service.deliveryPoint.occupyBox(selectedBoxId, null);
			if (result.status === 200) {
				this.$emit('get-delivery-point');
			}
		},

		async occupyBoxByEshop() {
			try {
				this.loading = true;

				if (this.forWholeDeliveryPoint) {
					const promises = this.deliveryPoint.boxes.map(async (box) => {
						if ((box.eshop && box.eshop.id === this.originalEshopId) || (!box.eshop && !this.originalEshopId)) {
							await this.$service.deliveryPoint.occupyBox(box.boxId, this.selectedEshopId);
						}
					});
					await Promise.all(promises);
				} else {
					await this.$service.deliveryPoint.occupyBox(this.selectedBoxId, this.selectedEshopId);
				}

				this.$emit('get-delivery-point');
				if (this.selectedEshopId) {
					notifyService.notifySuccess(this.$t('admin.assignEshopToBoxSuccess'));
				} else {
					notifyService.notifySuccess(this.$t('admin.removeEshopFromBoxSuccess'));
				}
				this.addEshopDialog = false;
				this.removeEshopDialog = false;
				this.originalEshopId = null;
			} catch (err) {
				notifyService.notifyError(this.$t('admin.assignEshopToBoxError'));
			} finally {
				this.loading = false;
			}
		},

		async createPickupReservation(selectedBoxId) {
			try {
				const { reservationId } = await this.$service.deliveryPoint.createPickupReservation(selectedBoxId);
				this.deliveryPoint.boxes.find((b) => b.boxId === selectedBoxId).reservationId = reservationId;
				this.$emit('get-delivery-point');

				notifyService.notifySuccess(this.$t('admin.createPickupReservationSuccess'));
			} catch (err) {
				notifyService.notifyError(this.$t('admin.createPickupReservationError'));
			}
		},

		async removeBoxFromSmartKeyBox(selectedBoxId) {
			let newBoxes = _.cloneDeep(this.deliveryPoint.boxes);
			newBoxes = newBoxes.filter((box) => box.boxId !== selectedBoxId);

			await this.$service.deliveryPoint.setBoxes(newBoxes, this.deliveryPoint.containerId);
			this.$emit('get-delivery-point');
		},

		async addBoxIntoSmartBox() {
			try {
				this.loading = true;

				const newBoxes = _.cloneDeep(this.deliveryPoint.boxes);
				if (this.boxToAdd.boxType === ClothesAndAccesories) {
					this.boxToAdd.serialNumber = -1;
				}
				newBoxes.push(this.boxToAdd);

				await this.$service.deliveryPoint.setBoxes(newBoxes, this.deliveryPoint.containerId);
				this.$emit('get-delivery-point');
				notifyService.notifySuccess(this.$t('admin.assignBoxToDeliveryPointSuccess'));
			} catch (err) {
				notifyService.notifyError(this.$t('admin.assignBoxToDeliveryPointError'));
			} finally {
				this.switchAddBoxDialogVisible();
				this.smartBoxes = [];
				this.getAllSmartBoxes();
				this.loading = false;
			}
		},

		async getEshops() {
			const list = await this.$service.eshop.getList(null, 1, 1000);
			list.entities.forEach((eshop) => {
				this.eshops.push({
					value: eshop.name,
					id: eshop.id,
				});
			});
		},

		async getAllSmartBoxes(page = 1) {
			const data = await this.$service.smartBox.getList({}, page, 1000);

			const formatDate = (date) => moment.utc(String(date)).local().format('DD.MM.YYYY');
			const formatSmartBox = (smartBox) => `${smartBox.lockAlias} / ${smartBox.factoryName} / ${smartBox.lockName} / ${formatDate(smartBox.addedDate)}`;

			(data?.list || []).forEach((smartBox) => {
				this.smartBoxes.push({
					value: formatSmartBox(smartBox),
					id: smartBox.lockId,
				});
			});

			if (data.pageNo < data.pages) {
				await this.getAllSmartBoxes(page + 1);
			}
		},

		switchAddBoxDialogVisible() {
			this.addBoxDialogVisible = !this.addBoxDialogVisible;
		},
		getLockAliasName(box) {
			if (box.boxType.indexOf('Clothes') >= 0) return this.$t('admin.clothes');

			const lock = this.smartBoxInfos.find((x) => x.lockId === box.serialNumber);

			return `${lock?.lockAlias || lock?.lockName || '-'} / ${lock?.factoryName || '-'}`;
		},
	},
};
</script>

<style>
.hover-cursor:hover {
	cursor: pointer;
}
.public-green-color {
	color: #00ae4c;
}
</style>
