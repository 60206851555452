<template>
	<base-user-list
		v-loading="loading"
		:users="users"
		show-filter
		detail-link="logistics-edit"
		new-link="logistics-new"
		show-assign-admin-column
		@get-page="loadData" />
</template>

<script>
import { BaseUserList } from '@/components/Base';

export default {
	name: 'LogisticsList',
	components: {
		BaseUserList,
	},
	data() {
		return {
			users: {},
			loading: false,
		};
	},
	methods: {
		async loadData(params) {
			this.loading = true;
			this.users = await this.$service.admin.getLogisticsList(params);
			this.loading = false;
		},
	},
};
</script>
