import { parseIncompletePhoneNumber } from 'libphonenumber-js/max';

import { appBaseUrlList, stateNames } from '@/globalConstants';

export function czechDecimalParser(value) {
	if (value.toString().includes(',')) {
		const commaToDot = value.toString().replace(',', '.');
		return Number(commaToDot);
	}
	return value;
}

export const isTelNumber = (event) => {
	const evt = event || window.event;
	const charCode = evt.which ? evt.which : evt.keyCode;
	return (charCode >= 48 && charCode <= 57) || charCode === 32 || charCode === 43 ? true : evt.preventDefault();
};

export const isInteger = (value) => /^-?\d+$/.test(value);

export const convertKeysFirstLetterToUppercase = (obj) => { // toto by mozna nemelo byt vubec treba
	const keyValues = Object.keys(obj).map((key) => {
		const newKey = key.charAt(0).toUpperCase() + key.slice(1);
		return { [newKey]: obj[key] };
	});

	return Object.assign({}, ...keyValues);
};

export const parseIncompletePhoneNumberWithNull = (value) => parseIncompletePhoneNumber(value || '');

export const translateWithNumber = (moduleName, baseLabelName, number) => {
	const parsedNumber = parseFloat(number);

	if (parsedNumber === 1) {
		return `${moduleName}.${baseLabelName}`;
	}

	if (parsedNumber > 0 && parsedNumber < 5) {
		return `${moduleName}.${baseLabelName}_dl`; // dual
	}

	return `${moduleName}.${baseLabelName}_pl`; // plural
};

export function is400ErrorOnLoginPage(status, baseAppBaseUrl, alternativeBaseAppBaseUrl) {
	const { pathname } = window.location;
	const currentAppBaseUrl = Object.values(appBaseUrlList).find((appBaseUrl) => pathname === appBaseUrl || pathname === `${appBaseUrl}/`);
	const isAlternativeBaseAppUrl = alternativeBaseAppBaseUrl === null
		? false
		: (alternativeBaseAppBaseUrl === pathname || pathname === alternativeBaseAppBaseUrl);
	const isBaseAppUrl = (baseAppBaseUrl === currentAppBaseUrl) || isAlternativeBaseAppUrl;

	return isBaseAppUrl && status === 400;
}

export function getAbbrState(state) {
	switch (state.toLowerCase()) {
		case stateNames.czechRepublic:
		case stateNames.czechia:
			return 'CZ';
		case stateNames.slovakRepublic:
		case stateNames.slovakia:
			return 'SK';
		default:
			return null;
	}
}
