<template>
	<div class="row">
		<div class="col-12">
			<base-stepper v-if="!emptyReservation" :trace="reservation.trace" />
		</div>
		<div class="col-md-8">
			<reservation-detail :reservation="reservation" @get-one="getOne" />
		</div>
		<div class="col-md-4">
			<el-tabs v-model="activeTab">
				<el-tab-pane :label="$t('logistics.container')" name="container-info">
					<container-info v-if="reservation.containerId" :reservation="reservation" />
				</el-tab-pane>
				<el-tab-pane :label="$t('logistics.note')" name="note">
					<reservation-note :reservation="reservation" />
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import { TabPane as ElTabPane, Tabs as ElTabs } from 'element-ui';

import { BaseStepper } from '@/components/Base';
import FormMixin from '@/util/form-mixin';
import ReservationNote from '@/components/Base/Shared/ReservationNote.vue';

import ReservationDetail from './ReservationDetails/ReservationDetail.vue';
import ContainerInfo from './ReservationDetails/ContainerInfo.vue';

export default {
	name: 'ReservationForm',
	components: {
		ReservationDetail,
		ContainerInfo,
		BaseStepper,
		ElTabs,
		ReservationNote,
		ElTabPane,
	},
	mixins: [FormMixin],

	data() {
		return {
			activeTab: 'note',
			reservation: {},
		};
	},

	computed: {
		emptyReservation() {
			return Object.keys(this.reservation).length === 0;
		},
	},

	created() {
		this.getOne();
	},

	methods: {
		async getOne() {
			this.reservation = await this.$service.reservation.getReservation(this.$route.params.id);
		},

		refreshReservation() {
			this.getOne();
		},

	},
};
</script>

<style lang="scss">
.text-monospace textarea {
	font-family: monospace;
	font-size: 0.75rem;
}
</style>
