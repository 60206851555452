import { mapValues } from 'lodash';

import { setCurrentApp, resetFilter, toggleSidebar, displaySidebar, setTheSpecialModal, setTheSpecialModalInputText } from './mutation-types';

export default {
	[setCurrentApp](state, appName) {
		state.currentAppName = appName;
	},

	[resetFilter](state, filterName) {
		state[filterName] = mapValues(state[filterName], (val) => (Array.isArray(val) ? [] : null));
	},

	[displaySidebar](state, show) {
		state.sidebar.showSidebar = show;
	},

	[toggleSidebar](state) {
		state.sidebar.isMinimized = !state.sidebar.isMinimized;
	},

	[setTheSpecialModal](state, data) {
		state.theSpecialModal = data;
	},
	[setTheSpecialModalInputText](state, text) {
		state.theSpecialModalInputText = text;
	},
};
