export const setCurrentApp = 'setCurrentApp';

export const authFailed = 'authFailed';
export const authSuccess = 'authSuccess';
export const loggedOut = 'loggedOut';
export const setCurrentUser = 'setCurrentUser';
export const setVersionInfo = 'setVersionInfo';
export const setRefreshTokenLoading = 'setRefreshTokenLoading';
export const setLoginActive = 'setLoginActive';

export const setFilter = 'setFilter';
export const resetFilter = 'resetFilter';
export const formChanged = 'formChanged';

export const addAppToLogin = 'addAppToLogin';
export const removeAppToLogin = 'removeAppToLogin';

export const addReservationForCollection = 'addReservationForCollection';
export const removeReservationForCollection = 'removeReservationForCollection';
export const cleanReservationsForCollection = 'cleanReservationsForCollection';
export const collectSoon = 'collectSoon';
export const collectLater = 'collectLater';

export const displaySidebar = 'displaySidebar';
export const toggleSidebar = 'toggleSidebar';

export const setTheSpecialModal = 'setTheSpecialModal';
export const setTheSpecialModalInputText = 'setTheSpecialModalInputText';
