import VeeValidate, { Validator } from 'vee-validate';
import veeLang from 'vee-validate/dist/locale/cs';
import locale from 'element-ui/lib/locale';
import lang from 'element-ui/lib/locale/lang/cs-CZ';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import Vue from 'vue';

export default function configureValidation() {
	Vue.use(VeeValidate, {
		fieldsBagName: 'fieldsbag', // 'fields collide with element ui'
	});

	Validator.localize('cs', veeLang);
	locale.use(lang);

	Validator.extend('phone', {
		validate(value) {
			const numberCZ = parsePhoneNumberFromString(value, 'CZ');
			const numberOther = parsePhoneNumberFromString(value);

			return Boolean((numberCZ && numberCZ.isValid()) || (numberOther && numberOther.isValid()));
		},
	});
}
