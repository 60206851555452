import { stringify } from 'qs';
import { isNil, omit, omitBy, pick } from 'lodash';

const API = '/api/';

export const BoxService = (http) => ({
	async setState(id, isDisabled) {
		const response = await http.post(`${API}Box/State`, { boxId: id, isDisabled });
		return response.data;
	},
	async getTypes() {
		const response = await http.get(`${API}Lists/BoxSizes`);
		return response.data;
	},

	async getList(page, filter, pageSize) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};
		const response = await http.get(`${API}Box/List`, config);
		return response.data;
	},

	async getBox(boxId) {
		const config = {
			params: { boxId },
			paramsSerializer: stringify,
		};
		const response = await http.get(`${API}Box/Detail`, config);
		return response.data;
	},

	async getStockItem(boxId) {
		const response = await http.get(`${API}Box/StockItem`, { params: { boxId } });
		return response.data;
	},

	async directSale(itemId, boxId, singleSale, category) {
		const response = await http.post(`${API}Box/DirectSale`, { itemId, category: category === '' ? null : category, singleSale, boxId });
		return response.data;
	},

	async directSaleToAllBoxesOfContainer(containerId, category) {
		const response = await http.post(`${API}container/SetDirectSaleCategory`, { containerId, category });
		return response.data;
	},

	async searchByLocationInput(input, searchFieldName, filter, abortController) {
		const locations = {
			State: filter.state,
			City: filter.city,
			Region: filter.region,
			County: filter.county,
		};
		let locationParams = null;
		switch (searchFieldName) {
			case 'Regions':
				locationParams = pick(locations, ['State']);
				break;
			case 'Counties':
				locationParams = pick(locations, ['State', 'Region']);
				break;
			case 'Cities':
				locationParams = omit(locations, ['City']);
				break;
			case 'Streets':
				locationParams = locations;
				break;
			default:
				locationParams = null;
		}
		const results = [];
		const response = await http.get(`${API}Lists/Container${searchFieldName}`, {
			signal: abortController?.signal,
			params: {
				Contains: input,
				...locationParams,
			},
		});
		response.data.forEach((el) => {
			results.push({ value: el });
		});
		return results;
	},
});
