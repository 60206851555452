import { MailNotifyService } from './mail-notify-service';
import { AdminService } from './admin-service';
import { ContainerTreeViewService } from './container-tree-view-service';
import { ListService } from './list-service';
import { LogisticsRouteService } from './logistics-route-service';
import { ReservationService } from './reservation-service';

export default (http) => ({
	admin: AdminService(http),
	containerTreeView: ContainerTreeViewService(http),
	list: ListService(http),
	logisticsRoute: LogisticsRouteService(http),
	reservation: ReservationService(http),
	mailNotifyService: MailNotifyService(http),
});
