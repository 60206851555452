import { render, staticRenderFns } from "./SidebarItem.vue?vue&type=template&id=4ac1b918&scoped=true"
import script from "./SidebarItem.vue?vue&type=script&lang=js"
export * from "./SidebarItem.vue?vue&type=script&lang=js"
import style0 from "./SidebarItem.vue?vue&type=style&index=0&id=4ac1b918&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac1b918",
  null
  
)

export default component.exports