<template>
	<div>
		<div
			v-for="(container, containerKey) in containersWithSortedReservation"
			:key="`container-${container.containerSn}-${containerKey}`"
			class="mt-3 table-responsive">
			<base-report-print-sheet-table nomargin>
				<thead>
					<tr>
						<th><span v-if="container.containerSn">{{ container.containerType | containerType }}</span> {{ container.containerSn }}</th>
						<th v-if="!container.containerId && !container.reservations.length" class="highlightBlank">
							{{ $t('driver.addressNotFilled') }}
						</th>
						<th v-else-if="!container.containerId">
							{{ getPrivateCustomerAddress(container) }}
						</th>
						<th v-else>
							{{ container | locationReport }}
						</th>
						<th v-if="container.latitude && container.longitude" :colspan="3">
							{{ container.latitude | coordinatesRad }}N, {{ container.longitude | coordinatesRad }}E
						</th>
						<th v-else :colspan="3" class="highlightBlank">
							{{ $t('driver.coordinatesNotFilled') }}
						</th>
						<th>
							<div v-if="showBatteryAlert(container) && container.containerType === 'PenguinBox'" class="alert-danger">
								<span v-if="container.isOffline && container.batteryVoltage > batteryThresholds.critical">{{ $t('driver.containerStuck') }}</span>
								<span v-else-if="container.batteryVoltage < batteryThresholds.critical">{{ $t('driver.containerOutOfBattery') }}</span>
								<span v-else-if="container.batteryVoltage < batteryThresholds.runningLow">{{ $t('driver.containerLowBattery') }}</span>
							</div>
							<div v-if="showBatteryAlert(container) && container.containerType === 'SmartKeyBox'" class="alert-danger">
								<span v-if="container.isOffline && container.batteryVoltage > smartBatteryThresholds.critical">{{ $t('driver.containerStuck') }}</span>
								<span v-else-if="container.batteryVoltage < smartBatteryThresholds.critical">{{ $t('driver.containerOutOfBattery') }}</span>
								<span v-else-if="container.batteryVoltage < smartBatteryThresholds.warning">{{ $t('driver.containerLowBattery') }}</span>
							</div>
							<div v-if="container.openingHours">
								<span v-if="container.openingHours.status === 'Closed'">
									{{ $t('driver.closed') }}
								</span>
								<span v-else-if="container.openingHours.openTime && !container.openingHours.closeTime">
									{{ $t('driver.open24hours') }}
								</span>
								<span v-else>
									{{ container.openingHours.openTime }}-{{ container.openingHours.closeTime }}
									<span v-if="container.openingHours.openTimeNext"> {{ container.openingHours.openTimeNext }}-{{ container.openingHours.closeTimeNext }} </span>
								</span>
							</div>
						</th>
						<th />
					</tr>
				</thead>

				<tbody v-if="container.reservations">
					<tr
						v-for="reservation in container.reservations"
						:key="`reservation-${reservation.reservationId}-${container.containerSn}`"
						:ref="`reservation-${reservation.reservationId}-${container.containerSn}`"
						:class="reservation.groupClass">
						<td>
							<span v-if="reservation.reservationNumber">
								{{ reservation.reservationNumber && reservation.reservationNumber.replaceAll(',', ', ') }}
							</span>
							<div v-if="reservation.stockItemSku">
								SKU: {{ reservation.stockItemSku }}
							</div>
						</td>
						<td>{{ getReservationName(reservation) }}</td>
						<td>{{ reservation.phone }}</td>
						<td>
							<span v-if="container.containerType !== 'SmartKeyBox'">PIN: <span class="text-pin">{{ reservation.unlockCode }}</span>
							</span>
							<span v-else>{{ reservation.lockName ? "BOX:" : "" }} {{ reservation.lockName }}</span>
						</td>
						<td>{{ reservation.packagesCount }} ks</td>
						<td v-if="ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.WAIT_FOR_DELIVERY" class="noBorder">
							<table>
								<tr>
									<td v-if="reservation.containerId">
										{{ $t('driver.deliveryState') }}
									</td>
									<td v-else>
										{{ $t('driver.deliveryStateAddress') }}
									</td>
								</tr>
								<tr>
									<td>{{ $t('driver.reason') }}:</td>
								</tr>
							</table>
						</td>
						<td v-else-if="ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.WAIT_FOR_COLLECT">
							{{ ' ' }}
						</td>
						<td v-else-if="isExpired(reservation)">
							{{ $t('driver.expiredRouteInfo') }}
						</td>
						<td v-else />
						<td class="text-center">
							<span v-if="ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.WAIT_FOR_DELIVERY" class="noBorder">
								<i
									v-if="compareRouteStatus(reservation, ReservationRouteStatus.CONFIRMED_BY_DRIVER)"
									:title="$t('driver.pickedUp')"
									class="fa fa-2x fa-car text-success" />
								<i
									v-else-if="compareRouteStatus(reservation, ReservationRouteStatus.ASSIGNED)"
									:title="$t('driver.notPickedUp')"
									class="fa fa-2x fa-home text-danger" />
							</span>
							<span v-else-if="isReadyForRouteStatus(reservation)" class="noBorder">
								<i
									:title="$t('driver.completed')"
									class="fa fa-2x fa-check text-success" />
							</span>
						</td>
					</tr>
					<tr
						v-for="(stockItem, stockItemKey) in container.stockItemsCounts"
						:key="`stock_item-${container.containerId}-${stockItem.item.id}-${stockItemKey}`"
						:class="stockItem.groupClass">
						<td>SKU: {{ stockItem.item.stockId }}</td>
						<td>{{ $t('driver.stockItemDelivery') }} {{ stockItem.item.name }}</td>
						<td colspan="3">
							min {{ stockItem.count }} {{ $t('driver.piece') }}
						</td>
						<td>{{ $t('driver.refill') }} ______ {{ $t('driver.piece') }}</td>
						<td>&nbsp;</td>
					</tr>
				</tbody>
			</base-report-print-sheet-table>
		</div>
	</div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { ReservationStatus, ReservationRouteStatus } from '@/modules/driver/store/enums';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import ReportDetailsMixin from '@/util/report-details-mixin';
import BaseReportPrintSheetTable from '@/components/Base/Report/BaseReportPrintSheetTable.vue';
import { batteryThresholds, smartBatteryThresholds } from '@/globalConstants';

export default {
	name: 'ContainersInfo',
	components: { BaseReportPrintSheetTable },
	mixins: [ReservationNameMixin, ReportDetailsMixin],
	props: {
		containers: {
			type: Array,
			default: () => [],
		},
		reportIsActive: {
			type: Boolean,
			required: true,
		},
		logisticRouteId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			ReservationRouteStatus,
			ReservationStatus,
			batteryThresholds,
			smartBatteryThresholds,
		};
	},
	computed: {
		containersWithSortedReservation() {
			return this.containers.map((container) => {
				const clonedContainer = cloneDeep(container);
				clonedContainer.reservations = this.reservationsSorted(container.reservations);
				clonedContainer.stockItemsCounts = this.setGroupClass(container.stockItemsCounts);

				return clonedContainer;
			});
		},
	},
	methods: {
		reservationsSorted(reservations) {
			const waitForDelivery = [];
			const expired = [];
			const waitForCollect = [];
			const picked = [];
			const discarded = [];
			const { WAIT_FOR_COLLECT, WAIT_FOR_DELIVERY, PICK_UP, DISCARD } = ReservationStatus;

			reservations.forEach((reservation) => {
				const r = cloneDeep(reservation);
				if (ReservationStatus.enumValueOf(reservation.status) === WAIT_FOR_DELIVERY) {
					r.group = 'delivery';
					waitForDelivery.push(r);
				} else if (ReservationStatus.enumValueOf(reservation.status) === WAIT_FOR_COLLECT) {
					r.group = 'collect';
					waitForCollect.push(r);
				} else if (this.isExpired(reservation)) {
					r.group = 'collect';
					expired.push(r);
				} else if (ReservationStatus.enumValueOf(reservation.status) === PICK_UP) {
					r.group = 'collect';
					picked.push(r);
				} else if (ReservationStatus.enumValueOf(reservation.status) === DISCARD) {
					r.group = 'collect';
					discarded.push(r);
				}
			});

			return this.setGroupClass(waitForCollect.concat(expired, waitForDelivery, picked, discarded));
		},
		setGroupClass(reservations) {
			return reservations.map((reservation) => {
				if (this.lastReservationGroup !== reservation.group) {
					this.lastReservationGroup = reservation.group;

					reservation.groupClass = 'group-border';
				} else {
					reservation.groupClass = '';
				}

				return reservation;
			});
		},
		compareRouteStatus({ reservationRouteStatus }, routeStatus) {
			return ReservationRouteStatus.enumValueOf(reservationRouteStatus) === routeStatus;
		},
		isReadyForRouteStatus(reservation) {
			if (ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.WAIT_FOR_DELIVERY) {
				return (
					ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.PICK_UP
            || ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.DISCARD
            || ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.PREPARED
            || ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.COLLECTED
            || this.isExpired(reservation)
				);
			}
			if (ReservationStatus.enumValueOf(reservation.status) === ReservationStatus.PREPARED) {
				return (
					ReservationStatus.enumValueOf(reservation.currentStatus) === ReservationStatus.COLLECTED
				);
			}
			return false;
		},
		getReservationName(reservation) {
			if (reservation && reservation.customerName && reservation.customerAddress) {
				reservation.customerAddress = reservation.customerAddress.replace(`${reservation.customerName}, `, '');
			}
			return this.reservationName(reservation);
		},
	},
};
</script>

<style lang="scss" scoped>
td .action {
  display: none;
}
tr:hover .action {
  display: inline;
}

.headerOfContainerTable {
  border-bottom: 3px solid black;
}
.noBorder {
  td {
    font-size: 9px;
    padding: 1px;
  }
  * {
    border: none;
  }
}
.qr_code-area {
  position: fixed;
  bottom: 0;
  margin-left: -15px;
  margin-bottom: 15px;
  width: 500px;
  max-width: 100%;
}
.text-big {
  font-size: 115%;
}

::v-deep .selected-box {
  animation: selected-box-animation 1s linear 1 1s;
  background: palegreen;
}

@keyframes selected-box-animation {
  from {
    background: red;
    color: white;
  }
  to {
    color: initial;
    background: palegreen;
  }
}
.availableReservations {
  min-width: 579px;
}
.group-border td {
  border-top-width: 2px;
}
.highlightBlank {
  background-color: #f5f588;
}
.mb-max {
  margin-bottom: 350px;
}

.table-responsive {
  overflow: auto;
}
.text-pin {
  font-size: 1.2rem;
}
</style>
