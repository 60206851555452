import { omit, pick } from 'lodash';

export const ContainerService = (http) => ({
	async getFreeContainers() {
		const response = await http.get('/api/Container/FreeContainers');
		return response.data;
	},

	async getFreeContainersWithExpired(mail, allowExpired = true) {
		const params = {};
		if (mail) {
			params.customerMail = mail;
			params.allowExpired = allowExpired;
		}
		const response = await http.get('/api/Container/FreeContainers', {
			params,
		});
		return response.data;
	},

	async searchByInput(input, searchFieldName, filter, abortController) {
		const locations = {
			State: filter.state,
			City: filter.city,
			Region: filter.region,
			County: filter.county,
		};
		let locationParams = null;
		switch (searchFieldName) {
			case 'Regions':
				locationParams = pick(locations, ['State']);
				break;
			case 'Counties':
				locationParams = pick(locations, ['State', 'Region']);
				break;
			case 'Cities':
				locationParams = omit(locations, ['City']);
				break;
			case 'Streets':
				locationParams = locations;
				break;
			default:
				locationParams = null;
		}
		const results = [];
		const response = await http.get(`/api/Lists/Container${searchFieldName}`, {
			signal: abortController?.signal,
			params: {
				Contains: input,
				...locationParams,
			},
		});
		response.data.forEach((el) => {
			results.push({ value: el });
		});
		return results;
	},

	async getOne(id) {
		const response = await http.get('/api/Container/OccupancyInfo', { params: { ContainerID: id } });
		return response.data;
	},

	async getNearestAvailable(containerId, minimumBoxSize, freeContainersWithBoxSizes) {
		// using POST since GET doesn't handle complex data well
		const response = await http.post('/api/Container/NearestAvailableContainer', {
			ContainerId: containerId,
			MinimumBoxSize: minimumBoxSize,
			FreeContainersWithBoxSizes: freeContainersWithBoxSizes,
		});
		return response.data;
	},
});
