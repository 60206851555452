<template>
	<card v-if="orders">
		<h4>Objednávky na stejnou adresu</h4>
		<el-table
			ref="ordersTable"
			:data="orders"
			show-overflow-tooltip
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="32" />
			<el-table-column property="code" header-align="center" sortable>
				<template #header>
					<el-tooltip :content="$t('eshop.code')" placement="top-start">
						<span>{{ $t('eshop.code') }}</span>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column property="createdDate" header-align="center" sortable>
				<template #header>
					<el-tooltip :content="$t('eshop.created')" placement="top-start">
						<span>{{ $t('eshop.created') }}</span>
					</el-tooltip>
				</template>
				<template #default="scope">
					{{ scope.row.createdDate | datetime }}
				</template>
			</el-table-column>
			<el-table-column property="name" header-align="center" sortable>
				<template #header>
					<el-tooltip :content="$t('eshop.firstName')" placement="top-start">
						<span>{{ $t('eshop.firstName') }}</span>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column property="email" header-align="center" sortable>
				<template #header>
					<el-tooltip :content="$t('eshop.email')" placement="top-start">
						<span>{{ $t('eshop.email') }}</span>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column property="phone" header-align="center" sortable>
				<template #header>
					<el-tooltip :content="$t('eshop.phone')" placement="top-start">
						<span>{{ $t('eshop.phone') }}</span>
					</el-tooltip>
				</template>
				<template #default="scope">
					{{ scope.row.phone | phone }}
				</template>
			</el-table-column>
		</el-table>
	</card>
</template>

<script>
import { TableColumn as ElTableColumn, Table as ElTable, Tooltip as ElTooltip } from 'element-ui';

export default {
	name: 'SameCustomerOrders',
	components: { ElTable, ElTableColumn, ElTooltip },
	props: {
		orderContainer: { type: Number, default: null },
		orderEmail: { type: String, default: null },
		orderId: { type: Number, default: null },
	},
	data() {
		return {
			orders: [],
		};
	},
	async created() {
		if (this.orderContainer && this.orderEmail) {
			this.orders = (await this.$service.eshopOrder.getList(1, { completed: false, email: this.orderEmail, containerId: this.orderContainer })).entities;
			const row = this.orders.find((s) => s.eshopOrderId === this.orderId);
			this.$nextTick(() => {
				if (this.$refs.ordersTable) {
					this.$refs.ordersTable.toggleRowSelection(row);
				}
			});
		} else {
			this.orders = [];
		}
	},
	methods: {
		handleSelectionChange(val) {
			this.$emit('orders', val);
		},
	},
};
</script>
