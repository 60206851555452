<template>
	<a :class="dropDownItemClass" :href="refreshTokenLoading ? '#' : url">
		<i :class="`nc-icon ${icon} app-icon`" />
		{{ title }}
	</a>
</template>

<script>

export default {
	name: 'TheAppSwitcherItem',
	props: {
		title: {
			type: String,
			default: '',
		},
		url: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
			default: '',
		},
		appName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
		};
	},
	computed: {
		currentAppName() {
			return this.$getConst('appName');
		},
		refreshTokenLoading() {
			return this.$store.state[this.currentAppName]?.refreshTokenLoading || false;
		},
		dropDownItemClass() {
			return [
				'dropdown-item',
				{
					'text-bold': this.currentAppName === this.appName,
					'dropdown-item-disabled': this.refreshTokenLoading,
				},
			];
		},
	},
};
</script>

<style>
.app-icon {
	position: relative;
	top: 1px;
	margin-right: 5px;
}

.app-check {
	float: right;
	margin-top: 3px;
	cursor: help;
}

.text-bold {
	font-weight: bold;
}

.dropdown-item-disabled {
	opacity: 0.5;
	cursor: wait;
}

</style>
