export const ReservationService = (http) => ({
	async pickup(unlockCode) {
		const response = await http.post('/api/Reservation/PickUp', {
			unlockCode,
		});
		return response.data;
	},
	async ackPickup(payload) {
		const response = await http.post('/api/Reservation/PickUpAck', payload);
		return response.data;
	},
	async getOne(id) {
		const response = await http.get('/api/Reservation/Detail', { params: { ReservationId: id } });
		return response.data;
	},
	async getReturnsAndExpirations(storeId) {
		const response = await http.get('/api/Store', { params: { storeId } });
		return response.data;
	},
});
