export const ListService = (http) => ({
	async getParameterDefinitions() {
		const response = await http.get('/api/Lists/ContainerParameters');
		return response.data;
	},

	async getContainerPriceMaps() {
		const response = await http.get('/api/Lists/PriceMaps');
		return response.data;
	},

	async getContainers(page) {
		const response = await http.get('/api/Container/List/ForSelect', {
			params: {
				page,
				pageSize: 100,
			},
		});
		return response.data;
	},

	async getPickupPlaces(page) {
		const response = await http.get('/api/Container/List/ForSelect', {
			params: {
				page,
				pageSize: 100,
				type: 1,
			},
		});
		return response.data;
	},
});
