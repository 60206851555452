// eslint-disable-next-line max-classes-per-file
import { Enum } from 'enumify';

import { camelToUpper, upperToCamel } from '@/util/enums';

export class ReservationStatus extends Enum {
	static #CZECH_DICT = {
		DISCARD: 'Zrušeno',
		PAYMENT_WAITING: 'Očekávaní platby',
		ACTIVE: 'Aktivní',
		PICK_UP: 'Vyzvednuto',
		COLLECTED: 'Svezeno',
		WAIT_FOR_DELIVERY: 'Čekám na doručení',
		PREPARED: 'Připraveno k vyzvednutí',
		WAIT_FOR_PAY: 'Připraveno pro přímý prodej',
		WAIT_FOR_RETURN: 'Čekám na vložení zákazníkem',
		WAIT_FOR_RETURN_WITH_RESERVATION: 'Čekám na vložení zákazníkem před doručením',
		WAIT_FOR_COLLECT: 'Čekám na svoz',
		WAIT_FOR_PACKAGE: 'Čekám na balíček od zákazníka',
		WAIT_FOR_PACKAGE_PAY: 'Čekám na platbu za poštovné ',
		WAIT_FOR_EMPTY_DESTINATION: 'Čeká na volný box',
		DELIVERY_RESERVATION_CREATED: 'Vytvořena rezervace k doručení',
		SELECT_FOR_RETURN_DIRECT_SALE: 'Vybráno pro vratku přímého prodeje',
		PRESELECT: 'Předvybráno k prodeji',
		LOST: 'Ztraceno',
		UNDELIVERED: 'Nedoručitelné',
		WAIT_FOR_RETURN_PAY: 'Vratka k úhradě',
	};

	static #ICON_DICT = {
		DISCARD: 'fas fa-ban',
		PAYMENT_WAITING: 'fas fa-comment-dollar',
		ACTIVE: 'fas fa-hdd',
		PICK_UP: 'fas fa-check-circle',
		COLLECTED: 'fas fa-warehouse',
		WAIT_FOR_DELIVERY: 'fas fa-shipping-fast',
		PREPARED: 'fas fa-box',
		WAIT_FOR_PAY: 'fas fa-shopping-cart',
		WAIT_FOR_RETURN: 'fas fa-user-clock',
		WAIT_FOR_RETURN_WITH_RESERVATION: 'fas fa-user-clock',
		SELECT_FOR_RETURN_DIRECT_SALE: 'fas fa-user-clock',
		WAIT_FOR_COLLECT: 'fas fa-history',
		WAIT_FOR_PACKAGE_PAY: 'fas fa-money-bill',
		WAIT_FOR_PACKAGE: 'fas fa-truck-loading',
		WAIT_FOR_EMPTY_DESTINATION: 'fas fa-warehouse',
		PRESELECT: 'fas fa-comment-dollar',
		DELIVERY_RESERVATION_CREATED: 'fas fa-boxes',
		LOST: 'fas fa-question-circle',
		UNDELIVERED: 'fas fa-store-slash',
		WAIT_FOR_RETURN_PAY: 'fas fa-money-bill',
	};

	camelCase() {
		return upperToCamel(this.name);
	}

	czech() {
		return ReservationStatus.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return ReservationStatus.#CZECH_DICT[camelToUpper(str)];
	}

	icon() {
		return ReservationStatus.#ICON_DICT[this.name];
	}

	static icon(str) {
		return ReservationStatus.#ICON_DICT[camelToUpper(str)];
	}

	static enumValueOf(str) {
		return super.enumValueOf(camelToUpper(str));
	}

	valueOf() {
		return this.camelCase();
	}
}
ReservationStatus.initEnum([
	'DISCARD',
	'PAYMENT_WAITING',
	'ACTIVE',
	'PICK_UP',
	'COLLECTED',
	'WAIT_FOR_DELIVERY',
	'PREPARED',
	'WAIT_FOR_PAY',
	'WAIT_FOR_RETURN',
	'WAIT_FOR_COLLECT',
	'WAIT_FOR_PACKAGE',
	'WAIT_FOR_PACKAGE_PAY',
	'WAIT_FOR_EMPTY_DESTINATION',
	'DELIVERY_RESERVATION_CREATED',
	'PRESELECT',
	'LOST',
	'UNDELIVERED',
	'WAIT_FOR_RETURN_PAY',
	'SELECT_FOR_RETURN_DIRECT_SALE',
	'WAIT_FOR_RETURN_WITH_RESERVATION',
]);

export class PickupPlace extends Enum {
	static #CZECH_DICT = {
		STORE: 'Výdejny',
		PENGUIN_BOX: 'Penguin Boxy',
		PRIVATE_ADDRESS: 'Adresy',
		SMART_KEY_BOX: 'Smart Boxy',
	};

	static #ICON_DICT = {
		STORE: '/static/img/icons/genesis.svg',
		PENGUIN_BOX: '/static/img/icons/pb.svg',
		PRIVATE_ADDRESS: 'fa fa-home',
		SMART_KEY_BOX: 'nc-icon nc-mobile',
	};

	camelCase() {
		return upperToCamel(this.name);
	}

	czech() {
		return PickupPlace.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return PickupPlace.#CZECH_DICT[camelToUpper(str)];
	}

	icon() {
		return PickupPlace.#ICON_DICT[this.name];
	}

	static icon(str) {
		return PickupPlace.#ICON_DICT[camelToUpper(str)];
	}

	static enumValueOf(str) {
		return super.enumValueOf(camelToUpper(str));
	}

	valueOf() {
		return this.camelCase();
	}
}
PickupPlace.initEnum(['STORE', 'PENGUIN_BOX', 'PRIVATE_ADDRESS', 'SMART_KEY_BOX']);

export class DeliveryPointType extends Enum {
	static #CZECH_DICT = {
		PenguinBox: 'Penguin Box',
		Store: 'Výdejna',
		SmartKeyBox: 'Smart Box',
	};

	czech() {
		return DeliveryPointType.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return DeliveryPointType.#CZECH_DICT[str];
	}
}

DeliveryPointType.initEnum(['PenguinBox', 'Store', 'SmartKeyBox']);

export class Weekday extends Enum {
	static #CZECH_DICT = {
		Monday: 'Pondělí',
		Tuesday: 'Úterý',
		Wednesday: 'Středa',
		Thursday: 'Čtvrtek',
		Friday: 'Pátek',
		Saturday: 'Sobota',
		Sunday: 'Neděle',
	};

	czech() {
		return Weekday.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return Weekday.#CZECH_DICT[str];
	}
}

Weekday.initEnum(['Monday', 'Tuesday', 'Wednesday:', 'Thursday', 'Friday', 'Saturday', 'Sunday']);

export const WeekdayNumber = {
	Sunday: 0x01,
	Monday: 0x02,
	Tuesday: 0x04,
	Wednesday: 0x08,
	Thursday: 0x10,
	Friday: 0x20,
	Saturday: 0x40,
};

// eslint-disable-next-line no-bitwise
export const WorkingDays = WeekdayNumber.Monday | WeekdayNumber.Tuesday | WeekdayNumber.Wednesday | WeekdayNumber.Thursday | WeekdayNumber.Friday;
// eslint-disable-next-line no-bitwise
export const Weekend = WeekdayNumber.Sunday | WeekdayNumber.Saturday;

export class OpeningHoursStatus extends Enum {
	static #CZECH_DICT = {
		Open: 'Otevřeno',
		Open24Hours: '24 hodin',
		Closed: 'Zavřeno',
	};

	czech() {
		return OpeningHoursStatus.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return OpeningHoursStatus.#CZECH_DICT[str];
	}
}

OpeningHoursStatus.initEnum(['Open', 'Open24Hours', 'Closed']);

export class MailTemplateFromStatus extends Enum {
	static #CZECH_DICT = {
		Discard: 'Zrušeno',
		PaymentWaiting: 'Očekávaní platby',
		Active: 'Aktivní',
		PickUp: 'Vyzvednuto',
		Collected: 'Svezeno',
		WaitForDelivery: 'Čekám na doručení',
		Prepared: 'Připraveno k vyzvednutí',
		WaitForPay: 'Připraveno pro přímý prodej',
		SelectedOnContainer: 'SELECTED ON CONTAINER',
		WaitForReturn: 'Čekám na vložení zákazníkem',
		WaitForReturnWithReservation: 'Čekám na vložení zákazníkem před doručením',
		WaitForCollect: 'Čekám na svoz',
		WaitForEmptyDestination: 'Čeká na volný box',
		DeliveryReservationCreated: 'Vytvořena rezervace k doručení',
		WaitForPackagePay: 'Čekám na platbu za poštovné',
		WaitForPackage: 'Čekám na balíček od zákazníka',
		Preselect: 'Předvybráno k prodeji',
		AddedToRoute: 'ADDED TO ROUTE',
		Created: 'CREATED',
		Lost: 'Ztraceno',
		Undelivered: 'Nedoručitelné',
		WaitForReturnPay: 'Vratka k úhradě',
	};

	czech() {
		return MailTemplateFromStatus.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return MailTemplateFromStatus.#CZECH_DICT[str];
	}
}

MailTemplateFromStatus.initEnum([
	'Discard',
	'PaymentWaiting',
	'Active',
	'PickUp',
	'Collected',
	'WaitForDelivery',
	'Prepared',
	'WaitForPay',
	'SelectedOnContainer',
	'WaitForReturn',
	'WaitForReturnWithReservation',
	'WaitForCollect',
	'WaitForEmptyDestination',
	'DeliveryReservationCreated',
	'WaitForPackagePay',
	'WaitForPackage',
	'Preselect',
	'AddedToRoute',
	'Created',
	'Lost',
	'Undelivered',
	'WaitForReturnPay',
]);

export class MailTemplateToStatus extends Enum {
	static #CZECH_DICT = {
		Discard: 'Zrušeno',
		PaymentWaiting: 'Očekávaní platby',
		Active: 'Aktivní',
		PickUp: 'Vyzvednuto',
		Collected: 'Svezeno',
		WaitForDelivery: 'Čekám na doručení',
		Prepared: 'Připraveno k vyzvednutí',
		WaitForPay: 'Připraveno pro přímý prodej',
		SelectedOnContainer: 'SELECTED ON CONTAINER',
		WaitForReturn: 'Čekám na vložení zákazníkem',
		WaitForReturnWithReservation: 'Čekám na vložení zákazníkem před doručením',
		WaitForCollect: 'Čekám na svoz',
		WaitForEmptyDestination: 'Čeká na volný box',
		DeliveryReservationCreated: 'Vytvořena rezervace k doručení',
		WaitForPackagePay: 'Čekám na platbu za poštovné',
		WaitForPackage: 'Čekám na balíček od zákazníka',
		Preselect: 'Předvybráno k prodeji',
		AddedToRoute: 'ADDED TO ROUTE',
		Created: 'CREATED',
		Lost: 'Ztraceno',
		Undelivered: 'Nedoručitelné',
		WaitForReturnPay: 'Vratka k úhradě',
	};

	czech() {
		return MailTemplateToStatus.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return MailTemplateToStatus.#CZECH_DICT[str];
	}
}

MailTemplateToStatus.initEnum([
	'Discard',
	'PaymentWaiting',
	'Active',
	'PickUp',
	'Collected',
	'WaitForDelivery',
	'Prepared',
	'WaitForPay',
	'SelectedOnContainer',
	'WaitForReturn',
	'WaitForReturnWithReservation',
	'WaitForCollect',
	'WaitForEmptyDestination',
	'DeliveryReservationCreated',
	'WaitForPackagePay',
	'WaitForPackage',
	'Preselect',
	'AddedToRoute',
	'Created',
	'Lost',
	'Undelivered',
	'WaitForReturnPay',
]);

export class MailTemplateDestination extends Enum {
	static #CZECH_DICT = {
		PenguinBox: 'PenguinBox',
		Store: 'Výdejna',
		PrivateAddress: 'Adresa',
		SmartKeyBox: 'SmartBox',
	};

	czech() {
		return MailTemplateDestination.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return MailTemplateDestination.#CZECH_DICT[str];
	}
}

MailTemplateDestination.initEnum(['PenguinBox', 'Store', 'PrivateAddress', 'SmartKeyBox']);

export class MailTemplateGroup extends Enum {
	static #CZECH_DICT = {
		Order: 'Objednávka',
		Rent: 'Výpůjčka',
		PostSender: 'Odesílatel poštovní zásilky',
		PostRecipient: 'Příjemce poštovní zásilky',
	};

	czech() {
		return MailTemplateGroup.#CZECH_DICT[this.name];
	}

	static czech(str) {
		return MailTemplateGroup.#CZECH_DICT[str];
	}
}

MailTemplateGroup.initEnum(['Order', 'Rent', 'PostSender', 'PostRecipient']);
