<template>
	<fieldset v-loading="loading" class="form-group border p-3 mt-n2">
		<legend class="w-auto px-2 label-control control-label">
			<label>{{ $t('admin.selectEshop') }} </label>
		</legend>
		<el-table
			ref="table_data"
			class="table-striped mt-n4"
			:data="tableData"
			row-class-name="tableRow"
			:default-sort="{ prop: filter.orderby, order: filter.direction }"
			@sort-change="sortChange"
			@row-click="goToTemplateEdit">
			<el-table-column property="name" sortable="custom">
				<template #header>
					<el-tooltip :content="$t('admin.name')" placement="top-start">
						<span>{{ $t('admin.name') }}</span>
					</el-tooltip>
				</template>
				<template #default="scope">
					<label :for="scope.row.eshopId" class="form-check-label ml-4">
						<input
							:id="scope.row.eshopId"
							v-model="eshopId"
							:value="scope.row.id"
							class="form-check-input"
							name="eshopId"
							type="radio">
						<el-tooltip :content="scope.row.name" placement="top-start">
							<span>{{ scope.row.name }}</span>
						</el-tooltip>
						<slot />
					</label>
				</template>
			</el-table-column>
		</el-table>
		<div class="row mt-2">
			<div class="col-8">
				<el-pagination
					:current-page="eshopListPage.currentPage"
					layout="total, prev, pager, next, jumper"
					:total="eshopListPage.totalCount"
					:page-count="eshopListPage.pagesCount"
					:page-size="pageSize"
					:page-sizes="pageSizes"
					@size-change="loadDataPageSize"
					@current-change="goToPage" />
			</div>
			<div class="col-4">
				<p-button
					type="warning"
					size="sm"
					class="pull-right ml-4"
					@click="removedEshop">
					{{ $t('admin.removeSelected') }}
				</p-button>
			</div>
		</div>

		<div class="row mb-n2">
			<div class="col-4">
				<autocomplete
					v-model="filter.name"
					:label="$t('admin.name')"
					trigger-on-focus
					search-field-name="name"
					@fetch-suggestions="querySearchAsync"
					@select="getEshopList" />
			</div>
			<div class="col-7 mt-3 d-flex align-items-center">
				<p-button type="success" size="md" @click="getEshopList">
					{{ $t('admin.search') }}
				</p-button>
				<el-tooltip :content="$t('admin.tooltips.clearFilter')" placement="top-start">
					<p-button
						class="ml-2"
						type="danger"
						icon
						size="md"
						@click="clearFilter">
						<i class="fa fa-times" />
					</p-button>
				</el-tooltip>
			</div>
		</div>
	</fieldset>
</template>

<script>
import { mapState } from 'vuex';
import { Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination, Tooltip as ElTooltip } from 'element-ui';

import { appName } from '@/modules/admin/config';
import { resetFilter, setFilter } from '@/store/mutation-types';

export default {
	name: 'EshopList',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
	},
	props: {
		defaultValue: {
			type: Number,
			default: null,
		},
		loading: {
			type: Boolean,
		},
	},
	data() {
		return {
			eshopListPage: Object,
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			eshopId: this.defaultValue,
			filter: {},
		};
	},
	computed: {
		...mapState(appName, ['eshopFilter']),
		tableData() {
			return this.eshopListPage.entities;
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'eshopFilter', data: val });
			},
		},
		eshopId(value) {
			this.$emit('set-value', value);
		},
	},
	created() {
		this.filter = this.eshopFilter;
		this.getEshopList();
	},
	methods: {
		sortChange(column) {
			if (column.order) {
				this.filter.orderby = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderby = null;
				this.filter.direction = null;
			}
			this.getEshopList();
		},
		goToTemplateEdit(row, col, event) {
			event.stopPropagation();

			const parent = event.target.closest('.cell');

			if (parent && event.target.nodeName !== 'INPUT') {
				parent.querySelector('input').click();
			}
		},
		removedEshop() {
			this.eshopId = null;
		},
		goToPage(page) {
			this.page = page;
			this.getEshopList();
		},
		loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			this.goToPage(1);
		},
		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'eshopFilter');
			this.filter = this.eshopFilter;
			this.getEshopList();
		},
		async getEshopList() {
			this.$emit('set-loading', true);
			this.eshopListPage = await this.$service.eshop.getList(this.filter, this.page, this.pageSize);
			this.$emit('set-loading', false);
		},
		async querySearchAsync(input, cb) {
			const results = await this.$service.deliveryPoint.searchByEshopInput(input);
			cb(results);
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
.card label {
	font-size: 0.8571rem;
}
</style>
