<template>
	<div>
		<g-chart
			:settings="{ packages: ['timeline'] }"
			:data="dataTable"
			:options="chartOptions"
			:create-chart="customCreate" />
	</div>
</template>

<script>
import { GChart } from 'vue-google-charts';

export default {
	name: 'PriceTimeline',
	components: {
		GChart,
	},
	props: {
		data: { type: Object, default: () => {} },
	},
	data() {
		return {
			daysCZ: {
				Monday: 'Pondělí',
				Tuesday: 'Úterý',
				Wednesday: 'Středa',
				Thursday: 'Čtvrtek',
				Friday: 'Pátek',
				Saturday: 'Sobota',
				Sunday: 'Neděle',
			},
			parsed: [],
			days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
			chartOptions: {
				height: 400,
				avoidOverlappingGridLines: false,
				tooltip: {
					isHtml: true,
				},
			},
			dataTable: null,
		};
	},
	watch: {
		data: {
			handler() {
				this.processData();
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		customCreate(el, api) {
			this.dataTable = new api.visualization.DataTable();

			this.dataTable.addColumn({ type: 'string', id: 'label' });
			this.dataTable.addColumn({ type: 'string', id: 'description' });
			this.dataTable.addColumn({ type: 'string', role: 'tooltip', p: { html: true } });
			this.dataTable.addColumn({ type: 'date', id: 'start' });
			this.dataTable.addColumn({ type: 'date', id: 'end' });
			this.dataTable.addRows(this.parsed);

			return new api.visualization.Timeline(el);
		},
		pushException(parsed, day, description, price, from, to) {
			const dateFrom = new Date(0, 0, 0, from, 0, 0);
			const dateTo = new Date(0, 0, 0, to, 0, 0);
			parsed.push([this.daysCZ[day], description, this.customTooltipElement(description, dateFrom, dateTo, price), dateFrom, dateTo]);
		},
		processData() {
			this.initialize();
			if (this.data.priceExceptions) {
				for (let i = 0; i < this.data.priceExceptions.length; i++) {
					if (typeof this.data.priceExceptions[i].daysOfWeek === 'string') {
						const days = this.data.priceExceptions[i].daysOfWeek.split(',');
						for (let j = 0; j < days.length; j++) {
							this.pushException(
								this.parsed,
								days[j].trim(),
								this.data.priceExceptions[i].description,
								this.data.priceExceptions[i].price,
								this.data.priceExceptions[i].fromHour,
								this.data.priceExceptions[i].toHour,
							);
						}
					}
				}
			}

			this.fillGaps();
		},
		fillGaps() {
			if (this.days.length) {
				this.parsed = [];
			}

			for (let i = 0; i < this.days.length; i++) {
				const day = this.daysCZ[this.days[i]];
				let hoursToAdd = [];
				for (let hour = 0; hour < 24; hour++) {
					const hourDate = new Date(0, 0, 0, hour, 0, 0);
					let addHour = true;
					for (let j = 0; j < this.parsed.length; j++) {
						if (this.parsed[j][0] === day && this.parsed[j][3] <= hourDate && this.parsed[j][4] > hourDate) {
							addHour = false;
						}
					}
					if (addHour) {
						if (hour - hoursToAdd[hoursToAdd.length - 1] > 1) {
							this.pushInterval(day, hoursToAdd);
							hoursToAdd = [];
							hoursToAdd.push(hour);
						} else {
							hoursToAdd.push(hour);
						}
					}
				}
				if (hoursToAdd.length) {
					this.pushInterval(day, hoursToAdd);
				} else {
					this.initialize();
				}
			}
		},
		pushInterval(day, hoursToAdd) {
			const from = new Date(0, 0, 0, hoursToAdd[0], 0, 0);
			const to = new Date(0, 0, 0, hoursToAdd[hoursToAdd.length - 1] + 1, 0, 0);
			this.parsed.push([day, 'Základní cena', this.customTooltipElement('Základní cena', from, to, this.data.basePrice), from, to]);
		},
		initialize() {
			this.parsed = [
				// Initialize monday with Základní cena label with 0 duration just to fix color for Základní cena
				['Pondělí', 'Základní cena', '', new Date(0, 0, 0, 0, 0, 0), new Date(0, 0, 0, 0, 0, 0)],
			];
		},
		customTooltipElement(description, from, to, price) {
			return (
				`${'<div style="word-wrap: unset ; padding: 10px 15px; width: 150%; border-radius: 3px;"><b>'}${description}</b>`
				+ `<br><b>Od: </b> ${from.getHours()}:00`
				+ `<br><b>Do: </b> ${to.getHours() === 0 ? 24 : to.getHours()}:00`
				+ `<br><b>Cena: </b> ${price}Kč/hod.`
				+ '</div>'
			);
		},
	},
};
</script>
