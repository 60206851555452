<template>
	<card v-if="reservationsForCollect.length" id="forCollect" class="col-md-12 mt-4">
		<h3>Rezervace k vyzvednutí</h3>
		<p-button @click="cleanForCollection">
			<i slot="labelRight" class="fa fa-times" />
			Vymazat
		</p-button>
		<p-button @click="print">
			<i slot="labelRight" class="fa fa-print" />
			Tisk
		</p-button>
		<el-table :data="reservationsForCollect">
			<el-table-column>
				<template #header>
					<el-tooltip :content="$t('global.order')" placement="top-start">
						<span>{{ $t('global.order') }}</span>
					</el-tooltip>
				</template>
				<template #default="scope">
					<el-tooltip :content="$t('global.tooltips.moveUp')" placement="top-start">
						<p-button
							:disabled="scope.$index === 0"
							size="sm"
							icon
							round
							@click="collectSoon(scope.row)">
							<i class="fa fa-chevron-up" />
						</p-button>
					</el-tooltip>
					<el-tooltip :content="$t('global.tooltips.moveDown')" placement="top-start">
						<p-button
							:disabled="scope.$index === reservationsForCollect.length - 1"
							icon
							round
							size="sm"
							@click="collectLater(scope.row)">
							<i class="fa fa-chevron-down" />
						</p-button>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column property="container.city">
				<template #header>
					<el-tooltip :content="$t('global.city')" placement="top-start">
						<span>{{ $t('global.city') }}</span>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column property="container.street">
				<template #header>
					<el-tooltip :content="$t('global.street')" placement="top-start">
						<span>{{ $t('global.street') }}</span>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column property="reservedFrom">
				<template #header>
					<el-tooltip :content="$t('global.occupiedFrom')" placement="top-start">
						<span>{{ $t('global.occupiedFrom') }}</span>
					</el-tooltip>
				</template>
				<template #default="scope">
					{{ scope.row.reservedFrom | datetime }}
				</template>
			</el-table-column>
			<el-table-column>
				<template #default="scope">
					<el-tooltip :content="$t('global.tooltips.deleteFromCollectionList')" placement="top-start">
						<p-button
							type="danger"
							size="sm"
							icon
							round
							@click="removeFromCollection(scope.row)">
							<i class="fa fa-times" />
						</p-button>
					</el-tooltip>
				</template>
			</el-table-column>
		</el-table>
	</card>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip } from 'element-ui';

export default {
	name: 'BaseCollectionList',
	components: { ElTable, ElTableColumn, ElTooltip },
	props: {
		removeFromCollection: {
			type: Function,
			required: true,
		},
		cleanForCollection: {
			type: Function,
			required: true,
		},
		collectSoon: {
			type: Function,
			required: true,
		},
		collectLater: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
		};
	},
	computed: {
		reservationsForCollect() {
			return this.$store.state[this.currentAppName].reservationsForCollect || [];
		},
	},
	methods: {
		print() {
			this.$router.push({ name: 'expired-print' });
		},
	},
};
</script>
