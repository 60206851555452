import * as actionTypes from '@/store/action-types';
import * as mutationTypes from '@/store/mutation-types';

export default class ReservationCollectionActions {
	constructor() {
		this.actions = {
			async [actionTypes.addForCollection]({ commit }, reservation) {
				commit(mutationTypes.addReservationForCollection, { reservation });
			},

			async [actionTypes.removeFromCollection]({ commit }, reservation) {
				commit(mutationTypes.removeReservationForCollection, { reservation });
			},

			async [actionTypes.cleanForCollection]({ commit }) {
				commit(mutationTypes.cleanReservationsForCollection);
			},

			[actionTypes.collectSoon]({ commit }, reservation) {
				commit(mutationTypes.collectSoon, { reservation });
			},

			async [actionTypes.collectLater]({ commit }, reservation) {
				commit(mutationTypes.collectLater, { reservation });
			},
		};
	}
}
