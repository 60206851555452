import Vue from 'vue';

import { appNameList } from '@/globalConstants';
// TODO: https://jira.artin.cz/browse/PENG-1549
// eslint-disable-next-line import/no-cycle
import AuthActions from '@/store/auth-actions';
import ReservationCollectionActions from '@/store/reservation-collection-actions';

import * as mutationTypes from './mutation-types';
import * as actionTypes from './action-types';

const authActions = new AuthActions([appNameList.eshop, appNameList.logistics, appNameList.admin]);
const reservationCollectionActions = new ReservationCollectionActions();

export default {
	...authActions.actions,
	...reservationCollectionActions.actions,

	async [actionTypes.loadContainerParameters]({ commit }) {
		const knownParameters = await Vue.$service.listService.getParameterDefinitions();
		commit(mutationTypes.setContainerParameters, { knownParameters });
	},

	async [actionTypes.loadContainerPriceMaps]({ commit }) {
		const priceMaps = await Vue.$service.listService.getContainerPriceMaps();
		commit(mutationTypes.setContainerPriceMaps, { priceMaps });
	},
};
