import { stringify } from 'qs';
import { isNil, omitBy } from 'lodash';

export const ListService = (http) => ({
	async getContainerList(page = 1, pageSize = 1000, filter = { orderBy: 'city', direction: 'Ascending' }) {
		const response = await http.get('/api/Container/List', {
			params: {
				Page: page,
				PageSize: pageSize,
				...filter,
			},
		});

		return response.data;
	},

	async getWaitForDeliveryList(page, filter, pageSize = 50) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};

		const response = await http.get('/api/Reservation/WaitForDelivery', config);

		return response.data;
	},
});
