// eslint-disable-next-line max-classes-per-file
import { Enum } from 'enumify';

import { ReservationStatusBase } from '@/store/enums';
import { camelToUpper, upperToCamel } from '@/util/enums';

export class ReservationStatus extends ReservationStatusBase {}

ReservationStatus.initEnum([
	'DISCARD',
	'PAYMENT_WAITING',
	'ACTIVE',
	'PICK_UP',
	'COLLECTED',
	'WAIT_FOR_DELIVERY',
	'PREPARED',
	'WAIT_FOR_PAY',
	'WAIT_FOR_RETURN',
	'WAIT_FOR_COLLECT',
	'WAIT_FOR_PACKAGE',
	'WAIT_FOR_PACKAGE_PAY',
	'WAIT_FOR_EMPTY_DESTINATION',
	'DELIVERY_RESERVATION_CREATED',
	'PRESELECT',
	'LOST',
	'SELECT_FOR_RETURN_DIRECT_SALE',
	'UNDELIVERED',
	'WAIT_FOR_RETURN_PAY',
	'WAIT_FOR_RETURN_WITH_RESERVATION',
]);

export class ReservationRouteStatus extends Enum {
	static #CZECH_DICT = {
		ASSIGNED: 'Nevyzvednutý',
		CONFIRMED_BY_DRIVER: 'Vyzvednutý',
	};

	static #ICON_DICT = {
		ASSIGNED: 'fa fa-2x fa-home text-danger',
		CONFIRMED_BY_DRIVER: 'fa fa-2x fa-car text-success',
	};

	static czech(str) {
		return ReservationRouteStatus.#CZECH_DICT[camelToUpper(str)];
	}

	static icon(str) {
		return ReservationRouteStatus.#ICON_DICT[camelToUpper(str)];
	}

	static enumValueOf(str) {
		return super.enumValueOf(camelToUpper(str));
	}

	camelCase() {
		return upperToCamel(this.name);
	}

	czech() {
		return ReservationRouteStatus.#CZECH_DICT[this.name];
	}

	icon() {
		return ReservationRouteStatus.#ICON_DICT[this.name];
	}

	valueOf() {
		return this.camelCase();
	}
}
ReservationRouteStatus.initEnum(['ASSIGNED', 'CONFIRMED_BY_DRIVER']);

export class Weekday extends Enum {
	static #DAY_NUMBER = {
		Monday: 1,
		Tuesday: 2,
		Wednesday: 3,
		Thursday: 4,
		Friday: 5,
		Saturday: 6,
		Sunday: 7,
	};

	static orderNumber(dayName) {
		return Weekday.#DAY_NUMBER[dayName];
	}
}
