// TODO: https://jira.artin.cz/browse/PENG-1549
/* eslint-disable import/no-cycle */
import { state } from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
