import { convertKeysFirstLetterToUppercase } from '@/util/helpers';

export const TemplateService = (http) => ({
	async getOne(data, smsVariant) {
		delete data.template;

		const response = await http.get(smsVariant ? '/api/SmsTemplates' : '/api/MailTemplates', {
			params: convertKeysFirstLetterToUppercase(data),
		});

		return response.data;
	},

	async update(data, smsVariant) {
		const params = convertKeysFirstLetterToUppercase(data);
		delete params.Template;

		const response = await http.post(
			smsVariant ? '/api/SmsTemplates' : '/api/MailTemplates',
			{ template: data.template },
			{
				params,
			},
		);

		return response.data;
	},

	async validate(data, smsVariant) {
		const params = convertKeysFirstLetterToUppercase(data);
		delete params.Template;

		const response = await http.post(
			smsVariant ? '/api/SmsTemplates/Validate' : '/api/MailTemplates/Validate',
			{ template: data.template },
			{
				params,
			},
		);

		return response?.data;
	},

	async replacements(groupId, smsVariant) {
		const response = await http.get(smsVariant ? '/api/SmsTemplates/Replacements' : '/api/MailTemplates/Replacements', {
			params: { group: groupId },
		});

		return response?.data;
	},
});
