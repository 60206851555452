<template>
	<el-tooltip
		:key="text"
		:content="text"
		placement="top-start"
		:disabled="disableTooltip">
		<span>{{ trimmedText }}</span>
	</el-tooltip>
</template>

<script>
import { Tooltip as ElTooltip } from 'element-ui';

export default {
	name: 'TrimText',
	components: {
		ElTooltip,
	},
	props: {
		text: { type: String, default: null },
		maxLength: { type: Number, default: 15 },
	},
	computed: {
		trimmedText() {
			if (this.needTrim) {
				return `${this.text.substr(0, this.maxLength)}...`;
			}
			return this.text;
		},
		needTrim() {
			return this.text && this.text.length > this.maxLength;
		},
		disableTooltip() {
			return !this.needTrim;
		},
	},
};
</script>
