import { stringify } from 'qs';
import { isNil, omitBy } from 'lodash';

export const LogisticsRouteService = (http) => ({
	async getList(filter, page = 1, pageSize = 50) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};
		const response = await http.get('/api/LogisticRoute/List', config);

		return response.data;
	},
	async ackLoadLorry(logisticRouteId, reservationIds) {
		const response = await http.post('/api/LogisticRoute/AckLoadToLorry', { logisticRouteId, reservationIds });

		return response.data;
	},
});
