export const ReservationService = (http) => ({
	async updateReservation(reserveTo, reservationId, customer, customerAddress) {
		const response = await http.post('/api/EshopReservation/Update', {
			to: reserveTo,
			reservationId,
			...customer,
			customerAddress,
		});
		return response.data;
	},

	async newReservationReturn(reservation) {
		const response = await http.post('/api/EshopReservation/NewReservationReturn', reservation);

		return response.data;
	},

	async createByUserStatic(year, month, userId) {
		const response = await http.get('/api/Reservation/CreateByUserStatistic', {
			params: {
				Year: year,
				Month: month,
				UserId: userId,
			},
		});
		return response.data;
	},

	async getNotes(reservationId) {
		const response = await http.get('/api/ReservationNotes', {
			params: {
				reservationId,
			},
		});
		return response.data;
	},

	async saveNotes(note) {
		const response = await http.post('/api/ReservationNotes', note);
		return response.data;
	},
});
