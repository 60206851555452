import { setLastFilteredContainers } from '@/modules/admin/store/mutation-types';

export default {
	data() {
		return {
			freeContainersFiltered: [],
			lastQuery: '',
			containerSelectInputSelector: '.container-select input',
		};
	},

	methods: {
		enrichFreeContainers(containers) {
			containers.forEach((container) => {
				container.containerFullNameLower = this.$options.filters.location(container)?.toLowerCase();
			});
		},
		filterFreeContainers(query) {
			if (query !== '' && query.length > 1) {
				this.freeContainersFiltered = [...this.freeContainers.filter((c) => c.containerFullNameLower.indexOf(query.toLowerCase()) !== -1)];
				this.$store.commit(`${this.currentAppName}/${setLastFilteredContainers}`, this.freeContainersFiltered);
			} else {
				this.freeContainersFiltered = [];
			}
		},
		setContainerSelectEventListener() {
			this.$el.querySelector(this.containerSelectInputSelector)?.addEventListener('input', (event) => {
				this.lastQuery = event.target.value;
			});
		},
		containerSelectOnChange() {
			this.$nextTick(() => {
				this.filterFreeContainers(this.lastQuery);
				this.$refs.containerSelect.$data.query = this.lastQuery;
				this.$refs.containerSelect.$data.lastQuery = this.lastQuery;
				if (this.lastQuery?.length > 0) {
					this.$refs.containerSelect.$data.currentPlaceholder = '';
				}
				this.$el.querySelector(this.containerSelectInputSelector).value = this.lastQuery;
			});
		},
		onVisibleChange(dropdownVisible) {
			if (dropdownVisible) {
				this.containerSelectOnChange();
			}
		},
		clearLastQuery() {
			this.lastQuery = '';
			this.$refs.containerSelect.$data.query = this.lastQuery;
			this.$refs.containerSelect.$data.lastQuery = this.lastQuery;
			this.$refs.containerSelect.$data.currentPlaceholder = '';
		},
		containerSelectSetText(text) {
			this.$nextTick(() => {
				this.$el.querySelector(this.containerSelectInputSelector).value = text;
			});
		},
	},
};
