export const ReservationService = (http) => ({
	async ackDelivery(reservationId) {
		const response = await http.post('/api/Reservation/DeliveryAck', {
			reservationId,
		});
		return response.data;
	},
	async ackCollected(reservationId) {
		const response = await http.post('/api/Reservation/CollectedAck', {
			reservationId,
		});
		return response.data;
	},
	async ackPickUp(reservationId) {
		const response = await http.post('/api/Reservation/PickUpAck', {
			reservationId,
		});
		return response.data;
	},
	async updateAddress(reservationToUpdate) {
		const response = await http.post('/api/Reservation/UpdateAddress', reservationToUpdate);
		return response.data;
	},
	async getReservation(reservationId) {
		const response = await http.get('/api/Reservation/Detail', { params: { reservationId } });
		return response.data;
	},
	async discardReservation(reservationId) {
		const response = await http.post('/api/EshopReservation/Discard', { reservationId });

		return response.data;
	},
	async getNotes(reservationId) {
		const response = await http.get('/api/ReservationNotes', {
			params: {
				reservationId,
			},
		});
		return response.data;
	},

	async saveNotes(note) {
		const response = await http.post('/api/ReservationNotes', note);
		return response.data;
	},
});
