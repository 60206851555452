<template>
	<card v-loading="loading">
		<h2>{{ $t('pickup.requestReturnOrder') }}</h2>
		<form-group-input
			v-if="boxTypeMode"
			v-loading="loadingContainers"
			:label="$t('pickup.container')"
			required>
			<el-select
				v-model="newReservation.containerId"
				class="select-default"
				filterable
				@change="cleanBoxType">
				<template slot="prefix">
					<span v-if="container" class="container-icon-in-select-box">
						<img v-if="container.containerType === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
						<img v-if="container.containerType === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
						<i v-if="container.containerType === 'PrivateAddress'" class="fa fa-home text-dark" />
						<i v-if="container.containerType === 'SmartKeyBox'" class="nc-icon nc-mobile text-dark" />
					</span>
				</template>
				<el-option
					v-for="opt in freeContainers"
					:key="opt.containerId"
					:label="formatContainerName(opt)"
					:value="opt.containerId">
					<img v-if="opt.containerType === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
					<img v-if="opt.containerType === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
					<i v-if="opt.containerType === 'PrivateAddress'" class="fa fa-home text-dark" />
					<i v-if="opt.containerType === 'SmartKeyBox'" class="nc-icon nc-mobile text-dark" />
					{{ formatContainerName(opt) }}
				</el-option>
			</el-select>
		</form-group-input>
		<form-group-input
			v-if="availableBoxTypes && boxTypeMode"
			:disabled="!newReservation.containerId"
			:label="$t('pickup.boxType')"
			required>
			<el-select v-model="newReservation.boxType" class="select-default">
				<el-option
					v-for="opt in availableBoxTypes"
					:key="opt"
					:value="opt"
					:label="BoxType.czech(opt)" />
			</el-select>
		</form-group-input>
		<form-group-input :label="$t('pickup.orderNumber')">
			<el-input v-model="newReservation.reservationNumber" :disabled="!newReservation.containerId" name="reservationNumber" />
		</form-group-input>
		<form-group-input :error="getError(newReservationValidation.email.field)" :label="$t('pickup.email')" some-required>
			<el-input
				v-model="newReservation.mail"
				v-validate="newReservationValidation.email.rule"
				:disabled="!newReservation.containerId"
				:name="newReservationValidation.email.field"
				:data-vv-as="$t('pickup.email')">
				<span slot="append" class="fa fa-at" />
			</el-input>
		</form-group-input>

		<form-group-input :error="getError(newReservationValidation.phone.field)" :label="$t('pickup.phone')" some-required>
			<el-input
				v-model="newReservation.phone"
				v-validate="newReservationValidation.phone.rule"
				:disabled="!newReservation.containerId"
				:name="newReservationValidation.phone.field"
				:data-vv-as="$t('pickup.phone')"
				@blur="formatPhone">
				<span slot="append" class="fa fa-phone" />
			</el-input>
		</form-group-input>

		<form-group-input
			v-if="boxTypeMode"
			:error="getError(newReservationValidation.reservedHours.field)"
			:label="$t('pickup.reserve')"
			required>
			<el-input
				v-model="reservedHours"
				v-validate="newReservationValidation.reservedHours.rule"
				:disabled="!newReservation.containerId"
				:name="newReservationValidation.reservedHours.field"
				type="number"
				:data-vv-as="$t('pickup.reserve')">
				<span slot="append">
					{{ $t(translateWithNumber('eshop', 'hour', reservedHours)) }}
				</span>
			</el-input>
		</form-group-input>
		<hr>
		<div class="d-flex justify-content-between align-items-center">
			<div>
				<p v-if="boxTypeMode" class="note text-muted">
					{{ $t('global.required') }}
				</p>
				<p class="note text-muted">
					** {{ $t('pickup.fillAtLeastOneField') }}
				</p>
			</div>
			<p-button :disabled="!canSave" @click.once="createNewReservation">
				{{ $t('pickup.create') }}
			</p-button>
		</div>
	</card>
</template>

<script>
import { Input as ElInput, Option as ElOption, Select as ElSelect } from 'element-ui';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/max';

import notifyService from '@/service/notify-service';
import { BoxType } from '@/modules/eshop/store/enums';
import Validation from '@/util/validations';
import ValidationForm from '@/util/validated-form-mixin';
import FormMixin from '@/util/form-mixin';
import { parseIncompletePhoneNumberWithNull, translateWithNumber } from '@/util/helpers';
import { formChanged } from '@/store/mutation-types';

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

export default {
	name: 'NewReturnOrder',
	components: {
		ElSelect,
		ElOption,
		ElInput,
	},

	mixins: [ValidationForm, FormMixin],
	props: {
		boxTypeMode: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			translateWithNumber,
			BoxType,
			loading: false,
			loadingContainers: false,
			freeContainers: [],
			availableBoxTypes: [],
			newReservation: {
				boxType: this.boxTypeMode ? null : 'Large',
				containerId: null,
				mail: null,
				phone: null,

				reservationNumber: null,
				minutes: HOURS_IN_DAY * MINUTES_IN_HOUR,
			},
			reservedHours: HOURS_IN_DAY,
			newReservationValidation: {
				email: {
					rule: Validation.email,
					field: 'email',
				},
				phone: {
					rule: Validation.phone,
					field: 'phone',
				},
				reservedHours: {
					rule: Validation.reservedHours,
					field: 'reservedHours',
				},
			},
			container: null,
			immediateSuceeded: false,
		};
	},

	computed: {
		currentUser() {
			return this.$store.state[this.currentAppName].currentUser;
		},
		canSave() {
			const { boxType, containerId, mail, phone } = this.newReservation;
			return (this.boxTypeMode ? boxType : true) && containerId && (mail || phone) && this.isValid;
		},
	},

	watch: {
		newReservation: {
			handler() {
				if (this.immediateSuceeded) {
					this.$store.commit(`${this.currentAppName}/${formChanged}`, true);
				} else {
					this.immediateSuceeded = true;
				}

				if (this.boxTypeMode) {
					const newContainerId = this.newReservation.containerId;
					const container = this.freeContainers.find((x) => x.containerId === newContainerId);
					this.availableBoxTypes = container?.availableBoxTypes || [];
				}
			},
			deep: true,
		},
		boxTypeMode: {
			immediate: true,
			handler(val) {
				if (val) {
					this.getFreeContainers();
				} else {
					this.newReservation.containerId = Number(this.currentUser.client_ContainerId);
				}
			},
		},
	},
	mounted() {
		this.immediateSuceeded = false;
	},
	methods: {
		cleanBoxType() {
			const newContainerId = this.newReservation.containerId;
			this.container = this.freeContainers.find((x) => x.containerId === newContainerId);
			if (this.container?.availableBoxTypes?.length === 1) {
				this.newReservation.boxType = this.container.availableBoxTypes.toString();
			} else {
				this.newReservation.boxType = null;
			}
		},
		async getFreeContainers() {
			try {
				this.loadingContainers = true;
				this.freeContainers = await this.$service.container.getFreeContainers();
			} catch (e) {
				notifyService.notifyError(this.$t('pickup.generalError'));
			} finally {
				this.loadingContainers = false;
			}
		},

		formatContainerName(container) {
			return this.$options.filters.location(container);
		},

		formatPhone() {
			// If parse is not called, format will return empty string on non-number input
			this.newReservation.phone = formatIncompletePhoneNumber(parseIncompletePhoneNumberWithNull(this.newReservation.phone), 'CZ');
		},

		async createNewReservation() {
			if (this.canSave) {
				try {
					this.loading = true;

					this.newReservation.minutes = this.reservedHours * MINUTES_IN_HOUR;
					if (!this.newReservation.mail) {
						this.newReservation.mail = null;
					}
					const response = await this.$service.reservation.newReservationReturn(this.newReservation);

					notifyService.notifySuccess(this.$t('pickup.returnOrderCreated'));

					this.$store.commit(`${this.currentAppName}/${formChanged}`, false);
					this.$router.push({ name: 'reservation-detail', params: { id: response.reservationId } });
				} catch (e) {
					console.warn(e);

					notifyService.notifyError(this.$t('pickup.returnOrderCreationError'));
				} finally {
					this.loading = false;
				}
			} else {
				notifyService.notifyError(this.$t('pickup.missingValues'));
			}
		},
	},
};
</script>

<style>
.note {
	font-size: 80%;
}
.el-select .el-input.is-disabled .el-input__inner::placeholder {
	color: #636c72;
}
.container-icon-in-select-box {
	padding-left: 7px;
	padding-top: 10px;
	padding-bottom: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
</style>
