import config from '../config';

export const getDefaultState = () => ({
	homePage: 'reservation-list',
	clientId: 'eshopApp',
	scopeName: 'EshopScope',
	sidebarUserLinks: config.sidebarUserLinks,
	appsToLogin: [],
	refreshTokenLoading: false,
	currentUser: {
		name: '',
		surname: '',
		email: '',
		Eshop: null,
		roles: [],
		DirectSale: null,
	},
	formChanged: false,
	priceMaps: [],
	loginError: null,
	containerValues: {},
	reservationsForCollect: [],
	sheetForPrint: [],
	versionInfo: null,
	reservationFilter: {
		isExpired: null,
		region: null,
		county: null,
		city: null,
		street: null,
		Status: null,
		DestinationType: null,
		Destination: null,
		isPostPackage: null,
		ReservationNumberLike: null,
		Phone: null,
		From: null,
		To: null,
		Email: null,
		orderBy: null,
		direction: null,
		SKU: null,
		reservationSelects: [],
	},
	reservationSelects: [],
	pickupPlaceSelects: [],
	directSaleProductFilter: {
		stockId: null,
		name: null,
		description: null,
		category: null,
		brand: null,
		price: null,
		size: null,
		allowDirectSale: null,
		orderBy: null,
		direction: null,
		inStock: null,
		inContainer: null,
	},
	userFilter: {
		locked: null,
	},
	boxFilter: {
		state: null,
		city: null,
		street: null,
		region: null,
		county: null,
		location: null,
		isOk: null,
		isLocked: null,
		isDisabled: null,
		isOffline: null,
		stockItemAssigned: null,
		itemName: null,
		stockItems: null,
		orderBy: null,
		direction: null,
	},
	shoptetFilter: {
		createdDateFrom: null,
		createdDateTo: null,
		code: null,
		email: null,
		phone: null,
		name: null,
		street: null,
		city: null,
		zip: null,
		country: null,
		shoptetContainerIdentifier: null,
		containerState: null,
		containerCity: null,
		containerStreet: null,
		containerRegion: null,
		containerCounty: null,
		completed: false,
		orderBy: null,
		direction: null,
	},
	deliveryPointFilter: {
		serialNumber: { from: null, to: null },
		state: null,
		region: null,
		county: null,
		city: null,
		street: null,
		location: null,
		isOk: null,
		isOffline: null,
		isLocked: null,
		isDisabled: null,
		orderby: null,
		direction: null,
		hasPaymentTerminal: null,
	},
	containersForSelect: [],
});

export const state = getDefaultState();
