import http from '@/service/http';

export default {
	async getReport(logisticRouteId) {
		const response = await http.get('/api/LogisticRoute/Detail', { params: { logisticRouteId }, headers: { 'api-version': '0.2' } });
		const reportData = response.data;
		const serialNumbers = reportData.routeStops.flatMap((routeStop) => {
			if (routeStop.containerType === 'SmartKeyBox') {
				return routeStop.reservations?.filter((reservation) => reservation.boxSerialNo).map((reservation) => reservation.boxSerialNo) || [];
			}
			return [];
		});
		if (serialNumbers) {
			const smartLockInfoListResponse = await http.post('/api/SmartLockInfo/List', { serialNumbers });
			const smartLockInfos = smartLockInfoListResponse.data;
			reportData.routeStops.forEach((routeStop) => {
				if (routeStop.containerType === 'SmartKeyBox') {
					routeStop.reservations?.forEach((reservation) => {
						reservation.lockName = smartLockInfos.find((info) => info.lockId === reservation.boxSerialNo)?.lockAlias;
					});
				}
			});
		}
		return reportData;
	},
};
