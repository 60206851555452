<template>
	<div>
		<div id="printOptimizedRoute" v-loading="loading">
			<h3>{{ $t('logistics.route') }} {{ reportData.logisticRouteId }} - {{ reportData.name }}</h3>
			<base-report-print-sheet-table>
				<thead>
					<tr>
						<th>{{ $t('logistics.sequence') }}</th>
						<th>{{ $t('logistics.address') }}</th>
						<th class="text-right">
							{{ $t('logistics.distance') }}
						</th>
						<th class="text-right">
							{{ $t('logistics.time') }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="waypoint in optimizeRouteData.waypoints" :key="waypoint.sequence">
						<td>
							{{ waypoint.sequence + 1 }}
						</td>
						<td>
							{{ waypoint.id.split(':')[2] }}
						</td>
						<td class="text-right">
							<span v-if="waypoint.sequence > 0">
								{{ formatDistance(optimizeRouteData.interconnections[waypoint.sequence - 1].distance) }}
							</span>
						</td>
						<td class="text-right">
							<span v-if="waypoint.sequence > 0">
								{{ formatTime(optimizeRouteData.interconnections[waypoint.sequence - 1].time) }}
							</span>
						</td>
					</tr>
					<tr>
						<td />
						<td class="font-weight-bold">
							{{ $t('logistics.total') }}
						</td>
						<td class="text-right font-weight-bold">
							{{ formatDistance(optimizeRouteData.distance) }}
						</td>
						<td class="text-right font-weight-bold">
							{{ formatTime(optimizeRouteData.time) }}
						</td>
					</tr>
				</tbody>
			</base-report-print-sheet-table>
		</div>
		<div class="mt-3">
			<p-button @click="saveOptimizedRoute">
				{{ $t('logistics.save') }}
			</p-button>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

import { BaseReportPrintSheetTable } from '@/components/Base';

export default {
	name: 'OptimizeRoute',
	components: {
		BaseReportPrintSheetTable,
	},
	props: {
		reportData: {
			type: Object,
			default: () => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		optimizeRouteData: {
			type: Object,
			default: () => ({ waypoints: [], interconnections: [] }),
		},
		privateReservationsMapping: {
			type: Object,
			default: () => {},
		},
		updateRouteCallback: {
			type: Function,
			required: true,
		},
	},
	methods: {
		async saveOptimizedRoute() {
			const containers = this.optimizeRouteData.waypoints
				.filter((waypoint) => !waypoint.id.startsWith('0:'))
				.flatMap((waypoint) => {
					const simpleContainers = [];
					const splitId = waypoint.id.split(':');
					const containerId = Number(splitId[0]);
					const mappingId = Number(splitId[1]);

					if (containerId) {
						simpleContainers.push({
							containerId,
							reservationId: null,
						});
					}
					if (mappingId) {
						// there can be more reservations for one private address
						// get the ids of the reservations and map them back to the route
						const relatedPrivateReservationIds = this.privateReservationsMapping[mappingId];
						relatedPrivateReservationIds.forEach((reservationId) => {
							simpleContainers.push({
								containerId: null,
								reservationId,
							});
						});
					}
					return simpleContainers;
				});
			await this.updateRouteCallback(containers, this.reportData.isActive);
			this.$emit('set-route-visible', false);
			this.$router.go(0);
		},

		formatDistance(distance) {
			return `${(distance / 1000).toFixed(2)} km`;
		},
		formatTime(s) {
			return moment('2015-01-01').startOf('day').seconds(s).format('H:mm:ss');
		},
	},
};
</script>
