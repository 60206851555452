<template>
	<div class="row">
		<div class="col-md-12 card">
			<div class="d-flex">
				<div class="col">
					<div class="d-flex">
						<div class="flex-column">
							<label>
								{{ $t('admin.reservationState') }}
							</label>
							<el-select
								v-model="filter.reservationSelects"
								:label="$t('admin.reservationState')"
								size="large"
								class="w-100"
								:placeholder="$t('admin.reservationState')"
								value-key="ordinal"
								clearable
								multiple
								@change="loadData">
								<el-option
									v-for="status in ReservationStatus.enumValues"
									:key="status.ordinal"
									:value="status"
									:label="status.czech()">
									<i :class="status.icon()" />
									{{ status.czech() }}
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="col-2">
					<form-group-input :label="$t('admin.expired')" type="text">
						<div class="d-flex mt-1">
							<yes-no-all
								v-model="filter.isExpired"
								:label="$t('admin.expired')"
								trigger-on-change-method
								@on-change-method="loadData" />
						</div>
					</form-group-input>
				</div>
				<div class="col">
					<div class="d-flex">
						<form-group-input :label="$t('admin.reservationPost')" class="ml-4">
							<div class="d-flex mt-1">
								<yes-no-all v-model="filter.isPostPackage" trigger-on-change-method @on-change-method="loadData" />
							</div>
						</form-group-input>
					</div>
				</div>
				<div class="col">
					<form-group-input v-model="filter.ReservationNumberLike" :label="$t('admin.orderNumber')" type="text" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.SKU" :label="$t('admin.sku')" type="text" />
				</div>
				<div class="col">
					<form-group-input
						v-model="filter.Phone"
						:label="$t('admin.phone')"
						type="text"
						@keypress="isTelNumber($event)" />
				</div>
				<div class="col">
					<form-group-input v-model="filter.Email" :label="$t('admin.email')" type="email" />
				</div>
			</div>
			<div v-show="advancedSearch">
				<div class="d-flex mb-1 align-items-center">
					<div class="col">
						<label> {{ $t('admin.container') }} </label>
						<el-select
							ref="containerSelect"
							v-model="filter.containers"
							v-loading="loading"
							:label="$t('admin.container')"
							class="w-100 container-select"
							:placeholder="$t('eshop.containerSearchHint')"
							value-key="ordinal"
							clearable
							multiple
							remote
							filterable
							:remote-method="filterFreeContainers"
							@change="loadDataContainers"
							@visible-change="onVisibleChange">
							<el-option
								v-for="opt in freeContainersFiltered"
								:key="opt.containerId"
								:label="formatContainerName(opt)"
								:value="opt.containerId">
								<img v-if="opt.type === 'Store'" src="/static/img/icons/genesis.svg" alt="Genesis">
								<img v-if="opt.type === 'PenguinBox'" src="/static/img/icons/pb.svg" alt="PB">
								<span v-if="opt.type === 'SmartKeyBox'"><i :class="PickupPlace.SMART_KEY_BOX.icon()" /></span>
								<i v-if="opt.type === 'PrivateAddress'" class="fa fa-home" />
								{{ formatContainerName(opt) }}
							</el-option>
						</el-select>
					</div>
					<div class="col-3">
						<label> {{ $t('admin.pickupPlaceType') }} </label>
						<el-select
							v-model="Destination"
							size="large"
							class="w-100"
							:placeholder="$t('admin.pickupPlaceType')"
							value-key="ordinal"
							clearable
							@change="loadData">
							<template slot="prefix">
								<span v-if="Destination" class="container-icon-in-select-box">
									<span v-show="Destination.valueOf() === 'PrivateAddress'"><i :class="PickupPlace.PRIVATE_ADDRESS.icon()" /></span>
									<span v-show="Destination.valueOf() === 'SmartKeyBox'"><i :class="PickupPlace.SMART_KEY_BOX.icon()" /></span>
									<span v-show="Destination.valueOf() === 'PenguinBox'"><img :src="Destination.icon()" alt=""></span>
									<span v-show="Destination.valueOf() === 'Store'"><img :src="Destination.icon()" alt=""></span>
								</span>
							</template>
							<el-option
								v-for="place in PickupPlace.enumValues"
								:key="place.ordinal"
								:label="place.czech()"
								:value="place">
								<span v-if="place">
									<span v-if="place.valueOf() === 'PrivateAddress'"><i :class="place.icon()" /></span>
									<span v-else-if="place.valueOf() === 'SmartKeyBox'"><i :class="place.icon()" /></span>
									<img v-else :src="place.icon()" alt="">
								</span>
								{{ place.czech() }}
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="d-flex align-items-center">
					<div class="col">
						<autocomplete
							v-model="filter.state"
							:label="$t('admin.country')"
							trigger-on-focus
							search-field-name="States"
							@fetch-suggestions="querySearchLocationAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.region"
							:label="$t('admin.region')"
							trigger-on-focus
							search-field-name="Regions"
							@fetch-suggestions="querySearchLocationAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.county"
							:label="$t('admin.county')"
							trigger-on-focus
							search-field-name="Counties"
							@fetch-suggestions="querySearchLocationAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.city"
							:label="$t('admin.city')"
							trigger-on-focus
							search-field-name="Cities"
							@fetch-suggestions="querySearchLocationAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<autocomplete
							v-model="filter.street"
							:label="$t('admin.street')"
							trigger-on-focus
							search-field-name="Streets"
							@fetch-suggestions="querySearchLocationAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<form-group-input
							v-model="filter.czechPostZip"
							:label="$t('admin.czPostIdentifier')"
							type="text" />
					</div>
				</div>
				<div class="d-flex mt-2">
					<div class="col">
						<autocomplete
							v-model="filter.EshopName"
							:label="$t('admin.eshop')"
							trigger-on-focus
							search-field-name="EshopName"
							@fetch-suggestions="querySearchEshopAsync"
							@select="loadData" />
					</div>
					<div class="col">
						<form-group-input :label="$t('admin.occupiedFromStart')">
							<date-picker
								v-model="filter.From"
								format="dd.MM.yyyy HH:mm"
								prefix-icon="el-icon-date"
								type="datetime"
								@change="loadData(1)" />
						</form-group-input>
					</div>
					<div class="col">
						<form-group-input :label="$t('admin.subscriptionEndBefore')">
							<date-picker
								v-model="filter.prepaidTo"
								format="dd.MM.yyyy HH:mm"
								prefix-icon="el-icon-date"
								type="datetime"
								@change="loadData(1)" />
						</form-group-input>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-between">
				<div class="col-2 d-flex mb-1 justify-content-start">
					<p-button type="success" size="md" @click="loadData">
						{{ $t('admin.search') }}
					</p-button>
					<el-tooltip :content="$t('admin.tooltips.clearFilter')" placement="top-start">
						<p-button
							class="ml-2"
							type="danger"
							icon
							size="md"
							@click="clearFilter">
							<i class="fa fa-times" />
						</p-button>
					</el-tooltip>
				</div>
				<div class="d-flex mb-1 mr-3">
					<p-button @click="toggleAdvancedSearch">
						{{ searchButtonLabel }}
					</p-button>
				</div>
			</div>
		</div>

		<!-- SEZNAM REZERVACI    -->

		<div v-loading="loading" class="col-md-12 card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<div class="d-flex justify-content-between">
							<div class="align-self-center">
								<el-pagination
									:current-page="page.currentPage"
									layout="total, sizes, prev, pager, next, jumper"
									:total="page.totalCount"
									:page-count="page.pagesCount"
									:page-size="pageSize"
									:page-sizes="pageSizes"
									@size-change="loadDataPageSize"
									@current-change="loadData" />
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 mt-2">
						<el-table
							:data="reservations"
							data-cy="ListMainTable"
							row-class-name="tableRow"
							show-overflow-tooltip
							:default-sort="{ prop: filter.orderBy, order: filter.direction }"
							@row-click="goToEdit"
							@sort-change="sortChange">
							<el-table-column width="60" align="center">
								<template #header>
									<el-tooltip :content="$t('admin.state')" placement="top-start">
										<span>{{ $t('admin.state') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="scope.row.status" :content="ReservationStatus.czech(scope.row.status)" placement="top-start">
										<span :key="scope.row.status" class="mr-2">
											<i :class="ReservationStatus.icon(scope.row.status)" />
										</span>
									</el-tooltip>

									<span v-if="scope.row.container">
										<span v-show="scope.row.container.type === 'PenguinBox'">
											<el-tooltip content="Penguin Box" placement="top">
												<img src="/static/img/icons/pb.svg" alt="PB">
											</el-tooltip>
										</span>
										<span v-show="scope.row.container.type === 'Store'">
											<el-tooltip content="Výdejna" placement="top">
												<img src="/static/img/icons/genesis.svg" alt="Genesis">
											</el-tooltip>
										</span>
										<span v-show="scope.row.container.type === 'SmartKeyBox'">
											<el-tooltip content="Smart Box" placement="top">
												<i class="nc-icon nc-mobile" />
											</el-tooltip>
										</span>
									</span>
									<span v-show="!scope.row.container || scope.row.container.type === 'PrivateAddress'">
										<el-tooltip content="Adresa" placement="top">
											<i class="fa fa-home gray" />
										</el-tooltip>
									</span>
								</template>
							</el-table-column>
							<el-table-column property="eshop.name" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.eshop')" placement="top-start">
										<span>{{ $t('admin.eshop') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="reservationNumber" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.orderNumber')" placement="top-start">
										<span>{{ $t('admin.orderNumber') }}</span>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column align="center" width="60">
								<template #header>
									<el-tooltip :content="$t('admin.actions')" placement="top-start">
										<span>{{ $t('admin.actions') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<el-tooltip :key="`${scope.row.reservationId}-tooltip`" :content="$t('admin.tooltips.addToCollectionList')" placement="top-start">
										<p-button
											v-if="scope.row.isExpired"
											:key="`${scope.row.reservationId}-button`"
											:disabled="disabledAddForCollect(scope.row)"
											type="danger"
											size="sm"
											icon
											@click.stop="addForCollection(scope.row)">
											<i class="fa fa-print" />
										</p-button>
									</el-tooltip>
								</template>
							</el-table-column>
							<el-table-column property="email" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.email')" placement="top-start">
										<span>{{ $t('admin.email') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<trim-text :text="scope.row.email" />
								</template>
							</el-table-column>
							<el-table-column property="phone" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.phone')" placement="top-start">
										<span>{{ $t('admin.phone') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.phone | phone }}
								</template>
							</el-table-column>
							<el-table-column property="reservedFrom" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.occupiedFrom')" placement="top-start">
										<span>{{ $t('admin.occupiedFrom') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.reservedFrom | datetime }}
								</template>
							</el-table-column>
							<el-table-column property="expiresOn" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.subscriptionEnd')" placement="top-start">
										<span>{{ $t('admin.subscriptionEnd') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.expiresOn | datetime }}
								</template>
							</el-table-column>
							<el-table-column property="deliveryPoint.state" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.country')" placement="top-start">
										<span>{{ $t('admin.country') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.deliveryPoint ? scope.row.deliveryPoint.state : '' }}
								</template>
							</el-table-column>
							<el-table-column property="deliveryPoint.city" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.city')" placement="top-start">
										<span>{{ $t('admin.city') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.deliveryPoint ? scope.row.deliveryPoint.city : '' }}
								</template>
							</el-table-column>
							<el-table-column property="deliveryPoint.street" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.street')" placement="top-start">
										<span>{{ $t('admin.street') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									{{ scope.row.deliveryPoint ? scope.row.deliveryPoint.street : '' }}
								</template>
							</el-table-column>
							<el-table-column property="container.serialNumber" header-align="center" sortable="custom">
								<template #header>
									<el-tooltip :content="$t('admin.container')" placement="top-start">
										<span>{{ $t('admin.container') }}</span>
									</el-tooltip>
								</template>
								<template #default="scope">
									<div class="d-flex justify-content-center">
										<router-link
											v-if="scope.row.container"
											:to="{
												name: getEditRoute(scope.row.container.type),
												params: { id: scope.row.container.containerId } }"
											class="justify-self-center">
											<p-button type="success">
												{{ scope.row.container.serialNumber }}
											</p-button>
										</router-link>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="row">
					<div class="col mt-2">
						<el-pagination
							:current-page="page.currentPage"
							layout="total, sizes, prev, pager, next, jumper"
							:total="page.totalCount"
							:page-count="page.pagesCount"
							:page-size="pageSize"
							:page-sizes="pageSizes"
							@size-change="loadDataPageSize"
							@current-change="loadData" />
					</div>
				</div>
			</div>
		</div>
		<base-collection-list
			:clean-for-collection="removeFromCollection"
			:collect-later="collectLater"
			:collect-soon="collectSoon"
			:remove-from-collection="removeFromCollection" />
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
	Table as ElTable,
	TableColumn as ElTableColumn,
	Tooltip as ElTooltip,
	Pagination as ElPagination,
	DatePicker,
	Select as ElSelect,
	Option as ElOption,
} from 'element-ui';

import { ReservationStatus, PickupPlace } from '@/modules/admin/store/enums';
import TrimText from '@/components/UIComponents/TrimText.vue';
// eslint-disable-next-line import/no-cycle
import { appName } from '@/modules/admin/config';
import { resetFilter, setFilter } from '@/store/mutation-types';
import { BaseCollectionList } from '@/components/Base';
import { addForCollection, cleanForCollection, collectLater, collectSoon, removeFromCollection } from '@/store/action-types';
// eslint-disable-next-line import/no-cycle
import ContainersForSelectMixin from '@/util/containers-for-select-mixin';
import ContainerSelectMixin from '@/util/container-select-mixin';
import { containerTypes } from '@/globalConstants';

export default {
	name: 'ReservationList',
	components: {
		TrimText,
		BaseCollectionList,
		ElPagination,
		ElTable,
		ElTableColumn,
		ElTooltip,
		DatePicker,
		ElSelect,
		ElOption,
	},
	mixins: [ContainersForSelectMixin, ContainerSelectMixin],
	data() {
		return {
			page: {},
			pageSize: 50,
			pageSizes: [50, 100, 150, 200],
			loading: false,
			ReservationStatus,
			PickupPlace,
			advancedSearch: false,
			Destination: null,
			filter: {},
			abortController: null,
			abortControllerData: null,
		};
	},
	computed: {
		...mapState(appName, ['containerValues', 'reservationsForCollect', 'reservationFilter', 'containersForSelect', 'lastFilteredContainers']),
		reservations() {
			return this.page.entities;
		},
		searchButtonLabel() {
			return this.advancedSearch ? this.$t('admin.simpleSearch') : this.$t('admin.advancedSearch');
		},
		freeContainers() {
			this.enrichFreeContainers(this.containersForSelect);
			return this.containersForSelect;
		},
	},
	watch: {
		filter: {
			deep: true,
			handler(val) {
				this.$store.commit(`${appName}/${setFilter}`, { filterName: 'reservationFilter', data: val });
			},
		},
	},
	async created() {
		this.filter = this.reservationFilter;
		if (this.lastFilteredContainers) {
			this.freeContainersFiltered = this.lastFilteredContainers;
		}
		if (this.isSomethingInAdvancedSearch()) {
			this.advancedSearch = true;
		}
		this.loadData();
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
		this.setContainerSelectEventListener();
	},
	methods: {
		...mapActions(appName, [addForCollection, removeFromCollection, cleanForCollection, collectSoon, collectLater]),
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		goToEdit(row) {
			this.$router.push({ name: 'reservation-detail', params: { id: row.reservationId } });
		},
		isSomethingInAdvancedSearch() {
			return this.filter
				&& (this.filter.containers?.length > 0
					|| this.Destination
					|| this.filter.state
					|| this.filter.region
					|| this.filter.county
					|| this.filter.city
					|| this.filter.street
					|| this.filter.EshopName
					|| this.filter.From
					|| this.filter.prepaidTo);
		},

		isTelNumber(event) {
			const evt = event || window.event;
			const charCode = evt.which ? evt.which : evt.keyCode;
			return (charCode >= 48 && charCode <= 57) || charCode === 32 || charCode === 43 ? true : evt.preventDefault();
		},
		getEditRoute(containerType) {
			if (containerType === containerTypes.smartBox) {
				return 'smart-box-edit';
			}
			if (containerType === containerTypes.store) {
				return 'pickup-place-edit';
			}
			return 'container-edit';
		},
		async sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}

			this.loadData();
		},

		async loadData(page = 1) {
			this.loading = true;
			this.abortControllerData?.abort();
			this.abortControllerData = new AbortController();
			this.filter.Status = this.filter.reservationSelects.map((status) => status.camelCase());
			this.filter.DestinationType = this.Destination ? this.Destination.valueOf() : null;
			const filter = { ...this.filter };

			if (!this.advancedSearch) {
				const simpleSearchAttrs = ['isExpired', 'ReservationNumberLike', 'Phone', 'Email', 'Status', 'isPostPackage', 'orderBy', 'direction', 'SKU'];

				Object.keys(filter).forEach((filterAttrName) => {
					if (filterAttrName && !simpleSearchAttrs.includes(filterAttrName)) {
						delete filter[filterAttrName];
					}
				});
			}

			this.page = await this.$service.reservation.getList(page || 1, filter, this.pageSize, this.abortControllerData);
			this.loading = false;
		},

		async loadDataPageSize(pageSize) {
			this.pageSize = pageSize;
			await this.loadData();
		},

		formatContainerName(container) {
			return this.$options.filters.location(container);
		},
		querySearchLocationAsync(input, cb, searchFieldName) {
			this.abortController?.abort();
			this.abortController = new AbortController();
			this.$service.deliveryPoint.searchByLocationInput(input, searchFieldName, this.filter, this.abortController)
				.then((results) => {
					cb(results);
				});
		},
		async querySearchEshopAsync(input, cb) {
			const results = await this.$service.deliveryPoint.searchByEshopInput(input);
			cb(results);
		},
		clearFilter() {
			this.$store.commit(`${appName}/${resetFilter}`, 'reservationFilter');
			this.filter = this.reservationFilter;
			this.Destination = null;
			this.clearLastQuery();
			this.loadData();
		},

		disabledAddForCollect(row) {
			return !!this.reservationsForCollect.filter((res) => res.reservationId === row.reservationId).length;
		},
		toggleAdvancedSearch() {
			this.advancedSearch = !this.advancedSearch;
		},
		async loadDataContainers() {
			await this.loadData();
			this.containerSelectOnChange();
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}

.fa-home {
	color: #606266;
}
</style>
