<template>
	<div class="row">
		<div class="col-md-8">
			<card>
				<h2
					v-if="editing"
					slot="header"
					class="card-title">
					{{ title }} {{ user.name }} {{ user.surname }}
				</h2>
				<h2
					v-else
					slot="header"
					class="card-title">
					{{ newTitle }}
				</h2>
				<div
					v-if="showUserCard || !editing"
					data-cy="editPageContent">
					<form>
						<form-group-input
							v-model="user.userName"
							v-validate="validations.userUserName.rules"
							data-cy="userUserName"
							placeholder="Zadejte přihlašovací jméno"
							:label="$t('admin.username')"
							:disabled="editing"
							:name="validations.userUserName.name"
							:error="getError(validations.userUserName.name)"
							:data-vv-as="$t('admin.username')"
							required />
						<form-group-input
							v-model="user.name"
							v-validate="validations.userName.rules"
							data-cy="userFirstName"
							placeholder="Zadejte jméno"
							:label="$t('admin.firstName')"
							:name="validations.userName.name"
							:error="getError(validations.userName.name)"
							:data-vv-as="$t('admin.firstName')"
							required />
						<form-group-input
							v-model="user.surname"
							v-validate="validations.userSurname.rules"
							data-cy="userSurname"
							placeholder="Zadejte příjmení"
							:label="$t('admin.surname')"
							:name="validations.userSurname.name"
							:error="getError(validations.userSurname.name)"
							:data-vv-as="$t('admin.surname')"
							required />
						<form-group-input
							v-model="user.email"
							v-validate="validations.userEmail.rules"
							data-cy="userEmail"
							placeholder="Zadejte e-mail"
							:label="$t('admin.email')"
							:name="validations.userEmail.name"
							:error="getError(validations.userEmail.name)"
							:data-vv-as="$t('admin.email')"
							required />
						<form-group-input
							v-model="user.phone"
							v-validate="validations.userPhone.rules"
							data-cy="userPhone"
							placeholder="Zadejte telefon"
							:label="$t('admin.phone')"
							:name="validations.userPhone.name"
							:error="getError(validations.userPhone.name)"
							:data-vv-as="$t('admin.phone')" />
						<form-group-input
							v-if="!editing"
							ref="passwordToBeConfirmed"
							v-model="user.password"
							v-validate="validations.userPassword.rules"
							data-cy="userPassword"
							placeholder="Zadejte výchozí heslo"
							:label="$t('admin.password')"
							type="password"
							:name="validations.userPassword.name"
							:error="getError(validations.userPassword.name)"
							:data-vv-as="$t('admin.password')"
							required />
						<form-group-input
							v-if="!editing"
							v-model="user.passwordConfirmation"
							v-validate="validations.userPasswordConfirmation.rules"
							data-cy="userPasswordConfirmation"
							placeholder="Potvrďte výchozí heslo"
							:label="$t('admin.confirmPassword')"
							type="password"
							:name="validations.userPasswordConfirmation.name"
							:error="getError(validations.userPasswordConfirmation.name)"
							:data-vv-as="$t('admin.confirmPassword')"
							required />
						<form-group-input
							v-if="showPickupPlace"
							:label="$t('admin.pickupPlace')"
							placeholder="Výdejna"
							required="showPickupPlace">
							<el-select
								v-model="user.storeId"
								v-validate="validations.userPickupPlace.rules"
								class="w-100"
								:data-vv-as="$t('admin.pickupPlace')"
								:name="validations.userPickupPlace.name"
								clearable
								filterable
								@clear="clearPickupPlace">
								<el-option
									v-for="pickupPlace in pickupPlacesForSelect.entities"
									:key="pickupPlace.containerId"
									:value="pickupPlace.containerId"
									:label="formatPickupPlaceName(pickupPlace)" />
							</el-select>
							<div
								v-if="getError(validations.userPickupPlace.name)"
								class="invalid-feedback d-block">
								{{ getError(validations.userPickupPlace.name) }}
							</div>
						</form-group-input>
						<p-checkbox
							v-if="editing"
							v-model="user.isLocked">
							{{ $t('admin.tooltips.lock') }}
						</p-checkbox>
						<confirm-button
							class="pull-right"
							data-cy="confirmButton"
							:show-success-message="false"
							:confirm-message="editing ? $t('admin.userUpdate') : $t('admin.userCreate')"
							:disabled="!isValid"
							:callback="() => validateAndSave('admin')">
							{{ $t('admin.save') }}
						</confirm-button>
						<div class="clearfix" />
					</form>
				</div>
			</card>
		</div>
	</div>
</template>

<script>
import { Select as ElSelect, Option as ElOption } from 'element-ui';
import { isEmpty } from 'lodash';

import { loadPickupPlaces } from '@/modules/admin/store/action-types';
import ValidationMixin from '@/util/validation-mixin';
import Validations from '@/util/validations';
import { ConfirmButton } from '@/components/UIComponents';
import { appNameList } from '@/globalConstants';

export default {
	name: 'BaseUserForm',
	components: {
		ConfirmButton,
		ElSelect,
		ElOption,
	},
	mixins: [ValidationMixin],
	props: {
		title: { type: String, default: null },
		newTitle: { type: String, default: null },
		linkBack: { type: String, default: null },
		addUserFunction: { type: String, default: null },
		showPickupPlace: { type: Boolean, default: false },
	},
	data() {
		return {
			userLoaded: false,
			editing: false,
			user: {},
			validations: {
				userUserName: {
					name: 'userUserName',
					rules: Validations.requiredField,
				},
				userName: {
					name: 'userName',
					rules: Validations.requiredField,
				},
				userSurname: {
					name: 'userSurname',
					rules: Validations.requiredField,
				},
				userEmail: {
					name: 'userEmail',
					rules: Validations.emailRules,
				},
				userPhone: {
					name: 'userPhone',
					rules: Validations.phoneRule,
				},
				userPassword: {
					name: 'userPassword',
					rules: Validations.passwordRules,
				},
				userPasswordConfirmation: {
					name: 'userPasswordConfirmation',
					rules: Validations.passwordConfirmationRules,
				},
				userPickupPlace: {
					name: 'userPickupPlace',
					rules: Validations.requiredField,
				},
			},
		};
	},
	computed: {
		showUserCard() {
			return !isEmpty(this.user);
		},
		pickupPlacesForSelect: {
			get() {
				return this.$store.state?.admin?.pickupPlacesForSelect || [];
			},
			set(val) {
				if (typeof this.$store.state?.admin.pickupPlacesForSelect !== 'undefined') {
					this.$store.state.admin.pickupPlacesForSelect = val;
				}
			},
		},
	},
	watch: {
		user: {
			handler() {
				if (this.userLoaded) {
					this.$store.commit(`${this.currentAppName}/formChanged`, true);
				}
			},
			deep: true,
		},
	},
	async created() {
		if (this.$route.params.id) {
			this.editing = true;
			this.user = await this.$service.admin.getOne(this.$route.params.id);
		}

		if ((this.currentAppName || '').toString() === appNameList.admin) {
			await this.$store.dispatch(`${appNameList.admin}/${loadPickupPlaces}`);
		}
	},
	updated() {
		this.userLoaded = true;
	},
	methods: {
		async save() {
			if (this.user.phone === '') {
				this.user.phone = null;
			}
			if (!this.editing) {
				// When fail, return 400 => exception
				await this.$service.admin[this.addUserFunction](this.user);
			} else {
				// When fail, return 400 => exception
				await this.$service.admin.update(this.user);
			}
			this.$store.commit(`${this.currentAppName}/formChanged`, false);
			this.$router.push({ name: this.linkBack });
		},
		formatPickupPlaceName(pickupPlace) {
			return this.$options.filters.location(pickupPlace);
		},
		clearPickupPlace() {
			this.user.storeId = null;
		},
	},
};
</script>
