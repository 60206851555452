import { Validator } from 'vee-validate';

import { appNameList } from '@/globalConstants';
import logService from '@/modules/eshop/service/log-service';

const sidebarLinksNoDirectSale = [
	{
		name: 'Rezervace',
		icon: 'nc-icon nc-calendar-60',
		path: '/eshop/rezervace',
		directSalePermissionRequired: false,
	},
	{
		name: 'Objednat dopravu',
		icon: 'nc-icon nc-book-bookmark',
		path: '/eshop/objednavka',
		directSalePermissionRequired: false,
	},
	{
		name: 'Objednat vratku',
		icon: 'nc-icon nc-delivery-fast',
		path: '/eshop/vratka',
	},
	{
		name: 'Eshop',
		icon: 'nc-icon nc-shop',
		path: '/eshop/eshop',
		directSalePermissionRequired: false,
	},
];

const sidebarLinks = [
	...sidebarLinksNoDirectSale,
	{
		name: 'Produkty',
		icon: 'nc-icon nc-cart-simple',
		path: '/eshop/produkty-primy-prodej',
		directSalePermissionRequired: true,
	},
	{
		name: 'Prodané',
		icon: 'nc-icon nc-money-coins',
		path: '/eshop/produkty-prodano',
		directSalePermissionRequired: true,
	},
	{
		name: 'Schránky',
		icon: 'nc-icon nc-box-2',
		path: '/eshop/schranky',
		directSalePermissionRequired: true,
	},
];

const sidebarUserLinks = [
	{ name: 'eshop-user-list', display: 'Uživatelé' },
	{ name: 'eshop-user-reservations', display: 'Uživatelé s rezervací' },
	{ name: 'password-change', display: 'Změna hesla' },
];

const config = {
	appName: appNameList.eshop,
	sidebarLinks,
	sidebarUserLinks,
	sidebarLinksNoDirectSale,
};

config.install = function (Vue) {
	Vue.prototype.$getConst = (key) => config[key];

	Validator.extend('validateAddress', {
		getMessage: () => this.$t('eshop.addressNotFound'),
		async validate(value, args) {
			const { zip, city, street } = args;

			// eslint-disable-next-line no-return-await
			return await logService.validateAddress(city, zip, street);
		},
	});
};

export const appName = appNameList.eshop;

export default config;
