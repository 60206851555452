import axios from 'axios';

const pragueParts = [
	'Benice',
	'Běchovice',
	'Bohnice',
	'Braník',
	'Břevnov',
	'Březiněves',
	'Bubeneč',
	'Čakovice',
	'Černý most',
	'Čimice',
	'Dejvice',
	'Dolní Chabry',
	'Dolní Měcholupy',
	'Dolní Počernice',
	'Dubeč',
	'Ďáblice',
	'Háje',
	'Hájek u Uhříněvsi',
	'Hloubětín',
	'Hlubočepy',
	'Hodkovičky',
	'Holešovice',
	'Holyně',
	'Horní Měcholupy',
	'Horní Počernice',
	'Hostavice',
	'Hostivař',
	'Hradčany',
	'Hrdlořezy',
	'Chodov',
	'Cholupice',
	'Jinonice',
	'Josefov',
	'Kamýk',
	'Karlín',
	'Kbely',
	'Klánovice',
	'Kobylisy',
	'Koloděje',
	'Kolovraty',
	'Komořany',
	'Košíře',
	'Královice',
	'Krč',
	'Křeslice',
	'Kunratice',
	'Kyje',
	'Lahovice',
	'Letňany',
	'Lhotka',
	'Libeň',
	'Liboc',
	'Libuš',
	'Lipany',
	'Lipence',
	'Lochkov',
	'Lysolaje',
	'Malá Chuchle',
	'Malá Strana',
	'Malešice',
	'Michle',
	'Miškovice',
	'Modřany',
	'Motol',
	'Nebušice',
	'Nedvězí u Říčan',
	'Nové Město',
	'Nusle',
	'Petrovice',
	'Písnice',
	'Pitkovice',
	'Podolí',
	'Prosek',
	'Přední Kopanina',
	'Radlice',
	'Radotín',
	'Ruzyně',
	'Řeporyje',
	'Řepy',
	'Satalice',
	'Sedlec',
	'Slivenec',
	'Smíchov',
	'Sobín',
	'Staré Město',
	'Stodůlky',
	'Strašnice',
	'Střešovice',
	'Střížkov',
	'Suchdol',
	'Šeberov',
	'Štěrboholy',
	'Točná',
	'Troja',
	'Třebonice',
	'Třeboradice',
	'Uhříněves',
	'Újezd u Průhonic',
	'Újezd nad Lesy',
	'Veleslavín',
	'Velká Chuchle',
	'Vinohrady',
	'Vinoř',
	'Vokovice',
	'Vršovice',
	'Vysočany',
	'Vyšehrad',
	'Záběhlice',
	'Zadní Kopanina',
	'Zbraslav',
	'Zličín',
	'Žižkov',
];

export const LogService = (http) => ({
	async getDeliveryPointPickupAttempts(page, containerId) {
		if (containerId) {
			const response = await http.get('/api/Log/PickUpAttempts', {
				params: { page, containerId, PageSize: 10 },
			});
			return response.data;
		}
		return {};
	},

	async validateAddress(state, county, city, street) {
		const response = await this.validateAddressData(state, county, city, street);

		if (!response) {
			return false;
		}
		return Array.isArray(response?.data) && response?.data?.length;
	},
	pragueContainsSuburb(city) {
		let foundPraguePart = false;
		for (let i = 0; i < pragueParts.length; i++) {
			const praguePart = pragueParts[i];
			if (city.toLowerCase().indexOf(praguePart.toLowerCase()) !== -1) {
				foundPraguePart = true;
				break;
			}
		}
		return foundPraguePart;
	},
	async validateAddressData(state, county, city, street) {
		let cityEdited = city;
		if (county.toLowerCase() === 'praha') {
			let foundPraguePart = null;
			for (let i = 0; i < pragueParts.length; i++) {
				const praguePart = pragueParts[i];
				if (city.toLowerCase().indexOf(praguePart.toLowerCase()) !== -1) {
					foundPraguePart = praguePart;
					break;
				}
			}
			if (foundPraguePart) {
				cityEdited = foundPraguePart;
			} else {
				cityEdited = 'Praha';
			}
		}
		let params = {};
		if (county.toLowerCase().indexOf('praha') !== -1) {
			params = {
				q: `${state}, ${cityEdited}, ${street}`,
				format: 'jsonv2',
			};
		} else {
			params = {
				q: `${state}, okres ${county}, ${cityEdited}, ${street}`,
				format: 'jsonv2',
			};
		}

		params.addressdetails = true;
		const headers = {
			'Accept-Language': 'cs',
		};
		try {
			return await axios.get('https://nominatim.openstreetmap.org/search', { params, headers });
		} catch {
			return false;
		}
	},

	convertCoordinatesToReal(degree, minutes, seconds) {
		return degree + minutes / 60 + seconds / 3600;
	},

	parseAndConvert(coordinates) {
		return this.convertCoordinatesToReal(
			parseFloat(coordinates.split('°')[0]),
			parseFloat(coordinates.split('\'')[0].split('°')[1]),
			parseFloat(coordinates.split('"')[0].split('\'')[1]),
		).toFixed(7);
	},

	async validateGps(gps) {
		const latitude = this.parseAndConvert(gps.split(',')[0]);
		const longitude = this.parseAndConvert(gps.split(',')[1]);

		const params = {
			at: `${latitude},${longitude}`,
			limit: 1,
			apiKey: 'bCnhwMWWaBLWpDb3OKKHuJfFepQOlXocwMNO5d_8CU4',
		};
		const response = await axios.get('https://discover.search.hereapi.com/v1/revgeocode', { params });

		return response.data?.items?.length;
	},
});
