<template>
	<component :is="tag" v-bind="$attrs" @click.stop.prevent="promptConfirm">
		<slot v-for="(_, name) in $slots" :slot="name" :name="name" />
		<template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
			<slot :name="name" v-bind="slotData" />
		</template>
	</component>
</template>

<script>
import { mapState } from 'vuex';
import { random, uniqueId } from 'lodash';

import notifyService from '@/service/notify-service';
import { setTheSpecialModal } from '@/store/mutation-types';

export default {
	name: 'ConfirmButton',
	props: {
		confirmMessage: {
			type: String,
			default() { return this.$t('global.confirmMessage'); },
		},
		messageSuccess: {
			type: String,
			default() { return this.$t('global.messageSuccess'); },
		},
		messageError: {
			type: String,
			default() { return this.$t('global.messageError'); },
		},
		showSuccessMessage: {
			type: Boolean,
			default: true,
		},
		tag: {
			type: String,
			default: 'p-button',
			description: 'Html tag to use',
		},
		callback: {
			type: Function,
			default: () => true,
		},
		showInput: {
			type: Boolean,
			default: false,
		},
		inputDescription: {
			type: String,
			default: '',
		},
		requireInput: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			actionId: null,
		};
	},
	computed: {
		...mapState({
			returnValue: (state) => state.theSpecialModal.returnValue,
			storeActionId: (state) => state.theSpecialModal.actionId,
		}),
	},
	watch: {
		returnValue: {
			handler(val) {
				if (val === null) {
					return;
				}
				if (val && this.actionId === this.storeActionId) {
					new Promise((resolve, reject) => {
						try {
							resolve(this.callback());
						} catch (e) {
							reject(e);
						} finally {
							this.$store.commit(setTheSpecialModal, {});
						}
					})
						.then(() => {
							if (this.showSuccessMessage) {
								notifyService.notifySuccess(this.messageSuccess);
							}
						})
						.catch((err) => {
							if (err !== 'cancel') {
								this.$store.commit(setTheSpecialModal, {
									title: this.$t('global.error'),
									text: this.messageError,
									type: 'error',
									confirmButtonText: 'OK',
									actionId: this.actionId,
									show: true,
								});
							}
						});
				}
			},
		},
	},

	mounted() {
		this.actionId = uniqueId(random(true));
	},
	methods: {
		promptConfirm() {
			this.$store.commit(setTheSpecialModal, {
				title: this.confirmMessage,
				type: 'warning',
				text: null,
				confirmButtonText: this.$t('global.yes'),
				cancelButtonText: this.$t('global.cancel'),
				show: true,
				returnValue: null,
				actionId: this.actionId,
				showInput: this.showInput,
				inputDescription: this.inputDescription,
				requireInput: this.requireInput,
			});
		},
	},
};
</script>
