<template>
	<span class="nowrap">
		<el-radio-group v-model="selected" size="small" @change="toggleInput">
			<el-radio-button v-if="enableDoesntMatterButton" :label="doesntMatterText" data-cy="yesNoAll-all" />
			<el-radio-button :label="yesText" data-cy="yesNoAll-yes" />
			<el-radio-button :label="noText" data-cy="yesNoAll-no" />
		</el-radio-group>
	</span>
</template>

<script>
import { RadioGroup as ElRadioGroup, RadioButton as ElRadioButton } from 'element-ui';

export default {
	name: 'YesNoAll',
	components: { ElRadioGroup, ElRadioButton },
	props: {
		value: Boolean,
		yesText: {
			type: String,
			default() { return this.$t('admin.tooltips.yes'); },
		},
		noText: {
			type: String,
			default() { return this.$t('admin.tooltips.no'); },
		},
		doesntMatterText: {
			type: String,
			default() { return this.$t('admin.tooltips.doesntMatter'); },
		},
		triggerOnChangeMethod: {
			type: Boolean,
			default: false,
		},
		enableDoesntMatterButton: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			selected: this.doesntMatterText,
			currentValue: this.value,
		};
	},
	watch: {
		value(newValue) {
			this.currentValue = newValue;
		},
		currentValue(newValue, old) {
			if (newValue !== old) {
				this.updateValue(newValue);
				this.setText();
			}
		},
	},
	created() {
		this.setText();
	},
	methods: {
		setText() {
			if (this.currentValue) {
				this.selected = this.yesText;
			} else if (this.currentValue === null) {
				this.selected = this.doesntMatterText;
			} else {
				this.selected = this.noText;
			}
		},
		updateValue(value) {
			this.$emit('input', value);
			if (this.triggerOnChangeMethod) {
				this.$emit('on-change-method');
			}
		},
		toggleInput(text) {
			switch (text) {
				case this.yesText:
					this.currentValue = true;
					break;

				case this.noText:
					this.currentValue = false;
					break;

				case this.doesntMatterText:
				default:
					this.currentValue = null;
					break;
			}
		},
	},
};
</script>

<style scoped>
.nowrap {
	white-space: nowrap;
}
</style>
