<template>
	<div class="card">
		<div class="card-body">
			<h2 class="card-title">
				{{ $t('admin.containerPhotos') }}
			</h2>
		</div>
		<div v-if="containerPhotos && containerPhotos.length > 0" class="card-body">
			<div class="d-flex flex-wrap my-2">
				<div v-for="photo in paginatedPhotos" :key="photo.rowKey" class="block image-container">
					<el-image :src="photo.uri" fit="cover" :preview-src-list="previewSrcList" />
					<div class="set-as-primary-overlay" @click="setPrimaryPhotoClick(photo.blobName)">
						<div class="set-as-primary-overlay-text">
							{{ !photoIsPrimary(photo.blobName) ? $t('admin.containerPhotoSetAsPrimary') : $t('admin.containerPhotoUnsetPrimary') }}
						</div>
					</div>
					<el-tooltip
						v-if="!photoIsPrimary(photo.blobName)"
						:content="$t('admin.deletePhoto')"
						placement="top-start"
						class="delete-icon">
						<confirm-button
							:key="photo.blobName"
							class="delete-button"
							:confirm-message="$t('admin.deletePhotoConfirmMessage')"
							:message-success="$t('admin.deletePhotoSuccessMessage')"
							:message-error="$t('admin.deletePhotoErrorMessage')"
							type="danger"
							icon
							:callback="() => deletePhoto(photo.blobName)">
							<i class="fa fa-times" />
						</confirm-button>
					</el-tooltip>
					<i v-if="photoIsPrimary(photo.blobName)" class="fa fa-check-circle primary-photo-icon" aria-hidden="true" />
				</div>
			</div>
		</div>
		<div v-else class="card-body">
			{{ $t('admin.containerNoPhotos') }}
		</div>
		<div v-if="containerPhotos && containerPhotos.length > 0" class="d-flex">
			<el-select v-model="pageSize" class="w-25" @change="pageSizeChange">
				<el-option
					v-for="item in options"
					:key="item.value"
					:label="item.label"
					:value="item.value" />
			</el-select>
			<p-pagination
				v-model="currentPage"
				:page-count="pageCount"
				class="mt-1"
				layout="prev,pager,next"
				@input="calculatePageCount" />
		</div>
		<div class="card-body">
			<el-upload
				ref="uploadComponent"
				action="/api/Container/SaveContainerPhoto"
				name="image"
				accept="image/jpeg"
				:disabled="false"
				:on-success="onUploadSuccess"
				:headers="headers"
				:show-file-list="false"
				:before-upload="beforeUpload">
				<p-button slot="trigger" :disabled="false" size="small">
					{{ $t('admin.containerPhotoInsert') }}
				</p-button>
			</el-upload>
		</div>
	</div>
</template>

<script>
import { Image as ElImage, Tooltip as ElTooltip, Upload as ElUpload, Select as ElSelect, Option as ElOption } from 'element-ui';

import notifyService from '@/service/notify-service';
import http from '@/service/http';
import { PPagination } from '@/components/UIComponents';

export default {
	name: 'DeliveryPointPhotos',
	components: {
		ElImage,
		ElTooltip,
		ElUpload,
		ElSelect,
		ElOption,
		PPagination,
	},
	props: {
		containerId: {
			type: Number,
			required: true,
		},
		primaryPhotoFileName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			containerPhotos: [],
			primaryPhoto: '',
			currentPage: 1,
			pageCount: null,
			pageSize: 4,
			options: [
				{
					value: 4,
					label: 4,
				},
				{
					value: 8,
					label: 8,
				},
				{
					value: 12,
					label: 12,
				},
				{
					value: 16,
					label: 16,
				},
			],
		};
	},
	computed: {
		headers() {
			return http.defaults.headers.common;
		},
		paginatedPhotos() {
			return this.containerPhotos.slice(this.currentPage * this.pageSize - this.pageSize, this.currentPage * this.pageSize);
		},
		previewSrcList() {
			return this.containerPhotos.map((c) => c.uri);
		},
	},
	async created() {
		await this.loadPhotos();
		this.primaryPhoto = this.primaryPhotoFileName;
		this.calculatePageCount();
	},
	methods: {
		async loadPhotos() {
			const response = await this.$service.deliveryPoint.getContainerPhotos(this.containerId);
			this.containerPhotos = response.containerPhotos;
		},
		async deletePhoto(fileName) {
			const success = await this.$service.deliveryPoint.deleteContainerPhoto(this.containerId, fileName);
			if (success) {
				this.containerPhotos = this.containerPhotos.filter((photo) => photo.blobName !== fileName);
				this.calculatePageCount();
				if (this.paginatedPhotos.length === 0 && this.currentPage > 1) {
					this.currentPage -= 1;
				}
			} else {
				throw new Error('admin.deletePhotoErrorMessage');
			}
		},
		onUploadSuccess(response) {
			if (response) {
				notifyService.notifySuccess(this.$t('admin.containerPhotoInsertedSuccess'));
				if (this.containerPhotos) {
					this.containerPhotos.unshift(response);
				} else {
					this.containerPhotos = [response];
				}
				this.calculatePageCount();
			}
		},
		async setPrimaryPhotoClick(fileName) {
			if (this.photoIsPrimary(fileName)) {
				await this.setPrimaryPhoto(null);
			} else {
				await this.setPrimaryPhoto(fileName);
			}
		},
		async setPrimaryPhoto(fileName) {
			const response = await this.$service.deliveryPoint.setContainerPhotoAsPrimary(this.containerId, fileName);
			if (response.success) {
				this.primaryPhoto = fileName;
				if (fileName) {
					notifyService.notifySuccess(this.$t('admin.containerPhotoSetAsPrimarySuccess'));
				} else {
					notifyService.notifySuccess(this.$t('admin.containerPhotoUnsetPrimarySuccess'));
				}
			} else {
				notifyService.notifyError(this.$t('admin.containerPhotoSetAsPrimaryError'));
			}
		},
		photoIsPrimary(fileName) {
			return fileName === this.primaryPhoto;
		},
		calculatePageCount() {
			if (this.containerPhotos) {
				this.pageCount = Math.ceil(this.containerPhotos.length / this.pageSize);
			}
		},
		pageSizeChange() {
			this.calculatePageCount();
			this.currentPage = 1;
		},
		beforeUpload(file) {
			// https://stackoverflow.com/a/68956880
			if (file.type.match(/image.*/)) {
				const MAX_WIDTH = 1920;
				const MAX_HEIGHT = MAX_WIDTH;
				const MIME_TYPE = 'image/jpeg';
				const QUALITY = 0.7;

				const blobUrl = URL.createObjectURL(file);
				const image = new Image();
				image.src = blobUrl;
				const self = this;
				image.onload = function () {
					URL.revokeObjectURL(this.src);
					const [newWidth, newHeight] = self.calculateSize(image, MAX_WIDTH, MAX_HEIGHT);
					const canvas = document.createElement('canvas');
					canvas.width = newWidth;
					canvas.height = newHeight;
					const context = canvas.getContext('2d');
					context.drawImage(image, 0, 0, newWidth, newHeight);
					canvas.toBlob(
						(blob) => {
							self.$service.deliveryPoint.uploadContainerPhoto(self.containerId, blob).then(self.onUploadSuccess);
						},
						MIME_TYPE,
						QUALITY,
					);
				};
			}
			return false;
		},
		calculateSize(img, maxWidth, maxHeight) {
			let { width } = img;
			let { height } = img;

			// calculate the width and height, constraining the proportions
			if (width > height) {
				if (width > maxWidth) {
					height = Math.round((height * maxWidth) / width);
					width = maxWidth;
				}
			} else if (height > maxHeight) {
				width = Math.round((width * maxHeight) / height);
				height = maxHeight;
			}
			return [width, height];
		},
	},
};
</script>

<style scoped>
.el-image {
	width: 100px;
	height: 100px;
}
.image-container .el-image :hover{
	cursor: zoom-in;
}
.image-container {
	position: relative;
	margin: 5px;
}
.image-container .delete-button {
	position: absolute;
    right: -10%;
    top: -10%;
    border-radius: 50%;
    width: 20%;
    height: 20%;
}
.btn.btn-icon {
	min-width: 0;
}
.set-as-primary-overlay {
    position: absolute;
    bottom: 5px;
    height: 38px;
    width: 100px;
    opacity: 0;
    transition: .5s ease;
    background-color: #2b2b2b97;

}

.set-as-primary-overlay-text {
    font-size: 90%;
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
	width: 100%;
}

.image-container:hover .set-as-primary-overlay {
    opacity: 1;
    cursor: pointer;
}

.primary-photo-icon {
    color: #00ae4c;
    position: absolute;
    right: -8%;
    top: -8%;
    font-size: 150%;
	background-color: white;
    border-radius: 50%;
}
</style>
