<template>
	<div
		v-show="active"
		:id="id || title"
		class="tab-pane"
		:class="{ active: active }">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'Tab',
	inject: ['addTab', 'removeTab'],
	props: {
		title: {
			type: String,
			default: '',
			description: 'Tab pane title',
		},
		id: {
			type: String,
			default: null,
			description: 'Tab pane id',
		},
	},
	data() {
		return {
			active: false,
		};
	},
	mounted() {
		this.addTab(this);
	},
	destroyed() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el);
		}
		this.removeTab(this);
	},
};
</script>
