<template>
	<div class="form-group">
		<slot name="label">
			<label v-if="label">
				{{ label }}
				<span v-if="required" class="text-danger">*</span>
			</label>
		</slot>
		<el-autocomplete
			:value="value"
			v-bind="$attrs"
			:fetch-suggestions="fetchSuggestions"
			:placeholder="placeholder"
			:name="name"
			:trigger-on-focus="triggerOnFocus"
			class="w-100"
			:class="[{ 'is-valid': hasSuccess }, { 'has-danger': error }, { 'is-invalid': error }]"
			@input="updateValue"
			@select="handleSelect" />
		<slot name="helpBlock">
			<div v-if="error" class="invalid-feedback d-block">
				{{ error }}
			</div>
		</slot>
	</div>
</template>

<script>
import { Autocomplete as ElAutocomplete } from 'element-ui';

export default {
	name: 'Autocomplete',
	components: {
		ElAutocomplete,
	},
	props: {
		value: { type: String, default: null },
		placeholder: { type: String, default: null },
		name: { type: String, default: null },
		error: { type: String, default: null },
		label: { type: String, default: null },
		triggerOnFocus: { type: Boolean, default: false },
		searchFieldName: { type: String, default: null },
		required: { type: Boolean, default: false },
	},
	data() {
		return {
			touched: false,
			hadError: false,
		};
	},
	computed: {
		hasSuccess() {
			return this.hadError && this.touched && !this.error;
		},
	},
	watch: {
		error: {
			handler(val) {
				if (val) {
					this.hadError = true;
				}
			},
			immediate: true,
		},
	},
	methods: {
		updateValue(value) {
			if (!this.touched && value) {
				this.touched = true;
			}
			this.$emit('input', value);
		},
		fetchSuggestions(input, cb) {
			this.$emit('fetch-suggestions', input, cb, this.searchFieldName);
		},
		handleSelect(value) {
			this.$emit('select', value);
		},
	},
};
</script>

<style>
.form-group .el-input__inner::placeholder {
	color: #636c72;
}
</style>
