<template>
	<card>
		<div class="card-body">
			<h2 class="card-title">
				{{ $t('eshop.container') }} {{ $t('eshop.nr') }} {{ reservation.containerSerialNumber }}
			</h2>
			<form-group-input :label="$t('eshop.country')" :value="reservation.state" readonly />
			<form-group-input :label="$t('eshop.city')" :value="reservation.city" readonly />
			<form-group-input :label="$t('eshop.street')" :value="reservation.street" readonly />
			<form-group-input :label="$t('eshop.location')" :value="reservation.location" readonly />
		</div>
	</card>
</template>

<script>

export default {
	name: 'ContainerInfo',
	components: {

	},
	props: {
		reservation: {
			type: Object,
			default: () => ({}),
		},
	},
};
</script>
