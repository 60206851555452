export const ContainerTreeViewService = (http) => ({
	async getStateList() {
		const response = await http.get('/api/ContainerTree/State/Reservations');
		return response.data;
	},
	async getRegionList(state) {
		const response = await http.get('/api/ContainerTree/Region/Reservations', { params: { state } });
		return response.data;
	},
	async getCountyList(state, region) {
		const response = await http.get('/api/ContainerTree/County/Reservations', { params: { state, region } });
		return response.data;
	},
	async getCityList(state, region, county) {
		const response = await http.get('/api/ContainerTree/City/Reservations', { params: { state, region, county } });
		return response.data;
	},

	async getContainerTree(region, county, city) {
		const response = await http.get('/api/ContainerTree/ContainerForDelivery', { params: { region, county, city } });
		return response.data;
	},

	async getPrivateAddressTree(region, county, city) {
		const response = await http.get('/api/ContainerTree/PrivateAddressDelivery', { params: { region, county, city } });
		return response.data;
	},

	async getService() {
		const requireService = true;
		const response = await http.get('/api/ContainerTree/ContainerForDelivery', { params: { requireService } });
		return response.data;
	},
	getParams(filter) {
		const { pbPosibilities, storePosibilities, addressPosibilities } = filter;

		return {
			'pbPosibilities.post': pbPosibilities.post,
			'pbPosibilities.delivery': pbPosibilities.delivery,
			'pbPosibilities.returns': pbPosibilities.returns,
			'pbPosibilities.expirations': pbPosibilities.expirations,
			'pbPosibilities.directDelivery': pbPosibilities.directDelivery,
			'pbPosibilities.service': pbPosibilities.service,
			'storePosibilities.post': storePosibilities.post,
			'storePosibilities.delivery': storePosibilities.delivery,
			'storePosibilities.returns': storePosibilities.returns,
			'storePosibilities.expirations': storePosibilities.expirations,
			'addressPosibilities.post': addressPosibilities.post,
			'addressPosibilities.delivery': addressPosibilities.delivery,
			'addressPosibilities.returns': addressPosibilities.returns,
		};
	},
});
