<template>
	<div class="row">
		<div class="col-12">
			<card>
				<el-steps :active="active">
					<el-step :description="$t('global.reservationCreated')" :title="trace.created | datetime" icon="fa fa-plus-circle text-success" />
					<el-step
						v-for="step in trace.steps"
						:key="step.date"
						:icon="`fa ${step.icon} text-success`"
						:title="createTitle(step)"
						:description="createLabel(step)" />
				</el-steps>
			</card>
		</div>
	</div>
</template>

<script>
import { Steps as ElSteps, Step as ElStep } from 'element-ui';
import moment from 'moment';

export const LOCAL_DATE_FORMAT = 'DD.MM.YYYY HH:mm';
export default {
	name: 'BaseStepper',
	components: {
		ElSteps,
		ElStep,
	},
	props: {
		trace: { type: Object, required: true },
	},
	data() {
		return {
			active: 0,
		};
	},
	watch: {
		trace: {
			deep: true,
			immediate: true,
			handler(val) {
				this.active = val.steps.length + 1;
			},
		},
	},
	methods: {
		createLabel({ changeBy, label }) {
			return changeBy ? `${label} ${changeBy}` : label;
		},
		createTitle(step) {
			return step.showDate ? moment.utc(String(step.date)).local().format(LOCAL_DATE_FORMAT) : step.label;
		},
	},
};
</script>

<style lang="scss" scoped>
.svg-inline--fa {
	height: 1.5em;
	width: 1.5em;
}
</style>
