<template>
	<div class="row">
		<div class="col">
			<h2 class="card-title">
				{{ $t('admin.generateNewCode') }} {{ reservationId }}
			</h2>

			<form>
				<form-group-input :label="$t('admin.email')" :value="email" readonly />
				<form-group-input :label="$t('admin.phone')" :value="phone" readonly />
				<confirm-button
					:callback="() => validateAndSave('admin')"
					:confirm-message="$t('admin.generateNewCode')"
					:show-success-message="false"
					class="pull-right">
					{{ $t('admin.generateNewCode') }}
				</confirm-button>
			</form>
		</div>
	</div>
</template>

<script>
import ValidationMixin from '@/util/validation-mixin';
import { ConfirmButton } from '@/components/UIComponents';

export default {
	name: 'GenerateNewCode',
	components: {
		ConfirmButton,
	},
	mixins: [ValidationMixin],
	props: {
		reservationId: {
			type: Number,
			required: true,
			description: 'reservation ID',
		},
		email: {
			type: String,
			default: null,
			description: 'e-mail',
		},
		phone: {
			type: String,
			default: null,
			description: 'phone',
		},
	},
	methods: {
		async save() {
			await this.$service.reservation.generateNewCode(this.reservationId);
			this.$emit('hide-generate-new-code');
		},
	},
};
</script>

<style scoped>
.card-title {
  color: #2c2c2c;
}
</style>
