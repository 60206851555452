import { Notification } from 'element-ui';

export default {
	notifySave(success, messages) {
		if (success) {
			this.notifySuccess(messages.success || 'Uloženo');
		} else {
			this.notifyError(messages.fail || 'Nepodařilo se uložit');
		}
	},
	notifySuccess(message) {
		Notification.success({
			message,
			type: 'success',
		});
	},
	notifyError(message) {
		const x = message.indexOf(':');
		const messageBody = x > -1 ? message.substring(x + 2) : message;
		Notification.error({
			message: messageBody,
			type: 'error',
		});
	},
};
