<template>
	<div>
		<div class="row">
			<div v-if="!loadedPickup" class="col-xl-9">
				<card>
					<h2>{{ $t('driver.loadPickupPlaceQR') }}</h2>
					<div v-if="scanQrCodeOfPickup" class="row justify-content-center">
						<qrcode-stream :camera="camera" class="qr_code mt-3" @decode="onDecodePickup" />
					</div>
					<div v-else class="row justify-content-center text-left">
						<div class="col-12 d-inline-flex justify-content-center">
							<form-group-input
								ref="pickupId"
								v-model="pickupId"
								v-validate="validations.pickup.rules"
								:placeholder="$t('driver.inputPickupNumber')"
								:label="$t('driver.pickupNumber')"
								type="number"
								:name="validations.pickup.name"
								:error="getError(validations.pickup.name)"
								:data-vv-as="$t('driver.pickupNumber')" />
							<p-button
								class="confirm-button"
								:disabled="loading"
								native-type="submit"
								size="md"
								type="success"
								@click="onDecodePickup(pickupId)">
								{{ $t('driver.confirm') }}
							</p-button>
						</div>
					</div>
					<div class="row justify-content-center">
						<div class="col-12 d-flex justify-content-center">
							<p-button
								v-if="scanQrCodeOfPickup"
								class="mt-3"
								type="primary"
								size="md"
								@click="toggleQrCodeOfPickup">
								{{ $t('driver.addByHand') }}
							</p-button>
							<p-button
								v-else
								type="primary"
								size="md"
								@click="toggleQrCodeOfPickup">
								{{ $t('driver.QRCode') }}
							</p-button>
						</div>
					</div>
				</card>
			</div>
			<div v-else class="col-xl-9">
				<card>
					<div>
						<div class="row justify-content-center">
							<div class="col-12">
								<h2>{{ $t('driver.pickupPlace') }}: {{ loadedStore ? loadedStore.serialNumber : '-' }}</h2>
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-12">
								<h3>1. {{ $t('driver.expiration') }}</h3>
							</div>
						</div>
						<div v-if="scanQrCodeOfExpiration" class="row justify-content-center text-left overflow-hidden">
							<div class="col-12 d-flex justify-content-center">
								<qrcode-stream :camera="camera" class="qr_code mt-3" @decode="onDecodeExpiration" />
							</div>
						</div>
						<div v-else class="row justify-content-center text-left">
							<div class="col-12 d-inline-flex justify-content-center">
								<form-group-input
									ref="orderNumberInput"
									v-model="orderNumber"
									:data-vv-as="$t('driver.orderNumber')"
									:error="getError(orderNumberName)"
									type="text"
									:label="$t('driver.orderNumber')"
									:placeholder="$t('driver.inputOrderNumber')" />
								<p-button
									:disabled="loading"
									class="confirm-button"
									native-type="submit"
									size="md"
									type="success"
									@click="onExpirationAddedManually">
									{{ $t('driver.confirm') }}
								</p-button>
							</div>
						</div>
						<div class="row justify-content-center text-left">
							<div class="col-12 d-flex justify-content-center">
								<p-button
									v-if="scanQrCodeOfExpiration"
									class="mt-3"
									type="primary"
									size="md"
									@click="toggleQrCodeOfExpiration">
									{{ $t('driver.addByHand') }}
								</p-button>
								<p-button
									v-else
									type="primary"
									class="mb-4"
									size="md"
									@click="toggleQrCodeOfExpiration">
									{{ $t('driver.QRCode') }}
								</p-button>
							</div>
						</div>
						<div class="row justify-content-center text-left">
							<div class="col-12">
								<div v-loading="expirationsAndReturnsLoading">
									<el-table
										:data="loadedExpirations"
										class="table-striped mt-3"
										show-overflow-tooltip
										border
										:default-sort="{ prop: expirationTable.orderBy, order: expirationTable.direction }"
										@sort-change="sortChangeExpirations">
										<el-table-column
											property="reservationNumber"
											align="center"
											sortable="custom"
											label-class-name="nowrap">
											<template #header>
												<el-tooltip :content="$t('driver.orderNumber')" placement="top-start">
													<span class="pr-3">{{ $t('driver.orderNumber') }}</span>
												</el-tooltip>
											</template>
										</el-table-column>
										<el-table-column align="center" class-name="td-actions text-center">
											<template #header>
												<el-tooltip :content="$t('driver.state')" placement="top-start">
													<span>{{ $t('driver.loaded') }}</span>
												</el-tooltip>
											</template>
											<template #default="scope">
												<span v-show="scope.row.isScanned"> <i class="fa fa-check fa-lg text-success" /> </span>
												<span v-show="!scope.row.isScanned"><i class="fa fa-times fa-lg text-danger" /> </span>
											</template>
										</el-table-column>
									</el-table>
								</div>
							</div>
						</div>
						<div class="row justify-content-center">
							<div class="col-12">
								<h3 class="mt-5">
									2. {{ $t('driver.returnedPackages') }}
								</h3>
							</div>
						</div>
						<div class="row justify-content-center text-left">
							<div class="col-12">
								<div v-loading="expirationsAndReturnsLoading">
									<el-table
										:data="loadedReturns"
										class="table-striped mt-3 mb-3"
										show-overflow-tooltip
										border
										:default-sort="{ prop: returnTable.orderBy, order: returnTable.direction }"
										@sort-change="sortChangeReturns">
										<el-table-column
											property="reservationNumber"
											align="center"
											sortable="custom"
											label-class-name="nowrap">
											<template #header>
												<el-tooltip :content="$t('driver.orderNumber')" placement="top-start">
													<span class="pr-3">{{ $t('driver.orderNumber') }}</span>
												</el-tooltip>
											</template>
										</el-table-column>
										<el-table-column align="center" class-name="td-actions text-center">
											<template #header>
												<el-tooltip :content="$t('driver.state')" placement="top-start">
													<span>{{ $t('driver.pickup') }}</span>
												</el-tooltip>
											</template>
											<template slot-scope="scope">
												<p-checkbox v-model="selectedReturns" :value="scope.row.reservationId.toString()" class="mt-1 mb-1" />
											</template>
										</el-table-column>
									</el-table>
									<div class="row justify-content-center">
										<p-button type="danger" size="md" @click="cancelSelect">
											{{ $t('driver.cancel') }}
										</p-button>
										<p-button type="success" size="md" @click="pickupSelectedItems">
											{{ $t('driver.confirmSelected') }}
										</p-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</card>
			</div>
		</div>

		<el-dialog
			:close-on-click-modal="false"
			:custom-class="`${dialogExpiredClass} text-center`"
			:visible.sync="showModal"
			width="85%">
			<span class="order_status">{{ informMessage }}</span>
		</el-dialog>

		<el-dialog
			:close-on-click-modal="false"
			:visible.sync="showErrorPickupDialog"
			round
			width="90%"
			custom-class="not_expired text-center"
			@close="closeErrorPickupDialog">
			<div class="d-flex justify-content-center centering_content">
				<div v-if="failedExpirations && failedExpirations.length" :class="failedReturns && failedReturns.length ? 'mb-4' : ''">
					<strong>{{ $t('driver.theseExpirationsNotPickuped') }}</strong>: {{ failedExpirations.join(', ') }}
				</div>
				<div v-if="failedReturns && failedReturns.length">
					<strong>{{ $t('driver.theseReturnsNotPickuped') }}</strong>: {{ failedReturns.join(', ') }}
				</div>
			</div>
			<div slot="footer" class="text-center">
				<p-button type="success" @click="closeErrorPickupDialog">
					OK
				</p-button>
			</div>
		</el-dialog>

		<el-dialog
			:close-on-click-modal="false"
			:visible.sync="confirmDialogVisible"
			round
			width="90%"
			@close="closeConfirmDialog">
			<h3 class="text-center">
				{{ $t('driver.confirmPickUp') }}
			</h3>
			<div class="d-flex justify-content-center centering_content">
				<div v-if="notSelectedExpirations.length" class="text-left mb-2">
					<strong>{{ $t('driver.theseExpirationsNotSelected') }}</strong>: {{ notSelectedExpirations.join(', ') }}
				</div>
				<div v-if="notSelectedReturns.length" class="text-left mb-2">
					<strong>{{ $t('driver.theseReturnsNotSelected') }}</strong>: {{ notSelectedReturns.join(', ') }}
				</div>
				<p class="text-center mb-2">
					<strong>{{ $t('driver.continue') }}?</strong>
				</p>
			</div>
			<div slot="footer" class="text-center">
				<p-button :disabled="loading" type="success" @click="confirmPickup">
					{{ $t('driver.confirm') }}
				</p-button>
				<p-button :disabled="loading" type="danger" @click="closeConfirmDialog">
					{{ $t('driver.cancel') }}
				</p-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import _ from 'lodash';
import { Dialog as ElDialog, Table as ElTable, TableColumn as ElTableColumn, Tooltip as ElTooltip } from 'element-ui';
import { QrcodeStream } from 'vue-qrcode-reader';

import ValidationMixin from '@/util/validated-form-mixin';
import { SORTING_ASC } from '@/modules/eshop/store/constants';
import Validations from '@/util/validations';
import notifyService from '@/service/notify-service';
import FormMixin from '@/util/form-mixin';
import { appName } from '@/modules/driver/config';
import { formChanged } from '@/store/mutation-types';

const MODAL_TIMEOUT = 2000;

export default {
	name: 'ExpirationsAndReturnOrders',
	components: {
		ElTable,
		ElTableColumn,
		ElTooltip,
		QrcodeStream,
		ElDialog,
	},
	mixins: [ValidationMixin, FormMixin],
	data() {
		return {
			dialogExpiredClass: null,
			expirationsAndReturnsLoading: false,
			loading: false,
			loadedPickup: false,
			loadedExpirations: [],
			loadedReturns: [],
			loadedStore: null,
			scanQrCodeOfPickup: false,
			scanQrCodeOfExpiration: false,
			showModal: false,
			informMessage: '',
			scannedExpirations: [],
			pickupId: null,
			selectedReturns: [],
			notSelectedExpirations: [],
			notSelectedReturns: [],
			confirmDialogVisible: false,
			failedExpirations: [],
			failedReturns: [],
			showErrorPickupDialog: false,
			orderNumber: null,
			orderNumberName: 'orderNumber',
			expirationTable: {
				orderBy: null,
				direction: null,
			},
			returnTable: {
				orderBy: null,
				direction: null,
			},
			validations: {
				pickup: {
					name: 'pickup',
					rules: Validations.Number,
				},
			},
		};
	},
	watch: {
		loadedPickup(val) {
			this.$store.commit(`${appName}/${formChanged}`, val);
		},
	},
	mounted() {
		this.toggleQrCodeOfPickup();
	},
	methods: {
		toggleQrCodeOfPickup() {
			this.scanQrCodeOfPickup = !this.scanQrCodeOfPickup;
			if (!this.scanQrCodeOfPickup) {
				this.$nextTick(() => {
					this.$refs.pickupId.$el.querySelector('input').focus();
				});
			}
			this.switchCamera(this.scanQrCodeOfPickup);
		},
		toggleQrCodeOfExpiration() {
			this.scanQrCodeOfExpiration = !this.scanQrCodeOfExpiration;
			if (!this.scanQrCodeOfExpiration) {
				this.$nextTick(() => {
					this.$refs.orderNumberInput.$el.querySelector('input').focus();
				});
			}

			this.switchCamera(this.scanQrCodeOfExpiration);
		},
		hideDialog() {
			this.showModal = false;
			this.orderNumber = null;
		},
		async onDecodePickup(pickupId) {
			if (!pickupId) {
				this.loadedPickup = false;
				return;
			}

			this.cameraReset();

			this.expirationsAndReturnsLoading = true;
			this.loading = true;

			const response = await this.$service.reservation.getReturnsAndExpirations(pickupId.toString());

			if (response?.expirations) {
				if (response.store) {
					response.expirations.forEach((expiration) => {
						expiration.isScanned = false;
					});

					this.loadedExpirations = response.expirations;
					this.loadedReturns = response.returns;
					this.loadedStore = response.store;
					this.loadedPickup = true;
					this.toggleQrCodeOfExpiration();
				} else {
					notifyService.notifyError(this.$t('driver.pickupPlaceNotFound'));
					this.loadedPickup = false;
				}
			} else {
				this.loadedPickup = false;
			}

			this.expirationsAndReturnsLoading = false;
			this.loading = false;
		},
		async onExpirationAddedManually() {
			this.loading = true;
			const expiration = this.loadedExpirations.find((reservation) => reservation.reservationNumber.toLowerCase() === this.orderNumber.toLowerCase());
			await this.setExpirationAsScanned(expiration);
			this.loading = false;
		},
		async onDecodeExpiration(decodedString) {
			if (!decodedString) {
				return;
			}
			this.cameraReset();
			this.loading = true;
			const qrData = await this.getQrData(decodedString);
			await this.setExpirationAsScanned(this.findExpiration(qrData));
			this.loading = false;
		},
		async setExpirationAsScanned(expiration) {
			if (expiration) {
				const reservationQr = await this.$service.qrCode.getData(expiration.reservationId);
				const reservation = await this.$service.qrCode.getReservation(reservationQr);

				if (reservation && reservation.length) {
					expiration.isScanned = true;
					reservation[0].isScanned = true;

					if (!this.scannedExpirations.find((scannedExpiration) => scannedExpiration.reservationId === reservation[0].reservationId)) {
						this.scannedExpirations.push(reservation[0]);
					}

					this.informModal(true, reservation);
				}
			} else {
				this.informModal(false);
			}
		},
		async getQrData(decodedString) {
			try {
				const data = JSON.parse(decodedString);
				const [result] = await this.$service.qrCode.getReservation(data);
				return result;
			} catch (e) {
				return decodedString;
			}
		},
		findExpiration(qrData) {
			const findMethod = _.isObject(qrData)
				? (reservation) => reservation.reservationId?.toString() === qrData.reservationId?.toString()
				: (reservation) => reservation.unlockCode?.toString() === qrData?.toString();

			return this.loadedExpirations.find(findMethod);
		},
		informModal(reservationExpired) {
			this.informMessage = reservationExpired ? this.$t('driver.expired') : this.$t('driver.notExpired');
			this.dialogExpiredClass = reservationExpired ? 'expired' : 'not_expired';
			this.showModal = true;

			setTimeout(this.hideDialog, MODAL_TIMEOUT);
		},
		pickupSelectedItems() {
			const showConfirmDialog = this.scannedExpirations.length !== this.loadedExpirations.length || this.selectedReturns.length !== this.loadedReturns.length;

			if (!showConfirmDialog) {
				this.closeConfirmDialog();
				this.confirmPickup();
			} else {
				this.notSelectedExpirations = this.filterNotSelectedItems(
					this.loadedExpirations,
					this.scannedExpirations.map((se) => se.reservationId),
				);
				this.notSelectedReturns = this.filterNotSelectedItems(this.loadedReturns, this.selectedReturns);
				this.confirmDialogVisible = showConfirmDialog;
			}
		},
		filterNotSelectedItems(loadedItems, scannedItems) {
			return loadedItems
				.filter((loadedReturn) => !scannedItems.find((scannedReturn) => scannedReturn.toString() === loadedReturn.reservationId.toString()))
				.map((notSelectedReturn) => notSelectedReturn.reservationNumber);
		},
		async confirmPickup() {
			this.loading = true;
			const expirationPromises = [];
			const returnPromises = [];

			this.scannedExpirations.forEach((item) => this.createPromisesForPickup(item, this.failedExpirations, expirationPromises), this);
			this.selectedReturns.forEach(
				(item) => this.createPromisesForPickup(
					this.loadedReturns.find((loadedReturn) => loadedReturn.reservationId.toString() === item),
					this.failedReturns,
					returnPromises,
				),
				this,
			);

			try {
				await Promise.all(expirationPromises);
				await Promise.all(returnPromises);

				if (this.failedExpirations.length || this.failedReturns.length) {
					this.showErrorPickupDialog = true;
				} else {
					this.closeErrorPickupDialog();
					this.cancelSelect();
					this.informMessage = this.$t('driver.operationSuccess');
					this.dialogExpiredClass = 'expired';
					this.showModal = true;
					setTimeout(this.hideDialog, MODAL_TIMEOUT);
					this.$store.commit(`${appName}/${formChanged}`, false);
				}
			} catch (e) {
				this.showErrorPickupDialog = true;
			} finally {
				this.loading = false;
				this.closeConfirmDialog();
			}
		},
		createPromisesForPickup(item, failedList, promises) {
			if (item.unlockCode) {
				promises.push(this.createPromiseForPickup(item, failedList));
			} else {
				failedList.push(item.reservationNumber);
			}
		},
		createPromiseForPickup(item, failedItems) {
			return new Promise((resolve, reject) => {
				this.$service.reservation
					.ackPickup({ reservationId: item.reservationId })
					.then((result) => {
						if (result && result.status !== 'Fail') {
							return resolve(result);
						}
						failedItems.push(item.reservationNumber);
						return reject(result);
					})
					.catch((error) => {
						failedItems.push(item.reservationNumber);
						return reject(error);
					});
			});
		},
		closeConfirmDialog() {
			this.confirmDialogVisible = false;
		},
		cancelSelect() {
			this.expirationsAndReturnsLoading = false;
			this.loading = false;
			this.loadedPickup = false;
			this.loadedExpirations = [];
			this.loadedReturns = [];
			this.loadedStore = null;
			this.scanQrCodeOfPickup = false;
			this.scanQrCodeOfExpiration = false;
			this.showModal = false;
			this.scannedExpirations = [];
			this.pickupId = null;
			this.selectedReturns = [];
			this.notSelectedExpirations = [];
			this.notSelectedReturns = [];
			this.confirmDialogVisible = false;
			this.failedExpirations = [];
			this.failedReturns = [];
			this.showErrorPickupDialog = false;
		},
		sortChangeExpirations(column) {
			this.loadedExpirations = this.sortChange(this.expirationTable, this.loadedExpirations, column);
		},
		sortChangeReturns(column) {
			this.loadedReturns = this.sortChange(this.returnTable, this.loadedReturns, column);
		},
		sortChange(storeData, list, column) {
			if (column.order) {
				storeData.orderBy = column.prop;
				storeData.direction = column.order;
			} else {
				storeData.orderBy = null;
				storeData.direction = null;
			}

			const sortedItems = list.sort((itemA, itemB) => itemA[storeData.orderBy].localeCompare(itemB[storeData.orderBy]));

			if (storeData.direction === SORTING_ASC) {
				return sortedItems.reverse();
			}

			return sortedItems;
		},
		closeErrorPickupDialog() {
			this.failedReturns = [];
			this.failedExpirations = [];
			this.showErrorPickupDialog = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.qr_code {
	max-width: 400px;
	z-index: 1;
}
.order_status {
	font-size: 14pt;
	font-weight: bold;
}
.centering_content {
	min-height: 150px;
	line-height: normal;
	flex-direction: column;
	align-items: center;
}
.confirm-button {
	height: 37px;
    margin-top: 25px;
    margin-left: 5px;
}

::v-deep {
	.el-table {
		.el-table__header {
			th > .nowrap {
				white-space: nowrap;
			}
		}
	}

	.el-dialog {
		&.expired {
			background-color: green;
		}

		&.not_expired {
			background-color: red;
		}

		&.expired,
		&.not_expired {
			.el-dialog__body {
				color: white;
				min-height: 200px;
				line-height: 200px;
			}
		}
	}
}
</style>
