<template>
	<div>
		<card v-if="showQrCodeArea" class="qr_code-area">
			<div v-if="scanQrCode">
				<h5 class="load-qrs-heading">
					{{ $t('driver.loadPackageQRCode') }}
				</h5>
				<qrcode-stream
					v-if="scanQrCode"
					:camera="camera"
					class="qr_code mx-auto mb-2"
					@decode="onDecode" />
			</div>
			<div v-else>
				<h5 class="load-qrs-heading">
					{{ $t('driver.addByHand') }}
				</h5>
				<div class="mx-auto mb-2 text-left d-flex justify-content-center">
					<form-group-input
						v-model="addByHandReservationNumber"
						:label="$t('driver.orderNumber')"
						:placeholder="$t('driver.addOrderNumber')" />
					<p-button
						id="reservation-number-confirm"
						type="default"
						size="md"
						@click="findReservationByNumber">
						{{ $t('driver.confirm') }}
					</p-button>
				</div>
			</div>
			<div class="text-center mb-2">
				<p-button
					v-if="!scanQrCode"
					class="mr-2"
					size="md"
					type="default"
					@click="scanQrCode = true">
					{{ $t('driver.QRCode') }}
				</p-button>
				<p-button
					v-else
					class="mr-2"
					size="md"
					type="default"
					@click="showAddByHand">
					{{ $t('driver.addByHand') }}
				</p-button>
				<p-button
					:disabled="!reservationsToAckLoadToLorry.length"
					size="md"
					type="success"
					@click="confirmAckLoadToLorry">
					{{ $t('driver.done') }}
					<span v-show="loadingSpinner">&nbsp;<i class="fa fa-spinner fa-spin fa-fw" /></span>
				</p-button>
			</div>
			<div class="text-center">
				<span class="text-big text-success">{{ $t('driver.remainsToLoad') }}:
					<strong>{{ remainingReservationsToBeLoaded }}/{{ totalReservationsToBeLoaded }}</strong>
					{{ $t(translateWithNumber('driver', 'reservation', totalReservationsToBeLoaded)) }}</span>
			</div>
			<i class="fa fa-times close-icon" aria-hidden="true" @click="hideQrCodeArea" />
		</card>

		<el-dialog
			v-if="reservationSelectedForLoad"
			:close-on-click-modal="false"
			:visible.sync="showConfirmDialogForMorePackages"
			round
			width="50%">
			<h3 class="text-center">
				{{ packagesCheckLabel }}
			</h3>
			<span slot="footer" class="d-flex justify-content-center">
				<p-button
					size="lg"
					type="success"
					class="m-2"
					@click="() => loadInTheCar(reservationSelectedForLoad)">
					{{ $t('driver.yes') }}
				</p-button>
				<p-button
					size="lg"
					type="danger"
					class="m-2"
					@click="() => toggleShowConfirmDialogForMorePackages(false)">
					{{ $t('driver.no') }}
				</p-button>
			</span>
		</el-dialog>

		<el-dialog
			:close-on-click-modal="false"
			:visible.sync="showConfirmDialogForQrCodeArea"
			round
			width="50%">
			<h3 class="text-center">
				{{ $t('driver.remainsToLoad') }}: {{ remainingReservationsToBeLoaded }}. {{ $t('driver.continue') }}?
			</h3>
			<span slot="footer" class="d-flex justify-content-center">
				<p-button size="lg" type="success" @click="makeAckLoadToLorry">{{ $t('driver.yes') }}
					<span v-show="loadingSpinner">&nbsp;<i class="fa fa-spinner fa-spin fa-fw" /></span>
				</p-button>
				<p-button size="lg" type="danger" @click="() => setDisplayConfirmDialogForQrCodeArea(false)">{{ $t('driver.noBack') }}</p-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { Dialog as ElDialog } from 'element-ui';
import { isObject } from 'lodash';
import { QrcodeStream } from 'vue-qrcode-reader';

import { ReservationRouteStatus } from '@/modules/driver/store/enums';
import notifyService from '@/service/notify-service';
import ValidationMixin from '@/util/validated-form-mixin';
import ReservationNameMixin from '@/util/reservation-name-mixin';
import { translateWithNumber } from '@/util/helpers';
import FormMixin from '@/util/form-mixin';
import BaseMixins from '@/util/base-mixins';

export default {
	name: 'AckLoadToLorryReservation',
	components: {
		QrcodeStream,
		ElDialog,
	},
	mixins: [ValidationMixin, ReservationNameMixin, FormMixin, BaseMixins],
	props: {
		reportData: {
			type: Object,
			required: true,
		},
		show: {
			type: Boolean,
			default: false,
		},
		loadData: {
			type: Function,
			required: true,
		},
		totalReservationsToBeLoaded: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			translateWithNumber,
			scanQrCode: false,
			showQrCodeArea: this.show,
			showConfirmDialogForQrCodeArea: false,
			reservationsToAckLoadToLorry: [],
			showConfirmDialogForMorePackages: false,
			reservationSelectedForLoad: null,
			loadingSpinner: false,
			addByHandReservationNumber: '',
		};
	},
	computed: {
		remainingReservationsToBeLoaded() {
			return this.totalReservationsToBeLoaded - this.reservationsToAckLoadToLorry.length;
		},
		packagesCheckLabel() {
			if (this.reservationSelectedForLoad) {
				return this.$t('driver.packagesCheck').replace('__PACKAGE_COUNT__', this.reservationSelectedForLoad.packagesCount);
			}
			return '';
		},
		allReservations() {
			if (this.reportData?.containers) {
				return this.reportData.containers.flatMap((container) => container.reservations);
			}
			return [];
		},
	},
	watch: {
		show: {
			handler(val) {
				this.setDisplayQrCodeArea(val);
			},
		},
		remainingReservationsToBeLoaded: {
			handler(val) {
				this.$emit('change-remaining-reservations-to-be-loaded', val);
			},
			immediate: true,
		},
	},
	methods: {
		async onDecode(decodedString) {
			let qrData = null;

			try {
				const data = JSON.parse(decodedString);
				[qrData] = await this.$service.qrCode.getReservation(data);
			} catch (e) {
				qrData = decodedString;
			}

			this.cameraReset();

			if (isObject(qrData)) {
				this.notifyAndLoadInTheCar(qrData.reservationId);
			} else {
				notifyService.notifyError(this.$t('driver.reservationNotFound'));
			}
		},
		notifyAndLoadInTheCar(reservationId) {
			const reservation = this.getReservationInWaitForDelivery(reservationId);
			if (reservation) {
				const isConfirmed = reservation.reservationRouteStatus
			&& ReservationRouteStatus.enumValueOf(reservation.reservationRouteStatus) === ReservationRouteStatus.CONFIRMED_BY_DRIVER;
				if (isConfirmed) {
					notifyService.notifyError(this.$t('driver.reservationHasAlreadyBeenStocked'));
				} else if (this.reservationsToAckLoadToLorry.includes(reservation.reservationId)) {
					notifyService.notifySuccess(this.$t('driver.reservationHasAlreadyBeenLoaded'));
				} else if (reservation?.packagesCount > 1) {
					this.reservationSelectedForLoad = reservation;
					this.toggleShowConfirmDialogForMorePackages(true);
				} else {
					this.loadInTheCar(reservation);
				}
			} else {
				notifyService.notifyError(this.$t('driver.reservationNotFound'));
			}
		},

		loadInTheCar(reservation) {
			this.reservationsToAckLoadToLorry.push(reservation.reservationId);
			notifyService.notifySuccess(this.$t('driver.reservationLoaded'));
			this.addByHandReservationNumber = '';
			this.toggleShowConfirmDialogForMorePackages(false);
			this.$emit('select-reservation', reservation);
		},
		getReservationInWaitForDelivery(reservationId) {
			const reservations = this.reportData.containers
				.filter((container) => container.waitForDeliveryReservations)
				.map((container) => {
					const reservation = container.waitForDeliveryReservations.find(
						(waitForDeliveryReservation) => waitForDeliveryReservation.reservationId.toString() === reservationId.toString(),
					);

					if (reservation) {
						return { ...reservation, containerSn: container.containerSn };
					}
					return null;
				})
				.filter(Boolean);

			return reservations[0] ?? null;
		},
		toggleShowConfirmDialogForMorePackages(visibility) {
			this.showConfirmDialogForMorePackages = visibility;
		},
		setDisplayQrCodeArea(show) {
			if (show) {
				document.body.classList.add('hide-transformations');
			} else {
				document.body.classList.remove('hide-transformations');
			}

			this.switchCamera(show);

			this.showQrCodeArea = show;
			this.scanQrCode = show;
		},
		setDisplayConfirmDialogForQrCodeArea(show) {
			this.showConfirmDialogForQrCodeArea = show;
		},
		confirmAckLoadToLorry() {
			if (this.remainingReservationsToBeLoaded) {
				this.setDisplayConfirmDialogForQrCodeArea(true);
			} else {
				this.makeAckLoadToLorry();
			}
		},
		async makeAckLoadToLorry() {
			try {
				this.loadingSpinner = true;
				const reservationIdsSuccess = await this.$service.logisticsRoute.ackLoadLorry(this.reportData.logisticRouteId, this.reservationsToAckLoadToLorry);

				if (!reservationIdsSuccess || reservationIdsSuccess.errors) {
					notifyService.notifyError(reservationIdsSuccess?.errors?.title ?? this.$t('driver.generalError'));
					return;
				}

				this.reservationsToAckLoadToLorry = this.reservationsToAckLoadToLorry.filter((r) => !reservationIdsSuccess.includes(r));

				reservationIdsSuccess.forEach((reservationId) => {
					const reservation = this.getReservationInWaitForDelivery(reservationId);

					this.$emit('unselect-reservation', reservation);
				});

				this.reportData.containers.forEach((container) => container.waitForDeliveryReservations?.forEach((reservation) => {
					if (reservationIdsSuccess.includes(reservation.reservationId)) {
						reservation.reservationRouteStatus = ReservationRouteStatus.CONFIRMED_BY_DRIVER.camelCase();
					}
				}));

				notifyService.notifySuccess(this.$t('driver.done'));

				this.loadData();
				this.$emit('change-show', false);
			} catch (e) {
				notifyService.notifyError(e.toString());
			} finally {
				this.setDisplayConfirmDialogForQrCodeArea(false);
				this.loadingSpinner = false;
			}
		},
		findReservationByNumber() {
			const reservation = this.allReservations.find((r) => r.reservationNumber?.toLowerCase() === this.addByHandReservationNumber?.toLowerCase());
			if (reservation) {
				this.notifyAndLoadInTheCar(reservation.reservationId);
			} else {
				notifyService.notifyError(this.$t('driver.orderNumberNotInReport'));
			}
		},
		showAddByHand() {
			this.scanQrCode = false;
			this.addByHandReservationNumber = '';
		},
		hideQrCodeArea() {
			this.$emit('change-show', false);
		},
	},
};
</script>

<style lang="scss" scoped>
.qr_code-area {
	position: fixed;
	left: 0;
	bottom: 0;
	margin: 0;
	width: 100%;
	text-align: center;
	max-width: 100%;
	padding: 0;
}
.qr_code {
	video {
		width: auto;
		height: auto;
		max-width: inherit;
		max-height: inherit;
	}
	max-width: 300px;
	max-height: 250px;
	z-index: 1;
	overflow: hidden;
}
.text-big {
	font-size: 115%;
}

::v-deep .selected-box {
	animation: selected-box-animation 1s linear 1 1s;
	background: palegreen;
}

@keyframes selected-box-animation {
	from {
		background: red;
		color: white;
	}
	to {
		color: initial;
		background: palegreen;
	}
}

.mb-max {
	margin-bottom: 325px !important;
}

.load-qrs-heading {
	margin: 0;
	margin-bottom: 8px;
}
.close-icon {
	position: absolute;
	top: 0;
	right: 0;
	padding: 22px;
	font-size: 135%;
	cursor: pointer;
}
#reservation-number-confirm {
	height: 37px;
    margin-top: 25px;
    margin-left: 5px;
}
</style>
