<template>
	<form v-loading="loading">
		<div class="card">
			<div class="card-body">
				<h2 class="card-title">
					{{ $t('admin.openingHoursWeek') }}
				</h2>
				<el-table
					class="table-striped"
					:data="openingHoursWeek">
					<el-table-column property="day" width="140">
						<template #header>
							<span>{{ $t('admin.day') }}</span>
						</template>
						<template #default="scope">
							<span>{{ Weekday.czech(scope.row.day) }}</span>
						</template>
					</el-table-column>
					<el-table-column width="140">
						<template #header>
							<span>{{ $t('admin.status') }}</span>
						</template>
						<template #default="scope">
							<el-select v-model="scope.row.status" @change="statusChange(scope.row)">
								<el-option
									v-for="status in OpeningHoursStatus.enumValues"
									:key="status.name"
									:value="status.name"
									:label="status.czech()" />
							</el-select>
						</template>
					</el-table-column>
					<el-table-column property="openTime" width="140">
						<template #header>
							<span>{{ $t('admin.openTime') }}</span>
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.openTime"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00'
								}" />
						</template>
					</el-table-column>
					<el-table-column width="18" align="center">
						<template #default="scope">
							<span v-if="isOpen(scope.row.status)">-</span>
						</template>
					</el-table-column>
					<el-table-column property="closeTime" width="140">
						<template #header>
							{{ $t('admin.closeTime') }}
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.closeTime"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00',
									minTime: scope.row.openTime
								}" />
						</template>
					</el-table-column>
					<el-table-column width="30">
            &nbsp;
					</el-table-column>
					<el-table-column property="openTimeNext" width="140">
						<template #header>
							<span>{{ $t('admin.openTime') }}</span>
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.openTimeNext"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00',
									minTime: scope.row.closeTime
								}" />
						</template>
					</el-table-column>
					<el-table-column width="18" align="center">
						<template #default="scope">
							<span v-if="isOpen(scope.row.status)">-</span>
						</template>
					</el-table-column>
					<el-table-column property="closeTimeNext" width="140">
						<template #header>
							<span>{{ $t('admin.closeTime') }}</span>
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.closeTimeNext"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00',
									minTime: scope.row.openTimeNext
								}" />
						</template>
					</el-table-column>
					<el-table-column property="description">
						<template #header>
							<span>{{ $t('admin.description') }}</span>
						</template>
						<template #default="scope">
							<el-input v-model="scope.row.description" :maxlength="250" name="description" />
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="card-footer">
				<div class="mt-2">
					<confirm-button
						class="pull-right"
						:show-success-message="false"
						:confirm-message="$t('admin.openingHoursUpdate')"
						:callback="() => validateAndSave('admin')">
						{{ $t('admin.save') }}
					</confirm-button>
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<h2 class="card-title">
					{{ $t('admin.openingHoursDate') }}
				</h2>
				<el-table
					class="table-striped"
					:data="openingHoursDate">
					<el-table-column property="day" width="140">
						<template #header>
							<span>{{ $t('admin.day') }}</span>
						</template>
						<template #default="scope">
							<date-picker
								v-model="scope.row.date"
								type="datetime"
								prefix-icon="el-icon-date"
								format="dd.MM.yyyy" />
						</template>
					</el-table-column>
					<el-table-column width="140">
						<template #header>
							<span>{{ $t('admin.status') }}</span>
						</template>
						<template #default="scope">
							<el-select v-model="scope.row.status" @change="statusChange(scope.row)">
								<el-option
									v-for="status in OpeningHoursStatus.enumValues"
									:key="status.name"
									:value="status.name"
									:label="status.czech()" />
							</el-select>
						</template>
					</el-table-column>
					<el-table-column property="openTime" width="140">
						<template #header>
							<span>{{ $t('admin.openTime') }}</span>
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.openTime"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00'
								}" />
						</template>
					</el-table-column>
					<el-table-column width="18" align="center">
						<template #default="scope">
							<span v-if="isOpen(scope.row.status)">-</span>
						</template>
					</el-table-column>
					<el-table-column property="closeTime" width="140">
						<template #header>
							<span>{{ $t('admin.closeTime') }}</span>
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.closeTime"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00',
									minTime: scope.row.openTime
								}" />
						</template>
					</el-table-column>
					<el-table-column width="30">
            &nbsp;
					</el-table-column>
					<el-table-column property="openTimeNext" width="140">
						<template #header>
							<span>{{ $t('admin.openTime') }}</span>
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.openTimeNext"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00',
									minTime: scope.row.closeTime
								}" />
						</template>
					</el-table-column>
					<el-table-column width="18" align="center">
						<template #default="scope">
							<span v-if="isOpen(scope.row.status)">-</span>
						</template>
					</el-table-column>
					<el-table-column property="closeTimeNext" width="140">
						<template #header>
							<span>{{ $t('admin.closeTime') }}</span>
						</template>
						<template #default="scope">
							<el-time-select
								v-if="isOpen(scope.row.status)"
								v-model="scope.row.closeTimeNext"
								:picker-options="{
									start: '00:00',
									step: '00:15',
									end: '24:00',
									minTime: scope.row.openTimeNext
								}" />
						</template>
					</el-table-column>
					<el-table-column property="description">
						<template #header>
							<span>{{ $t('admin.description') }}</span>
						</template>
						<template #default="scope">
							<el-input
								v-model="scope.row.description"
								:maxlength="100"
								name="description" />
						</template>
					</el-table-column>
					<el-table-column>
						<template slot-scope="scope">
							<p-button size="mini" type="danger" @click="handleDelete(scope.row)">
								{{ $t('admin.remove') }}
							</p-button>
						</template>
					</el-table-column>
				</el-table>
				<p-button type="success" class="mr-3" @click="addOpeningHours">
					<span class="fa fa-plus" /> {{ $t('admin.openingHours') }}
				</p-button>
				<p-button type="success" @click="addPublicHolidays">
					<span class="fa fa-plus" /> {{ $t('admin.publicHolidays') }}
				</p-button>
			</div>
		</div>
	</form>
</template>

<script>
import {
	Table as ElTable,
	TableColumn as ElTableColumn,
	TimeSelect as ElTimeSelect,
	Input as ElInput, DatePicker,
	Select as ElSelect,
	Option as ElOption,
} from 'element-ui';
import moment from 'moment';

import { publicHolidays } from '@/modules/admin/store/constants';
import ValidationMixin from '@/util/validation-mixin';
import { Weekday, OpeningHoursStatus } from '@/modules/admin/store/enums';
import { ConfirmButton } from '@/components/UIComponents';

export default {
	name: 'DeliveryPointOpeningHoursForm',
	components: {
		ElTable,
		ElTableColumn,
		ElTimeSelect,
		ElInput,
		DatePicker,
		ElSelect,
		ElOption,
		ConfirmButton,
	},
	mixins: [ValidationMixin],
	data() {
		return {
			loading: false,
			Weekday,
			OpeningHoursStatus,
			openingHours: [],
		};
	},
	computed: {
		deliveryPointId() {
			return Number(this.$route.params.id);
		},
		openingHoursWeek() {
			return this.openingHours.filter((openingHour) => !openingHour.date);
		},
		openingHoursDate() {
			return this.openingHours.filter((openingHour) => openingHour.date);
		},
	},
	async created() {
		this.loading = true;
		if (this.deliveryPointId) {
			const openingHoursStart = moment().startOf('day').utc(true).toDate();
			const openingHoursEnd = moment().endOf('day').add(1, 'y').utc(true)
				.toDate();
			this.openingHours = await this.$service.deliveryPoint.getOpeningHours(this.deliveryPointId, openingHoursStart, openingHoursEnd);
		}
		this.loading = false;
	},

	methods: {
		async save() {
			await this.$service.deliveryPoint.updateOpeningHours(this.deliveryPointId, this.openingHours);
			this.$router.push({ name: 'pickup-place-edit', params: { id: this.deliveryPointId } });
		},
		addOpeningHours() {
			const date = new Date();
			this.openingHours.push({ date, day: date.getDay(), status: OpeningHoursStatus.Closed });
		},
		addPublicHolidays() {
			const year = new Date().getFullYear();
			publicHolidays.czech.forEach((p) => {
				const date = new Date(p.date.replace('0000', year));
				if (year === date.getFullYear()) {
					this.openingHours.push({ date, day: date.getDay(), status: OpeningHoursStatus.Closed, description: p.name });
				}
			});
		},
		isOpen(status) {
			return OpeningHoursStatus.enumValueOf(status) === OpeningHoursStatus.Open;
		},
		statusChange(row) {
			switch (OpeningHoursStatus.enumValueOf(row.status)) {
				case OpeningHoursStatus.Open24Hours:
					row.openTime = '00:00';
					row.closeTime = null;
					row.openTimeNext = null;
					row.closeTimeNext = null;
					break;
				case OpeningHoursStatus.Closed:
					row.openTime = null;
					row.closeTime = null;
					row.openTimeNext = null;
					row.closeTimeNext = null;
					break;
				default:
			}
		},
		handleDelete(row) {
			const index = this.openingHours.findIndex((o) => o === row);
			this.openingHours.splice(index, 1);
		},
	},
};
</script>
