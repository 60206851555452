import { mapActions } from 'vuex';

import store from '@/store';
import { authenticate, logout } from '@/store/action-types';
import ReservationList from '@/modules/eshop/views/Reservation/ReservationList.vue';
import ReservationForm from '@/modules/eshop/views/Reservation/ReservationForm.vue';
import DirectSaleProductList from '@/modules/eshop/views/DirectSale/DirectSaleProductList.vue';
import DirectSaleProductSale from '@/modules/eshop/views/DirectSale/DirectSaleProductSale.vue';
import BoxList from '@/modules/eshop/views/Boxes/BoxList.vue';
import AssignStockItemList from '@/modules/eshop/views/Boxes/AssignStockItemList.vue';
import {
	BaseNewReturnOrder,
	BaseChangePasswordForm,
	BaseCollectionPrintSheet,
	BaseLogin,
	BaseForgotPassword,
	BaseResetPassword,
	BaseConfirmation,
} from '@/components/Base';
import EshopOrderList from '@/modules/eshop/views/EshopOrder/EshopOrderList.vue';
import EshopOrderDetail from '@/modules/eshop/views/EshopOrder/EshopOrderDetail.vue';
import ReservationStickerPrint from '@/modules/eshop/views/Reservation/ReservationDetails/ReservationStickerPrint.vue';
import EshopUserList from '@/modules/eshop/views/EshopUsers/EshopUserList.vue';
import DirectSaleProductForm from '@/modules/eshop/views/DirectSale/DirectSaleProductForm.vue';
import NewOrder from '@/modules/eshop/views/Order/NewOrder.vue';
import EshopUserForm from '@/modules/eshop/views/EshopUsers/EshopUserForm.vue';
import LoggedOutLayout from '@/components/Layouts/LoggedOutLayout.vue';
import EshopReservationUserList from '@/modules/eshop/views/EshopUsers/EshopReservationUserList.vue';
import { appName } from '@/modules/eshop/config';
import { i18n } from '@/i18n';

export const routes = [
	{
		component: LoggedOutLayout,
		path: '',
		meta: {
			title: i18n.t('admin.eshop'),
		},
		children: [
			{ name: 'login', path: '', component: BaseLogin, props: { ...mapActions(appName, [authenticate, logout]) } },
			{ name: 'forgot-password', path: 'zapomenute-heslo', component: BaseForgotPassword },
			{ name: 'pass-reset', path: 'ResetPass/:user', component: BaseResetPassword },
			{ name: 'mail-confirm', path: 'Confirmation/:user', component: BaseConfirmation },
		],
	},
	{
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "shop" */ '../../components/Layouts/DashboardLayout.vue'),
		path: '/eshop/',
		async beforeEnter(to, from, next) {
			if (!store.state[appName].currentUser || !store.state[appName].currentUser.DirectSale) {
				await store.dispatch(`${appName}/loadCurrentUser`);
			}
			next();
		},
		redirect: '/eshop/rezervace',
		meta: {
			title: i18n.t('admin.eshop'),
		},
		children: [
			{
				name: 'reservation-list',
				path: 'rezervace',
				component: ReservationList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservation') }],
				},
			},
			{
				name: 'reservation-detail',
				path: 'rezervace/detail/:id',
				component: ReservationForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservation'), link: 'reservation-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'direct-sale-list',
				path: 'produkty-primy-prodej',
				component: DirectSaleProductList,
				async beforeEnter(to, from, next) {
					if (!store.state[appName].currentUser || !store.state[appName].currentUser.DirectSale) {
						await store.dispatch(`${appName}/loadCurrentUser`);
					}
					if (store.state[appName].currentUser.DirectSale === 'True') {
						next();
					} else {
						next({
							name: 'reservation-list',
						});
					}
				},
				meta: {
					breadcrumb: [{ name: i18n.t('admin.directSaleProducts') }],
				},
			},
			{
				name: 'direct-sale-product-sold',
				path: '/eshop/produkty-prodano',
				component: DirectSaleProductSale,
				async beforeEnter(to, from, next) {
					if (!store.state[appName].currentUser || !store.state[appName].currentUser.DirectSale) {
						await store.dispatch(`${appName}/loadCurrentUser`);
					}
					if (store.state[appName].currentUser.DirectSale === 'True') {
						next();
					} else {
						next({
							name: 'reservation-list',
						});
					}
				},
				meta: {
					breadcrumb: [{ name: i18n.t('admin.directSaleProducts'), link: 'direct-sale-list' }, { name: i18n.t('admin.export') }],
				},
			},
			{
				name: 'direct-sale-product-new',
				path: 'produkty-primy-prodej/novy',
				component: DirectSaleProductForm,
				async beforeEnter(to, from, next) {
					if (!store.state[appName].currentUser || !store.state[appName].currentUser.DirectSale) {
						await store.dispatch(`${appName}/loadCurrentUser`);
					}
					if (store.state[appName].currentUser.DirectSale === 'True') {
						next();
					} else {
						next({
							name: 'reservation-list',
						});
					}
				},
				meta: {
					breadcrumb: [{ name: i18n.t('admin.directSaleProducts'), link: 'direct-sale-list' }, { name: i18n.t('admin.new') }],
				},
			},
			{
				name: 'direct-sale-product-edit',
				path: 'produkty-primy-prodej/:id',
				component: DirectSaleProductForm,
				async beforeEnter(to, from, next) {
					if (!store.state[appName].currentUser || !store.state[appName].currentUser.DirectSale) {
						await store.dispatch(`${appName}/loadCurrentUser`);
					}
					if (store.state[appName].currentUser.DirectSale === 'True') {
						next();
					} else {
						next({
							name: 'reservation-list',
						});
					}
				},
				meta: {
					breadcrumb: [{ name: i18n.t('admin.directSaleProducts'), link: 'direct-sale-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'box-list',
				path: 'schranky',
				component: BoxList,
				async beforeEnter(to, from, next) {
					if (!store.state[appName].currentUser || !store.state[appName].currentUser.DirectSale) {
						await store.dispatch(`${appName}/loadCurrentUser`);
					}
					if (store.state[appName].currentUser.DirectSale === 'True') {
						next();
					} else {
						next({
							name: 'reservation-list',
						});
					}
				},
				meta: {
					breadcrumb: [{ name: i18n.t('admin.boxes') }],
				},
			},
			{
				name: 'box-list-assign-item',
				path: 'schranky/:boxId',
				component: AssignStockItemList,
				props: true,
				async beforeEnter(to, from, next) {
					if (!store.state[appName].currentUser || !store.state[appName].currentUser.DirectSale) {
						await store.dispatch(`${appName}/loadCurrentUser`);
					}
					if (store.state[appName].currentUser.DirectSale === 'True') {
						next();
					} else {
						next({
							name: 'reservation-list',
						});
					}
				},
				meta: {
					breadcrumb: [{ name: i18n.t('admin.boxes'), link: 'box-list' }, { name: i18n.t('admin.assignItem') }],
				},
			},
			{
				name: 'return-order',
				path: 'vratka',
				component: BaseNewReturnOrder,
				props: { boxTypeMode: true },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.orderReturn') }],
				},
			},
			{
				name: 'box-order',
				path: 'objednavka',
				component: NewOrder,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.orderDelivery') }],
				},
			},
			{
				name: 'box-order-shoptet',
				path: 'objednavka/shoptet',
				component: NewOrder,
				props: { shoptet: Object },
				meta: {
					breadcrumb: [{ name: i18n.t('admin.orderDelivery') }],
				},
			},
			{
				name: 'eshop-order-list',
				path: 'eshop',
				component: EshopOrderList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.eshop') }],
				},
			},
			{
				name: 'eshop-order-detail',
				path: 'eshop/detail/:id',
				component: EshopOrderDetail,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.eshop'), link: 'eshop-order-list' }, { name: i18n.t('admin.detail') }],
				},
			},
			{
				name: 'password-change',
				path: 'zmena-hesla',
				component: BaseChangePasswordForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.passwordChange') }],
				},
			},
			{
				name: 'expired-print',
				path: 'sber-rezervaci',
				component: BaseCollectionPrintSheet,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.reservationsPickup') }],
				},
			},
			{
				name: 'sticker-print',
				path: 'tisk-stitku',
				component: ReservationStickerPrint,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.labelsPrint') }],
				},
			},
			{
				name: 'eshop-user-list',
				path: 'eshop-uzivatele',
				component: EshopUserList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.users') }],
				},
			},
			{
				name: 'eshop-user-new',
				path: 'eshop-uzivatele/novy',
				component: EshopUserForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.users'), link: 'eshop-user-list' }, { name: 'Nový' }],
				},
			},
			{
				name: 'eshop-user-edit',
				path: 'eshop-uzivatele/:id',
				component: EshopUserForm,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.users'), link: 'eshop-user-list' }, { name: 'Detail' }],
				},
			},
			{
				name: 'eshop-user-reservations',
				path: 'eshop-uzivatele-s-rezervaci',
				component: EshopReservationUserList,
				meta: {
					breadcrumb: [{ name: i18n.t('admin.usersWithReservations') }],
				},
			},
		],
	},
];
