import { setTheSpecialModal } from '@/store/mutation-types';

export default {
	methods: {
		async unsavedChangesMethod() {
			this.$store.commit(setTheSpecialModal, {
				show: true,
				title: 'Upozornění',
				text: 'Doopravdy chcete opustit tuto stránku? Máte neuložené změny!',
				icon: 'warning',
				confirmButtonText: 'Ano, odejít',
				cancelButtonText: 'Ne, zůstat',
			});

			return new Promise((resolve) => {
				this.$root.$on('the-special-modal-close', (val, actionId) => {
					if (!actionId) {
						resolve(val);
					}
				});
			});
		},
	},
	async beforeRouteLeave(to, from, next) {
		const { currentAppName } = this.$store.state;

		if (this.$store.state[currentAppName].formChanged) {
			const answer = await this.unsavedChangesMethod();

			if (answer) {
				next();
				this.$store.commit(`${currentAppName}/formChanged`, false);
			} else {
				next(false);
			}
		} else {
			next();
		}
	},
};
