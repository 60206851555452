<template>
	<div v-loading="loading">
		<h2>
			{{ $t('eshop.findAlternativeContainer') }}
		</h2>
		<form-group-input :label="$t('eshop.boxType')">
			<div>
				<el-radio-group v-model="selectedBoxType" size="medium" @change="boxTypeChanged">
					<el-radio-button
						v-for="box in BoxType.enumValues.filter((t) => t.name != 'SmallWithUSB')"
						:key="box.ordinal"
						:label="box">
						<span>{{ box.czech() }}</span>
					</el-radio-button>
				</el-radio-group>
				<div v-if="selectedBoxType">
					<i class="fa fa-info-circle" aria-hidden="true" />
					<span class="font-weight-bold pl-1">Max: </span>
					{{ $t('eshop.width') }}&nbsp;{{ selectedBoxProportions.width }}&nbsp;cm
					&#x25cf;&nbsp;{{ $t('eshop.height') }}&nbsp;{{ selectedBoxProportions.height }}&nbsp;cm
					&#x25cf;&nbsp;{{ $t('eshop.depth') }}&nbsp;{{ selectedBoxProportions.depth }}&nbsp;cm
				</div>
			</div>
		</form-group-input>
		<div v-if="recommendationErrorMessage">
			<label>{{ recommendationErrorMessage }}</label>
		</div><p-button :disabled="!selectedBoxType" class="mb-2 mt-2" @click="findAlternativeContainer">
			{{ $t('eshop.findAlternativeContainer') }}
		</p-button>
	</div>
</template>

<script>
import {
	RadioGroup as ElRadioGroup,
	RadioButton as ElRadioButton,
} from 'element-ui';

import { BoxType } from '@/modules/eshop/store/enums';

export default {
	name: 'EshopOrderListAlternativeContainerModal',
	components: {
		ElRadioGroup,
		ElRadioButton,
	},
	props: {
		originalContainerId: {
			type: Number,
			required: true,
		},
		freeContainers: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			selectedBoxType: null,
			recommendationErrorMessage: null,
			BoxType,
		};
	},
	computed: {
		selectedBoxProportions() {
			return this.selectedBoxType.proportions();
		},
	},
	methods: {
		boxTypeChanged(box) {
			this.selectedBoxType = box;
		},
		async findAlternativeContainer() {
			this.loading = true;
			this.recommendationErrorMessage = null;

			let container = null;

			try {
				container = await this.$service.container.getNearestAvailable(
					this.originalContainerId,
					this.selectedBoxType.name,
					Object.fromEntries(this.freeContainers.map((c) => [c.containerId, c.availableBoxTypes])),
				);
			} catch (err) {
				if (err.status !== 404 && err.status !== 400) {
					this.loading = false;
					throw err;
				}
			}

			if (container) {
				this.$emit('found-new-container', container);
			} else {
				this.recommendationErrorMessage = this.$t('eshop.noAlternativeContainerFound');
			}

			this.loading = false;
		},
	},
};
</script>
