<template>
	<div class="row">
		<div class="col-md-6">
			<card>
				<base-containers-tree-view only-check @containers="getContainers" />
			</card>
		</div>
		<div class="col-md-6">
			<card v-loading="loading">
				<form-group-input :label="$t('eshop.soldAfter')">
					<date-picker
						v-model="filter.from"
						type="datetime"
						prefix-icon="el-icon-date"
						format="dd.MM.yyyy HH:mm" />
				</form-group-input>
				<form-group-input :label="$t('eshop.soldBefore')">
					<date-picker
						v-model="filter.to"
						type="datetime"
						prefix-icon="el-icon-date"
						format="dd.MM.yyyy HH:mm" />
				</form-group-input>
				<p-button @click="exportData">
					Export
				</p-button>
			</card>
		</div>
	</div>
</template>

<script>
import { DatePicker } from 'element-ui';

import { BaseContainersTreeView } from '@/components/Base';

export default {
	name: 'DirectSaleProductSale',
	components: {
		DatePicker,
		BaseContainersTreeView,
	},
	data() {
		return {
			containerIds: [],
			filter: { from: null, to: null },
			loading: false,
		};
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcutsHandler);
		this.filter.to = new Date(Date.now());
		this.filter.from = new Date(this.filter.from);
		this.filter.from.setMonth(this.filter.from.getMonth() - 3);
	},
	methods: {
		keyboardShortcutsHandler(shortcut) {
			if (shortcut.key === 'Enter') {
				this.loadData();
			}
		},
		getContainers(containerIds) {
			this.containerIds = containerIds;
		},
		async exportData() {
			this.loading = true;
			await this.$service.stockItem.exportData(this.filter.from, this.filter.to, this.containerIds);
			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.el-table {
	::v-deep .el-table__body .tableRow:hover {
		cursor: pointer;
	}
}
</style>
