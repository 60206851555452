<template>
	<div class="card ml-2">
		<div class="card-body">
			<el-pagination
				:current-page="currentPage"
				:page-count="pagesCount"
				:page-size="pageSize"
				:page-sizes="pageSizes"
				class="d-flex justify-content-center"
				layout="sizes, prev, pager, next, jumper"
				@current-change="processData"
				@size-change="loadDataPageSize" />
			<el-table :data="itemsOnPage" :default-sort="{ prop: orderBy, order: direction }" @sort-change="sortChange">
				<el-table-column :label="$t('eshop.queueName')" property="queueName" sortable />
				<el-table-column
					:label="$t('eshop.queueLength')"
					property="count"
					sortable
					width="200" />
			</el-table>
			<el-pagination
				:current-page="currentPage"
				:page-count="pagesCount"
				:page-size="pageSize"
				:page-sizes="pageSizes"
				class="d-flex mt-3 justify-content-center"
				layout="sizes, prev, pager, next, jumper"
				@current-change="processData"
				@size-change="loadDataPageSize" />
		</div>
	</div>
</template>

<script>
import { Table as ElTable, TableColumn as ElTableColumn, Pagination as ElPagination } from 'element-ui';
import _ from 'lodash';

import { SORTING_ASC } from '@/modules/eshop/store/constants';

const DEFAULT_PAGE = 1;

export default {
	name: 'DirectSaleCategories',
	components: {
		ElPagination,
		ElTable,
		ElTableColumn,
	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			pageSize: 10,
			pageSizes: [10, 20, 50, 100],
			pagesCount: 0,
			currentPage: DEFAULT_PAGE,
			itemsOnPage: [],
			orderBy: null,
			direction: null,
			filter: {},
		};
	},
	watch: {
		data: {
			async handler() {
				this.init();
			},
			deep: true,
			immediate: true,
		},
	},

	methods: {
		init() {
			this.processData(this.currentPage);
			this.refreshPageCount();
		},
		processData(page = DEFAULT_PAGE) {
			this.itemsOnPage = _.cloneDeep(this.data);

			this.sortPaginationData();

			const start = (page - 1) * this.pageSize;
			const end = start + this.pageSize;

			this.itemsOnPage = this.itemsOnPage.slice(start, end);
			this.currentPage = page;
		},
		refreshPageCount() {
			this.pagesCount = Math.ceil(this.data.length / this.pageSize);
		},
		loadDataPageSize(pageSize) {
			this.pageSize = pageSize;

			this.init();
		},
		sortPaginationData() {
			if (this.orderBy) {
				this.itemsOnPage.sort(this.alphaNumCompare);

				if (this.direction !== SORTING_ASC) {
					this.itemsOnPage.reverse();
				}
			}
		},
		sortChange(column) {
			if (column.order) {
				this.filter.orderBy = column.prop;
				this.filter.direction = column.order;
			} else {
				this.filter.orderBy = null;
				this.filter.direction = null;
			}

			this.processData(this.currentPage);
		},
		alphaNumCompare(a, b) {
			return String(a[this.orderBy]).localeCompare(String(b[this.orderBy]), undefined, {
				numeric: true,
				sensitivity: 'base',
			});
		},
	},
};
</script>
