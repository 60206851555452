import { Enum } from 'enumify';

import { camelToUpper, upperToCamel } from '@/util/enums';

export class ReservationStatusBase extends Enum {
	static #CZECH_DICT = {
		WAIT_FOR_COLLECT: 'Čekám na svoz',
		DISCARD: 'Zrušeno',
		PAYMENT_WAITING: 'Očekávaní platby',
		ACTIVE: 'Aktivní',
		PICK_UP: 'Vyzvednuto',
		COLLECTED: 'Svezeno',
		WAIT_FOR_DELIVERY: 'Čekám na doručení',
		PREPARED: 'Připraveno k vyzvednutí',
		WAIT_FOR_PAY: 'Připraveno pro přímý prodej',
		WAIT_FOR_PACKAGE: 'Čekám na balíček od zákazníka',
		WAIT_FOR_PACKAGE_PAY: 'Čekám na platbu za poštovné ',
		WAIT_FOR_RETURN: 'Čekám na vložení zákazníkem',
		WAIT_FOR_RETURN_WITH_RESERVATION: 'Čekám na vložení zákazníkem před doručením',
		WAIT_FOR_EMPTY_DESTINATION: 'Čeká na volný box',
		DELIVERY_RESERVATION_CREATED: 'Vytvořena rezervace k doručení',
		SELECT_FOR_RETURN_DIRECT_SALE: 'Vybráno pro vratku přímého prodeje',
		PRESELECT: 'Předvybráno k prodeji',
		LOST: 'Ztraceno',
		UNDELIVERED: 'Nedoručitelné',
		WAIT_FOR_RETURN_PAY: 'Vratka k úhradě',
	};

	static #ICON_DICT = {
		WAIT_FOR_COLLECT: 'fas fa-history',
		DISCARD: 'fas fa-ban',
		PAYMENT_WAITING: 'fas fa-comment-dollar',
		ACTIVE: 'fas fa-hdd',
		PICK_UP: 'fas fa-check-circle',
		COLLECTED: 'fas fa-warehouse',
		WAIT_FOR_DELIVERY: 'fas fa-shipping-fast',
		PREPARED: 'fas fa-box',
		WAIT_FOR_PAY: 'fas fa-shopping-cart',
		WAIT_FOR_RETURN: 'fas fa-user-clock',
		WAIT_FOR_RETURN_WITH_RESERVATION: 'fas fa-user-clock',
		WAIT_FOR_PACKAGE_PAY: 'fas fa-money-bill',
		WAIT_FOR_PACKAGE: 'fas fa-truck-loading',
		WAIT_FOR_EMPTY_DESTINATION: 'fas fa-warehouse',
		DELIVERY_RESERVATION_CREATED: 'fas fa-boxes',
		PRESELECT: 'fas fa-comment-dollar',
		LOST: 'fas fa-question-circle',
		UNDELIVERED: 'fas fa-store-slash',
		WAIT_FOR_RETURN_PAY: 'fas fa-money-bill',
		SELECT_FOR_RETURN_DIRECT_SALE: 'fas fa-user-clock',
	};

	static czech(str) {
		return ReservationStatusBase.#CZECH_DICT[camelToUpper(str)];
	}

	static icon(str) {
		return ReservationStatusBase.#ICON_DICT[camelToUpper(str)];
	}

	static enumValueOf(str) {
		return super.enumValueOf(camelToUpper(str));
	}

	camelCase() {
		return upperToCamel(this.name);
	}

	czech() {
		return ReservationStatusBase.#CZECH_DICT[this.name];
	}

	icon() {
		return ReservationStatusBase.#ICON_DICT[this.name];
	}

	valueOf() {
		return this.camelCase();
	}
}
